import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Container, Notification } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Button, IconButton } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'

import { ReactComponent as ProbeLogo } from '@clain/core/assets/probe_logo.svg'
import { ReactComponent as FolderIcon } from '@clain/core/assets/folder.svg'

import ProbeVM from '../../vm/ProbeViewModel'
import Search from '../Search'
import ExtendedLayoutPanelComponent from './_ExtendedLayoutPanel'
import { useParams, generatePath, Link } from 'react-router-dom'
import styles from './index.scss'
import { Tooltip } from '@clain/core/ui-kit'
import { Hotkey } from '@clain/core/ui-kit'
import { AddMultipleNodes } from './AddMultiple'
import { UserPresenceListContainer } from './UserPresenceList'
import { SaveToCaseModal } from '../../../Cases/components/SaveToCaseModal'
import * as S from './ProbeHeader.styles'
import { Stub } from '@clainio/web-platform'
import { PROBE_NOTIFICATION_STYLES } from '@platform/components/ProbeSandbox/constants'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

const handleBackButtonClick = () => {
  ProbeVM.setIsAnalyticsLayerActive(false)
  ProbeVM.activeEntity.clearAnalytics()
  ProbeVM.probeState.setBottombarStatus('default')
}

const ProbeHeader: React.FC = () => {
  const { t } = useTranslation()
  const [probeNameInputActive, setNameInputActive] = useState<boolean>(false)
  const [probeName, setProbeName] = useState<string>(
    ProbeVM.probeData?.name || ''
  )
  const inputEl = useRef<HTMLDivElement>(null)
  const params = useParams<{ caseId: string; probeId: string }>()
  const { caseId } = params
  const linkToCases = caseId
    ? generatePath('/cases/:caseId', {
        caseId,
      })
    : ''

  const handleProbeNameInputBlur = async () => {
    try {
      await ProbeVM.probeService.updateName(probeName)
      setNameInputActive(false)
    } catch (e) {
      Notification.notify(
        e.message,
        { type: 'warning' },
        PROBE_NOTIFICATION_STYLES
      )
    }
  }
  const handleProbeNameInputFocus = () => {
    setNameInputActive(true)
  }

  const handleProbeNameInputKeyUp = (event) => {
    if (event.key === 'Enter') {
      const inputElement = inputEl.current?.querySelector('input')
      inputElement?.blur()
    }
  }

  useEffect(() => {
    if (ProbeVM.probeData?.name) {
      setProbeName(ProbeVM.probeData?.name)
    }
  }, [ProbeVM.probeData?.name])

  const defaultProbeTitle = t('platform:untitledProbe') as string

  const logoAndTitle = (
    <React.Fragment>
      <Tooltip content={t('platform:goToMain')}>
        <Link to="/">
          <ProbeLogo className={cx('ProbeLogo')} />
        </Link>
      </Tooltip>
      {!caseId && <SaveToCaseModal probeId={ProbeVM.probeState.probeId} />}

      {caseId && (
        <ColDeprecated gap={0.25} className={cx('HeadingsRow')}>
          <Tooltip content={t('platform:goBackToCase')}>
            <Link to={linkToCases}>
              <Typography
                color="grey2"
                variant="body1-sm"
                className={cx('Subheading')}
              >
                {ProbeVM.probeState.caseData?.title}
                <FolderIcon className={cx('FolderIcon')} />
              </Typography>
            </Link>
          </Tooltip>
          <Tooltip content={t('platform:renameGraph')}>
            <S.TextFieldStyled
              ref={inputEl}
              value={probeName}
              onChange={setProbeName}
              size="sm"
              variant="base"
              focusVariant="outline"
              spaces={['none', 'xs']}
              ellipsis
              font={
                probeName.toLocaleLowerCase() ===
                  defaultProbeTitle?.toLocaleLowerCase() &&
                !probeNameInputActive
                  ? 'heading300Normal'
                  : 'heading300Accent'
              }
              color={
                probeName.toLocaleLowerCase() ===
                  defaultProbeTitle?.toLocaleLowerCase() &&
                !probeNameInputActive
                  ? 'onBackgroundVariant2'
                  : 'onBackgroundVariant1'
              }
              onBlur={handleProbeNameInputBlur}
              onFocus={handleProbeNameInputFocus}
              onKeyUp={handleProbeNameInputKeyUp}
            />
          </Tooltip>
        </ColDeprecated>
      )}
    </React.Fragment>
  )

  if (ProbeVM.isAnalyticsLayerActive) {
    return (
      <Container gap={[0.25, 1]} className={cx('ProbeHeader')}>
        <RowDeprecated gap={2.5} className={cx('LeftPanel')}>
          {logoAndTitle}
        </RowDeprecated>
        <RowDeprecated gap={1.5} className={cx('RightPanel')}>
          <Button onClick={handleBackButtonClick} startIcon="ChevronLeft">
            {t('platform:backToCanvas')}
          </Button>
        </RowDeprecated>
      </Container>
    )
  }

  return (
    <Stub isActive={!ProbeVM.isInitialized}>
      <Container gap={[0.25, 1]} className={cx('ProbeHeader')}>
        <RowDeprecated gap={2.5} className={cx('LeftPanel')}>
          {logoAndTitle}
          <RowDeprecated className={cx('LeftPanelActions')}>
            <RowDeprecated gap={0.25}>
              <Tooltip content={t('platform:undo')} placement="bottom">
                <IconButton
                  size="md"
                  onClick={ProbeVM.history.undo}
                  disabled={ProbeVM.history.isDisabledUndo}
                  isLoading={ProbeVM.history.processingActionType === 'undo'}
                  iconVariant="Undo"
                />
              </Tooltip>
              <Tooltip content={t('platform:redo')} placement="bottom">
                <IconButton
                  size="md"
                  onClick={ProbeVM.history.redo}
                  disabled={ProbeVM.history.isDisabledRedo}
                  isLoading={ProbeVM.history.processingActionType === 'redo'}
                  iconVariant="Redo"
                />
              </Tooltip>
            </RowDeprecated>
            <div className={cx('VerticalDelimiter')} />
            <Tooltip content={t('platform:exportSvg')} placement="bottom">
              <IconButton
                size="md"
                onClick={ProbeVM.downloadSVG}
                disabled={
                  !ProbeVM.probeState.nodes.size &&
                  !ProbeVM.probeState.edges.size
                }
                iconVariant="FileSvg"
              />
            </Tooltip>
          </RowDeprecated>
        </RowDeprecated>

        <RowDeprecated gap={0.5} className={cx('CenterPanel')}>
          <Search
            target={({ show }) => (
              <Button
                endIcon="PlusCircle"
                variant="solid"
                color="primary"
                onClick={show}
              >
                {t('platform:addNode')}
              </Button>
            )}
          />
          <AddMultipleNodes />
          <Button
            endIcon="StarAdd"
            variant="outline"
            color="secondary"
            disabled={true}
          >
            <Typography variant="body2-sm">{t('platform:custom')}</Typography>
          </Button>
          {ProbeVM._showLayoutPanelButton && <ExtendedLayoutPanelComponent />}
        </RowDeprecated>

        <RowDeprecated gap={1} className={cx('RightPanel')}>
          <RowDeprecated gap={0.25}>
            <CheckboxSwitch
              label="USD"
              onChange={ProbeVM.setIsUsdCurrency}
              value={ProbeVM.isUsdCurrency}
              className={cx('CurrencyCheckbox')}
            />
          </RowDeprecated>
          <div className={cx('VerticalDelimiter')} />
          <RowDeprecated gap={0.25}>
            <Tooltip
              content={
                <>
                  {t('platform:addComment')} <Hotkey>C</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={ProbeVM.commentsController.addComment}
                active={ProbeVM.commentsController.isPositioningInProgress}
                iconVariant="CommentLined"
              />
            </Tooltip>
            <Tooltip
              content={
                <>
                  {t('platform:addText')} <Hotkey>T</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={ProbeVM.textController.createTextNode}
                iconVariant="TextTool"
              />
            </Tooltip>
          </RowDeprecated>
          <div className={cx('VerticalDelimiter')} />
          <RowDeprecated gap={0.25}>
            <Tooltip
              content={
                <>
                  {t('platform:toggleGrid')} <Hotkey>G</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={() =>
                  ProbeVM.saveIsMagneticGridActive(
                    !ProbeVM.isMagneticGridActive
                  )
                }
                active={ProbeVM.isMagneticGridActive}
                iconVariant="Grid"
              />
            </Tooltip>
            <Tooltip
              content={
                <>
                  {ProbeVM.isRightSidebarActive
                    ? t('platform:hideLayersTab')
                    : t('platform:showLayersTab')}{' '}
                  <Hotkey>L</Hotkey>
                </>
              }
            >
              <IconButton
                size="md"
                onClick={() =>
                  ProbeVM.setIsRightSidebarActive(!ProbeVM.isRightSidebarActive)
                }
                active={ProbeVM.isRightSidebarActive}
                iconVariant="Layers"
              />
            </Tooltip>
            <IconButton size="md" disabled iconVariant="ZoomToMax" />
          </RowDeprecated>
          <div className={cx('VerticalDelimiter')} />
          <RowDeprecated gap={0.5}>
            <IconButton size="md" disabled iconVariant="Notification" />
            <UserPresenceListContainer />
          </RowDeprecated>
        </RowDeprecated>
      </Container>
    </Stub>
  )
}

export default withErrorBoundary(observer(ProbeHeader))
