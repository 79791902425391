import { PropsWithChildren, useEffect } from 'react'

import {
  TRANSACTION_EVM_FILTERS_INIT_STATE,
  useFiltersActions,
  setInitialState,
} from '../TransactionEvmFilters/TransactionEvmFilters.service'
import {
  TransactionFiltersParams,
  TransactionEvmFiltersParams,
} from './TransactionFilters.types'
import { normalizeTransactionFiltersEvm } from './TransactionFilters.utils'
import { useTransactionViewModel } from '../context'
import { observer } from 'mobx-react-lite'
import { pipe } from 'ramda'
import {
  FILTER_ASSET_KEY,
  FILTER_ASSET_VALUE_TYPE_KEY,
  FILTER_CALENDAR_KEY,
  FILTER_CLUSTER_KEY,
  FILTER_SCAM_SPAM_KEY,
  FILTER_SCORE_KEY,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_ORDER_KEY,
  FILTER_TRANSFER_TYPE_KEY,
} from '../../../constants'
import {
  normalizeCalendarToIntern,
  normalizeComparatorsConditionIntern,
  normalizeComparatorsValueIntern,
  normalizeIsComparatorIntern,
  normalizeScoreToIntern,
  normalizeSelectItemIntern,
  normalizeTextItemListIntern,
  normalizeTokenToIntern,
  normalizeTransferTypeToIntern,
} from '../../../utils'
import { TransactionsEvmFilters } from '../../../../ProbeSandbox/types/filters/TransactionsEvmFilters'
import { FILTER_SCAM_SPAM } from '../constants/transactionFilters'
import { useTimezone } from '@platform/hooks'

const normalizeInternFilters = (
  filterType: TransactionFiltersParams['filterType'],
  filters: Partial<TransactionsEvmFilters>,
  timezone: string
) => {
  let normalizedFilters = pipe(
    () => TRANSACTION_EVM_FILTERS_INIT_STATE,
    normalizeTokenToIntern(FILTER_ASSET_KEY.data, filters?.includeTokens?.[0]),
    normalizeComparatorsValueIntern(FILTER_ASSET_VALUE_TYPE_KEY.data, {
      from: filters?.amountFrom,
      to: filters?.amountTo,
    }),
    normalizeComparatorsConditionIntern(FILTER_ASSET_VALUE_TYPE_KEY.condition, {
      from: filters?.amountFrom,
      to: filters?.amountTo,
    }),
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, filters, timezone),
    normalizeScoreToIntern(FILTER_SCORE_KEY.data, filters),
    normalizeTextItemListIntern(FILTER_CLUSTER_KEY.data, {
      id: filters?.counterpartyId || filters?.counterpartyAddressId,
      label: filters?.counterpartyName,
    }),
    normalizeSelectItemIntern(FILTER_SORT_ORDER_KEY.data, filters?.sortOrder),
    normalizeSelectItemIntern(FILTER_SORT_BY_KEY.data, filters?.sortBy),
    filterType === 'default'
      ? normalizeTransferTypeToIntern(
          FILTER_TRANSFER_TYPE_KEY.data,
          filters?.direction
        )
      : (data) => data
  )()

  normalizedFilters = pipe(
    () => normalizedFilters,
    normalizeIsComparatorIntern(
      FILTER_SCAM_SPAM_KEY.root,
      FILTER_SCAM_SPAM,
      filters?.spam
    )
  )()

  return normalizedFilters
}

const useSetFilters = (counterpartyType: 'address' | 'cluster') => {
  const {
    filters: _filters,
    setFilters,
    filterType,
    defaultFilters,
  } = useTransactionViewModel()
  const timezone = useTimezone()
  const filters = _filters as TransactionsEvmFilters

  const { setFilters: setFiltersAction } = useFiltersActions({
    onSetFilters: (updatedFilters) => {
      setFilters(
        normalizeTransactionFiltersEvm(
          updatedFilters,
          filterType,
          defaultFilters,
          counterpartyType,
          timezone
        )
      )
    },
  })

  useEffect(() => {
    if (filters) {
      setFiltersAction(normalizeInternFilters(filterType, filters, timezone))
    }
  }, [
    filters?.sortBy,
    filters?.spam,
    filters?.sortOrder,
    filters?.direction,
    filters?.from,
    filters?.to,
    filters?.amountFrom,
    filters?.amountTo,
    filters?.includeTokens?.[0]?.id,
    filters?.scoreFrom,
    filters?.scoreTo,
    filters?.counterpartyId,
    filters?.counterpartyAddressId,
    filters?.counterpartyName,
  ])
}

const useInitFilters = () => {
  const timezone = useTimezone()
  const { setFilters } = useFiltersActions()
  const {
    initialFilters: _initialFilters,
    defaultFilters,
    filterType,
  } = useTransactionViewModel()
  const initialFilters = _initialFilters as unknown as TransactionsEvmFilters

  useEffect(() => {
    if (initialFilters) {
      setFilters(normalizeInternFilters(filterType, initialFilters, timezone))
      setInitialState(
        normalizeInternFilters(filterType, defaultFilters, timezone)
      )
    }
  }, [
    initialFilters?.amountFrom,
    initialFilters?.spam,
    initialFilters?.amountTo,
    initialFilters?.direction,
    initialFilters?.from,
    initialFilters.to,
    initialFilters?.includeTokens?.[0]?.id,
    initialFilters?.scoreFrom,
    initialFilters?.scoreTo,
    initialFilters?.counterpartyId,
    initialFilters?.counterpartyAddressId,
    initialFilters?.counterpartyName,
  ])
}

const TransactionEvmFiltersContainerLocal: React.FC<
  PropsWithChildren<TransactionEvmFiltersParams>
> = ({ children, counterpartyType }) => {
  useInitFilters()
  useSetFilters(counterpartyType)

  return <>{children}</>
}

export const TransactionEvmFiltersContainer = observer(
  TransactionEvmFiltersContainerLocal
)
