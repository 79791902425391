import { injectable } from 'inversify'

import { NodeAttributes } from '@clain/graph'
import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'
import { roundToPrecision } from '@clain/core/utils/math'
import type { ScoreRounded } from 'packages/core/ui-kit'

import ProbeNode from './ProbeNode'

import { createAlertAddon } from './ProbeNode.utils'
import { AddressNodeDataUtxo } from '../../types'

const CORE_RADIUS = 22
const CORE_BACKGROUND_COLOR = '#fff'
const SCORE_ORBIT_WIDTH = 4
const LABEL_ORBIT_WIDTH = 42
const LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const LABEL_COLOR = 'rgba(15, 19, 27, 1)'
const LABEL_SIZE = 12
const LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const LABEL_BORDER_RADIUS = 4
const CLUSTER_LABEL_ORBIT_WIDTH = 58
const CLUSTER_LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const CLUSTER_LABEL_COLOR = 'rgba(99, 123, 171, 1)'
const CLUSTER_LABEL_SIZE = 12
const CLUSTER_LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const CLUSTER_LABEL_BORDER_RADIUS = 4
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'
const SCORE_LABEL_ORBIT_SIZE = CORE_RADIUS
const GHOSTED_OPACITY = 0.3

@injectable()
export class AddressProbeNodeUtxo<
  T extends AddressNodeDataUtxo = AddressNodeDataUtxo,
> extends ProbeNode<T> {
  protected generateAttributes() {
    const scoreBackgroundColor =
      this.data.score != null
        ? this.theme.getToken([
            'score',
            'tag',
            `score${roundToPrecision(this.data.score) as ScoreRounded}`,
            'background',
            'color',
          ])
        : this.theme.getToken([
            'node',
            'transaction',
            'address',
            'border',
            'color',
          ])

    const scoreTextColor = this.theme.getToken([
      'score',
      'tag',
      `score${roundToPrecision(this.data.score) as ScoreRounded}`,
      'text',
      'color',
    ])

    const noAddress = !this.data.address || this.data.address.startsWith('7')
    const label = noAddress
      ? 'unable to decode'
      : this.data.address?.slice(0, 10)
    const iconSize = parseInt(this.theme.getToken(['icon', 'md', 'size']))

    const attributes = {
      size: CORE_RADIUS,
      fill: CORE_BACKGROUND_COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      locked: this.locked === true ? true : false,
      shape: 'circle',
      linkType: 'master',
      icon: icon({ variant: 'pin' }),
      iconColor: this.theme.getToken([
        'icon',
        'on',
        'background',
        'variant1',
        'color',
      ]),
      iconWidth: iconSize,
      iconHeight: iconSize,
      border: {
        color: OUTER_BORDER_COLOR,
        width: 0,
      },
      orbits: [
        {
          size: CORE_RADIUS,
          border: {
            color: scoreBackgroundColor,
            opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
            width: SCORE_ORBIT_WIDTH,
          },
        },
        {
          size: SCORE_LABEL_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 0.5,
              satellite: {
                type: 'label',
              },
            },
          ],
          virtual: true,
        },
        {
          size: CORE_RADIUS + 3,
          locations: [
            {
              angle: Math.PI * 0.9,
              satellite: {
                type: 'icon',
                icon: icon({ variant: this.data.currency }),
                width: 20,
                height: 20,
                fill: this.layoutSettingsState.state.graphBackgroundColor,
                shape: 'circle',
                border: {
                  width: 2,
                  color: this.layoutSettingsState.state.graphBackgroundColor,
                },
              },
            },
          ],
          virtual: true,
        },
        {
          size: LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
              },
            },
          ],
        },
        {
          size: CLUSTER_LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: CLUSTER_LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
                color: CLUSTER_LABEL_COLOR,
                fontSize: CLUSTER_LABEL_SIZE,
                fill: CLUSTER_LABEL_BACKGROUND_COLOR,
                borderRadius: CLUSTER_LABEL_BORDER_RADIUS,
                padding: [4, 2],
              },
            },
          ],
        },
      ],
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    if (this.layers.score && this.data.score) {
      attributes.orbits[1].locations[0].satellite = {
        type: 'label',
        fontSize: 10,
        color: scoreTextColor,
        text: `${this.data.score.toFixed(2)}`,
        fill: scoreBackgroundColor,
        borderRadius: 4,
        padding: [3, 1],
      }
    }

    if (this.layers.addressClusterLabel) {
      attributes.orbits[4].locations[0].satellite = {
        type: 'label',
        text: this.data.entity?.name || `${this.data.clusterId}`,
        color: CLUSTER_LABEL_COLOR,
        fontSize: CLUSTER_LABEL_SIZE,
        fill: CLUSTER_LABEL_BACKGROUND_COLOR,
        borderRadius: CLUSTER_LABEL_BORDER_RADIUS,
        padding: [2, 1],
      }
    }

    if (this.layers.addressLabel) {
      attributes.orbits[3].locations[0].satellite = {
        type: 'label',
        text: label,
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [4, 2],
        ...createAlertAddon({
          theme: this.theme,
          count:
            this.data?.alertCount?.seen + this.data?.alertCount?.markedSeen,
          state:
            this.data?.alertCount?.seen > 0
              ? 'triggered'
              : this.data?.alertCount?.active
                ? 'normal'
                : 'inactive',
        }),
      }
    }

    return attributes
  }
}
