import { useTranslation } from 'react-i18next'
import { ComparatorsValueFilterValues } from './ComparatorsValueFilter.types'

export const useComparatorsValidation = () => {
  const { t } = useTranslation()

  const validateBetweenValues = (values: ComparatorsValueFilterValues) => {
    let error = ''

    const [first, second] = values
    if (!first || !second) {
      error = t('platform:enterNumbers')
    }

    if (+first >= +second) {
      error = t('platform:firstValueLessThanSecond')
    }

    return error
  }

  const validateValue = (values: ComparatorsValueFilterValues) => {
    let error = ''

    const [first] = values
    if (!first) {
      error = t('platform:enterNumber')
    }

    return error
  }

  return {
    validateBetweenValues,
    validateValue,
  }
}
