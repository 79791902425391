import { action, makeObservable, computed, observable, toJS } from 'mobx'
import { isEmpty } from 'ramda'

import type { Alert, AlertEvents, Alerts, AlertCounts } from '../types'
import {
  INIT_ALERT_EVENTS_STATE,
  INIT_ALERT_STATE,
} from './AlertState.constants'
import type { AlertInitState, AlertEventsInitState } from './AlertState.types'

export class AlertState {
  public id: string

  @observable public activeAlertHashKey?: string
  @observable public alerts: AlertInitState = INIT_ALERT_STATE
  @observable public events: AlertEventsInitState = INIT_ALERT_EVENTS_STATE
  @observable public counts: AlertCounts

  constructor() {
    makeObservable(this)
  }

  public setCaseId(id: string) {
    this.id = id
  }

  @action.bound
  public clear() {
    this.id = undefined
    this.activeAlertHashKey = undefined
    this.alerts = INIT_ALERT_STATE
    this.events = INIT_ALERT_EVENTS_STATE
  }

  public getAlertsByKey(hashKey: string) {
    return this.alerts.data[hashKey]?.length ? this.alerts.data[hashKey] : []
  }

  @action.bound
  public setAlerts(alerts: Alerts, hashKey?: string) {
    const restData = isEmpty(toJS(this.alerts.data)) ? {} : this.alerts.data
    this.alerts = {
      data: {
        ...restData,
        [hashKey || this.activeAlertHashKey]: alerts,
      },
      loading: false,
      loadingMain: false,
    }
  }

  @action.bound
  public putAlert(alert: Alert) {
    const alerts = this.getAlerts?.length ? [...this.getAlerts, alert] : [alert]

    this.setAlerts(alerts)
    this.setCounts({
      ...this.counts,
      alerts,
    })
  }

  @action.bound
  public putAlertByKey(alert: Alert, hashKey: string) {
    const alerts = [...this.getAlertsByKey(hashKey), alert]

    this.setAlerts(alerts, hashKey)
    this.setCounts({
      ...this.counts,
      alerts,
    })
  }

  @computed public get getAlerts() {
    return this.activeAlertHashKey
      ? this.alerts.data[this.activeAlertHashKey]
      : []
  }

  @action.bound
  public isAllowedFetchAlerts(hash: string) {
    return !this.alerts.data[hash]?.length
  }

  @action.bound
  public setIsLoadingAlerts() {
    this.alerts.loading = true
  }

  @action.bound
  public setEvents(events: AlertEvents) {
    this.events = { data: events, loading: false, loadingMain: false }
  }

  @action.bound
  public resetEvents() {
    this.events.data = INIT_ALERT_EVENTS_STATE['data']
  }

  @action.bound
  public setIsLoadingEvents(loading: boolean) {
    this.events.loading = loading
  }

  @action.bound
  public setCounts(counts: AlertCounts) {
    this.counts = counts
  }

  @action.bound
  public setActiveAlertHashKey(alertHashKey: string) {
    this.activeAlertHashKey = alertHashKey
  }

  @action.bound
  public updateAlert(index: number, updater: (oldAlert: Alert) => Alert) {
    const alerts = [
      ...this.getAlerts.slice(0, index),
      updater(this.getAlerts[index]),
      ...this.getAlerts.slice(index + 1),
    ]

    this.setAlerts(alerts)
    this.setCounts({
      ...this.counts,
      alerts,
    })
  }

  @action.bound
  public removeAlert(index: number) {
    const alerts = [
      ...this.getAlerts.slice(0, index),
      ...this.getAlerts.slice(index + 1),
    ]

    this.setAlerts(alerts)
    this.setCounts({
      ...this.counts,
      alerts,
    })
  }
}
