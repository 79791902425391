import { FILTER_ASSET_KEY } from '@platform/components/BlockchainFilter/constants'
import { AssetFilter as AssetFilterDI } from '@platform/components/BlockchainFilter/containers/Filters/AssetFilter'
import { useAddressesSearchViewModel } from '@platform/components/BlockchainFilter/context'

export const AssetFilter = {
  Container: () => {
    return (
      <AssetFilterDI.Container
        tokens={useAddressesSearchViewModel().tokens}
        filterKey={FILTER_ASSET_KEY}
      />
    )
  },
  Menu: () => {
    return (
      <AssetFilterDI.Menu
        tokens={useAddressesSearchViewModel().tokens}
        filterKey={FILTER_ASSET_KEY}
      />
    )
  },
}
