import {
  ADDRESSES_FILTERS_INIT_STATE,
  useFiltersActions,
  setInitialState,
} from './AddressesFilters.service'
import { equals, pipe } from 'ramda'
import { useEffect } from 'react'

import { toJS } from 'mobx'
import {
  FILTER_AMOUNT_KEY,
  FILTER_CALENDAR_KEY,
} from '@platform/components/BlockchainFilter/constants/filtersKeys'
import { AddressesSearchRequestParams } from '@platform/apiServices'
import {
  normalizeCalendarToIntern,
  normalizeComparatorsToIntern,
} from '@platform/components/BlockchainFilter/utils/filterNormalize'

export const satoshiToBtc = (value?: number) => {
  if (!value) return null

  return value * Math.pow(10, -8)
}

export const normalizeInternFilters = (
  filters: Partial<AddressesSearchRequestParams>
) => {
  const normalizeInitFilters = pipe(
    () => ADDRESSES_FILTERS_INIT_STATE,
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, {
      from: filters.trx_time_from,
      to: filters.trx_time_to,
    }),
    normalizeComparatorsToIntern(FILTER_AMOUNT_KEY.root, {
      from: satoshiToBtc(filters.trx_total_amount_from),
      to: satoshiToBtc(filters.trx_total_amount_to),
    })
  )()

  return normalizeInitFilters
}

export const useInitFilters = (
  _initFilters: Partial<AddressesSearchRequestParams>,
  _defaultFilters: Partial<AddressesSearchRequestParams>
) => {
  const { setFilters } = useFiltersActions()
  const initFilters = toJS(_initFilters)
  const defaultFilters = toJS(_defaultFilters)

  useEffect(() => {
    if (initFilters) {
      const normalizeInitFilters = normalizeInternFilters(initFilters)
      const normalizeDefaultFilters = normalizeInternFilters(defaultFilters)

      if (!equals(normalizeInitFilters, ADDRESSES_FILTERS_INIT_STATE)) {
        setInitialState(normalizeDefaultFilters)
        setFilters(normalizeInitFilters)
      }
    }
  }, [
    initFilters.trx_time_from,
    initFilters.trx_time_to,
    initFilters.trx_total_amount_from,
    initFilters.trx_total_amount_to,
  ])
}
