export function getConfig() {
  const result = {
    PLATFORM_WS: null,
    PLATFORM_URL: null,
    PLATFORM_API: null,
    COMPLIANCE_URL: null,
    COMPLIANCE_WS: null,
    API: null,
    MINERVA_URL: null,
    INTERNAL_URL: null,
    GITHUB_URL: null,
    TITLE: process.env.TITLE,
    DOCS_URL: null,
    ENV: process.env.ENV,
    VERSION: null,
    AUTH_CLIENT_ID: process.env.AUTH_CLIENT_ID,
    AUTH_AUDIENCE: process.env.AUTH_AUDIENCE,
    AUTH_URL: process.env.AUTH_URL,
    APP_NAME: process.env.APP_NAME,
    template: function templateFromConfig(template: string) {
      return Object.keys(result).reduce(
        (compiled, key) => compiled.replaceAll(`%${key}%`, result[key]),
        template
      )
    },
  }

  if (process.env.APP_NAME === 'platform') {
    result['PLATFORM_WS'] = process.env.PLATFORM_WS
    result['PLATFORM_URL'] = process.env.PLATFORM_URL
    result['PLATFORM_API'] = process.env.PLATFORM_API
    result['DOCS_URL'] = process.env.DOCS_URL
    result['VERSION'] = process.env.VERSION
  }

  if (process.env.APP_NAME === 'compliance') {
    result['COMPLIANCE_WS'] = process.env.COMPLIANCE_WS
    result['COMPLIANCE_URL'] = process.env.COMPLIANCE_URL
    result['API'] = process.env.API
    result['PLATFORM_URL'] = process.env.PLATFORM_URL
  }

  if (process.env.APP_NAME === 'minerva') {
    result['MINERVA_URL'] = process.env.MINERVA_URL
    result['API'] = process.env.API
  }

  if (process.env.APP_NAME === 'internal') {
    result['INTERNAL_URL'] = process.env.INTERNAL_URL
    result['GITHUB_URL'] = process.env.GITHUB_URL
    result['PLATFORM_URL'] = process.env.PLATFORM_URL
    result['API'] = process.env.API
    result['DOCS_URL'] = process.env.DOCS_URL
  }

  return result
}

export default function useConfig() {
  return getConfig()
}
