import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFilterSlice } from '../TransactionEvmFilters.service'
import { ScoreFilter, ScoreFilterMenu } from '../../../../../Filters'
import { useTransactionStaticStore } from '../../context'

export const ScoreFilterMenuContainer = () => {
  const { t } = useTranslation()
  const [score, { setFilter }] = useFilterSlice('score.data')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <ScoreFilterMenu
      onSelect={setFilter}
      score={score}
      size={transactionStaticStore.current.filterSize}
      title={t('platform:counterpartyScore')}
    />
  )
}

export const ScoreFilterContainer = () => {
  const { t } = useTranslation()
  const [score] = useFilterSlice('score.data')
  const [, { resetFilter }] = useFilterSlice('score')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <ScoreFilter
      score={score}
      size={transactionStaticStore.current.filterSize}
      clearFilter={resetFilter}
      filterLable={t('platform:counterpartyScore')}
      menuComponent={<ScoreFilterMenuContainer />}
    />
  )
}
