import React from 'react'
import {
  Checkbox,
  Icon,
  IconButton,
  Stack,
  SurfaceContainer,
  TextField,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { useTranslation } from 'react-i18next'
import { type CreatePresetCardProps } from './PresetCard.types'
import { useKey } from 'react-use'
import * as S from './PresetCard.styles'

export const CreatePresetCard = ({
  onSubmit,
  onClose,
}: CreatePresetCardProps) => {
  const { t } = useTranslation()
  const [presetName, setPresetName] = React.useState('')
  const [monitoring, setMonitoring] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const handlePresetNameChange = (value: string) => {
    setPresetName(value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    await onSubmit({ name: presetName, monitoring })
    setIsLoading(false)
    onClose()
  }

  useKey('Enter', handleSubmit, undefined, [presetName, monitoring, onSubmit])

  return (
    <SurfaceContainer
      borderRadius={'md'}
      boxShadow={'elevation200'}
      spaces={['none', 'sm', 'md', 'lg']}
    >
      <Stack align={'center'} justify={'space-between'} gap={'xs'}>
        <TextField
          autoFocus
          value={presetName}
          onChange={handlePresetNameChange}
          autoComplete={'off'}
          fullWidth
          placeholder={t('platform:nameNewPreset')}
          size={'lg'}
        />
        <Stack align={'center'} fullWidth={false}>
          <IconButton
            isLoading={isLoading}
            onClick={handleSubmit}
            disabled={!presetName || isLoading}
            variant={'text'}
            size={'sm'}
            iconVariant={'Check'}
            color={'primary'}
          />
          <IconButton
            onClick={onClose}
            variant={'text'}
            size={'sm'}
            iconVariant={'Close'}
            color={'critical'}
          />
        </Stack>
      </Stack>
      <S.CreatedCardMetaSection align={'center'} gap={'sm'}>
        {/* TODO: add monitoring checkbox after API will be ready */}
        {/* <Checkbox
          size={'xxs'}
          value={monitoring}
          onChange={(val) => setMonitoring(val)}
        />
        <TypographyNew variant={'body200Normal'} color="onBackgroundVariant1">
          {t('platform:monitorTransactions')}
        </TypographyNew>
        <Tooltip maxWidth={240} content={t('platform:monitoringInfo')}>
          <div>
            <Icon
              variant={'Info'}
              size={'xxs'}
              color={'onBackgroundVariant1'}
            />
          </div>
        </Tooltip>*/}
      </S.CreatedCardMetaSection>
    </SurfaceContainer>
  )
}
