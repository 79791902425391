import { ContainerModule, interfaces } from 'inversify'
import {
  ITransactionFilterPresets,
  TransactionFilterPresetsViewModel,
} from '@platform/modules/transactionFilterPresets'
import { DATA_TRANSACTIONS_SEARCH_VIEW_MODEL } from '@platform/components/BlockchainFilter/di/transactions/constants'

const initializeModule = (bind: interfaces.Bind) => {
  bind<ITransactionFilterPresets>(
    DATA_TRANSACTIONS_SEARCH_VIEW_MODEL.TransactionFilterPresetsViewModel
  ).to(TransactionFilterPresetsViewModel)
}

export const transactionFilterPresetsViewModel = new ContainerModule(
  initializeModule
)
