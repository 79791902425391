import {
  DefaultUtxoTableConfig,
  TableColumnConfig,
  TransactionsUtxoTableItem,
} from './TransactionsUtxoTable.types'
import { BaseLink } from '@clain/core/Link'
import { TransactionFeatureTags } from '../../../../ProbeSandbox/ui/FeautureTags'
import {
  CopyIcon,
  EllipsisTypography,
  EllipsisTypographyBlock,
  Score,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'
import { TableSortButton, AssetsListShort } from '@clain/core/ui-kit'
import AssetsRow from '../../../../ProbeSandbox/ui/AssetsRow'
import { fromUnixTime } from 'date-fns'
import classnames from 'classnames/bind'
import styles from './TransactionsUtxoTable.scss'
import * as S from './TransactionsUtxoTable.styles'
import { Amount, Tooltip } from '@clainio/web-platform'
import { TranscationInfo } from '../components'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  isDefaultTokenId,
} from '../../../../ProbeSandbox/utils/convertTokenBalances'
import { FoggyStyled } from '../TransactionTable.styles'
import { AssetFilterPopover } from '@platform/components/EntitiesTable/ui'
import { i18next } from '@platform/i18Manager'

const cx = classnames.bind(styles)

export const getDefaultUtxoTableConfig = ({
  filters,
  showInUSD,
  isLoading,
  coinType,
  formatDate,
  formatMoney: formatMoneySettings,
  onChangeSort,
}: DefaultUtxoTableConfig<TransactionsUtxoTableItem>) => {
  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD || filters?.includeTokens?.[0]?.id == null
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
        ? {
            value: amount,
            currency: coinType,
          }
        : {
            value: amount,
            decimals: filters?.includeTokens[0].decimals,
            code: filters?.includeTokens[0].symbol,
          }
  }

  const defaultConfig: Record<
    | 'transaction'
    | 'features'
    | 'direction'
    | 'counterparty'
    | 'amount'
    | 'tokens'
    | 'fee'
    | 'fee_byte'
    | 'time',
    TableColumnConfig<TransactionsUtxoTableItem>
  > = {
    transaction: {
      name: 'transaction',
      width: 1.2,
      gap: 'md',
      spaces: ['none', 'none', 'none', 'xl'],
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {i18next.t('platform:transaction')}
        </TypographyNew>
      ),
      render: (item) => (
        <>
          <FoggyStyled color="base" content={<CopyIcon value={item.hash} />}>
            <TranscationInfo
              hasDemix={item?.hasDemix}
              hasCrossChainSwap={item?.hasCrossChainSwap}
            />
            <EllipsisTypographyBlock as={Stack} fullWidth>
              <Tooltip content={item.hash}>
                <EllipsisTypography
                  as={BaseLink}
                  to="/:coin/explorer/transaction/:hash"
                  params={{ hash: item.hash, coin: coinType }}
                  target="_blank"
                  variantAs="link"
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  type="center"
                >
                  {item.hash}
                </EllipsisTypography>
              </Tooltip>
            </EllipsisTypographyBlock>
          </FoggyStyled>
        </>
      ),
      renderFooter: null,
    },
    features: {
      name: 'features',
      width: 0.75,
      minWidth: '140px',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {i18next.t('platform:features')}
        </TypographyNew>
      ),
      render: (transaction) => (
        <TransactionFeatureTags transaction={transaction} />
      ),
    },
    direction: {
      name: 'direction',
      width: 0.5,
      justify: 'center',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {i18next.t('platform:direction')}
        </TypographyNew>
      ),
      render: ({ direction }) => (
        <TypographyNew
          variant="body200Normal"
          transform="capitalize"
          color="onBackgroundVariant1"
        >
          {i18next.t(`platform:${direction}`)}
        </TypographyNew>
      ),
    },
    counterparty: {
      name: 'counterparty',
      width: 1,
      gap: 'md',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {i18next.t('platform:counterparty')}
        </TypographyNew>
      ),
      render: ({ counterparties }) => {
        if (!counterparties?.length) return null

        const largestCounterparty = counterparties[0]
        const othersCpCount = counterparties.length - 1
        const largestCounterpartyClusterId = largestCounterparty.clusterId

        return (
          <>
            <Score value={largestCounterparty.score} size="xs" />
            {!largestCounterpartyClusterId ? (
              'Processing...'
            ) : largestCounterpartyClusterId === 0 ? (
              'Mining'
            ) : (
              <S.ClusterContainer align={'center'} gap={'sm'}>
                {largestCounterparty?.icon && (
                  <S.ClusterEntityIcon $url={largestCounterparty?.icon} />
                )}
                <EllipsisTypography
                  as={BaseLink}
                  to="/:coin/cluster/:clusterId"
                  params={{
                    clusterId: largestCounterpartyClusterId,
                    coin: coinType,
                  }}
                  target="_blank"
                  variantAs="link"
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  type="end"
                >
                  {`${
                    largestCounterparty.name || largestCounterpartyClusterId
                  }`}
                </EllipsisTypography>
              </S.ClusterContainer>
            )}
            {othersCpCount > 0 && (
              <S.CounterpartiesOthersCount
                variant="body200NormalCode"
                color="onBackgroundVariant1"
              >
                +{othersCpCount} other
              </S.CounterpartiesOthersCount>
            )}
          </>
        )
      },
    },
    amount: {
      name: 'amount',
      width: 1,
      justify: 'end',
      gap: 'md',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:amount')}
          </TypographyNew>
          <TableSortButton
            name="amount"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ amount, amountUsd }) => {
        const formatAmountConfig = getAmountConfig(amount, amountUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatAmountConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatAmountConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatAmountConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
      renderFooter: null,
    },
    tokens: {
      name: 'tokens',
      width: 0.5,
      justify: 'center',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {i18next.t('platform:assets')}
        </TypographyNew>
      ),
      render: ({ tokens }) => (
        <AssetFilterPopover
          maxHeight={300}
          content={
            <AssetsListShort
              assets={tokens}
              isDefaultTokenId={isDefaultTokenId}
            />
          }
          anchor={
            <span>
              <AssetsRow
                className={cx('AssetsRow')}
                buttonMode
                tokens={tokens}
              />
            </span>
          }
        />
      ),
    },
    fee: {
      name: 'fee',
      width: 1,
      justify: 'end',
      gap: 'md',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:fee')}
          </TypographyNew>
          <TableSortButton
            name="fee"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ fee }) => {
        if (fee == null) return null

        const fromaFeeConfig = {
          value: fee,
          currency: coinType,
        }

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...fromaFeeConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    fee_byte: {
      name: 'fee_byte',
      width: 1,
      justify: 'end',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:feeVByte')}
          </TypographyNew>
          <TableSortButton
            name="fee_byte"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ feeByte }) => {
        if (feeByte == null) return null

        const fromaFeeConfig = {
          value: feeByte,
          precision: 2,
          code: 'sat/vB',
        }

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...fromaFeeConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    time: {
      name: 'time',
      width: 1,
      justify: 'end',
      gap: 'md',
      spaces: ['none', 'xl'],
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:time')}
          </TypographyNew>
          <TableSortButton
            name="time"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ time }) => (
        <TypographyNew color="onBackgroundBase" variant="body200NormalCode">
          {formatDate(fromUnixTime(time), 'date-time-numeric')}
        </TypographyNew>
      ),
    },
  }
  return { defaultConfig }
}
