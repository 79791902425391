import { Button } from '@clain/core/ui-kit'
import { UnsavedGraphModal } from './UnsavedGraphModal'
import { ReactComponent as ChangeFolderIcon } from '@clain/core/assets/change_folder.svg'
import React, { useState } from 'react'
import classnames from 'classnames/bind'
import styles from './index.scss'
import { useDocumentVisibilityChange } from '../../../../hooks'

import { useSessionStorage } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export const SaveToCaseModalButton = ({ show, probeId }) => {
  const { t } = useTranslation()
  const [visibleModal, setVisibleModal] = useState(false)
  const [skippedProbeId, setSkippedProbeId] = useSessionStorage<string>(
    'UnsavedGraphModal:ProbeId',
    null
  )

  useDocumentVisibilityChange(() => {
    if (visibleModal) return

    if (skippedProbeId !== String(probeId)) {
      setVisibleModal(true)
    }
  })

  const showSaveModal = () => {
    show()
    setVisibleModal(false)
  }

  const saveNotShowAgain = () => {
    setSkippedProbeId(String(probeId))
    setVisibleModal(false)
  }

  return (
    <>
      <Button
        onClick={showSaveModal}
        size={'md'}
        variant="outline"
        color={'secondary'}
        className={cx('targetButton')}
        endCustomIcon={ChangeFolderIcon}
      >
        {t('platform:saveToCase')}
      </Button>
      <UnsavedGraphModal
        visible={visibleModal}
        saveNotShowAgain={saveNotShowAgain}
        onClose={() => {
          setVisibleModal(false)
        }}
        showSaveToCaseModal={showSaveModal}
      />
    </>
  )
}
