import Pagination, { PaginationProps } from '@clain/core/Pagination'
import { InjectComponentModel } from '@clain/core/utils'
import { useEntityPagination } from '../hooks'

export const BlockchainFilterPagination = InjectComponentModel<PaginationProps>(
  Pagination,
  {
    useFactory: useEntityPagination,
  }
)
