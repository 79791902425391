import { IconButton, Tooltip } from '@clainio/web-platform'
import { useTranslation } from 'react-i18next'

export const ExpandButton = ({
  onClick,
  itemCount,
}: {
  onClick: () => void
  itemCount: number
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      content={t('platform:expandSimilar', '', {
        count: itemCount,
        type: `${t('platform:address', '', { count: itemCount })}`?.toLowerCase(),
      })}
    >
      <IconButton
        variant="text"
        color="primary"
        size={'sm'}
        onClick={onClick}
        iconVariant="PlusCircleFilled"
      />
    </Tooltip>
  )
}
