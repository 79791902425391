import { useTranslation } from 'react-i18next'
import { CounterTabOption } from '@clain/core/ui-kit'

interface TabOptionsProps {
  alerts: Pick<CounterTabOption<string>, 'counterValue' | 'counterVariant'>
}

export const useTabOptions = ({
  alerts,
}: TabOptionsProps): CounterTabOption<string>[] => {
  const { t } = useTranslation()

  return [
    {
      value: 'timeline',
      children: t('platform:timeline'),
      counterValue: 0,
      counterVariant: 'default' as const,
    },
    {
      value: 'graphs',
      children: t('platform:graphs'),
      counterValue: 0,
      counterVariant: 'important' as const,
    },
    {
      value: 'files',
      children: t('platform:files'),
      counterValue: 0,
      counterVariant: 'default' as const,
    },
    {
      value: 'alerts',
      children: t('platform:monitoring'),
      counterValue: alerts.counterValue ?? 0,
      counterVariant: alerts.counterVariant,
    },
  ]
}
