import React, { useEffect } from 'react'
import { Modal } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './ProbeGenerateReport.scss'
import { ProbeGenerateReportButton } from './ProbeGenerateReportButton'
import { ProbeGenerateModalContent } from './ProbeGenerateModalContent'
import reportFacade from './ProbeGenerateReport.facade'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'
const cx = classnames.bind(styles)

export const ProbeGenerateReport = withErrorBoundary(() => {
  useEffect(() => {
    reportFacade.init()
    return () => {
      reportFacade.clear()
    }
  }, [])

  return (
    <Modal
      size={'lg'}
      className={cx('ProbeGenerateReport')}
      target={ProbeGenerateReportButton}
    >
      {ProbeGenerateModalContent}
    </Modal>
  )
})
