import {
  useClusterFilterData,
  useClusterSearchData,
  useSelectedClusterFilterData,
} from './useClusterFilterData'
import {
  SearchListFilter,
  SearchListFilterMenu,
  ScoreMenuListItem,
} from '../../../../../Filters'
import { useTransactionStaticStore } from '../../context'
import { useTranslation } from 'react-i18next'

export const ClusterFilterMenuContainer = () => {
  const { menuListData, onSearch } = useClusterSearchData()
  const { selectedItems, setFilter } = useClusterFilterData()
  const counterpartyStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <SearchListFilterMenu
      onSelect={setFilter}
      onSearch={onSearch}
      selectedItems={selectedItems}
      list={menuListData}
      size={counterpartyStaticStore.current.filterSize}
      searchPlaceholder={t('platform:findCluster')}
      searchInfoText={t('platform:findClusterInfo')}
      renderItem={ScoreMenuListItem}
    />
  )
}

export const ClusterFilterContainer = () => {
  const { isMultiple, multipleLabel, resetFilter } = useClusterFilterData()
  const firstSelected = useSelectedClusterFilterData()
  const counterpartyStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <SearchListFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      size={counterpartyStaticStore.current.filterSize}
      label={t('platform:counterpartyCluster')}
      icon="ClusterAlt"
      firstSelectedItem={firstSelected}
      clearFilter={resetFilter}
      menuComponent={<ClusterFilterMenuContainer />}
    />
  )
}
