import {
  AssetChip,
  ColDeprecated,
  CopyCursorContainer,
  EllipsisTypography,
  RowDeprecated,
  Stack,
  Tag,
  Tooltip,
} from '@clain/core/ui-kit'

import * as S from './TransactionAddressEvmTable.styles'

import {
  TransactionAddressEvmTableItem,
  TransactionAddressEvmTableKey,
  DefaultEvmTableConfig,
  TableColumnConfig,
} from './TransactionAddressEvmTable.types'
import { CoinTypeEVM } from '../../../../../types/coin'
import { getEVMTagConfig } from '../../../../../utils/getEVMTagConfig'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'
import { TransactionToken } from '@platform/components/ProbeSandbox/types/converted/TransactionToken'
import {
  RowAddressItem,
  RowLayout,
  RowLayoutProps,
} from '@platform/components/TransactionBlock'
import type { RowAddressItemProps } from '@platform/components/TransactionBlock'

const itemLayoutConfig: Record<CoinTypeEVM, RowLayoutProps['options']> = {
  eth: {
    itemGrow: 4,
    extraGrow: 4,
  },
  bnb: {
    itemGrow: 4,
    extraGrow: 4,
  },
  trx: {
    itemGrow: 4,
    extraGrow: 4,
  },
}

const ADDRESS_ITEM_CONFIG: Record<
  CoinTypeEVM,
  Pick<
    RowAddressItemProps,
    'addressMaxWidth' | 'clusterWidthPercent' | 'addressWidthPercent'
  >
> = {
  eth: {
    addressMaxWidth: 310,
    addressWidthPercent: 75,
    clusterWidthPercent: 25,
  },
  bnb: {
    addressMaxWidth: 310,
    addressWidthPercent: 75,
    clusterWidthPercent: 25,
  },
  trx: {
    addressMaxWidth: 253,
    addressWidthPercent: 75,
    clusterWidthPercent: 25,
  },
}

export const getEvmTableConfig = ({
  formatMoney: formatMoneySettings,
  currency,
}: DefaultEvmTableConfig<TransactionAddressEvmTableItem>) => {
  const renderItem = (data: any) => {
    const newData = data || ({} as typeof data)
    if (!data) {
      newData.clusterId = null
      newData.contract = true
    }

    const { label, ...tagProps } = getEVMTagConfig(
      newData.contract ? 'CA' : 'EOA'
    )

    return (
      <RowAddressItem
        {...ADDRESS_ITEM_CONFIG[currency]}
        wrapper={S.RowAddressItemEVMStyled}
        score={newData.score}
        address={newData.hash}
        blockchain={currency}
        clusterId={newData.clusterId}
        clusterName={newData.name}
        rightChildren={
          <RowDeprecated gap={1.25}>
            <S.TagsContainerStyled>
              {data && (
                <Tag {...tagProps} size={'xs'}>
                  {label}
                </Tag>
              )}
            </S.TagsContainerStyled>
          </RowDeprecated>
        }
      />
    )
  }

  const getAmount = (
    amountUsd: number | string,
    amount: number | string,
    token: TransactionToken | null
  ) => {
    const tokenConfig =
      token?.decimals != null
        ? {
            decimals: Number(token.decimals),
          }
        : {}
    const amountConfig = {
      ...tokenConfig,
      value: Number(amount),
      currency,
    }

    const amountUsdConfig = {
      value: amountUsd,
      currency: 'usd',
    }

    const amountFormatted = formatMoneySettings({
      formats: GENERAL_NUMBER_NOTATION,
      ...amountConfig,
      code: '',
    })

    const amountUsdFormatted = formatMoneySettings({
      formats: GENERAL_NUMBER_NOTATION,
      ...amountUsdConfig,
      code: '$',
      position: 'start',
      spaces: false,
    })

    const tickerValue = token?.symbol != null ? token.symbol.toUpperCase() : ''
    const assetChipVariant =
      !token?.spam && !token?.scam ? 'licit' : token?.scam ? 'scam' : 'spam'

    return (
      <Stack gap="xs" align="center">
        <S.AmountContainerStyled>
          <S.AmountStyled>
            <CopyCursorContainer
              value={formatMoney({
                ...amountConfig,
                precision: 64,
                code: '',
                minimumSignificantDigits: 1,
              })}
            >
              <Tooltip
                content={formatMoney({
                  ...amountConfig,
                  precision: 64,
                  minimumSignificantDigits: 1,
                  code: tickerValue,
                })}
              >
                <RowDeprecated gap={0.5} align={'right'} fullWidth>
                  <EllipsisTypography
                    variant={'body200NormalCode'}
                    color={'onBackgroundBase'}
                  >
                    {amountFormatted}
                  </EllipsisTypography>
                  <S.AssetChipContainer>
                    <AssetChip
                      fullWidth
                      variant={assetChipVariant}
                      icon={token?.icon}
                      color={isDefaultTokenId(token?.id) ? 'original' : 'grey'}
                      id={token?.id}
                      blockchain={currency}
                      interactive={false}
                    >
                      {tickerValue}
                    </AssetChip>
                  </S.AssetChipContainer>
                </RowDeprecated>
              </Tooltip>
            </CopyCursorContainer>
          </S.AmountStyled>
        </S.AmountContainerStyled>
        <S.AmountUsdContainerStyled>
          <S.AmountUSDStyled>
            <CopyCursorContainer
              value={formatMoney({
                ...amountUsdConfig,
                precision: 64,
                code: '',
                minimumSignificantDigits: 1,
              })}
            >
              <Tooltip
                content={formatMoney({
                  ...amountUsdConfig,
                  precision: 64,
                  minimumSignificantDigits: 1,
                })}
              >
                <RowDeprecated gap={0.5} align={'right'} fullWidth>
                  <EllipsisTypography
                    variant={'body200NormalCode'}
                    color={'onBackgroundBase'}
                  >
                    {amountUsdFormatted}
                  </EllipsisTypography>
                </RowDeprecated>
              </Tooltip>
            </CopyCursorContainer>
          </S.AmountUSDStyled>
        </S.AmountUsdContainerStyled>
      </Stack>
    )
  }

  const layoutConfig = itemLayoutConfig[currency]

  const defaultConfig: Record<
    TransactionAddressEvmTableKey,
    TableColumnConfig<TransactionAddressEvmTableItem>
  > = {
    item: {
      name: 'item',
      width: 1,
      render: (item) => {
        return (
          <ColDeprecated gap={0} style={{ width: '100%' }}>
            <RowLayout
              key={item.from.hash}
              options={layoutConfig}
              leftComponent={renderItem(item.from)}
              rightComponent={renderItem(item.to)}
              extraRightComponent={getAmount(
                item.amountUsd,
                item.amount,
                item.token
              )}
            />
          </ColDeprecated>
        )
      },
    },
  }

  return defaultConfig
}
