import { action, computed, makeObservable, observable } from 'mobx'

import { apiServicesStateFacade } from './ApiServicesStateFacade'
import { queryParamsAddressesSearchViewModel } from './QueryParamsTrxSearchViewModel'
import { addressesSearchViewModel } from './AddressesSearchViewModel'
import { ERROR } from '../../../../constants'
import { BlockTransactionUTXO } from '@platform/apiServices'
import { Token } from '@platform/components/ProbeSandbox/types/converted/TokenBalance'

export class DataAddressesSearchViewModel {
  private apiServicesStateFacade = apiServicesStateFacade
  private queryParamsTrxSearchViewModel = queryParamsAddressesSearchViewModel
  private transactionsSearchViewModel = addressesSearchViewModel
  @observable private _selectedTransactions: BlockTransactionUTXO[] = []

  constructor() {
    makeObservable(this)
  }

  @action
  public init = this.transactionsSearchViewModel.init

  @action
  public setSelectTransactions = (
    data: BlockTransactionUTXO,
    checked: boolean
  ) => {
    if (checked) {
      this._selectedTransactions = [...this._selectedTransactions, data]
    } else {
      this._selectedTransactions = this._selectedTransactions.filter(
        (transaction) => transaction.id !== data.id
      )
    }
  }

  @action
  public downloadCSV = () => {
    return this.transactionsSearchViewModel.downloadCSV()
  }
  public get isDownloadingCSV() {
    return this.transactionsSearchViewModel.isDownloadingCSV
  }

  @computed
  public get tokens(): Token[] {
    return []
  }

  @action
  public resetSelected = () => {
    this._selectedTransactions = []
  }

  @computed
  public get filters() {
    return this.apiServicesStateFacade.getApiParamsStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsInitialFilters() {
    return this.apiServicesStateFacade.getApiParamsInitialStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsUrlParams() {
    return this.queryParamsTrxSearchViewModel.getUrlParams
  }

  @computed.struct
  public get transactionsTableDefaultFilters() {
    return this.apiServicesStateFacade.getApiParamsDefaultStateByService(
      'transactions'
    )
  }

  public get filtersUpdate() {
    return this.apiServicesStateFacade.updateApiParamsStateByService(
      'transactions'
    )
  }

  @computed
  public get tableData() {
    return this.apiServicesStateFacade.getStateByService('transactions')?.data
      ?.data
  }

  @computed
  public get isTransactionsTableDataLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'LOADING'
    )
  }

  @computed
  public get isTransactionsTableDataIdle() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'IDLE'
    )
  }

  @computed
  public get isTransactionsTableDataSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'SUCCESS'
    )
  }

  @computed
  public get isTransactionsTableDataError() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'ERROR'
    )
  }

  @computed
  public get transactionsTableDataError() {
    const error =
      this.apiServicesStateFacade.getStateErrorByService('transactions')
    if (typeof error === 'string') {
      return error
    }
    return ERROR.networkError
  }

  @computed
  public get isTransactionsTableDataEmpty() {
    if (
      this.isTransactionsTableDataIdle ||
      this.isTransactionsTableDataLoading
    ) {
      return false
    }

    return !this.tableData?.transactions?.length
  }

  @computed
  public get isShowTopPanel() {
    if (
      this.isTransactionsTableDataIdle ||
      (this.isTransactionsTableDataLoading &&
        !this.tableData?.transactions?.length)
    ) {
      return false
    }

    return !!this.tableData?.transactions?.length
  }

  @computed
  public get isDisabledDownloadCsv() {
    return this.filters == null
  }

  @action
  public clear = () => {
    this.transactionsSearchViewModel.clear()
  }
}

export const dataAddressesSearchViewModel = new DataAddressesSearchViewModel()
