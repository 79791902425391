import { HttpResponse } from '@clain/core/http'
import { CoinType } from '@clain/core/ui-kit'
import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'
import {
  TransactionsBlockSearch,
  TransactionSearchRequestParams,
} from '@platform/apiServices'

const transactionsFacade = new APIServiceStateFacade<
  TransactionSearchRequestParams[],
  HttpResponse<TransactionsBlockSearch>
>(new APIServiceStateViewModel())

export const transactionsApiParams =
  new StateViewModel<TransactionSearchRequestParams>(
    {} as TransactionSearchRequestParams
  )

export const transactionState = new StateViewModel<{
  blockchain: CoinType
}>({
  blockchain: null,
})

export const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    transactions: transactionsFacade,
  },
  apiParams: {
    transactions: transactionsApiParams,
  },
})
