import React, { useCallback, useEffect, useMemo } from 'react'
import useFuse from '@clain/core/useFuse'
import { Token } from '../../ProbeSandbox/types/converted/TokenBalance'
import { partition } from 'ramda'
import {
  Divider,
  Icon,
  Spinner,
  Stack,
  TextField,
  Tooltip,
} from '@clain/core/ui-kit'
import { AssetFilterMenuListItem } from './AssetFilterMenuListItem'
import { AssetFilterMenuProps } from './AssetFilter.types'
import { usePopoverState } from '@clain/core/ui-kit'
import { HeaderFixedStyled } from './AssetFilterMenu.styles'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'

export const AssetFilterMenu = ({
  selectedTokens = [],
  tokenList = [],
  onSelect,
  size = 'md',
  onSearch,
  disabledStaticSearch = false,
  searchInfo,
  loading,
}: AssetFilterMenuProps) => {
  const parentRef = React.useRef()
  const { setOpen } = usePopoverState()
  const [inputValue, setInputValue] = React.useState('')
  const { t } = useTranslation()

  const handleSearch = (value: string) => {
    if (onSearch) {
      onSearch(value)
    }
    setInputValue(value)
  }

  const getAssets = useFuse(tokenList, {
    keys: ['name'],
    threshold: 0.4,
  })

  const handleSelect = (token: Token) => {
    setOpen(false)
    onSelect([token])
  }

  const isSelected = useCallback(
    (token: Token) =>
      selectedTokens && selectedTokens.some((t) => t.id === token.id),
    [selectedTokens]
  )

  const [selectedListTokens, restTokens] = useMemo(
    () =>
      partition(
        isSelected,
        disabledStaticSearch ? tokenList : getAssets(inputValue)
      ),
    [isSelected, disabledStaticSearch, tokenList, getAssets, inputValue]
  )

  const orderedList = [...selectedListTokens, ...restTokens]

  const rowVirtualizer = useVirtualizer({
    count: orderedList?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 32,
    overscan: 20,
  })

  const virtualItems = rowVirtualizer.getVirtualItems()

  useEffect(() => {
    return () => {
      onSearch?.('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ width: '300px' }}>
      <HeaderFixedStyled>
        <Stack padding={[size === 'sm' ? 'xs' : 'none', 'lg']} align="center">
          <TextField
            fullWidth
            size={size === 'sm' ? 'md' : 'lg'}
            placeholder={t('platform:selectAsset')}
            value={inputValue}
            variant="base"
            onChange={handleSearch}
          />
          {searchInfo && !loading && (
            <Tooltip content={searchInfo} maxWidth={182}>
              <span>
                <Icon
                  size={size === 'sm' ? 'xs' : 'sm'}
                  variant="InfoSmall"
                  color="onBackgroundVariant1"
                />
              </span>
            </Tooltip>
          )}
          {loading && <Spinner size="xxxs" color="grey2" />}
        </Stack>
        {virtualItems?.length || loading ? (
          <Divider type="line" color="base" spaces={'none'} />
        ) : null}
      </HeaderFixedStyled>
      {!loading && (
        <div
          ref={parentRef}
          style={{
            width: '100%',
            height:
              rowVirtualizer.getTotalSize() > 333
                ? '233px'
                : `${rowVirtualizer.getTotalSize()}px`,
            overflow: 'auto',
            contain: 'strict',
          }}
        >
          <div
            style={{
              height: rowVirtualizer.getTotalSize(),
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
              }}
            >
              {virtualItems?.map((virtualRow, index) => {
                const isSelected =
                  selectedListTokens.length >= virtualRow.index + 1
                const isLastSelected =
                  selectedListTokens.length === virtualRow.index + 1

                return (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                  >
                    {index === 0 && (
                      <Divider type="empty" spaces={['xxs', 'none']} />
                    )}
                    <AssetFilterMenuListItem
                      onSelect={handleSelect}
                      size={size}
                      {...orderedList[virtualRow.index]}
                    />
                    {isSelected && isLastSelected && (
                      <Divider
                        type="line"
                        color="base"
                        spaces={['xs', 'none']}
                      />
                    )}
                    {index + 1 === virtualItems.length && (
                      <Divider type="empty" spaces={['xxs', 'none']} />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
