import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import {
  AddressSearchResult,
  SearchResults as SearchResultsType,
  TransactionSearchResult,
} from '../../../vm/services/SearchService/SearchService.types'
import {
  AddressSearchCard,
  ClusterSearchCard,
  TransactionSearchCard,
} from './cards'

import styles from './index.scss'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CoinType } from '../../../../../types/coin'

const cx = classnames.bind(styles)

interface SearchRenderItem {
  content: React.ReactNode
  items?: Array<SearchRenderItem>
}

type HandleClickOnCluster = (clusterId: number, currency: CoinType) => void
type HandleClickOnAddress = (
  data: AddressSearchResult,
  currency: CoinType
) => void
type HandleClickOnTransaction = (
  data: TransactionSearchResult,
  currency: CoinType
) => void

interface SearchResultsProps {
  results?: Array<SearchResultsType>
  handleClickOnCluster: HandleClickOnCluster
  handleClickOnAddress: HandleClickOnAddress
  handleClickOnTransaction: HandleClickOnTransaction
}

const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  handleClickOnCluster,
  handleClickOnAddress,
  handleClickOnTransaction,
}) => {
  const { t } = useTranslation()
  const { addressesWithEntities, clusters, transactions } =
    React.useMemo(() => {
      const addressesWithEntities = new Map<number, SearchRenderItem>()
      const clusters = new Array<SearchRenderItem>()
      const transactions = new Array<SearchRenderItem>()

      results?.forEach((searchResults) => {
        if (searchResults.type === 'address') {
          searchResults.result.forEach((addressResult) => {
            if (!addressesWithEntities.has(addressResult.clusterId)) {
              addressesWithEntities.set(addressResult.clusterId, {
                content: (
                  <ClusterSearchCard
                    key={`entity_${addressResult.clusterId}`}
                    name={String(addressResult.name || addressResult.clusterId)}
                    currency={addressResult.currency}
                    score={addressResult.score}
                    onClick={() =>
                      handleClickOnCluster(
                        addressResult.clusterId,
                        addressResult.currency
                      )
                    }
                  />
                ),
                items: [],
              })
            }

            addressesWithEntities.get(addressResult.clusterId).items.push({
              content: (
                <AddressSearchCard
                  key={addressResult.address}
                  hash={addressResult.address}
                  currency={addressResult.currency}
                  score={addressResult.score}
                  onClick={() =>
                    handleClickOnAddress(addressResult, addressResult.currency)
                  }
                />
              ),
            })
          })
        }

        if (
          searchResults.type === 'cluster' ||
          searchResults.type === 'entity'
        ) {
          searchResults.result.forEach((clusterResult) => {
            clusters.push({
              content: (
                <ClusterSearchCard
                  key={`cluster_${clusterResult.clusterId}_${clusterResult.currency}`}
                  name={String(clusterResult.name || clusterResult.clusterId)}
                  currency={clusterResult.currency}
                  score={clusterResult.score}
                  onClick={() =>
                    handleClickOnCluster(
                      clusterResult.clusterId,
                      clusterResult.currency
                    )
                  }
                />
              ),
            })
          })
        }

        if (searchResults.type === 'transaction') {
          searchResults.result.forEach((transactionResult) => {
            transactions.push({
              content: (
                <TransactionSearchCard
                  key={transactionResult.hash}
                  hash={transactionResult.hash}
                  currency={transactionResult.currency}
                  onClick={() =>
                    handleClickOnTransaction(
                      transactionResult,
                      transactionResult.currency
                    )
                  }
                />
              ),
            })
          })
        }
      })

      return {
        addressesWithEntities: Array.from(addressesWithEntities.values()),
        clusters,
        transactions,
      }
    }, [results])

  if (!addressesWithEntities.length && !clusters.length && !transactions.length)
    return null

  return (
    <div className={cx('SearchResults')}>
      {addressesWithEntities.length > 0 && (
        <React.Fragment>
          <Container>
            <Typography variant="caption1" color="grey2">
              {t('platform:addressesWithEntities')}
            </Typography>
          </Container>
          {addressesWithEntities.map((item, index) => (
            <div className={cx('EntityWithAddresses')} key={index}>
              {item.content}
              {item.items.map((item) => item.content)}
            </div>
          ))}
        </React.Fragment>
      )}
      {clusters.length > 0 && (
        <React.Fragment>
          <Container>
            <Typography variant="caption1" color="grey2">
              {t('platform:clusters')}
            </Typography>
          </Container>
          {clusters.map((item) => item.content)}
        </React.Fragment>
      )}
      {transactions.length > 0 && (
        <React.Fragment>
          <Container>
            <Typography variant="caption1" color="grey2">
              {t('platform:transactions')}
            </Typography>
          </Container>
          {transactions.map((item) => item.content)}
        </React.Fragment>
      )}
    </div>
  )
}

export default SearchResults
