import type {
  IDeleteEntities,
  GraphEmitEvents,
  GraphEventAddEdge,
  GraphEventAddNode,
  GraphEventUpdateEdge,
  GraphEventUpdateNode,
  GraphEventUpdatePosition,
} from './GraphEvents.types'
import {
  clusterKey,
  transactionKey,
  transactionAddressKey,
  addressKey,
  osintKey,
  demixKey,
} from './utils'

export const groupEventsByEventType = (
  events: GraphEmitEvents[]
): {
  addNodes: GraphEventAddNode[]
  deleteEntities: IDeleteEntities
  addEdges: GraphEventAddEdge[]
  updateEdges: GraphEventUpdateEdge[]
  updateNodes: GraphEventUpdateNode[]
  updatePositions: GraphEventUpdatePosition[]
} => {
  return events.reduce(
    (acc, event) => {
      if (event.type === 'add_node') {
        return { ...acc, addNodes: [...acc.addNodes, event] }
      }

      if (event.type === 'update_node') {
        return { ...acc, updateNodes: [...acc.updateNodes, event] }
      }

      if (event.type === 'delete_node' || event.type === 'delete_edge') {
        return { ...acc, deleteEntities: [...acc.deleteEntities, event] }
      }

      if (event.type === 'add_edge') {
        return { ...acc, addEdges: [...acc.addEdges, event] }
      }

      if (event.type === 'update_edge') {
        return { ...acc, updateEdges: [...acc.updateEdges, event] }
      }

      if (event.type === 'update_position') {
        return { ...acc, updatePositions: [...acc.updatePositions, event] }
      }

      return acc
    },
    {
      addNodes: [],
      updateNodes: [],
      addEdges: [],
      updateEdges: [],
      deleteEntities: [],
      updatePositions: [],
    }
  )
}

export const removeDuplicateEvents = <T extends { key: string }>(
  events: T[]
): T[] => {
  const uniqueEvents = new Map<string, T>()

  events.forEach((event) => {
    if (!uniqueEvents.has(event.key)) {
      uniqueEvents.set(event.key, event)
    }
  })
  return Array.from(uniqueEvents.values())
}

export const applyEventsMeta = <T, M>(events: T, meta: M) => {
  return {
    events,
    meta,
  }
}

export const applyEntitiesMeta = <T, M>(entities: T, meta: M) => {
  return {
    entities,
    meta,
  }
}

export const nodesInProcessingKey = (events: GraphEmitEvents[]): string[] => {
  const keys: string[] = []

  events.forEach((event) => {
    if (event.type === 'add_node') {
      if (
        event.data.strategy === 'cluster' ||
        event.data.strategy === 'cluster-flow'
      ) {
        keys.push(
          clusterKey({ clusterId: event.data.clusterId }, event.data.currency)
        )
      } else if (event.data.strategy === 'transaction') {
        const data = event.data as any
        keys.push(
          data?.hash
            ? transactionKey(data)
            : transactionAddressKey(data.trxAddressData)
        )
      } else if (event.data.strategy === 'transaction-address') {
        keys.push(transactionAddressKey(event.data.trxAddressData))
      } else if (
        event.data.strategy === 'transaction-multiple' ||
        event.data.strategy === 'transaction-token'
      ) {
        keys.push(transactionKey(event.data))
      } else if (
        event.data.strategy === 'address' ||
        event.data.strategy === 'address-cluster'
      ) {
        keys.push(addressKey(event.data))
      } else if (event.data.strategy === 'osint') {
        keys.push(osintKey(event.data))
      } else if (event.data.strategy === 'demix') {
        keys.push(demixKey(event.data))
      }
    }

    if (event.type === 'delete_node') {
      keys.push(event.entity.key)
    }
  })

  return keys
}
