import React, { memo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  FiltersButtons,
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useFiltersActions,
  useFilterSlice,
  useFiltersOptions,
} from './AddressUtxoFilters.service'
import { AssetFilterContainer, AssetFilterMenuContainer } from './AssetFilter'
import {
  CalendarFilterContainer,
  CalendarFilterMenuContainer,
} from './CalendarFilter'
import {
  AddressFilterContainer,
  AddressFilterMenuContainer,
} from './AddressFilter'
import {
  AddressTypeFilterContainer,
  AddressTypeFilterMenuContainer,
} from './AddressTypeFilter'
import { useAddressStaticStore, useAddressViewModel } from '../context'
import { SegwitFilterContainer } from './SegwitFilter'
import { FilterLayout } from '../../../ui'
import {
  EXLUDE_SORT_FILTERS,
  TABLE_FILTER_MENU_MAX_HEIGHT,
} from '../../../constants'
import { excludeSortFilters } from '../../../utils/excludeSortFilters'

const Filters = () => {
  const _filterList = useFilterNameList()
  const { excludeFilters } = useAddressViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])
  const transactionStaticStore = useAddressStaticStore()
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return <AssetFilterContainer key={filter} />
      case 'calendar':
        return <CalendarFilterContainer key={filter} />
      case 'address':
        return <AddressFilterContainer key={filter} />
      case 'segwit':
        return <SegwitFilterContainer key={filter} />
      case 'addressType':
        return <AddressTypeFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length
    ? [
        ...filterList.map(createFilter),
        <FiltersButtons.IconAdd
          key="filter-icon-add"
          maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
          size={transactionStaticStore.current.filterSize}
          popupContent={<AddressUtxoFilterMenu />}
        />,
      ]
    : []

  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const transactionStaticStore = useAddressStaticStore()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')
  const { t } = useTranslation()

  return (
    <FiltersButtons.Clear
      disabled={!isFiltersChanged}
      label={t('platform:clear')}
      size={transactionStaticStore.current.filterSize}
      onClear={() => resetFilters()}
    />
  )
}

const AddressUtxoFilterMenu: React.FC = () => {
  const [, { setFilter: setSegwitFilter }] = useFilterSlice('segwit.data')
  const { t } = useTranslation()
  const { excludeFilters } = useAddressViewModel()

  let itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    []

  if (!excludeFilters?.includes('asset')) {
    itemsAssetGroup = [
      ...itemsAssetGroup,
      {
        id: 'asset',
        icon: 'Asset',
        label: t('platform:asset'),
        isDisabled: false,
        component: AssetFilterMenuContainer,
      },
    ]
  }

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [
      [
        {
          id: 'address',
          icon: 'Pin',
          label: t('platform:address'),
          isDisabled: false,
          component: AddressFilterMenuContainer,
        },
        ...itemsAssetGroup,
      ],
      [
        {
          id: 'segwit',
          icon: 'Segwit',
          label: t('platform:segwit'),
          isDisabled: false,
          onItemClick: () => {
            setSegwitFilter({ value: 'segwit', label: t('platform:segwit') })
          },
        },
        {
          id: 'addressType',
          icon: 'FeatureFlag',
          label: t('platform:addressType'),
          isDisabled: false,
          component: AddressTypeFilterMenuContainer,
        },
      ],
      [
        {
          id: 'calendar',
          icon: 'Calendar',
          label: t('platform:time'),
          isDisabled: false,
          component: CalendarFilterMenuContainer,
        },
      ],
    ]

  return <FiltersMenu.Wrapper items={items} />
}

const AddressUtxoFiltersComponent = () => {
  const transactionStaticStore = useAddressStaticStore()
  const { t } = useTranslation()

  return (
    <FiltersMenu.Provider
      initValue={{ size: transactionStaticStore.current.filterSize }}
    >
      <FilterLayout
        leftSection={
          <FiltersButtons.Add
            maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
            size={transactionStaticStore.current.filterSize}
            popupContent={<AddressUtxoFilterMenu />}
            label={t('platform:filters')}
          />
        }
        centerSection={<Filters />}
        rightSection={<ButtonClearFilter />}
      />
    </FiltersMenu.Provider>
  )
}

export const AddressUtxoFilters = memo(observer(AddressUtxoFiltersComponent))
