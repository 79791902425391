import { CoinType } from '@clain/core/types/coin'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx'

import {
  DEFAULT_COUNTERPARTIES_FILTERS_CURRENCY,
  DEFAULT_OSINTS_FILTERS,
  DEFAULT_TOKENS_FILTERS,
  DEFAULT_TRANSACTIONS_FILTERS_CURRENCY,
  ENTITY_FILTER_PREFIXES,
} from '../constants'
import { EntityServices } from '../../ProbeSandbox/vm/services/EntitiesServices'
import { IEntityServices } from '../../ProbeSandbox/vm/services/EntitiesServices/types'
import { CURRENCY } from '../../ProbeSandbox/constants/currency'
import { isEmpty } from 'ramda'
import { apiServicesStateFacade } from './ApiServicesStateFacade'
import { dataTokensViewModel } from './DataTokensViewModel'
import { dataStatsViewModel } from './DataStatsViewModel'
import { PageAnalyticsViewModel } from './PageAnalyticsViewModel'
import { SectionDetailsViewModel } from './SectionDetailsViewModel'
import { SectionEntitiesTableViewModel } from './SectionEntitiesTableViewModel'
import { queryParamsViewModel } from './QueryParamsViewModel'
import { commonPageData } from './ApiServicesStateFacade'
import { SectionPortfolioViewModel } from '../baseClasses/SectionPortfolioViewModel'
import {
  transactionsTableViewModel,
  counterpartiesTableViewModel,
  osintTableViewModel,
} from './DataEntitiesTableViewModel'

export class AddressPageViewModel {
  @observable public isInitialized = false

  private static instance: AddressPageViewModel
  private reactionDisposers: Array<IReactionDisposer> = []
  private services: IEntityServices
  private entityType = 'address' as const
  private queryParamsViewModel = queryParamsViewModel
  private apiServicesStateFacade = apiServicesStateFacade
  private statsViewModel = dataStatsViewModel
  private tokensViewModel = dataTokensViewModel
  private transactionsTableViewModel = transactionsTableViewModel
  private counterpartiesTableViewModel = counterpartiesTableViewModel
  private osintTableViewModel = osintTableViewModel

  public pageAnalyticsViewModel: PageAnalyticsViewModel

  public commonPageData = commonPageData
  public sectionDetailsViewModel: SectionDetailsViewModel
  public sectionEntitiesTableViewModel: SectionEntitiesTableViewModel

  public sectionPortfolioViewModel: SectionPortfolioViewModel<
    typeof this.statsViewModel,
    typeof this.tokensViewModel,
    typeof this.commonPageData
  >

  public static getInstance(): AddressPageViewModel {
    if (!AddressPageViewModel.instance) {
      AddressPageViewModel.instance = new AddressPageViewModel()
    }
    return AddressPageViewModel.instance
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (currency: CoinType, addressHash: string, timezone: string) => {
    this.commonPageData.initState({
      addressHash,
      aid: null,
      blockchain: currency,
      timezone,
    })
    this.services = EntityServices.getInstance(CURRENCY)
    this.pageAnalyticsViewModel = new PageAnalyticsViewModel()
    this.sectionPortfolioViewModel = new SectionPortfolioViewModel(
      this.statsViewModel,
      this.tokensViewModel,
      this.commonPageData
    )
    this.sectionDetailsViewModel = new SectionDetailsViewModel(
      this.sectionPortfolioViewModel
    )
    this.sectionEntitiesTableViewModel = new SectionEntitiesTableViewModel()
    this.initStats()
    this.initReactions()
    this.isInitialized = true
  }

  @action
  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          isStatsSuccess: this.statsViewModel.isSuccess,
          aid: this.statsViewModel.data?.addressId,
        }),
        ({ isStatsSuccess, aid }) => {
          if (isStatsSuccess) {
            this.commonPageData.updateState({
              aid,
            })
            this.initTokens()
          }
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => this.tokensViewModel.isSuccess,
        (isTokensSuccess) => {
          if (isTokensSuccess) {
            this.initQueryParams()
            this.initAnalytics()
            this.initEntitiesTable()
          }
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          isNetflowSuccess:
            this.pageAnalyticsViewModel.netflowChartViewModel.isSuccess,
          calendarFilter:
            this.pageAnalyticsViewModel.netflowChartViewModel.calendarFilter ||
            [],
          scoreFilter:
            this.pageAnalyticsViewModel.netflowChartViewModel.scoreFilter,
          groupByFilter:
            this.pageAnalyticsViewModel.netflowChartViewModel.groupByFilter,
          includeTokens:
            this.pageAnalyticsViewModel.netflowChartViewModel
              .formattedParamsData.state.includeTokens,
          convertTo:
            this.pageAnalyticsViewModel.netflowChartViewModel
              .formattedParamsData.state.convertTo,
        }),
        ({
          isNetflowSuccess,
          groupByFilter,
          scoreFilter,
          calendarFilter,
          convertTo,
          includeTokens,
        }) => {
          if (isNetflowSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              group_by: groupByFilter,
              score_min: scoreFilter?.[0],
              score_max: scoreFilter?.[1],
              timestamp_from: calendarFilter?.[0],
              timestamp_to: calendarFilter?.[1],
              convert_to: convertTo === 'usd' ? 'usd' : 'native',
              include_tokens: includeTokens,
            }))
          }
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          isSenkeySuccess:
            this.pageAnalyticsViewModel.senkeyChartViewModel.isSuccess,
          calendarFilter:
            this.pageAnalyticsViewModel.senkeyChartViewModel.calendarFilter ||
            [],
          scoreFilter:
            this.pageAnalyticsViewModel.senkeyChartViewModel.scoreFilter,
          includeTokens:
            this.pageAnalyticsViewModel.senkeyChartViewModel.formattedParamsData
              .state.includeTokens,
          convertTo:
            this.pageAnalyticsViewModel.senkeyChartViewModel.formattedParamsData
              .state.convertTo,
        }),
        ({
          isSenkeySuccess,
          scoreFilter,
          calendarFilter,
          convertTo,
          includeTokens,
        }) => {
          if (isSenkeySuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              cp: {
                ...prevState.cp,
                score_min: scoreFilter?.[0],
                score_max: scoreFilter?.[1],
                timestamp_from: calendarFilter?.[0],
                timestamp_to: calendarFilter?.[1],
                convert_to: convertTo === 'usd' ? 'usd' : 'native',
                include_tokens: includeTokens,
              },
            }))
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isTransactionsDataSuccess: this.transactionsTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.transactionsTable.filters
            .page,
        }),
        ({ isTransactionsDataSuccess, page }) => {
          if (isTransactionsDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.transactions]: {
                ...prevState[ENTITY_FILTER_PREFIXES.transactions],
                page,
              },
            }))
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isDataSuccess: this.counterpartiesTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.counterpartiesTable.filters
            .page,
        }),
        ({ isDataSuccess, page }) => {
          if (isDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.counterparties]: {
                ...prevState[ENTITY_FILTER_PREFIXES.counterparties],
                page,
              },
            }))
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isDataSuccess: this.osintTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.osintTable.filters.page,
        }),
        ({ isDataSuccess, page }) => {
          if (isDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.osint]: {
                ...prevState[ENTITY_FILTER_PREFIXES.osint],
                page,
              },
            }))
          }
        }
      )
    )
  }

  @action
  private initStats = async () => {
    this.apiServicesStateFacade.injectRequestMethodByService('stats')(
      this.services.getServices(this.entityType, this.blockchain)
        .getStatsByAddress
    )
    this.apiServicesStateFacade.initDataLoadingReaction('stats', [
      this.addressHash,
    ])
  }

  @action
  private initTokens = () => {
    this.apiServicesStateFacade.initApiParamsStateByService('tokens')(
      DEFAULT_TOKENS_FILTERS
    )
    this.apiServicesStateFacade.injectRequestMethodByService('tokens')(
      this.services.getServices(this.entityType, this.blockchain).getTokens
    )

    this.apiServicesStateFacade.initDataLoadingReaction('tokens', [this.aid])
  }

  @action
  private initEntitiesTable = () => {
    this.sectionEntitiesTableViewModel.setActiveTab(
      this.queryParamsViewModel.queryParamsState.active_tab
    )
    this.apiServicesStateFacade.initApiParamsStateByService('osint')({
      ...DEFAULT_OSINTS_FILTERS,
      ...this.queryParamsViewModel.queryParamsState.osint,
    })
    this.apiServicesStateFacade.injectRequestMethodByService('osint')(
      this.services.getServices(this.entityType, this.blockchain).getOsints
    )

    const { includeTokens = [], ...restParams } =
      this.queryParamsViewModel.queryParamsState.trns
    const tokenId = includeTokens?.[0] ? Number(includeTokens?.[0]) : null
    const token = this.tokensViewModel.tokensBalanceData.find(
      (el) => tokenId === el?.token?.id
    )

    this.apiServicesStateFacade.initApiParamsStateByService('transactions')({
      ...restParams,
      includeTokens: token?.token != null ? [token?.token] : [],
    })
    this.apiServicesStateFacade.initDefaultApiParamsStateByService(
      'transactions'
    )({
      ...this.queryParamsViewModel.queryParamsDefaultState.trns,
      includeTokens: [],
    })
    this.apiServicesStateFacade.injectRequestMethodByService('transactions')(
      this.services.getServices(this.entityType, this.blockchain)
        .getTransactions
    )

    this.apiServicesStateFacade.injectRequestMethodByService('token')(
      async (payload) => {
        if (!payload?.address) return

        return await this.services
          .getServices('explorer', this.blockchain)
          .getTokenByAddress(payload)
      }
    )

    const {
      includeTokens: includeTokensCounterparties = [],
      ...restCounterpartiesParams
    } = this.queryParamsViewModel.queryParamsState.counterparties
    const tokenIdCounterparties = includeTokensCounterparties?.[0]
      ? Number(includeTokensCounterparties?.[0])
      : null
    const tokenCounterparties = this.tokensViewModel.tokensBalanceData.find(
      (el) => tokenIdCounterparties === el?.token?.id
    )

    this.apiServicesStateFacade.initApiParamsStateByService('counterparties')({
      ...restCounterpartiesParams,
      includeTokens:
        tokenCounterparties?.token != null ? [tokenCounterparties?.token] : [],
    })
    this.apiServicesStateFacade.initDefaultApiParamsStateByService(
      'counterparties'
    )({
      ...this.queryParamsViewModel.queryParamsDefaultState.counterparties,
      includeTokens: [],
    })
    this.apiServicesStateFacade.injectRequestMethodByService('counterparties')(
      this.services.getServices(this.entityType, this.blockchain)
        .getCounterparties
    )

    this.apiServicesStateFacade.initDataLoadingReaction('transactions', [
      this.aid,
    ])
    this.apiServicesStateFacade.initDataLoadingReaction('counterparties', [
      this.aid,
    ])
    this.apiServicesStateFacade.initDataLoadingReaction('osint', [this.aid])
    this.apiServicesStateFacade.initDataLoadingReaction('token')
  }

  @action
  private initAnalytics = () => {
    this.pageAnalyticsViewModel.initAnalytics()
    this.pageAnalyticsViewModel.loadAnalytics()
  }

  @action
  private initQueryParams = () => {
    this.queryParamsViewModel.initQueryParams()
  }

  @computed
  public get addressHash() {
    return this.commonPageData.state.addressHash
  }

  @computed
  public get aid() {
    return this.commonPageData.state?.aid
  }

  @computed
  public get blockchain() {
    return this.commonPageData.state.blockchain
  }

  @computed.struct
  public get pageNotFound() {
    return (
      isEmpty(this.statsViewModel.data || {}) && this.statsViewModel.isSuccess
    )
  }

  @action
  public clear = () => {
    this.isInitialized = false
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.commonPageData.clearState()
    this.statsViewModel.clear()
    this.tokensViewModel.clear()
    this.sectionEntitiesTableViewModel.clear()
    this.pageAnalyticsViewModel.clear()
    this.queryParamsViewModel.queryParamsController.clearQueryParamsState()
    this.apiServicesStateFacade.clear()
  }
}

export const addressPageViewModel = AddressPageViewModel.getInstance()
