import { useFilterSlice } from '../TransactionEvmFilters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '../../../../../Filters/ComparatorsValueFilter'
import { useTransactionStaticStore } from '../../context'
import { useTranslation } from 'react-i18next'

export const AssetValueFilterMenuContainer = () => {
  const [filter, { setFilter }] = useFilterSlice('assetValue')
  const transactionStaticStore = useTransactionStaticStore()
  const [selectedAssets] = useFilterSlice('asset.data')
  const selectedAsset = selectedAssets?.[0]
  const { t } = useTranslation()

  return (
    <ComparatorsValueFilterMenu
      values={filter.data}
      selectedCondition={filter.condition}
      title={t('platform:assetValue')}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
      label={selectedAsset?.symbol}
    />
  )
}

export const AssetValueFilterContainer = () => {
  const [values] = useFilterSlice('assetValue.data')
  const [, { resetFilter }] = useFilterSlice('assetValue')
  const [condition] = useFilterSlice('assetValue.condition')
  const transactionStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label={t('platform:assetValue')}
      clearFilter={resetFilter}
      menuComponent={<AssetValueFilterMenuContainer />}
    />
  )
}
