import React from 'react'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '../../../../../Filters/ComparatorsValueFilter'
import { useTransactionStaticStore } from '../../context'

import { useTranslation } from 'react-i18next'
export const FeeByteFilterMenuContainer = () => {
  const [filter, { setFilter }] = useFilterSlice('feeByte')
  const transactionStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <ComparatorsValueFilterMenu
      values={filter.data}
      title={t('platform:transactionFeeVByte')}
      onChange={setFilter}
      selectedCondition={filter.condition}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const FeeByteFilterContainer = () => {
  const [values] = useFilterSlice('feeByte.data')
  const [, { resetFilter }] = useFilterSlice('feeByte')
  const [condition] = useFilterSlice('feeByte.condition')
  const transactionStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label={t('platform:feeVByte')}
      clearFilter={resetFilter}
      menuComponent={<FeeByteFilterMenuContainer />}
    />
  )
}
