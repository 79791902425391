import { observer } from 'mobx-react-lite'
import { InjectedComponentOptions } from './injectComponentModel.types'

export function InjectComponentModel<T, R = T>(
  Component: React.ComponentType<T>,
  options?: InjectedComponentOptions<T, Omit<T, keyof R>>
) {
  const InjectedComponent = observer((props: Omit<T, keyof R>) => {
    const paramsThis = options
      ? options
      : (this as InjectedComponentOptions<T, Omit<T, keyof R>>)
    const params = paramsThis?.useFactory?.(props)

    return <Component {...(props as T)} {...params} />
  })

  return InjectedComponent
}

export const CreateInjectModel = <T, R extends T = T>(
  options?: InjectedComponentOptions<T, Omit<T, keyof R>>
) => {
  return (InjectComponentModel as typeof InjectComponentModel<T, R>).bind(
    options
  )
}
