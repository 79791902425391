import { ProbeViewModel } from '../../ProbeViewModel'
import { SnapshotCommand } from '@platform/components/ProbeSandbox/types/history'
import { ServerEventNodeEdgeReceive } from '@platform/components/ProbeSandbox/types/serverData'
import { entityDataToSnapshot } from '../../EntityDataToSnapshot'
import { GraphEmitEvents } from '@clain/graph-entities'
import {
  ProbeEdges,
  ProbeNodes,
} from '@platform/components/ProbeSandbox/vm/ProbeState'
import { Position } from '@platform/components/ProbeSandbox/types/Position'

type PositionsCollections = { key: string; position: Position }[]

export class EntityLinkingController {
  private nodeLinkProcessTimeouts: Map<string, number> = new Map()
  private isLinkingProcessStarted = false
  private probeVM: ProbeViewModel

  constructor(probeVM: ProbeViewModel) {
    this.probeVM = probeVM
  }

  private getCurrentLinkEdgeBySlaveNode = (nodeKey: string) => {
    return this.probeVM.probeState.getEdges.find(
      (edge) => edge.data.edgeType === 'link' && edge.sourceKey === nodeKey
    )
  }

  private getCurrentLinkEdgesByMasterNode = (nodeKey: string) => {
    return this.probeVM.probeState.getEdges.filter(
      (edge) => edge.data.edgeType === 'link' && edge.targetKey === nodeKey
    )
  }

  private shouldUnlink = (
    currentOverlappingNode: string | null,
    currentLinkEdge: ProbeEdges | null
  ) => {
    return currentOverlappingNode == null && currentLinkEdge?.key
  }

  private shouldLink = (
    currentOverlappingNode: string | null,
    currentLinkEdge: ProbeEdges | null
  ) => {
    return (
      currentOverlappingNode != null &&
      currentOverlappingNode !== currentLinkEdge?.targetKey
    )
  }

  private handleDeleteEdge = (currentLinkEdge: ProbeEdges) => {
    const probeEvents: GraphEmitEvents[] = [
      {
        type: 'delete_edge',
        entity: {
          strategy: 'none',
          edgeKey: currentLinkEdge.key,
        },
      },
    ]

    const historyEvents: ServerEventNodeEdgeReceive[] = [
      {
        type: 'delete_edge',
        key: currentLinkEdge.key,
      },
    ]

    return { probeEvents, historyEvents }
  }

  private handleAddEdge = (
    node: ProbeNodes,
    currentOverlappingNode: string | null,
    currentLinkEdge: ProbeEdges | null
  ) => {
    const addEdgeProbeEvent = {
      type: 'add_edge',
      key: `${node.key}_${currentOverlappingNode}`,
      data: {
        srcKey: node.key,
        dstKey: currentOverlappingNode,
        type: 'link',
      },
    } as const

    const deleteEdgeProbeEvent = currentLinkEdge
      ? ({
          type: 'delete_edge',
          entity: {
            strategy: 'none',
            edgeKey: currentLinkEdge?.key,
          },
        } as const)
      : null

    const probeEvents: GraphEmitEvents[] = [addEdgeProbeEvent]
    if (deleteEdgeProbeEvent) {
      probeEvents.push(deleteEdgeProbeEvent)
    }

    const addEdgeHistoryEvent: ServerEventNodeEdgeReceive = {
      type: 'add_edge',
      key: `${node.key}_${currentOverlappingNode}`,
      data: {
        srcKey: node.key,
        dstKey: currentOverlappingNode,
        edgeData: {
          edgeType: 'link',
        },
      },
    }

    const deleteEdgeHistoryEvent = currentLinkEdge
      ? ({
          type: 'delete_edge',
          key: currentLinkEdge.key,
        } as const)
      : null

    const historyEvents: ServerEventNodeEdgeReceive[] = [addEdgeHistoryEvent]
    if (deleteEdgeHistoryEvent) {
      historyEvents.push(deleteEdgeHistoryEvent)
    }

    return { probeEvents, historyEvents }
  }

  private emitProbeEvents = (
    events: GraphEmitEvents[],
    snapshotToHistory = false
  ) => {
    this.probeVM.probeEvents.emit(events, { snapshotToHistory })
  }

  private emitSnapshotsToHistory = (
    snapshotPositions: SnapshotCommand,
    events: ServerEventNodeEdgeReceive[]
  ) => {
    this.probeVM.history.push([
      ...snapshotPositions,
      ...entityDataToSnapshot.eventToSnapshot(events),
    ])
  }

  public hideLinkingArea = () => {
    this.probeVM.app.nodeLinkingModule.hideLinkingArea()
  }

  public startLinkingProcess = (nodeKey: string): void => {
    if (!this.probeVM.probeState.nodes.has(nodeKey)) {
      return
    }
    this.isLinkingProcessStarted = true
    const nodeInteractionModule = this.probeVM.app.nodeLinkingModule
    const linkedId = nodeInteractionModule.isOverlappedWith(nodeKey)
    const node = this.probeVM.probeState.nodes.get(nodeKey)

    if (linkedId) {
      node.setLinkProcessType('readyToLink')
      nodeInteractionModule.showLinkingArea(linkedId)
    } else {
      node.setLinkProcessType('readyToUnlink')
      nodeInteractionModule.hideLinkingArea()
    }
  }

  public finishLinkingProcess = (
    nodeKey: string,
    snapshotPositions?: SnapshotCommand
  ): void => {
    if (
      !this.probeVM.probeState.nodes.has(nodeKey) ||
      !this.isLinkingProcessStarted
    ) {
      return
    }
    this.isLinkingProcessStarted = false
    const node = this.probeVM.probeState.nodes.get(nodeKey)
    const currentLinkEdge = this.getCurrentLinkEdgeBySlaveNode(nodeKey)
    const currentOverlappingNode =
      this.probeVM.app.nodeLinkingModule.currentOverlappingNode

    this.probeVM.app.nodeLinkingModule.hideLinkingArea()

    let eventsForProbeEvents: GraphEmitEvents[] = []
    let eventsForHistory: ServerEventNodeEdgeReceive[] = []
    if (this.shouldUnlink(currentOverlappingNode, currentLinkEdge)) {
      const { probeEvents, historyEvents } =
        this.handleDeleteEdge(currentLinkEdge)
      eventsForProbeEvents = probeEvents
      eventsForHistory = historyEvents
    } else if (this.shouldLink(currentOverlappingNode, currentLinkEdge)) {
      const { probeEvents, historyEvents } = this.handleAddEdge(
        node,
        currentOverlappingNode,
        currentLinkEdge
      )
      eventsForProbeEvents = probeEvents
      eventsForHistory = historyEvents
    }
    this.emitProbeEvents(eventsForProbeEvents, !snapshotPositions)
    if (snapshotPositions) {
      this.emitSnapshotsToHistory(snapshotPositions, eventsForHistory)
    }

    if (this.nodeLinkProcessTimeouts.has(nodeKey)) {
      clearTimeout(this.nodeLinkProcessTimeouts.get(nodeKey))
      this.nodeLinkProcessTimeouts.delete(nodeKey)
    }

    const timeoutID = window.setTimeout(() => {
      node.setLinkProcessType('none')
      this.nodeLinkProcessTimeouts.delete(nodeKey)
    }, 200)

    this.nodeLinkProcessTimeouts.set(nodeKey, timeoutID)
  }

  public getSyncedPositions = (
    nodeKey: string,
    newPosition: Position
  ): PositionsCollections => {
    const syncPositions: PositionsCollections = []
    const linkEdges = this.getCurrentLinkEdgesByMasterNode(nodeKey)
    if (linkEdges.length) {
      const targetNodeOldPosition =
        this.probeVM.probeState.nodes.get(nodeKey).position
      const diffX = newPosition.x - targetNodeOldPosition.x
      const diffY = newPosition.y - targetNodeOldPosition.y
      linkEdges.forEach((linkEdge) => {
        const sourcePosition = this.probeVM.probeState.nodes.get(
          linkEdge.sourceKey
        ).position
        syncPositions.push({
          key: linkEdge.sourceKey,
          position: {
            x: sourcePosition.x + diffX,
            y: sourcePosition.y + diffY,
          },
        })
      })
    }
    return syncPositions
  }

  public getSyncedNodesByNodeKey = (nodeKey: string) => {
    const linkEdges = this.getCurrentLinkEdgesByMasterNode(nodeKey) || []
    return linkEdges.map((linkEdge) =>
      this.probeVM.probeState.nodes.get(linkEdge.sourceKey)
    )
  }

  public getMasterNodeKeyBySlaveNodeKey = (slaveNodeKey: string) => {
    const linkedEdge = this.getCurrentLinkEdgeBySlaveNode(slaveNodeKey)
    return linkedEdge?.targetKey
  }
}
