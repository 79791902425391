import { GetCounterpartiesParams } from '../types'
import { normalizeIncludeTokensPayload } from './normalizeIncludeTokensPayload'
import { normalizeScorePayload } from './normalizeScorePayload'

export const normalizeCounterpartiesPlayload = (
  params: GetCounterpartiesParams
) => {
  const {
    page,
    pageSize: per_page,
    sortBy: sort_by,
    sortOrder: sort_order,
    scoreFrom,
    scoreTo,
    categories,
    includeTokens,
    from: active_from,
    to: active_to,
    counterpartyId: cp_id,
    counterpartyAddressId: cp_aid,
  } = params

  return {
    page,
    per_page,
    sort_by,
    sort_order,
    ...normalizeScorePayload({
      cp_score: { from: scoreFrom, to: scoreTo },
    }),
    ...normalizeIncludeTokensPayload(includeTokens),
    cp_id,
    cp_aid,
    categories,
    active_from,
    active_to,
  }
}
