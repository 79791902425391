import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { AlertInfobarHeader } from './AlertInfobarHeader'
import { AlertInfobarList } from './AlertInfobarList'
import styles from './AlertInfobar.scss'
import classNames from 'classnames/bind'
import { RowDeprecated } from '@clain/core/ui-kit'
import useVm from '@clain/core/useVm'
import { useCtx } from '../../../../../ctx'
import { AlertsViewModel } from '../../../../../modules'
import ProbeVM from '../../../vm/ProbeViewModel'

const cx = classNames.bind(styles)

export const AlertInfobar: React.FC = observer(() => {
  const alertsVm = useVm(AlertsViewModel, useCtx())
  const [isOpen, setIsOpen] = useState(true)

  const handleIsOpen = useCallback(() => {
    setIsOpen((is) => !is)
  }, [])

  const handleSeeEvents = useCallback(() => {
    ProbeVM.probeState.setBottombarTabActive('alertEvents')
  }, [])

  return alertsVm?.alertsWithBaseTokens?.length && !alertsVm.loadingAlerts ? (
    <RowDeprecated
      align="left"
      fullWidth
      gap={0}
      className={cx('AlertInfobar')}
    >
      <AlertInfobarHeader isOpen={isOpen} onIsOpen={handleIsOpen} />
      <AlertInfobarList
        isOpen={isOpen}
        list={alertsVm.alertsWithBaseTokens}
        onRemove={alertsVm.removeAlert}
        onToggle={alertsVm.toggleAlert}
        onSeeEvents={handleSeeEvents}
        disabledSeeEvents={!alertsVm?.events?.events?.length}
      />
    </RowDeprecated>
  ) : null
})
