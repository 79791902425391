import { ContainerSize, useComparators } from '@clain/core/ui-kit'
import {
  SelectFilterComparatorValue,
  SelectFilterItem,
} from './SelectFilter.types'

export const useSelectFilterOptionsMap = () => {
  const comparators = useComparators()

  return {
    [comparators.is.value]: comparators.is.text[0],
    [comparators.isNot.value]: comparators.isNot.text[0],
  } as const
}

export const useSelectFilterOptions = (): SelectFilterItem<
  Extract<SelectFilterComparatorValue, 'is' | 'isNot'>
>[] => {
  const comparators = useComparators()
  const selectFilterOptionsMap = useSelectFilterOptionsMap()

  return [
    {
      label: selectFilterOptionsMap[comparators.is.value],
      value: comparators.is.value,
      icon: 'Check',
    },
    {
      label: selectFilterOptionsMap[comparators.isNot.value],
      value: comparators.isNot.value,
      icon: 'Blocker',
    },
  ]
}

export const CONTAINER_ITEM_SIZE: ContainerSize = 'md'
