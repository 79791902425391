import { useFilterSlice } from '../TransactionUtxoFilters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '../../../../../Filters/ComparatorsValueFilter'
import { useTransactionStaticStore } from '../../context'
import { useTranslation } from 'react-i18next'

export const FeeFilterMenuContainer = () => {
  const [values, { setFilter }] = useFilterSlice('fee')
  const transactionStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <ComparatorsValueFilterMenu
      values={values.data}
      title={t('platform:transactionFee')}
      onChange={setFilter}
      selectedCondition={values.condition}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const FeeFilterContainer = () => {
  const [values] = useFilterSlice('fee.data')
  const [, { resetFilter }] = useFilterSlice('fee')
  const [condition] = useFilterSlice('fee.condition')
  const transactionStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      icon="AssetValue"
      size={transactionStaticStore.current.filterSize}
      label={t('platform:fee')}
      clearFilter={resetFilter}
      menuComponent={<FeeFilterMenuContainer />}
    />
  )
}
