import { ErrorBoundaryContainer } from './ErrorBoundaryContainer'
import React from 'react'

export const withErrorBoundary = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> => {
  const ComponentWithErrorBoundary: React.FC<P> = (props) => {
    return (
      <ErrorBoundaryContainer>
        <WrappedComponent {...props} />
      </ErrorBoundaryContainer>
    )
  }

  return ComponentWithErrorBoundary
}
