import { Graphics } from 'pixi.js'
import formatColor from '../core/utils/formatColor'
import { NodeOptions } from '../types'

const scaleFactor = 10

export class AreaGfx {
  public gfx: Graphics

  constructor(areaOptions: NodeOptions['area']) {
    this.gfx = new Graphics()
    this.updateGfx(areaOptions)
  }

  public updateGfx = (options: NodeOptions['area']) => {
    const graphics = this.gfx
    const [fillColor] = formatColor(options.color)
    const [borderColor] = formatColor(options.borderColor)

    graphics.clear()
    graphics.circle(0, 0, options.radius * scaleFactor)
    if (borderColor) {
      graphics.stroke({
        width: options.borderWidth * scaleFactor,
        color: borderColor,
      })
    }

    graphics.fill({ color: fillColor, alpha: options.opacity })
    graphics.scale.set(1 / scaleFactor)
    graphics.visible = false
  }
}
