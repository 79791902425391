import { useTranslation } from 'react-i18next'
import { comparators } from '@clain/core/ui-kit'
import { MultisigFilterItem } from './MultisigFilter.types'

export const useMultisigFilter = () => {
  const { t } = useTranslation()

  const optionsMap = {
    [comparators.isNot.value]: t('platform:notMultisig'),
    [comparators.is.value]: t('platform:specificMultisig'),
  }

  const options: MultisigFilterItem[] = [
    {
      label: optionsMap[comparators.isNot.value],
      value: comparators.isNot.value,
    },
    {
      label: optionsMap[comparators.is.value],
      value: comparators.is.value,
    },
  ]

  return {
    optionsMap,
    options,
  }
}
