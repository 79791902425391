import React, {
  createContext,
  useContext,
  useRef,
  useLayoutEffect,
  PropsWithChildren,
} from 'react'
import {
  UseCounterpartyStaticStoreData,
  UseCounterpartyStaticInitStoreData,
} from './CounterpartyContext.types'
import { mergeDeepRight } from 'ramda'
import { createViewModelContext } from '@clain/core/utils/mobxUtils'
import { CounterpartyFiltersParams } from '../CounterpartyFilters/CounterpartyFilters.types'
import { CounterpartyNameFilters } from '../CounterpartyContainerFilters/CounterpartyContainerFilters.service'

function useCounterpartyStaticStoreData(
  init: UseCounterpartyStaticStoreData | null
) {
  const store = useRef<UseCounterpartyStaticStoreData | null>(init)
  return store
}

type UseStaticStoreDataReturnType = ReturnType<
  typeof useCounterpartyStaticStoreData
>

const CounterpartyStaticStoreContext =
  createContext<UseStaticStoreDataReturnType | null>(null)

export const useCounterpartyStaticStore = () => {
  return useContext(CounterpartyStaticStoreContext)
}

export const useInitCounterpartyStaticStore = (
  props: Partial<UseCounterpartyStaticStoreData>
) => {
  const counterpartyStaticStore = useCounterpartyStaticStore()
  //TODO remove as when issue will be resolved https://github.com/ramda/types/issues/130
  useLayoutEffect(() => {
    counterpartyStaticStore.current = mergeDeepRight(
      counterpartyStaticStore.current,
      props
    ) as UseCounterpartyStaticStoreData
  }, [counterpartyStaticStore, props])
}

const CounterpartyStaticStateProvider: React.FC<
  PropsWithChildren<UseCounterpartyStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  const store = useCounterpartyStaticStoreData(initStaticStore)

  return (
    <CounterpartyStaticStoreContext.Provider value={store}>
      {children}
    </CounterpartyStaticStoreContext.Provider>
  )
}

export const CounterpartyProvider: React.FC<
  PropsWithChildren<UseCounterpartyStaticInitStoreData>
> = ({ initStaticStore, children }) => {
  return (
    <CounterpartyStaticStateProvider initStaticStore={initStaticStore}>
      {children}
    </CounterpartyStaticStateProvider>
  )
}

export const {
  ViewModelProvider: CounterpartyViewModelProvider,
  useViewModel: useCounterpartyViewModel,
} = createViewModelContext<CounterpartyFiltersParams<CounterpartyNameFilters>>()
