import React from 'react'
import { observer } from 'mobx-react-lite'

import useVm from '@clain/core/useVm'

import { CaseViewModel } from './CaseViewModel'
import { useCtx } from '../../../../ctx'
import CaseHeader from '../CaseHeader'
import Timeline from '../Timeline'
import Graphs from '../Graphs'
import Files from '../Files'
import Alerts from '../Alerts'
import { AlertsViewModel } from '../../../../modules'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { useParams } from '@clain/core/Router/router'

const Case: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const caseVm = useVm(CaseViewModel, useCtx())
  const alertsVm = useVm(AlertsViewModel, useCtx())

  React.useEffect(() => {
    if (id) {
      caseVm.init(id)
      alertsVm.init(id)
      alertsVm.initDataLoadingReaction()
    }

    return () => {
      caseVm.clear()
      alertsVm.clear()
    }
  }, [id])

  HeaderSlot.useContent(() => <CaseHeader />, [])

  switch (caseVm.tab) {
    case 'timeline':
      return <Timeline />
    case 'graphs':
      return <Graphs />
    case 'files':
      return <Files />
    case 'alerts':
      return <Alerts />
    default:
      return null
  }
}

export default observer(Case)
