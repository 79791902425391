import { useTranslation } from 'react-i18next'
import { useFilterSlice } from '../TrxFilters.service'
import {
  ScoreFilter as ScoreFilterComponent,
  ScoreFilterMenu,
} from '@platform/components/Filters'
import {
  FilterDropdownMenuComponentProps,
  FilterDropdownMenuContainerItem,
} from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'
import {
  FilterStatesByFilter,
  ScoreFilterState,
} from '@platform/components/BlockchainFilter/types'

type FilterProps = Partial<FilterDropdownMenuComponentProps> & {
  id: keyof FilterStatesByFilter<ScoreFilterState>
  title: string
}

const MenuContainer = ({ id, title }: FilterProps) => {
  const [values, { setFilter }] = useFilterSlice(`${id}.data`)

  return <ScoreFilterMenu onSelect={setFilter} score={values} title={title} />
}

const FilterContainer = ({ id, title }: FilterProps) => {
  const [values] = useFilterSlice(`${id}.data`)
  const [, { resetFilter }] = useFilterSlice(id)
  const { t } = useTranslation()

  return (
    <ScoreFilterComponent
      score={values}
      filterLable={t('platform:score')}
      filterIcon={null}
      menuComponent={<MenuContainer id={id} title={title} />}
      clearFilter={resetFilter}
    />
  )
}

const MenuItem = (): FilterDropdownMenuContainerItem => {
  const { t } = useTranslation()

  return {
    id: '',
    label: t('platform:score'),
    icon: null,
    isDisabled: false,
  }
}

export const ScoreFilter = {
  MenuItem,
  Container: FilterContainer,
  Menu: MenuContainer,
}
