import { filtersContextFactory } from '@clain/core/ui-kit'

export const createInjectFiltersModel = <
  TFiltersModel extends () => ReturnType<typeof filtersContextFactory<any>>
>(
  useFiltersModel: TFiltersModel
) => {
  return <T, FiltersState extends Record<string, any>>(
    Component: React.ComponentType<
      T & ReturnType<typeof filtersContextFactory<FiltersState>>
    >
  ) => {
    return function InjectedComponent(props: T) {
      const filtersModel = useFiltersModel() as ReturnType<
        typeof filtersContextFactory<FiltersState>
      >

      return <Component {...filtersModel} {...props} />
    }
  }
}

export const createInjectFiltersHook = <
  TFiltersModel extends () => ReturnType<typeof filtersContextFactory<any>>
>(
  useFiltersModel: TFiltersModel
) => {
  return <T, TReturn, FiltersState extends Record<string, any>>(
    useFn: (
      params: T & ReturnType<typeof filtersContextFactory<FiltersState>>
    ) => TReturn
  ) => {
    return function InjectedHook(props: T) {
      const filtersModel = useFiltersModel() as ReturnType<
        typeof filtersContextFactory<FiltersState>
      >

      return useFn({ ...props, ...filtersModel })
    }
  }
}
