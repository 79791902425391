import { useTheme } from 'styled-components'
import {
  ColorPickerCore,
  ColorPickerProps,
  createTokenKey,
  IconButton,
  selectToken,
} from '@clain/core/ui-kit'
import { PopoverBaseContainer } from '@clainio/web-platform'

export const PaletteColors: React.FC<
  Pick<ColorPickerProps, 'value' | 'onChange' | 'onReset'>
> = ({ value, onChange, onReset }) => {
  const theme = useTheme()
  const colors = [
    selectToken(theme, createTokenKey(['grey500'])),
    selectToken(theme, createTokenKey(['custom', 'magenta'])),
    selectToken(theme, createTokenKey(['custom', 'neon'])),
    selectToken(theme, createTokenKey(['custom', 'sky'])),
    selectToken(theme, createTokenKey(['custom', 'wave'])),
    selectToken(theme, createTokenKey(['custom', 'emerald'])),
    selectToken(theme, createTokenKey(['custom', 'grass'])),
    selectToken(theme, createTokenKey(['custom', 'lemon'])),
    selectToken(theme, createTokenKey(['custom', 'orange'])),
    selectToken(theme, createTokenKey(['custom', 'rose'])),
  ]

  const listColors = colors.map((color) => ({
    color,
    value: color,
  }))

  return (
    <PopoverBaseContainer
      placement={'right-end'}
      offset={12}
      content={
        <ColorPickerCore
          list={listColors}
          value={value}
          onChange={(value) => {
            onChange(value)
          }}
          onReset={onReset}
        />
      }
      anchor={<IconButton size="md" iconVariant="Pallete" />}
    />
  )
}
