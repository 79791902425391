import { LanguageTypes } from '@platform/types'
import { MenuOption } from '@clain/core/ui-kit'

export const DEFAULT_LANG = 'en'

export const UIKIT_LANG_NAMESPACE = 'uikit'

export const LANG_NAMESPACES = ['platform', UIKIT_LANG_NAMESPACE]
export const LANG_STORAGE_KEY = 'language'

export const LANGS = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  pt: 'Português',
  nl: 'Nederlands',
  ko: '한국어',
  ja: '日本語',
} satisfies Record<LanguageTypes, string>

export const LANGS_KEYS = Object.keys(LANGS) as LanguageTypes[]

export const LANGS_OPTIONS = Object.entries(LANGS).map(([key, value]) => ({
  value: key,
  label: value,
  selected: false,
})) as Array<MenuOption>
