import { useFilterSlice } from '../AddressUtxoFilters.service'
import { useGetFilterLabels } from '@clain/core/ui-kit'
import { useAddressViewModel } from '../../context'
import { useTranslation } from 'react-i18next'

export const useAssetFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('asset.data')
  const [, { resetFilter }] = useFilterSlice('asset')
  const { tokens } = useAddressViewModel()
  const { t } = useTranslation()
  const [condition] = useFilterSlice('asset.condition')
  const { isMultiple, multipleLabel } = useGetFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: t('platform:asset_one'),
  })

  return {
    isMultiple,
    multipleLabel,
    selectedTokens: data,
    menuListData: tokens,
    setFilter,
    resetFilter,
  }
}

export const useSelectedAssetData = () => {
  const { selectedTokens, menuListData } = useAssetFilterData()

  return selectedTokens?.length
    ? menuListData.find((token) => selectedTokens[0].id === token.id)
    : null
}
