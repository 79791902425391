import { ProbeGraph } from '../types/ProbeGraph'
import { ServerEdgeType, ServerNodeType } from '../types/serverData'
import { EdgeData } from '../types/edgeEntitiesData/EdgeData'
import { EdgeAttributes } from '@clain/graph'
import debounce from 'lodash/debounce'

export type GetTransactionBlockReturn =
  | false
  | {
      nodeKeys: Array<string>
      edgeKeys: Array<string>
    }

type NodeEdgeTypes = [ServerNodeType[], ServerEdgeType[]]
type EdgeNodeTypes = [ServerEdgeType[], ServerNodeType[]]

type NodeTypesEdgesTypes = (NodeEdgeTypes | EdgeNodeTypes)[]

type DomainBlockOptions = {
  includeSource?: boolean
  includeTarget?: boolean
}

const isEdgeConnectedToSelectedEdge = (
  edgeId: string,
  graph: ProbeGraph,
  source: string,
  target: string
) => {
  return graph.target(edgeId) === source || graph.source(edgeId) === target
}

const isEvmTransactionEdgeValid = (
  data: EdgeData,
  attrs: EdgeAttributes<EdgeData>
) => {
  return (
    data.edgeType === 'evm_transaction' &&
    attrs.data.edgeType === 'evm_transaction' &&
    data.type === attrs.data?.type &&
    data.index === attrs.data?.index
  )
}

const shouldIncludeEdge = (
  edgeId: string,
  graph: ProbeGraph,
  source: string,
  target: string,
  attrs: EdgeAttributes<EdgeData>,
  edgeTypes: ServerEdgeType[]
) => {
  const data = graph.getEdgeAttributes(edgeId).data

  if (edgeTypes.includes(data.edgeType)) {
    switch (data.edgeType) {
      case 'evm_transaction':
        return isEvmTransactionEdgeValid(data, attrs)
      case 'utxo_transaction':
        return isEdgeConnectedToSelectedEdge(edgeId, graph, source, target)
      default:
        return false
    }
  }

  return false
}

const consoleLogEdgeType = debounce(console.info, 1000, { leading: true })

const getEdgesTypesByNodeType = (
  graph: ProbeGraph,
  nodeKey: string,
  nodeTypesEdgeTypes: NodeEdgeTypes[]
) => {
  const attrs = graph.getNodeAttributes(nodeKey)

  for (let i = 0; i < nodeTypesEdgeTypes.length; i++) {
    const [nodeType, edgeType] = nodeTypesEdgeTypes[i]
    if (nodeType.includes(attrs.data.nodeType)) {
      return edgeType
    }
  }

  return false
}

const getNodeEdgeTypesByEdgeKey = (
  graph: ProbeGraph,
  edgeKey: string,
  nodeTypesEdgeTypes: EdgeNodeTypes[]
): NodeEdgeTypes | false => {
  const attrs = graph.getEdgeAttributes(edgeKey)

  for (let i = 0; i < nodeTypesEdgeTypes.length; i++) {
    const [edgeType, nodeType] = nodeTypesEdgeTypes[i]
    if (edgeType.includes(attrs.data.edgeType)) {
      return [nodeType, edgeType] as unknown as NodeEdgeTypes
    }
  }

  return false
}

const getEdgesByEdgeTypes = (
  graph: ProbeGraph,
  nodeKey: string,
  edgeType: ServerEdgeType[],
  options?: DomainBlockOptions
) => {
  const edgeKeys = graph.edges(nodeKey)
  const edges: string[] = []
  const nodes = []

  edgeKeys.forEach((edgeKey) => {
    const attrs = graph.getEdgeAttributes(edgeKey)
    const source = graph.source(edgeKey)
    const target = graph.target(edgeKey)

    if (options?.includeSource && source) {
      nodes.push(source)
    }

    if (options?.includeTarget && target) {
      nodes.push(target)
    }

    if (edgeType.includes(attrs.data.edgeType)) {
      edges.push(edgeKey)
    }
  })

  return { edges, nodes }
}

const getSourceTargetNodes = (
  graph: ProbeGraph,
  edgesKey: string[],
  options?: DomainBlockOptions
) => {
  const nodes = []

  edgesKey.forEach((edgeKey) => {
    const source = graph.source(edgeKey)
    const target = graph.target(edgeKey)

    if (options?.includeSource && source) {
      nodes.push(source)
    }

    if (options?.includeTarget && target) {
      nodes.push(target)
    }
  })

  return nodes
}

const getDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string,
  nodeTypesEdgeTypes: NodeTypesEdgesTypes = [],
  options?: DomainBlockOptions
): GetTransactionBlockReturn => {
  if (graph.hasNode(nodeOrEdgeKey)) {
    return getDomainBlockByNodeKey(
      graph,
      nodeOrEdgeKey,
      nodeTypesEdgeTypes as NodeEdgeTypes[],
      options
    )
  } else {
    const attrs = graph.getEdgeAttributes(nodeOrEdgeKey)
    const nodeEdgeTypes = getNodeEdgeTypesByEdgeKey(
      graph,
      nodeOrEdgeKey,
      nodeTypesEdgeTypes as EdgeNodeTypes[]
    )

    if (!nodeEdgeTypes) return false
    const [, edgeTypes] = nodeEdgeTypes

    const source = graph.source(nodeOrEdgeKey)
    const target = graph.target(nodeOrEdgeKey)
    //@ts-expect-error
    if (attrs.data?.type) {
      consoleLogEdgeType(
        //@ts-expect-error
        `%cSelected edge type: %c ${attrs.data?.type}`,
        'color: white;',
        'color: yellow;'
      )
    }

    return graph
      .extremities(nodeOrEdgeKey)
      .map((nodeKey) => {
        const transactionBlock = getDomainBlockByNodeKey(graph, nodeKey, [
          nodeEdgeTypes,
        ])

        if (!transactionBlock) return transactionBlock
        const filteredEdges = transactionBlock.edgeKeys.filter((edgeId) =>
          shouldIncludeEdge(edgeId, graph, source, target, attrs, edgeTypes)
        )

        const filteredNodes = getSourceTargetNodes(
          graph,
          [nodeOrEdgeKey, ...filteredEdges],
          options
        )

        return {
          nodeKeys: [...transactionBlock.nodeKeys, ...filteredNodes],
          edgeKeys: [nodeOrEdgeKey, ...filteredEdges],
        }
      })
      .find((result) => result !== false)
  }
}

const getDomainBlockByNodeKey = (
  graph: ProbeGraph,
  nodeKey: string,
  nodeTypesEdgeTypes: NodeEdgeTypes[],
  options?: DomainBlockOptions
): GetTransactionBlockReturn => {
  const edgeTypes = getEdgesTypesByNodeType(graph, nodeKey, nodeTypesEdgeTypes)

  if (!edgeTypes) return false

  const { edges, nodes } = getEdgesByEdgeTypes(
    graph,
    nodeKey,
    edgeTypes,
    options
  )

  return {
    nodeKeys: [...nodes, nodeKey],
    edgeKeys: edges,
  }
}

export const getBoundedDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string
) =>
  getDomainBlock(graph, nodeOrEdgeKey, [
    [['cluster'], []],
    [['address'], []],
    [['flow'], []],
    [['evm_transaction'], ['evm_transaction']],
    [['utxo_transaction'], ['utxo_transaction']],
    [['demix'], ['demix']],
  ])

export const getNotGhostedBoundedDomainBlock = (
  graph: ProbeGraph,
  nodeOrEdgeKey: string
) =>
  getDomainBlock(
    graph,
    nodeOrEdgeKey,
    [
      [['cluster'], ['flow', 'address_belongs']],
      [['address_belongs'], ['cluster', 'address']],
      [['utxo_transaction_address'], ['utxo_transaction']],
      [
        ['address'],
        [
          'address_belongs',
          'evm_transaction',
          'transaction_address_belongs',
          'flow',
          'cross_chain_swap_flow',
        ],
      ],
      [['flow'], ['address', 'cluster']],
      [['evm_transaction'], ['evm_transaction']],
      [['utxo_transaction'], ['utxo_transaction']],
      [['demix'], ['demix']],
    ],
    {
      includeSource: true,
      includeTarget: true,
    }
  )

export default getDomainBlock
