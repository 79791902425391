import React from 'react'
import { ColDeprecated } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
import { Report } from '../Report'

import classnames from 'classnames/bind'
import styles from './GeneratingReportStep.scss'
import { AlertCaution } from '@clain/core/ui-kit'
import reportFacade from '../../ProbeGenerateReport.facade'
const cx = classnames.bind(styles)

export const GeneratingReportStep = () => {
  const { t } = useTranslation()

  return (
    <ColDeprecated gap={1} className={cx('ReportWrapper')}>
      <Report isReportGenerating={!reportFacade.generatingReportError} />
      <div className={cx('ReportWrapperFooter')}>
        <AlertCaution variant="info">
          {t('platform:generatingReportInfo')}
        </AlertCaution>
      </div>
    </ColDeprecated>
  )
}
