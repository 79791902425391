import { TagProps, TagVariantDeprecated } from '@clain/core/ui-kit'

export const STATUS_TO_TAG_VARIANT: Record<string, TagVariantDeprecated> = {
  in_progress: 'success',
  on_hold: 'default',
  archived: 'default',
}

export const STATUS_TO_TAG_LABEL = {
  in_progress: 'inProgress',
  on_hold: 'onHold',
  archived: 'archived',
} as const

export const STATUS_TO_TAG_TYPE_VARIANT: Record<
  string,
  Pick<TagProps, 'type' | 'variant'>
> = {
  in_progress: { type: 'highlight', variant: 'success' },
  on_hold: { type: 'highlight', variant: 'secondary' },
}
