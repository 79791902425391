import { randomInt, stubColor } from '@clain/core/Chart2/mock.utils'
import { DashboardOptions } from './dashboard.types'

export const generateMockData = (): DashboardOptions => {
  const data = new Array(randomInt(24, 32)).fill(null).map(() => {
    return {
      name: '',
      value: randomInt(60000, 450000),
      color: stubColor,
    }
  })

  return {
    data,
    topBy: 'balance',
    coin: 'btc',
    currency: 'src',
  }
}

export const createStaticPosition = () => {
  let prevTooltipX
  let prevTooltipY

  return (point, params, dom, rect, size) => {
    const padding = 8
    let x = rect.x + rect.width + padding
    let y = rect.y + rect.height / 2 - size.contentSize[1] / 2

    if (size.viewSize[0] - x < size.contentSize[0]) {
      x = rect.x - size.contentSize[0] - padding
    }

    if (size.viewSize[1] - y < size.contentSize[1]) {
      y = rect.y + rect.height - size.contentSize[1]
    }

    if (Math.abs(x - prevTooltipX) < 15) {
      return [prevTooltipX, prevTooltipY]
    }

    prevTooltipX = x
    prevTooltipY = y

    return [x, y]
  }
}
