import ct from 'countries-and-timezones'
import Fuse from 'fuse.js'

export type { Country } from 'countries-and-timezones'

const countries = Object.values(ct.getAllCountries())

const indexer = new Fuse(countries, {
  keys: [
    { name: 'id', weight: 0.8 },
    { name: 'name', weight: 0.2 },
  ],
})

export const getAllCountries = (search?: string) => {
  return search ? indexer.search(search).map(({ item }) => item) : countries
}
