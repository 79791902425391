import React, { FC, useMemo } from 'react'
import { Button, Counter, FiltersBar, Stack } from '@clainio/web-platform'
import { useRightOverlapSidebarStateSetter } from '@clain/core/state'
import { useTranslation } from 'react-i18next'

export type PresetsFiltersPanelProps = {
  presetsCount?: number
  isAlertActive?: boolean
  selectedPresetName?: string | null
}

export const PresetsFiltersPanel: FC<PresetsFiltersPanelProps> = ({
  selectedPresetName = null,
  presetsCount = 0,
}) => {
  const { t } = useTranslation()
  const setIsRightOverlapSidebarOpen = useRightOverlapSidebarStateSetter()

  const presetsButtonsProps = useMemo(
    () =>
      presetsCount
        ? ({
            endCustomIcon: () => (
              <Counter
                value={presetsCount}
                size={'sm'}
                variant={'secondary'}
                type={'solid'}
              />
            ),
          } as const)
        : ({ endIcon: 'Plus' } as const),
    [presetsCount]
  )

  return (
    <Stack align={'center'} gap={'lg'} justify={'end'}>
      {selectedPresetName && (
        <FiltersBar.Component>
          <FiltersBar.LabelButton label={t('platform:preset')} />
          <FiltersBar.ConditionButton
            label={t('platform:is')}
            selectable={false}
          />
          <FiltersBar.LabelButton
            label={selectedPresetName}
            variant={'value'}
          />
        </FiltersBar.Component>
      )}
      <Button
        onClick={() => setIsRightOverlapSidebarOpen(true)}
        variant={'outline'}
        color={'secondary'}
        size={'md'}
        {...presetsButtonsProps}
      >
        {t('platform:presets')}
      </Button>
    </Stack>
  )
}
