import { useEditor, EditorContent, Editor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import Mention from '@tiptap/extension-mention'
import Bold from '@tiptap/extension-bold'
import React, { useCallback, useEffect } from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as BackgroundColorIcon } from '../assets/editor_background_color.svg'
import { ReactComponent as BlockquoteIcon } from '../assets/editor_blockquote.svg'
import { ReactComponent as BoldIcon } from '../assets/editor_bold.svg'
import { ReactComponent as ClearFormattingIcon } from '../assets/editor_clear_formatting.svg'
import { ReactComponent as InsertLinkIcon } from '../assets/editor_insertLink.svg'
import { ReactComponent as ItalicIcon } from '../assets/editor_italic.svg'
import { ReactComponent as OrderedListIcon } from '../assets/editor_ordered_list.svg'
import { ReactComponent as StrikethroughIcon } from '../assets/editor_strikethrough.svg'
import { ReactComponent as UnderlineIcon } from '../assets/editor_underline.svg'
import { ReactComponent as UnorderedListIcon } from '../assets/editor_unordered_list.svg'
import { ReactComponent as AttachFileIcon } from '../assets/attach_file.svg'

import './Tiptap.scss'
import styles from './Tiptap.module.scss'

const cx = classnames.bind(styles)

interface MenuBarProps {
  editor: Editor
  mini: boolean
  onFileAttachClick?: () => void
}

const MenuBar: React.FC<MenuBarProps> = ({
  editor,
  mini,
  onFileAttachClick,
}) => {
  const setLink = useCallback(() => {
    if (editor) {
      const previousUrl = editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      if (url === null) {
        return
      }

      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run()
        return
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <div className={cx('TiptapMenu')}>
      <button
        type="button"
        title="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={cx(
          { ['is-active']: editor.isActive('bold') },
          'EditorButton'
        )}
      >
        <BoldIcon />
      </button>
      <button
        type="button"
        title="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={cx(
          { ['is-active']: editor.isActive('italic') },
          'EditorButton'
        )}
      >
        <ItalicIcon />
      </button>
      <button
        type="button"
        title="Underline"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={cx(
          { ['is-active']: editor.isActive('underline') },
          'EditorButton'
        )}
      >
        <UnderlineIcon />
      </button>
      <button
        type="button"
        title="Strikethrough"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={cx(
          { ['is-active']: editor.isActive('strike') },
          'EditorButton'
        )}
      >
        <StrikethroughIcon />
      </button>
      {!mini && (
        <button
          type="button"
          title="Clear Formatting"
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className={cx('EditorButton')}
        >
          <ClearFormattingIcon />
        </button>
      )}

      <button
        type="button"
        title="Highlight"
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        className={cx(
          { ['is-active']: editor.isActive('highlight') },
          'EditorButton'
        )}
      >
        <BackgroundColorIcon />
      </button>
      {onFileAttachClick && (
        <button
          type="button"
          title="Attach File"
          onClick={onFileAttachClick}
          className={cx('EditorButton')}
        >
          <AttachFileIcon />
        </button>
      )}
      {!mini && (
        <button
          type="button"
          title="Quote"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={cx(
            { ['is-active']: editor.isActive('blockquote') },
            'EditorButton'
          )}
        >
          <BlockquoteIcon />
        </button>
      )}
      {!mini && (
        <button
          type="button"
          title="Unordered List"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={cx(
            { ['is-active']: editor.isActive('orderedList') },
            'EditorButton'
          )}
        >
          <UnorderedListIcon />
        </button>
      )}
      {!mini && (
        <button
          type="button"
          title="Ordered List"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={cx(
            { ['is-active']: editor.isActive('bulletList') },
            'EditorButton'
          )}
        >
          <OrderedListIcon />
        </button>
      )}
      {!mini && (
        <button
          type="button"
          title="Add Link"
          onClick={setLink}
          className={cx(
            { ['is-active']: editor.isActive('link') },
            'EditorButton'
          )}
        >
          <InsertLinkIcon />
        </button>
      )}
      {/* <button
        type="button"
        title="Unset Link"
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive('link')}
      >
        <InsertLinkIcon />
      </button> */}
    </div>
  )
}

interface TiptapProps {
  className?: string
  value: string
  placeholder?: string
  mini: boolean
  onFileAttachClick: () => void
  onChange: (value: string) => void
  onMount: () => void
}

const Tiptap: React.FC<TiptapProps> = ({
  className,
  value,
  placeholder,
  mini,
  onFileAttachClick,
  onChange,
  onMount,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        code: false,
        codeBlock: false,
        dropcursor: false,
        gapcursor: false,
        heading: false,
        horizontalRule: false,
      }),
      Bold,
      Underline,
      Link.configure({
        openOnClick: false,
      }),
      Highlight.configure({
        multicolor: true,
      }),
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
      }),
    ],
    onCreate: () => {
      onMount()
    },
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML())
    },
    autofocus: true,
    content: value,
    editorProps: {
      attributes: {
        class: cx(className, 'Editor', { EditorMini: mini }),
      },
      handleDOMEvents: {
        keydown: (view, event) => {
          if (event.key === 'Enter') {
            return true
          }
          return false
        },
      },
    },
  })

  useEffect(() => {
    const isSame = editor && editor.getHTML() === value

    if (!editor || isSame) {
      return
    }

    editor.commands.setContent(value, false)
  }, [editor, value])

  return (
    <div className={cx('Tiptap')}>
      <EditorContent editor={editor} />
      <MenuBar
        editor={editor}
        mini={mini}
        onFileAttachClick={onFileAttachClick}
      />
    </div>
  )
}

export default Tiptap
