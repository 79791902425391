import { IconButton } from '@clain/core/ui-kit'
import { useSearchEntitiesStateSetter } from '@clain/core/state'

interface NavSearchProps {
  className?: string
}

const NavSearch = ({ className }: NavSearchProps) => {
  const setIsSearchEntitiesModalOpen = useSearchEntitiesStateSetter()

  return (
    <div style={{ display: 'inline-flex' }} className={className}>
      <IconButton
        variant="text"
        iconVariant="Search"
        size="md"
        className={className}
        onClick={() => setIsSearchEntitiesModalOpen(true)}
      />
    </div>
  )
}

export default NavSearch
