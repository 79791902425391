import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as ApproveIcon } from '@clain/core/assets/approve.svg'

import BaseCard from '../BaseCard'
import { CaseStatusDiff, User } from '../../../../types'

interface StatusChangeCardProps {
  date: Date
  user: User
  diff: CaseStatusDiff
  className?: string
}

const StatusChangeCard: React.FC<StatusChangeCardProps> = ({
  date,
  className,
  user,
  diff,
}) => {
  const { t } = useTranslation()

  return (
    <BaseCard
      date={date}
      label={t('platform:status')}
      icon={ApproveIcon}
      className={className}
      title={
        <Typography variant="body3">
          {user.name}{' '}
          <Typography variant="body1">
            {t('platform:changedStatusTo')}{' '}
          </Typography>
          {t(`platform:${diff.new}`)}
        </Typography>
      }
    />
  )
}

export default StatusChangeCard
