import { useFilterSlice } from '../TransactionEvmFilters.service'
import { useGetFilterLabels } from '@clain/core/ui-kit'
import { useTransactionViewModel } from '../../context'
import { useTranslation } from 'react-i18next'

export const useAssetFilterData = () => {
  const { t } = useTranslation()
  const [data, { setFilter }] = useFilterSlice('asset.data')
  const [, { resetFilter }] = useFilterSlice('asset')
  const [, { resetFilter: resetAssetValueFilter }] =
    useFilterSlice('assetValue')
  const {
    tokens,
    searchAssetLoading: searchLoading,
    disabledAssetStaticSearch,
  } = useTransactionViewModel()

  const [condition] = useFilterSlice('asset.condition')
  const { isMultiple, multipleLabel } = useGetFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: t('platform:asset_one'),
  })

  const handleResetFilter = () => {
    resetFilter()
    resetAssetValueFilter()
  }

  return {
    isMultiple,
    multipleLabel,
    selectedTokens: data,
    menuListData: tokens,
    setFilter,
    resetFilter: handleResetFilter,
    searchLoading,
    disabledInnerSearch: disabledAssetStaticSearch,
  }
}

export const useSelectedAssetData = () => {
  const { selectedTokens } = useAssetFilterData()

  return selectedTokens?.length ? selectedTokens[0] : null
}
