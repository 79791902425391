import { comparators, ContainerSize, FilterBarTypes } from '@clain/core/ui-kit'
import { ComparatorsValueFilterItem } from './ComparatorsValueFilter.types'
import { useTranslation } from 'react-i18next'

export const useComparatorsValueFilter = () => {
  const { t } = useTranslation()

  const optionsMap = {
    [comparators.isGreater.value]: t('platform:greaterThan'),
    [comparators.isLess.value]: t('platform:lessThan'),
    [comparators.is.value]: t('platform:equalTo'),
    [comparators.isBetween.value]: t('platform:between'),
  }

  const options: ComparatorsValueFilterItem[] = [
    {
      label: optionsMap[comparators.isGreater.value],
      value: comparators.isGreater.value,
    },
    {
      label: optionsMap[comparators.isLess.value],
      value: comparators.isLess.value,
    },
    {
      label: optionsMap[comparators.is.value],
      value: comparators.is.value,
    },
    {
      label: optionsMap[comparators.isBetween.value],
      value: comparators.isBetween.value,
    },
  ]

  return {
    optionsMap,
    options,
  }
}

export const CONTAINER_ITEM_SIZE: ContainerSize = 'md'

export const TEXT_FIELD_SIZE: Record<FilterBarTypes.Size, ContainerSize> = {
  sm: 'md',
  md: 'lg',
}
