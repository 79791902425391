import {
  startOfDay,
  endOfDay,
  fromUnixTime,
  addMinutes,
  isEqual,
  getUnixTime,
} from 'date-fns'
import { getWeekStartByRegion } from 'weekstart/full'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'

import defaultLocale from './locale'

export const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const getWeekStartDay = () => getWeekStartByRegion(getCurrentTimezone())

export const isEqualDay = (a: Date, b: Date) =>
  isEqual(startOfDay(a), startOfDay(b))

export const startOfDayUnixTime = (value: number | Date) => {
  // приводит unix timestamp к локальному времени с учетом часового пояса
  const zonedDate = typeof value === 'number' ? fromUnixTime(value) : value

  const currentTimeZoneOffset = zonedDate.getTimezoneOffset()

  // корректируем смещение часового пояса относительно UTC
  const fixedDate = addMinutes(zonedDate, currentTimeZoneOffset)
  const startOfDayDate = startOfDay(fixedDate)

  // и так как дата по прежнему с часовым поясом, то берем значения напрямую
  const startOfDayUTCms = Date.UTC(
    startOfDayDate.getFullYear(),
    startOfDayDate.getMonth(),
    startOfDayDate.getDate(),
    0,
    0,
    0
  )

  return startOfDayUTCms / 1000
}

export const endOfDayUnixTime = (value: number | Date) => {
  // приводит unix timestamp к локальному времени с учетом часового пояса
  const zonedDate = typeof value === 'number' ? fromUnixTime(value) : value

  const currentTimeZoneOffset = zonedDate.getTimezoneOffset()

  // корректируем смещение часового пояса относительно UTC
  const fixedDate = addMinutes(zonedDate, currentTimeZoneOffset)
  const startOfDayDate = endOfDay(fixedDate)

  // и так как дата по прежнему с часовым поясом, то берем значения напрямую
  const startOfDayUTCms = Date.UTC(
    startOfDayDate.getFullYear(),
    startOfDayDate.getMonth(),
    startOfDayDate.getDate(),
    23,
    59,
    59
  )

  return startOfDayUTCms / 1000
}
interface FormatDateConfig {
  year?: string
  month?: string
  day?: string
  minute?: string
  hour?: string
  hour12?: boolean
}

export type FormatDatePreset =
  | 'date-time'
  | 'date'
  | 'date-numeric'
  | 'date-time-numeric'
  | 'time'
  | FormatDateConfig

const dateTimePreset: FormatDateConfig = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  minute: 'numeric',
  hour: 'numeric',
}

const datePreset: FormatDateConfig = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const dateNumericPreset: FormatDateConfig = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

const timePreset: FormatDateConfig = {
  minute: 'numeric',
  hour: 'numeric',
}

const dateTimeNumericPreset: FormatDateConfig = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: 'numeric',
  hour: 'numeric',
}

const presets = {
  'date-time': dateTimePreset,
  date: datePreset,
  'date-numeric': dateNumericPreset,
  'date-time-numeric': dateTimeNumericPreset,
  time: timePreset,
}

export const formatDate = (
  date: Date,
  options: FormatDatePreset = 'date-time'
) => {
  const opts = typeof options === 'string' ? presets[options] : options

  if (date && isNaN(date.getDate())) {
    return ''
  }
  if (!(date instanceof Date) || !isFinite(date.getTime())) {
    console.warn('Invalid date provided to formatDate:', date)
    return ''
  }

  // @ts-ignore
  return new Intl.DateTimeFormat(defaultLocale, opts).format(date)
}

export const useFormatDate = (timeZone = 'utc'): typeof formatDate => {
  let localTz = timeZone
  if (!timeZone) {
    localTz = 'utc'
  }
  return (date, options) => formatDate(toZonedTime(date, localTz), options)
}

export const useTranslateDate = (timeZone = 'utc') => {
  let localTz = timeZone
  if (!timeZone) {
    localTz = 'utc'
  }
  return (date: Date) => fromZonedTime(date, localTz)
}

/**
 * Converts a date string or Date object to a Unix timestamp string.
 * @param value - The date string or Date object to be converted.
 * @param timeZone - The time zone of the date string or Date object.
 * @returns The Unix timestamp as a number.
 */
export const convertToUnixTimestamp = (
  value: Date | string | number,
  timeZone = 'utc'
): number => getUnixTime(fromZonedTime(value, timeZone))

/**
 * Converts a Unix timestamp (string or number) to a Date object in the specified time zone.
 * @param timestamp - The Unix timestamp as a string or number.
 * @param timeZone - The time zone to interpret the timestamp in.
 * @returns The corresponding Date object.
 */
export const convertFromUnixTimestamp = (
  timestamp: string | number,
  timeZone = 'UTC'
): Date => {
  const timestampNumber =
    typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp
  const date = new Date(timestampNumber * 1000)
  return toZonedTime(date, timeZone)
}

/**
 * Converts a Unix timestamp (string or number) to a Date object in the local timezone.
 * @param timestamp - The Unix timestamp as a string or number.
 * @returns The corresponding Date object in the local timezone.
 */
export const convertFromUnixTimestampToLocal = (
  timestamp: string | number
): Date => {
  const timestampNumber =
    typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp
  return new Date(timestampNumber * 1000)
}
