import React from 'react'
import {
  Button,
  Modal,
  ModalSlot,
  RowDeprecated,
  ColDeprecated,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames/bind'
import styles from './index.scss'
import { UnsavedGraphModalTypes } from './types'
const cx = classnames.bind(styles)

export const UnsavedGraphModal: React.FC<UnsavedGraphModalTypes> = ({
  showSaveToCaseModal,
  saveNotShowAgain,
  visible,
  onClose,
}) => {
  const { t } = useTranslation()

  const body: ModalSlot = () => {
    return (
      <Stack gap={'xxxxl'} direction={'column'} padding={['xs', 'md', 'md']}>
        <ColDeprecated>
          <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
            {t('platform:unsavedGraphModalDescription')}
          </TypographyNew>
        </ColDeprecated>
        <RowDeprecated>
          <Stack
            align="center"
            justify="space-between"
            padding={['md', 'none', 'none']}
          >
            <Button
              size="lg"
              variant="solid"
              color="critical"
              onClick={() => {
                saveNotShowAgain()
              }}
            >
              {t('platform:dontShowAgain')}
            </Button>
            <Button
              size="lg"
              variant="solid"
              color="primary"
              type="submit"
              onClick={showSaveToCaseModal}
            >
              {t('platform:saveGraph')}
            </Button>
          </Stack>
        </RowDeprecated>
      </Stack>
    )
  }

  return (
    <Modal
      closable={true}
      size="md"
      visible={visible}
      target={null}
      onRequestClose={onClose}
      title={
        <TypographyNew
          variant={'heading300Accent'}
          color={'onBackgroundBase'}
          className={cx('title', 'unsaved-title')}
        >
          {t('platform:unsavedChangesAlert')}
        </TypographyNew>
      }
    >
      {body}
    </Modal>
  )
}
