import { i18next } from '@platform/i18Manager'

export type Language = 'en' | 'fr' | 'ge' | 'du'

export const language = (): Record<Language, string> => ({
  en: i18next.t('platform:languageEnglish'),
  fr: i18next.t('platform:languageFrench'),
  ge: i18next.t('platform:languageGerman'),
  du: i18next.t('platform:languageDutch'),
})

export const tabsOptions = (): TabOption[] => [
  { value: 'en', children: language().en },
  { value: 'fr', children: language().fr },
  { value: 'ge', children: language().ge },
  { value: 'du', children: language().du },
]

export type TabOption = {
  value: Language
  children: string
}

export const actionButtonWidth = 137

export const regenerateButtonTooltipMessage = i18next.t(
  'platform:regenerateButtonTooltipMessage'
)
