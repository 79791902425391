import { toZonedTime } from 'date-fns-tz'

import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'

import { Label, RhombusSatellite } from '@clain/graph/src/types'
import {
  CreateAlertAddonParams,
  CreateAlertState,
} from './ProbeNode.utils.types'
import { formatDate, getCurrentTimezone } from '@clain/core/utils/date'
import { ITheme } from '../../models'

const ALERT_STATE: Record<CreateAlertState, string> = {
  normal: '#808DFF',
  triggered: '#F04275',
  inactive: '#BDC8DF',
}

export const createAlertAddon = ({
  state = 'normal',
  count = 0,
  theme,
}: CreateAlertAddonParams & { theme: ITheme }): Pick<Label, 'rightAddon'> => {
  const iconSize = parseInt(theme.getToken(['icon', 'xxxs', 'size']))

  return count > 0
    ? {
        rightAddon: {
          gap: 2,
          label: {
            text: '',
            fontSize: 10,
            color: '#fff',
            fill: ALERT_STATE[state],
            icon: icon({ variant: 'notificationFilled' }),
            iconColor: theme.getToken(['icon', 'on', 'primary', 'color']),
            iconWidth: iconSize,
            iconHeight: iconSize,
            borderRadius: 4,
            padding: [3, 0],
            rightAddon: {
              gap: 3,
              label: {
                text: `${count}`,
                color: '#fff',
                fontSize: 10,
              },
            },
          },
        },
      }
    : {}
}

const DEMIX_ACTION_BACKGROUND_COLOR = '#f4cace'
const DEMIX_ACTION__BACKGROUND_COLOR_HOVERED = '#f0b8be'

const DEMIX_ACTION_BORDER_COLOR = '#D94D5C'

export const createDemixActionAddon = ({
  count = 0,
  isHovered = false,
  theme,
}: {
  count: number
  isHovered: boolean
  theme: ITheme
}): Pick<Label, 'rightAddon'> =>
  count > 0
    ? {
        rightAddon: {
          gap: 0,
          label: {
            text: `${count}`,
            fontSize: 12,
            lineHeight: 16,
            color: 'rgba(15, 19, 27, 1)',
            borderRadius: 4,
            height: 21,
            border: {
              color: DEMIX_ACTION_BORDER_COLOR,
              width: 1,
            },
            padding: [6, 0],
            fill: isHovered
              ? DEMIX_ACTION__BACKGROUND_COLOR_HOVERED
              : DEMIX_ACTION_BACKGROUND_COLOR,
            rightAddon: {
              gap: 4,
              label: {
                padding: [0, 0],
                text: '',
                color: 'rgba(75, 96, 138, 1)',
                fill: 'transparent',
                fontSize: 12,
                height: 14,
                icon: icon({ variant: 'demix' }),
                iconColor: theme.getToken([
                  'icon',
                  'on',
                  'background',
                  'variant1',
                  'color',
                ]),
                iconWidth: 14,
                iconHeight: 14,
              },
            },
          },
        },
      }
    : {}

export const createNodeCreateAtLabel = ({
  date,
  timezone,
}: {
  date: string | number
  timezone: string
}): Label => {
  return {
    fontSize: 10,
    lineHeight: 14,
    borderRadius: 4,
    text: formatDate(
      toZonedTime(
        Number(date) ? new Date(+date * 1000) : date,
        timezone || getCurrentTimezone()
      ),
      'date-time'
    ),
    padding: [4, 2],
    fill: '#E7EDF9CC',
    color: 'rgba(75, 96, 138, 1)',
  }
}

export const createCrossChainAction = (
  isHovered = false,
  theme: ITheme
): RhombusSatellite => {
  return {
    type: 'rhombus',
    id: 'crossChainAction',
    size: 28,
    fill: !isHovered ? '#F8E4FF' : '#EEC6FF',
    icon: icon({ variant: 'bridge' }),
    iconColor: theme.getToken([
      'icon',
      'on',
      'background',
      'variant1',
      'color',
    ]),
    iconWidth: 14,
    iconHeight: 14,
    border: {
      width: 1,
      color: 'rgba(166, 72, 230, 1)',
    },
  }
}
