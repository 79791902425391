import {
  useLocation,
  useNavigate,
  useMatch,
  useParams,
  useSearchParams,
  createBrowserRouter,
  redirect,
  generatePath,
  RouterProvider,
  Navigate,
  Route,
  Outlet,
  NavigateFunction,
} from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import { isNil, mapObjIndexed, reject } from 'ramda'
import { getUnixTime } from 'date-fns'
export type SearchParams = { [key: string]: any }

export const parseSearchParams = <T extends SearchParams>(
  searchString: string
): T => {
  const params = new URLSearchParams(searchString)
  const result: any = {}

  for (const [key, val] of params) {
    const targetObj = result
    const keys = key.split(/[\[\]]/).filter((k) => k.length > 0)
    const lastKeyIndex = keys.length - 1

    keys.reduce((acc, currentKey, index) => {
      const decodedValue = decodeURIComponent(val)
      // Check if we are at the last key and if the original key indicates an array
      const isArray = key.endsWith('[]') && index === lastKeyIndex

      if (index === lastKeyIndex) {
        if (isArray) {
          if (!acc[currentKey]) {
            acc[currentKey] = [decodedValue]
          } else {
            acc[currentKey].push(decodedValue)
          }
        } else {
          acc[currentKey] = decodedValue
        }
      } else {
        // Ensure a nested structure exists for the next level of keys
        if (!acc[currentKey]) {
          acc[currentKey] = {}
        }
        return acc[currentKey]
      }

      return acc // This return is needed to satisfy TypeScript, but isn't used
    }, targetObj)
  }

  return result as T
}

export const toSearchParamsString = <T extends SearchParams>(
  params: T,
  prefix = ''
): string => {
  const parts = mapObjIndexed((value, key) => {
    const fullKey = prefix ? `${prefix}[${key}]` : key // Adjust key for nested objects
    if (Array.isArray(value) && !isEmpty(value)) {
      return value
        .map((val) => {
          return `${fullKey}[]=${encodeURIComponent(val)}`
        })
        .join('&')
    } else if (value instanceof Date) {
      return `${fullKey}=${encodeURIComponent(getUnixTime(value))}`
    } else if (typeof value === 'object' && value !== null && !isEmpty(value)) {
      return toSearchParamsString(value, fullKey)
    } else if (
      !isNil(value) &&
      value !== '' &&
      !Array.isArray(value) &&
      typeof value !== 'object'
    ) {
      return `${fullKey}=${encodeURIComponent(value.toString())}`
    }
    return null
  }, params)

  return reject(isNil, Object.values(parts)).join('&')
}

export const useObjectFormattedSearchParams = <T extends SearchParams>() => {
  const location = useLocation()
  const navigate = useNavigate()

  const replaceSearchParams = (params: T) => {
    navigate(`${location.pathname}?${toSearchParamsString<T>(params)}`)
  }

  return [parseSearchParams<T>(location.search), replaceSearchParams] as const
}

export type { NavigateFunction }

export {
  useLocation,
  useNavigate,
  useMatch,
  useParams,
  useSearchParams,
  createBrowserRouter,
  redirect,
  generatePath,
  RouterProvider,
  Navigate,
  Route,
  Outlet,
}
