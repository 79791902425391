import { ProbeViewModel } from '../../ProbeViewModel'
import { action, makeObservable } from 'mobx'
import {
  GraphEntityEvent,
  EntitySelectPayload,
} from '../GraphEntityEvent.types'
import { getBoundedDomainBlock } from '../../../utils/getDomainBlock'

export class EntitySelectController {
  constructor(private probeVM: ProbeViewModel) {
    makeObservable(this)
  }

  @action
  public onSelectArea = ({
    payload: { nodeKeys, edgeKeys, isExpanding },
  }: GraphEntityEvent<EntitySelectPayload>) => {
    const nodeKeysSet = new Set(nodeKeys)
    const edgeKeysSet = new Set(edgeKeys)

    nodeKeys.forEach((nodeKey) => {
      const transactionBlock = getBoundedDomainBlock(
        this.probeVM.app.graph,
        nodeKey
      )

      if (transactionBlock) {
        transactionBlock.edgeKeys.forEach((edgeKey) => {
          edgeKeysSet.add(edgeKey)
        })
      }
    })

    edgeKeys.forEach((edgeKey) => {
      const transactionBlock = getBoundedDomainBlock(
        this.probeVM.app.graph,
        edgeKey
      )

      if (transactionBlock) {
        transactionBlock.nodeKeys.forEach((nodeKey) => {
          nodeKeysSet.add(nodeKey)
        })

        transactionBlock.edgeKeys.forEach((edgeKey) => {
          edgeKeysSet.add(edgeKey)
        })
      }
    })

    let nodeKeysSetToApply: Set<string> = nodeKeysSet
    let edgeKeysSetToApply: Set<string> = edgeKeysSet

    if (isExpanding) {
      nodeKeysSetToApply = new Set([
        ...nodeKeysSet,
        ...this.probeVM.probeState.selectedNodeIds,
      ])
      edgeKeysSetToApply = new Set([
        ...edgeKeysSet,
        ...this.probeVM.probeState.selectedEdgeIds,
      ])
    }

    if (nodeKeysSetToApply.size) {
      this.probeVM.setSelectedNodeIds(nodeKeysSetToApply)
      this.probeVM.setSelectedEdgeIds(edgeKeysSetToApply)
    }
  }

  @action
  public onUnSelectArea = ({
    payload: { nodeKeys, edgeKeys },
  }: GraphEntityEvent<EntitySelectPayload>) => {
    nodeKeys.forEach((nodeKey) => {
      const transactionBlock = getBoundedDomainBlock(
        this.probeVM.app.graph,
        nodeKey
      )

      if (transactionBlock) {
        this.probeVM.probeState.selectedNodeIds.delete(nodeKey)
        transactionBlock.edgeKeys.forEach((edgeKey) => {
          this.probeVM.probeState.selectedEdgeIds.delete(edgeKey)
        })
      } else {
        this.probeVM.probeState.selectedNodeIds.delete(nodeKey)
      }
    })

    edgeKeys.forEach((edgeKey) => {
      const transactionBlock = getBoundedDomainBlock(
        this.probeVM.app.graph,
        edgeKey
      )

      if (transactionBlock) {
        transactionBlock.nodeKeys.forEach((nodeKey) => {
          this.probeVM.probeState.selectedNodeIds.delete(nodeKey)
        })

        transactionBlock.edgeKeys.forEach((edgeKey) => {
          this.probeVM.probeState.selectedEdgeIds.delete(edgeKey)
        })
      } else {
        this.probeVM.probeState.selectedEdgeIds.delete(edgeKey)
      }
    })
  }
}
