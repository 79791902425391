import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import useVm from '@clain/core/useVm'
import { Typography } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { LightBox } from '@clain/core/ui-kit'
import { FilePreviewStub } from '@clain/core/ui-kit'

import { FilesViewModel } from './FilesViewModel'
import { useCtx } from '../../../../ctx'
import FileItem from './FileItem'

import styles from './index.scss'

const cx = classnames.bind(styles)

const Files: React.FC = () => {
  const { t } = useTranslation()
  const filesVm = useVm(FilesViewModel, useCtx())

  const lightBoxItems = filesVm.files?.map((attachment) => {
    const extArr = attachment.name.split('.')
    const ext = extArr.length > 1 ? extArr[extArr.length - 1] : '?'

    return {
      title: attachment.name,
      content: /^image\/[png|jpg|jpeg]/gi.test(attachment.contentType) ? (
        <img src={attachment.file} />
      ) : (
        <FilePreviewStub ext={ext} />
      ),
      downloadLink: attachment.file,
    }
  })

  if (filesVm.showStub) {
    return (
      <Typography variant="heading2" color="grey3" className={cx('FilesStub')}>
        {t('platform:noFilesUploaded')}
      </Typography>
    )
  }

  return (
    <ColDeprecated>
      <Typography variant="heading3" color="grey1">
        {t('platform:uploadedFiles')}
      </Typography>
      <Container gap={[2, 1.5]} className={cx('FilesContainer')}>
        {filesVm.showEarlierFilesGroupOnly && (
          <ColDeprecated gap={0.5}>
            {filesVm.files.map((file, index) => (
              <FileItem
                key={index}
                file={file}
                onClick={() => filesVm.handleClickOnFileItem(index)}
              />
            ))}
          </ColDeprecated>
        )}

        {!filesVm.showEarlierFilesGroupOnly && (
          <ColDeprecated gap={2.5}>
            {filesVm.groupedFiles.day.length > 0 && (
              <ColDeprecated>
                <Typography variant="body2" color="grey3">
                  {t('platform:today')}
                </Typography>
                <ColDeprecated gap={0.5}>
                  {filesVm.groupedFiles.day.map((file) => (
                    <FileItem
                      key={file.index}
                      file={file}
                      onClick={() => filesVm.handleClickOnFileItem(file.index)}
                    />
                  ))}
                </ColDeprecated>
              </ColDeprecated>
            )}

            {filesVm.groupedFiles.week.length > 0 && (
              <ColDeprecated>
                <Typography variant="body2" color="grey3">
                  {t('platform:previousWeek')}
                </Typography>
                <ColDeprecated gap={0.5}>
                  {filesVm.groupedFiles.week.map((file) => (
                    <FileItem
                      key={file.index}
                      file={file}
                      onClick={() => filesVm.handleClickOnFileItem(file.index)}
                    />
                  ))}
                </ColDeprecated>
              </ColDeprecated>
            )}

            {filesVm.groupedFiles.month.length > 0 && (
              <ColDeprecated>
                <Typography variant="body2" color="grey3">
                  {t('platform:previousMonth')}
                </Typography>
                <ColDeprecated gap={0.5}>
                  {filesVm.groupedFiles.month.map((file) => (
                    <FileItem
                      key={file.index}
                      file={file}
                      onClick={() => filesVm.handleClickOnFileItem(file.index)}
                    />
                  ))}
                </ColDeprecated>
              </ColDeprecated>
            )}

            {filesVm.groupedFiles.earlier.length > 0 && (
              <ColDeprecated>
                <Typography variant="body2" color="grey3">
                  {t('platform:earlier')}
                </Typography>
                <ColDeprecated gap={0.5}>
                  {filesVm.groupedFiles.earlier.map((file) => (
                    <FileItem
                      key={file.index}
                      file={file}
                      onClick={() => filesVm.handleClickOnFileItem(file.index)}
                    />
                  ))}
                </ColDeprecated>
              </ColDeprecated>
            )}
          </ColDeprecated>
        )}
      </Container>
      <LightBox
        isActive={filesVm.isLightboxActive}
        setIsActive={filesVm.setIsLightboxActive}
        activeIndex={filesVm.lightboxIndex}
        setActiveIndex={filesVm.setLightboxIndex}
        items={lightBoxItems}
      />
    </ColDeprecated>
  )
}

export default observer(Files)
