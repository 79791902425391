import { NodeOptions } from '../../types'

const getNodeLargestOrbitSize = (nodeOptions: NodeOptions): number => {
  return (
    nodeOptions?.orbits?.reduce((result, nodeOrbit) => {
      if (nodeOrbit.virtual) {
        return result
      }

      const size = nodeOrbit.size ?? 0
      const borderWidth = nodeOrbit.border?.width ?? 0

      const nodeOrbitSize =
        nodeOptions.shape === 'circle'
          ? size + borderWidth
          : size / 2 + borderWidth

      return Math.max(nodeOrbitSize, result)
    }, 0) ?? 0
  )
}

export default getNodeLargestOrbitSize
