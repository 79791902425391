import { useFilterNameList } from './TrxFilters.service'

import { ScoreFilter } from './ScoreFilter'
import { RbfFilter } from './RbfFilter'
import { SegwitFilter } from './SegwitFilter'
import { LocktimeFilter } from './LocktimeFilter'
import { VersionFilter } from './VersionFilter'
import { CompressionFilter } from './CompressionFilter'
import { ChangeFilter } from './ChangeFilter'
import { TypeFilter } from './TypeFilter'

import { ComparatorFilter } from './ComparatorFilter'
import { filtersPropsById } from './TrxFilter.const'
import { MultisigFilter } from './MultisigFilter'

export const CreateFilter = (
  filter: ReturnType<typeof useFilterNameList>[number]
) => {
  const { ComparatorFilterPropsById, MultisigFilterPropsById } =
    filtersPropsById()
  switch (filter) {
    case 'amount':
    case 'input_amount':
    case 'output_amount':
    case 'numberInputs':
    case 'numberOutputs':
    case 'vSize':
    case 'size':
    case 'fee':
    case 'feeByte':
      return (
        <ComparatorFilter.Container
          key={filter}
          {...ComparatorFilterPropsById[filter]}
        />
      )
    case 'rbf':
      return <RbfFilter.Container />
    case 'segwit':
      return <SegwitFilter.Container />
    case 'locktime':
      return <LocktimeFilter.Container />
    case 'version':
      return <VersionFilter.Container />
    case 'input_score':
      return <ScoreFilter.Container id={filter} title={'Input score'} />
    case 'output_score':
      return <ScoreFilter.Container id={filter} title={'Output score'} />
    case 'input_compression':
      return <CompressionFilter.Container id={filter} title={'Input'} />
    case 'output_compression':
      return <CompressionFilter.Container id={filter} title={'Output'} />
    case 'input_type':
      return <TypeFilter.Container id={filter} title={'Input type'} />
    case 'input_multisig':
      return (
        <MultisigFilter.Container
          key={filter}
          {...MultisigFilterPropsById[filter]}
          label="Input"
        />
      )
    case 'output_type':
      return <TypeFilter.Container id={filter} title={'Output type'} />
    case 'output_change':
      return <ChangeFilter.Container />
    case 'output_multisig':
      return (
        <MultisigFilter.Container
          key={filter}
          {...MultisigFilterPropsById[filter]}
          label="Output"
        />
      )
  }
}
