import React, { useEffect } from 'react'
import TeamSettings from '../components/Settings/TeamSettings'
import { useAppLayoutResetter } from '@clain/core/state'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

export const PageTeamSettings = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])

  return (
    <ErrorBoundaryContainer>
      <TeamSettings />
    </ErrorBoundaryContainer>
  )
}

export default PageTeamSettings
