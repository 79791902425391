import { InjectComponentModel } from '@clain/core/utils'
import { BlockchainMenu } from '../ui'
import { useCurrencyFilter } from '../hooks/useCurrencyFilter'
import { ICurrencyFilter } from '../types'

export const CurrencyFilter = InjectComponentModel<ICurrencyFilter>(
  BlockchainMenu,
  {
    useFactory: useCurrencyFilter,
  }
)
