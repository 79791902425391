import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import { KeyValue } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { useFormatDate } from '../../hooks'
import { useFormatMoney, formatDifficulty } from '@clain/core/utils/format'
import { getBlockSlug } from '@clain/core/utils/slug'
import { formatNumber as formatNumberU } from '@clain/core/utils/format'
import { useTranslation } from 'react-i18next'

import styles from './index.scss'

import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { CoinTypeUTXO } from '../../types/coin'
import { RawBlockDataBlockUTXO } from '../../apiServices'
import { Amount, TypographyNew } from '@clainio/web-platform'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

export interface BlockDetailsBtcProps {
  block: RawBlockDataBlockUTXO
  currency: 'src' | 'usd'
  loading: boolean
  type?: CoinTypeUTXO
}

const BlockDetailsBtc = ({
  block,
  currency,
  loading,
  type = 'btc',
}: BlockDetailsBtcProps) => {
  const { t } = useTranslation()
  const formatMoney = useFormatMoney()
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const formatDate = useFormatDate()

  return (
    // @ts-expect-error
    <KeyValue
      className={cx('blockTable')}
      data={block}
      loading={loading}
      withSeparator
      keyWidth={
        block?.mweb_total_transactions || block?.mweb_amount ? 160 : 100
      }
    >
      {[
        [
          {
            key: t('platform:height'),
            value: ({ height }) => (
              <Amount
                value={formatNumberU(height, 0)}
                fullValue={formatNumberU(height, 0)}
                copyValue={formatNumberU(height, 0)}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:confirmations'),
            value: ({ confirmations }) => (
              <Amount
                value={formatNumberU(confirmations, 0)}
                fullValue={formatNumberU(confirmations, 0)}
                copyValue={formatNumberU(confirmations, 0)}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:size'),
            value: ({ size }) => size,
          },
          {
            key: t('platform:transactions'),
            value: ({ trx_count }) => (
              <Amount
                value={formatNumber(trx_count, 0)}
                fullValue={formatNumberU(trx_count, 0)}
                copyValue={formatNumberU(trx_count, 0)}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:version'),
            value: ({ version }) => version,
          },
          {
            key: t('platform:difficulty'),
            value: ({ difficulty }) => (
              <Amount
                value={formatDifficulty(difficulty)}
                fullValue={formatNumberU(difficulty, 0)}
                copyValue={formatNumberU(difficulty, 0)}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:totalOut'),
            value: ({ total_sent_usd, total_sent }) => (
              <Amount
                value={formatMoney({
                  value: currency === 'usd' ? total_sent_usd : total_sent,
                  currency: currency === 'src' ? type : currency,
                })}
                fullValue={formatMoney({
                  value: currency === 'usd' ? total_sent_usd : total_sent,
                  currency: currency === 'src' ? type : currency,
                })}
                copyValue={formatMoney({
                  value: currency === 'usd' ? total_sent_usd : total_sent,
                  currency: currency === 'src' ? type : currency,
                  code: '',
                })}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:fee'),
            value: ({ total_fee_usd, total_fee }) => (
              <Amount
                value={formatMoney({
                  value: currency === 'usd' ? total_fee_usd : total_fee,
                  currency: currency === 'src' ? type : currency,
                })}
                fullValue={formatMoney({
                  value: currency === 'usd' ? total_fee_usd : total_fee,
                  currency: currency === 'src' ? type : currency,
                })}
                copyValue={formatMoney({
                  value: currency === 'usd' ? total_fee_usd : total_fee,
                  currency: currency === 'src' ? type : currency,
                  code: '',
                })}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:bits'),
            value: ({ bits }) => bits,
          },
          {
            key: t('platform:nonce'),
            value: ({ nonce }) => (
              <Amount
                value={formatNumberU(nonce, 0)}
                fullValue={formatNumberU(nonce, 0)}
                copyValue={formatNumberU(nonce, 0)}
                enabledCopy
                variant={'body100Normal'}
              />
            ),
          },
          {
            key: t('platform:time'),
            value: ({ time }) => formatDate(fromUnixTime(time)),
          },
          {
            key: t('platform:hash'),
            value: ({ hash }) => (
              <Amount
                value={getBlockSlug(hash)}
                fullValue={hash}
                copyValue={hash}
                enabledCopy
                variant={'body100NormalCode'}
              />
            ),
          },
          {
            key: t('platform:previousBlock'),
            value: ({ prev_block }) => (
              <Link to={`/${type}/explorer/block/${prev_block}`}>
                <TypographyNew
                  as={'a'}
                  variant={'body100NormalCode'}
                  color={'onBackgroundBase'}
                >
                  {getBlockSlug(prev_block)}
                </TypographyNew>
              </Link>
            ),
          },
          {
            key: t('platform:nextBlock'),
            value: ({ next_block }) => (
              <Link to={`/${type}/explorer/block/${next_block}`}>
                <TypographyNew
                  as={'a'}
                  variant={'body100NormalCode'}
                  color={'onBackgroundBase'}
                >
                  {getBlockSlug(next_block)}
                </TypographyNew>
              </Link>
            ),
          },
          {
            key: t('platform:merkleRoot'),
            value: ({ merkle_root }) => (
              <Amount
                value={getBlockSlug(merkle_root)}
                fullValue={merkle_root}
                copyValue={merkle_root}
                enabledCopy
                variant={'body100NormalCode'}
              />
            ),
          },
          {
            key: t('platform:chainWork'),
            value: ({ chain_work }) => (
              <Amount
                value={getBlockSlug(chain_work)}
                fullValue={chain_work}
                copyValue={chain_work}
                enabledCopy
                variant={'body100NormalCode'}
              />
            ),
          },
          {
            key: t('platform:miner'),
            value: ({ pool_name }) => pool_name,
          },
        ],
        [
          block?.mweb_total_transactions
            ? {
                key: t('platform:totalMWTransactions'),
                value: ({ mweb_total_transactions }) => mweb_total_transactions,
              }
            : null,
          block?.mweb_amount
            ? {
                key: t('platform:totalMWAmount'),
                value: ({ mweb_amount }) =>
                  formatMoney({
                    value: mweb_amount,
                    currency: type,
                  }),
              }
            : null,
        ],
      ]}
    </KeyValue>
  )
}

export default observer(BlockDetailsBtc)
