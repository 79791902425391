import { InjectComponentModel } from '@clain/core/utils'
import { useInjectBlockchainModel } from '../hooks'
import * as ui from '../ui'

export const SelectedEntitiesCount = InjectComponentModel<
  ui.SelectedEntitiesCountProps,
  Omit<ui.SelectedEntitiesCountProps, 'entityName'>
>(ui.SelectedEntitiesCount, {
  useFactory: (props) => {
    const { selectedEntitiesCount } = useInjectBlockchainModel()

    return { ...props, count: selectedEntitiesCount }
  },
})
