import { injectable } from 'inversify'
import { action } from 'mobx'

import { AbstractDeleteNodeController } from './DeleteNodeController'
import type { IEntityEdge } from '../../GraphEvents.types'
import { LiteTransactionEdge, ServerRemoveEvents } from '../../types'
import { edgeEvmTrxByTypeKey } from '../../utils'

@injectable()
export class DeleteEVMNodeController extends AbstractDeleteNodeController {
  private handleDeleteTransactionByEdges = (
    nodeKey: string,
    transactionData: any,
    fromData: any,
    toData: any,
    type: LiteTransactionEdge['type'],
    transferIndex?: number
  ) => {
    const deleteEntities: ServerRemoveEvents = []

    if (nodeKey === fromData.hash || nodeKey === toData.hash) {
      const edgeFrom = edgeEvmTrxByTypeKey(
        fromData.hash,
        transactionData.hash,
        {
          index: transferIndex,
          type,
        }
      )

      const edgeTo = edgeEvmTrxByTypeKey(transactionData.hash, toData.hash, {
        index: transferIndex,
        type,
      })

      deleteEntities.push(
        ...this.deleteTransactionByEdges(
          [edgeFrom, edgeTo],
          transactionData.hash
        )
      )
    }

    return deleteEntities
  }

  protected handleTransactionNode(): ServerRemoveEvents {
    const deleteEntities: ServerRemoveEvents = []

    const nodeKey = this.nodeKey

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    const neighbors = this.graph.neighbors(nodeKey)
    const edges = this.graph.edges(nodeKey)

    const selectedEdgeIds = Array.from(this.probeState.selectedEdgeIds)
    const neighborsSelectedEdges = selectedEdgeIds.filter((edgeId) =>
      edges.includes(edgeId)
    )

    if (!neighborsSelectedEdges.length) {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, neighbors, edges)
      )
    }

    if (this.isExistsEdges(nodeKey)) {
      deleteEntities.push(...this.removeSelectedEdges(nodeKey))
    }

    if (this.isExistsEdges(nodeKey)) {
      deleteEntities.push(...this.deleteNodes(neighbors, false))
    } else {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, neighbors, edges)
      )
    }

    return deleteEntities
  }

  protected handleAddressNode(): ServerRemoveEvents {
    const deleteEntities: ServerRemoveEvents = []

    const nodeKey = this.nodeKey
    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    if (this.isForceDelete) {
      const neighbors = this.graph.neighbors(nodeKey)
      neighbors.forEach((neighbor) => {
        const neighborData = this.probeState.nodes.get(neighbor).data
        if (neighborData.nodeType === 'evm_transaction') {
          neighborData.transfers.forEach((el, index) => {
            deleteEntities.push(
              ...this.handleDeleteTransactionByEdges(
                nodeKey,
                neighborData,
                el.from,
                el.to,
                'transfer',
                index
              )
            )
          })

          neighborData.tokens.forEach((el) => {
            deleteEntities.push(
              ...this.handleDeleteTransactionByEdges(
                nodeKey,
                neighborData,
                el.from,
                el.to,
                'token_transfer',
                el.logIndex
              )
            )
          })
          neighborData.internals.forEach((el) => {
            deleteEntities.push(
              ...this.handleDeleteTransactionByEdges(
                nodeKey,
                neighborData,
                el.from,
                el.to,
                'internal',
                el.index
              )
            )
          })
        }
      })
      if (!this.isNodeExists(nodeKey)) {
        return deleteEntities
      }

      const edges = this.graph.edges(nodeKey)
      deleteEntities.push(
        { type: 'delete_node', key: nodeKey },
        ...this.deleteEdges(edges)
      )
      this.addDeletedEntity(nodeKey)
    }

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    if (!this.isExistsEdges(nodeKey)) {
      deleteEntities.push({ type: 'delete_node', key: nodeKey })
      this.addDeletedEntity(nodeKey)
    }

    return deleteEntities
  }

  @action
  protected deleteTransactionByEdges = (
    edgeKeys: [string, string],
    transactionKey: string,
    isForceDelete = false
  ): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (
      !edgeKeys.every((key) => this.probeState.edges.has(key)) ||
      !this.isNodeExists(transactionKey)
    ) {
      return deleteEntities
    }

    const trxNeighbors = this.graph.neighbors(transactionKey)

    deleteEntities.push(...this.deleteEdges(edgeKeys))

    if (isForceDelete) {
      deleteEntities.push({ type: 'delete_node', key: transactionKey })
      this.addDeletedEntity(transactionKey)
    } else {
      const excludedDeletedEdges = this.graph
        .edges(transactionKey)
        .filter((edgeKey) => !this.deletedEntities.has(edgeKey))

      if (excludedDeletedEdges.length < 2) {
        deleteEntities.push({ type: 'delete_node', key: transactionKey })
        this.addDeletedEntity(transactionKey)
      }
    }

    deleteEntities.push(...this.deleteNodes(trxNeighbors, false))

    return deleteEntities
  }

  @action
  public deleteNodeByEdges = (data: IEntityEdge): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    switch (data.entity.strategy) {
      case 'sourceTarget':
        deleteEntities.push(
          ...this.deleteSourcesTargetByEdge(data.entity.edgeKey)
        )
        break
      case 'address':
      case 'cluster':
        deleteEntities.push(
          ...this.deleteClusterOrAddressByEdges(
            data.entity.edgeKeys,
            data.entity.nodeKey
          )
        )
        break
      case 'transaction':
        deleteEntities.push(
          ...this.deleteTransactionByEdges(
            data.entity.edgeKeys,
            data.entity.nodeKey
          )
        )
        break
      default:
        deleteEntities.push(...this.deleteEdgeByKey(data.entity.edgeKey))
    }

    return deleteEntities
  }

  @action public deleteNode = (
    nodeKey: string,
    isForceDelete = true
  ): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    this.init(nodeKey, isForceDelete)
    switch (this.node.data.nodeType) {
      case 'evm_transaction':
        deleteEntities.push(...this.handleTransactionNode())
        break
      case 'address':
        deleteEntities.push(...this.handleAddressNode())
        break
      case 'demix':
        deleteEntities.push(...this.handleDemixNode())
        break
      default:
        if (isForceDelete) {
          deleteEntities.push(...this.deleteEdges(this.graph.edges(nodeKey)))
          deleteEntities.push(...this.deleteNodeByKey(nodeKey))
        }
    }

    return deleteEntities
  }
}
