import React from 'react'
import classnames from 'classnames/bind'

import { CheckboxSwitch } from '@clain/core/ui-kit'
import { Field } from '@clain/core/Form'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './SettingsLine.scss'
import { SettingsLineProps } from './SettingsLine.types'

const cx = classnames.bind(styles)

export const SettingsLine: React.FC<SettingsLineProps> = (props) => (
  <div className={cx('SettingsLineRow')}>
    <div className={cx('SwitchRow')}>
      <Typography variant="subheading2" color="grey2">
        {props.label}
      </Typography>
      <RowDeprecated gap={1.5}>
        {props.variant === 'once' && (
          <Field
            rtl
            as={CheckboxSwitch}
            className={cx('CheckboxSwitch')}
            name={`${props.fieldName}`}
            label={
              props?.fieldLabel && (
                <Typography color="grey2">{props.fieldLabel}</Typography>
              )
            }
          />
        )}
        {props.variant === 'twice' && (
          <>
            <Field
              rtl
              as={CheckboxSwitch}
              className={cx('CheckboxSwitch')}
              name={`${props.firstFieldName}`}
              label={
                props?.firstFieldLabel && (
                  <Typography color="grey2">{props.firstFieldLabel}</Typography>
                )
              }
            />
            <Field
              rtl
              as={CheckboxSwitch}
              className={cx('CheckboxSwitch')}
              name={`${props.secondFieldName}`}
              label={
                props?.secondFieldLabel && (
                  <Typography color="grey2">
                    {props.secondFieldLabel}
                  </Typography>
                )
              }
            />
          </>
        )}
      </RowDeprecated>
    </div>
    {props?.description && (
      <Typography className={cx('Help')} variant="caption1" color="grey3">
        {props.description}
      </Typography>
    )}
    {props?.children && <div className={cx('Content')}>{props.children}</div>}
  </div>
)
