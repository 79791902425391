import { usePlotEntitiesOnGraph } from '@platform/hooks'
import { dataAddressesSearchViewModel } from '../model/addresses'
import { useBlockchainFilterEntityPage } from './useBlockchainFilterEntityPage'
import { dataTransactionsSearchViewModel } from '@platform/components/BlockchainFilter/di/transactions/containers'

export const useInjectBlockchainModel = () => {
  const { isTransactions } = useBlockchainFilterEntityPage()
  const viewModel = isTransactions
    ? dataTransactionsSearchViewModel
    : dataAddressesSearchViewModel

  const {
    disabledPlotOnGraph,
    selectUnSelectAllEntities,
    isAllSelectedEntities,
    plotSelectedOnGraph,
    selectedEntitiesCount,
    selectedEntities,
    setSelectEntities,
    resetSelectedEntities,
  } = usePlotEntitiesOnGraph(isTransactions ? 'transactions' : 'addresses')

  return {
    disabledPlotOnGraph,
    selectUnSelectAllEntities,
    isAllSelectedEntities,
    plotSelectedOnGraph,
    selectedEntitiesCount,
    selectedEntities,
    setSelectEntities,
    resetSelectedEntities,
    viewModel: viewModel,
  }
}
