import { ContainerModule, interfaces } from 'inversify'
import { DI_PROBE_TYPES } from '../DITypes'
import { IAutoSelectTransaction } from '../../models'
import { AutoSelectTransaction } from '../../vm/AutoSelectTransaction'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IAutoSelectTransaction>(DI_PROBE_TYPES.AutoSelectTransaction)
    .to(AutoSelectTransaction)
    .inSingletonScope()
}

export const probeActiveEntityModules = new ContainerModule(initializeModule)
