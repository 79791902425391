import React, { useEffect } from 'react'

import { BlockchainFilter } from '@platform/components/BlockchainFilter'
import { useAppLayoutResetter } from '@clain/core/state'

export const BlockchainFilterPage = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <BlockchainFilter />
}

export default BlockchainFilterPage
