import classnames from 'classnames/bind'
import { useParams, useSearchParams } from '@clain/core/Router/router'
import { observer } from 'mobx-react-lite'
import { ReactComponent as ExplorerIcon } from '@clain/core/assets/view.svg'
import Portlet from '@clain/core/Portlet'
import Pagination from '@clain/core/Pagination'
import { Button, MagicGrid, Stack } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import { FormatProvider } from '@clain/core/utils/format'
import { useTranslation } from 'react-i18next'
import Header from '../Header'
import BlockDetailsBtc from './BlockDetailsBtc'
import BlockDetailsEvm from './BlockDetailsEvm'
import { useSelectedCoin } from '../CoinSelect'
import { useSelectedCurrency } from '../NavIcons'
import { getConfig } from '@clain/core/useConfig'
import styles from './index.scss'
import { TransactionBlock } from '../TransactionBlock'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { LoaderOverlay } from '@clainio/web-platform'
import { HeaderSlot } from '@clain/core/Layout/slots'
import {
  BlockDataTransaction,
  RawBlockData,
  RawBlockDataBlock,
  RawBlockDataBlockEVM,
  RawBlockDataBlockUTXO,
} from '../../apiServices'
import { isEVM } from '@clain/core/types'
import { CoinType } from '@platform/types/coin'
import { usePlotEntitiesOnGraph } from '@platform/hooks'

const cx = classnames.bind(styles)
const config = getConfig()

interface BlockTableProps {
  block?: RawBlockDataBlock
  type: CoinType
  currency: 'src' | 'usd'
  loading?: boolean
}

const BlockDetails = ({ block, type, currency, loading }: BlockTableProps) => {
  if (isEVM(type)) {
    return (
      <BlockDetailsEvm
        block={block as RawBlockDataBlockEVM}
        currency={currency}
        loading={loading}
        coinType={type}
      />
    )
  } else {
    return (
      <BlockDetailsBtc
        block={block as RawBlockDataBlockUTXO}
        currency={currency}
        loading={loading}
        type={type}
      />
    )
  }
}

const Block = () => {
  const { t } = useTranslation()
  const { id: hash } = useParams<{ id: string }>()
  const coin = useSelectedCoin()
  const currency = useSelectedCurrency()

  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || '1'

  const { data, isLoading } = useHttp<RawBlockData>(
    `${config?.PLATFORM_API}/api/private/explorer/${coin}/block/${hash}/${page}`
  )

  const formattedData = normalizeSnakeToCamelCase(data)
  const {
    selectUnSelectAllEntities,
    isAllSelectedEntities,
    plotSelectedOnGraph,
    disabledPlotOnGraph,
    selectedEntities,
    setSelectEntities,
  } = usePlotEntitiesOnGraph(
    'transactions',
    formattedData?.transactions as unknown as BlockDataTransaction
  )

  HeaderSlot.useContent(
    () => (
      <Header
        icon={<ExplorerIcon />}
        title={
          <>
            {t('platform:block')}
            <span className={cx('subtitle')}>{formattedData?.block?.hash}</span>
          </>
        }
      />
    ),
    []
  )

  return (
    <FormatProvider
      precision={{ btc: 8, eth: 8, bnb: 8, trx: 6, doge: 8, ltc: 8, usd: 2 }}
    >
      <MagicGrid>
        <Portlet variant="card">
          {{
            body: (
              <BlockDetails
                block={data?.block}
                type={coin}
                currency={currency}
                loading={isLoading}
              />
            ),
          }}
        </Portlet>
        {/*@ts-expect-error*/}
        <LoaderOverlay as={MagicGrid} gap={0.5} loading={isLoading}>
          <Stack align="center" justify="end" gap="md">
            <Button
              variant="outline"
              color="secondary"
              endIcon="Checks"
              onClick={selectUnSelectAllEntities}
            >
              {isAllSelectedEntities
                ? t('platform:deselectPage')
                : t('platform:selectPage')}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              endIcon="GraphPlot"
              disabled={disabledPlotOnGraph}
              onClick={plotSelectedOnGraph}
            >
              {t('platform:plotSelected')}
            </Button>
          </Stack>
          {formattedData?.transactions.map((transaction) => (
            <TransactionBlock
              key={transaction.hash}
              blockchain={coin}
              transaction={transaction}
              isUSD={currency === 'usd'}
              selectedTransaction={selectedEntities.some(
                (selectedTransaction) =>
                  selectedTransaction.id === transaction.id
              )}
              onSelectTransaction={setSelectEntities}
            />
          ))}
          <Pagination totalPages={data?.total_pages} />
        </LoaderOverlay>
      </MagicGrid>
    </FormatProvider>
  )
}

export default observer(Block)
