import { RawPresetData } from '@platform/apiServices/transactionPresets'
import {
  INormalizedTransactionPresetsServerData,
  INormalizedTransactionPresetsServerDataPayload,
} from '@platform/modules/transactionFilterPresets/TransactionFilterPresetsViewModel.types'

export const normalizeTransactionPresetsServerData = (
  preset: RawPresetData
): INormalizedTransactionPresetsServerData => {
  return {
    ...preset,
    timestamp: [preset.filters.trx_time_from, preset.filters.trx_time_to],
  }
}

export const normalizeTransactionPresetsServerDataPayload = ({
  monitoring,
  ...preset
}: INormalizedTransactionPresetsServerDataPayload) => {
  const { filters, ...restPreset } = preset
  const { page, ...restFilters } = filters
  return {
    ...restPreset,
    filters: restFilters,
    is_monitored: monitoring,
  }
}
