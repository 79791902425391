import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFilterSlice } from '../CounterpartyContainerFilters.service'
import { ScoreFilter, ScoreFilterMenu } from '../../../../../Filters'
import { useCounterpartyStaticStore } from '../../context'

export const ScoreFilterMenuContainer = () => {
  const { t } = useTranslation()
  const [score, { setFilter }] = useFilterSlice('score.data')
  const counterpartyStaticStore = useCounterpartyStaticStore()

  return (
    <ScoreFilterMenu
      onSelect={setFilter}
      score={score}
      size={counterpartyStaticStore.current.filterSize}
      title={t('platform:counterpartyScore')}
    />
  )
}

export const ScoreFilterContainer = () => {
  const { t } = useTranslation()
  const [score] = useFilterSlice('score.data')
  const [, { resetFilter }] = useFilterSlice('score')
  const counterpartyStaticStore = useCounterpartyStaticStore()

  return (
    <ScoreFilter
      score={score}
      size={counterpartyStaticStore.current.filterSize}
      filterLable={t('platform:counterpartyScore')}
      menuComponent={<ScoreFilterMenuContainer />}
      clearFilter={resetFilter}
    />
  )
}
