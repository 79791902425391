import { injectable, inject } from 'inversify'

import { EntityStrategyType } from './GenerateEntity.types'
import { normalizeStrategyType } from './GenerateEntity.utils'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IGenerateEntity,
  IGenerateEntities,
  IGenerateEntitiesAdapter,
  StrategyData,
} from '../../GraphEvents.types'
import {
  EventCluster,
  EventAddress,
  EventOsint,
  EventTransactionEVM,
  EventTransactionUTXO,
  EventTransactionAddress,
  EventTransactionToken,
  EventTransactionLinked,
  EventUtxoTransactionByTrxAddress,
  EventComment,
  EventDemix,
  EventDemixUtxo,
  EventDemixEvm,
  EventCustomEntity,
  EventText,
  EventCrossChainSwap,
  EventUnsupportedAddress,
  EventAddressFlow,
  EventEdgeLink,
} from '../../types'

@injectable()
export class GenerateEntity implements IGenerateEntity<unknown> {
  private strategies = new Map<
    EntityStrategyType,
    IGenerateEntities<unknown>[]
  >()

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeCluster)
    generateNodeCluster: IGenerateEntities<EventCluster>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeFlow)
    generateEdgeFlow: IGenerateEntities<EventCluster>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeAddressFlow)
    generateEdgeAddressFlow: IGenerateEntities<EventAddressFlow>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeAddress)
    generateNodeAddress: IGenerateEntities<EventAddress>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeAddressBelongs)
    generateEdgeAddressBelongs: IGenerateEntities<EventAddress>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeOsint)
    generateNodeOsint: IGenerateEntities<EventOsint>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeAttribution)
    generateEdgeAttribution: IGenerateEntities<EventOsint>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeTransactionEVM)
    generateNodeTransactionEVM: IGenerateEntities<EventTransactionEVM>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionEVM)
    generateEdgeTransactionEVM: IGenerateEntities<EventTransactionEVM>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeTransactionUTXO)
    generateNodeTransactionUTXO: IGenerateEntities<EventTransactionUTXO>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionUTXO)
    generateEdgeTransactionUTXO: IGenerateEntities<EventTransactionUTXO>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeAutoConnectTransactionUTXO)
    generateNodeAutoConnectTransactionUTXO: IGenerateEntities<EventTransactionUTXO>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeAutoConnectTransactionUTXO)
    generateEdgeAutoConnectTransactionUTXO: IGenerateEntities<EventTransactionUTXO>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionInternalsEVM)
    generateEdgeTransactionInternalsEVM: IGenerateEntities<EventTransactionEVM>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionTokensEVM)
    generateEdgeTransactionTokensEVM: IGenerateEntities<EventTransactionEVM>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeTransactionAddressUTXO)
    generateNodeTransactionAddressUTXO: IGenerateEntities<EventTransactionAddress>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionAddressUTXO)
    generateEdgeTransactionAddressUTXO: IGenerateEntities<EventTransactionAddress>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeTransactionTokenUTXO)
    generateNodeTransactionTokenUTXO: IGenerateEntities<EventTransactionToken>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionTokenUTXO)
    generateEdgeTransactionTokenUTXO: IGenerateEntities<EventTransactionToken>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateTransactionAddressLinked)
    generateTransactionAddressLinked: IGenerateEntitiesAdapter<
      EventTransactionLinked,
      EventTransactionAddress
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateTransactionUtxoLinked)
    generateTransactionUtxoLinked: IGenerateEntitiesAdapter<
      EventTransactionLinked,
      EventUtxoTransactionByTrxAddress
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeComment)
    generateNodeComment: IGenerateEntities<EventComment>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeComment)
    generateEdgeComment: IGenerateEntities<EventComment>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeDemix)
    generateNodeDemix: IGenerateEntities<EventDemix>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeDemix)
    generateEdgeDemix: IGenerateEntities<EventDemix>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateDemixTrxUtxo)
    generateDemixTrxUtxo: IGenerateEntitiesAdapter<
      EventDemixUtxo,
      EventTransactionUTXO
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateDemixTrxAddressUtxo)
    generateDemixTrxAddressUtxo: IGenerateEntitiesAdapter<
      EventDemixUtxo,
      EventTransactionAddress
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateDemixTrxEvm)
    generateDemixTrxEvm: IGenerateEntitiesAdapter<
      EventDemixEvm,
      EventTransactionEVM
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeCustom)
    generateNodeCustom: IGenerateEntities<EventCustomEntity>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeText)
    generateNodeText: IGenerateEntities<EventText>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapAddress)
    generateCrossChainSwapAddress: IGenerateEntitiesAdapter<
      EventCrossChainSwap,
      EventAddress
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapUnsupportedAddress)
    generateCrossChainSwapUnsupportedAddress: IGenerateEntitiesAdapter<
      EventCrossChainSwap,
      EventUnsupportedAddress
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEdgeCrossChainSwapFlow)
    generateEdgeCrossChainSwapFlow: IGenerateEntities<EventCrossChainSwap>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateNodeUnsupportedAddress)
    generateNodeUnsupportedAddress: IGenerateEntities<EventUnsupportedAddress>,
    @inject(GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapTrxEvm)
    generateCrossChainSwapTrxEvm: IGenerateEntitiesAdapter<
      EventCrossChainSwap,
      EventTransactionEVM
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapTrxUtxo)
    generateCrossChainSwapTrxUtxo: IGenerateEntitiesAdapter<
      EventCrossChainSwap,
      EventTransactionUTXO
    >,
    @inject(GRAPH_ENTITIES_TYPES.GenerateLinkEdge)
    generateLinkEdge: IGenerateEntities<EventEdgeLink>
  ) {
    this.strategies.set('text', [generateNodeText])
    this.strategies.set('cluster', [generateNodeCluster])
    this.strategies.set('cluster-flow', [generateNodeCluster, generateEdgeFlow])
    this.strategies.set('address-flow', [
      generateNodeAddress,
      generateEdgeAddressFlow,
    ])
    this.strategies.set('address', [generateNodeAddress])
    this.strategies.set('address-cluster', [
      generateNodeCluster,
      generateNodeAddress,
      generateEdgeAddressBelongs,
    ])
    this.strategies.set('osint', [generateNodeOsint, generateEdgeAttribution])
    this.strategies.set('evm-transaction', [
      generateNodeTransactionEVM,
      generateEdgeTransactionEVM,
    ])
    this.strategies.set('evm-transaction-multiple', [
      generateNodeTransactionEVM,
      generateEdgeTransactionEVM,
      generateEdgeTransactionInternalsEVM,
      generateEdgeTransactionTokensEVM,
    ])
    this.strategies.set('utxo-transaction', [
      generateNodeTransactionUTXO,
      generateEdgeTransactionUTXO,
      generateNodeAutoConnectTransactionUTXO,
      generateEdgeAutoConnectTransactionUTXO,
    ])
    this.strategies.set('utxo-transaction-address', [
      generateNodeTransactionAddressUTXO,
      generateEdgeTransactionAddressUTXO,
    ])
    this.strategies.set('utxo-transaction-token', [
      generateNodeTransactionTokenUTXO,
      generateEdgeTransactionTokenUTXO,
    ])
    this.strategies.set('utxo-transaction-linked', [
      generateTransactionAddressLinked.adapter(
        generateNodeTransactionAddressUTXO
      ),
      generateTransactionAddressLinked.adapter(
        generateEdgeTransactionAddressUTXO
      ),
      generateTransactionUtxoLinked.adapter(generateNodeTransactionUTXO),
      generateTransactionUtxoLinked.adapter(generateEdgeTransactionUTXO),
      generateTransactionUtxoLinked.adapter(
        generateNodeAutoConnectTransactionUTXO
      ),
      generateTransactionUtxoLinked.adapter(
        generateEdgeAutoConnectTransactionUTXO
      ),
    ])
    this.strategies.set('comment', [generateNodeComment, generateEdgeComment])
    this.strategies.set('utxo-demix', [
      generateDemixTrxUtxo.adapter(generateNodeTransactionUTXO),
      generateDemixTrxUtxo.adapter(generateEdgeTransactionUTXO),
      generateDemixTrxAddressUtxo.adapter(generateNodeTransactionAddressUTXO),
      generateDemixTrxAddressUtxo.adapter(generateEdgeTransactionAddressUTXO),
      generateDemixTrxUtxo.adapter(generateNodeAutoConnectTransactionUTXO),
      generateDemixTrxUtxo.adapter(generateEdgeAutoConnectTransactionUTXO),
      generateNodeDemix,
      generateEdgeDemix,
    ])
    this.strategies.set('evm-demix', [
      generateDemixTrxEvm.adapter(generateNodeTransactionEVM),
      generateDemixTrxEvm.adapter(generateEdgeTransactionEVM),
      generateNodeDemix,
      generateEdgeDemix,
    ])
    this.strategies.set('custom', [generateNodeCustom])
    this.strategies.set('edge-link', [generateLinkEdge])
    this.strategies.set('crossChainSwap', [
      generateCrossChainSwapAddress.adapter(generateNodeAddress),
      generateCrossChainSwapUnsupportedAddress.adapter(
        generateNodeUnsupportedAddress
      ),
      generateCrossChainSwapTrxEvm.adapter(generateNodeTransactionEVM),
      generateCrossChainSwapTrxEvm.adapter(generateEdgeTransactionEVM),
      generateCrossChainSwapTrxUtxo.adapter(generateNodeTransactionUTXO),
      generateCrossChainSwapTrxUtxo.adapter(generateEdgeTransactionUTXO),
      generateEdgeCrossChainSwapFlow,
    ])
  }

  public get(data: StrategyData) {
    if (!this.strategies.has(normalizeStrategyType(data))) {
      throw new Error(`No generateEntity found for type ${data.type}`)
    }

    return this.strategies.get(normalizeStrategyType(data))
  }
}
