import { action } from 'mobx'

import { AbstractDeleteNodeController } from './DeleteNodeController'
import type { IEntityEdge } from '../../GraphEvents.types'
import {
  ServerRemoveEvents,
  CreateData,
  LiteTransactionAddressNode,
} from '../../types'

export class DeleteUTXONodeController extends AbstractDeleteNodeController {
  protected handleTransactionNode(): ServerRemoveEvents {
    const deleteEntities: ServerRemoveEvents = []
    const nodeKey = this.nodeKey

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    const inNeighbors = this.graph.inNeighbors(nodeKey)
    const outNeighbors = this.graph.outNeighbors(nodeKey)
    const neighbors = this.graph.neighbors(nodeKey)
    const edges = this.graph.edges(nodeKey)

    if (this.isForceDelete) {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, neighbors, edges)
      )

      return deleteEntities
    }

    if (!this.isExistsInEdges(nodeKey)) {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, outNeighbors, edges)
      )
    }
    if (!this.isExistsOutEdges(nodeKey)) {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, inNeighbors, edges)
      )
    }

    return deleteEntities
  }

  protected handleTransactionAddressNode(): ServerRemoveEvents {
    const deleteEntities: ServerRemoveEvents = []
    const nodeKey = this.nodeKey

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    const node = this.node as CreateData<LiteTransactionAddressNode>

    const neighbors = this.graph.neighbors(nodeKey)
    const edges = this.graph.edges(nodeKey)

    if (this.isForceDelete) {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, neighbors, edges)
      )
    } else if (
      !this.isExistsEdgesByKeys(
        this.filterEdgesByType(nodeKey, ['utxo_transaction'])
      ) &&
      !this.isNodeExists(node.data?.transactionHash)
    ) {
      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, neighbors, edges)
      )
    }

    return deleteEntities
  }

  protected handleAddressNode(): ServerRemoveEvents {
    const deleteEntities: ServerRemoveEvents = []
    const nodeKey = this.nodeKey

    if (this.isForceDelete) {
      if (!this.isNodeExists(nodeKey)) {
        return deleteEntities
      }

      const neighbors = this.graph.neighbors(nodeKey)
      const edges = this.graph.edges(nodeKey)

      deleteEntities.push(
        ...this.deleteEdgesAndNodesRecursively(nodeKey, neighbors, edges)
      )
    }

    return deleteEntities
  }

  @action
  public deleteNodeByEdges = (data: IEntityEdge): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    switch (data.entity.strategy) {
      case 'sourceTarget':
        deleteEntities.push(
          ...this.deleteSourcesTargetByEdge(data.entity.edgeKey)
        )
        break
      case 'address':
      case 'cluster':
        deleteEntities.push(
          ...this.deleteClusterOrAddressByEdges(
            data.entity.edgeKeys,
            data.entity.nodeKey
          )
        )
        break
      case 'transaction':
        break
      default:
        deleteEntities.push(...this.deleteEdgeByKey(data.entity.edgeKey))
    }

    return deleteEntities
  }
  @action public deleteNode = (
    nodeKey: string,
    isForceDelete = true
  ): ServerRemoveEvents => {
    const deleteEntities: ServerRemoveEvents = []

    if (!this.isNodeExists(nodeKey)) {
      return deleteEntities
    }

    this.init(nodeKey, isForceDelete)

    switch (this.node.data.nodeType) {
      case 'utxo_transaction':
        deleteEntities.push(...this.handleTransactionNode())
        break
      case 'utxo_transaction_address':
        deleteEntities.push(...this.handleTransactionAddressNode())
        break
      case 'address':
        deleteEntities.push(...this.handleAddressNode())
        break
      case 'demix':
        deleteEntities.push(...this.handleDemixNode())
        break
      case 'comment_pin':
        deleteEntities.push(...this.handleCommentNode())
        break
      default:
        if (isForceDelete) {
          deleteEntities.push(...this.deleteEdges(this.graph.edges(nodeKey)))
          deleteEntities.push(...this.deleteNodeByKey(nodeKey))
        }
    }

    return deleteEntities
  }
}
