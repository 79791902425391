import { useAddressTableConfig } from './useAddressTableConfig'
import React from 'react'
import { RowDeprecated, Stack } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from '../AddressTable.scss'
import { AddressSelectedEvmTableProps } from '../AddressEvmTable'
import { AddressSelectedUtxoTableProps } from '../AddressUtxoTable'
import { AddressStack } from '../AddressTable.styles'

const cx = classnames.bind(styles)
export const useProbeAddressTableConfig = (
  props: Omit<
    AddressSelectedEvmTableProps | AddressSelectedUtxoTableProps,
    'itemsPerPage'
  >
) => {
  const defaultConfig = useAddressTableConfig(props)
  const [isAllTransactionsChecked, setIsAllTransactionsChecked] =
    React.useState(false)
  const [isAllTransactionsLoading, setIsAllTransactionsLoading] =
    React.useState(false)

  React.useEffect(() => {
    // @ts-ignore
    if (props.data?.every((item) => item.selected)) {
      setIsAllTransactionsChecked(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.selected)) {
      setIsAllTransactionsChecked(false)
    }
    // @ts-ignore
    if (props.data?.every((item) => item.isProcessing)) {
      setIsAllTransactionsLoading(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.isProcessing)) {
      setIsAllTransactionsLoading(false)
    }
  }, [props.data])

  const isNotDeterminate = //@ts-ignore
    props.data?.every((item) => item.selected) ||
    //@ts-ignore
    props.data?.every((item) => !item.selected)

  const isIndeterminate = !isNotDeterminate

  const handleChangeTransactionCheckbox = () => {
    const selected = isIndeterminate || !isAllTransactionsChecked
    props.setAllSelected(
      // @ts-ignore
      props.data.filter((item) => (selected ? !item.selected : item.selected)),
      selected
    )
  }

  const selectedConfig = {
    ...defaultConfig,
    address: {
      ...defaultConfig.address,
      renderTitle: () => (
        <AddressStack gap="xl" align="center">
          <Checkbox
            isLoading={isAllTransactionsLoading}
            value={isAllTransactionsChecked}
            onChange={handleChangeTransactionCheckbox}
            indeterminate={isIndeterminate}
            disabled={!props.data?.length}
          />
          {defaultConfig.address.renderTitle()}
        </AddressStack>
      ),
      render: (item, index, options) => (
        <AddressStack gap="xl" align="center" fullHeight>
          <Checkbox
            value={item.selected}
            isLoading={item.isProcessing}
            onChange={() => props.setSelected(item, !item.selected)}
          />
          {defaultConfig.address.render(item, index, options)}
        </AddressStack>
      ),
    },
  }
  return selectedConfig as typeof defaultConfig
}
