import type { EdgeAttributes } from '@clain/graph'

import ProbeEdge from './ProbeEdge'
import { LinkEdgeData } from '../../types'

const COLOR = 'rgba(99, 123, 171, 1)'

export class LinkEdge<
  T extends LinkEdgeData = LinkEdgeData
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const attributes = {
      color: COLOR,
      disabled: true,
      visible: false,
    } as EdgeAttributes<T>

    return attributes
  }
}
