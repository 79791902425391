import {
  SurfaceContainer,
  Stack,
  RowDeprecated,
  TypographyNew,
} from '@clain/core/ui-kit'
import { ListToPanelLayoutProps } from './ListToPanelLayout.types'

export const ListToPanelLayout: React.FC<ListToPanelLayoutProps> = ({
  title,
  rightComponent,
  leftComponent,
}) => (
  <SurfaceContainer variant={null} spaces={['xl', 'none', 'none', 'none']}>
    <Stack justify="space-between" align="center">
      <RowDeprecated gap={0.5}>
        <TypographyNew variant={'heading300Accent'} color={'onBackgroundBase'}>
          {title}
        </TypographyNew>
        {leftComponent}
      </RowDeprecated>
      <RowDeprecated gap={0}>{rightComponent}</RowDeprecated>
    </Stack>
  </SurfaceContainer>
)
