import { UTXO_COINS } from '@clain/core/utils/currency'
import { useNavigate, useParams } from '@clain/core/Router/router'
import { CoinTypeEVM, CoinTypeUTXO } from '@platform/types/coin'
import {
  BlockchainFilterPathParams,
  createBlockchainFilterPath,
} from '@platform/constants'
import { ICurrencyFilter } from '../types/ICurrencyFilter'

export const useCurrencyFilter = (): ICurrencyFilter => {
  const changePath = useNavigate()
  const { coin, entity } = useParams<BlockchainFilterPathParams>()

  const coins: CoinTypeUTXO[] = UTXO_COINS
  const disabledCoins: Record<CoinTypeEVM, string> = {
    eth: 'eth',
    trx: 'trx',
    bnb: 'bnb',
  }

  const onChangeCurrency = (coin: CoinTypeUTXO) => {
    changePath(createBlockchainFilterPath(coin, entity))
  }

  return { onChangeCurrency, disabledCoins, coins, selectedCoin: coin }
}
