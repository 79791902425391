import { StickyBarOptions } from 'sticky-bar'
import React, { PropsWithChildren, useCallback, useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import * as S from './RightOverlapSidebar.styles'

interface RightOverlapSidebarProps {
  toggle: () => void
  isOpen: boolean
  deps?: Array<unknown>
  options?: StickyBarOptions
  width?: string
  clickOutside?: boolean
}

export const RightOverlapSidebar: React.FC<
  PropsWithChildren<RightOverlapSidebarProps>
> = ({ toggle, isOpen, children, width = '384px', clickOutside = true }) => {
  const ref = useRef(null)

  const handleClickOutside = useCallback(() => {
    if (isOpen && clickOutside) {
      toggle()
    }
  }, [isOpen, toggle, clickOutside])

  useOnClickOutside(ref, handleClickOutside)

  return (
    <S.RightOverlapSidebarWrapper $isOpen={isOpen} $width={width}>
      <S.RightOverlapSidebar ref={ref}>
        {isOpen && children}
      </S.RightOverlapSidebar>
    </S.RightOverlapSidebarWrapper>
  )
}
