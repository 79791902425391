import React from 'react'
import {
  Icon,
  List,
  ListItem,
  ListItemAtom,
  ListItemContent,
  usePopoverState,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { listItemSize } from './TransferTypeFilter.constants'
import { TransferTypeFilterMenuProps } from './TransferTypeFilter.types'
import { useTransferTypeFilter } from './TransferTypeFilter.hooks'

export const TransferTypeFilterMenu = ({
  selected,
  onSelect,
  size = 'md',
}: TransferTypeFilterMenuProps) => {
  const { options } = useTransferTypeFilter()
  const { setOpen } = usePopoverState()

  return (
    <List>
      {options?.map(({ value, label, icon }) => (
        <ListItem
          isSelected={
            selected === value || (selected == null ? value === 'both' : false)
          }
          key={value}
          onClick={() => {
            setOpen(false)
            onSelect(value)
          }}
          size={[listItemSize, size]}
          content={({ ...params }) => (
            <ListItemContent
              {...params}
              slots={[
                {
                  type: 'iconText',
                  component: ({ size, density, variant: [body] }) => (
                    <ListItemAtom gap={density}>
                      <div>
                        <Icon
                          size={size}
                          variant={icon}
                          color="onBackgroundVariant1"
                        />
                      </div>
                      <EllipsisTypography
                        variant={body}
                        color={'onBackgroundBase'}
                      >
                        {label}
                      </EllipsisTypography>
                    </ListItemAtom>
                  ),
                },
              ]}
            />
          )}
        />
      ))}
    </List>
  )
}
