import { useGetFilterLabels } from '@clain/core/ui-kit'
import { injectFiltersHook } from '@platform/components/BlockchainFilter/context'
import { AssetFilteState } from '@platform/components/BlockchainFilter/types'
import {
  AssetFilterData,
  AssetFilterSelected,
  InjectAssetFilterProps,
} from './AssetFilter.types'

export const useAssetFilterData = injectFiltersHook<
  InjectAssetFilterProps,
  AssetFilterData,
  Record<string, AssetFilteState>
>(({ filterKey, useFilterSlice, tokens }) => {
  const [data, { setFilter }] = useFilterSlice(filterKey.data)
  const [, { resetFilter }] = useFilterSlice(filterKey.root)

  const [condition] = useFilterSlice(filterKey.condition)
  const { isMultiple, multipleLabel } = useGetFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'assets',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedTokens: data,
    tokenList: tokens,
    onSelect: setFilter,
    clearFilter: resetFilter,
    size: 'md',
  }
})

export const useSelectedAssetData = (
  props: InjectAssetFilterProps
): AssetFilterSelected => {
  const { selectedTokens, tokenList } = useAssetFilterData(props)

  const firstSelectedToken = selectedTokens?.length
    ? tokenList.find((token) => selectedTokens[0].id === token.id)
    : null

  return { firstSelectedToken }
}
