import React from 'react'
import { useTranslation } from 'react-i18next'

import { HeaderSlot } from '@clain/core/Layout/slots'
import { Header } from '@clain/core/Layout'
import { Button } from '@clain/core/ui-kit'
import { InjectComponentModel } from '@clain/core/utils'

import { IDownloadCsv } from '../../types'
import { useInjectBlockchainModel } from '../../hooks'

const UseBlockchainFilterHeader: React.FC<IDownloadCsv> = (props) => {
  const { t } = useTranslation()
  const { isDisabledDownloadCsv, downloadCSV, isDownloadingCSV } = props

  HeaderSlot.useContent(
    () => (
      <Header
        title={t('platform:transactionFilter')}
        iconVariant={'TxnFilter'}
        actions={
          <Button
            isLoading={isDownloadingCSV}
            variant="text"
            color="secondary"
            onClick={downloadCSV}
            disabled={isDisabledDownloadCsv || isDownloadingCSV}
            endIcon="FileCsv"
          >
            {t('platform:exportData')}
          </Button>
        }
      />
    ),
    [isDownloadingCSV, isDisabledDownloadCsv]
  )

  return null
}

export const BlockchainFilterHeader = InjectComponentModel<IDownloadCsv>(
  UseBlockchainFilterHeader,
  {
    useFactory: () => {
      const {
        viewModel: { isDisabledDownloadCsv, downloadCSV, isDownloadingCSV },
      } = useInjectBlockchainModel()

      return { isDisabledDownloadCsv, downloadCSV, isDownloadingCSV }
    },
  }
)
