import { InfoTableOptionsId } from './AlertInfobarList.types'
import { i18next } from '@platform/i18Manager'

export const infoTableOptions = () => ({
  [InfoTableOptionsId.Direction]: {
    name: `${i18next.t('platform:direction')}:`,
  },
  [InfoTableOptionsId.Asset]: {
    name: `${i18next.t('platform:asset')}:`,
  },
  [InfoTableOptionsId.Threshold]: {
    name: `${i18next.t('platform:threshold')}:`,
  },
  [InfoTableOptionsId.Frequency]: {
    name: `${i18next.t('platform:triggerFrequency')}:`,
  },
})

export const OPTIONS_ALERT_ID = {
  seeEvents: 'seeEvents',
}
