import React, { useRef } from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Button, TextField } from '@clain/core/ui-kit'
import styles from './FieldEdit.scss'
import { FieldEditProps } from './FieldEdit.types'
import { Field } from '@clain/core/Form'

const cx = classnames.bind(styles)

export const FieldEdit: React.FC<FieldEditProps> = ({
  onSubmit,
  onEdit,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [isEditing, setIsEditing] = React.useState(false)

  const handleEdit = () => {
    setIsEditing(true)
    onEdit?.(true)

    ref.current.focus()
  }

  const handleApply = () => {
    onSubmit()
    setIsEditing(false)
    onEdit?.(false)
  }

  return (
    <div className={cx('FieldEdit', { isEditing, disabled })}>
      <Field
        ref={ref}
        as={TextField}
        readOnly={!isEditing}
        variant={isEditing ? 'outline' : 'base'}
        className={cx('Input')}
        {...rest}
      />
      {isEditing ? (
        <Button
          key="apply"
          type="submit"
          variant="solid"
          color="primary"
          size="lg"
          onClick={handleApply}
        >
          {t('platform:apply')}
        </Button>
      ) : (
        <Button
          key="edit"
          size="lg"
          onClick={handleEdit}
          variant="outline"
          color="secondary"
        >
          {t('platform:edit')}
        </Button>
      )}
    </div>
  )
}
