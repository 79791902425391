import { InjectComponentModel } from '@clain/core/utils'
import { PlotOnGraphPanel as PlotOnGraphPanelComponent } from '../ui'
import { IPlotOnGraph } from '../types'
import { useInjectBlockchainModel } from '../hooks'

export const PlotOnGraphPanel = InjectComponentModel<IPlotOnGraph>(
  PlotOnGraphPanelComponent,
  {
    useFactory: () => {
      const {
        disabledPlotOnGraph,
        selectUnSelectAllEntities,
        isAllSelectedEntities,
        plotSelectedOnGraph,
      } = useInjectBlockchainModel()

      return {
        disabledPlotOnGraph,
        selectUnSelectAll: selectUnSelectAllEntities,
        isAllSelected: isAllSelectedEntities,
        plotSelectedOnGraph,
      }
    },
  }
)
