import { inject, injectable } from 'inversify'
import {
  INormalizedTransactionPresetsServerData,
  INormalizedTransactionPresetsServerDataPayload,
  ITransactionFilterPresets,
} from './TransactionFilterPresetsViewModel.types'
import { action, makeObservable, observable } from 'mobx'
import { ITransactionPresetsAPIService } from '@platform/apiServices/transactionPresets'
import {
  normalizeTransactionPresetsServerData,
  normalizeTransactionPresetsServerDataPayload,
} from '@platform/modules/transactionFilterPresets/TransactionFilterPresetsViewModel.utils'
import { DI_TYPES } from '@platform/di/DITypes'

@injectable()
export class TransactionFilterPresetsViewModel
  implements ITransactionFilterPresets
{
  constructor(
    @inject(DI_TYPES.TransactionFilterPresetsAPI)
    private apiService: ITransactionPresetsAPIService
  ) {
    makeObservable(this)
  }

  @observable public presets: INormalizedTransactionPresetsServerData[] = []
  @observable public isLoading = false
  @observable
  public selectedPreset: INormalizedTransactionPresetsServerData | null = null

  @action
  public createPreset = async (
    data: INormalizedTransactionPresetsServerDataPayload
  ) => {
    const response = await this.apiService.createPreset(
      normalizeTransactionPresetsServerDataPayload(data)
    )
    const normalizedPreset = normalizeTransactionPresetsServerData(response)
    this.presets = [normalizedPreset, ...this.presets]
    this.setSelectedPreset(normalizedPreset)
  }

  @action
  public deletePreset = async (id: number) => {
    await this.apiService.deletePreset(id)
    this.clearSelectedPreset()
    this.presets = this.presets.filter((preset) => preset.id !== id)
  }

  @action
  public joinChannelAndGetPresets = async () => {
    this.isLoading = true
    const response = await this.apiService.joinChannelAndGetPresets()
    this.isLoading = false
    this.presets = response.map(normalizeTransactionPresetsServerData)
  }

  @action
  public setSelectedPreset = (
    preset: INormalizedTransactionPresetsServerData
  ) => {
    this.selectedPreset = preset
  }

  @action
  public clearSelectedPreset = () => {
    this.selectedPreset = null
  }
}
