import React from 'react'
import { FiltersBar } from '../../FiltersBar'
import { ScoreFilterProps } from './ScoreFilter.types'
import { FiltersBarProps } from '../../FiltersBar/FilterBar.types'
import { useTranslation } from 'react-i18next'

export const ScoreFilter = ({
  score,
  menuComponent,
  size = 'md',
  clearFilter,
  filterLable: _filterLable,
  filterIcon = 'Score',
}: ScoreFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  const { t } = useTranslation()
  const filterLable = _filterLable || t('platform:score')

  if (score?.[0] == null || score?.[1] == null) {
    return null
  }

  const label = `${score?.[0].toFixed(1)} - ${score?.[1].toFixed(1)}`

  return (
    <FiltersBar
      size={size}
      filterName={{ label: filterLable, icon: filterIcon }}
      operator={{ label: t('platform:between') }}
      filterValue={{
        label,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
