import React, { useState } from 'react'
import { usePopoverState, Calendar } from '@clain/core/ui-kit'
import { CalendarFilterMenuProps } from './CalendarFilter.types'

export const CalendarFilterMenu = ({
  selectedDate,
  allowedRange,
  onSelect,
  mode = 'datetime',
  timeframe = true,
  disableDaysAfterToday,
  freezeFrom,
}: CalendarFilterMenuProps) => {
  const { setOpen } = usePopoverState()
  const [date, setDate] = useState(selectedDate ? selectedDate : allowedRange)

  const onSubmitCalendar = () => {
    setOpen(false)
    onSelect(date)
  }
  return (
    <Calendar
      onSubmit={onSubmitCalendar}
      value={date || undefined}
      // @ts-expect-error TODO fix Calendar types in ui-kit
      onChange={setDate}
      allowedRange={allowedRange}
      mode={mode}
      timeframe={timeframe}
      disableDaysAfterToday={disableDaysAfterToday}
      freezeFrom={freezeFrom}
    />
  )
}
