import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAddressFilterData,
  useClusterSearchData,
  useSelectedClusterFilterData,
} from './useAddressFilterData'
import {
  SearchListFilter,
  SearchListFilterMenu,
  TextMenuListItem,
} from '../../../../../Filters'
import { useCounterpartyStaticStore } from '../../context'

export const AddressFilterMenuContainer = () => {
  const { t } = useTranslation()
  const { menuListData, onSearch } = useClusterSearchData()
  const { selectedItems, setFilter } = useAddressFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  const onSelect = useCallback(
    (items: typeof selectedItems) => {
      setFilter(items)
    },
    [setFilter]
  )

  return (
    <SearchListFilterMenu
      onSelect={onSelect}
      onSearch={onSearch}
      selectedItems={selectedItems}
      list={menuListData}
      size={counterpartyStaticStore.current.filterSize}
      searchPlaceholder={t('platform:findAddress')}
      searchInfoText={t('platform:typeLabelToFind')}
      renderItem={TextMenuListItem}
    />
  )
}

export const AddressFilterContainer = () => {
  const { t } = useTranslation()
  const { isMultiple, multipleLabel, resetFilter } = useAddressFilterData()
  const firstSelected = useSelectedClusterFilterData()
  const counterpartyStaticStore = useCounterpartyStaticStore()

  return (
    <SearchListFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      size={counterpartyStaticStore.current.filterSize}
      label={t('platform:counterpartyAddress')}
      icon="Pin"
      firstSelectedItem={firstSelected}
      clearFilter={resetFilter}
      menuComponent={<AddressFilterMenuContainer />}
    />
  )
}
