import React from 'react'
import { FiltersBar } from '../../FiltersBar'
import { GroupByProps } from './GroupByFilter.types'
import { useGroupByFilter } from './useGroupByFilter'
import { useTranslation } from 'react-i18next'

export const GroupByFilter = ({
  selectedGroupBy,
  menuComponent,
}: GroupByProps) => {
  const { t } = useTranslation()
  const { groupByOptionsMap } = useGroupByFilter()

  if (!selectedGroupBy) {
    return null
  }

  return (
    <FiltersBar
      size={'md'}
      filterName={{ label: t('platform:groupBy') }}
      filterValue={{
        label: groupByOptionsMap[selectedGroupBy],
        menuComponent,
      }}
    />
  )
}
