import React from 'react'
import {
  FiltersBar as UiFiltersBar,
  PopoverBaseContainer,
} from '@clain/core/ui-kit'
import { FiltersBarProps } from './FilterBar.types'

export const FiltersBar = ({
  isDisabled = false,
  size,
  filterName,
  filterValue,
  operator,
  clearFilter,
  maxHeight = 475,
}: FiltersBarProps) => {
  return (
    <UiFiltersBar.Component size={size} disabled={isDisabled}>
      <UiFiltersBar.LabelButton
        label={filterName.label}
        icon={filterName.icon}
      />
      {operator && operator.label ? (
        operator?.operatorComponent ? (
          <PopoverBaseContainer
            order={['content']}
            maxHeight={maxHeight}
            anchor={
              <UiFiltersBar.ConditionButton label={operator.label} selectable />
            }
            content={operator?.operatorComponent}
          />
        ) : (
          <UiFiltersBar.ConditionButton
            label={operator.label}
            selectable={false}
          />
        )
      ) : null}
      {filterValue?.menuComponent ? (
        <PopoverBaseContainer
          order={['content']}
          maxHeight={maxHeight}
          anchor={
            <UiFiltersBar.LabelButton
              label={filterValue.label}
              icon={filterValue.icon}
              customIcon={filterValue.customIcon}
              selectable
            />
          }
          content={filterValue.menuComponent}
        />
      ) : (
        <UiFiltersBar.LabelButton
          label={filterValue.label}
          icon={filterValue.icon}
          customIcon={filterValue.customIcon}
          variant={filterValue?.variant || 'value'}
        />
      )}

      {clearFilter ? <UiFiltersBar.ClearButton onClear={clearFilter} /> : null}
    </UiFiltersBar.Component>
  )
}
