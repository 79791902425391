import { CounterpartyContext } from '../CounterpartyContainerFilters'
import { CounterpartyTypeFilters } from './CounterpartyFilters.types'
import { CounterpartiesFilters } from '../../../../ProbeSandbox/types/filters/CounterpartiesFilters'
import { convertToUnixTimestamp } from '@clain/core/utils/date'

export const normalizeCounterpartyFilters = (
  counterpartyFilters: CounterpartyContext.CounterpartyFiltersState,
  defaultFilters: CounterpartyTypeFilters,
  counterpartyType: 'address' | 'cluster',
  timezone: string
): CounterpartiesFilters => {
  const normalizedFilters: CounterpartiesFilters = {
    ...defaultFilters,
    scoreFrom: undefined,
    scoreTo: undefined,
    includeTokens: defaultFilters?.includeTokens,
    from: undefined,
    to: undefined,
    categories: undefined,
    counterpartyId: undefined,
    counterpartyAddressId: undefined,
    counterpartyName: undefined,
  }

  if (counterpartyFilters.sortBy.data) {
    normalizedFilters['sortBy'] = counterpartyFilters.sortBy.data
  }

  if (counterpartyFilters.sortOrder.data) {
    normalizedFilters['sortOrder'] = counterpartyFilters.sortOrder.data
  }

  if (counterpartyFilters.score.data) {
    const [scoreFrom, scoreTo] = counterpartyFilters.score.data

    normalizedFilters['scoreFrom'] = scoreFrom
    normalizedFilters['scoreTo'] = scoreTo
  }

  if (counterpartyFilters.asset.data) {
    normalizedFilters['includeTokens'] = counterpartyFilters.asset.data
  }

  if (counterpartyFilters.category.data) {
    normalizedFilters['categories'] = counterpartyFilters.category.data
  }

  /*if (trxFilters.assetValue.data) {
    const [fromValue, toValue] = trxFilters.assetValue.data
    const assetCondition = trxFilters.assetValue.condition

    if (assetCondition !== 'isLess') {
      normalizedFilters['amountFrom'] = `${fromValue}`
    }

    if (toValue) {
      normalizedFilters['amountTo'] = `${toValue}`
    }

    if (assetCondition === 'is' || assetCondition === 'isLess') {
      normalizedFilters['amountTo'] = `${fromValue}`
    }
  }*/

  if (counterpartyFilters.calendar.data) {
    const [fromDate, toDate] = counterpartyFilters.calendar.data

    normalizedFilters['from'] = convertToUnixTimestamp(fromDate, timezone)
    normalizedFilters['to'] = convertToUnixTimestamp(toDate, timezone)
  }

  if (
    counterpartyFilters.cluster.data &&
    counterpartyFilters.cluster.data?.length
  ) {
    const cluster = counterpartyFilters.cluster.data[0]
    if (counterpartyType === 'address') {
      normalizedFilters['counterpartyAddressId'] = cluster.id
    } else {
      normalizedFilters['counterpartyId'] = cluster.id
    }
    normalizedFilters['counterpartyName'] = cluster.label
  }

  return normalizedFilters
}
