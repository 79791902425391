import React, { useMemo } from 'react'
import debounce from 'lodash/debounce'
import http, { CancelTokenSource } from '@clain/core/http'
import classNames from 'classnames/bind'
import { checkCoinsByType } from '@clain/core/utils/checkCoinByType'
import {
  searchBlockchainsRequest,
  searchEntitiesRequest,
} from '@clain/api/endpoint'
import {
  EntityAutocompleteProps,
  EntityAutocompleteValue,
} from './CreateAlertModal.types'
import { useEventAway } from '@clain/core/useEventAway'
import {
  Typography,
  TagDeprecated,
  TextField,
  RowDeprecated,
  Score,
  capitalizeFirstLetter,
  Icon,
  TextFieldAdornmentContainer,
} from '@clain/core/ui-kit'
import styles from './CreateAlertModal.scss'
import { createNeverSeen, selectedIdByType } from './CreateAlertModal.utils'
import { COINS } from '@clain/core/utils/currency'
import { PopoverBaseContainer } from '@clainio/web-platform'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

export const EntityAutocomplete: React.FC<EntityAutocompleteProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState('')
  const [rawOptions, setRawOptions] = React.useState<any>()
  const [visible, setVisible] = React.useState(false)
  const inputRef = React.useRef(null)
  const contentRef = React.useRef(null)
  const cancelTokenEntities = React.useRef<CancelTokenSource | undefined>(
    undefined
  )
  const cancelTokenBlockchains = React.useRef<CancelTokenSource | undefined>(
    undefined
  )

  React.useEffect(() => {
    const selected = selectedIdByType(value)

    if (selected) {
      return setInputValue(`${selected}`)
    }
  }, [value])

  const getOptions = React.useCallback(
    debounce((newValue) => {
      if (!newValue?.length) {
        return false
      }

      if (checkCoinsByType(newValue, COINS, 'address')) {
        if (typeof cancelTokenBlockchains.current != typeof undefined) {
          cancelTokenBlockchains.current.cancel()
        }
        cancelTokenBlockchains.current = http.CancelToken.source()

        http
          .get(searchBlockchainsRequest({ term: newValue }), {
            cancelToken: cancelTokenEntities?.current?.token,
          })
          .then((data) => {
            setRawOptions(data.data.data)
          })
          .catch(console.error)
      } else if (newValue?.length >= 3) {
        if (typeof cancelTokenEntities.current != typeof undefined) {
          cancelTokenEntities.current.cancel()
        }
        cancelTokenEntities.current = http.CancelToken.source()

        http
          .get(searchEntitiesRequest({ term: newValue }), {
            cancelToken: cancelTokenEntities.current.token,
          })
          .then((data) => {
            setRawOptions(data.data.data)
          })
          .catch(console.error)
      }
    }, 300),
    []
  )

  const handleChange = (newValue: string) => {
    setInputValue(newValue)
    getOptions(newValue)

    if (!newValue) {
      onChange()
    }
  }

  const filteredRawOptions: Array<EntityAutocompleteValue> = useMemo(() => {
    const result: EntityAutocompleteValue[] = rawOptions
      ?.filter(({ type }) => type === 'entity' || type === 'address')
      ?.flatMap(({ result }) => result)

    const neverSeenList = createNeverSeen(inputValue).reduce((acc, item) => {
      if (
        result.some(
          (token) =>
            token.currency === item.currency &&
            token.address?.toLowerCase() === item.address?.toLowerCase()
        )
      ) {
        return acc
      }

      return [...acc, item]
    }, [])

    if (inputValue) {
      result.push(...neverSeenList)
    }

    return result
  }, [rawOptions])

  const content =
    filteredRawOptions?.length > 0 &&
    filteredRawOptions.map((item, index) => {
      const title = selectedIdByType(item)

      const onClick = () => {
        setVisible(false)
        return onChange(item)
      }

      return (
        <RowDeprecated
          key={index}
          className={cx('SearchItem')}
          onClick={onClick}
        >
          <Icon variant={capitalizeFirstLetter(item.currency)} />
          <RowDeprecated gap={0.5} fullWidth>
            <Score value={item.score} size="sm" useNewColors={true} />
            <Typography
              variant="body1-sm"
              color="black"
              className={cx('SearchTitle')}
            >
              {title}
            </Typography>
            {item?.neverSeen && (
              <TagDeprecated variant="warning" type="outline" size="sm">
                {t('platform:neverSeen')}
              </TagDeprecated>
            )}
          </RowDeprecated>
        </RowDeprecated>
      )
    })

  useEventAway(
    contentRef,
    (e) => {
      if (
        contentRef.current.contains(e.target) ||
        inputRef.current.contains(e.target)
      ) {
        return
      }

      setVisible(false)
    },
    ['click']
  )

  return (
    <PopoverBaseContainer
      open={visible && content.length > 0}
      maxHeight={410}
      onOpenChange={setVisible}
      placement="bottom"
      sameWidth={true}
      content={content}
      className={cx('SearchList')}
      anchor={
        <div className={cx('InputSearchSection')}>
          <TextField
            placeholder={t('platform:searchAddressOrCluster')}
            variant="outline"
            value={inputValue}
            onChange={handleChange}
            className={cx('InputSearch')}
            clearable={visible}
            onClear={() => setRawOptions([[]])}
            ref={inputRef}
            startAddon={() =>
              value && !visible ? (
                <TextFieldAdornmentContainer>
                  <RowDeprecated gap={1}>
                    <Icon variant={capitalizeFirstLetter(value.currency)} />
                    <Score value={value.score} size="sm" useNewColors={true} />
                  </RowDeprecated>
                </TextFieldAdornmentContainer>
              ) : null
            }
            onClick={() => setVisible(true)}
          />
        </div>
      }
    />
  )
}
