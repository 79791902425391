import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
} from '@clain/core/ui-kit'
import { ScoreFilter } from './ScoreFilter'
import { RbfFilter } from './RbfFilter'
import { useFilterAction, useFilterSlice } from './TrxFilters.service'
import { SegwitFilter } from './SegwitFilter'
import { LocktimeFilter } from './LocktimeFilter'
import { VersionFilter } from './VersionFilter'
import { CompressionFilter } from './CompressionFilter'
import { ChangeFilter } from './ChangeFilter'
import { TypeFilter } from './TypeFilter'
import { ComparatorFilter } from './ComparatorFilter'
import { filtersPropsById } from './TrxFilter.const'
import { MultisigFilter } from './MultisigFilter'

export const TransactionFilterMenu: React.FC = () => {
  const { ComparatorFilterPropsById } = filtersPropsById()
  const [, { setFilter: setRbfFilter }] = useFilterSlice('rbf.data')
  const [, { setFilter: setSegwitFilter }] = useFilterSlice('segwit.data')
  const [, { setFilter: setLocktimeFilter }] = useFilterSlice('locktime.data')

  const itemsGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['amount'],
        component: ComparatorFilter.Menu as any,
      },
      VersionFilter.MenuItem(),
      RbfFilter.MenuItem(setRbfFilter),
      SegwitFilter.MenuItem(setSegwitFilter),
      LocktimeFilter.MenuItem(setLocktimeFilter),
      {
        ...ComparatorFilterPropsById['fee'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['feeByte'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['vSize'],
        component: ComparatorFilter.Menu,
      },
      {
        ...ComparatorFilterPropsById['size'],
        component: ComparatorFilter.Menu,
      },
    ]

  const itemsGroup2: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['numberInputs'],
        component: ComparatorFilter.Menu as any,
      },
      {
        ...ComparatorFilterPropsById['numberOutputs'],
        component: ComparatorFilter.Menu,
      },
    ]

  const items = [itemsGroup, itemsGroup2]

  return <FiltersMenu.Wrapper items={items} />
}

export const InputFilterMenu: React.FC = () => {
  const { t } = useTranslation()
  const { ComparatorFilterPropsById, MultisigFilterPropsById } =
    filtersPropsById()
  const { setFilter: setInputMultisig } = useFilterAction(
    'input_multisig.condition'
  )

  const itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['input_amount'],
        component: ComparatorFilter.Menu as any,
      },
      {
        ...MultisigFilterPropsById['input_multisig'],
        onItemClick: () => {
          setInputMultisig('isNot')
        },
        component: () => (
          <MultisigFilter.Menu
            id={'input_multisig'}
            title={t('platform:multisig')}
          />
        ),
      },
      {
        ...ScoreFilter.MenuItem(),
        id: 'input_score',
        component: () => (
          <ScoreFilter.Menu
            id={'input_score'}
            title={t('platform:inputScore')}
          />
        ),
      },
      {
        ...CompressionFilter.MenuItem(),
        id: 'input_compression',
        component: () => (
          <CompressionFilter.Menu
            id={'input_compression'}
            title={t('platform:input')}
          />
        ),
      },
      {
        ...TypeFilter.MenuItem(),
        id: 'input_type',
        component: () => (
          <TypeFilter.Menu id={'input_type'} title={t('platform:inputType')} />
        ),
      },
    ]

  const items = [itemsAssetGroup]

  return <FiltersMenu.Wrapper items={items} />
}

export const OutputFilterMenu: React.FC = () => {
  const { t } = useTranslation()
  const { ComparatorFilterPropsById, MultisigFilterPropsById } =
    filtersPropsById()
  const [, { setFilter: setChangeFilter }] =
    useFilterSlice('output_change.data')
  const { setFilter: setOutputMultisig } = useFilterAction(
    'output_multisig.condition'
  )

  const itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        ...ComparatorFilterPropsById['output_amount'],
        component: ComparatorFilter.Menu as any,
      },
      {
        ...MultisigFilterPropsById['output_multisig'],
        onItemClick: () => {
          setOutputMultisig('isNot')
        },
        component: () => (
          <MultisigFilter.Menu
            id={'output_multisig'}
            title={t('platform:multisig')}
          />
        ),
      },
      {
        ...ScoreFilter.MenuItem(),
        id: 'output_score',
        component: () => (
          <ScoreFilter.Menu
            id={'output_score'}
            title={t('platform:outputScore')}
          />
        ),
      },
      {
        ...CompressionFilter.MenuItem(),
        id: 'output_compression',
        component: () => (
          <CompressionFilter.Menu
            id={'output_compression'}
            title={t('platform:output')}
          />
        ),
      },
      {
        ...TypeFilter.MenuItem(),
        id: 'output_type',
        component: () => (
          <TypeFilter.Menu
            id={'output_type'}
            title={t('platform:outputType')}
          />
        ),
      },
      ChangeFilter.MenuItem(setChangeFilter),
    ]

  const items = [itemsAssetGroup]

  return <FiltersMenu.Wrapper items={items} />
}
