import { observer } from 'mobx-react-lite'
import { CoinType } from '../../../../types/coin'
import { TransactionBlock } from '../../../TransactionBlock'
import { useTransactiosnSearchViewModel } from '../../context'
import { usePlotEntitiesOnGraph } from '@platform/hooks'
import { BlockDataTransaction } from '@platform/apiServices'

type TransactionListProps = {
  coin: CoinType
}

export const TransactionList: React.FC<TransactionListProps> = observer(
  ({ coin }) => {
    const { tableData: transactionsTableData } =
      useTransactiosnSearchViewModel()
    const { selectedEntities, setSelectEntities } = usePlotEntitiesOnGraph(
      'transactions',
      transactionsTableData?.transactions as unknown as BlockDataTransaction
    )

    return (
      <>
        {transactionsTableData?.transactions?.map((transaction) => (
          <TransactionBlock
            key={transaction.hash}
            isUSD={false}
            blockchain={coin}
            transaction={transaction}
            selectedTransaction={selectedEntities.some(
              (selectedTransaction) => selectedTransaction.id === transaction.id
            )}
            onSelectTransaction={setSelectEntities}
          />
        ))}
      </>
    )
  }
)
