import { i18next } from '@platform/i18Manager'
import ProbeVM from '../../../vm/ProbeViewModel'
import { Notification } from '@clain/core/ui-kit'

const notificationStyles = {
  position: 'top-right',
  style: { top: 46 },
} as const

class AddMultipleNodesFacade {
  async handleAddMultipleNodes(searchResult) {
    try {
      ProbeVM.searchController.handleAddMultipleNodes(
        searchResult.addresses,
        searchResult.transactions
      )
      /*
      Notification.notify(
        'Nodes are successfully added.',
        { type: 'success' },
        notificationStyles
      )*/
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      Notification.notify(
        i18next.t('platform:unexpectedError'),
        { type: 'warning' },
        notificationStyles
      )
    }
  }

  async bulkSearch(data) {
    try {
      const searchResult = await ProbeVM.searchService.bulkSearch(data)
      if (!searchResult.addresses.length && !searchResult.transactions.length) {
        return { success: false, error: i18next.t('platform:noValidHashes') }
      }
      return { success: true, data: searchResult }
    } catch (data) {
      return { success: false, error: i18next.t('platform:unexpectedError') }
    }
  }
}

export default new AddMultipleNodesFacade()
