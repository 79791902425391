import { injectable, inject } from 'inversify'

import { GraphController } from '@clain/graph/src/GraphController'
import {
  ILayoutStrategyController,
  LayoutStrategyController,
} from './LayoutStrategyController'
import {
  AnchorKeyController,
  IAnchorKeyController,
} from './AnchorKeyController'
import { RunLayout } from '../RunLayout'
import { Position } from '../../../types/Position'
import { RunLayoutDefault } from '../RunLayoutDefault'
import { RunLayoutEvmTransactions } from '../RunLayoutEvmTransactions'
import { RunLayoutUtxoTransactions } from '../RunLayoutUtxoTransactions'
import { RunLayoutDemixTransaction } from '../RunLayoutDemixTransaction'
import { resolveOverlap } from '@clain/graph-layout/shiftLayout'
import { FormattedGraph, IRunLayoutFlowType, Options } from './layout.types'
import {
  IEntitiesGraph,
  ProbeGraphController,
} from '@clain/graph-entities/src/types'
import { GRAPH_ENTITIES_TYPES } from '@clain/graph-entities/src/constants/injectTypes'
import { RunLayoutCustom } from '../RunLayoutCustom'

export interface ILayoutController {
  getGraphPositionsAfterLayout: (
    options?: Options
  ) => Promise<Record<string, Position>>
}

@injectable()
export class LayoutController implements ILayoutController {
  private graph: ProbeGraphController['graph']
  public virtualGraph: ProbeGraphController['graph']
  private graphController: ProbeGraphController
  private graphLayoutStrategyController: ILayoutStrategyController
  private anchorKeyController: IAnchorKeyController
  private layout: RunLayout

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesGraph)
    graph: IEntitiesGraph
  ) {
    this.graph = graph
  }

  private init = () => {
    this.virtualGraph = this.graph.copy()
    this.graphController = new GraphController(this.virtualGraph)
    this.graphLayoutStrategyController = new LayoutStrategyController(
      this.virtualGraph
    )
    this.anchorKeyController = new AnchorKeyController(this.virtualGraph)
    this.layout = new RunLayout(
      new RunLayoutDefault(this.virtualGraph),
      new RunLayoutEvmTransactions(this.virtualGraph),
      new RunLayoutUtxoTransactions(this.virtualGraph),
      new RunLayoutDemixTransaction(this.virtualGraph),
      new RunLayoutCustom(this.virtualGraph)
    )
  }

  private clear = () => {
    this.virtualGraph.clear()
    this.virtualGraph.removeAllListeners()
  }

  public getGraphPositionsAfterLayout = async ({
    unlockedNodes = [],
    mode = 'default',
  }: Options = {}) => {
    this.init()
    const positions: Record<string, Position> = {}
    const oldPositions: Record<string, Position> = {}

    this.virtualGraph.forEachNode((node, attributes) => {
      oldPositions[node] = {
        x: attributes.position.x,
        y: attributes.position.y,
      }
    })

    if (unlockedNodes.length) {
      unlockedNodes.forEach((node) => {
        this.virtualGraph.updateNodeAttribute(node, 'locked', () => false)
      })
    }

    await this.runLayout(mode)

    this.virtualGraph.forEachNode((node, attributes) => {
      positions[node] = attributes.position
    })

    this.virtualGraph.forEachEdge((_, attributes, source, target) => {
      if (attributes.data.edgeType === 'link') {
        const linkMasterNode = positions[target]
        const diffX = linkMasterNode.x - oldPositions[target].x
        const diffY = linkMasterNode.y - oldPositions[target].y
        positions[source] = {
          x: oldPositions[source].x + diffX,
          y: oldPositions[source].y + diffY,
        }
      }
    })

    this.clear()

    return positions
  }

  private runLayout = async (mode: Options['mode']) => {
    const strategies = this.generateLayoutStrategies()
    const layoutResults: {
      positions: Record<string, Position>
      strategy: IRunLayoutFlowType
      group: string[]
      groupId: number
    }[] = []

    for (const { strategy, nodes, edges, group, groupId } of strategies) {
      const unlockedNodes = nodes.filter(
        (node) => !this.virtualGraph.getNodeAttributes(node).locked
      )
      if (!unlockedNodes.length) {
        continue
      }
      let selectedNodes = nodes
      let selectedEdges = edges

      if (mode === 'rearrange') {
        selectedNodes = unlockedNodes
        const edgeKeysSet = new Set<string>()
        selectedNodes.forEach((key) => {
          this.graph.edges(key).forEach((edgeKey) => {
            const source = this.graph.source(edgeKey)
            const target = this.graph.target(edgeKey)
            if (
              selectedNodes.includes(source) &&
              selectedNodes.includes(target)
            ) {
              edgeKeysSet.add(edgeKey)
            }
          })
        })
        selectedEdges = Array.from(edgeKeysSet)
      }

      const { key: anchorKey, nodeType: anchorNodeType } =
        this.anchorKeyController.getAnchorKey(unlockedNodes, strategy)

      const positions = await this.layout.get(strategy).run({
        anchorKey,
        nodes: selectedNodes,
        edges: selectedEdges,
        anchorNodeType,
        group,
        mode,
      })
      layoutResults.push({ positions, strategy, group, groupId })
    }

    this.resolveOverlapInsideGraph(layoutResults)
    this.resolveOverlapBetweenGraphs(layoutResults)
  }

  private generateLayoutStrategies = (): FormattedGraph[] => {
    const groups = this.getConnectedNodesWithUnlockedNodes()
    return groups.reduce<FormattedGraph[]>(
      (formattedGraphs, group, currentIndex) => {
        const strategies =
          this.graphLayoutStrategyController.getStrategies(group)

        Object.keys(strategies).forEach((strategy: keyof typeof strategies) => {
          strategies[strategy].forEach((item) => {
            const nodes = Array.from(item.nodes)
            const edges = Array.from(item.edges)

            if (strategy === 'default') {
              const allNodeLocked = nodes.every(
                (node: any) => this.virtualGraph.getNodeAttributes(node).locked
              )
              if (allNodeLocked) {
                return
              }
            }

            formattedGraphs.push({
              // @ts-ignore
              strategy,
              // @ts-ignore
              edges,
              // @ts-ignore
              nodes,
              group,
              groupId: currentIndex,
            })
          })
        })

        return formattedGraphs
      },
      []
    )
  }

  private resolveOverlapInsideGraph = (
    layoutResults: {
      positions: Record<string, Position>
      strategy: IRunLayoutFlowType
      group: string[]
      groupId: number
    }[]
  ) => {
    layoutResults
      .filter((el) => el.strategy === 'default' || el.strategy === 'custom')
      .forEach(({ positions, group }) => {
        this.shiftOverlappedSubGraph({ positions, group, direction: 'top' })
      })
    layoutResults
      .filter((el) => el.strategy !== 'default' && el.strategy !== 'custom')
      .forEach(({ positions, group }) => {
        this.shiftOverlappedSubGraph({ positions, group })
      })
  }

  private resolveOverlapBetweenGraphs = (
    layoutResults: {
      positions: Record<string, Position>
      strategy: IRunLayoutFlowType
      group: string[]
      groupId: number
    }[]
  ) => {
    const layoutResultsFromDifferentGraphs = layoutResults.reduce(
      (acc, current) => {
        if (!acc.some((group) => group.groupId === current.groupId)) {
          acc.push(current)
        }
        return acc
      },
      [] as typeof layoutResults
    )

    layoutResultsFromDifferentGraphs.forEach(({ group }) => {
      const positions = group.reduce((acc, cur) => {
        acc[cur] = this.virtualGraph.getNodeAttributes(cur).position
        return acc
      }, {} as Record<string, Position>)
      this.shiftOverlappedSubGraph({
        positions,
        group: this.virtualGraph.nodes(),
      })
    })
  }

  private shiftOverlappedSubGraph = ({
    positions,
    direction = 'bottom',
    group,
  }: {
    positions: Record<string, Position>
    direction?: 'top' | 'bottom'
    group: string[]
  }) => {
    const positionsAfterShift = resolveOverlap({
      unlockedGroup: positions,
      lockedPositions: group.reduce((acc, cur) => {
        if (!positions[cur]) {
          acc.push(this.virtualGraph.getNodeAttributes(cur).position)
        }
        return acc
      }, [] as Position[]),
      direction,
    })
    Object.entries(positionsAfterShift).forEach(([key, position]) => {
      this.virtualGraph.updateNodeAttribute(key, 'position', () => position)
    })
    return positionsAfterShift
  }

  private getConnectedNodesWithUnlockedNodes = () =>
    this.graphController.findConnectedNodesByAttribute(
      'locked',
      (isLocked) => !isLocked
    )
}
