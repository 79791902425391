import { observable, action, computed, makeObservable } from 'mobx'
import {
  BlockTransactionEVM,
  BlockTransactionUTXO,
  BlockDataTransaction,
} from '@platform/apiServices'
import { BlocksHeightState } from '@platform/states/BlocksHeightState'
import { ctx } from '@platform/ctx'
import { createProbePath } from '@platform/constants'
import {
  LiteTransactionAddressUtxo,
  EventCluster,
  EventTransactionEVM,
  EventTransactionUTXO,
  EventClusterBasePayload,
  EventAddressCounterpartyPayload,
  EventAddress,
} from '@clain/graph-entities'
import { isUTXO } from '@clain/core/types'
import {
  IPlotEntitiesOnGraph,
  PlotEntitiesOnGraphType,
} from './PlotEntitiesOnGraph.types'
import { inject, injectable } from 'inversify'
import { DI_TYPES } from '@platform/di/DITypes'
import { IPlotEntities } from '@platform/components/ProbeSandbox/models'

export interface IPlotEntitiesState<
  TTransaction extends BlockDataTransaction = BlockDataTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
> {
  _selectedTransactions: TTransaction[]
  transactions: TTransaction[]

  _selectedClusters: TCluster[]
  clusters: TCluster[]

  _selectedAddresses: TAddresses[]
  addresses: TAddresses[]
}

@injectable()
export class PlotEntitiesState<
  TTransaction extends BlockDataTransaction = BlockDataTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
> implements IPlotEntitiesState<TTransaction, TCluster, TAddresses>
{
  @observable _selectedTransactions: TTransaction[] = []
  @observable transactions: TTransaction[] = []

  @observable _selectedClusters: TCluster[] = []
  @observable clusters: TCluster[] = []

  @observable _selectedAddresses: TAddresses[] = []
  @observable addresses: TAddresses[] = []

  constructor() {
    makeObservable(this)
  }
}

@injectable()
export class PlotEntitiesOnGraph<
  TTransaction extends BlockDataTransaction = BlockDataTransaction,
  TCluster extends EventClusterBasePayload = EventClusterBasePayload,
  TAddresses extends
    EventAddressCounterpartyPayload = EventAddressCounterpartyPayload,
> implements IPlotEntitiesOnGraph<TTransaction, TCluster>
{
  private blocksHeightState: BlocksHeightState

  constructor(
    @inject(DI_TYPES.PlotProbeEntities)
    private plotEntitiesController: IPlotEntities,
    @inject(DI_TYPES.PlotEntitiesState)
    private plotEntitiesState: IPlotEntitiesState<
      TTransaction,
      TCluster,
      TAddresses
    >
  ) {
    makeObservable(this)
    this.blocksHeightState = ctx.blocksHeightState
    this.plotEntitiesController = plotEntitiesController
  }

  @action
  public init: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster,
    TAddresses
  >['init'] = (type, data) => {
    if (type === 'clusters') {
      this.plotEntitiesState.clusters = data as TCluster[]
    } else if (type === 'addresses') {
      this.plotEntitiesState.addresses = data as TAddresses[]
    } else if (type === 'transactions') {
      this.plotEntitiesState.transactions = data as TTransaction[]
    }
  }

  // Clusters

  @computed
  private get selectedClusters() {
    return this.plotEntitiesState._selectedClusters
  }

  @computed
  private get selectedClustersCount() {
    return this.plotEntitiesState._selectedClusters.length
  }

  @action
  private setSelectClusters = (data: TCluster, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedClusters = [
        ...this.plotEntitiesState._selectedClusters,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedClusters =
        this.plotEntitiesState._selectedClusters.filter(
          (cluster) => cluster.clusterId !== data.clusterId
        )
    }
  }

  @action
  private resetSelectedClusters = () => {
    this.plotEntitiesState._selectedClusters = []
  }

  @action
  private selectUnSelectAllClusters = () => {
    if (this.isAllSelectedClusters) {
      this.resetSelectedClusters()
      return
    }

    this.plotEntitiesState._selectedClusters = this.plotEntitiesState.clusters
  }

  @computed
  private get isAllSelectedClusters() {
    if (!this.plotEntitiesState.clusters?.length) return false

    return (
      this.selectedClusters.length === this.plotEntitiesState.clusters.length
    )
  }

  // Addresses
  @computed
  private get selectedAddresses() {
    return this.plotEntitiesState._selectedClusters
  }

  @computed
  private get selectedAddressesCount() {
    return this.plotEntitiesState._selectedAddresses.length
  }

  @action
  private setSelectAddresses = (data: TAddresses, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedAddresses = [
        ...this.plotEntitiesState._selectedAddresses,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedAddresses =
        this.plotEntitiesState._selectedAddresses.filter(
          (cluster) => cluster.clusterId !== data.clusterId
        )
    }
  }

  @action
  private resetSelectedAddresses = () => {
    this.plotEntitiesState._selectedAddresses = []
  }

  @action
  private selectUnSelectAllAddresses = () => {
    if (this.isAllSelectedClusters) {
      this.resetSelectedClusters()
      return
    }

    this.plotEntitiesState._selectedAddresses = this.plotEntitiesState.addresses
  }

  @computed
  private get isAllSelectedAddresses() {
    if (!this.plotEntitiesState.clusters?.length) return false

    return (
      this.selectedAddresses.length === this.plotEntitiesState.clusters.length
    )
  }

  // Transactions
  @computed
  private get selectedTransactions() {
    return this.plotEntitiesState._selectedTransactions
  }

  @computed
  private get selectedTransactionsCount() {
    return this.plotEntitiesState._selectedTransactions.length
  }

  @action
  private setSelectTransactions = (data: TTransaction, checked: boolean) => {
    if (checked) {
      this.plotEntitiesState._selectedTransactions = [
        ...this.plotEntitiesState._selectedTransactions,
        data,
      ]
    } else {
      this.plotEntitiesState._selectedTransactions =
        this.plotEntitiesState._selectedTransactions.filter(
          (transaction) => transaction.id !== data.id
        )
    }
  }

  @action
  private resetSelectedTransactions = () => {
    this.plotEntitiesState._selectedTransactions = []
  }

  @action
  private selectUnSelectAllTransactions = () => {
    if (this.isAllSelectedTransactions) {
      this.resetSelectedTransactions()
      return
    }

    this.plotEntitiesState._selectedTransactions =
      this.plotEntitiesState.transactions
  }

  @computed
  private get isAllSelectedTransactions() {
    if (!this.plotEntitiesState.transactions?.length) return false

    return (
      this.selectedTransactions.length ===
      this.plotEntitiesState.transactions.length
    )
  }

  public selectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectedEntities'] = (type) => {
    if (type === 'clusters') {
      return this.selectedClusters
    } else if (type === 'addresses') {
      return this.selectedAddresses
    } else {
      return this.plotEntitiesState._selectedTransactions as any
    }
  }

  public selectedEntitiesCount: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectedEntitiesCount'] = (type) => {
    if (type === 'clusters') {
      return this.selectedClustersCount
    } else if (type === 'addresses') {
      return this.selectedAddressesCount
    } else {
      return this.selectedTransactionsCount
    }
  }

  @action
  public setSelectEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['setSelectEntities'] = (type) => {
    if (type === 'clusters') {
      return this.setSelectClusters as any
    } else if (type === 'addresses') {
      return this.setSelectAddresses as any
    } else {
      return this.setSelectTransactions as any
    }
  }

  public selectUnSelectAllEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['selectUnSelectAllEntities'] = (type) => {
    if (type === 'clusters') {
      return this.selectUnSelectAllClusters
    } else if (type === 'addresses') {
      return this.selectUnSelectAllAddresses
    } else if (type === 'transactions') {
      return this.selectUnSelectAllTransactions
    }
  }

  public isAllSelectedEntities: IPlotEntitiesOnGraph<
    TTransaction,
    TCluster
  >['isAllSelectedEntities'] = (type) => {
    if (type === 'clusters') {
      return this.isAllSelectedClusters
    } else if (type === 'addresses') {
      return this.isAllSelectedAddresses
    } else if (type === 'transactions') {
      return this.isAllSelectedTransactions
    }
  }

  @computed.struct
  public get disabledPlotOnGraph() {
    return (
      !this.plotEntitiesState._selectedTransactions.length &&
      !this.plotEntitiesState._selectedClusters.length &&
      !this.plotEntitiesState._selectedAddresses.length
    )
  }

  @action
  public plotSelectedOnGraph = () => {
    if (!this.disabledPlotOnGraph) {
      const normalizedAddTransactions =
        this.plotEntitiesState._selectedTransactions.map((_transaction) => {
          if (isUTXO(_transaction.currency)) {
            const transaction = _transaction as unknown as BlockTransactionUTXO

            return {
              createBy: 'by-trx-id',
              strategy: 'transaction',
              currency: transaction.currency,
              id: transaction.id,
              hash: transaction.hash,
              direction: 'out',
              inputs:
                transaction.inputs as unknown as LiteTransactionAddressUtxo[],
              outputs:
                transaction.outputs as unknown as LiteTransactionAddressUtxo[],
            } satisfies EventTransactionUTXO
          } else {
            const transaction = _transaction as BlockTransactionEVM

            return {
              strategy: 'transaction',
              type: 'transfer',
              currency: transaction.currency,
              index: 0,
              from: {
                hash: transaction.transfers[0]?.sender.address,
                id: transaction.transfers[0]?.sender.addressId,
                clusterId: transaction.transfers[0]?.sender.clusterId,
              },
              to: {
                hash: transaction.transfers[0]?.receiver.address,
                id: transaction.transfers[0]?.receiver.addressId,
                clusterId: transaction.transfers[0]?.receiver.clusterId,
              },
              hash: transaction.hash,
              id: transaction.id,
            } satisfies EventTransactionEVM
          }
        })

      const normalizeAddClusters = this.plotEntitiesState._selectedClusters.map(
        (cluster) =>
          ({
            strategy: 'cluster',
            ...cluster,
          }) satisfies EventCluster
      )

      const normalizeAddAddresses =
        this.plotEntitiesState._selectedAddresses.map(
          (address) =>
            ({
              strategy: 'address-cluster',
              ...address,
            }) satisfies EventAddress
        )

      this.plotEntitiesOnGraph([
        ...normalizeAddClusters,
        ...normalizeAddAddresses,
        ...normalizedAddTransactions,
      ])
      this.resetAllEntities()
    }
  }

  @action
  private plotEntitiesOnGraph = (
    entities: Parameters<typeof this.plotEntitiesController.pushPlotEntities>[1]
  ) => {
    this.blocksHeightState.getNewProbe().then((probe) => {
      window.open(createProbePath(probe.id), '_blank')
      this.plotEntitiesController.pushPlotEntities(probe.id, entities)
    })
  }

  @action
  public resetEntities: IPlotEntitiesOnGraph<TTransaction, TCluster>['clear'] =
    (type) => {
      if (type === 'clusters') {
        this.resetSelectedClusters()
        this.plotEntitiesState.clusters = []
      } else if (type === 'addresses') {
        this.resetSelectedAddresses()
        this.plotEntitiesState.addresses = []
      } else if (type === 'transactions') {
        this.resetSelectedTransactions()
        this.plotEntitiesState.transactions = []
      }
    }

  private resetAllEntities = () => {
    ;(
      [
        'addresses',
        'clusters',
        'transactions',
      ] satisfies PlotEntitiesOnGraphType[]
    ).forEach((type) => this.resetEntities(type))
  }

  @action
  public clear: IPlotEntitiesOnGraph<TTransaction, TCluster>['clear'] = (
    type
  ) => {
    this.resetEntities(type)
    this.plotEntitiesController.clear()
  }
}
