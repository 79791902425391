import React, { FC } from 'react'
import { RowDeprecated } from '@clain/core/ui-kit'
import { useSearchEntitiesTranslation } from './SearchEntities.constants'
import { Tooltip } from '@clain/core/ui-kit'
import { SearchEntities } from '@clain/core/ui-kit'
import { SearchResultActionsProps } from './SearchEntities.types'

export const SearchResultActions: FC<SearchResultActionsProps> = ({
  groupIdx,
  id,
  itemIdx,
  item,
  entityType,
  activeGroup,
  activeItem,
  activeButton,
  onHoverActiveButton,
  onClickActionButton,
  isMouseActive,
  activeButtonRef,
}) => {
  const { actionButtons } = useSearchEntitiesTranslation()

  return (
    <RowDeprecated gap={0.25}>
      {actionButtons
        .filter((el) => {
          switch (entityType) {
            case 'cluster':
            case 'entity':
              return el.type === 'cluster'
            case 'transaction':
              return el.type === 'explorer'
            case 'address':
              return el.type === 'cluster' || el.type === 'address'
            default:
              return true
          }
        })
        .map(({ label, type, icon }, btnIdx) => {
          const actionId = `${id}-ActionButton-${btnIdx}`
          const isActiveButton =
            groupIdx === activeGroup &&
            activeItem === itemIdx &&
            activeButton === btnIdx
          return (
            <Tooltip
              content={label}
              visibleDelay={500}
              visible={isActiveButton && !isMouseActive}
              key={actionId}
            >
              <div>
                <SearchEntities.SearchItemActionButton
                  ref={(el) => {
                    if (isActiveButton) {
                      activeButtonRef.current = el
                    }
                  }}
                  isActive={isActiveButton}
                  onHoverActiveButton={() => {
                    onHoverActiveButton(groupIdx, itemIdx, btnIdx, false)
                  }}
                  variant={icon}
                  value={type}
                  onClick={() => onClickActionButton(type, entityType, item)}
                />
              </div>
            </Tooltip>
          )
        })}
    </RowDeprecated>
  )
}
