import { CounterTabOption } from '@clain/core/ui-kit'
import { EntitiesTableTabTypes } from '../../../EntitiesTable/EntitiesTable.types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useTableHeaderTabs = ({
  transactionsCounterValue,
  counterpartiesCounterValue,
  addressesCounterValue,
  osintCounterValue,
}: {
  transactionsCounterValue?: number
  counterpartiesCounterValue?: number
  addressesCounterValue?: number
  osintCounterValue?: number
} = {}) => {
  const { t } = useTranslation()

  return useMemo(() => {
    const tabs: Array<CounterTabOption<EntitiesTableTabTypes>> = [
      {
        value: 'counterparties',
        children: t('platform:clusterTableCounterparties'),
        counterValue: counterpartiesCounterValue ?? 0,
      },
      {
        value: 'transactions',
        children: t('platform:clusterTableTransactions'),
        counterValue: transactionsCounterValue ?? 0,
      },
      {
        value: 'addresses',
        children: t('platform:clusterTableAddresses'),
        counterValue: addressesCounterValue ?? 0,
      },
      {
        value: 'osint',
        children: t('platform:clusterTableOsint'),
        counterValue: osintCounterValue ?? 0,
      },
    ]

    return tabs
  }, [
    t,
    transactionsCounterValue,
    counterpartiesCounterValue,
    addressesCounterValue,
    osintCounterValue,
  ])
}
