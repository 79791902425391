import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import { injectFiltersModel } from '../../../context'
import { CalendarFilterState } from '../../../types'
import { ComponentFilterKey } from '@clain/core/utils'
import { CalendarInput } from '@clain/core/ui-kit'

export const CalendarFilter = injectFiltersModel<
  ComponentFilterKey,
  Record<string, CalendarFilterState>
>(({ filterKey, useFilterSlice }) => {
  const { t } = useTranslation()
  const [values, { setFilter }] = useFilterSlice(filterKey.data)

  const [date, setDate] = useState(
    values ? values : ([] as unknown as [Date, Date])
  )

  useEffect(() => {
    setDate(values)
  }, [values])

  const onSubmitCalendar = (hide: () => void | undefined) => {
    setFilter(date)
    hide?.()
  }

  return (
    <CalendarInput
      ErrorBoundaryWrapper={ErrorBoundaryContainer}
      showTimeOnLabel={true}
      size={'md'}
      mode={'datetime'}
      variant={'outline'}
      value={date?.length ? date : undefined}
      onChange={(value) => setDate(value as [Date, Date])}
      requiredRange
      onSubmit={onSubmitCalendar}
      submitButtonText={t('platform:apply')}
    />
  )
})
