import { useInjectBlockchainModel } from './useInjectBlockchainModel'
import { PaginationProps } from '@clain/core/Pagination'

export const useEntityPagination = (): PaginationProps => {
  const viewModel = useInjectBlockchainModel()

  const {
    viewModel: { filtersUpdate, tableData, filters },
  } = viewModel

  return {
    value: filters.page,
    totalPages:
      tableData?.pagination?.totalEntries / tableData?.pagination?.pageSize,
    onChange: (page) => {
      filtersUpdate({ page })
    },
  }
}
