import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as echarts from 'echarts'
import { fontFamily } from '@clain/core/styles/fonts'
import { DashboardOptions } from './dashboard.types'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { DashboardTooltip } from './DashboardTooltip'

export const useChartOptions = () => {
  const { t } = useTranslation()

  return useCallback(
    ({
      data,
      topBy,
      coin,
      currency,
      formatDate,
      formatNumber,
      formatMoney,
    }: DashboardOptions) => {
      const formatTreemapLabel = (params) => {
        const cluster = (params.data as any)?.cluster

        if (!cluster) {
          return params.name
        }

        let formattedValue = ''

        if (topBy === 'balance') {
          formattedValue = formatMoney({
            value: currency === 'usd' ? cluster.balance_usd : cluster.balance,
            currency,
          })
        }

        if (topBy === 'addresses') {
          formattedValue = t('platform:addressesCount', '', {
            count: formatNumber(cluster.size, 0),
          })
        }

        if (topBy === 'transactions') {
          formattedValue = t('platform:transactionsCount', '', {
            count: formatNumber(cluster.transaction_count, 0),
          })
        }

        return [
          '{name|' + params.name + '}',
          '{hr|}',
          '{topBy|' + formattedValue + '}',
        ].join('\n')
      }

      const gapWidth = 4
      const itemStyle = {
        borderColor: '#fff',
        borderRadius: 2,
        gapWidth,
        shadowColor: 'rgba(140, 152, 164, 0.25)',
        shadowBlur: 0,
      }

      const treemap: echarts.TreemapSeriesOption = {
        name: '',
        type: 'treemap',
        visibleMin: 1,
        label: {
          show: true,
          position: 'insideTopLeft',
          formatter: formatTreemapLabel,
          rich: {
            topBy: { fontSize: 14 },
            name: {
              fontSize: 14,
              fontWeight: 'bold',
              padding: [2, 0, 2, 0],
            },
            hr: {
              width: '100%',
              borderColor: 'rgba(255,255,255,0.4)',
              borderWidth: 0.5,
              height: 0,
              lineHeight: 10,
            },
          },
        },
        left: 12,
        top: 12,
        right: 12,
        bottom: 12,
        roam: false,
        itemStyle: { gapWidth: 24 },
        breadcrumb: {
          show: false,
          emptyItemWidth: 0,
        },
        data: data.map(({ color, children, ...item }) => ({
          ...item,
          itemStyle: { color, gapWidth: 4 },
          upperLabel: { show: true, height: 32 },
          visibleMin: 0,
          children: children?.map(({ color, ...item }) => ({
            ...item,
            visibleMin: 0,
            label: {},
            itemStyle: { ...itemStyle, gapWidth: 4, color },
          })),
        })),
      }

      return {
        textStyle: {
          fontFamily,
          fontSize: 14,
        },
        tooltip: data
          ? {
              appendToBody: false,
              transitionDuration: 0,
              enterable: false,
              formatter: createTooltipFormatter(
                (info) => ({ cluster: info.data.cluster }),
                DashboardTooltip,
                { coin, currency, formatDate }
              ),
              extraCssText: 'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25);',
              borderWidth: 0,
              confine: true,
            }
          : null,
        series: [treemap],
      }
    },
    [t]
  )
}
