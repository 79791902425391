import React, { useRef } from 'react'
import { Typography, Icon, Button, AlertCaution } from '@clain/core/ui-kit'
import Form, { Field, validators } from '@clain/core/Form'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Textarea } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'

export const AddMultipleNodesModalBody = ({
  hide,
  formError,
  formLoading,
  handleBulkSearch,
}) => {
  const cancelButtonRef = useRef(null)
  const { t } = useTranslation()
  const onFormSubmit = async (data) => {
    await handleBulkSearch(data, hide)
  }

  return (
    <React.Fragment>
      <Form onSubmit={onFormSubmit}>
        {({ form }) => {
          const { valid } = form.getState()
          return (
            <ColDeprecated gap={2.1}>
              <RowDeprecated align="between">
                <Typography variant="subheading2" color="black">
                  {t('platform:addMultipleNodes')}
                </Typography>
                <div>
                  <Icon
                    variant={'Close'}
                    onClick={hide}
                    color={'onBackgroundVariant1'}
                  />
                </div>
              </RowDeprecated>
              <RowDeprecated>
                <Typography variant={'body1'} color="grey2">
                  {t('platform:pasteHashesText')}
                </Typography>
              </RowDeprecated>
              <RowDeprecated>
                <Field
                  fullWidth
                  name="term"
                  variant="outline"
                  as={Textarea}
                  rows={15}
                  placeholder={t('platform:pasteHashesPlaceholder')}
                  autoFocus={true}
                  validate={validators.required()}
                />
              </RowDeprecated>
              <RowDeprecated gap={0.75}>
                <Field name="withClusters" as={Checkbox} defaultValue={false} />
                <Typography variant={'body1'} color="black">
                  {t('platform:plotRelevantClusters')}
                </Typography>
              </RowDeprecated>
              {formError && (
                <AlertCaution variant="error">{formError}</AlertCaution>
              )}
              <RowDeprecated align={'right'}>
                <Button
                  ref={cancelButtonRef}
                  size="lg"
                  variant="outline"
                  color="secondary"
                  onClick={hide}
                >
                  {t('platform:close')}
                </Button>
                <Button
                  style={{
                    width: cancelButtonRef.current?.clientWidth || 'auto',
                  }}
                  isLoading={formLoading}
                  disabled={!valid}
                  size="lg"
                  variant="solid"
                  color="primary"
                  type="submit"
                >
                  {t('platform:add')}
                </Button>
              </RowDeprecated>
            </ColDeprecated>
          )
        }}
      </Form>
    </React.Fragment>
  )
}
