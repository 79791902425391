import { useTranslation } from 'react-i18next'
import {
  EntitiesCount,
  PlotOnGraphPanel,
  SelectedEntitiesCount,
} from '../../containers'
import { ListToPanelLayout } from '../../ui'

export const TransactionListToPanel: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ListToPanelLayout
      title={t('platform:transactions')}
      leftComponent={
        <>
          <EntitiesCount />
          <SelectedEntitiesCount entityName={t('platform:transactions')} />
        </>
      }
      rightComponent={<PlotOnGraphPanel />}
    />
  )
}
