import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '@platform/components/Filters'
import {
  FilterDropdownMenuComponentProps,
  FilterDropdownMenuContainerItem,
} from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'

import { injectFiltersModel } from '@platform/components/BlockchainFilter/context'
import { ComponentFilterKey } from '@clain/core/utils'
import { COMPRESSION_OPTIONS } from '@platform/components/BlockchainFilter/constants'
import { useSelectFilterOptions } from '@platform/components/Filters/SelectFilter/SelectFilter.constants'
import { CompressionFilterState } from '@platform/components/BlockchainFilter/types'

const LABEL = 'Compression'

type FilterProps = Partial<FilterDropdownMenuComponentProps> & {
  title?: string
}

const MenuContainer = injectFiltersModel<
  ComponentFilterKey & FilterProps,
  Record<string, CompressionFilterState>
>(({ filterKey, title, useFilterSlice }) => {
  const [filter, { setFilter }] = useFilterSlice(filterKey.data)

  return (
    <SelectFilterMenu
      selected={filter ? filter.value : null}
      title={title}
      onChange={setFilter}
      size={'md'}
      options={COMPRESSION_OPTIONS}
    />
  )
})

const FilterContainer = injectFiltersModel<
  ComponentFilterKey & FilterProps,
  Record<string, CompressionFilterState>
>(({ filterKey, title, useFilterSlice }) => {
  const [, { resetFilter }] = useFilterSlice(filterKey.root)
  const [filter] = useFilterSlice(filterKey.data)
  const [condition] = useFilterSlice(filterKey.condition)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={title}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer filterKey={filterKey} title={title} />}
      conditionComponent={<FilterConditionContainer filterKey={filterKey} />}
    />
  )
})

const FilterConditionContainer = injectFiltersModel<
  ComponentFilterKey,
  Record<string, CompressionFilterState>
>(({ filterKey, useFilterSlice }) => {
  const [condition, { setFilter }] = useFilterSlice(filterKey.condition)
  const compressionOptions = useSelectFilterOptions()

  return (
    <SelectFilterCondition
      options={compressionOptions}
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
})

const MenuItem: FilterDropdownMenuContainerItem = {
  id: '',
  label: LABEL,
  icon: null,
  isDisabled: false,
  // component: MenuContainer,
}

export const CompressionFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
  Menu: MenuContainer,
}
