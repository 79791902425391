import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { BlockchainIcon, RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import { ReactComponent as ChevronDownIcon } from '@clain/core/assets/chevron_down.svg'
import { ReactComponent as ChevronUpIcon } from '@clain/core/assets/chevron_up.svg'

import AssetsRow from '../../AssetsRow'
import { TokenBalance } from '../../../types/converted/TokenBalance'
import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'
import { DropdownMenu } from '@clain/core/ui-kit'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { getActiveEntityInfo } from '../../../vm/active-entity/helpers/getAddressId'
import {
  DEFAULT_DOGE_TOKEN,
  DEFAULT_LTC_TOKEN,
  DEFAULT_TRON_TOKEN,
  isDefaultTokenId,
} from '../../../utils/convertTokenBalances'
import { CoinType } from '@platform/types/coin'

const cx = classnames.bind(styles)

const balanceTypeToName = {
  volume: 'platform:volume',
  balance: 'platform:balance',
  sent: 'platform:sent',
  received: 'platform:received',
} as const

interface TokensBalanceProps {
  tokens: Array<TokenBalance>
}

const NOT_SUPPORTED_TOKENS = [
  DEFAULT_TRON_TOKEN.id,
  DEFAULT_DOGE_TOKEN.id,
  DEFAULT_LTC_TOKEN.id,
]

const TokensBalance: React.FC<TokensBalanceProps> = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)
  const [balanceType, setBalanceType] = React.useState<
    'volume' | 'balance' | 'sent' | 'received'
  >('balance')
  const [currency, setCurrency] = React.useState<'usd' | 'native'>('usd')
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  const activeEntityInfo = getActiveEntityInfo(ProbeVM.activeEntity)

  const filteredTokens = React.useMemo(() => {
    return (
      activeEntityInfo?.tokensBalance?.filter(
        ({ token }) => token.id > 2 && !NOT_SUPPORTED_TOKENS.includes(token.id)
      ) || []
    )
  }, [activeEntityInfo?.tokensBalance])

  const sortedTokens = React.useMemo(() => {
    return [...filteredTokens].sort(
      ({ balanceUsd: a }, { balanceUsd: b }) => b - a
    )
  }, [filteredTokens])

  if (!sortedTokens?.length) return null

  return (
    <ColDeprecated>
      <RowDeprecated
        align="between"
        className={cx('TokensBalance')}
        onClick={() => setIsOpen((is) => !is)}
      >
        <Typography variant="body3-sm" color="grey4">
          {t('platform:assets')} ({activeEntityInfo?.tokensTotalCount})
        </Typography>
        <RowDeprecated>
          <AssetsRow
            tokens={sortedTokens.map(({ token }) => token)}
            showRest={false}
          />
          {isOpen ? (
            <ChevronUpIcon className={cx('ChevronIcon')} />
          ) : (
            <ChevronDownIcon className={cx('ChevronIcon')} />
          )}
        </RowDeprecated>
      </RowDeprecated>
      {isOpen && (
        <ColDeprecated gap={0.5}>
          <RowDeprecated gap={0} align="between">
            <Typography
              className={cx('AssetTitle')}
              variant="body1-sm"
              color="grey3"
            >
              {t('platform:asset')}
            </Typography>
            <RowDeprecated gap={0.5} className={cx('BalanceSymbolTitle')}>
              <DropdownMenu
                value={balanceType}
                onChange={setBalanceType}
                options={[
                  {
                    value: 'volume',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        {t('platform:volume')}
                      </Typography>
                    ),
                  },
                  {
                    value: 'balance',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        {t('platform:balance')}
                      </Typography>
                    ),
                  },
                  {
                    value: 'sent',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        {t('platform:sent')}
                      </Typography>
                    ),
                  },
                  {
                    value: 'received',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        {t('platform:received')}
                      </Typography>
                    ),
                  },
                ]}
              >
                <div className={cx('DropdownInner', 'volume')}>
                  <Typography variant="body1-sm" color="grey3">
                    {t(balanceTypeToName[balanceType])}
                  </Typography>
                  <ChevronDownIcon className={cx('ChevronIcon', 'shifted')} />
                </div>
              </DropdownMenu>
              <DropdownMenu
                value={currency}
                onChange={setCurrency}
                options={[
                  {
                    value: 'native',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        {t('platform:native')}
                      </Typography>
                    ),
                  },
                  {
                    value: 'usd',
                    label: (
                      <Typography variant="body1-sm" color="grey3">
                        USD
                      </Typography>
                    ),
                  },
                ]}
              >
                <div className={cx('DropdownInner', 'symbol')}>
                  <Typography variant="body1-sm" color="grey3">
                    {t(
                      currency === 'native' ? 'platform:native' : 'platform:usd'
                    )}
                  </Typography>
                  <ChevronDownIcon className={cx('ChevronIcon', 'shifted')} />
                </div>
              </DropdownMenu>
            </RowDeprecated>
          </RowDeprecated>
          <div>
            {sortedTokens.map(
              ({
                balance,
                balanceUsd,
                received,
                receivedUsd,
                sent,
                sentUsd,
                token: { id, name, symbol, blockchain, decimals, icon },
              }) => (
                <RowDeprecated
                  className={cx('TokenRow')}
                  align="between"
                  key={id}
                >
                  <RowDeprecated className={cx('MiddleRow')}>
                    <BlockchainIcon
                      size="sm"
                      icon={icon}
                      currency={blockchain.toLowerCase() as CoinType}
                      color={isDefaultTokenId(id) ? 'original' : 'grey'}
                    />
                    <ColDeprecated gap={0.25} className={cx('MiddleCol')}>
                      <Typography
                        className={cx('NoOverflowValue')}
                        variant="body1-sm"
                        color="grey4"
                      >
                        {name}
                      </Typography>
                      {balanceType === 'balance' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value: currency === 'native' ? balance : balanceUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                      {balanceType === 'sent' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value: currency === 'native' ? sent : sentUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                      {balanceType === 'received' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value:
                              currency === 'native' ? received : receivedUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                      {balanceType === 'volume' && (
                        <Typography
                          variant="body1-sm"
                          color="black"
                          className={cx('NoOverflowValue')}
                        >
                          {formatMoney({
                            value:
                              currency === 'native'
                                ? received + sent
                                : receivedUsd + sentUsd,
                            decimals: currency === 'native' ? decimals : 0,
                            symbol: '',
                            code: '',
                          })}
                        </Typography>
                      )}
                    </ColDeprecated>
                  </RowDeprecated>
                  <Typography variant="body1-sm" color="grey1">
                    {currency === 'native' ? symbol : t('platform:usd')}
                  </Typography>
                </RowDeprecated>
              )
            )}
          </div>
        </ColDeprecated>
      )}
    </ColDeprecated>
  )
}

export default observer(TokensBalance)
