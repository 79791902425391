import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { BlockchainIcon, Container } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

import FilterDropdown from '../FilterDropdown'
import styles from './index.scss'
import { TokenFilterProps, TokenItem } from './TokenFilter.types'
import { CoinType } from '../../../../../../../types/coin'
import { TypographyNew } from '@clainio/web-platform'
import { useVirtualizer } from '@tanstack/react-virtual'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const cx = classnames.bind(styles)

type TokenOption = TokenFilterProps['tokens'][number]

const TokenFilterItem = memo(
  ({
    tokenOption,
    currency,
  }: {
    tokenOption: TokenOption
    currency: CoinType
  }) => {
    return (
      <>
        <RowDeprecated>
          <BlockchainIcon
            size="sm"
            icon={tokenOption?.token?.icon}
            currency={currency}
            color={isDefaultTokenId(tokenOption?.id) ? 'original' : 'grey'}
          />
          <TypographyNew variant={'body200Normal'} color={'onBackgroundBase'}>
            {tokenOption.token?.name}
          </TypographyNew>
        </RowDeprecated>
        <TypographyNew variant={'body200Normal'} color={'onBackgroundVariant2'}>
          {tokenOption.token?.symbol}
        </TypographyNew>
      </>
    )
  }
)
TokenFilterItem.displayName = 'TokenFilterItem'

const FilterContent = ({
  tokens,
  onChange,
  onChangeSearch,
  value,
  currency,
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = React.useState('')
  const options = tokens
  const parentRef = useRef(null)

  const virtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 32,
    overscan: 20,
  })
  const clearInputValue = () => {
    setInputValue('')
  }

  const onSelectToken = useCallback((token: TokenItem) => {
    onChange(token)
  }, [])

  const onInputValueChange = (value: string) => {
    setInputValue(value)
    onChangeSearch(value)
  }

  const input = useMemo(
    () => (
      <TextField
        variant="outline"
        placeholder={t('platform:findToken')}
        size="md"
        fullWidth
        value={inputValue}
        onChange={onInputValueChange}
        clearable
        onClear={clearInputValue}
        onClick={(event) => event.stopPropagation()}
      />
    ),
    [inputValue, t]
  )

  return (
    <Container>
      <ColDeprecated>
        {input}
        {options?.length > 0 && (
          <ColDeprecated ref={parentRef} gap={0} className={cx('TokenOptions')}>
            <div
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                position: 'relative',
                width: '100%',
              }}
            >
              {virtualizer.getVirtualItems().map((virtualItem) => (
                <div
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={virtualizer.measureElement}
                  className={cx('TokenOption', {
                    active: value === options[virtualItem.index].id,
                  })}
                  onClick={() => onSelectToken(options[virtualItem.index])}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  <TokenFilterItem
                    currency={currency}
                    tokenOption={options[virtualItem.index]}
                  />
                </div>
              ))}
            </div>
          </ColDeprecated>
        )}
      </ColDeprecated>
    </Container>
  )
}

export const TokenFilter: React.FC<PropsWithChildren<TokenFilterProps>> = (
  props
) => {
  return (
    <FilterDropdown
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      content={
        <FilterContent
          value={props.value}
          onChange={props.onChange}
          tokens={props.tokens}
          onChangeSearch={props.onChangeSearch}
          currency={props.currency}
        />
      }
      disabled={props.disabled}
      hideOnClickInside
      placement={props.placement}
    >
      {props.children}
    </FilterDropdown>
  )
}
