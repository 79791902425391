import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'
import { Calendar, CalendarValue } from '@clain/core/ui-kit'

import FilterDropdown from '../FilterDropdown'

import styles from './index.scss'
import {
  convertFromUnixTimestamp,
  convertToUnixTimestamp,
} from '@clain/core/utils/date'

const cx = classnames.bind(styles)

const CALENDAR_MODE = 'default'
const CALENDAR_DISABLE_DAYS_AFTER_TODAY = true
const CALENDAR_FREEZE_FROM = false

type PeriodFilterValue = [] | [number, number]

interface PeriodFilterProps {
  value: PeriodFilterValue
  onChange: (value: PeriodFilterValue) => void
  disabled?: boolean
  allowedRange?: [Date, Date]
}

const PeriodFilter: React.FC<PropsWithChildren<PeriodFilterProps>> = ({
  value: [valueFrom, valueTo],
  onChange,
  children,
  disabled,
  allowedRange,
}) => {
  const calendarValue = React.useMemo<CalendarValue>(() => {
    if (valueFrom && valueTo) {
      return [
        convertFromUnixTimestamp(valueFrom),
        convertFromUnixTimestamp(valueTo),
      ]
    }

    return []
  }, [valueFrom, valueTo])

  const handleCalendarChange = ([from, to]: CalendarValue) => {
    onChange([convertToUnixTimestamp(from), convertToUnixTimestamp(to)])
  }
  const content = (
    <div className={cx('PeriodFilterContent')}>
      <Calendar
        value={calendarValue}
        mode={CALENDAR_MODE}
        disableDaysAfterToday={CALENDAR_DISABLE_DAYS_AFTER_TODAY}
        freezeFrom={CALENDAR_FREEZE_FROM}
        onChange={handleCalendarChange}
        allowedRange={allowedRange}
        resetButton
      />
    </div>
  )

  return (
    <FilterDropdown
      content={content}
      placement={'top-start'}
      disabled={disabled}
      className={cx('PeriodFilter')}
    >
      {children}
    </FilterDropdown>
  )
}

export default PeriodFilter
