import { TabOption, TabsProps } from '@clain/core/ui-kit'
import { useParams, useNavigate } from '@clain/core/Router/router'
import {
  blockchainFilterAddressPath,
  BlockchainFilterPathParams,
  blockchainFilterTxnPath,
  createBlockchainFilterPath,
} from '@platform/constants'

const tabsOptions: TabOption<BlockchainFilterPathParams['entity']>[] = [
  { value: blockchainFilterTxnPath, children: 'Transactions' },
  { value: blockchainFilterAddressPath, children: 'Addresses' },
]

export const useEntitySelectedTabs = (): TabsProps<
  BlockchainFilterPathParams['entity'],
  BlockchainFilterPathParams['entity']
> => {
  const { coin, entity: selectedTab } = useParams<BlockchainFilterPathParams>()
  const onNavigate = useNavigate()

  const handelChangeTab = (entity: BlockchainFilterPathParams['entity']) => {
    onNavigate(createBlockchainFilterPath(coin, entity))
  }

  return {
    options: tabsOptions,
    value: selectedTab,
    onChange: handelChangeTab,
  }
}
