import { useCallback, useState } from 'react'
import { enabledBlockchainsSearch } from '../../../../ProbeSandbox/vm/services/SearchService/SearchService.utils'
import { CoinType } from '../../../../../types/coin'
import {
  AddressSearchResult,
  SearchResults,
} from '../../../../ProbeSandbox/vm/services/SearchService/SearchService.types'

export interface UseFetchAddressesProps<T> {
  currency: CoinType
  getSearchResults: (query: string) => Promise<SearchResults[]>
  normalize: (list: AddressSearchResult) => T
}

export const useFetchAddresses = <T>({
  currency,
  getSearchResults,
  normalize,
}: UseFetchAddressesProps<T>) => {
  const [addresses, setAddresses] = useState<T[]>([])

  const getAddresses = useCallback(
    async (query: string) => {
      if (enabledBlockchainsSearch(query)) {
        const addressResults = await getSearchResults(query)
          .then((searchResults) =>
            searchResults.filter(({ type }) => type === 'address')
          )
          .then((searchResults) =>
            searchResults.flatMap(
              ({ result }) => result as Array<AddressSearchResult>
            )
          )
          .then((searchResults) =>
            searchResults.filter(({ currency: c }) => {
              return c === currency
            })
          )

        setAddresses(addressResults.map(normalize))
        return addressResults as Array<AddressSearchResult>
      }

      return [] as Array<AddressSearchResult>
    },
    [currency, getSearchResults]
  )

  return {
    getAddresses,
    addresses,
  } as const
}
