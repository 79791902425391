import { Container, Graphics } from 'pixi.js'
import LabelGfx from './LabelGfx'
import TextureCache from '../../textureCache'
import formatColor from '../utils/formatColor'
import { Label } from '../../types'

interface MetaInfo {
  backgroundColor?: string
  borderRadius?: number
  padding?: [y: number, x: number]
  items: Label[]
}

interface UpdateMetaInfoGfxProps {
  metaInfo: MetaInfo
  textureCache: TextureCache
}

export class MetaInfoGfx {
  public gfx: Container

  constructor() {
    this.createGfx()
  }

  public updateGfx = async ({
    metaInfo,
    textureCache,
  }: UpdateMetaInfoGfxProps) => {
    this.gfx.removeChildren()

    const background = new Graphics()
    this.gfx.addChild(background)

    let yOffset = 0
    let containerWidth = 0
    let containerHeight = 0
    const labels: Container[] = []
    for (let index = 0; index < metaInfo.items.length; index++) {
      const item = metaInfo.items[index]
      const labelGfx = new LabelGfx()
      await labelGfx.updateGfx({
        labelOptions: item,
        textureCache,
      })
      if (labelGfx.gfx.width > containerWidth) {
        containerWidth = labelGfx.gfx.width
      }
      containerHeight += labelGfx.gfx.height
      if (index > 0) {
        yOffset += labelGfx.gfx.height
      }
      labelGfx.gfx.position.set(0, yOffset)
      labels.push(labelGfx.gfx)
      this.gfx.addChild(labelGfx.gfx)
    }

    const paddingX = metaInfo?.padding?.[1] ?? 0
    const paddingY = metaInfo?.padding?.[0] ?? 0

    background.roundRect(
      0,
      0,
      containerWidth + paddingX * 2,
      containerHeight + paddingY * 2,
      metaInfo?.borderRadius
    )

    background.position.set(
      -((containerWidth + paddingX * 2) / 2),
      -(paddingY + 10)
    )
    labels.forEach((labelContainer) => {
      labelContainer.position.x =
        background.position.x + (labelContainer.width / 2 + paddingX)
    })
    if (metaInfo?.backgroundColor) {
      const [color, alpha] = formatColor(metaInfo?.backgroundColor)
      background.fill({ color, alpha })
    }
  }

  private createGfx = (): void => {
    this.gfx = new Container()
  }
}

export default MetaInfoGfx
