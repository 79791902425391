import React from 'react'
import { FiltersBar } from '../../FiltersBar'
import { useComparators } from '@clain/core/ui-kit'
import { SelectListFilterProps } from './SelectListFilter.types'
import { FiltersBarProps } from '../../FiltersBar/FilterBar.types'

export const SelectListFilter = ({
  isMultiple,
  icon,
  label,
  multipleLabel,
  menuComponent,
  firstSelectedItem,
  size = 'md',
  clearFilter,
}: SelectListFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  const comparators = useComparators()

  if (!firstSelectedItem) {
    return null
  }

  const filterValueLabel = isMultiple ? multipleLabel : firstSelectedItem?.label

  return (
    <FiltersBar
      size={size}
      filterName={{ label, icon }}
      operator={{
        label: isMultiple ? comparators.isAny.text[0] : comparators.is.text[0],
      }}
      filterValue={{
        label: filterValueLabel,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
