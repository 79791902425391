import { RawAddressCounterparty } from '../types/raw/RawAddressCounterparty'
import { AddressCounterparty } from '../types/converted/AddressCounterparty'
import { convertToken } from './convertAddressBalance'
import { CoinType } from '@clain/core/ui-kit'

export const convertAddressCounterparty =
  (currency: CoinType) =>
  (rawCounterparty: RawAddressCounterparty): AddressCounterparty => {
    return {
      type: 'address',
      active: rawCounterparty.active,
      category: rawCounterparty.category,
      address: rawCounterparty.address,
      entity: rawCounterparty.entity,
      net: rawCounterparty.net,
      netUsd: rawCounterparty.net_usd,
      totalIn: rawCounterparty.total_in,
      totalInUsd: rawCounterparty.total_in_usd,
      totalOut: rawCounterparty.total_out,
      totalOutUsd: rawCounterparty.total_out_usd,
      tokenIds: rawCounterparty.token_ids,
      tokens: rawCounterparty.tokens.map(convertToken(currency)),
      clusterId: rawCounterparty.cluster_id,
    }
  }
