import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from '@clain/core/utils/i18next'

import { MagicGrid, Select, Stack, TypographyNew } from '@clain/core/ui-kit'

import SettingsHeader from '../SettingsHeader'

import styles from './index.scss'
import { SeveritiesModel } from './Severities'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { LANGS_OPTIONS, LANG_STORAGE_KEY } from '@platform/constants/languege'
import { useLocalStorage } from '@clain/core/hooks'
import { LanguageTypes } from '@platform/types'
import { Timezone } from './Timezone'
import { i18next } from '@platform/i18Manager'
import { observer } from 'mobx-react-lite'
import { useSettings } from '@platform/hooks'

const cx = classnames.bind(styles)

const SelectLanguage = observer(() => {
  const [language, setLanguage] = useLocalStorage<LanguageTypes>(
    LANG_STORAGE_KEY,
    i18next.language
  )
  const { userSettings, updateUserSettings } = useSettings()

  const handleChange = (language: LanguageTypes) => {
    setLanguage(language)
    updateUserSettings({ language })
    i18next.changeLanguage(language)
  }

  const selectedLang = LANGS_OPTIONS.map((option) => ({
    ...option,
    selected: option.value === language,
  }))

  return (
    <Select
      variant="outline"
      value={userSettings?.language ?? language}
      options={selectedLang}
      onChange={handleChange}
    />
  )
})

const GlobalSettings: React.FC = () => {
  const { t } = useTranslation()
  HeaderSlot.useContent(() => <SettingsHeader />)

  return (
    <div className={cx('Form')}>
      <MagicGrid gap={2.5}>
        <TypographyNew variant="heading100Normal" color="onBackgroundBase">
          {t('platform:generalSettings')}
        </TypographyNew>
        <Timezone />
        <Stack direction="column" gap={'md'}>
          <TypographyNew
            variant="heading300Accent"
            color="onBackgroundVariant1"
          >
            {t('platform:language')}
          </TypographyNew>
          <SelectLanguage />
        </Stack>
        <Stack direction="column" gap="md">
          <TypographyNew
            variant="heading300Accent"
            color="onBackgroundVariant1"
          >
            {t('platform:severityTitle')}
          </TypographyNew>
          <TypographyNew variant="body200Accent" color="onBackgroundVariant1">
            {t('platform:severityDescription')}
          </TypographyNew>
        </Stack>
        <Stack direction="column" gap="md">
          <SeveritiesModel />
        </Stack>
      </MagicGrid>
    </div>
  )
}

export default GlobalSettings
