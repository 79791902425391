import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FiltersButtons,
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useFilterSlice,
} from './TransactionEvmFilters.service'
import { AssetFilterContainer, AssetFilterMenuContainer } from './AssetFilter'
import { ScoreFilterContainer, ScoreFilterMenuContainer } from './ScoreFilter'
import {
  CalendarFilterContainer,
  CalendarFilterMenuContainer,
} from './CalendarFilter'
import {
  TransferTypeContainer,
  TransferTypeMenuContainer,
} from './TransferTypeFilter'
import //UsdAssetValueFilterContainer,
//UsdAssetValueFilterMenuContainer,
'./UsdAssetValueFilter'
import {
  AssetValueFilterContainer,
  AssetValueFilterMenuContainer,
} from './AssetValueFilter'
import { useTransactionStaticStore, useTransactionViewModel } from '../context'
import {
  EXLUDE_SORT_FILTERS,
  //FILTER_SCAM_SPAM_KEY,
  TABLE_FILTER_MENU_MAX_HEIGHT,
} from '../../../constants'
import { factoryTransactionEvmFilters } from './TransactionEvmFIlters.utils'
import {
  ClusterFilterContainer,
  ClusterFilterMenuContainer,
} from './ClusterFilter'
import { excludeSortFilters } from '../../../utils/excludeSortFilters'
import {
  AddressFilterMenuContainer,
  AddressFilterContainer,
} from './AddressFilter'
//import { ScamSpamFilterContainer } from './ScamSpamFilter'
//mport { FILTER_SCAM_SPAM } from '../constants/transactionFilters'

const TransactionFilterMenu: React.FC = () => {
  const { t } = useTranslation()
  const [asset] = useFilterSlice('asset.data')
  const { counterpartyType } = useTransactionViewModel()
  //const [, { setFilter: setScamSpamFilter }] = useFilterSlice(
  //  FILTER_SCAM_SPAM_KEY.data
  //)

  const itemsAssetGroup: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[] =
    [
      {
        id: 'asset',
        icon: 'Asset',
        label: t('platform:assets'),
        isDisabled: false,
        component: AssetFilterMenuContainer,
      },
      {
        id: 'assetValue',
        icon: 'AssetValue',
        label: t('platform:assetValue'),
        isDisabled: !asset,
        component: AssetValueFilterMenuContainer,
        info: !asset ? t('platform:selectSingleAsset') : '',
      },
      /*
  {
    id: 'usdValue',
    icon: 'UsdValue',
    label: 'USD value',
    isDisabled: false,
    component: UsdAssetValueFilterMenuContainer,
  },*/
    ]

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [
      [
        {
          id: 'transferType',
          icon: 'Transaction',
          label: t('platform:transferType'),
          isDisabled: false,
          component: TransferTypeMenuContainer,
        },
        counterpartyType === 'cluster'
          ? {
              id: 'cluster',
              icon: 'ClusterAlt',
              label: t('platform:counterpartyCluster'),
              isDisabled: false,
              component: ClusterFilterMenuContainer,
            }
          : {
              id: 'address',
              icon: 'Pin',
              label: t('platform:counterpartyAddress'),
              isDisabled: false,
              component: AddressFilterMenuContainer,
            },
        {
          id: 'score',
          icon: 'Score',
          label: t('platform:counterpartyScore'),
          isDisabled: false,
          component: ScoreFilterMenuContainer,
        },
      ],
      itemsAssetGroup,
      [
        {
          id: 'calendar',
          icon: 'Calendar',
          label: t('platform:time'),
          isDisabled: false,
          component: CalendarFilterMenuContainer,
        },
      ],
      /*[
        {
          id: FILTER_SCAM_SPAM_KEY.root,
          icon: FILTER_SCAM_SPAM.icon,
          label: FILTER_SCAM_SPAM.label,
          isDisabled: false,
          onItemClick: () => {
            setScamSpamFilter(FILTER_SCAM_SPAM)
          },
        },
      ],*/
    ]

  return <FiltersMenu.Wrapper items={items} />
}

const Filters = () => {
  const _filterList = useFilterNameList()
  const { excludeFilters, counterpartyType } = useTransactionViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])
  const transactionStaticStore = useTransactionStaticStore()
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return <AssetFilterContainer key={filter} />
      case 'score':
        return <ScoreFilterContainer key={filter} />
      case 'calendar':
        return <CalendarFilterContainer key={filter} />
      case 'transferType':
        return <TransferTypeContainer key={filter} />
      case 'assetValue':
        return <AssetValueFilterContainer key={filter} />
      case 'cluster':
        return counterpartyType === 'cluster' ? (
          <ClusterFilterContainer key={filter} />
        ) : (
          <AddressFilterContainer key={filter} />
        )

      //case FILTER_SCAM_SPAM_KEY.root:
      //return <ScamSpamFilterContainer key={filter} />
      //case 'usdAssetValue':
      //return <UsdAssetValueFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length
    ? [
        ...filterList.map(createFilter),
        <FiltersButtons.IconAdd
          key="filter-icon-add"
          maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
          size={transactionStaticStore.current.filterSize}
          popupContent={<TransactionFilterMenu />}
        />,
      ]
    : []

  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const TransactionEvmFiltersComponent = factoryTransactionEvmFilters({
  filterMenu: <TransactionFilterMenu />,
  filters: <Filters />,
})

export const TransactionEvmFilters = memo(
  observer(TransactionEvmFiltersComponent)
)
