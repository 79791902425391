import { action, computed, makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'
import {
  ServerAddEvent,
  ServerAddEvents,
  ServerAddNodeEvent,
} from '../../types/serverData'

export type IAddedEntities = {
  get entities(): ServerAddEvents
  get nodeEntities(): Array<ServerAddNodeEvent>
  get emitEntitiesKeys(): Array<string>
  has(key: string): boolean
  addEmitEntitiesKey(key: string): void
  get(get: string): ServerAddEvent
  set(key: string, data: ServerAddEvent): void
  clear(): void
}

@injectable()
export class AddedEntities implements IAddedEntities {
  @observable private _entities = new Map<string, ServerAddEvent>()
  @observable private _emitEntitiesKeys = new Set<string>()

  constructor() {
    makeObservable(this)
  }

  @computed
  public get entities(): Array<ServerAddEvent> {
    return Array.from(this._entities.values())
  }

  @computed
  public get emitEntitiesKeys(): Array<string> {
    return Array.from(this._emitEntitiesKeys.values())
  }

  @computed
  public get nodeEntities(): Array<ServerAddNodeEvent> {
    return this.entities.filter(
      (entity) => entity.type === 'add_node'
    ) as Array<ServerAddNodeEvent>
  }

  @action
  public set = (key: string, data: ServerAddEvent) => {
    this._entities.set(key, data)
  }

  @action
  public has = (key: string): boolean => {
    return this._entities.has(key)
  }

  @action
  public addEmitEntitiesKey = (key: string) => {
    this._emitEntitiesKeys.add(key)
  }

  @action
  public get = (key: string) => {
    return this._entities.get(key)
  }

  @action
  public clear = () => {
    this._entities.clear()
    this._emitEntitiesKeys.clear()
  }
}
