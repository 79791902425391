import React, { PropsWithChildren } from 'react'
import {
  RowDeprecated,
  FiltersButtons,
  FiltersMenu,
  Stack,
  Tooltip,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useSetFilters } from './AddressesFiltersContainer'
import {
  useFiltersActions,
  ADDRESSES_FILTERS_KEYS,
  useFiltersOptions,
} from './AddressesFilters.service'
import { AddressesFiltersMenu } from './AddressesFiltersMenu'
import { FilterLayout } from '@platform/components/BlockchainFilter/ui'
import { VerticalDivider } from '@platform/components/BlockchainFilter/ui/FilterLayout/FilterLayout.styles'
import {
  CalendarFilter,
  CurrencyFilter,
  FiltersBar,
} from '@platform/components/BlockchainFilter/containers'
import { CreateFilter } from './createFilter.utils'
import { FILTER_CALENDAR_KEY } from '@platform/components/BlockchainFilter/constants'

export const TABLE_FILTER_MENU_MAX_HEIGHT = 473

const ButtonClearFilter = () => {
  const { t } = useTranslation()
  const { resetFilters } = useFiltersActions()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <Tooltip content={!isFiltersChanged ? t('platform:setFiltersFirst') : ''}>
      <span>
        <FiltersButtons.Clear
          disabled={!isFiltersChanged}
          label={t('platform:clearFilters')}
          size={'md'}
          onClear={resetFilters}
        />
      </span>
    </Tooltip>
  )
}

const TopSection = () => (
  <Stack justify={'space-between'}>
    <RowDeprecated>
      <CurrencyFilter />
      <VerticalDivider />
      <CalendarFilter filterKey={FILTER_CALENDAR_KEY} />
    </RowDeprecated>
    <RowDeprecated>
      <ButtonClearFilter />
    </RowDeprecated>
  </Stack>
)

const FilterMenuButtons = () => {
  const { t } = useTranslation()

  return (
    <RowDeprecated>
      <FiltersButtons.Add
        label={t('platform:filters')}
        maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
        size={'md'}
        variant={'text'}
        popupContent={<AddressesFiltersMenu />}
      />
    </RowDeprecated>
  )
}

const FilterBars = () => (
  <FiltersBar
    keys={ADDRESSES_FILTERS_KEYS}
    menu={<AddressesFiltersMenu />}
    createFilter={CreateFilter}
  />
)

const AddressesFilterComponent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useSetFilters()
  return <>{children}</>
}

const AddressesFiltersComponent = () => {
  return (
    <AddressesFilterComponent>
      <FiltersMenu.Provider initValue={{ size: 'md' }}>
        <FilterLayout
          topSection={<TopSection />}
          middleSection={<FilterMenuButtons />}
          bottomSection={<FilterBars />}
        />
      </FiltersMenu.Provider>
    </AddressesFilterComponent>
  )
}

export const AddressesFilters = observer(AddressesFiltersComponent)
