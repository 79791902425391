import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { KeyValue } from '@clain/core/ui-kit'
import { enterprise, Score } from '@clain/core/ui-kit'
import { Text } from '@clain/core/ui-kit'
import styles from './dashboard.scss'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { useFormatMoneySettings } from '../../hooks/useFormatMoneySettings'
import { ThemeProvider } from 'styled-components'

const cx = classnames.bind(styles)

export const DashboardTooltip = ({ currency, cluster, coin, formatDate }) => {
  const { t } = useTranslation()
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  if (!cluster) {
    return null
  }

  return (
    <ThemeProvider theme={enterprise['light']}>
      <div className={cx('DashboardTooltip')}>
        <Text bold>{cluster.identity.name}</Text>
        <Score value={cluster.score} className={cx('score')} />

        <KeyValue
          dense
          layout="vertical"
          data={cluster}
          className={cx('details')}
        >
          {[
            {
              key: t('platform:category'),
              value: ({ identity }) => identity.category,
            },
            {
              key: t('platform:balance'),
              value: ({ balance, balance_usd }) =>
                formatMoney({
                  value: currency === 'usd' ? balance_usd : balance,
                  currency,
                }),
            },
            {
              key: t('platform:addresses'),
              value: ({ size }) => formatNumber(size, 0),
            },
            (coin === 'eth' || coin === 'trx') &&
              cluster.transaction_count && {
                key: t('platform:transactions'),
                value: ({ transaction_count }) =>
                  formatNumber(transaction_count, 0),
              },
            {
              key: t('platform:lastSeen'),
              value: ({ last_seen }) =>
                formatDate(fromUnixTime(last_seen), 'date'),
            },
          ].filter(Boolean)}
        </KeyValue>
      </div>
    </ThemeProvider>
  )
}
