import curry from 'ramda/src/curry'
import { AppGraph, IArea } from '../types'
import PixiNode from '../node'
import { AreaGfx } from '../area'

export class AnimationAreaModule {
  private nodeKeyToNodeInstance: Map<string, PixiNode>
  private nodeKeyToAreaGfx: Map<string, AreaGfx>
  private graph: AppGraph

  constructor(params: {
    nodeKeyToNodeInstance: Map<string, PixiNode>
    nodeKeyToAreaGfx: Map<string, any>
    graph: AppGraph
  }) {
    this.nodeKeyToNodeInstance = params.nodeKeyToNodeInstance
    this.nodeKeyToAreaGfx = params.nodeKeyToAreaGfx
    this.graph = params.graph
  }

  private getNodeProps = (nodeKey: string) => {
    return this.graph.getNodeAttributes(nodeKey)
  }

  private nodeArea = (nodeKey: string) => {
    return this.nodeKeyToAreaGfx.get(nodeKey)
  }

  private updateArea = (nodeKey: string, options: IArea) => {
    if (
      this.nodeKeyToNodeInstance.has(nodeKey) &&
      this.nodeKeyToAreaGfx.has(nodeKey)
    ) {
      this.nodeArea(nodeKey).updateGfx(options)
      this.graph.getNodeAttributes(nodeKey).area = options
    }
  }

  public animationImmediately = (nodeKey: string) => {
    const node = this.nodeKeyToNodeInstance.get(nodeKey)
    const area = this.nodeKeyToAreaGfx.get(node.id)

    if (area && node) {
      this.getNodeProps(nodeKey)?.area?.animationImmediately?.(area.gfx, {
        updateArea: curry(this.updateArea)(nodeKey),
      })
    }
  }
}
