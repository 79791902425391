import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Score } from '@clain/core/ui-kit'

import InfobarTable from '../InfobarTable'
import { Token } from '../../../types/converted/TokenBalance'

import styles from './index.scss'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { useFormatNumberSettings } from '../../../../../hooks/useFormatNumberSettings'
import { isEmpty } from 'ramda'
import { CoinType } from '../../../../../types/coin'
import { ClusterNodeData } from '../../../types/nodeEntitiesData/ClusterNodeData'
import { AddressNodeDataUtxo } from '../../../types/nodeEntitiesData/AddressNodeDataBtc'
import { DEFAULT_BNB_TOKEN } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const cx = classnames.bind(styles)

interface BaseProps {
  transactionsCount?: number
  totalAmountUsd?: number
  totalAmount?: number
  token?: Token
  isUsd: boolean
}

type FlowInfobarProps = BaseProps & {
  fromData: ClusterNodeData | AddressNodeDataUtxo
  toData: ClusterNodeData | AddressNodeDataUtxo
  currency: CoinType
}

const FlowInfobar: React.FC<FlowInfobarProps> = (props) => {
  const { t } = useTranslation()
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const { totalAmountUsd, totalAmount = 0, isUsd, token } = props

  const total = (() => {
    if (isUsd || token?.id === 0 || !token) {
      if (totalAmountUsd == null) return

      return formatMoney({
        value: totalAmountUsd,
        currency: 'usd',
      })
    }

    if (token?.id === 1) {
      return formatMoney({
        value: totalAmount,
        currency: 'btc',
        precision: 8,
      })
    }

    if (token?.id === 2) {
      return formatMoney({
        value: totalAmount,
        currency: 'eth',
        precision: 12,
      })
    }

    if (token?.id === DEFAULT_BNB_TOKEN.token.id) {
      return formatMoney({
        value: totalAmount,
        currency: props.currency,
        precision: 12,
      })
    }

    if (token?.id === 93) {
      return formatMoney({
        value: totalAmount,
        currency: 'doge',
        precision: 8,
      })
    }

    if (token?.id === 92) {
      return formatMoney({
        value: totalAmount,
        currency: 'ltc',
        precision: 8,
      })
    }

    if (token?.id === 4232) {
      return formatMoney({
        value: totalAmount,
        currency: 'trx',
        precision: 8,
      })
    }

    if (isEmpty(token)) {
      return
    }

    return formatMoney({
      value: totalAmount,
      decimals: token.decimals,
      code: token.symbol,
    })
  })()

  return (
    <Container className={cx('FlowInfobar')} gap={[1.5, 1]}>
      <ColDeprecated gap={2}>
        <Typography variant="subheading2" color="grey1">
          {t('platform:fundsFlow')}
        </Typography>
        <ColDeprecated gap={0.25}>
          {props.fromData &&
            (props.fromData.nodeType === 'cluster' ? (
              <ClusterInfoPanel
                dir="from"
                name={
                  props.fromData.entity?.name ||
                  String(props.fromData.clusterId)
                }
                category={props.fromData.entity?.category}
                score={props.fromData.score}
              />
            ) : (
              <AddressInfoPanel
                dir="from"
                hash={props.fromData.address}
                score={props.fromData.score}
                currency={props.currency}
              />
            ))}
          {props.toData &&
            (props.toData.nodeType === 'cluster' ? (
              <ClusterInfoPanel
                dir="to"
                name={
                  props.toData.entity?.name || String(props.toData.clusterId)
                }
                category={props.toData.entity?.category}
                score={props.toData.score}
              />
            ) : (
              <AddressInfoPanel
                dir="to"
                hash={props.toData.address}
                score={props.toData.score}
                currency={props.currency}
              />
            ))}
        </ColDeprecated>
        <InfobarTable
          data={[
            [
              {
                name: t('platform:transactions') + ':',
                value:
                  props.transactionsCount !== undefined &&
                  formatNumber(props.transactionsCount, 0),
              },
              {
                name: t('platform:total') + ':',
                value: total,
              },
            ],
          ]}
        />
      </ColDeprecated>
    </Container>
  )
}

interface ClusterInfoPanelProps {
  dir: 'from' | 'to'
  name: string
  score: number
  category: string
}

const ClusterInfoPanel: React.FC<ClusterInfoPanelProps> = ({
  dir,
  name,
  score,
  category: _category,
}) => {
  const { t } = useTranslation()
  const category = _category || t('platform:unidentified')

  return (
    <Container gap={[1.5, 1]} className={cx('FromToContainer')}>
      <RowDeprecated gap={0.5} className={cx('FromToRow')}>
        <Typography
          className={cx('FromToLabel')}
          variant="caption1"
          color="grey4"
        >
          {t(dir === 'from' ? 'platform:fromCluster' : 'platform:toCluster')}
        </Typography>
        <Typography variant="body2" color="black" className={cx('ClusterName')}>
          {name}
        </Typography>
        <Score value={score} useNewColors={true} size="sm" />
        <Typography
          className={cx('CategoryTag')}
          variant="caption1"
          color="white"
          transform="uppercase"
        >
          {category}
        </Typography>
      </RowDeprecated>
    </Container>
  )
}

interface AddressInfoPanelProps {
  dir: 'from' | 'to'
  hash: string
  score: number
  currency: CoinType
}

const AddressInfoPanel: React.FC<AddressInfoPanelProps> = ({
  dir,
  hash,
  score,
  currency,
}) => {
  const { t } = useTranslation()

  return (
    <Container gap={[1.5, 1]} className={cx('FromToContainer')}>
      <RowDeprecated gap={0.5} className={cx('FromToRow')}>
        <Typography
          className={cx('FromToLabel')}
          variant="caption1"
          color="grey4"
        >
          {t(dir === 'from' ? 'platform:fromAddress' : 'platform:toAddress')}
        </Typography>
        <Typography className={cx('Hash')} variant="code1" color="black">
          <Link
            to="/:coin/address/:hash"
            params={{ hash, coin: currency }}
            className={cx('HashLink')}
            target="_blank"
          >
            {hash}
          </Link>
        </Typography>
        <Score value={score} useNewColors={true} size="sm" />
      </RowDeprecated>
    </Container>
  )
}

export default FlowInfobar
