import { isEVM, isUTXO } from '@clain/core/types/coin'
import {
  TransactionAddressEvmTableItem,
  TransactionAddressEvmTableProps,
  DefaultEvmTableConfig,
  getEvmTableConfig,
} from '../TransactionAddressEvmTable'
import { useCommonTable } from '.'
import {
  DefaultUtxoTableConfig,
  getUtxoTableConfig,
  TransactionAddressUtxoTableProps,
  TransactionAddressSelectedUtxoTableItem,
} from '../TransactionAddressUtxoTable'

export const useTransactionAddressTableConfig = <
  V extends
    | TransactionAddressEvmTableItem
    | TransactionAddressSelectedUtxoTableItem,
  T extends
    | TransactionAddressEvmTableProps<
        V extends TransactionAddressEvmTableItem
          ? TransactionAddressEvmTableItem
          : TransactionAddressEvmTableItem
      >
    | TransactionAddressUtxoTableProps<
        V extends TransactionAddressSelectedUtxoTableItem
          ? TransactionAddressSelectedUtxoTableItem
          : TransactionAddressSelectedUtxoTableItem
      >,
>(
  props: Omit<T, 'data' | 'itemsPerPage'>
) => {
  let config = {} as ReturnType<
    | typeof getEvmTableConfig
    | typeof getUtxoTableConfig<TransactionAddressSelectedUtxoTableItem>
  >
  const commonTableParams = useCommonTable(props)

  const params = {
    ...commonTableParams,
    ...props,
  }

  if (isUTXO(props.currency)) {
    config = getUtxoTableConfig<TransactionAddressSelectedUtxoTableItem>(
      params as unknown as DefaultUtxoTableConfig<TransactionAddressSelectedUtxoTableItem>
    )
  }
  if (isEVM(props.currency)) {
    config = getEvmTableConfig(
      params as DefaultEvmTableConfig<TransactionAddressEvmTableItem>
    )
  }

  return { config, sortingProps: commonTableParams.sortingProps }
}
