import { CLUSTER_ADDRESS_TYPE } from '../../ProbeSandbox/types/converted/ClusterAddress'

export const TABLE_FILTER_MENU_MAX_HEIGHT = 473

export const FILTER_VERSION_OPTIONS = [
  {
    label: 'V1',
    value: 1,
  },
  {
    label: 'V2',
    value: 2,
  },
]

export const ADDRESS_TYPE = CLUSTER_ADDRESS_TYPE.filter(
  (type) => !['ND', 'PKH_MULT', 'NS', 'WUNK'].includes(type)
).map((type) => ({
  value: type,
  label: `P2${type}`,
}))

export const NOT_LITECOIN_ADDRESS_TYPE = CLUSTER_ADDRESS_TYPE.filter(
  (type) =>
    !['ND', 'MW_HOGADDR', 'MW_PEG_IN', 'PKH_MULT', 'NS', 'WUNK'].includes(type)
).map((type) => ({
  value: type,
  label: `P2${type}`,
}))

export const createFilterKeys = <T extends string>(root: T) => {
  return {
    root,
    data: `${root}.data`,
    condition: `${root}.condition`,
  } as const
}

export const FILTER_ASSET_KEY = createFilterKeys('asset')
export const FILTER_ASSET_VALUE_TYPE_KEY = createFilterKeys('assetValue')
export const FILTER_CALENDAR_KEY = createFilterKeys('calendar')
export const FILTER_SCORE_KEY = createFilterKeys('score')
export const FILTER_TRANSFER_TYPE_KEY = createFilterKeys('transferType')
export const FILTER_FEE_KEY = createFilterKeys('fee')
export const FILTER_FEE_BYTE_KEY = createFilterKeys('feeByte')
export const FILTER_VERSION_KEY = createFilterKeys('version')
export const FILTER_RBF_KEY = createFilterKeys('rbf')
export const FILTER_SEGWIT_KEY = createFilterKeys('segwit')
export const FILTER_LOCKTIME_KEY = createFilterKeys('locktime')
export const FILTER_CLUSTER_KEY = createFilterKeys('cluster')
export const FILTER_ADDRESS_KEY = createFilterKeys('address')
export const FILTER_ADDRESS_TYPE_KEY = createFilterKeys('addressType')
export const FILTER_CATEGORY_KEY = createFilterKeys('category')
export const FILTER_SORT_BY_KEY = createFilterKeys('sortBy')
export const FILTER_SORT_ORDER_KEY = createFilterKeys('sortOrder')
export const FILTER_SCAM_SPAM_KEY = createFilterKeys('spam')

export const EXLUDE_SORT_FILTERS: Array<'sortBy' | 'sortOrder'> = [
  'sortBy',
  'sortOrder',
]
