import { ProbeViewModel } from '../../ProbeViewModel'
import { action, makeObservable } from 'mobx'
import {
  WorldClickStrategyInterface,
  WorldClickStrategyType,
} from '../GraphEntityEvent.types'
import PixiEvent from '@clain/graph/src/core/PixiEvent'
import { EntititiesGhosted } from './EntititiesGhosted'

class WorldClickStrategy implements WorldClickStrategyInterface {
  constructor(private action: WorldClickStrategyInterface['handle']) {}

  handle(
    ...payload: Parameters<WorldClickStrategyInterface['handle']>
  ): ReturnType<WorldClickStrategyInterface['handle']> {
    this.action(...payload)
  }
}

export class WorldEventsController {
  private strategies: Record<WorldClickStrategyType, WorldClickStrategy>
  private entitiesGhosted: EntititiesGhosted

  constructor(private probeVM: ProbeViewModel) {
    makeObservable(this)
    this.entitiesGhosted = new EntititiesGhosted(this.probeVM)
    this.strategies = {
      rightClick: new WorldClickStrategy(this.openWorldMenu),
      leftClick: new WorldClickStrategy(this.unselectedEntities),
    }
  }

  private openWorldMenu = (event: PixiEvent<unknown>) => {
    if (!event) {
      return
    }

    const { domEvent } = event

    const toWordCoordinates = this.probeVM.app.toWorldCoordinates({
      x: domEvent.offsetX,
      y: domEvent.offsetY,
    })

    this.probeVM.circularMenuController.open(toWordCoordinates, 'world')
  }

  private unselectedEntities = (event: PixiEvent<unknown>) => {
    if (!event?.worldTarget) {
      return
    }

    this.entitiesGhosted.removeGhostedEntities()

    if (this.probeVM.probeState.selectedEdgeIds.size) {
      this.probeVM.probeState.selectedEdgeIds.clear()
    }

    if (this.probeVM.probeState.selectedNodeIds.size) {
      this.probeVM.probeState.selectedNodeIds.clear()
    }
  }

  @action
  public onClick = (event: PixiEvent<unknown>) => {
    if (this.probeVM.commentsController.isPositioningInProgress) return

    const { domEvent } = event

    const isRightClick = domEvent.button === 2
    const isSimpleLeftClick =
      domEvent.button === 0 &&
      !domEvent.shiftKey &&
      !domEvent.ctrlKey &&
      !domEvent.metaKey &&
      !domEvent.altKey

    const strategyKeys = []

    if (isSimpleLeftClick) {
      strategyKeys.push('leftClick')
    }

    if (isRightClick) {
      strategyKeys.push('rightClick')
    }

    strategyKeys.forEach((strategyKey) => {
      this.strategies[strategyKey].handle(event)
    })
  }
}
