import { CoinType } from '@clain/core/ui-kit'
import { convertPaginated } from './convertPaginated'
import {
  RawAddressCounterparties,
  RawAddressCounterpartiesCumulative,
} from '../types/raw/RawAddressCounterparties'
import {
  AddressCounterpartiesCumulative,
  AddressCounterparties,
} from '../types/converted/AddressCounterparties'
import { convertAddressCounterparty } from './convertAddressCounterparty'

const convertAddressCounterpartiesCumulative = (
  rawAddressCounterpartiesCumulative: RawAddressCounterpartiesCumulative
): AddressCounterpartiesCumulative => {
  return {
    netUsd: rawAddressCounterpartiesCumulative.net_usd,
    net: rawAddressCounterpartiesCumulative.net,
    totalIn: rawAddressCounterpartiesCumulative.total_in,
    totalOut: rawAddressCounterpartiesCumulative.total_out,
    totalInUsd: rawAddressCounterpartiesCumulative.total_in_usd,
    totalOutUsd: rawAddressCounterpartiesCumulative.total_out_usd,
  }
}

export const convertAddressCounterparties = (
  rawAddressCounterparties: RawAddressCounterparties,
  currency: CoinType
): AddressCounterparties => {
  return {
    categories: rawAddressCounterparties.categories,
    cumulative: convertAddressCounterpartiesCumulative(
      rawAddressCounterparties.cumulative
    ),
    data: convertPaginated(
      {
        counterparties: rawAddressCounterparties.data.map(
          convertAddressCounterparty(currency)
        ),
        page_number: rawAddressCounterparties.pagination.page_number,
        page_size: rawAddressCounterparties.pagination.page_size,
        total_entries: rawAddressCounterparties.pagination.total_entries,
        total_pages: rawAddressCounterparties.pagination.total_pages,
      },
      'counterparties'
    ),
  }
}
