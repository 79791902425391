import React, { PropsWithChildren } from 'react'
import {
  ColDeprecated,
  RowDeprecated,
  FiltersButtons,
  FiltersMenu,
  Stack,
  Tooltip,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useSetFilters } from './TrxFiltersContainer'

import {
  useFiltersActions,
  INPUT_FILTERS_KEYS,
  OUTPUT_FILTERS_KEYS,
  TRANSACTION_FILTERS_KEYS,
  FILTER_BAR_KEYS,
  useFiltersOptions,
  useFilterNameList,
} from './TrxFilters.service'

import {
  InputFilterMenu,
  OutputFilterMenu,
  TransactionFilterMenu,
} from './FiltersMenu'
import { CalendarFilterContainer } from './CalendarFilter'
import { FiltersBar } from '@platform/components/BlockchainFilter/containers/FiltersBar'
import { CreateFilter } from './createFilter.utils'
import { VerticalDivider } from '@platform/components/BlockchainFilter/ui/FilterLayout/FilterLayout.styles'
import { FilterLayout } from '@platform/components/BlockchainFilter/ui'
import {
  CurrencyFilter,
  PresetsFiltersPanelContainer,
} from '@platform/components/BlockchainFilter/containers'

export const TABLE_FILTER_MENU_MAX_HEIGHT = 473

const ButtonClearFilter = () => {
  const { t } = useTranslation()
  const { resetFilters } = useFiltersActions()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')

  return (
    <Tooltip content={!isFiltersChanged ? t('platform:setFiltersFirst') : ''}>
      <span>
        <FiltersButtons.Clear
          disabled={!isFiltersChanged}
          label={t('platform:clearFilters')}
          size={'md'}
          onClear={resetFilters}
        />
      </span>
    </Tooltip>
  )
}

const TopSection = () => (
  <Stack justify={'space-between'}>
    <RowDeprecated>
      <CurrencyFilter />
      <VerticalDivider />
      <CalendarFilterContainer />
    </RowDeprecated>
    <RowDeprecated>
      <ButtonClearFilter />
    </RowDeprecated>
  </Stack>
)

const FilterMenuButtons = () => {
  const { t } = useTranslation()

  return (
    <RowDeprecated>
      <FiltersButtons.Add
        label={t('platform:transaction')}
        maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
        size={'md'}
        variant={'text'}
        popupContent={<TransactionFilterMenu />}
      />
      <FiltersButtons.Add
        label={t('platform:input')}
        maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
        size={'md'}
        variant={'text'}
        popupContent={<InputFilterMenu />}
      />
      <FiltersButtons.Add
        label={t('platform:output')}
        maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
        size={'md'}
        variant={'text'}
        popupContent={<OutputFilterMenu />}
      />
    </RowDeprecated>
  )
}

const MiddleSection = () => {
  return (
    <Stack justify={'space-between'}>
      <FilterMenuButtons />
      <PresetsFiltersPanelContainer />
    </Stack>
  )
}

const FilterBars = () => {
  const { t } = useTranslation()

  return (
    <ColDeprecated gap={1.75}>
      <FiltersBar
        title={t('platform:transaction')}
        keys={TRANSACTION_FILTERS_KEYS}
        menu={<TransactionFilterMenu />}
        createFilter={CreateFilter}
      />
      <FiltersBar
        title={t('platform:input')}
        keys={INPUT_FILTERS_KEYS}
        menu={<InputFilterMenu />}
        createFilter={CreateFilter}
      />
      <FiltersBar
        title={t('platform:output')}
        keys={OUTPUT_FILTERS_KEYS}
        menu={<OutputFilterMenu />}
        createFilter={CreateFilter}
      />
    </ColDeprecated>
  )
}

const TrxFiltersComponentInner: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useSetFilters()
  return <>{children}</>
}

const TrxFiltersComponent = () => {
  const filters = useFilterNameList()
  const hideBottomSection = React.useMemo(
    () => !filters.filter((filter) => FILTER_BAR_KEYS.includes(filter))?.length,
    [filters]
  )

  return (
    <TrxFiltersComponentInner>
      <FiltersMenu.Provider initValue={{ size: 'md' }}>
        <FilterLayout
          topSection={<TopSection />}
          middleSection={<MiddleSection />}
          bottomSection={<FilterBars />}
          hideBottomSection={hideBottomSection}
        />
      </FiltersMenu.Provider>
    </TrxFiltersComponentInner>
  )
}

export const TrxFilters = observer(TrxFiltersComponent)
