import React, { ReactNode } from 'react'
import {
  CounterpartyTableItem,
  CounterpartyTableProps,
} from './CounterpartyTableConfig'
import { DataGrid, useTableStubs } from '@clain/core/ui-kit'
import { useDefaultCounterpartyTableConfig } from './hooks/useDefaultCounterpartyTableConfig'
import classnames from 'classnames/bind'
import styles from './CounterpartyTable.scss'
import { EntityTableNotFound } from '../../ui'
import * as S from '../../ui'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

type Props = Pick<
  CounterpartyTableProps<CounterpartyTableItem>,
  'data' | 'itemsPerPage' | 'isLoading' | 'currentPage'
> & {
  config: ReturnType<typeof useDefaultCounterpartyTableConfig>
  filtersContent?: ReactNode
}

export const CounterpartyTable = withErrorBoundary(
  ({
    data,
    isLoading,
    itemsPerPage = 10,
    config,
    filtersContent,
    currentPage,
  }: Props) => {
    const { stubbedColumns, stubbedData } = useTableStubs(
      Object.values(config),
      data?.map((item, index) => ({
        ...item,
        key: index,
      })),
      itemsPerPage
    )

    const loading = Boolean(data && isLoading)

    return (
      <div className={cx('CounterpartyTableWrapper')}>
        <S.Container>
          {filtersContent ? (
            <S.FilterContainer>{filtersContent}</S.FilterContainer>
          ) : null}
          <S.TableContainer>
            <DataGrid
              type="simple"
              columns={stubbedColumns}
              data={stubbedData}
              size={'md'}
              variant={['base', 'high', 'high']}
              borders={[
                { bottom: { color: 'secondaryContainerDeep' } },
                { bottom: { color: 'secondaryContainerDeep' } },
              ]}
              spaces={['none', 'xl']}
              loading={loading}
              notFoundComponent={<EntityTableNotFound />}
              emitScrollToTop={currentPage}
            />
          </S.TableContainer>
        </S.Container>
      </div>
    )
  }
)
