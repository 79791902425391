import styled, { css } from 'styled-components'
import { selectToken } from '@clainio/web-platform/dist/tokens/utils/selectToken'
import { createTokenKey } from '@clainio/web-platform/dist/tokens/utils/createTokenKey'
import { SurfaceContainer } from '@clainio/web-platform'

export const RightOverlapSidebarWrapper = styled.div<{
  $isOpen: boolean
  $width: string
}>`
  @starting-style {
    opacity: 0;
    transform: translateX(100%);
  }
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  transform: translateX(${({ $isOpen }) => ($isOpen ? '0' : '100%')});
  width: ${({ $width }) => $width};
  transition: all 0.25s;
  transition-behavior: allow-discrete;
`
export const RightOverlapSidebar = styled(SurfaceContainer)`
  ${({ theme }) =>
    css({
      backgroundColor: selectToken(theme, createTokenKey(['white'])),
      borderLeft: `1px solid ${selectToken(
        theme,
        createTokenKey(['grey500'])
      )}`,
      width: '100%',
      minHeight: '100vh',
      overflow: 'auto',
    })}
`
