import { startOfDay, subMonths, endOfDay } from 'date-fns'
import {
  Exposure as ExposureType,
  ExposureItem,
} from '@clain/core/Exposure/Exposure.types'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { formatNumber } from '@clain/core/utils/format'
import { fromZonedTime } from 'date-fns-tz'

export const getOneYearZoomValue = (timezone: string) => ({
  start: fromZonedTime(startOfDay(subMonths(new Date(), 12)), timezone),
  end: fromZonedTime(endOfDay(new Date()), timezone),
})
export const transformExposure = (
  exposure: ExposureType
): Array<ExposureItem> =>
  Object.entries(exposure).map(([name, scoreFractions]) => {
    const totalFraction = scoreFractions.reduce(
      (result, [, fraction]) => result + fraction,
      0
    )
    const categoryScore = scoreFractions.reduce(
      (result, [score, fraction]) =>
        result + score * (fraction / totalFraction),
      0
    )

    return {
      name,
      value: totalFraction,
      color: getScoreColor(categoryScore, true),
      score: categoryScore,
      children: scoreFractions.map(([score, fraction]) => ({
        name: `${name} ${formatNumber((fraction * 100) / totalFraction, 1)}%`,
        color: getScoreColor(score, true),
        value: fraction,
        score,
      })),
    }
  })
