import { TransactionUtxo } from '../../../../types/converted/TransactionUtxo'
import {
  ExplorerServiceSchema,
  EXPLORER_SERVICE_CONFIG,
  getChannelKeyExplorer,
} from './ExplorerServiceSchema'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { CoinType } from '../../../../../../types/coin'
import { TransactionExplorerEvm } from '../../../../types/converted/TransactionEvm'

export class ExplorerService extends ExplorerServiceSchema {
  private currency: CoinType

  constructor(currency: CoinType) {
    super({
      CHANNEL_KEY: getChannelKeyExplorer(currency),
      ...EXPLORER_SERVICE_CONFIG,
    })
    this.currency = currency
  }

  public getTokenByAddress = async (
    ...payload: Parameters<typeof this._getTokenByAddress>
  ) => {
    return this._getTokenByAddress(...payload).then((response) => {
      return response
    })
  }

  public getTransaction = (
    hash: string
  ): Promise<TransactionUtxo | TransactionExplorerEvm> =>
    this._getTransaction(hash).then(
      (rawTransaction) =>
        normalizeSnakeToCamelCase(rawTransaction) as
          | TransactionUtxo
          | TransactionExplorerEvm
    )
}

export default ExplorerService
