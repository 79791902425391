import { StatusBlock } from '@clain/core/ui-kit'
import * as S from './EntityTableNotFound.styles'
import { FC } from 'react'
import { StackSpacesType } from '@clainio/web-platform'
import { useTranslation } from 'react-i18next'
export const EntityTableNotFound: FC<{
  padding?: StackSpacesType
}> = ({ padding = 'xxxxl' }) => {
  const { t } = useTranslation()

  return (
    <S.EntityTableNotFoundContainer padding={padding} align={'center'}>
      <StatusBlock>
        <StatusBlock.Image type={'search'} />
        <StatusBlock.Title>{t('platform:noResultsFound')}</StatusBlock.Title>
        <StatusBlock.Subtitle>
          {t('platform:tryToClearFiltersOrChangeFilterConditions')}
        </StatusBlock.Subtitle>
      </StatusBlock>
    </S.EntityTableNotFoundContainer>
  )
}
