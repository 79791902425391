import React from 'react'

import { FiltersProvider, useFiltersActions } from './TrxFilters.service'

import { normalizeTrxFilters } from './TrxFilter.utils'

import { TrxFilters } from './TrxFilters'
import { useInitFilters } from './TrxFilters.hooks'
import { useTransactiosnSearchViewModel } from '../../../context'
import { BlockchainFilterRightSidebar } from '@platform/components/BlockchainFilter/containers'
import { usePlotEntitiesOnGraph } from '@platform/hooks'

export const TransactionFiltersTrx = () => {
  return (
    <FiltersProvider>
      {/* TODO it should not be here*/}
      <BlockchainFilterRightSidebar />
      <TrxFilters />
    </FiltersProvider>
  )
}

export const useSetFilters = () => {
  const {
    filtersUpdate: transactionsTableFiltersUpdate,
    transactionsInitialFilters,
    transactionsTableDefaultFilters,
  } = useTransactiosnSearchViewModel()
  const { resetSelectedEntities } = usePlotEntitiesOnGraph('transactions')

  useInitFilters(transactionsInitialFilters, transactionsTableDefaultFilters)

  useFiltersActions({
    onSetFilters: (filters) => {
      transactionsTableFiltersUpdate(normalizeTrxFilters(filters))
      resetSelectedEntities('transactions')
    },
  })
}
