import { TypographyNew, EllipsisTypography } from '@clain/core/ui-kit'
import { styled } from 'styled-components'

export const CounterpartiesOthersCount = styled(TypographyNew)`
  flex: 0 0 auto;
  white-space: nowrap;
`

export const TypographyNoWrap = styled(EllipsisTypography)`
  white-space: nowrap;
`
