import {
  Button,
  ColDeprecated,
  TextField,
  RowDeprecated,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'
import { ReactComponent as FolderPlusThinIcon } from '@clain/core/assets/folder-plus-thin.svg'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { SaveToCaseModalStepNewProps } from './types'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames/bind'
import styles from './index.scss'

const cx = classnames.bind(styles)

export const SaveToCaseModalStepNew: React.FC<SaveToCaseModalStepNewProps> = ({
  hide,
  setStep,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const [newCaseName, setNewCaseName] = useState('')
  const saveButtonEnabled = newCaseName.length > 2

  const handleSubmit = () => {
    onSubmit(newCaseName)
  }

  useLayoutEffect(() => {
    inputRef?.current?.focus()
  }, [])

  return (
    <Stack gap={'xxxl'} direction={'column'}>
      <TextField
        ref={inputRef}
        value={newCaseName}
        onChange={setNewCaseName}
        fullWidth
        variant="outline"
        placeholder={t('platform:typeCaseLabel')}
      />

      <div className={cx('newContainer')}>
        <RowDeprecated />

        <RowDeprecated className={cx('center')}>
          <TypographyNew
            variant={'heading200Normal'}
            color={'onBackgroundVariant1'}
          >
            {t('platform:specifyNameForNewCase')}
          </TypographyNew>
        </RowDeprecated>

        <ColDeprecated className={cx('center')} gap={0}>
          <TypographyNew variant={'body100Normal'} color="onBackgroundBase">
            {t('platform:newCaseWillBeCreated')}
          </TypographyNew>
          <TypographyNew variant={'body100Normal'} color="onBackgroundBase">
            {t('platform:caseSettingsCanBeModified')}
          </TypographyNew>
        </ColDeprecated>

        <RowDeprecated className={cx('center')}>
          <FolderPlusThinIcon />
        </RowDeprecated>

        <RowDeprecated />
      </div>

      <RowDeprecated>
        <Button
          size="lg"
          variant="outline"
          color="secondary"
          onClick={() => setStep('list')}
        >
          {t('platform:backToList')}
        </Button>

        <RowDeprecated className={cx('footerButtonsRight')}>
          <Button size="lg" variant="outline" color="secondary" onClick={hide}>
            {t('platform:cancel')}
          </Button>
          <Button
            disabled={!saveButtonEnabled}
            size="lg"
            variant="solid"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            {t('platform:save')}
          </Button>
        </RowDeprecated>
      </RowDeprecated>
    </Stack>
  )
}
