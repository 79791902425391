import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DemixTrackList } from './DemixTrackList'
import probeVM from '../../vm/ProbeViewModel'
import classnames from 'classnames/bind'
import styles from './DemixTrackList.scss'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

const DemixTrackListContainer = () => {
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  if (!probeVM.demixAction?.isTrackListPopupOpened) {
    return null
  }
  return (
    <div
      ref={(node) => {
        if (node) {
          setContainerWidth(node.clientWidth)
          setContainerHeight(node.clientHeight)
        }
      }}
      className={cx('DemixTrackListContainer')}
      style={{
        transform: `translate(${
          probeVM.demixAction.getTrackListPopupPosition.x - containerWidth / 2
        }px, ${
          probeVM.demixAction.getTrackListPopupPosition.y - containerHeight
        }px)`,
      }}
    >
      <DemixTrackList
        items={probeVM.demixAction.getTrackListData}
        onSelect={probeVM.demixAction.onSelectDemixTrack}
      />
    </div>
  )
}

export default withErrorBoundary(observer(DemixTrackListContainer))
