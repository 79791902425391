import {
  DefaultUtxoTableConfig,
  TransactionsUtxoTableProps,
  getDefaultUtxoTableConfig,
  TransactionsUtxoTableItem,
} from './UtxoTable'
import { useCommonTableHelpers } from './helpers'
import {
  DefaultEvmTableConfig,
  TransactionEvmTableProps,
  getDefaultEvmTableConfig,
  TransactionEvmTableItem,
} from './EvmTable'
import { isEVM, isUTXO } from '@clain/core/types/coin'

export const useDefaultTransactionsTableConfig = ({
  cumulative,
  filters,
  setFilters,
  isLoading,
  getSearchResults,
  showInUSD,
  allowedRange,
  getSearchClusters,
  coinType,
  tokensBalance,
  ...rest
}: Omit<
  | TransactionsUtxoTableProps<TransactionsUtxoTableItem>
  | TransactionEvmTableProps<TransactionEvmTableItem>,
  'data' | 'itemsPerPage'
>) => {
  let config = {} as ReturnType<
    typeof getDefaultUtxoTableConfig | typeof getDefaultEvmTableConfig
  >
  const commonTableParams = useCommonTableHelpers({
    coinType,
    filters,
    setFilters,
    getSearchResults,
    getSearchClusters,
  })

  const params = {
    ...commonTableParams,
    ...rest,
    cumulative,
    isLoading,
    showInUSD,
    allowedRange,
    coinType,
    filters,
    setFilters,
    tokensBalance,
  }

  if (isUTXO(coinType)) {
    config = getDefaultUtxoTableConfig(
      params as DefaultUtxoTableConfig<TransactionsUtxoTableItem>
    )
  }
  if (isEVM(coinType)) {
    config = getDefaultEvmTableConfig(
      params as DefaultEvmTableConfig<TransactionEvmTableItem>
    )

    config = {
      ...config,
      defaultConfig: {
        ...config.defaultConfig,
        from: {
          ...(config as ReturnType<typeof getDefaultEvmTableConfig>)
            .defaultConfig.from,
          width: 1.2,
          minWidth: '200px',
        },
        to: {
          ...(config as ReturnType<typeof getDefaultEvmTableConfig>)
            .defaultConfig.to,
          width: 1.2,
          minWidth: '200px',
        },
      },
    }
  }

  return config
}
