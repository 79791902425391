import { FlowProbeEdge } from '@platform/components/ProbeSandbox/types/entities/FlowProbeEdge'
import { CoinType } from '../../../../../types/coin'
import { ClusterCounterparty } from '../../../types/converted/ClusterCounterparty'
import { addressKey, clusterKey, edgeKey } from '../../../utils/key'
import ProbeVM from '../../../vm/ProbeViewModel'
import { AddressCounterparty } from '@platform/components/ProbeSandbox/types/converted/AddressCounterparty'

export const useAggregateCounterparties = (
  data: (ClusterCounterparty | AddressCounterparty)[],
  currency: CoinType,
  activeKey: string
) => {
  const normalize = (
    addressCounterparty: ClusterCounterparty | AddressCounterparty
  ) => {
    const addressCounterpartyKey =
      addressCounterparty.type === 'cluster'
        ? clusterKey({ clusterId: addressCounterparty.clusterId }, currency)
        : addressKey({ address: addressCounterparty.address.address, currency })

    const activeAddressToCounterpartyEdgeKey = edgeKey(
      activeKey,
      addressCounterpartyKey
    )
    const counterpartyToActiveAddressEdgeKey = edgeKey(
      addressCounterpartyKey,
      activeKey
    )

    const isIncomingFlowEdge =
      ProbeVM.probeState.edges.get(counterpartyToActiveAddressEdgeKey)?.data
        .edgeType === 'flow'
    const isOutgoingFlowEdge =
      ProbeVM.probeState.edges.get(activeAddressToCounterpartyEdgeKey)?.data
        .edgeType === 'flow'

    const netflowSelected =
      (
        ProbeVM.probeState.edges.get(
          counterpartyToActiveAddressEdgeKey
        ) as FlowProbeEdge
      )?.data.net ||
      (
        ProbeVM.probeState.edges.get(
          activeAddressToCounterpartyEdgeKey
        ) as FlowProbeEdge
      )?.data.net

    const inflowSelected = isIncomingFlowEdge && !netflowSelected
    const outflowSelected = isOutgoingFlowEdge && !netflowSelected

    const isProcessing =
      ProbeVM.probeEvents.meta.nodesInProcessing[addressCounterpartyKey]

    const selected = isIncomingFlowEdge || isOutgoingFlowEdge

    // Return the modified object, merging the properties
    return {
      ...addressCounterparty,
      selected,
      isProcessing,
      inflowSelected,
      outflowSelected,
      netflowSelected,
    }
  }

  return data?.map(normalize)
}
