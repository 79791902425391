import React from 'react'
import classnames from 'classnames/bind'

import http from '@clain/core/http'
import { useFormatDate } from '../../../hooks'
import { MagicGrid } from '@clain/core/ui-kit'
import Form from '@clain/core/Form'
import { ColDeprecated } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { EditableAvatar } from '@clain/core/ui-kit'

import ChangePasswordCard from './ChangePasswordCard'
import styles from './index.scss'
//import SystemTimeDropdown from './SystemTimeDropdown'
//import useHttp from '@clain/core/useHttp'
import { SettingsLine } from './SettingsLine'
import { ProfileUser } from '../../../modules'
import { observer } from 'mobx-react-lite'
import pick from 'ramda/src/pick'
import { FieldEdit } from './FieldEdit'
import { FormRow } from './FormRow'
import { useSettings } from '../../../hooks'
import SettingsHeader from '../SettingsHeader'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()
import { HeaderSlot } from '@clain/core/Layout/slots'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

interface ProfileCardProps {
  user: ProfileUser
  onSubmit: (
    data: Partial<Pick<ProfileUser, 'avatar' | 'fullName' | 'position'>>
  ) => void
  onPasswordChange: () => void
}

const ProfileFormCard = ({
  user,
  onSubmit,
  onPasswordChange,
}: ProfileCardProps) => {
  const { t } = useTranslation()
  const [isGlobalEdit, setIsGlobalEdit] = React.useState(false)

  const formatDate = useFormatDate()

  const handleLoadImage = async (file: File) => {
    const formData = new FormData()

    formData.append('avatar', file)

    await http.post(
      buildUrl`${config?.PLATFORM_API}/api/private/settings/profile/avatar`,
      formData,
      {
        headers: {
          'content-type': 'multipart/forms-data',
        },
      }
    )
  }

  const handleRemoveImage = () => {
    http.post(
      buildUrl`${config?.PLATFORM_API}/api/private/settings/profile/avatar/remove`
    )
  }

  return (
    <div className={cx('ProfileCard')}>
      <RowDeprecated gap={3} className={cx('TopPanel')}>
        <ColDeprecated
          align="center"
          gap={0.5}
          className={cx('TopPanelFirstColumn')}
        >
          <MagicGrid gap={3}>
            <div>
              <RowDeprecated gap={0.5} direction="column">
                <ColDeprecated align="center">
                  <EditableAvatar
                    name={user.fullName}
                    src={user.avatar}
                    className={`${cx('Avatar')} sentry-block`}
                    onLoad={handleLoadImage}
                    onRemove={handleRemoveImage}
                  />
                </ColDeprecated>
                <ColDeprecated className={cx('Date')}>
                  {t('platform:since')}{' '}
                  {user.createdAt &&
                    formatDate(new Date(user.createdAt), 'date')}
                </ColDeprecated>
              </RowDeprecated>
            </div>
            <RowDeprecated gap={1} direction="column">
              <Button
                variant="solid"
                color="primary"
                size="lg"
                endIcon="Renew"
                onClick={onPasswordChange}
              >
                {t('platform:changePassword')}
              </Button>
              <Typography
                variant="body1"
                color="grey3"
                className={cx('Information')}
              >
                {t('platform:passwordInstructions')}
              </Typography>
            </RowDeprecated>
          </MagicGrid>
        </ColDeprecated>
        <Form
          className={`${cx('Form')} sentry-mask`}
          initialValues={pick(['fullName', 'position', 'avatar'], user)}
          onSubmit={onSubmit}
        >
          {({ form }) => {
            return (
              <ColDeprecated className={cx('FormGrid')}>
                <FormRow label={`${t('platform:fullName')}:`}>
                  <FieldEdit
                    name="fullName"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    onSubmit={() => form.submit()}
                  />
                </FormRow>
                <FormRow label={`${t('platform:corporateEmail')}:`}>
                  <TextField
                    readOnly
                    value={user.email}
                    name="email"
                    className={cx('FormInput')}
                    variant="base"
                  />
                </FormRow>
                <FormRow label={`${t('platform:position')}:`}>
                  <FieldEdit
                    name="position"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    onSubmit={() => form.submit()}
                  />
                </FormRow>
              </ColDeprecated>
            )
          }}
        </Form>
      </RowDeprecated>
    </div>
  )
}

/*
interface Timezone {
  name: string
  utcOffset: number
  utcOffsetStr: string
}
*/

const ProfileSettings: React.FC = () => {
  const settings = useSettings()
  const [isChangingPassword, setIsChangingPassword] = React.useState(false)
  const { t } = useTranslation()

  const handleChangePassword = () => {
    settings.changePassword()
    setIsChangingPassword(true)
  }

  HeaderSlot.useContent(() => <SettingsHeader />)

  return isChangingPassword ? (
    <MagicGrid className={cx('Container')} gap={2.5}>
      <Typography variant="heading2" color="black">
        {t('platform:profile')}
      </Typography>
      <ChangePasswordCard />
      <Button
        className={cx('BackToProfileButton')}
        variant="outline"
        color="secondary"
        size="lg"
        onClick={() => setIsChangingPassword(false)}
      >
        {t('platform:backToProfile')}
      </Button>
    </MagicGrid>
  ) : (
    <MagicGrid className={cx('Container')} gap={2.5}>
      <Typography variant="heading2" color="black">
        {t('platform:profile')}
      </Typography>
      <ProfileFormCard
        user={settings.userProfile}
        onSubmit={settings.updateUserProfile}
        onPasswordChange={handleChangePassword}
      />
      <Form
        initialValues={settings.userSettings}
        onChange={settings.updateUserSettings}
        className={`${cx('Form')} sentry-mask`}
      >
        {() => (
          <MagicGrid gap={2.5}>
            {/*


            <MagicGrid gap={2}>
              <Typography variant="heading2" color="black">
                System time
              </Typography>
              <Field
                as={SystemTimeDropdown}
                name="timezone"
                variant="outline"
                timezones={timezones}
                disabled={isLoading || error}
              />
            </MagicGrid>
            */}
            <MagicGrid gap={2.5}>
              <Typography variant="heading2" color="black">
                {t('platform:notifications')}
              </Typography>
              <MagicGrid gap={1.5}>
                <SettingsLine
                  variant="twice"
                  label={t('platform:mentions')}
                  firstFieldName="notifications.mentions.app"
                  firstFieldLabel={t('platform:inApp')}
                  secondFieldName="notifications.mentions.email"
                  secondFieldLabel={t('platform:email')}
                />
                <SettingsLine
                  variant="twice"
                  label={t('platform:comments')}
                  firstFieldName="notifications.comments.app"
                  firstFieldLabel={t('platform:inApp')}
                  secondFieldName="notifications.comments.email"
                  secondFieldLabel={t('platform:email')}
                />
                <SettingsLine
                  variant="twice"
                  label={t('platform:alerts')}
                  firstFieldName="notifications.alerts.app"
                  firstFieldLabel={t('platform:inApp')}
                  secondFieldName="notifications.alerts.email"
                  secondFieldLabel={t('platform:email')}
                />
                {/*
                <SettingsLine
                  variant="twice"
                  label="TXN filter updates"
                  firstFieldName="txnFilterUpdates.app"
                  firstFieldLabel="In-app"
                  secondFieldName="txnFilterUpdates.email"
                  secondFieldLabel="Email"
                  description="Notify about updates based on saved TXN filter settings"
                />
                */}
              </MagicGrid>
            </MagicGrid>
            <MagicGrid gap={2}>
              <Typography variant="heading2" color="black">
                {t('platform:graphPageSettings')}
              </Typography>
              <MagicGrid gap={1.5}>
                <SettingsLine
                  variant="once"
                  label={t('platform:openGraphNewTab')}
                  fieldName="graph.openInNewTab"
                />
                <SettingsLine
                  variant="once"
                  label={t('platform:autoConnectUtxo')}
                  fieldName="graph.utxoAutoconnect"
                />
                <SettingsLine
                  variant="twice"
                  label={t('platform:letterNotation')}
                  firstFieldName="graph.letterNotation.graph"
                  firstFieldLabel={t('platform:inGraph')}
                  secondFieldName="graph.letterNotation.tables"
                  secondFieldLabel={t('platform:inTables')}
                  description={t('platform:letterNotationDescription')}
                />
              </MagicGrid>
            </MagicGrid>
          </MagicGrid>
        )}
      </Form>
    </MagicGrid>
  )
}

export default observer(ProfileSettings)
