import { action, observable } from 'mobx'

export class ActiveEntityVisitedLink {
  @observable private visited: Set<string> = new Set()

  @action
  public add = (key: string) => {
    this.visited.add(key)
  }

  @action
  public has = (key: string) => {
    return this.visited.has(key)
  }

  @action
  public clear = () => {
    this.visited.clear()
  }
}
