import React, { Fragment } from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'

import { KeyValue } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Text } from '@clain/core/ui-kit'
import Markup from '../Markup'
import { useFormatDate } from '../../hooks'

import { OsIntItem } from './types'

import styles from './index.scss'
const cx = classnames.bind(styles)

interface WebLinkModalContentProps {
  link: OsIntItem
}

const WebLinkModalContent = ({ link }: WebLinkModalContentProps) => {
  const formatDate = useFormatDate()

  if (!link) return null

  const linkTitle = (
    <Text className={cx('ModalTitle')} size="lg">
      {link.title || link.url}
    </Text>
  )

  const linkDetails = (
    <KeyValue layout="vertical" data={link}>
      {[
        {
          key: 'Original link',
          value: ({ url }) => (
            <Link to={link.url} target="_blank">
              {url}
            </Link>
          ),
        },
        {
          key: 'Username',
          value: ({ user_link, username }) => (
            <Link to={link.user_link} target="_blank">
              {username || user_link}
            </Link>
          ),
        },
        {
          key: 'Indexed at',
          value: ({ time }) => formatDate(fromUnixTime(time)),
        },
      ]}
    </KeyValue>
  )

  const linkContent = (
    <div className={cx('ModalContent')}>
      {link.content.html && <Markup content={link.content.html} />}

      {link.content.markdown && (
        <Markup content={link.content.markdown} type="md" />
      )}
    </div>
  )

  return (
    <Fragment>
      {linkTitle}
      <div className={cx('ScrollPanel')}>
        {linkDetails}
        {linkContent}
      </div>
    </Fragment>
  )
}

export default WebLinkModalContent
