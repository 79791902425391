import { AddressesFiltersState } from './AddressesFilters.service'

import { btcToSatoshi } from '../AddressesSearch'
import { AddressesSearchRequestParams } from '@platform/apiServices'
import { FILTER_RECEIVED_AMOUNT_KEY } from '@platform/components/BlockchainFilter/constants'

export const normalizeTrxFilters = (
  trxFilters: AddressesFiltersState
): Partial<AddressesSearchRequestParams> => {
  const normalizedFilters: Partial<AddressesSearchRequestParams> = {
    page: 1,
    trx_total_amount_from: undefined,
    trx_total_amount_to: undefined,
    trx_time_from: undefined,
    trx_time_to: undefined,
  }

  if (trxFilters.calendar.data) {
    const [fromDate, toDate] = trxFilters.calendar.data

    if (fromDate) {
      normalizedFilters['trx_time_from'] = fromDate
    }

    if (toDate) {
      normalizedFilters['trx_time_to'] = toDate
    }
  }

  if (trxFilters[FILTER_RECEIVED_AMOUNT_KEY.root].data) {
    const [fromValue, toValue] =
      trxFilters[FILTER_RECEIVED_AMOUNT_KEY.root].data
    const condition = trxFilters[FILTER_RECEIVED_AMOUNT_KEY.root].condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_total_amount_from = btcToSatoshi(fromValue)
        normalizedFilters.trx_total_amount_to = btcToSatoshi(toValue)
        break

      case 'is':
        normalizedFilters.trx_total_amount_from = btcToSatoshi(fromValue)
        normalizedFilters.trx_total_amount_to = btcToSatoshi(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_total_amount_to = btcToSatoshi(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_total_amount_from = btcToSatoshi(fromValue)
        break
    }
  }

  return normalizedFilters
}
