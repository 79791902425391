import { action, computed, makeObservable, observable, transaction } from 'mobx'
import { IStateViewModel } from './mobxUtils.types'
import cloneDeep from 'lodash/cloneDeep'

export class StateViewModel<T> implements IStateViewModel<T> {
  @observable private initStateSchema: Partial<T>
  @observable initialState: T
  @observable state: T

  constructor(state?: T) {
    makeObservable(this)
    if (state) {
      this.initStateSchema = this.clone(state)
      this.initState(state)
    }
  }

  @action
  setState = (state: T) => {
    this.state = this.clone(state)
  }

  @action
  initState = (initialState: T) => {
    this.initialState = this.clone(initialState)
    this.state = this.clone(initialState)
  }

  @computed
  get defaultState() {
    return this.initStateSchema
  }

  @action
  initDefaultState = (state: Partial<T>) => {
    this.initStateSchema = this.clone(state)
  }

  /**
   * Updates the state with the provided partial state or updater function.
   *
   * If a partial state object is provided, only the specified fields are updated,
   * and the other fields in the state remain unchanged.
   * If an updater function is provided, it receives the current state and returns
   * a new state object. Only the fields that differ from the current state will be updated.
   *
   * @param stateOrUpdater - A partial state object or a function that returns a new state.
   * @param isForceInitUpdate - If true, updates the initialState with the new values as well.
   *
   * @example
   * // Update specific fields without affecting others
   * updateState({ name: "Alice" });
   * // this.state will be { name: "Alice", age: 17 } if the previous state was { name: "John", age: 17 }
   *
   * @example
   * // Use an updater function to modify the state
   * updateState(prevState => ({ ...prevState, name: "Alice" }));
   * // this.state will be { name: "Alice", age: 17 } if the previous state was { name: "John", age: 17 }
   */
  @action
  updateState = (
    stateOrUpdater: Partial<T> | ((prevState: T) => T),
    isForceInitUpdate = false
  ) => {
    let newState: Partial<T>

    if (typeof stateOrUpdater === 'function') {
      newState = (stateOrUpdater as (prevState: T) => T)(this.clone(this.state))
    } else if (
      !this.state ||
      (typeof stateOrUpdater === 'object' && Array.isArray(stateOrUpdater)) ||
      this.isPrimitive(stateOrUpdater)
    ) {
      this.state = stateOrUpdater as T
      if (isForceInitUpdate) {
        this.initialState = stateOrUpdater as T
      }
      return
    } else {
      newState = stateOrUpdater
    }

    transaction(() => {
      Object.keys(newState).forEach(
        action((key) => {
          const newValue = newState[key]
          if (this.state[key] !== newValue) {
            this.state[key] = newValue
            if (isForceInitUpdate) {
              this.initialState[key] = newValue
            }
          }
        })
      )
    })
  }

  @action
  resetState = () => {
    if (this.initialState) {
      this.state = this.clone(this.initialState)
    } else {
      this.clearState()
    }
  }

  @action
  clearState = () => {
    this.initialState = this.clone(this.initStateSchema) as T
    this.state = this.clone(this.initStateSchema) as T
  }

  private clone = <U>(data: U): U => {
    return cloneDeep(data)
  }

  private isPrimitive = <T>(value: T): boolean => {
    return value !== Object(value)
  }
}
