import { Container } from 'inversify'
import {
  probeActiveEntityModules,
  probeBaseModules,
  probePlotEntitiesModules,
} from './modules'

const probeContainer = new Container()

probeContainer.load(probeBaseModules)
probeContainer.load(probeActiveEntityModules)
probeContainer.load(probePlotEntitiesModules)

export const getProbeModule = <T>(key: symbol) => {
  return probeContainer.get<T>(key)
}

export { probeContainer }
