import { useTranslation } from 'react-i18next'
import { FilterDropdownMenuContainerTypes } from '@clain/core/ui-kit'
import { AddressFilterMenuContainer } from './AddressFilter'
import { AssetFilterMenuContainer } from './AssetFilter'
import { CalendarFilterMenuContainer } from './CalendarFilter'

export const useFilterItems = () => {
  const { t } = useTranslation()

  const items: FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem[][] =
    [
      [
        {
          id: 'address',
          icon: 'Pin',
          label: t('platform:address'),
          isDisabled: false,
          component: AddressFilterMenuContainer,
        },
        {
          id: 'asset',
          icon: 'Asset',
          label: t('platform:asset'),
          isDisabled: false,
          component: AssetFilterMenuContainer,
        },
      ],
      [
        {
          id: 'calendar',
          icon: 'Calendar',
          label: t('platform:time'),
          isDisabled: false,
          component: CalendarFilterMenuContainer,
        },
      ],
    ]

  return items
}
