import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as EmailIcon } from '@clain/core/assets/email.svg'
import { Container } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

const ChangePasswordCard = () => {
  const { t } = useTranslation()

  return (
    <Container className={cx('ChangePasswordCard')} gap={2}>
      <MagicGrid gap={2}>
        <RowDeprecated gap={2}>
          <EmailIcon className={cx('EmailIcon')} />
          <Typography variant="heading4" color="green">
            {t('platform:passwordChangeSuccess')}
          </Typography>
        </RowDeprecated>
        <Typography variant="body1" color="grey1">
          {t('platform:passwordChangeInstructions')}
          <ul className={cx('UnorderedList')}>
            <li>{t('platform:checkSpam')}</li>
            <li>{t('platform:tryAgain')}</li>
          </ul>
        </Typography>
      </MagicGrid>
    </Container>
  )
}

export default ChangePasswordCard
