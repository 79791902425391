import { ContainerModule, interfaces } from 'inversify'
import { IProbeEdge } from '../../models'
import { DI_TYPES } from '../DITypes'
import {
  CommentProbeEdge,
  CustomEdge,
  DemixEdge,
  FlowProbeEdge,
  TransactionProbeEdge,
  AddressBelongsProbeEdge,
  AttributionEdge,
  TransactionAddressBelongsProbeEdge,
  LinkEdge,
} from '../../entities'
import { EdgeData, NodeData } from '../../types'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.AddressBelongsProbeEdge).to(
    AddressBelongsProbeEdge
  )

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.AttributionEdge).to(
    AttributionEdge
  )

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.CommentProbeEdge).to(
    CommentProbeEdge
  )

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.CustomEdge).to(CustomEdge)

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.DemixEdge).to(DemixEdge)

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.FlowProbeEdge).to(FlowProbeEdge)

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.TransactionProbeEdge).to(
    TransactionProbeEdge
  )

  bind<IProbeEdge<EdgeData, NodeData>>(
    DI_TYPES.TransactionAddressBelongsProbeEdge
  ).to(TransactionAddressBelongsProbeEdge)

  bind<IProbeEdge<EdgeData, NodeData>>(DI_TYPES.LinkEdge).to(LinkEdge)
}

export const edgesModule = new ContainerModule(initializeModule)
