import { Link } from 'react-router-dom'
import {
  DefaultEvmTableConfig,
  TableColumnConfig,
  TableExpandedColumnConfig,
  TransactionEvmTableItem,
  TransactionEvmTableItemKey,
  TransactionsEvmExpandedItem,
} from './TransactionsEvmTable.types'
import {
  TypographyNew,
  RowDeprecated,
  TableSortButton,
  EllipsisTypography,
  IconButton,
  CopyIcon,
  Tooltip,
  StackDeprecated,
  Amount,
  EllipsisTypographyBlock,
  Stack,
  TablePlaceBorderRowVariant,
} from '@clain/core/ui-kit'
import { fromUnixTime } from 'date-fns'
import classnames from 'classnames/bind'
import styles from './TransactionsEvmTable.scss'
import { ContentCounterparty, Method, TranscationInfo } from '../components'
import { ContentValue } from '../components/ContentValue/ContentValue'
import { Token } from '../../../../ProbeSandbox/types/converted/TokenBalance'
import {
  FoggyStyled,
  TableCellContainer,
  TrxEvmTableCell,
  VisibilityContainer,
} from '../TransactionTable.styles'
import {
  ClusterTransactionAddressEvm,
  ClusterTransactionToken,
  TransactionTransfers,
} from '../../../../ProbeSandbox/types/converted/ClusterTransaction'
import { CoinTypeEVM, CoinType } from '../../../../../types/coin'
import { mergeDefaultToken } from '../../../../ProbeSandbox/utils/convertTokenBalances'
import {
  formatMoney,
  formatMoneyShort,
  GENERAL_NUMBER_NOTATION,
} from '@clain/core/utils/format'
import { existSomeScamSpam } from '../helpers'
import { i18next } from '@platform/i18Manager'

const cx = classnames.bind(styles)

const TransferItem = ({
  transferAddress,
  currency,
  isSpamScamp = false,
}: {
  currency: CoinTypeEVM
  transferAddress: ClusterTransactionAddressEvm | null
  isSpamScamp?: boolean
}) => {
  return (
    <TableCellContainer $muted={isSpamScamp}>
      <TrxEvmTableCell>
        {transferAddress ? (
          <RowDeprecated gap={0.5} className={cx('FromToRow')}>
            <ContentCounterparty
              score={transferAddress.score}
              address={transferAddress.address}
              clusterName={`${
                transferAddress.entity?.name || transferAddress.clusterId
              }`}
              clusterId={transferAddress.clusterId}
              currency={currency}
              contract={transferAddress.contract}
              entityIcon={transferAddress?.entity?.icon}
            />
          </RowDeprecated>
        ) : (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            System Call
          </TypographyNew>
        )}
      </TrxEvmTableCell>
    </TableCellContainer>
  )
}

export const getDefaultEvmTableConfig = ({
  filters,
  setFilters,
  isLoading,
  coinType,
  formatDate,
  formatMoney: formatMoneySettings,
  onChangeSort,
}: DefaultEvmTableConfig<TransactionEvmTableItem>) => {
  const renderAmount = (transfer: TransactionTransfers) => {
    const amountUsd = transfer?.usd

    const amountText = (() => {
      return formatMoneyShort({
        formats: GENERAL_NUMBER_NOTATION,
        value: Math.abs(amountUsd),
        currency: 'usd',
        code: '',
        symbol: '',
      })
    })()

    if (amountUsd == null) return

    const configFullValue = {
      formats: GENERAL_NUMBER_NOTATION,
      value: Math.abs(amountUsd),
      currency: 'usd',
      symbol: '',
      minimumSignificantDigits: 1,
    }

    return (
      <TableCellContainer justify="end" $muted={existSomeScamSpam([transfer])}>
        <Amount
          enabledCopy
          value={amountText}
          copyValue={formatMoney({
            ...configFullValue,
            code: '',
          })}
          fullValue={formatMoney(configFullValue)}
          symbol={'usd'}
        />
      </TableCellContainer>
    )
  }

  const renderValueAmount = (
    transfer: TransactionTransfers,
    allTransfersTokens: ClusterTransactionToken[],
    currency: CoinType,
    expanded: boolean
  ) => {
    const amount = transfer?.value
    const amountUsd = transfer?.usd

    const tokens = expanded
      ? transfer?.token
        ? [transfer.token]
        : [
            mergeDefaultToken(
              currency,
              {}
            ) as unknown as ClusterTransactionToken,
          ]
      : allTransfersTokens

    const getAmount = (() => {
      if (amount == null) return

      if (!tokens?.[0]) return null

      const decimals = tokens[0].decimals

      const tokenConfig =
        decimals != null
          ? {
              decimals: Number(decimals),
            }
          : {}

      return {
        amount: formatMoneySettings({
          value: amount,
          currency: coinType,
          code: '',
          ...tokenConfig,
        }),
        fullAmount: formatMoney({
          value: amount,
          precision: 64,
          minimumSignificantDigits: 1,
          currency: coinType,
          code: tokens?.[0]?.symbol,
          ...tokenConfig,
        }),
        copyAmount: formatMoney({
          value: amount,
          precision: 64,
          minimumSignificantDigits: 1,
          currency: coinType,
          code: '',
          ...tokenConfig,
        }),
      }
    })()

    return (
      <TableCellContainer justify="end" $muted={existSomeScamSpam([transfer])}>
        <ContentValue
          selected={tokens[0]}
          assets={tokens}
          blockchain={currency}
          onSelect={(token) =>
            setFilters({
              includeTokens: [token as unknown as Token],
              page: 1,
            })
          }
          value={getAmount?.amount ?? ''}
          fullValue={getAmount?.fullAmount ?? ''}
          copyValue={getAmount?.copyAmount ?? ''}
          direction={Number(amountUsd) < 0 ? 'out' : 'in'}
        />
      </TableCellContainer>
    )
  }

  const borders = [
    { bottom: { color: 'secondaryContainerDeep' } },
    { bottom: { color: 'secondaryContainerDeep' } },
  ] satisfies TablePlaceBorderRowVariant

  const defaultConfig: Record<
    TransactionEvmTableItemKey,
    TableColumnConfig<TransactionEvmTableItem>
  > = {
    transaction: {
      name: 'transaction',
      width: 0.5,
      borders,
      renderTitle: () => (
        <RowDeprecated>
          <RowDeprecated gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              {i18next.t('platform:transaction')}
            </TypographyNew>
          </RowDeprecated>
        </RowDeprecated>
      ),
      render: (item) => {
        return (
          <TableCellContainer $muted={existSomeScamSpam(item.transfers)}>
            <FoggyStyled color="base" content={<CopyIcon value={item.hash} />}>
              <StackDeprecated className={cx('ContentTnx')}>
                <RowDeprecated className={cx('ContentTnxContent')}>
                  <RowDeprecated
                    className={cx('ContentTnxContentLink')}
                    gap={0.25}
                  >
                    <TranscationInfo
                      failed={!item?.status}
                      hasDemix={item?.hasDemix}
                      hasCrossChainSwap={item?.hasCrossChainSwap}
                    />
                    <EllipsisTypographyBlock as={Stack} fullWidth>
                      <Tooltip content={item.hash}>
                        <div style={{ width: '100%', display: 'flex' }}>
                          <EllipsisTypography
                            as={Link}
                            to={`/${coinType}/explorer/transaction/${item.hash}`}
                            target="_blank"
                            variantAs="link"
                            variant="body200NormalCode"
                            color="onBackgroundBase"
                            type="center"
                          >
                            {item.hash}
                          </EllipsisTypography>
                        </div>
                      </Tooltip>
                    </EllipsisTypographyBlock>
                  </RowDeprecated>
                </RowDeprecated>
              </StackDeprecated>
            </FoggyStyled>
          </TableCellContainer>
        )
      },
      renderFooter: null,
    },
    method: {
      name: 'method',
      width: 0.5,
      minWidth: '120px',
      borders,
      renderTitle: () => (
        <RowDeprecated gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:method')}
          </TypographyNew>
        </RowDeprecated>
      ),
      render: ({ signature, transfers }) => {
        return (
          <TableCellContainer $muted={existSomeScamSpam(transfers)}>
            <Method name={signature?.name || signature?.signature} />
          </TableCellContainer>
        )
      },
    },
    from: {
      name: 'from',
      width: 1.5,
      minWidth: '346px',
      borders,
      renderTitle: () => (
        <RowDeprecated gap={0.5}>
          <TypographyNew
            variant="body200NormalCode"
            color="onBackgroundBase"
            transform="capitalize"
          >
            {i18next.t('platform:from')}
          </TypographyNew>
        </RowDeprecated>
      ),
      render: ({ transfers, currency }) => {
        if (!transfers?.length) return null
        const transfer = transfers[0]

        return (
          <TransferItem
            transferAddress={transfer.sender}
            currency={currency}
            isSpamScamp={existSomeScamSpam([transfer])}
          />
        )
      },
    },
    to: {
      name: 'to',
      width: 1.5,
      minWidth: '346px',
      borders,
      renderTitle: () => (
        <RowDeprecated gap={0.5}>
          <TypographyNew
            variant="body200NormalCode"
            color="onBackgroundBase"
            transform="capitalize"
          >
            {i18next.t('platform:to')}
          </TypographyNew>
        </RowDeprecated>
      ),
      render: ({ currency, transfers }) => {
        if (!transfers?.length) return null
        const transfer = transfers[0]

        return (
          <TransferItem
            transferAddress={transfer.receiver}
            currency={currency}
            isSpamScamp={existSomeScamSpam([transfer])}
          />
        )
      },
    },
    value: {
      name: 'value',
      width: 0.8,
      justify: 'end',
      minWidth: '150px',
      borders,
      renderTitle: () => (
        <RowDeprecated gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:value')}
          </TypographyNew>
        </RowDeprecated>
      ),
      render: ({ transfers, currency, allTransfersTokens }) => {
        if (!transfers?.length) return null

        const transfer = transfers?.[0]
        return renderValueAmount(transfer, allTransfersTokens, currency, false)
      },
    },
    amount: {
      name: 'Amount',
      width: 0.3,
      justify: 'end',
      minWidth: '97px',
      borders,
      renderTitle: () => (
        <RowDeprecated gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:usd')}
          </TypographyNew>
          <TableSortButton
            name="amount"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </RowDeprecated>
      ),
      render: ({ transfers }) => {
        if (!transfers?.length) return null
        const transfer = transfers[0]
        return renderAmount(transfer)
      },
      renderFooter: null,
    },
    time: {
      name: 'time',
      width: 0.7,
      justify: 'end',
      borders,
      renderTitle: () => (
        <RowDeprecated gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {i18next.t('platform:time')}
          </TypographyNew>
          <TableSortButton
            name="time"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </RowDeprecated>
      ),
      render: ({ transfers, time }, _, { collapse, onCollapse }) => (
        <TableCellContainer
          gap="xs"
          justify="end"
          $muted={existSomeScamSpam(transfers)}
        >
          <TypographyNew
            color="onBackgroundBase"
            variant="body200NormalCode"
            whiteSpace="nowrap"
          >
            {formatDate(fromUnixTime(time), 'date-time-numeric')}
          </TypographyNew>
          <VisibilityContainer $visible={transfers?.length > 1}>
            <IconButton
              size="sm"
              onClick={() => onCollapse()}
              iconVariant={collapse ? 'Up' : 'Down'}
            />
          </VisibilityContainer>
        </TableCellContainer>
      ),
    },
  }

  const expandendConfig: Record<
    'transaction' | 'method' | 'from' | 'to' | 'amount' | 'value' | 'time',
    TableExpandedColumnConfig<TransactionsEvmExpandedItem>
  > = {
    transaction: {
      name: 'transaction',
      render: () => null,
    },
    method: {
      name: 'method',
      render: () => null,
    },
    from: {
      name: 'from',
      borders,
      render: ({ transfer, currency }) => {
        if (!transfer) return null
        return (
          <TransferItem
            transferAddress={transfer.sender}
            isSpamScamp={existSomeScamSpam([transfer])}
            currency={currency}
          />
        )
      },
    },
    to: {
      name: 'to',
      borders,
      render: ({ transfer, currency }) => {
        if (!transfer) return null

        return (
          <TransferItem
            transferAddress={transfer.receiver}
            currency={currency}
            isSpamScamp={existSomeScamSpam([transfer])}
          />
        )
      },
    },
    value: {
      name: 'value',
      borders,
      render: ({ transfer, allTransfersTokens, currency }) => {
        if (!transfer) return

        return renderValueAmount(transfer, allTransfersTokens, currency, true)
      },
    },
    amount: {
      name: 'Amount',
      borders,
      render: ({ transfer }) => {
        if (!transfer) return null

        return renderAmount(transfer)
      },
    },
    time: {
      name: 'time',
      render: () => null,
    },
  }

  return { defaultConfig, expandendConfig }
}
