import { ListItemProps } from '@clain/core/ui-kit'
import { AssetFilterProps } from './ContentValue.types'
import { AssetsListShortItem } from '@clainio/web-platform'
import * as S from './ContentValue.styles'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'
import { AssetFilterPopover } from '@platform/components/EntitiesTable/ui'

const LIST_ITEM_CONFIG: ListItemProps = {
  size: ['md', 'md'],
}

export const AssetFilter: React.FC<AssetFilterProps> = ({
  selected,
  assets,
  blockchain,
  onSelect,
}) => {
  return (
    <AssetFilterPopover
      content={assets.map((asset) => {
        const variant = asset.scam
          ? 'critical'
          : asset.spam
            ? 'warning'
            : 'base'

        return (
          <AssetsListShortItem
            key={asset.id}
            {...LIST_ITEM_CONFIG}
            asset={{ ...asset, blockchain }}
            onClick={() => onSelect(asset)}
            variant={variant}
            iconSize="xs"
            isDefaultTokenId={isDefaultTokenId}
          />
        )
      })}
      placement="bottom"
      trigger="click"
      anchor={
        <span>
          <S.StyledAssetChip
            {...selected}
            blockchain={blockchain}
            isMulti={assets.length > 1}
            color={isDefaultTokenId(selected?.id) ? 'original' : 'grey'}
            variant={
              selected?.scam ? 'scam' : selected?.spam ? 'spam' : 'licit'
            }
            fullWidth
          >
            {selected.symbol}
          </S.StyledAssetChip>
        </span>
      }
    />
  )
}
