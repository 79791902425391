import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'

import { TransactionCommonEvm } from '../../../types/converted/TransactionEvm'
import InfobarTable from '../InfobarTable'

import styles from './index.scss'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { CoinTypeEVM } from '../../../../../types/coin'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

interface TransactionEvmInfobarProps {
  transaction: TransactionCommonEvm
  isUsd: boolean
  coinType: CoinTypeEVM
}

const TransactionEvmInfobar: React.FC<TransactionEvmInfobarProps> = ({
  transaction,
  isUsd,
  coinType = 'eth',
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  if (!transaction) return null

  return (
    <Container className={cx('TransactionEthInfobar')} gap={[1.5, 1]}>
      <ColDeprecated gap={2}>
        <ColDeprecated>
          <RowDeprecated gap={0.5}>
            <Typography variant="subheading2">
              {t('platform:transaction')}
            </Typography>
            <Typography className={cx('Transaction')} variant="code1">
              <Link
                className={cx('TransactionLink')}
                to="/:coin/explorer/transaction/:hash"
                params={{ hash: transaction.hash, coin: coinType }}
                target="_blank"
              >
                {transaction.hash}
              </Link>
            </Typography>
            <CopyIcon value={transaction.hash} />
          </RowDeprecated>
        </ColDeprecated>
        <InfobarTable
          data={[
            [
              {
                name: t('platform:status') + ':',
                value: transaction.status ? (
                  <div className={cx('Status', 'success')}>
                    <Typography color="grey2" variant="body2-sm">
                      {t('platform:success')}
                    </Typography>
                  </div>
                ) : (
                  <div className={cx('Status', 'fail')}>
                    <Typography color="white" variant="body3-sm">
                      {t('platform:fail')}
                    </Typography>
                  </div>
                ),
              },
              {
                name: t('platform:value') + ':',
                value: isUsd
                  ? formatMoney({
                      value: transaction.transfers[0]?.usd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.transfers[0]?.value,
                      currency: coinType,
                      code:
                        transaction.transfers[0]?.token?.symbol?.toUpperCase() ||
                        '',
                    }),
              },
              {
                name: t('platform:fee') + ':',
                value: isUsd
                  ? formatMoney({
                      value: transaction.feeUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.fee,
                      currency: coinType,
                    }),
              },
            ],
            [
              {
                name: t('platform:time') + ':',
                value:
                  transaction.time &&
                  formatDate(fromUnixTime(transaction.time), 'date-time'),
              },
              {
                name: t('platform:blockHeight') + ':',
                value: transaction.height,
              },
              {
                name: t('platform:confirmations') + ':',
                value: transaction.confirmations,
              },
            ],
          ]}
        />
      </ColDeprecated>
    </Container>
  )
}

export default observer(TransactionEvmInfobar)
