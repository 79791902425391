import { ColDeprecated, Icon, Tooltip } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
import { TransactionInfoProps } from './TransactionInfo.types'

export const TranscationInfo: React.FC<TransactionInfoProps> = ({
  failed = false,
  hasCrossChainSwap = false,
  hasDemix = false,
}) => {
  const { t } = useTranslation()
  const enabledContent = failed || hasDemix || hasCrossChainSwap

  return enabledContent ? (
    <ColDeprecated>
      <Tooltip
        content={
          hasDemix
            ? t('platform:autoDemixAvailable')
            : hasCrossChainSwap
              ? t('platform:crossChainSwap')
              : t('platform:errorTransactionTooltip')
        }
      >
        <span>
          <Icon
            variant={
              hasDemix ? 'Demix' : hasCrossChainSwap ? 'Bridge' : 'AlertCircle'
            }
            color="criticalHigh"
            size="xxs"
          />
        </span>
      </Tooltip>
    </ColDeprecated>
  ) : null
}
