import { differenceInCalendarMonths } from 'date-fns'
import { startOfMonth } from 'date-fns'

const dataTypes = {
  cumulative: 'cumulative',
  incremental: 'incremental',
}

export default function groupSeriesByMonth<
  RowData extends [number, number, ...any[]]
>(
  data: RowData[],
  dataType: keyof typeof dataTypes = 'incremental'
): RowData[] {
  if (data.length === 0) {
    return []
  }

  const startDate = data[0][0] // ms

  let prev

  return data.reduce((acc, [x, y, ...rest]) => {
    // при отдалении от начальной даты, будет меняться разница месяцев
    const curr = differenceInCalendarMonths(x, startDate)

    // пока неделя остается прежней, суммируем значения
    if (curr === prev) {
      // пока неделя остается прежней, суммируем значения
      if (dataType === dataTypes.incremental) {
        // TODO: useBigInt
        acc[acc.length - 1][1] = Number(acc[acc.length - 1][1]) + Number(y)
      }
      // используем последний день недели, т.к. данные кумулятивные
      if (dataType === dataTypes.cumulative) {
        acc[acc.length - 1][1] = Number(y)
      }
    }
    // когда неделя поменялась, то добавляем новую точку в данные
    // первая тоже тут добавится, потому что prev не задан
    else {
      prev = curr
      return [...acc, [startOfMonth(new Date(x)).getTime(), y, ...rest]]
    }

    return acc
  }, [])
}
