import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  OUTPUT_FILTERS_KEYS,
  useFilterNameList,
  useFilterSlice,
} from '../TrxFilters.service'

import { FilterDropdownMenuContainerItem } from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'
import { useResetIsNotChange } from '../TrxFilters.hooks'
import { SelectFilter, SelectFilterItem } from '@platform/components/Filters'

const ID = 'output_change'

const FilterContainer = () => {
  const { t } = useTranslation()
  const [, { resetFilter }] = useFilterSlice(ID)
  const [filter] = useFilterSlice(`${ID}.data`)
  const [condition] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={t('platform:output')}
      clearFilter={resetFilter}
    />
  )
}

const MenuItem = (
  setFilter: (data: SelectFilterItem<string>) => void
): FilterDropdownMenuContainerItem => {
  const { t } = useTranslation()
  const filters = useFilterNameList().filter((filter) =>
    OUTPUT_FILTERS_KEYS.includes(filter)
  )
  useResetIsNotChange()

  const isDisabled = !filters?.length

  return {
    id: ID,
    icon: null,
    label: t('platform:outputIsNotChange'),
    info: (
      <span style={{ whiteSpace: 'pre-wrap', maxWidth: '212px' }}>
        {t('platform:outputChangeFilterInfo')}
      </span>
    ),
    isDisabled,
    onItemClick: () => {
      setFilter({ value: ID, label: t('platform:change') })
    },
  }
}

export const ChangeFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
}
