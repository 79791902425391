import React from 'react'
import {
  CopyIcon,
  EllipsisTypography,
  EllipsisTypographyBlock,
  Stack,
  Tooltip,
  TypographyNew,
} from '@clain/core/ui-kit'
import { BaseLink } from '@clain/core/Link'
import { fromUnixTime } from 'date-fns'

import { TableSortButton, AssetsListShort, Amount } from '@clain/core/ui-kit'

import classnames from 'classnames/bind'
import styles from '../AddressTable.scss'

import AssetsRow from '../../../../ProbeSandbox/ui/AssetsRow'

import {
  AddressEvmTableItem,
  AddressEvmTableKey,
  DefaultEvmTableConfig,
  TableColumnConfig,
} from './AddressEvmTable.types'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  isDefaultTokenId,
} from '../../../../ProbeSandbox/utils/convertTokenBalances'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import { AddressStack, FoggyStyled } from '../AddressTable.styles'
import { AssetFilterPopover } from '@platform/components/EntitiesTable/ui'
import { i18next } from '@platform/i18Manager'

const cx = classnames.bind(styles)

export const getEvmTableConfig = ({
  currency,
  filters,
  isLoading,
  onChangeSort,
  showInUSD,
  formatMoney: formatMoneySettings,
  formatDate,
}: DefaultEvmTableConfig<AddressEvmTableItem>) => {
  const t = i18next.t

  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD || filters?.includeTokens?.[0]?.id == null
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
        ? {
            value: amount,
            currency,
          }
        : {
            value: amount,
            decimals: filters?.includeTokens[0].decimals,
            code: filters?.includeTokens[0].symbol,
          }
  }

  const defaultConfig: Record<
    AddressEvmTableKey,
    TableColumnConfig<AddressEvmTableItem>
  > = {
    address: {
      name: 'address',
      width: 1.5,
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {t('platform:address')}
        </TypographyNew>
      ),
      render: (item) => (
        <AddressStack align="center" fullHeight>
          <EllipsisTypographyBlock as={Stack} fullWidth>
            <FoggyStyled
              color="base"
              content={<CopyIcon value={item.address} />}
            >
              <Tooltip content={item.address}>
                <EllipsisTypography
                  as={BaseLink}
                  to="/:coin/address/:address"
                  params={{ address: item.address, coin: currency }}
                  target="_blank"
                  variantAs="link"
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  type="center"
                >
                  {item.address}
                </EllipsisTypography>
              </Tooltip>
            </FoggyStyled>
          </EllipsisTypographyBlock>
        </AddressStack>
      ),
    },
    tokens: {
      name: 'tokens',
      width: 0.5,
      justify: 'center',
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {t('platform:assets')}
        </TypographyNew>
      ),
      render: ({ tokens: assets }) => (
        <AssetFilterPopover
          maxHeight={300}
          content={
            <AssetsListShort
              assets={assets}
              isDefaultTokenId={isDefaultTokenId}
            />
          }
          anchor={
            <span>
              <AssetsRow
                className={cx('AssetsRow')}
                tokens={assets}
                buttonMode
              />
            </span>
          }
        />
      ),
    },
    received: {
      name: 'received',
      width: 1,
      justify: 'end',
      gap: 'md',
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {t('platform:received')}
          </TypographyNew>
          <TableSortButton
            name="received"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ received, receivedUsd }) => {
        if (!received) return

        const formatReceivedConfig = getAmountConfig(received, receivedUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatReceivedConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatReceivedConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatReceivedConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    sent: {
      name: 'sent',
      width: 1,
      justify: 'end',
      gap: 'md',
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {t('platform:sent')}
          </TypographyNew>
          <TableSortButton
            name="sent"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ sent, sentUsd }) => {
        if (!sent) return

        const formatSentConfig = getAmountConfig(sent, sentUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatSentConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatSentConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatSentConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    balance: {
      name: 'balance',
      width: 1,
      justify: 'end',
      gap: 'md',
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {t('platform:balance')}
          </TypographyNew>
          <TableSortButton
            name="balance"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ balance, balanceUsd }) => {
        if (!balance) return

        const formatBalanceConfig = getAmountConfig(balance, balanceUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatBalanceConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatBalanceConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatBalanceConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    transactions: {
      name: 'transactions',
      width: 1,
      justify: 'end',
      gap: 'md',
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {t('platform:transactions')}
          </TypographyNew>
          <TableSortButton
            name="transactions"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ trx, trxIn, trxOut }) => (
        <TypographyNew variant="body200Normal" color="onBackgroundBase">
          {trx} (
          <TypographyNew
            as="span"
            variant="body200Normal"
            color="onBackgroundVariant2"
          >
            {t('platform:in')}
          </TypographyNew>{' '}
          {trxIn}/{' '}
          <TypographyNew
            as="span"
            variant="body200Normal"
            color="onBackgroundVariant2"
          >
            {t('platform:out')}
          </TypographyNew>{' '}
          {trxOut})
        </TypographyNew>
      ),
    },
    period: {
      name: 'period',
      width: 1.25,
      minWidth: '220px',
      justify: 'end',
      gap: 'md',
      renderTitle: () => (
        <>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {t('platform:activityPeriod')}
          </TypographyNew>
          <TableSortButton
            name="time"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </>
      ),
      render: ({ activeFrom, activeTo }) => {
        if (!activeFrom || !activeTo)
          return (
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              {t('platform:unknown')}
            </TypographyNew>
          )

        return (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {`${formatDate(
              fromUnixTime(activeFrom),
              'date-numeric'
            )} - ${formatDate(fromUnixTime(activeTo), 'date-numeric')}`}
          </TypographyNew>
        )
      },
    },
  }

  return defaultConfig
}
