import { styled } from 'styled-components'
import { Foggy, Stack } from '@clain/core/ui-kit'

export const FoggyStyled = styled(Foggy)`
  width: 100%;
`

export const AddressStack = styled(Stack)`
  overflow: hidden;
`
