import { ContainerModule, interfaces } from 'inversify'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import {
  IGenerateEntityEvents,
  IGenerateEntity,
  IGenerateEntitiesAdapter,
} from '../../GraphEvents.types'
import {
  GenerateEdgeFlow,
  GenerateEdgeAddressBelongs,
  GenerateEdgeAttribution,
  GenerateEdgeCrossChainSwapFlow,
} from '../../modules/GenerateEdges'
import { GenerateEdge } from '../../modules/GenerateEdges/GenerateEdge'
import { GenerateEdgeAutoConnectTransactionUTXO } from '../../modules/GenerateEdges/GenerateEdgeAutoConnectTransactionUTXO'
import { GenerateEdgeComment } from '../../modules/GenerateEdges/GenerateEdgeComment'
import { GenerateEdgeDemix } from '../../modules/GenerateEdges/GenerateEdgeDemix'
import { GenerateEdgeTransactionAddressUTXO } from '../../modules/GenerateEdges/GenerateEdgeTransactionAddressUTXO'
import { GenerateEdgeTransactionEVM } from '../../modules/GenerateEdges/GenerateEdgeTransactionEVM'
import { GenerateEdgeTransactionInternalsEVM } from '../../modules/GenerateEdges/GenerateEdgeTransactionInternalsEVM'
import { GenerateEdgeTransactionTokensEVM } from '../../modules/GenerateEdges/GenerateEdgeTransactionTokensEVM'
import { GenerateEdgeTransactionTokenUTXO } from '../../modules/GenerateEdges/GenerateEdgeTransactionTokenUTXO'
import { GenerateEdgeTransactionUTXO } from '../../modules/GenerateEdges/GenerateEdgeTransactionUTXO'
import { GenerateEntity } from '../../modules/GenerateEntity'
import {
  GenerateDemixTrxUtxo,
  GenerateDemixTrxAddressUtxo,
  GenerateDemixTrxEvm,
  GenerateTransactionAddressLinked,
  GenerateTransactionUtxoLinked,
  GenerateCrossChainSwapAddress,
  GenerateCrossChainSwapUnsupportedAddress,
  GenerateCrossChainSwapTrxEvm,
  GenerateCrossChainSwapTrxUtxo,
} from '../../modules/GenerateEntityAdapters'
import { GenerateEntityEvents } from '../../modules/GenerateEntityEvents'
import {
  GenerateNodeCluster,
  GenerateNodeAddress,
  GenerateNodeOsint,
} from '../../modules/GenerateNodes'
import { GenerateNode } from '../../modules/GenerateNodes/GenerateNode'
import { GenerateNodeAutoConnectTransactionUTXO } from '../../modules/GenerateNodes/GenerateNodeAutoConnectTransactionUTXO'
import { GenerateNodeComment } from '../../modules/GenerateNodes/GenerateNodeComment'
import { GenerateNodeCustom } from '../../modules/GenerateNodes/GenerateNodeCustom'
import { GenerateNodeDemix } from '../../modules/GenerateNodes/GenerateNodeDemix'
import { GenerateNodeText } from '../../modules/GenerateNodes/GenerateNodeText'
import { GenerateNodeTransactionAddressUTXO } from '../../modules/GenerateNodes/GenerateNodeTransactionAddressUTXO'
import { GenerateNodeTransactionEVM } from '../../modules/GenerateNodes/GenerateNodeTransactionEVM'
import { GenerateNodeTransactionTokenUTXO } from '../../modules/GenerateNodes/GenerateNodeTransactionTokenUTXO'
import { GenerateNodeTransactionUTXO } from '../../modules/GenerateNodes/GenerateNodeTransactionUTXO'
import { GenerateNodeUnsupportedAddress } from '../../modules/GenerateNodes/GenerateNodeUnsupportedAddress'
import {
  EventDemixUtxo,
  EventTransactionUTXO,
  EventTransactionAddress,
  EventDemixEvm,
  EventTransactionEVM,
  EventTransactionLinked,
  EventUtxoTransactionByTrxAddress,
  EventCrossChainSwap,
  EventAddress,
  EventUnsupportedAddress,
  EventCluster,
  EventOsint,
  EventTransactionToken,
  EventComment,
  EventDemix,
  EventCustomEntity,
  EventText,
  EventAddressFlow,
  EventEdgeLink,
} from '../../types'
import { GenerateEdgeAddressFlow } from '../../modules/GenerateEdges/GenerateEdgeAddressFlow'
import { GenerateEdgeLink } from '../../modules/GenerateEdges/GenerateEdgeLink'

export const generateEntityModule = new ContainerModule(
  (bind: interfaces.Bind) => {
    //Entity strategy
    bind<IGenerateEntityEvents>(GRAPH_ENTITIES_TYPES.GenerateEntityEvents).to(
      GenerateEntityEvents
    )

    bind<IGenerateEntity<unknown>>(GRAPH_ENTITIES_TYPES.GenerateEntity).to(
      GenerateEntity
    )

    // Entity Adapters
    bind<IGenerateEntitiesAdapter<EventDemixUtxo, EventTransactionUTXO>>(
      GRAPH_ENTITIES_TYPES.GenerateDemixTrxUtxo
    ).to(GenerateDemixTrxUtxo)

    bind<IGenerateEntitiesAdapter<EventDemixUtxo, EventTransactionAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateDemixTrxAddressUtxo
    ).to(GenerateDemixTrxAddressUtxo)

    bind<IGenerateEntitiesAdapter<EventDemixEvm, EventTransactionEVM>>(
      GRAPH_ENTITIES_TYPES.GenerateDemixTrxEvm
    ).to(GenerateDemixTrxEvm)

    bind<
      IGenerateEntitiesAdapter<EventTransactionLinked, EventTransactionAddress>
    >(GRAPH_ENTITIES_TYPES.GenerateTransactionAddressLinked).to(
      GenerateTransactionAddressLinked
    )

    bind<
      IGenerateEntitiesAdapter<
        EventTransactionLinked,
        EventUtxoTransactionByTrxAddress
      >
    >(GRAPH_ENTITIES_TYPES.GenerateTransactionUtxoLinked).to(
      GenerateTransactionUtxoLinked
    )

    bind<IGenerateEntitiesAdapter<EventCrossChainSwap, EventAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapAddress
    ).to(GenerateCrossChainSwapAddress)

    bind<
      IGenerateEntitiesAdapter<EventCrossChainSwap, EventUnsupportedAddress>
    >(GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapUnsupportedAddress).to(
      GenerateCrossChainSwapUnsupportedAddress
    )

    bind<IGenerateEntitiesAdapter<EventCrossChainSwap, EventTransactionEVM>>(
      GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapTrxEvm
    ).to(GenerateCrossChainSwapTrxEvm)

    bind<IGenerateEntitiesAdapter<EventCrossChainSwap, EventTransactionUTXO>>(
      GRAPH_ENTITIES_TYPES.GenerateCrossChainSwapTrxUtxo
    ).to(GenerateCrossChainSwapTrxUtxo)

    //Entity Generate
    bind<GenerateNode<EventCluster>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeCluster
    ).to(GenerateNodeCluster)

    bind<GenerateEdge<EventCluster>>(GRAPH_ENTITIES_TYPES.GenerateEdgeFlow).to(
      GenerateEdgeFlow
    )

    bind<GenerateEdge<EventEdgeLink>>(GRAPH_ENTITIES_TYPES.GenerateLinkEdge).to(
      GenerateEdgeLink
    )

    bind<GenerateEdge<EventAddressFlow>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeAddressFlow
    ).to(GenerateEdgeAddressFlow)

    bind<GenerateNode<EventAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeAddress
    ).to(GenerateNodeAddress)

    bind<GenerateEdge<EventAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeAddressBelongs
    ).to(GenerateEdgeAddressBelongs)

    bind<GenerateNode<EventOsint>>(GRAPH_ENTITIES_TYPES.GenerateNodeOsint).to(
      GenerateNodeOsint
    )

    bind<GenerateEdge<EventOsint>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeAttribution
    ).to(GenerateEdgeAttribution)

    bind<GenerateNode<EventTransactionEVM>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeTransactionEVM
    ).to(GenerateNodeTransactionEVM)

    bind<GenerateEdge<EventTransactionEVM>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionEVM
    ).to(GenerateEdgeTransactionEVM)

    bind<GenerateNode<EventTransactionUTXO>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeTransactionUTXO
    ).to(GenerateNodeTransactionUTXO)

    bind<GenerateEdge<EventTransactionUTXO>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionUTXO
    ).to(GenerateEdgeTransactionUTXO)

    bind<GenerateNode<EventTransactionUTXO>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeAutoConnectTransactionUTXO
    ).to(GenerateNodeAutoConnectTransactionUTXO)

    bind<GenerateEdge<EventTransactionUTXO>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeAutoConnectTransactionUTXO
    ).to(GenerateEdgeAutoConnectTransactionUTXO)

    bind<GenerateEdge<EventTransactionEVM>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionInternalsEVM
    ).to(GenerateEdgeTransactionInternalsEVM)

    bind<GenerateEdge<EventTransactionEVM>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionTokensEVM
    ).to(GenerateEdgeTransactionTokensEVM)

    bind<GenerateNode<EventTransactionAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeTransactionAddressUTXO
    ).to(GenerateNodeTransactionAddressUTXO)

    bind<GenerateEdge<EventTransactionAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionAddressUTXO
    ).to(GenerateEdgeTransactionAddressUTXO)

    bind<GenerateNode<EventTransactionToken>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeTransactionTokenUTXO
    ).to(GenerateNodeTransactionTokenUTXO)

    bind<GenerateEdge<EventTransactionToken>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeTransactionTokenUTXO
    ).to(GenerateEdgeTransactionTokenUTXO)

    bind<GenerateNode<EventComment>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeComment
    ).to(GenerateNodeComment)

    bind<GenerateEdge<EventComment>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeComment
    ).to(GenerateEdgeComment)

    bind<GenerateNode<EventDemix>>(GRAPH_ENTITIES_TYPES.GenerateNodeDemix).to(
      GenerateNodeDemix
    )

    bind<GenerateEdge<EventDemix>>(GRAPH_ENTITIES_TYPES.GenerateEdgeDemix).to(
      GenerateEdgeDemix
    )

    bind<GenerateNode<EventCustomEntity>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeCustom
    ).to(GenerateNodeCustom)

    bind<GenerateNode<EventText>>(GRAPH_ENTITIES_TYPES.GenerateNodeText).to(
      GenerateNodeText
    )

    bind<GenerateEdge<EventCrossChainSwap>>(
      GRAPH_ENTITIES_TYPES.GenerateEdgeCrossChainSwapFlow
    ).to(GenerateEdgeCrossChainSwapFlow)

    bind<GenerateNode<EventUnsupportedAddress>>(
      GRAPH_ENTITIES_TYPES.GenerateNodeUnsupportedAddress
    ).to(GenerateNodeUnsupportedAddress)
  }
)
