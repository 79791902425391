import {
  Icon,
  List,
  ListItemContainer,
  PopoverBaseContainer,
  RowDeprecated,
} from '@clainio/web-platform'
import { RowStyled } from './EntityDetails.styles'
import React, { FC } from 'react'
import { NoDataText, TooltipTop, TypographyNewEllipsisStyled } from './Helpers'

type RowWebsiteProps = {
  firstLink: string
  hrefs: string[]
  restLinks: string[]
}

export const RowWebsite: FC<RowWebsiteProps> = ({
  firstLink,
  hrefs,
  restLinks,
}) => (
  <TooltipTop content={'Website'}>
    <RowDeprecated gap={0.25}>
      <div>
        <Icon variant={'Globe'} size={'xs'} color="onBackgroundVariant1" />
      </div>
      {firstLink ? (
        <>
          <RowStyled $maxWidth={240}>
            <TypographyNewEllipsisStyled
              href={hrefs[0]}
              as={'a'}
              target="_blank"
              variant={'body100Normal'}
              color={'primaryBase'}
            >
              {firstLink}
            </TypographyNewEllipsisStyled>
          </RowStyled>
          {restLinks?.length > 0 && (
            <PopoverBaseContainer
              placement={'bottom-end'}
              content={
                <List maxWidth={280}>
                  {restLinks.map((link, index) => (
                    <ListItemContainer key={link} size={'md'}>
                      <TypographyNewEllipsisStyled
                        style={{ cursor: 'pointer' }}
                        href={`https://${hrefs[index + 1]}`}
                        as={'a'}
                        target="_blank"
                        variant={'body100Normal'}
                        color={'primaryBase'}
                      >
                        {link}
                      </TypographyNewEllipsisStyled>
                    </ListItemContainer>
                  ))}
                </List>
              }
              anchor={
                <div>
                  <Icon
                    cursor={'pointer'}
                    variant={'Down'}
                    color={'onBackgroundVariant1'}
                    size={'xs'}
                  />
                </div>
              }
            />
          )}
        </>
      ) : (
        <NoDataText />
      )}
    </RowDeprecated>
  </TooltipTop>
)
