import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import { useTranslation } from 'react-i18next'

import { ReactComponent as InfoIcon } from '@clain/core/assets/info_outline.svg'

import { Container } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../hooks'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ConfirmButton } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { CheckboxSwitchDouble } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Battery } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'

import SettingsHeader from '../SettingsHeader'
import styles from './index.scss'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import AddNewOfficerForm from './AddNewOfficerForm'
import { NewEntityButton } from '@clain/core/ui-kit'
import {
  TeamMember,
  TeamMembers,
} from '../../../modules/settings/services/SettingsService.types'
import { useSettings } from '../../../hooks'
import { HeaderSlot } from '@clain/core/Layout/slots'

const cx = classnames.bind(styles)

interface MemberCardProps {
  user: TeamMember
  showRemoveMemberButton: boolean
  showAddMemberButton: boolean
  allowAddMember: boolean
  addMember: (id: number) => void
  removeMember: (id: number) => void
}

const MemberCard = ({
  user,
  showRemoveMemberButton,
  showAddMemberButton,
  allowAddMember,
  addMember,
  removeMember,
}: MemberCardProps) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const isProbeUser = user.subscriptions.probe
  const isCrmUser = user.subscriptions.crm

  const showAddToButton = showAddMemberButton && !isProbeUser && allowAddMember

  return (
    <div
      className={cx('MemberCard', {
        supervisor: user.position === 'supervisor',
        disabled: showAddMemberButton && isProbeUser,
      })}
    >
      <Avatar name={user.fullName} className={cx('Avatar')} />
      <div className={cx('Info')}>
        <div className={cx('Role')}>
          {user.position || t('platform:officer')}
        </div>
        <div className={cx('Name')}>{user.fullName}</div>
        <div className={cx('Position')}>{user.position}</div>
        <div className={cx('Date')}>
          {formatDate(new Date(user.insertedAt), 'date')}
        </div>
      </div>
      <div className={cx('Email')}>{user.email}</div>
      <div className={cx('Actions')}>
        {showAddToButton && (
          <ConfirmButton
            onClick={() => addMember(user.id)}
            className={cx('MemberCardButton')}
            title={
              <Typography variant="heading5" color="black">
                {t('platform:addUserToProbe')}
              </Typography>
            }
            acceptText={t('platform:addOfficer')}
            content={
              <>
                <Typography variant="body3">{`${user.fullName}'s`}</Typography>{' '}
                {t('platform:willBeProvidedAccess')}
              </>
            }
          >
            {t('platform:addToProbe')}
          </ConfirmButton>
        )}
        <ColDeprecated
          gap={0.25}
          align="left"
          className={cx(
            'Subscriptions',
            (showRemoveMemberButton || showAddToButton) && 'SubscriptionsHide'
          )}
        >
          {isProbeUser && (
            <ColDeprecated gap={0}>
              <TagDeprecated variant="alternate" size="sm">
                probe
              </TagDeprecated>
            </ColDeprecated>
          )}
          {isCrmUser && (
            <ColDeprecated gap={0}>
              <TagDeprecated variant="default" size="sm">
                crm
              </TagDeprecated>
            </ColDeprecated>
          )}
        </ColDeprecated>
        {showRemoveMemberButton && (
          <ConfirmButton
            onClick={() => removeMember(user.id)}
            className={cx('MemberCardButton')}
            title={
              <Typography variant="heading5" color="black">
                {t('platform:removeFromProbe')}
              </Typography>
            }
            acceptText={t('platform:remove')}
            content={
              <>
                <Typography variant="body3">{`${user.fullName}'s}`}</Typography>{' '}
                {t('platform:accessDisabled')}
              </>
            }
          >
            {t('platform:removeFromProbe')}
          </ConfirmButton>
        )}
      </div>
    </div>
  )
}

const MemberCardPlus = ({ disabled = false }) => {
  const { t } = useTranslation()

  const target: ModalSlot = ({ show }) => {
    return (
      <NewEntityButton disabled={disabled} onClick={show} size="lg">
        {t('platform:newMember')}
      </NewEntityButton>
    )
  }

  const body: ModalSlot = ({ hide }) => (
    <AddNewOfficerForm onSubmitSuccess={hide} onCancel={hide} />
  )

  return (
    <Modal
      target={target}
      closable
      title={
        <div className={cx('MemberCardPlusHeader')}>
          <Typography variant="heading5" color="black">
            {t('platform:addNewTeamMember')}
          </Typography>
        </div>
      }
    >
      {body}
    </Modal>
  )
}

interface MembersListProps {
  users: TeamMembers
  showAddMember: boolean
  showRemoveMemberButton: boolean
  showAddMemberButton: boolean
  allowAddMember: boolean
  addMember: (index: number) => void
  removeMember: (index: number) => void
}

const MembersList = ({
  users,
  showAddMember,
  allowAddMember,
  showAddMemberButton,
  showRemoveMemberButton,
  addMember,
  removeMember,
}: MembersListProps) => {
  return (
    <div className={`${cx('MembersList')} sentry-block sentry-mask`}>
      {showAddMember && <MemberCardPlus disabled={!allowAddMember} />}
      {users.map((user) => (
        <MemberCard
          key={user.id}
          user={user}
          allowAddMember={allowAddMember}
          showRemoveMemberButton={showRemoveMemberButton}
          showAddMemberButton={showAddMemberButton}
          addMember={addMember}
          removeMember={removeMember}
        />
      ))}
    </div>
  )
}

const ReachedLimitPanel = () => {
  const { t } = useTranslation()
  return (
    <Container className={cx('ReachedLimitPanel')}>
      <RowDeprecated gap={0.75}>
        <InfoIcon />
        <Typography variant="body3">
          {t('platform:reachedTeamLimit')}
        </Typography>
      </RowDeprecated>
    </Container>
  )
}

const TeamSettings: React.FC = () => {
  const { t } = useTranslation()
  const [status, setStatus] = React.useState<'current' | 'all'>('current')
  const settings = useSettings()

  HeaderSlot.useContent(() => <SettingsHeader />)

  return (
    <ErrorBoundaryContainer>
      <div className={cx('Container')}>
        <MagicGrid gap={2}>
          <MagicGrid gap={4}>
            <MagicGrid gap={3}>
              <Typography variant="heading2" color="black">
                {t('platform:teamSetup')}
              </Typography>
              <RowDeprecated gap={1.2}>
                <RowDeprecated gap={2.5} fullWidth>
                  <Typography
                    variant={'body1'}
                    color={'grey2'}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t('platform:teamMembers')}
                  </Typography>
                  <Battery
                    capacity={settings.teamPlan.maxUsers}
                    level={settings.teamPlan.current}
                  />
                </RowDeprecated>
                {settings.teamPlan.maxUsers ? (
                  <Typography
                    variant="body1"
                    color={'black'}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {settings.teamPlan.current} of {settings.teamPlan.maxUsers}
                  </Typography>
                ) : null}
              </RowDeprecated>
            </MagicGrid>
            <CheckboxSwitchDouble
              leftLabel={t('platform:currentProbeUsers')}
              rightLabel={t('platform:allCompanyMembers')}
              leftValue="current"
              rightValue="all"
              value={status}
              onChange={setStatus}
            />
          </MagicGrid>

          {settings.isLimitReached && <ReachedLimitPanel />}

          <MembersList
            users={
              status === 'current' ? settings.probeMembers : settings.allMembers
            }
            showAddMember={status === 'current'}
            showRemoveMemberButton={status === 'current'}
            showAddMemberButton={status === 'all'}
            allowAddMember={!settings.isLimitReached}
            addMember={settings.enableTeamMember}
            removeMember={settings.disableTeamMember}
          />
        </MagicGrid>
      </div>
    </ErrorBoundaryContainer>
  )
}

export default observer(TeamSettings)
