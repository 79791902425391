import { action, makeObservable, observable } from 'mobx'
import { ProbeViewModel } from '../ProbeViewModel'
import { entityDataToSnapshot } from '../EntityDataToSnapshot'
import throttle from 'lodash/throttle'
import { GraphEmitEvents, Position } from '../ProbeEvents'
import {
  ServerUpdateNodeReceive,
  ServerUpdatePositionNodeReceive,
} from '../../types/serverData'
import { TextProbeNode } from '../../types/nodeEntitiesData/TextNodeData'

export class TextNodeController {
  @observable public isNewNode = false
  @observable public isPositionTextOnCanvasInProgress = false
  @observable public isActive = false
  @observable public probeTextNode: TextProbeNode | null = null
  @observable public temporaryTextNode: TextProbeNode | null = null
  private probeVM: ProbeViewModel

  constructor(probeVM: ProbeViewModel) {
    makeObservable(this)
    this.probeVM = probeVM
  }

  @action
  public createTextNode = () => {
    this.setIsPositionTextOnCanvasInProgress(true)
    const { key } = this.probeVM.factory.produceTextNode()
    this.positionTextNodeOnCanvas(key)
  }

  @action
  public setIsPositionTextOnCanvasInProgress = (inProgress: boolean) => {
    document.body.style.cursor = inProgress ? 'text' : 'auto'
    this.isPositionTextOnCanvasInProgress = inProgress
  }

  @action
  private positionTextNodeOnCanvas = (key: string) => {
    const node = this.probeVM.probeState.nodes.get(key) as TextProbeNode
    if (!node) return
    node.setInteractive(false)
    const pointerListener = this.getPointerListener(node)
    this.probeVM.pointerController.addListener(pointerListener)
    this.probeVM.app.once('world:mouseup', () => {
      this.probeVM.pointerController.removeListener(pointerListener)
      node.setInteractive(true)
      this.isNewNode = true
      this.activateTextNode(key)
      this.setIsPositionTextOnCanvasInProgress(false)
    })
  }

  private getPointerListener = (node: TextProbeNode) => {
    return (position: Position) => {
      const worldPosition = this.probeVM.app?.toWorldCoordinates(position)
      if (worldPosition) node.moveTo(worldPosition)
    }
  }

  @action
  public activateTextNode = (key: string) => {
    this.probeTextNode = this.probeVM.probeState.nodes.get(key) as TextProbeNode
    if (!this.probeTextNode) return

    this.temporaryTextNode = this.probeVM.factory.produceTextNode(
      this.probeTextNode.data,
      this.probeTextNode.position.x,
      this.probeTextNode.position.y,
      key,
      false
    ).node

    const activateNode = () => {
      this.probeTextNode.visible = false
      this.temporaryTextNode.visible = false
      this.isActive = true
    }

    if (this.isNewNode && this.probeVM.camera.zoom < 0.8) {
      this.probeVM.app.moveScreenWithAnimate({
        position: this.probeTextNode.position,
        onFinish: activateNode,
      })
    } else {
      activateNode()
    }
  }

  @action
  public deactivateTextNode = () => {
    if (!this.probeTextNode) return

    this.probeTextNode.visible = true
    if (this.isNewNode && this.temporaryTextNode?.data.text.trim()) {
      this.addNode()
    }
    if (this.temporaryTextNode?.data.text !== this.probeTextNode.data.text) {
      this.updateServerNodeData('all', true)
    }
    this.syncProbeStateTextNode()
    this.probeTextNode.setHighlighted(false)
    if (!this.temporaryTextNode?.data.text.trim() && !this.isNewNode) {
      this.deleteTextNode()
    }
    this.clear()
  }

  @action
  public deleteTextNode = () => {
    this.clear()
  }

  private addNode = () => {
    this.probeVM.probeEvents.emit([
      {
        type: 'add_node',
        data: {
          strategy: 'text',
          key: this.temporaryTextNode?.key,
          data: this.temporaryTextNode?.data,
        },
      },
    ])
  }

  public updateServerNodeDataByKey = (key: string) => {
    const node = this.probeVM.probeState.nodes.get(key) as TextProbeNode
    const nodeDataPayload = this.getNodeDataPayload(key, node.data)
    const nodePositionPayload = this.getNodePositionPayload(key, node.position)
    this.handleUpdateStrategy(
      'update_node',
      true,
      nodeDataPayload,
      nodePositionPayload
    )
  }

  public updateServerNodeData = (
    strategy: 'update_node' | 'update_position' | 'all',
    saveToSnapshot: boolean
  ) => {
    const { key, position, data } = this.temporaryTextNode || {}
    if (!data?.text || this.isNewNode) return

    const nodeDataPayload = this.getNodeDataPayload(key, data)
    const nodePositionPayload = this.getNodePositionPayload(key, position)

    this.handleUpdateStrategy(
      strategy,
      saveToSnapshot,
      nodeDataPayload,
      nodePositionPayload
    )
  }

  private getNodeDataPayload = (key: string, data: TextProbeNode['data']) => {
    return {
      type: 'update_node',
      key,
      data: {
        type: data.nodeType,
        nodeData: {
          fontSize: data.fontSize,
          nodeType: data.nodeType,
          text: data.text,
          height: data.height,
          width: data.width,
          scale: data.scale,
        },
      },
    } as const
  }

  private getNodePositionPayload = (key: string, position: Position) => {
    return {
      type: 'update_position',
      key,
      data: {
        position,
      },
    } as const
  }

  private handleUpdateStrategy = (
    strategy: 'update_node' | 'update_position' | 'all',
    saveToSnapshot: boolean,
    nodeDataPayload: ServerUpdateNodeReceive,
    nodePositionPayload: ServerUpdatePositionNodeReceive
  ) => {
    const updateNode = (optimistic = true) =>
      this.emitUpdate(nodeDataPayload, optimistic)
    const updatePosition = (optimistic = true) =>
      this.emitUpdate(nodePositionPayload, optimistic)

    if (saveToSnapshot) {
      this.probeVM.history.push(
        entityDataToSnapshot.eventToSnapshot(
          strategy === 'all'
            ? [nodeDataPayload, nodePositionPayload]
            : [
                strategy === 'update_node'
                  ? nodeDataPayload
                  : nodePositionPayload,
              ]
        )
      )
    }

    switch (strategy) {
      case 'update_node':
        return updateNode()
      case 'update_position':
        return updatePosition()
      case 'all':
        updateNode()
        updatePosition()
    }
  }

  private emitUpdate = throttle(
    (update: GraphEmitEvents, optimistic = true) => {
      this.probeVM.probeEvents.emit([update], { optimistic })
    },
    200,
    { leading: true, trailing: true }
  )

  public syncProbeStateTextNode = () => {
    if (this.probeTextNode && this.temporaryTextNode) {
      this.probeTextNode.setData(this.temporaryTextNode.data)
      this.probeTextNode.moveTo(this.temporaryTextNode.position)
    }
  }

  public get positionOnCanvas() {
    return this.probeVM.app?.toGlobalCoordinates(
      this.temporaryTextNode?.position || { x: 0, y: 0 }
    )
  }

  @action
  public clear = () => {
    this.isNewNode = false
    this.isPositionTextOnCanvasInProgress = false
    this.isActive = false
    this.probeTextNode = null
    this.temporaryTextNode = null
  }
}
