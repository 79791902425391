import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { KeyValue } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { TagDeprecated } from '@clain/core/ui-kit'
import { useFormatDate } from '../../hooks'
import { useFormatMoney } from '@clain/core/utils/format'
import { getBlockSlug } from '@clain/core/utils/slug'

import styles from './index.scss'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { CoinTypeUTXO } from '../../types/coin'
import { RawBlockTransactionUTXO } from '../../apiServices'
import { fromUnixTime } from 'date-fns'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

export interface TransactionDetailsBtcProps {
  transaction: RawBlockTransactionUTXO
  currency: 'src' | 'usd'
  loading: boolean
  type?: CoinTypeUTXO
}

const TransactionDetailsUtxo = observer(
  ({
    transaction,
    currency,
    loading,
    type = 'btc',
  }: TransactionDetailsBtcProps) => {
    const { t } = useTranslation()
    const formatMoney = useFormatMoney()
    const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
    const formatDate = useFormatDate()

    return (
      <KeyValue
        className={cx('transactionTable')}
        data={transaction}
        loading={loading}
      >
        {[
          {
            key: t('platform:size'),
            value: ({ size }) => size,
          },
          {
            key: t('platform:block'),
            value: ({ block, mem_pool }) => {
              if (mem_pool) {
                return <TagDeprecated>{t('platform:mempool')}</TagDeprecated>
              }

              return (
                <Link to={`/${type}/explorer/block/${block}`}>
                  {getBlockSlug(block)}
                </Link>
              )
            },
          },
          {
            key: t('platform:confirmations'),
            value: ({ confirmations }) => formatNumber(confirmations, 0),
          },
          {
            key: t('platform:time'),
            value: ({ time, created_at }) => {
              if (!time) {
                return formatDate(new Date(created_at))
              }

              return formatDate(fromUnixTime(time))
            },
          },
          {
            key: t('platform:totalIn'),
            value: ({ total_in_usd, total_in }) =>
              formatMoney({
                value: currency === 'usd' ? total_in_usd : total_in,
                currency: currency === 'src' ? type : currency,
              }),
          },
          {
            key: t('platform:totalOut'),
            value: ({ total_out_usd, total_out }) =>
              formatMoney({
                value: currency === 'usd' ? total_out_usd : total_out,
                currency: currency === 'src' ? type : currency,
              }),
          },
          {
            key: t('platform:fee'),
            value: ({ fee_usd, fee }) =>
              formatMoney({
                value: currency === 'usd' ? fee_usd : fee,
                currency: currency === 'src' ? type : currency,
              }),
          },
        ]}
      </KeyValue>
    )
  }
)

export default TransactionDetailsUtxo
