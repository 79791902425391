import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IAddedEntities } from '../AddedEntities'
import type { IAssociateEntity } from '../AssociateEntity'
import type { IPositioningEntities } from '../PositioningEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventAddress, IEntitiesMainState, ServerAddEvents } from '../../types'
import { addressKey, clusterKey } from '../../utils'

@injectable()
export class GenerateNodeAddress extends GenerateNode<EventAddress> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.AssociateEntity)
    private associateEntity: IAssociateEntity,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventAddress>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const { currency } = data

    const key = addressKey({ address: data.address, currency })
    const position = this.positioningEntities.run('address')
    const cluster = this.probeState.getSelectedNodesDataByType(
      'data.nodeType',
      ['cluster']
    )

    const clusterId = data?.clusterId || cluster?.[0]?.data?.clusterId

    const nodes = this.nodes({ meta })

    if (!this.isNodeExists(key)) {
      nodes.push({
        type: 'add_node',
        key,
        data: {
          id: data.id,
          position,
          currency: data.currency,
          type: 'address',
        },
      })

      if (clusterId) {
        nodes.push(
          ...this.associateEntity.addressWithExistingCluster(
            key,
            clusterKey({ clusterId }, data.currency)
          )
        )
      }

      nodes.push(
        ...this.associateEntity.addressWithExistingTransactionAddress(
          key,
          data.id
        )
      )
    }

    return nodes.acc
  }
}
