import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Header } from '@clain/core/Layout'
import { useNavigate, generatePath, useMatch } from '@clain/core/Router/router'
import { Tabs, TabPropsOption } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

type Page =
  | 'global'
  | 'rules'
  | 'team'
  | 'notifications'
  | 'api'
  | 'profile'
  | 'reset'

const SettingsHeader: React.FC = () => {
  const { t } = useTranslation()
  const match = useMatch('/settings/:page')
  const page = match?.params?.page as Page
  const navigate = useNavigate()

  const tabs: TabPropsOption<string>[] = [
    {
      value: 'global',
      children: t('platform:generalSettings'),
    },
    {
      value: 'team',
      children: t('platform:teamSetup'),
    },
    {
      value: 'api',
      children: t('platform:settingsApi'),
    },
    {
      value: 'profile',
      children: t('platform:profile'),
    },
  ]

  const onTabChange = (page: Page) => {
    navigate(generatePath('/settings/:page', { page }))
  }

  return (
    <Header iconVariant="Settings" title={t('platform:settings')}>
      <Tabs
        className={cx('SettingsTabs')}
        options={tabs}
        value={page}
        onChange={onTabChange}
      />
    </Header>
  )
}

export default SettingsHeader
