import classnames from 'classnames/bind'
import { addDays } from 'date-fns'
import { Typography } from '@clain/core/ui-kit'
import { ScoreDot } from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'

import styles from './netflow.scss'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export const NetflowTooltip = ({
  points,
  groupBy,
  formatOptions,
  formatDate,
}) => {
  const { t } = useTranslation()

  const balance = points.filter((p) => p.seriesName === 'Balance')[0]
    ?.value?.[1]

  const startDate = points?.[0].value?.[0]

  const inflowPoints = points
    .filter((p) => p.seriesName !== 'Balance' && p.value?.[1] >= 0)
    .sort((a, b) => b.value[1] - a.value[1])
  const outflowPoints = points
    .filter((p) => p.seriesName !== 'Balance' && p.value?.[1] < 0)
    .sort((a, b) => Math.abs(b.value[1]) - Math.abs(a.value[1]))

  const inflowTotal = inflowPoints.reduce((acc, p) => acc + p.value?.[1], 0)
  const outflowTotal = outflowPoints.reduce((acc, p) => acc - p.value?.[1], 0)

  const inflowShown = inflowPoints.slice(0, 3)
  const outflowShown = outflowPoints.slice(0, 3)

  const inflowOther = inflowPoints.slice(3)
  const outflowOther = outflowPoints.slice(3)

  const inflowOtherSum = inflowOther.reduce((acc, p) => acc + p.value?.[1], 0)
  const outflowOtherSum = outflowOther.reduce((acc, p) => acc - p.value?.[1], 0)

  return (
    <div className={cx('NetflowTooltip')}>
      <div className={cx('inflow')}>
        <Typography variant="body3">
          {t('platform:incoming')}:{' '}
          {formatMoney({ value: inflowTotal, ...formatOptions })}
        </Typography>
        <ul>
          {inflowShown.map((p) => (
            <li key={p.seriesName}>
              <ScoreDot className={cx('score')} value={p.value[2]} />{' '}
              {p.seriesName}:{' '}
              {formatMoney({ value: p.value?.[1], ...formatOptions })}
            </li>
          ))}
          {inflowOther.length ? (
            <li>
              <ScoreDot className={cx('score')} /> {t('platform:other')} (
              {inflowOther.length}):{' '}
              {formatMoney({ value: inflowOtherSum, ...formatOptions })}
            </li>
          ) : null}
        </ul>
      </div>
      {typeof balance !== 'undefined' && (
        <div className={cx('balance')}>
          <Typography variant="body3">
            {t('platform:balance')}:{' '}
            {formatMoney({ value: balance, ...formatOptions })}
          </Typography>
          {groupBy === 'week' ? (
            <div>
              {formatDate(startDate, 'date')} —{' '}
              {formatDate(addDays(startDate, 6), 'date')}
            </div>
          ) : (
            <div>{formatDate(startDate, 'date')}</div>
          )}
        </div>
      )}
      <div className={cx('outflow')}>
        <Typography variant="body3">
          {t('platform:outgoing')}:{' '}
          {formatMoney({ value: outflowTotal, ...formatOptions })}
        </Typography>
        <ul>
          {outflowShown.map((p) => (
            <li key={p.seriesName}>
              <ScoreDot className={cx('score')} value={p.value[2]} />{' '}
              {p.seriesName}:{' '}
              {formatMoney({ value: Math.abs(p.value?.[1]), ...formatOptions })}
            </li>
          ))}
          {outflowOther.length ? (
            <li>
              <ScoreDot className={cx('score')} /> {t('platform:other')} (
              {outflowOther.length}
              ): {formatMoney({ value: outflowOtherSum, ...formatOptions })}
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  )
}
