import { action, makeObservable, computed } from 'mobx'

import { CasesService } from '../../services'
import { CasesState } from '../../states'
import { NewCase } from '../../types'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

type CasesHeaderViewModelProps = [
  {
    casesCtx: {
      casesState: CasesState
      casesService: CasesService
    }
  },
  NavigateFunction,
]

export class CasesHeaderViewModel {
  @computed public get filters() {
    return this.casesState.filters
  }

  @computed public get counters() {
    return this.casesState.cases?.counters
  }

  private casesState: CasesState
  private casesService: CasesService
  private navigate: (route: string) => void

  public constructor([
    {
      casesCtx: { casesState, casesService },
    },
    navigate,
  ]: CasesHeaderViewModelProps) {
    this.casesState = casesState
    this.casesService = casesService
    this.navigate = navigate
    makeObservable(this)
  }

  @action.bound
  public createCase = async (newCaseData: NewCase) => {
    const { id } = await this.casesService.create(newCaseData)
    if (id) {
      this.navigate(`/cases/${id}`)
    }
  }
}
