import { BaseLink } from '@clain/core/Link'
import {
  EllipsisTypography,
  IconButton,
  Stack,
  Tooltip,
  CopyContainer,
  BlockchainIcon,
} from '@clain/core/ui-kit'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'
import * as S from './EntityHash.styles'
import { EntityHashProps } from './EntityHash.types'

export const TransactionLink: React.FC<EntityHashProps> = ({
  hash,
  currency,
  isUnsupportedAddress,
}) => {
  return (
    <Tooltip content={hash}>
      <div style={{ width: '100%', display: 'flex' }}>
        {isUnsupportedAddress ? (
          <EllipsisTypography
            variant="body200NormalCode"
            color="onBackgroundBase"
            type="center"
            key={hash}
          >
            {hash}
          </EllipsisTypography>
        ) : (
          <BaseLink
            to="/:currency/explorer/transaction/:hash"
            params={{ hash, currency }}
            target="_blank"
            as={(props) => (
              <EllipsisTypography
                {...props}
                as="a"
                variant="body200NormalCode"
                color="onBackgroundBase"
                type="center"
                key={hash}
              >
                {hash}
              </EllipsisTypography>
            )}
          />
        )}
      </div>
    </Tooltip>
  )
}

export const AddressLink: React.FC<EntityHashProps> = ({
  hash,
  currency,
  isUnsupportedAddress,
}) => {
  return (
    <Tooltip content={hash}>
      <div style={{ width: '100%', display: 'flex' }}>
        {isUnsupportedAddress ? (
          <EllipsisTypography
            variant="body200NormalCode"
            color="onBackgroundBase"
            type="center"
            key={hash}
          >
            {hash}
          </EllipsisTypography>
        ) : (
          <BaseLink
            to="/:coin/address/:hash"
            params={{ address: hash, coin: currency }}
            target="_blank"
            as={(props) => (
              <EllipsisTypography
                {...props}
                as="a"
                variant="body200NormalCode"
                color="onBackgroundBase"
                type="center"
                key={hash}
              >
                {hash}
              </EllipsisTypography>
            )}
          />
        )}
      </div>
    </Tooltip>
  )
}

export const EntityHash: React.FC<EntityHashProps> = ({
  linkComponent: LinkComponent,
  enabledIcon = true,
  ...rest
}) => {
  return (
    <Stack gap="xs" align="center">
      {enabledIcon && (
        <S.IconContainer>
          <Tooltip content={capitalizeFirstLetter(rest.currency)}>
            <span>
              <BlockchainIcon size="xs" currency={rest.currency} />
            </span>
          </Tooltip>
        </S.IconContainer>
      )}
      <S.RightContainer>
        <S.ContentContainer>
          <S.HashContainer>
            <LinkComponent {...rest} />
          </S.HashContainer>
        </S.ContentContainer>
        <S.MoreContainer>
          <CopyContainer value={rest.hash}>
            <IconButton size="sm" iconVariant="Copy" />
          </CopyContainer>
        </S.MoreContainer>
      </S.RightContainer>
    </Stack>
  )
}
