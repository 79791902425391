import React from 'react'
import { Button, Typography } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'

export const AddMultipleNodesButton = ({ handleResetModalState, show }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="outline"
      color="secondary"
      endIcon={'AddMultiple'}
      onClick={() => {
        handleResetModalState()
        show()
      }}
    >
      <Typography variant="body2-sm">{t('platform:addMultiple')}</Typography>
    </Button>
  )
}
