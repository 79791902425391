import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useClusterFilterData,
  useClusterSearchData,
  useSelectedClusterFilterData,
} from './useClusterFilterData'
import {
  SearchListFilter,
  SearchListFilterMenu,
  ScoreMenuListItem,
} from '../../../../../Filters'
import { useTransactionStaticStore } from '../../context'

export const ClusterFilterMenuContainer = () => {
  const { t } = useTranslation()
  const { menuListData, onSearch } = useClusterSearchData()
  const { selectedItems, setFilter } = useClusterFilterData()
  const counterpartyStaticStore = useTransactionStaticStore()

  const onSelect = useCallback(
    (items: typeof selectedItems) => {
      setFilter(items)
    },
    [setFilter]
  )

  return (
    <SearchListFilterMenu
      onSelect={onSelect}
      onSearch={onSearch}
      selectedItems={selectedItems}
      list={menuListData}
      size={counterpartyStaticStore.current.filterSize}
      searchPlaceholder={t('platform:findCluster')}
      searchInfoText={t('platform:findClusterInfo')}
      renderItem={ScoreMenuListItem}
    />
  )
}

export const ClusterFilterContainer = () => {
  const { t } = useTranslation()
  const { isMultiple, multipleLabel, resetFilter } = useClusterFilterData()
  const firstSelected = useSelectedClusterFilterData()
  const counterpartyStaticStore = useTransactionStaticStore()

  return (
    <SearchListFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      size={counterpartyStaticStore.current.filterSize}
      label={t('platform:counterpartyCluster')}
      icon="ClusterAlt"
      firstSelectedItem={firstSelected}
      clearFilter={resetFilter}
      menuComponent={<ClusterFilterMenuContainer />}
    />
  )
}
