import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames/bind'

import { Typography, Container } from '@clain/core/ui-kit'
import ProbeVM from '../../vm/ProbeViewModel'
import { FiltersProvider } from '../../../ClusterFingerprintFilters/ClusterFingerprintFilters.service'
import { ClusterFingerprintFilters } from '../../../ClusterFingerprintFilters'
import { ClusterFingerprintChart } from '../../../ClusterFingerprintChart'
import { TransactionsFlagsViewModelProvider } from '../../../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'

import styles from './index.scss'

const cx = classnames.bind(styles)

export const ClusterFingerprintBlock = observer(() => {
  const { t } = useTranslation()
  const { activeEntity } = ProbeVM
  if (!activeEntity.analytics.isShowTransactionsByFlags) {
    return null
  }

  return (
    <TransactionsFlagsViewModelProvider
      viewModel={activeEntity.analytics.transactionsFlagsChartViewModel}
    >
      <FiltersProvider>
        <div className={cx('FundsFlowHeader')}>
          <Typography
            variant="heading3"
            color="grey1"
            className={cx('FundsFlowTitle')}
          >
            {t('platform:clusterFingerprint')}
          </Typography>
        </div>
        <Container className={cx('FundsFlowFilters')} gap={[1, 2]}>
          <ClusterFingerprintFilters />
        </Container>
        <Container gap={[0.5, 1]}>
          <ClusterFingerprintChart />
        </Container>
      </FiltersProvider>
    </TransactionsFlagsViewModelProvider>
  )
})

ClusterFingerprintBlock.displayName = 'ClusterFingerprintBlock'
