import React from 'react'

import { FiltersProvider, useFiltersActions } from './AddressesFilters.service'

import { normalizeTrxFilters } from './AddressesFilter.utils'

import { AddressesFilters } from './AddressFilters'
import { useInitFilters } from './AddressesFilters.hooks'
import { useAddressesSearchViewModel } from '@platform/components/BlockchainFilter/context'

export const AddressesFiltersContainer = () => {
  return (
    <FiltersProvider>
      <AddressesFilters />
    </FiltersProvider>
  )
}

export const useSetFilters = () => {
  const {
    resetSelected: resetSelectedTransactions,
    filtersUpdate: transactionsTableFiltersUpdate,
    transactionsInitialFilters,
    transactionsTableDefaultFilters,
  } = useAddressesSearchViewModel()

  useInitFilters(transactionsInitialFilters, transactionsTableDefaultFilters)

  useFiltersActions({
    onSetFilters: (filters) => {
      transactionsTableFiltersUpdate(normalizeTrxFilters(filters))
      resetSelectedTransactions()
    },
  })
}
