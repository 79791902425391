import { action, computed, makeObservable } from 'mobx'
import { equals } from 'ramda'
import * as Sentry from '@sentry/react'
import { SettingsService } from '../services'

import { SettingsState } from '../state'
import type { Severities, Severity } from '../services/SettingsService.types'
import { i18next } from '@platform/i18Manager'
import { LANG_STORAGE_KEY, LANGS_KEYS } from '@platform/constants/languege'

interface SettingsViewModelProps {
  settingsCtx: {
    settingsState: SettingsState
    settingsService: SettingsService
  }
}

export class SettingsViewModel {
  private settingsState: SettingsState
  private settingsService: SettingsService

  public constructor({
    settingsCtx: { settingsState, settingsService },
  }: SettingsViewModelProps) {
    makeObservable(this)
    this.settingsState = settingsState
    this.settingsService = settingsService
  }

  public init = async () => {
    this.settingsService.initChannel().then((settings) => {
      this.settingsState.setSettings(settings)
      if (!LANGS_KEYS.includes(settings.user?.language) && i18next.language) {
        this.updateUserSettings({ language: i18next.language })
      }

      if (
        settings?.user?.language &&
        settings?.user?.language !== i18next.language
      ) {
        localStorage.setItem(
          LANG_STORAGE_KEY,
          JSON.stringify(settings.user.language)
        )
        i18next.changeLanguage(settings.user.language)
      }
    })

    this.settingsService.subscribeSettingsUpdated(
      this.settingsState.setSettings
    )
    const settingsProfile = await this.settingsService.getSettingsProfile()
    Sentry.setUser({
      id: settingsProfile.user.id,
      companyId: settingsProfile.user.companyId,
    })
    this.setSettingsProfile(settingsProfile)
  }

  @computed public get severities(): Severities {
    return this.settingsState.getSeverities
  }

  @computed public get settingsProfile() {
    return this.settingsState.getSettingsProfile
  }

  @computed public get loading() {
    return this.settingsState.getLoading
  }

  @computed public get probeMembers() {
    return this.settingsState.getProbeMembers
  }

  @computed public get allMembers() {
    return this.settingsState.getAllMembers
  }

  @computed public get teamPlan() {
    return this.settingsState.getTeamPlan
  }

  @computed public get isEnabledAddSeverity(): boolean {
    return this.severities.length < 5
  }

  @computed public get isLimitReached(): boolean {
    return this.teamPlan.maxUsers <= this.teamPlan.current
  }

  @computed public get api() {
    return this.settingsState.getApi
  }

  @computed public get userProfile() {
    return this.settingsState.getUserProfile
  }

  @computed public get userSettings() {
    return this.settingsState.getUserSettings
  }

  @action.bound
  private setSettingsProfile = (
    data: typeof this.settingsState.settingsProfile
  ) => {
    this.settingsState.settingsProfile = data
  }
  @action.bound
  public async createSeverity(newSeverity: Omit<Severity, 'id' | 'isDefault'>) {
    const createdSeverity =
      await this.settingsService.createSeverity(newSeverity)

    this.settingsState.putSeverity(createdSeverity)
  }

  @action.bound
  public updateSeverty(updates: Severity) {
    this.settingsService.updateSeverity(updates.id, updates)
    this.settingsState.updateSeverity(updates.id, () => updates)
  }

  @action.bound
  public removeSeverity(id: number) {
    this.settingsService.removeSeverity(id)
    this.settingsState.removeSeverity(id)
  }

  @action.bound
  public async setDefaultSeverity(id: number) {
    const severities = await this.settingsService.setDefaultSeverity(id)

    this.settingsState.setSeverities(severities)
  }

  @action.bound
  public async createTeamMember(
    ...args: Parameters<typeof this.settingsService.createTeamMember>
  ) {
    const createdTeamMember = await this.settingsService.createTeamMember(
      ...args
    )
    this.settingsState.putTeamMember(createdTeamMember)
  }

  @action.bound
  public async enableTeamMember(id: number) {
    const updatedTeamMember = await this.settingsService.enableTeamMember(id)
    this.settingsState.updateTeamMember(id, () => updatedTeamMember)
  }

  @action.bound
  public async disableTeamMember(id: number) {
    const updatedTeamMember = await this.settingsService.disableTeamMember(id)
    this.settingsState.updateTeamMember(id, () => updatedTeamMember)
  }

  @action.bound
  public async generateKeys() {
    const apiKeys = await this.settingsService.generateKeys()
    this.settingsState.setApiKeys(apiKeys)
  }

  @action.bound
  public async whitelistIps(
    ...args: Parameters<typeof this.settingsService.whitelistIps>
  ) {
    await this.settingsService.whitelistIps(...args)
  }

  @action.bound
  public async updateUserProfile(
    ...args: Parameters<typeof this.settingsService.updateUserProfile>
  ) {
    await this.settingsService.updateUserProfile(...args)
  }

  @action.bound
  public async updateUserSettings(
    ...args: Parameters<typeof this.settingsService.updateUserSettings>
  ) {
    const updatingSettings = args[0]
    const currentSettings = this.userSettings

    if (!equals(updatingSettings, currentSettings)) {
      this.settingsState.setUserSettings(...args)
      await this.settingsService.updateUserSettings(...args)
    }
  }

  @action.bound
  public async changePassword(
    ...args: Parameters<typeof this.settingsService.changePassword>
  ) {
    await this.settingsService.changePassword(...args)
  }

  public clear() {
    this.settingsService.clear()
  }
}
