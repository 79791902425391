import { useTranslation } from 'react-i18next'
import { CounterTabOption } from '@clain/core/ui-kit'
import { EntitiesTableTabTypes } from '../../../EntitiesTable/EntitiesTable.types'
import { useMemo } from 'react'

export const useTableHeaderTabs = ({
  transactionsCounterValue,
  counterpartiesCounterValue,
  osintCounterValue,
}: {
  transactionsCounterValue?: number
  counterpartiesCounterValue?: number
  osintCounterValue?: number
} = {}) => {
  const { t } = useTranslation()

  return useMemo(() => {
    const tabs: Array<CounterTabOption<EntitiesTableTabTypes>> = [
      {
        value: 'counterparties',
        children: t('platform:clusterTableCounterparties'),
        counterValue: counterpartiesCounterValue ?? 0,
      },
      {
        value: 'transactions',
        children: t('platform:clusterTableTransactions'),
        counterValue: transactionsCounterValue ?? 0,
      },
      {
        value: 'osint',
        children: t('platform:clusterTableOsint'),
        counterValue: osintCounterValue ?? 0,
      },
    ]

    return tabs
  }, [
    transactionsCounterValue,
    counterpartiesCounterValue,
    osintCounterValue,
    t,
  ])
}
