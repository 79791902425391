import React from 'react'
import ChartLoader from '../ChartLoader'
import { observer } from 'mobx-react-lite'
import Exposure from '@clain/core/Exposure/Exposure'
import { Card } from '@clain/core/ui-kit'
import { useBreakdownViewModel } from '../../modules/analytics/Breakdown/BreakdownData.context'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import { useTranslation } from 'react-i18next'

export const ExposureChart = observer(() => {
  const { t } = useTranslation()
  const { status, exposure } = useBreakdownViewModel()
  return (
    <Card title={t('platform:exposure')}>
      <ErrorBoundaryContainer>
        <ChartLoader loading={status === 'LOADING'}>
          <Exposure
            withTitle={false}
            mode={'default'}
            className={''}
            data={exposure}
            useNewColors
          />
        </ChartLoader>
      </ErrorBoundaryContainer>
    </Card>
  )
})
