import React, { useCallback } from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Typography } from '@clain/core/ui-kit'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import probeVM from '../../vm/ProbeViewModel'

import { ReactComponent as KeyboardIcon } from '@clain/core/assets/keyboard.svg'
import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'
import { ReactComponent as CommentIcon } from '@clain/core/assets/comment.svg'
import { ReactComponent as GridIcon } from '@clain/core/assets/grid.svg'
import { ReactComponent as LayersIcon } from '@clain/core/assets/layers.svg'

import styles from './ShortcutsModal.scss'
import { isMac } from '@clain/core/utils/tools'
import { observer } from 'mobx-react-lite'
import { Icon, Tooltip } from '@clain/core/ui-kit'
import { Hotkey } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

const modButton = isMac() ? '⌘' : 'Ctrl'

export const ShortcutsModal: React.FC = observer(() => {
  const { t } = useTranslation()
  const toggleModal = useCallback((toggle: boolean) => {
    probeVM.setIsShortcutsModalActive(toggle)
  }, [])

  const target: ModalSlot = ({ show }) => {
    return (
      <Tooltip content={tooltipContent}>
        <div className={cx('ButtonContainer')}>
          <KeyboardIcon
            className={cx('Button')}
            onClick={() => toggleModal(true)}
          />
        </div>
      </Tooltip>
    )
  }

  const tooltipContent = (
    <>
      {t('platform:showShortcuts')} <Hotkey>?</Hotkey>
    </>
  )

  const body: ModalSlot = ({ hide }) => (
    <React.Fragment>
      <RowDeprecated align="between">
        <Typography variant="heading3" color="black">
          {t('platform:shortcuts')}
        </Typography>
        <CloseIcon
          className={cx('CloseIcon')}
          onClick={() => toggleModal(false)}
        />
      </RowDeprecated>
      <div className={cx('Delimeter')}></div>
      <RowDeprecated className={cx('HelpContent')}>
        <ColDeprecated className={cx('Items')} gap={1}>
          <Typography
            className={cx('Title')}
            variant="subheading2"
            color="grey1"
          >
            {t('platform:tools')}
          </Typography>
          <div className={cx('RowItem')}>
            <div className={cx('Icon')}>
              <Icon variant="Undo" size="sm" />
            </div>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:undo')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              <Hotkey colors="light">Z</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <div className={cx('Icon')}>
              <Icon variant="Redo" size="sm" />
            </div>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:redo')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              <Hotkey colors="light">Shift</Hotkey> +{' '}
              <Hotkey colors="light">Z</Hotkey> or{' '}
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              <Hotkey colors="light">Y</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <CommentIcon className={cx('Icon')} />
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:comment')}
            </Typography>
            <Hotkey colors="light">
              <Typography variant="body3" color="grey1">
                C
              </Typography>
            </Hotkey>
          </div>
          <div className={cx('RowItem')}>
            <GridIcon className={cx('Icon')} />
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:grid')}
            </Typography>
            <Hotkey colors="light">
              <Typography variant="body3" color="grey1">
                G
              </Typography>
            </Hotkey>
          </div>
          <div className={cx('RowItem')}>
            <LayersIcon className={cx('Icon')} />
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:layers')}
            </Typography>
            <Hotkey colors="light">
              <Typography variant="body3" color="grey1">
                L
              </Typography>
            </Hotkey>
          </div>
          <Typography
            className={cx('Title', 'TitleSecond')}
            variant="subheading2"
            color="grey1"
          >
            {t('platform:canvas')}
          </Typography>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:realignSelectedNodes')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">R</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:moveCanvas')}
            </Typography>
            <Typography variant="body3" color="grey1">
              {t('platform:drag')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:moveNode')}
            </Typography>
            <Typography variant="body3" color="grey1">
              {t('platform:drag')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:selectObject')}
            </Typography>
            <Typography variant="body3" color="grey1">
              {t('platform:leftClick')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:multipleObjectSelection')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              {t('platform:leftClick')} or <Hotkey colors="light">Shift</Hotkey>{' '}
              + {t('platform:leftClick')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:deselectSingle')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> +{' '}
              {t('platform:leftClick')} or <Hotkey colors="light">Shift</Hotkey>{' '}
              + {t('platform:leftClick')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:multiSelect')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">Shift</Hotkey> + {t('platform:drag')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:deselectMultipleObjects')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">{modButton}</Hotkey> + {t('platform:drag')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:zoomInOut')}
            </Typography>
            <Typography variant="body3" color="grey1">
              {t('platform:scrollUpDown')}
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:removeSelection')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">Esc</Hotkey>
            </Typography>
          </div>
          <div className={cx('RowItem')}>
            <Typography className={cx('Label')} variant="body1" color="grey3">
              {t('platform:deleteSelectedObjects')}
            </Typography>
            <Typography variant="body3" color="grey1">
              <Hotkey colors="light">Del</Hotkey>
            </Typography>
          </div>
        </ColDeprecated>
      </RowDeprecated>
    </React.Fragment>
  )

  return (
    <Modal
      target={target}
      className={cx('ShortcutsModal')}
      visible={probeVM.isShortcutsModalActive}
      onRequestClose={() => toggleModal(false)}
    >
      {body}
    </Modal>
  )
})
