import React from 'react'

import { useFilterSlice } from '../Filters.service'
import { ScoreFilter, ScoreFilterMenu } from '@platform/components/Filters'
import { useTransactionAddressStaticStore } from '../../context'
import { useTranslation } from 'react-i18next'

export const OutputScoreFilterMenuContainer = () => {
  const [score, { setFilter }] = useFilterSlice('outputScore.data')
  const transactionStaticStore = useTransactionAddressStaticStore()
  const { t } = useTranslation()
  return (
    <ScoreFilterMenu
      onSelect={setFilter}
      score={score}
      size={transactionStaticStore.current.filterSize}
      title={t('platform:outputScore')}
    />
  )
}

export const OutputScoreFilterContainer = () => {
  const [score] = useFilterSlice('outputScore.data')
  const [, { resetFilter }] = useFilterSlice('outputScore')
  const transactionStaticStore = useTransactionAddressStaticStore()
  const { t } = useTranslation()
  return (
    <ScoreFilter
      score={score}
      size={transactionStaticStore.current.filterSize}
      clearFilter={resetFilter}
      filterLable={t('platform:outputScore')}
      menuComponent={<OutputScoreFilterMenuContainer />}
    />
  )
}
