import { ERROR } from '../../../../constants'
import { i18next } from '@platform/i18Manager'

export const notificationConfig = () =>
  ({
    success: {
      text: i18next.t('platform:reportGeneratedSuccess'),
      type: 'success',
    },
    error: {
      text: ERROR.generateAIReport,
      type: 'warning',
    },
  }) as const

export const notificationToastOptions = {
  position: 'top-right',
  //TODO maybe we should rewrite toaster default right position
  style: { top: 94, marginRight: -2 },
} as const

export function setsHaveSameKeys(set1: Set<string>, set2: Set<string>) {
  if (set1.size !== set2.size) {
    return false
  }

  for (const key of set1) {
    if (!set2.has(key)) {
      return false
    }
  }

  return true
}
