import React from 'react'
import classnames from 'classnames/bind'
import { Typography } from '@clain/core/ui-kit'

import styles from './UsersPresenceList.scss'
import { RowDeprecated } from '@clain/core/ui-kit'

const cx = classnames.bind(styles)

type UserItemProps = {
  name: string
  text?: React.ReactNode
  selected: boolean
  onClick?: () => void
  avatar: React.ReactNode
}

export const UserListItem: React.FC<UserItemProps> = ({
  name,
  text,
  selected,
  onClick,
  avatar,
}) => {
  return (
    <RowDeprecated gap={0.5} className={cx('UserItem', { selected })} onClick={onClick}>
      {avatar}
      <Typography variant={'body1'} color={'black'}>
        {name}
      </Typography>
      {text}
    </RowDeprecated>
  )
}
