import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import { Token } from '../ProbeSandbox/types/converted/TokenBalance'
import { useEffect } from 'react'
import { useGroupByFilter } from '../Filters/GroupByFilter/useGroupByFilter'
import { useAssetFilterDispatchers } from './AssetFilter/useAssetFilterData'
import { useGroupByFilterDispatchers } from './GroupByFilter/useGroupByFilterData'
import { useScoreFilterDispatchers } from './ScoreFilter/useScoreFilterData'
import { useCalendarFilterDispatchers } from './CalendarFilter/useCalendarFilterData'
import { useNetflowViewModel } from '../../modules/analytics/Netflow/NetflowData.context'
import { toJS } from 'mobx'

interface FundsFlowFiltersState {
  asset: {
    data: Token[] | null
    condition: Extract<ComparatorsValue, 'is'>
  }
  groupBy: {
    data: ReturnType<typeof useGroupByFilter>['groupByOptions'][number]['value']
  }
  score: { data: [number, number]; condition: Extract<ComparatorsValue, 'is'> }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
} = filtersContextFactory<FundsFlowFiltersState>({
  groupBy: { data: null },
  asset: { data: null, condition: comparators.is.value },
  score: { data: null, condition: comparators.is.value },
  calendar: { data: null, condition: comparators.is.value },
})

export const useInitFilters = () => {
  const { setFilters: setLocalFilters } = useFiltersActions()
  const { groupByFilter, calendarFilter, assetFilter, scoreFilter } =
    useNetflowViewModel()

  useAssetFilterDispatchers()
  useGroupByFilterDispatchers()
  useScoreFilterDispatchers()
  useCalendarFilterDispatchers()
  const initialAssetId = assetFilter.id

  useEffect(() => {
    if (
      groupByFilter &&
      calendarFilter &&
      initialAssetId != null &&
      scoreFilter
    ) {
      setLocalFilters({
        groupBy: {
          data: groupByFilter,
        },
        asset: {
          data: [toJS(assetFilter)],
          condition: comparators.is.value,
        },
        score: { data: [...scoreFilter], condition: comparators.is.value },
        calendar: {
          data: [...calendarFilter],
          condition: comparators.is.value,
        },
      })
    }
  }, [calendarFilter, groupByFilter, initialAssetId, scoreFilter])
}
