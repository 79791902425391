import { useFilterSlice } from '../FundsFlowFilters.service'
import { useGetFilterLabels } from '@clain/core/ui-kit'
import { useEffect } from 'react'
import { useNetflowViewModel } from '../../../modules/analytics/Netflow/NetflowData.context'
import { useTranslation } from 'react-i18next'

export const useAssetFilterData = () => {
  const { t } = useTranslation()
  const { assets } = useNetflowViewModel()
  const [data, { setFilter }] = useFilterSlice('asset.data')
  const menuListData = assets

  const [condition] = useFilterSlice('asset.condition')
  const { isMultiple, multipleLabel } = useGetFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: t('platform:asset_other'),
  })

  return {
    isMultiple,
    multipleLabel,
    selectedAssets: data,
    menuListData,
    setFilter,
  }
}

export const useAssetFilterDispatchers = () => {
  const [selectedAssets] = useFilterSlice('asset.data')

  const { updateFilters } = useNetflowViewModel()
  const selectedAsset = selectedAssets?.[0]
  const dispatchAssetChanges = (argAsset: typeof selectedAssets) => {
    if (!argAsset) {
      return
    }
    updateFilters({ asset: argAsset[0] })
  }

  useEffect(() => {
    if (selectedAsset?.id != null) {
      dispatchAssetChanges([selectedAsset])
    }
  }, [selectedAsset?.id])
}

export const useSelectedAssetData = () => {
  const { selectedAssets, menuListData } = useAssetFilterData()

  return selectedAssets?.length
    ? menuListData.find((token) => selectedAssets[0].id === token.id)
    : null
}
