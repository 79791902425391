import { createContext, useContext, createElement, useCallback } from 'react'
import { bignumber, isZero, abs } from 'mathjs'
import warning from 'warning'

import { moveDecimalPoint, gte } from './math'
import { isCoin, getCurrencySymbol } from './currency'
import defaultLocale from './locale'

export const formatNumber = (value: any, precision = 2) => {
  return bignumber(value)
    .toNumber()
    .toLocaleString(defaultLocale, {
      minimumFractionDigits: isZero(value) ? 0 : precision,
      maximumFractionDigits: isZero(value) ? 0 : precision,
    })
}

// symbolNumberFormatter(1e3, 'k', 15000) => 15k
const symbolNumberFormatter = (
  // можно считать это классом числа
  base: number,
  symbol: string,
  value: number,
  precision?: number
): string => {
  const sign = value >= 0 ? '' : '-'
  const val = Math.abs(value) / base
  // десятичная часть
  const decimalPart = val - Math.floor(val)
  const autoPrecision = decimalPart > 0 ? 1 : 0
  const resultPrecision = precision || autoPrecision

  if (Math.abs(value) >= base) {
    return `${sign}${val.toFixed(resultPrecision)}${symbol}`
  }

  return String(value)
}

export const formatNumberShort = ({
  value,
  precision = 0,
  shortPrecision = 0,
  pointer = '.',
}: {
  value: number
  precision?: number
  shortPrecision?: number
  pointer?: '.' | ','
}) => {
  const SHORT_PRECISION = 1e3

  const symbols = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Q' },
    { value: 1e18, symbol: 'E' },
    { value: 1e21, symbol: 'Z' },
    { value: 1e24, symbol: 'Y' },
  ].reverse()

  for (const s of symbols) {
    if (Math.abs(value) >= s.value) {
      const result = symbolNumberFormatter(
        s.value,
        s.symbol,
        value,
        Math.abs(value) >= SHORT_PRECISION ? shortPrecision : precision
      )

      if (pointer !== '.') {
        return result.replace('.', pointer)
      }
      return result
    }
  }

  const normalizedValue =
    value > 0 && precision > 0 ? value.toFixed(precision) : value

  if (pointer !== '.') {
    return normalizedValue.toString().replace('.', pointer)
  }

  return normalizedValue.toString()
}

export const formatDifficulty = (value: any) => {
  const result = moveDecimalPoint(value, -12) // Tera (TH) 10^12

  return `${formatNumber(result, 3)} TH`
}

export const formatAmount = ({ value, currency, precision }: any) => {
  if (isCoin(currency)) {
    return `${formatNumber(value, precision || 4)} ${currency.toUpperCase()}`
  }

  return `${getCurrencySymbol(currency)}${formatNumber(
    value,
    precision || 2
  )} ${currency.toUpperCase()}`
}

const MIN_VALUE_BEFORE_SHORTING = 999_999

export const formatNumberProgressive = (value: number) => {
  return value <= MIN_VALUE_BEFORE_SHORTING
    ? formatNumber(value, 0)
    : formatNumberShort({ value: Number(value) })
}

/*
type CurrencyFormat = {
  code: string,
  decimals: number,
  precision: number,
  symbol: string,
}
*/

const baseFormat = {
  code: '',
  decimals: 0,
  precision: 4,
  symbol: '',
}

export const FORMATS_WITHOUT_DECIMALS = [
  {
    code: 'btc',
    decimals: 0,
    precision: 4,
    symbol: '',
  },
  {
    code: 'trx',
    decimals: 0,
    precision: 6,
    symbol: '',
  },
  {
    code: 'eth',
    decimals: 0,
    precision: 4,
    symbol: '',
  },
  {
    code: 'bnb',
    decimals: 0,
    precision: 4,
    symbol: '',
  },
  {
    code: 'doge',
    decimals: 0,
    precision: 4,
    symbol: '',
  },
  {
    code: 'ltc',
    decimals: 0,
    precision: 4,
    symbol: '',
  },
  {
    code: 'usd',
    decimals: 0,
    precision: 2,
    symbol: '$',
  },
  {
    code: 'sat',
    decimals: 0,
    precision: 0,
    symbol: '',
  },
]

const defaultFormats = [
  {
    code: 'btc',
    decimals: 8,
    precision: 8,
    symbol: '',
  },
  {
    code: 'trx',
    decimals: 6,
    precision: 6,
    symbol: '',
  },
  {
    code: 'eth',
    decimals: 18,
    precision: 3,
    symbol: '',
  },
  {
    code: 'bnb',
    decimals: 18,
    precision: 3,
    symbol: '',
  },
  {
    code: 'doge',
    decimals: 8,
    precision: 4,
    symbol: '',
  },
  {
    code: 'ltc',
    decimals: 8,
    precision: 8,
    symbol: '',
  },
  {
    code: 'usd',
    decimals: 0,
    precision: 2,
    symbol: '$',
  },
  {
    code: 'sat',
    decimals: 0,
    precision: 0,
    symbol: '',
  },
]

export const GENERAL_NUMBER_NOTATION = [
  {
    code: 'btc',
    decimals: 8,
    precision: 8,
    symbol: '',
  },
  {
    code: 'trx',
    decimals: 6,
    precision: 6,
    symbol: '',
  },
  {
    code: 'eth',
    decimals: 18,
    precision: 3,
    symbol: '',
  },
  {
    code: 'bnb',
    decimals: 18,
    precision: 3,
    symbol: '',
  },
  {
    code: 'doge',
    decimals: 8,
    precision: 4,
    symbol: '',
  },
  {
    code: 'ltc',
    decimals: 8,
    precision: 8,
    symbol: '',
  },
  {
    code: 'usd',
    decimals: 0,
    precision: 2,
    symbol: '',
  },
  {
    code: 'sat',
    decimals: 0,
    precision: 0,
    symbol: '',
  },
]

const resolveFormat = (defaults: any, formats: any, currency: any) => {
  const code = isPrimitive(currency) ? currency : currency.code

  const format = formats.reduce((resultFormat: any, formatItem: any) => {
    if (formatItem.code === code) {
      resultFormat = { ...resultFormat, ...formatItem }
    }

    return resultFormat
  }, defaults)

  const currentFormat = isPrimitive(currency) ? { code: currency } : currency

  return {
    ...format,
    ...currentFormat,
  }
}

const formatPresets = {
  default: {
    formats: defaultFormats,
    // Базовый формат для всех
    defaults: baseFormat,
    locale: defaultLocale,
  },
  short: {
    formats: defaultFormats.map((f) => ({ ...f, shortPrecision: 2 })),
    // Базовый формат для всех
    defaults: {
      precision: 2,
      shortPrecision: 2,
    },
    locale: defaultLocale,
  },
}

type FormatPresetName = keyof typeof formatPresets

export interface Format {
  code: string
  decimals: number
  precision: number
  symbol: string
}

export interface ShortFormat extends Format {
  shortPrecision?: number
}

interface CreateFormatPresetConfig<TFormat> {
  formats: TFormat[]
  defaults: Partial<TFormat>
  locale: string
  pointer?: '.' | ','
}

export type FormatPresetConfig = CreateFormatPresetConfig<Format>

export type ShortFormatPresetConfig = CreateFormatPresetConfig<ShortFormat>

interface FormatOpts extends FormatPresetConfig {
  value: any
  currency: string
  position: 'start' | 'end'
  spaces: boolean
}

const resolveFormatPreset = <
  TFormat extends FormatPresetConfig | ShortFormatPresetConfig,
>(
  type: FormatPresetName,
  preset?: Partial<TFormat>
) => {
  if (!preset) {
    return formatPresets[type]
  }

  const normalizedPreset = preset

  if (type === 'short') {
    normalizedPreset.formats.map((f) => ({ shortPrecision: 2, ...f }))
  }

  return {
    ...formatPresets[type],
    ...normalizedPreset,
  } as TFormat
}

export const moneyPosition = ({
  position = 'end',
  spaces = true,
  value,
  symbol,
  code,
  sign,
  pointer = '.',
}: Pick<FormatOpts, 'position' | 'spaces' | 'value' | 'pointer'> &
  Pick<Format, 'code' | 'symbol'> & { sign?: string }) => {
  const formattedValue = pointer === '.' ? value : value.replace('.', pointer)
  return position === 'end'
    ? `${sign}${symbol}${formattedValue}${spaces ? ' ' : ''}${code}`
    : `${code}${spaces ? ' ' : ''}${sign}${symbol}${formattedValue}`
}

export const createFormatMoneyShort = (
  preset?: Partial<ShortFormatPresetConfig>
) => {
  const config = resolveFormatPreset('short', preset)

  return ({
    value,
    currency,
    formats = config.formats,
    defaults = config.defaults,
    position = 'end',
    spaces = true,
    pointer,
    ...props
  }: Partial<FormatOpts & ShortFormat>) => {
    const sign = gte(value, 0) ? '' : '-'
    const format = resolveFormat(defaults, formats, currency)
    const code = props.code ?? format.code?.toUpperCase() ?? ''
    const symbol = props.symbol ?? format.symbol ?? ''
    const decimals = props.decimals ?? format.decimals
    const precision = props.precision ?? format.precision
    const shortPrecision = props.shortPrecision ?? format.shortPrecision

    const normalizedValue = moveDecimalPoint(value, -decimals)

    const numberValue = abs(normalizedValue).toNumber()
    const formatedMoney = formatNumberShort({
      value: numberValue,
      precision,
      shortPrecision,
    })
    return moneyPosition({
      sign,
      position,
      spaces,
      symbol,
      code,
      pointer,
      value: formatedMoney,
    })
  }
}

export function createFormatMoney(preset?: Partial<FormatPresetConfig>) {
  const config = resolveFormatPreset('default', preset)

  return function _formatMoney({
    value,
    currency,
    formats = config.formats,
    // Базовый формат для всех
    defaults = config.defaults,
    locale = config.locale,
    significant = false,
    minimumSignificantDigits,
    maximumSignificantDigits,
    position = 'end',
    spaces = true,
    ...props
  }: Partial<
    FormatOpts &
      Format & {
        significant?: number | boolean
        minimumSignificantDigits?: number
        maximumSignificantDigits?: number
      }
  >) {
    if (!value && value !== 0) {
      warning(value, 'Warning: formatMoney received empty value')
      return ''
    }

    const format = resolveFormat(defaults, formats, currency)

    const code = props.code ?? format.code?.toUpperCase() ?? ''
    const decimals = props.decimals ?? format.decimals
    const precision = props.precision ?? format.precision
    const symbol = props.symbol ?? format.symbol ?? ''

    const valuePrecision = isZero(value) ? 0 : precision
    const normalizedValue = moveDecimalPoint(value, -decimals)

    const resultPrecision =
      significant && typeof significant === 'number'
        ? significant
        : valuePrecision
    const resultValue = normalizedValue

    const sign = gte(value, 0) ? '' : '-'

    const numberValue = abs(resultValue).toNumber()
    const valueLength =
      numberValue > 1 ? Math.round(numberValue).toFixed().length : 0

    let minimumFractionDigits = resultPrecision
    let maximumFractionDigits = resultPrecision

    if (significant) {
      if (valueLength <= resultPrecision) {
        maximumFractionDigits = maximumFractionDigits - valueLength
        minimumFractionDigits = minimumFractionDigits - valueLength
      }
      if (valueLength > resultPrecision) {
        maximumFractionDigits = 0
        minimumFractionDigits = 0
      }
    }

    const val = abs(resultValue).toNumber().toLocaleString(locale, {
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
    })

    return moneyPosition({ sign, position, spaces, symbol, code, value: val })
  }
}

export const formatMoney = createFormatMoney()
export const formatMoneyShort = createFormatMoneyShort()

export const formatMoneyByType = ({
  isShort = false,
  ...params
}: Partial<FormatPresetConfig> & { isShort?: boolean }) => {
  return (opts: Parameters<typeof formatMoney>[0]) => {
    if (isShort) {
      return formatMoneyShort({ ...params, ...opts, shortPrecision: 2 })
    }

    return formatMoney({ ...params, ...opts })
  }
}

export const formatNumberByType = (isShort: boolean) => {
  const MIN_VALUE_BEFORE_SHORTING = 9_999

  return (value: number, precision?: number) => {
    if (isShort && value >= MIN_VALUE_BEFORE_SHORTING) {
      return formatNumberShort({ value, precision: 2 })
    }

    return formatNumber(value, precision)
  }
}

function isPrimitive(val: any) {
  if (typeof val === 'object') {
    return val === null
  }
  return typeof val !== 'function'
}

const FormatContext = createContext({
  formats: [],
  defaults: {},
})

export const FormatProvider = ({
  decimals,
  precision,
  symbol,
  formats: f = defaultFormats,
  defaults: d = baseFormat,
  children,
}: any) => {
  // Контексты можно "наследовать" и доопределять
  const parent = useContext(FormatContext)

  let formats = parent.formats ? [...parent.formats, ...f] : f
  const defaults = parent.defaults ? { ...parent.defaults, ...d } : d

  const overrides = {
    decimals,
    precision,
    symbol,
  }

  Object.keys(overrides).forEach((key) => {
    if (overrides[key]) {
      Object.keys(overrides[key]).forEach((code) => {
        formats = [
          ...formats,
          {
            code,
            [key]: overrides[key][code],
          },
        ]
      })
    }
  })

  const value = {
    formats,
    defaults,
  }

  // eslint-disable-next-line react/no-children-prop
  return createElement(FormatContext.Provider, { value, children })
}

export const useFormatMoney = (
  defOpts = {
    formats: [],
    defaults: {},
  }
) => {
  const ctxOpts = useContext(FormatContext)

  const formats = [...ctxOpts.formats, ...defOpts.formats]
  const defaults = { ...ctxOpts.defaults, ...defOpts.defaults }

  return useCallback(
    (opts) => formatMoney({ defaults, formats, ...opts }),
    [ctxOpts]
  )
}

export const formatPercent = (value, precision = 1) => {
  if (!value) {
    return ''
  }
  return (
    value.toLocaleString(defaultLocale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }) + '%'
  )
}

const getBaseLog = (x, y) => Math.floor(Math.log(x) / Math.log(y))

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = getBaseLog(bytes, k)

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

type FormatConfig = {
  code: string
  decimals: number
  precision: number
  symbol: string
}

export const getAmountNumber = (
  value: number,
  code: string,
  formatConfig: FormatConfig[] = defaultFormats
) => {
  const format = formatConfig.find((f) => f.code === code)

  const factor = Math.pow(10, format.decimals)
  const amount = value / factor

  return parseFloat(amount.toFixed(format.precision))
}
