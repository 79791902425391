import React from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { ReactComponent as TrashIcon } from '@clain/core/assets/trash.svg'
import {
  RowDeprecated,
  ColDeprecated,
  Typography,
  ConfirmButton,
} from '@clain/core/ui-kit'
import type { ConfirmButtonProps } from '@clain/core/ui-kit'

import styles from './AlertInfobarList.scss'
import optionStyles from '../AlertInfobarOptions/AlertInfobarOptions.scss'

const cx = classNames.bind(styles)
const cxOption = classNames.bind(optionStyles)

export const AlertInfobarConfirm: React.FC<
  Pick<ConfirmButtonProps, 'onClick'> & { tag: string }
> = ({ onClick, tag }) => {
  const { t } = useTranslation()

  return (
    <ConfirmButton
      as="span"
      onClick={onClick}
      className={cxOption('OptionSection')}
      confirmVariant="delete"
      title={
        <Typography variant="heading5" color="black">
          {t('platform:deleteAlert')}
        </Typography>
      }
      content={
        <Typography variant="body1" color="black">
          <Typography variant="body3" color="black">
            {`${tag} `}
          </Typography>
          {t('platform:deleteAlertConfirmation')}
        </Typography>
      }
    >
      <div className={cxOption('Delimiter')} />
      <RowDeprecated gap={1} className={cxOption('Option')}>
        <ColDeprecated>
          <TrashIcon className={cx('OptionIcon')} />
        </ColDeprecated>
        <ColDeprecated>
          <Typography variant="body1-sm" color="black">
            {t('platform:delete')}
          </Typography>
        </ColDeprecated>
      </RowDeprecated>
    </ConfirmButton>
  )
}
