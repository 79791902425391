import React, { useCallback } from 'react'
import { useNavigate } from '@clain/core/Router/router'

import Chart from '@clain/core/Chart2'
import { useFormatDate } from '../../hooks'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { useFormatMoneySettings } from '../../hooks/useFormatMoneySettings'
import { useChartOptions } from './dashboard.hooks'
import { DashboardChartProps } from './dashboard.types'
import { generateMockData } from './dashboard.utils'
import { observer } from 'mobx-react-lite'

const DashboardChart = observer(
  ({
    className,
    data,
    topBy,
    coin,
    currency,
    loading,
  }: DashboardChartProps) => {
    const navigate = useNavigate()
    const formatDate = useFormatDate()
    const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
    const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })
    const getOptions = useChartOptions()

    /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
    const mock = React.useMemo(() => {
      return generateMockData()
    }, [])

    const weakState = React.useRef<any>()
    const [ticker, setTicker] = React.useState(false)

    React.useEffect(() => {
      if (data) {
        weakState.current = getOptions({ data: [] })
        setTicker(true)
      }
    }, [data, currency, coin])

    React.useEffect(() => {
      if (ticker && data) {
        weakState.current = getOptions({
          data,
          topBy,
          coin,
          currency,
          formatDate,
          formatNumber,
          formatMoney,
        })
        setTicker(false)
      }
    }, [ticker, data])

    const option = weakState.current || getOptions(mock)

    const onClick = useCallback(
      (event) => {
        const link = event?.data?.link
        if (link) {
          if (event?.event?.event?.ctrlKey || event?.event?.event?.metaKey) {
            window.open(link, '_blank')
          } else {
            navigate(link)
          }
        }
      },
      [navigate]
    )

    const onEvents = React.useMemo(() => {
      return {
        click: onClick,
      }
    }, [onClick])

    return (
      <Chart
        className={className}
        loading={loading}
        stub={!data}
        option={option}
        style={{
          height: '100%',
          width: '100%',
        }}
        onEvents={onEvents}
      />
    )
  }
)

DashboardChart.displayName = 'DashboardChart'

export default DashboardChart
