import {
  IApiServiceStateViewModel,
  IAPIServiceStateFacade,
} from '../mobxUtils.types'

export class APIServiceStateFacade<
  Params extends unknown[],
  Response,
  ERROR = unknown
> implements IAPIServiceStateFacade<Params, Response>
{
  public stateViewModel: IApiServiceStateViewModel<Response, ERROR>
  private requestMethod: (...params: Params) => Promise<Response>

  constructor(state: IApiServiceStateViewModel<Response, ERROR>) {
    this.stateViewModel = state
  }

  public injectRequestMethod = (
    requestMethod: (...params: Params) => Promise<Response>
  ) => {
    this.requestMethod = requestMethod
  }

  public request = async (...params: Params) => {
    this.stateViewModel.setStatus('LOADING')
    try {
      const result = await this.requestMethod(...params)
      this.stateViewModel.updateState(result)
      this.stateViewModel.setStatus('SUCCESS')
      return result
    } catch (e: unknown) {
      this.stateViewModel.setStatus('ERROR')
      this.stateViewModel.setError(e as any)
    }
  }
}
