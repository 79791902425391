import { isEVM } from '@clain/core/types/coin'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { MIXER_ICON } from '../constants/mixer'
import { DemixTrackEvm } from '../types/converted/DemixTrackEvm'
import { DemixTrackTrx } from '../types/converted/DemixTrackTrx'
import { DemixTrackUtxo } from '../types/converted/DimixTrackUtxo'
import { demixKey } from '../utils/key'
import { ProbeViewModel } from './ProbeViewModel'
import { TransactionProbeNodeUtxo } from '../types/entities/TransactionProbeNodeUTXO'

class DemixActionViewModel {
  private static instance: DemixActionViewModel | null = null
  @observable private id: null | string = null
  @observable public isTrackListPopupOpened = false
  @observable private trackListPopupPosition = { x: 0, y: 0 }

  private constructor(private probeVM: ProbeViewModel) {
    makeObservable(this)
  }

  public static getInstance(probeVM: ProbeViewModel) {
    if (!this.instance) {
      this.instance = new DemixActionViewModel(probeVM)
    }
    return this.instance
  }

  @action
  public init = () => {
    this.probeVM.app.on('moved', this.updateTrackListPopupPosition)

    reaction(
      () => this.probeVM.probeState.nodes.get(this.id)?.position,
      (newCanvasRelatedPosition) => {
        if (newCanvasRelatedPosition) {
          this.updateTrackListPopupPosition()
        }
      }
    )
  }

  @action
  private updateTrackListPopupPosition = () => {
    if (this.probeVM.probeState.nodes.has(this.id)) {
      const node = this.probeVM.probeState.nodes.get(this.id)
      this.trackListPopupPosition = this.probeVM.app.toGlobalCoordinates({
        x: node.position.x,
        y:
          node.position.y -
          (this.probeVM.app.graph.getNodeAttributes(node.key).withPills
            ? 50
            : 35),
      })
    }
  }

  @computed public get getTrackListPopupPosition() {
    if (this.id) {
      return this.trackListPopupPosition
    }
    return null
  }

  @computed public get getTrackListData(): Array<
    DemixTrackTrx & { isChecked: boolean; isProcessing: boolean }
  > {
    if (this.id) {
      const node = this.probeVM.probeState.nodes.get(
        this.id
      ) as TransactionProbeNodeUtxo

      //TODO implement real data from backend
      return (
        node?.data?.demixTracks?.map((demixTrack) => ({
          ...demixTrack,
          currency: node.data.currency,
          trackIcon: MIXER_ICON[demixTrack.entityId],
          confidenceLevel: +(demixTrack.confidenceLevel * 100).toFixed(2),
          isChecked: this.probeVM.probeState.nodes.has(demixKey(demixTrack)),
          isProcessing:
            this.probeVM.probeEvents.meta.nodesInProcessing[demixTrack.id],
        })) || []
      )
    }
    return null
  }

  @action
  public onSelectDemixTrack = ({
    data,
    isSelected,
    closeTrackList = true,
  }: {
    data: DemixTrackTrx
    isSelected: boolean
    closeTrackList?: boolean
  }) => {
    if (isSelected) {
      this.probeVM.probeEvents.emit(
        [
          {
            type: 'add_node',
            data: isEVM(data.currency)
              ? {
                  strategy: 'demix',
                  id: data.id,
                  currency: data.currency,
                  transactions: (data as DemixTrackEvm).transactions,
                  sourceNodeKey: this.id,
                }
              : {
                  strategy: 'demix',
                  id: data.id,
                  currency: data.currency,
                  transactions: (data as DemixTrackUtxo).transactions,
                  sourceNodeKey: this.id,
                },
          },
        ],
        {
          animation: true,
          accumulationEntitiesKeyType: 'newAdded',
          animationType: {
            strategy: 'moveToCentroid',
            scaleStrategy: 'auto',
          },
        }
      )
    } else {
      this.probeVM.probeEvents.emit([
        { type: 'delete_node', entity: { key: demixKey(data) } },
      ])
    }
    if (closeTrackList) {
      this.closeDemixTrackListPopup()
    }
  }

  @action
  private reset = () => {
    this.id = null
    this.isTrackListPopupOpened = false
    this.trackListPopupPosition = { x: 0, y: 0 }
  }

  @action
  public openDemixTrackListPopup = (id: typeof this.id) => {
    if (id !== this.id && this.id !== null) {
      this.closeDemixTrackListPopup()
    }
    this.id = id
    this.isTrackListPopupOpened = true
  }

  public closeDemixTrackListPopup = () => {
    this.reset()
  }
}

export default DemixActionViewModel
