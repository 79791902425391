import type { EdgeAttributes } from '@clain/graph'

import ProbeEdge from './ProbeEdge'
import { AttributionEdgeData } from '../../types'

const WIDTH = 2
const COLOR = 'rgba(99, 123, 171, 1)'
const STYLE = 'solid'
const SOURCE_TYPE = 'round'
const TARGET_TYPE = 'round'

export class AttributionEdge<
  T extends AttributionEdgeData = AttributionEdgeData,
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const attributes = {
      width: WIDTH,
      color: COLOR,
      style: STYLE,
      sourceType: SOURCE_TYPE,
      targetType: TARGET_TYPE,
      disabled: this.disabled || !this.layers.osint,
    } as EdgeAttributes<T>

    return attributes
  }
}
