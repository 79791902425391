import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  FiltersButtons,
  FilterDropdownMenuContainerTypes,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useFiltersActions,
  useFiltersOptions,
} from './AddressEvmFilters.service'
import { AssetFilterContainer, AssetFilterMenuContainer } from './AssetFilter'
import {
  CalendarFilterContainer,
  CalendarFilterMenuContainer,
} from './CalendarFilter'
import {
  AddressFilterContainer,
  AddressFilterMenuContainer,
} from './AddressFilter'
import { useAddressStaticStore, useAddressViewModel } from '../context'
import { FilterLayout } from '../../../ui'
import {
  EXLUDE_SORT_FILTERS,
  TABLE_FILTER_MENU_MAX_HEIGHT,
} from '../../../constants'
import { excludeSortFilters } from '../../../utils/excludeSortFilters'
import { useFilterItems } from './AddressEvmFilters.hooks'

const Filters = () => {
  const _filterList = useFilterNameList()
  const { excludeFilters } = useAddressViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])
  const items = useFilterItems()
  const transactionStaticStore = useAddressStaticStore()
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'asset':
        return <AssetFilterContainer key={filter} />
      case 'calendar':
        return <CalendarFilterContainer key={filter} />
      case 'address':
        return <AddressFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length
    ? [
        ...filterList.map(createFilter),
        <FiltersButtons.IconAdd
          key="filter-icon-add"
          maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
          size={transactionStaticStore.current.filterSize}
          popupContent={<FiltersMenu.Wrapper items={items} />}
        />,
      ]
    : []

  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const ButtonClearFilter = () => {
  const { resetFilters } = useFiltersActions()
  const transactionStaticStore = useAddressStaticStore()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')
  const { t } = useTranslation()

  return (
    <FiltersButtons.Clear
      disabled={!isFiltersChanged}
      label={t('platform:clear')}
      size={transactionStaticStore.current.filterSize}
      onClear={() => resetFilters()}
    />
  )
}

const AddressEvmFiltersComponent = () => {
  const transactionStaticStore = useAddressStaticStore()
  const { t } = useTranslation()
  const items = useFilterItems()

  return (
    <FiltersMenu.Provider
      initValue={{ size: transactionStaticStore.current.filterSize }}
    >
      <FilterLayout
        leftSection={
          <FiltersButtons.Add
            maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
            size={transactionStaticStore.current.filterSize}
            popupContent={<FiltersMenu.Wrapper items={items} />}
            label={t('platform:filters')}
          />
        }
        centerSection={<Filters />}
        rightSection={<ButtonClearFilter />}
      />
    </FiltersMenu.Provider>
  )
}

export const AddressEvmFilters = observer(AddressEvmFiltersComponent)
