import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import {
  ComparatorsValueFilterValues,
  TextFilterMenuListItem,
} from '../../../../Filters'
import { TransferTypeFilterValue } from '../../../../Filters/TransferTypeFilter'
import { Token } from '../../../../ProbeSandbox/types/converted/TokenBalance'
import {
  FeatureFilterState,
  SortByFilterState,
  SortOrderFilterState,
} from '../../../types/FilterState'

interface AssetValueFilterState {
  data: ComparatorsValueFilterValues
  condition: Extract<
    ComparatorsValue,
    'isGreater' | 'isLess' | 'is' | 'isBetween'
  >
}
export interface TransactionEvmFiltersState {
  sortBy: SortByFilterState
  sortOrder: SortOrderFilterState
  asset: {
    data: Token[] | null
    condition: Extract<ComparatorsValue, 'is'>
  }
  cluster: {
    data: TextFilterMenuListItem[]
    condition: Extract<ComparatorsValue, 'isAny'>
  }
  score: { data: [number, number]; condition: Extract<ComparatorsValue, 'is'> }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
  transferType: {
    data: TransferTypeFilterValue
    condition: Extract<ComparatorsValue, 'is'>
  }
  assetValue: AssetValueFilterState
  spam: FeatureFilterState
  //usdAssetValue: AssetValueFilterState
}

export type TransactionEvmNameFilters = keyof TransactionEvmFiltersState

export const TRANSACTION_EVM_FILTERS_INIT_STATE: TransactionEvmFiltersState = {
  sortBy: { data: null },
  sortOrder: { data: null },
  asset: { data: null, condition: comparators.is.value },
  score: { data: null, condition: comparators.is.value },
  calendar: { data: null, condition: comparators.is.value },
  transferType: { data: null, condition: comparators.is.value },
  cluster: { data: null, condition: comparators.isAny.value },
  assetValue: {
    data: null,
    condition: comparators.isGreater.value,
  },
  spam: {
    data: null,
    condition: comparators.is.value,
  },
  // usdAssetValue: {
  // data: { fromValue: null, toValue: null },
  //  condition: comparators.isGreater.value,
  //},
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  useFilterAction,
  useFiltersOptions,
  setInitialState,
} = filtersContextFactory<TransactionEvmFiltersState>(
  TRANSACTION_EVM_FILTERS_INIT_STATE
)
