import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'

import { Button, IconButton, RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as ExpandIcon } from '@clain/core/assets/expand.svg'
import { ReactComponent as CompressIcon } from '@clain/core/assets/compress.svg'
import { ReactComponent as ChevronsDownIcon } from '@clain/core/assets/chevrons_down.svg'
import { ReactComponent as ChevronsUpIcon } from '@clain/core/assets/chevrons_up.svg'

import ProbeVM from '../../../vm/ProbeViewModel'

import styles from './index.scss'
import { StyledBottombarTopPanel } from './BottombarTopPanel.styles'

const cx = classnames.bind(styles)

interface BottombarTopPanelProps {
  children?: React.ReactNode
}

const BottombarTopPanel = (
  { children }: BottombarTopPanelProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => (
  <StyledBottombarTopPanel ref={ref} variant="deep" spaces={['none', 'xl']}>
    {children}
    <RowDeprecated className={cx('Actions')}>
      {/* {ProbeVM.probeState.bottombarStatus !== 'fullScreen' ? (
        <ExpandIcon
          className={cx('Action')}
          onClick={() => ProbeVM.probeState.setBottombarStatus('fullScreen')}
        />
      ) : (
        <CompressIcon
          className={cx('Action')}
          onClick={() => ProbeVM.probeState.setBottombarStatus('default')}
        />
      )} */}
      {ProbeVM.probeState.bottombarStatus !== 'hidden' ? (
        <IconButton
          size="sm"
          variant="outline"
          color="secondary"
          iconVariant="ChevronsDown"
          onClick={() => ProbeVM.probeState.setBottombarStatus('hidden')}
        />
      ) : (
        <IconButton
          size="sm"
          variant="outline"
          color="secondary"
          iconVariant="ChevronsUp"
          onClick={() => ProbeVM.probeState.setBottombarStatus('default')}
        />
      )}
    </RowDeprecated>
  </StyledBottombarTopPanel>
)

export default observer(BottombarTopPanel, { forwardRef: true })
