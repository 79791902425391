import React, { FC, ReactNode } from 'react'
import { Stack, SurfaceContainer } from '@clainio/web-platform'

export const PresetsLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <SurfaceContainer>
      <Stack
        gap={'xl'}
        direction={'column'}
        fullWidth
        padding={['xl', 'xxxxl', 'xl', 'xxl']}
      >
        {children}
      </Stack>
    </SurfaceContainer>
  )
}
