import React from 'react'
import classnames from 'classnames/bind'

import { ColDeprecated, EllipsisTypography } from '@clain/core/ui-kit'
import { getSortOrder, TableSortButton, TableColumns } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import Pagination from '@clain/core/Pagination'
import { useFormatDate } from '../../../../../hooks'
import { CopyIcon } from '@clain/core/ui-kit'

import styles from './index.scss'
import { AlertEvents, AlertEvent } from '../../../../../modules/alerts/types'
import { AlertEventsFilters } from '../../../../../modules'
import { Checkbox } from '@clain/core/ui-kit'
import { getDirection } from '../../../../../modules/alerts/AlertsViewModel.utils'
import { Button } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'
import AlertsStub from '../AlertsStub'
import { useFormatMoneySettings } from '@platform/hooks/useFormatMoneySettings'
import { DataGrid, TypographyNew } from '@clainio/web-platform'
import { BaseLink } from '@clain/core/Link'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

type AlertEventWithKey = AlertEvent & { key: string }

interface AlertEventsTableProps {
  events: AlertEvents
  tabsOptions?: Array<CounterTabPropsOption<string>>
  tabValue: string
  filters: AlertEventsFilters
  updateFilters: (filters: Partial<AlertEventsFilters>) => void
  loading: boolean
  loadingMain: boolean
}

const AlertEventsTable: React.FC<AlertEventsTableProps> = observer(
  ({
    events,
    filters,
    updateFilters,
    tabsOptions,
    tabValue,
    loading,
    loadingMain,
  }) => {
    const formatDate = useFormatDate()
    const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

    const onChangeSort = (field: 'time' | 'amountUsd') =>
      updateFilters({
        sortBy: field,
        sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
      })

    const onChangeTab = (value: string) => {
      updateFilters({ alertId: +value })
    }

    const columns: TableColumns<AlertEventWithKey> = [
      {
        name: 'transaction',
        width: 1.5,
        renderTitle: () => (
          <RowDeprecated gap={0.5}>
            <Checkbox value={false} onChange={() => true} disabled />
            <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
              Transaction
            </TypographyNew>
          </RowDeprecated>
        ),
        render: ({ alert, hash }: AlertEventWithKey) => (
          <RowDeprecated gap={0.5} className={cx('TransactionCell')}>
            <Checkbox value={false} onChange={() => false} disabled />
            <BaseLink
              to="/:currency/explorer/transaction/:hash"
              params={{ currency: alert.currency, hash }}
              target="_blank"
              as={(props) => (
                <EllipsisTypography
                  {...props}
                  as="a"
                  variant="body100NormalCode"
                  color="onBackgroundBase"
                  type="center"
                  key={hash}
                >
                  {hash}
                </EllipsisTypography>
              )}
            />
            <CopyIcon value={hash} />
          </RowDeprecated>
        ),
      },
      {
        name: 'counterparty',
        width: 1.5,
        renderTitle: () => (
          <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
            Counterparty
          </TypographyNew>
        ),
        render: ({ counterparties, alert }: AlertEventWithKey) => {
          const [{ score, clusterId, entity }] = counterparties

          return (
            <RowDeprecated gap={0.5} className={cx('Counterparty')}>
              <Score value={score} size="sm" />
              <BaseLink
                to="/:currency/cluster/:clusterId"
                params={{ clusterId, currency: alert.currency }}
                target="_blank"
                as={(props) => (
                  <EllipsisTypography
                    {...props}
                    as="a"
                    variant="body100NormalCode"
                    color="onBackgroundBase"
                  >
                    {entity?.name || clusterId}
                  </EllipsisTypography>
                )}
              />
              {counterparties.length > 1 && (
                <TypographyNew
                  variant="body100NormalCode"
                  color="onBackgroundVariant1"
                  className={cx('CounterpartyOthers')}
                >
                  +{counterparties.length - 1} other
                </TypographyNew>
              )}
            </RowDeprecated>
          )
        },
      },
      {
        name: 'direction',
        width: 0.5,
        align: 'center',
        renderTitle: () => (
          <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
            Direction
          </TypographyNew>
        ),
        render: ({ direction }: AlertEventWithKey) => (
          <TypographyNew
            variant="body100NormalCode"
            color="onBackgroundVariant1"
            transform="capitalize"
          >
            {getDirection({ direction })}
          </TypographyNew>
        ),
      },
      {
        name: 'amount',
        width: 1.1,
        justify: 'end',
        renderTitle: () => (
          <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
            Amount
          </TypographyNew>
        ),
        render: ({ amount, alert }) => (
          <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
            {`${amount} ${
              alert.asset ? alert.asset?.symbol?.toLocaleUpperCase() : ''
            }`}
          </TypographyNew>
        ),
      },
      {
        name: 'amountUsd',
        width: 0.8,
        justify: 'end',
        renderTitle: () => (
          <RowDeprecated gap={0.5}>
            <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
              USD Amount
            </TypographyNew>
            <TableSortButton
              name="amountUsd"
              onChangeSort={onChangeSort}
              order={filters.sortOrder}
              sortBy={filters.sortBy}
            />
          </RowDeprecated>
        ),
        render: ({ amountUsd }) => (
          <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
            {formatMoney({
              value: amountUsd,
              currency: '$',
              position: 'start',
              spaces: false,
            })}
          </TypographyNew>
        ),
      },
      {
        name: 'time',
        width: 0.8,
        justify: 'end',
        gap: 'md',
        renderTitle: () => (
          <>
            <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
              Time
            </TypographyNew>
            <TableSortButton
              name="time"
              sortBy={filters.sortBy}
              order={filters.sortOrder}
              onChangeSort={onChangeSort}
            />
          </>
        ),
        render: (item: AlertEventWithKey) => (
          <TypographyNew variant="body100NormalCode" color="onBackgroundBase">
            {formatDate(item.time, 'date-time')}
          </TypographyNew>
        ),
      },
    ]

    return (
      <ColDeprecated gap={0} className={cx('AlertEventsTable')}>
        <RowDeprecated align="between" className={cx('AlertEventsTableHeader')}>
          <div>
            {tabsOptions && (
              <CounterTabs
                size="lg"
                options={tabsOptions}
                value={tabValue}
                onChange={onChangeTab}
              />
            )}
          </div>
          <Button variant="outline" color="secondary" disabled>
            plot selected
          </Button>
        </RowDeprecated>
        <DataGrid
          type="simple"
          columns={columns}
          data={events?.events?.map((event) => ({
            ...event,
            key: String(event.id),
          }))}
          size={'xl'}
          className={cx('Table')}
          variant={['base', 'high', 'high']}
          borderRadius={'lg'}
          borders={[
            { bottom: { color: 'secondaryContainerDeep' } },
            { bottom: { color: 'secondaryContainerDeep' } },
          ]}
          spaces={['none', 'xl']}
          loading={loading}
          loadingMain={loadingMain}
        />
        {!loadingMain && !loading && !events?.events?.length && (
          <AlertsStub text="No Events" />
        )}
        <Pagination
          value={filters.page}
          totalPages={events.totalPages}
          onChange={(page) => updateFilters({ page })}
        />
      </ColDeprecated>
    )
  }
)

export default withErrorBoundary(AlertEventsTable)
