import React, { memo } from 'react'

import { DataGrid, useTableStubs } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './AddressTable.scss'
import { useAddressTableConfig } from './hooks/useAddressTableConfig'
import { AddressEvmTableItem, AddressEvmTableProps } from './AddressEvmTable'
import * as S from '../../ui'
import { EntityTableNotFound } from '../../ui'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

export type AddressProps = Pick<
  AddressEvmTableProps<AddressEvmTableItem>,
  'data' | 'itemsPerPage' | 'isLoading' | 'currentPage'
> & {
  config: ReturnType<typeof useAddressTableConfig>
  filtersContent?: React.ReactElement
}

const AddressTableComponent = memo(
  ({
    data,
    isLoading,
    itemsPerPage = 10,
    config,
    filtersContent,
    currentPage,
  }: AddressProps) => {
    const { stubbedColumns, stubbedData } = useTableStubs(
      Object.values(config),
      data?.map((item, index) => ({
        ...item,
        key: index,
      })),
      itemsPerPage
    )

    const loading = Boolean(data && isLoading)

    return (
      <div className={cx('AddressTableWrapper')}>
        <S.Container>
          {filtersContent ? (
            <S.FilterContainer>{filtersContent}</S.FilterContainer>
          ) : null}
          <S.TableContainer>
            <DataGrid
              type="simple"
              columns={stubbedColumns}
              data={stubbedData}
              loading={loading}
              emitScrollToTop={currentPage}
              size={'md'}
              variant={['base', 'high', 'high']}
              borders={[
                { bottom: { color: 'secondaryContainerDeep' } },
                { bottom: { color: 'secondaryContainerDeep' } },
              ]}
              spaces={['none', 'xl']}
              notFoundComponent={<EntityTableNotFound />}
            />
          </S.TableContainer>
        </S.Container>
      </div>
    )
  }
)

export const AddressTable = withErrorBoundary(AddressTableComponent)

AddressTableComponent.displayName = 'AddressTable'
