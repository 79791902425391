import React, { Fragment } from 'react'
import { RowDeprecated, ScoreSquare, ActivityPeriod } from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import {
  EntityDescriptionWrapper,
  EntityDetailsLayout,
  getTags,
  RowBalance,
  RowCategory,
  RowHashEntityName,
} from '../../../EntityDetails'
import { Separator, TypographyNew } from '@clainio/web-platform'
import { Link } from '@clain/core'
import { useAddressDetailsData } from './useAddressDetailsData'
import { useTranslation } from 'react-i18next'

export const AddressDetails = observer(() => {
  const { t } = useTranslation()
  const {
    addressData: {
      isLoading,
      score,
      clusterId,
      entityName,
      category,
      description,
      statsPeriodLocal,
      balance,
      totalReceived,
      totalSent,
      transactionsCount,
      tagsData,
      hash,
      blockchain,
      timezone,
    },
    formatDate,
  } = useAddressDetailsData()

  return (
    <EntityDetailsLayout
      isLoading={isLoading}
      scoreSlot={<ScoreSquare value={score} />}
      titleSlot={
        <RowDeprecated gap={0.75}>
          <RowHashEntityName name={hash} />
          {getTags(tagsData).map((el, i) => (
            <Fragment key={i}>{el}</Fragment>
          ))}
        </RowDeprecated>
      }
      informationSlot={
        <RowDeprecated gap={0.5}>
          <Link to={`/${blockchain}/cluster/${clusterId}`} target="_blank">
            <TypographyNew
              as={'a'}
              variant={'body100Accent'}
              color={'onBackgroundBase'}
            >
              {entityName}
            </TypographyNew>
          </Link>
          <Separator variant={'vertical'} />
          <RowCategory category={category} />
        </RowDeprecated>
      }
      descriptionSlot={
        <EntityDescriptionWrapper>{description}</EntityDescriptionWrapper>
      }
      activityPeriodSlot={
        <ActivityPeriod
          timeZone={timezone}
          today={formatDate(new Date(), 'date')}
          activityPeriod={statsPeriodLocal}
        />
      }
      balanceSlot={
        <RowBalance
          balance={balance}
          received={totalReceived}
          sent={totalSent}
          transactions={transactionsCount}
          labels={{
            balance: t('platform:balance'),
            received: t('platform:totalReceived'),
            sent: t('platform:totalSent'),
            transactions: t('platform:transactions'),
          }}
        />
      }
    />
  )
})
