import { action, makeObservable, observable } from 'mobx'

import { ProbeViewModel } from './ProbeViewModel'
import { Position } from '../types/Position'
import { edgeKey } from '../utils/key'
import { CommentPinProbeNode } from '../types/entities/CommentPinProbeNode'
import { ServerEventNodeEdgeReceive } from '../types/serverData'
import { EntityLinkingController } from '@platform/components/ProbeSandbox/vm/GraphEntityEvent/controllers/EntityLinkingController'

class CommentsController {
  @observable public isPositioningInProgress: boolean

  private probeVM: ProbeViewModel
  private entityLinkingController: EntityLinkingController
  constructor(probeVM: ProbeViewModel) {
    makeObservable(this)

    this.probeVM = probeVM
    this.entityLinkingController = this.probeVM.entityLinkingController
    this.probeVM.probeEvents.subscribe(({ events }) =>
      this.closeCommentExecutor(events)
    )
  }

  private closeCommentExecutor = (events: ServerEventNodeEdgeReceive[]) => {
    events?.forEach((event) => {
      if (
        event.type === 'delete_node' &&
        this.probeVM.probeState.nodes.has(event.key) &&
        this.probeVM.probeState.nodes.get(event.key).type === 'comment_pin'
      ) {
        this.closeComment(event.key)
      }
    })
  }

  @action
  public init() {
    this.probeVM.app.on('world:mousedown', () => {
      this.probeVM.probeState.nodes.forEach((node) => {
        if (node.data.nodeType !== 'comment_pin') return

        if (node.key !== this.probeVM.mouseDownNodeKey) {
          if (this.probeVM.shortcutMenuController.isOpened(node.key)) {
            this.closeComment(node.key)
          }
        }
      })
    })
  }

  @action
  public setIsPositioningInProgress(isPositioningInProgress: boolean) {
    this.isPositioningInProgress = isPositioningInProgress
  }

  @action
  public addComment = () => {
    if (this.isPositioningInProgress) return

    this.probeVM.layers.setComments(true)
    this.setIsPositioningInProgress(true)
    const { node: nodeCommentPlug, key: commentPlugKey } =
      this.probeVM.factory.produceCommentPlugNode()

    const { key, node } = this.probeVM.factory.produceCommentPinNode({
      plugKey: commentPlugKey,
    })

    const { edge: edgeComment, key: edgeCommentKey } =
      this.probeVM.factory.produceCommentProbeEdge(key, commentPlugKey)

    this.probeVM.eventsGraphReaction.multipleEvents([
      { type: 'add_node', key, data: node },
      { type: 'add_node', key: commentPlugKey, data: nodeCommentPlug },
      { type: 'add_edge', key: edgeCommentKey, data: edgeComment },
    ])

    nodeCommentPlug.setDisabled(true)
    if (edgeComment) {
      edgeComment.setDisabled(true)
    }
    this.positionComment(key)
  }

  @action
  public positionComment(key: string) {
    const node = this.probeVM.probeState.nodes.get(key)
    if (!node) return

    node.setInteractive(false)

    const pointerListener = (position: Position) => {
      const worldPosition = this.probeVM.app.toWorldCoordinates(position)
      node.moveTo(worldPosition)
      if (node.graphData().linkType === 'slave') {
        this.entityLinkingController.startLinkingProcess(key)
      }
    }

    this.probeVM.pointerController.addListener(pointerListener)

    this.probeVM.app.once('world:mouseup', () => {
      this.probeVM.pointerController.removeListener(pointerListener)
      node.setInteractive(true)
      if (this.isPositioningInProgress) {
        this.openComment(key)
      }
      this.setIsPositioningInProgress(false)
    })
  }

  @action
  public openComment(key: string) {
    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const { plugKey } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)

    if (this.probeVM.probeState.nodes.has(plugKey)) {
      const plugNode = this.probeVM.probeState.nodes.get(plugKey)
      plugNode.setDisabled(false)

      plugNode.moveTo({
        x: pinNode.position.x + 150,
        y: pinNode.position.y,
      })

      this.probeVM.shortcutMenuController.open(
        key,
        'comment',
        plugNode.position
      )
    }

    if (this.probeVM.probeState.edges.has(commentEdgeKey)) {
      const commentEdge = this.probeVM.probeState.edges.get(commentEdgeKey)
      commentEdge.setDisabled(false)
    }
  }

  @action
  public closeComment(key: string) {
    if (!this.probeVM.probeState.nodes.has(key)) return

    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const { plugKey, messages } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)
    const hasMessage = messages?.length
    if (this.probeVM.probeState.nodes.has(plugKey)) {
      const plugNode = this.probeVM.probeState.nodes.get(plugKey)
      plugNode.setDisabled(true)
    }

    if (this.probeVM.probeState.edges.has(commentEdgeKey)) {
      const commentEdge = this.probeVM.probeState.edges.get(commentEdgeKey)
      commentEdge.setDisabled(true)
    }

    this.probeVM.shortcutMenuController.hide(key)
    if (!hasMessage) {
      if (pinNode.graphData().linkType === 'slave') {
        this.entityLinkingController.hideLinkingArea()
      }
      this.probeVM.eventsGraphReaction.multipleEvents([
        { type: 'delete_node', key },
        { type: 'delete_node', key: plugKey },
      ])
    }
  }

  @action
  public getComments = (key: string) => {
    if (!this.probeVM.probeState.nodes.has(key)) return []

    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode

    return pinNode.data.messages || []
  }

  @action public createComment = (key: string, text: string) => {
    if (!this.probeVM.probeState.nodes.has(key)) return
    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const comments = pinNode.data.messages || []

    const mergedComments = [
      ...comments,
      {
        name: this.probeVM.settings.userProfile.email,
        text,
        time: Math.floor(Date.now() / 1000),
      },
    ]

    const firstSendComment = !comments.length

    if (firstSendComment) {
      this.probeVM.probeEvents.emit([
        {
          type: 'add_node',
          data: {
            strategy: 'comment',
            key,
            messages: [
              {
                name: this.probeVM.settings.userProfile.email,
                text,
                time: Math.floor(Date.now() / 1000),
              },
            ],
          },
        },
      ])
      if (pinNode.graphData().linkType === 'slave') {
        this.entityLinkingController.finishLinkingProcess(key)
      }
    } else {
      this.probeVM.probeEvents.emit(
        [
          {
            type: 'update_node',
            key: pinNode.key,
            data: {
              type: 'comment_pin',
              nodeData: {
                messages: mergedComments,
              },
            },
          },
        ],
        { optimistic: true }
      )
    }

    pinNode.updateData({ messages: mergedComments })
  }

  @action
  public deleteComment = (key: string) => {
    if (!this.probeVM.probeState.nodes.has(key)) return

    const pinNode = this.probeVM.probeState.nodes.get(
      key
    ) as CommentPinProbeNode
    const { plugKey } = pinNode.data
    const commentEdgeKey = edgeKey(key, plugKey)
    this.closeComment(key)

    this.probeVM.probeEvents.emit([
      { type: 'delete_node', entity: { key } },
      { type: 'delete_node', entity: { key: plugKey } },
      {
        type: 'delete_edge',
        entity: {
          strategy: 'none',
          edgeKey: commentEdgeKey,
        },
      },
    ])
  }
}

export default CommentsController
