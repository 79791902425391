import { PropsWithChildren, useEffect } from 'react'
import {
  CounterpartyContainerFilters,
  CounterpartyContext,
} from '../CounterpartyContainerFilters/index'
import {
  CounterpartyNameFilters,
  COUNTERPARTY_FILTERS_INIT_STATE,
  setInitialState,
  useFiltersActions,
} from '../CounterpartyContainerFilters/CounterpartyContainerFilters.service'
import {
  CounterpartyFiltersParams,
  CounterpartyTypeFilters,
} from './CounterpartyFilters.types'
import { normalizeCounterpartyFilters } from './CounterpartyFilters.utils'
import {
  CounterpartyProvider,
  CounterpartyViewModelProvider,
  useCounterpartyViewModel,
  useInitCounterpartyStaticStore,
} from '../context'
import { equals, pipe } from 'ramda'
import {
  normalizeCalendarToIntern,
  normalizeScoreToIntern,
  normalizeSelectItemIntern,
  normalizeTextItemListIntern,
  normalizeTokenToIntern,
} from '../../../utils'
import {
  FILTER_ASSET_KEY,
  FILTER_CALENDAR_KEY,
  FILTER_CATEGORY_KEY,
  FILTER_CLUSTER_KEY,
  FILTER_SCORE_KEY,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_ORDER_KEY,
} from '../../../constants'
import { observer } from 'mobx-react-lite'
import { useTimezone } from '@platform/hooks'

const normalizeInternFilters = (
  filters: Partial<CounterpartyTypeFilters>,
  timezone: string
) => {
  return pipe(
    () => COUNTERPARTY_FILTERS_INIT_STATE,
    normalizeTokenToIntern(FILTER_ASSET_KEY.data, filters?.includeTokens?.[0]),
    normalizeCalendarToIntern(FILTER_CALENDAR_KEY.data, filters, timezone),
    normalizeScoreToIntern(FILTER_SCORE_KEY.data, filters),
    normalizeTextItemListIntern(FILTER_CLUSTER_KEY.data, {
      id: filters?.counterpartyId || filters?.counterpartyAddressId,
      label: filters?.counterpartyName,
    }),
    normalizeSelectItemIntern(FILTER_CATEGORY_KEY.data, filters?.categories),
    normalizeSelectItemIntern(FILTER_SORT_ORDER_KEY.data, filters?.sortOrder),
    normalizeSelectItemIntern(FILTER_SORT_BY_KEY.data, filters?.sortBy)
  )()
}

const useSetFilters = (counterpartyType: 'address' | 'cluster') => {
  const timezone = useTimezone()
  const { defaultFilters, filters, setFilters } = useCounterpartyViewModel()
  const { setFilters: setFiltersActions } = useFiltersActions({
    onSetFilters: (updatedFilters) => {
      setFilters(
        normalizeCounterpartyFilters(
          updatedFilters,
          defaultFilters,
          counterpartyType,
          timezone
        )
      )
    },
  })
  useEffect(() => {
    if (filters) {
      setFiltersActions(normalizeInternFilters(filters, timezone))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters?.sortBy,
    filters?.sortOrder,
    filters?.from,
    filters.to,
    filters?.includeTokens?.[0]?.id,
    filters?.scoreFrom,
    filters?.scoreTo,
    filters?.counterpartyId,
    filters?.counterpartyAddressId,
    filters?.counterpartyName,
    filters?.categories?.length,
  ])
}

const useInitFilters = () => {
  const timezone = useTimezone()
  const { setFilters } = useFiltersActions()
  const { initialFilters, defaultFilters } = useCounterpartyViewModel()

  useEffect(() => {
    if (initialFilters) {
      const normalizeInitFilters = normalizeInternFilters(
        initialFilters,
        timezone
      )
      const normalizeDefaultFilters = normalizeInternFilters(
        defaultFilters,
        timezone
      )

      if (!equals(normalizeInitFilters, COUNTERPARTY_FILTERS_INIT_STATE)) {
        setInitialState(normalizeDefaultFilters)
        setFilters(normalizeInitFilters)
      }
    }
  }, [
    initialFilters?.sortBy,
    initialFilters?.sortOrder,
    initialFilters?.from,
    initialFilters.to,
    initialFilters?.includeTokens?.[0]?.id,
    initialFilters?.scoreFrom,
    initialFilters?.scoreTo,
    initialFilters?.counterpartyId,
    initialFilters?.counterpartyAddressId,
    initialFilters?.counterpartyName,
    initialFilters?.categories?.length,
  ])
}

const CounterpartyFiltersFiltersComponent: React.FC<
  PropsWithChildren<CounterpartyFiltersParams<CounterpartyNameFilters>>
> = observer(
  ({ children, getSearchResults, coinType, filterSize, counterpartyType }) => {
    useInitCounterpartyStaticStore({
      getSearchResults,
      coinType,
      filterSize,
      counterpartyType,
    })
    useInitFilters()
    useSetFilters(counterpartyType)

    return <>{children}</>
  }
)

export function CounterpartyFilters<
  ExcludeFilters extends CounterpartyNameFilters,
>(props: CounterpartyFiltersParams<ExcludeFilters>) {
  return (
    <CounterpartyViewModelProvider viewModel={props}>
      <CounterpartyProvider initStaticStore={props}>
        <CounterpartyContext.FiltersProvider>
          <CounterpartyFiltersFiltersComponent {...props}>
            <CounterpartyContainerFilters />
          </CounterpartyFiltersFiltersComponent>
        </CounterpartyContext.FiltersProvider>
      </CounterpartyProvider>
    </CounterpartyViewModelProvider>
  )
}
