import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { IconButton } from '@clain/core/ui-kit'

import styles from '../index.scss'

const cx = classnames.bind(styles)

interface CrossChainSwapToolpanelProps {
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

export const CrossChainSwapToolpanel: React.FC<
  CrossChainSwapToolpanelProps
> = ({ deleteNode, isDeleteNodeDisabled = false }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <Tooltip
          placement="right"
          content={t(
            isDeleteNodeDisabled
              ? 'platform:deletingDisabled'
              : 'platform:removeNode'
          )}
        >
          <IconButton
            size="md"
            disabled={isDeleteNodeDisabled}
            iconVariant="Delete"
            onClick={isDeleteNodeDisabled ? null : deleteNode}
          />
        </Tooltip>
      </RowDeprecated>
    </React.Fragment>
  )
}
