import React, { memo } from 'react'
import { useTheme } from 'styled-components'
import {
  FiltersButtons,
  FiltersMenu,
  HorizontalSlider,
  selectToken,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { useFilterNameList, useFiltersOptions } from './Filters.service'
import { OutputAmountFilterContainer } from './OutputAmountFilter'
import { OutputScoreFilterContainer } from './OutputScoreFilter'
import { InputAmountFilterContainer } from './InputAmountFilter'

import {
  useTransactionAddressStaticStore,
  useTransactionAddressViewModel,
} from '../context'

import { excludeSortFilters } from '@platform/components/EntitiesTable/utils/excludeSortFilters'
import { FilterLayout } from '@platform/components/EntitiesTable/ui'
import { EXCLUDE_SORT_FILTERS } from './constants'
import { useTranslation } from 'react-i18next'

const FiltersSection = () => {
  const _filterList = useFilterNameList()
  const { excludeFilters } = useTransactionAddressViewModel()
  const filterList = excludeSortFilters(_filterList, [
    ...EXCLUDE_SORT_FILTERS,
    ...(excludeFilters ? (excludeFilters as never) : []),
  ])

  const transactionStaticStore = useTransactionAddressStaticStore()
  const theme = useTheme()

  const createFilter = (filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'inputAmount':
        return <InputAmountFilterContainer key={filter} />
      case 'outputAmount':
        return <OutputAmountFilterContainer key={filter} />
      case 'outputScore':
        return <OutputScoreFilterContainer key={filter} />
    }
  }

  const sliders = filterList?.length ? filterList.map(createFilter) : []
  return (
    <HorizontalSlider
      arrowSize={transactionStaticStore.current.filterSize}
      slides={sliders}
      gap={theme.paddingLg}
      foggyColor={selectToken(theme, 'colorSecondaryContainerBase')}
    />
  )
}

const ButtonClearFilter = () => {
  const { resetFilters } = useTransactionAddressViewModel()
  const transactionStaticStore = useTransactionAddressStaticStore()
  const isFiltersChanged = useFiltersOptions('isFiltersChanged')
  const { t } = useTranslation()

  return (
    <FiltersButtons.Clear
      disabled={!isFiltersChanged}
      label={t('platform:clear')}
      size={transactionStaticStore.current.filterSize}
      onClear={() => resetFilters()}
    />
  )
}

const TransactionAddressFiltersComponent = () => {
  const transactionStaticStore = useTransactionAddressStaticStore()

  return (
    <FiltersMenu.Provider
      initValue={{ size: transactionStaticStore.current.filterSize }}
    >
      <FilterLayout
        leftSection={null}
        centerSection={<FiltersSection />}
        rightSection={<ButtonClearFilter />}
      />
    </FiltersMenu.Provider>
  )
}

export const TransactionAddressFilters = memo(
  observer(TransactionAddressFiltersComponent)
)
