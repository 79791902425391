import React, { FC } from 'react'
import {
  PopoverBaseContainer,
  PopoverBaseContainerProps,
} from '@clainio/web-platform'

export type FilterDropdownProps = Pick<
  PopoverBaseContainerProps,
  'onOpenChange' | 'placement'
> & {
  content: React.ReactNode
  disabled?: boolean
  hideOnClickInside?: boolean
  children?: React.ReactNode
  className?: string
  isOpen?: boolean
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  children,
  content,
  placement = 'bottom',
  disabled,
  hideOnClickInside = false,
  className,
  onOpenChange,
  isOpen,
}) => {
  return (
    <PopoverBaseContainer
      className={className}
      content={content}
      hideOnClickInside={hideOnClickInside}
      placement={placement}
      open={disabled ? false : isOpen}
      onOpenChange={onOpenChange}
      anchor={<div>{children}</div>}
    />
  )
}

FilterDropdown.displayName = 'FilterDropdown'

export default FilterDropdown
