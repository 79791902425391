import React, { FC } from 'react'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clainio/web-platform'
import { Icon } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import classnames from 'classnames/bind'
import styles from './ProbeGenerateModalHeader.scss'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

interface ProbeGenerateModalHeaderProps {
  hide: () => void
}

const AI_DOCS_URL = 'https://docs.clain.io/'

export const ProbeGenerateModalHeader: FC<ProbeGenerateModalHeaderProps> = ({
  hide,
}) => {
  const { t } = useTranslation()

  return (
    <ColDeprecated gap={0.75} className={cx('Header')}>
      <RowDeprecated align="between">
        <RowDeprecated gap={0.5}>
          <Typography variant="subheading2" color="black">
            {t('platform:aiReport')}
          </Typography>
          <TagDeprecated>{t('platform:beta')}</TagDeprecated>
        </RowDeprecated>
        <Icon variant={'Close'} onClick={hide} color={'onBackgroundVariant1'} />
      </RowDeprecated>
      <Typography variant={'body1-sm'} color={'grey2'}>
        {t('platform:aiReportBetaDescription')}{' '}
        <Link to={AI_DOCS_URL}>{t('platform:completeDocumentation')}</Link>{' '}
        {t('platform:forFurtherInformation')}
      </Typography>
    </ColDeprecated>
  )
}
