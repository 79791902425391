import styled from 'styled-components'
import {
  createTokenKey,
  RowDeprecated,
  selectToken,
} from '@clainio/web-platform'
import { IconLeftRightWrapperStyled } from '../../../../../TransactionBlock/utxo/TransactionBlockUTXO.styles'

export const CheckboxWrapper = styled(IconLeftRightWrapperStyled)`
  padding-right: ${({ theme }) =>
    selectToken(theme, createTokenKey(['padding', 'xs']))};
`
export const AddressRow = styled(RowDeprecated)``
export const HeaderLeftWrapper = styled(RowDeprecated)<{
  $withPadding: boolean
}>`
  padding-left: ${({ $withPadding }) => ($withPadding ? '36px' : 0)};
`

export const HeaderRightWrapper = styled(RowDeprecated)<{
  $withPadding: boolean
}>`
  padding-right: ${({ $withPadding }) => ($withPadding ? '36px' : 0)};
`
