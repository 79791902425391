import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Card, CheckboxSwitch, Stub } from '@clain/core/ui-kit'
import { Stack, StatusBlock } from '@clainio/web-platform'
import { FiltersProvider } from '../SenkeyChartFilters/SenkeyChartFilters.service'

import { useSenkeyViewModel } from '../../modules/analytics/Senkey/SenkeyData.context'
import { SenkeyChartFilters } from '../SenkeyChartFilters'
import classnames from 'classnames/bind'
import styles from './SenkeyChartSection.scss'
import { SenkeyChartCluster } from '../SenkeyChartCluster'
import { SenkeyChartAddress } from '../SenkeyChartAddress'
import { observer } from 'mobx-react-lite'
import { DEFAULT_USD_TOKEN } from '../ProbeSandbox/utils/convertTokenBalances'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
const cx = classnames.bind(styles)

export const SenkeyChartSection = observer(() => {
  const { t } = useTranslation()
  const {
    notFound,
    entityType,
    isLoading,
    updateFilters,
    assetFilter,
    convertToFilter,
  } = useSenkeyViewModel()
  const assetId = assetFilter.id

  const onChangeCurrencyConversation = useCallback((isUsd: boolean) => {
    updateFilters({
      convertTo: isUsd ? 'usd' : 'native',
    })
  }, [])
  return (
    <FiltersProvider>
      <ErrorBoundaryContainer>
        <Card
          title={t('platform:topCounterparties')}
          headerSlot={
            <Stub isActive={isLoading}>
              <CheckboxSwitch
                disabled={assetId === DEFAULT_USD_TOKEN.token.id}
                label={'USD'}
                value={convertToFilter === 'usd'}
                onChange={onChangeCurrencyConversation}
              />
            </Stub>
          }
        >
          <div className={cx('SenkeyChartFilters')}>
            <SenkeyChartFilters />
          </div>
          {notFound ? (
            <Stack padding={'xxxxl'}>
              <StatusBlock>
                <StatusBlock.Image type={'data'} />
                <StatusBlock.Title>
                  {t('platform:noDataAvailable')}
                </StatusBlock.Title>
                <StatusBlock.Subtitle>
                  {t('platform:dataProcessing')}
                </StatusBlock.Subtitle>
              </StatusBlock>
            </Stack>
          ) : entityType === 'cluster' ? (
            <SenkeyChartCluster />
          ) : (
            <SenkeyChartAddress />
          )}
        </Card>
      </ErrorBoundaryContainer>
    </FiltersProvider>
  )
})
