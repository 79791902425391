import { GraphEventsOptions } from './GraphEvents.types'

export const INIT_PROBE_EVENTS_OPTIONS: Required<
  Omit<GraphEventsOptions, 'id'>
> = {
  optimistic: false,
  animation: false,
  accumulationEntitiesKeyType: 'newAdded',
  animationType: { strategy: 'moveToCentroid', scale: 0.8 },
}
