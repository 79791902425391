import socket from './socket'
import { UserAccessState } from '../../states/UserAcessState'
import { BaseChannel } from './WebSocketChannel'
import {
  INotificationService,
  ISentryService,
  WSStateInit,
} from './WebSocket.types'

const TIMEOUT = 60_000
export class BaseWSState {
  public phoenixSocket: typeof socket
  public channels: Map<string, BaseChannel> = new Map()
  public userState: UserAccessState
  public refreshToken: () => void
  protected notificationService: INotificationService
  protected sentryService: ISentryService
  protected WSChannel: typeof BaseChannel

  protected constructor(
    notificationService: INotificationService,
    sentryService: ISentryService,
    WSChannel: typeof BaseChannel
  ) {
    this.notificationService = notificationService
    this.sentryService = sentryService
    this.WSChannel = WSChannel
  }

  public init({ wss, token, userState, refreshToken }: WSStateInit) {
    this.connect(wss, token)
    this.userState = userState
    this.refreshToken = refreshToken
  }

  public reconnectWithNewToken = (token: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.phoenixSocket) {
          this.phoenixSocket.disconnect(() => {
            this.phoenixSocket.reconnect({ token })
            resolve()
          })
        } else {
          resolve()
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  public channel(topic: string, params?: { [key: string]: unknown }) {
    if (!this.channels.has(topic)) {
      this.channels.set(topic, this.createNewChannel(topic, params))
    }

    return this.channels.get(topic)
  }

  public clear(topic: string) {
    if (this.channels.has(topic)) {
      const channel = this.channel(topic)

      channel.leave()
    }

    this.channels.delete(topic)
  }

  private connect(wss: string, token: string) {
    this.phoenixSocket = socket
    this.phoenixSocket.init(wss, { params: { token }, timeout: TIMEOUT })

    this.phoenixSocket.onError((error) => {
      // console.log('error', error)
      // TODO: вот тут происходит разлогин
      // при потере подключения с беком при деплое
      console.log('error socket', error)
      //window.location.replace('/logout')
    })
  }

  protected errorHandled: { [key: string]: boolean } = {}

  protected handleErrorOncePerTopic(topic: string, reason: string) {
    if (!this.errorHandled[topic]) {
      this.errorHandled[topic] = true

      this.sentryService.captureException(new Error(reason), {
        extra: { topic },
      })
    }
  }

  public async clearAllChannels() {
    const leavePromises = Array.from(this.channels.values()).map(
      async (channel) => {
        await channel.leave()
      }
    )

    await Promise.all(leavePromises)

    this.channels.clear()
  }

  protected createNewChannel(
    topic: string,
    params?: { [key: string]: unknown }
  ): BaseChannel {
    const errorHandler = (reason) => {
      if (reason?.reason?.toLowerCase() === 'subscription expired') {
        this.userState.setSubscriptionExpired(true)
      }

      if (
        ['no access', 'no subscription'].includes(
          reason?.reason?.toLowerCase() || ''
        )
      ) {
        this.userState.setNoAccess(true)
      }

      if (
        ['token is invalid', 'token is expired'].includes(
          reason?.reason?.toLowerCase() ||
            reason?.reason?.error?.toLowerCase() ||
            ''
        )
      ) {
        this.refreshToken()
        return reason
      }

      if (reason?.reason?.toLowerCase() === 'join crashed') {
        this.handleErrorOncePerTopic(topic, reason.reason)
      }
      return reason
    }
    return new this.WSChannel(
      topic,
      this.phoenixSocket?.channel?.(topic, params),
      errorHandler,
      this.notificationService,
      this.sentryService
    )
  }
}
