import React from 'react'
import classnames from 'classnames/bind'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { useNavigate, useParams, generatePath } from '@clain/core/Router/router'

import {
  capitalizeFirstLetter,
  DropdownMenu,
  Icon,
  Stack,
} from '@clain/core/ui-kit'

import styles from './index.scss'
import { paramsState } from '../../routes'
import * as T from './index.types'
import { initialCoin } from '../../utils/initialCoin'
import { CoinType } from '../../types/coin'
import { COIN_NAMES_RECORD, getCoinName } from '@clain/core/utils/currency'
import * as S from './CoinSelect.styles'

const cx = classnames.bind(styles)

export const coinState = atom<CoinType>({
  key: 'coinState',
  default: initialCoin,
})

export const useSelectedCoin = () => useRecoilValue(coinState)

const Coin = React.forwardRef<HTMLDivElement, T.CoinProps>(
  ({ as: Tag = 'div', value, className, textClassName }, ref) => {
    return (
      <Tag ref={ref} className={cx('Coin', className)}>
        <Stack gap="xl" align="center">
          <S.CoinSelectIcon>
            <Icon variant={capitalizeFirstLetter(value)} />
          </S.CoinSelectIcon>
          <span className={cx('text', textClassName)}>
            {getCoinName(value)}
          </span>
        </Stack>
      </Tag>
    )
  }
)

Coin.displayName = 'Coin'

const CoinSelect = React.forwardRef(
  (
    {
      // workaround to fix warning
      className,
      textClassName,
      iconClassName,
    }: T.CoinSelectProps,
    _ref
  ) => {
    const params = useParams()
    const navigate = useNavigate()

    const [coin, setCoin] = useRecoilState(coinState)
    const [, setCoinParam] = useRecoilState(paramsState)

    React.useEffect(() => {
      const urlCoin = params.coin as CoinType
      if (urlCoin && urlCoin !== coin) {
        setCoin(urlCoin)
        setCoinParam({ coin: urlCoin })
      }
    }, [params.coin])

    const handleChangeCoin = (c: CoinType) => {
      setCoin(c)
      setCoinParam({ coin: c })

      try {
        const newPath = generatePath('/:coin/dashboard', { coin: c })
        navigate(newPath)
      } catch {
        navigate(`/${c}/dashboard`)
      }
    }

    return (
      <DropdownMenu
        options={COIN_NAMES_RECORD}
        value={coin}
        padded={false}
        placement="bottom"
        onChange={handleChangeCoin}
      >
        <div>
          <Coin
            className={cx('CoinSelect', className)}
            textClassName={textClassName}
            iconClassName={iconClassName}
            value={coin}
          />
        </div>
      </DropdownMenu>
    )
  }
)
CoinSelect.displayName = 'CoinSelect'
export default CoinSelect
