import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Text } from '@clain/core/ui-kit'
import { formatNumber } from '@clain/core/utils/format'
import { useOnlineStatus } from '@clain/core/useOnlineStatus'

import { useSelectedCoin } from '../CoinSelect'
import { useCtx } from '../../ctx'
import { useTranslation } from '@clain/core/utils/i18next'

const StatusItem = ({ className }: { className: string }) => {
  const coin = useSelectedCoin()
  const { t } = useTranslation()
  const onlineStatus = useOnlineStatus()

  const { blocksHeightState } = useCtx()

  const explorerHeight = blocksHeightState[coin]?.explorerHeight
  const cashflowd_height = blocksHeightState[coin]?.cashflowdHeight

  const lag = explorerHeight - cashflowd_height

  const statusText = useMemo(() => {
    if (!onlineStatus) return t('platform:internetConnectionLost')
    if (!cashflowd_height) return t('platform:serverOffline')

    if (lag > 0) {
      return t('platform:blockBehind', '', { count: lag ?? 0 })
    }

    return t('platform:upToDate')
  }, [cashflowd_height, lag, onlineStatus, t])

  const title = useMemo(() => {
    if (!cashflowd_height) return undefined

    const blockLabel =
      explorerHeight === 1 ? t('platform:block') : t('platform:blocks')

    return t('platform:blocksProcessed', '', {
      processed: formatNumber(cashflowd_height, 0),
      total: formatNumber(explorerHeight, 0),
      blockLabel: blockLabel,
    })
  }, [cashflowd_height, explorerHeight, t])

  return (
    <Text muted size="lg" title={title} className={className}>
      {statusText}
    </Text>
  )
}

export default observer(StatusItem)
