import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { ColDeprecated } from '@clain/core/ui-kit'
import useVm from '@clain/core/useVm'
import { Spinner } from '@clain/core/ui-kit'

import Card from '../Card'
import { CasesViewModel } from './CasesViewModel'
import { useCtx } from '../../../../ctx'
import CasesHeader from '../CasesHeader'

import styles from './index.scss'
import { useSettings } from '../../../../hooks'
import { HeaderSlot } from '@clain/core/Layout/slots'

const cx = classnames.bind(styles)

const Cases = () => {
  const casesVm = useVm(CasesViewModel, useCtx())
  const settings = useSettings()

  React.useEffect(() => {
    casesVm.init()

    return () => {
      casesVm.clear()
    }
  }, [])

  HeaderSlot.useContent(() => <CasesHeader />, [])

  const loaderRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && casesVm.loadingPossible) {
          casesVm.load()
        }
      },
      {
        rootMargin: '200px 0px',
        threshold: 1,
      }
    )

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <ColDeprecated gap={0.25}>
        {casesVm.cases?.cases.map((caseItem) => (
          <Card
            key={caseItem.id}
            case={caseItem}
            users={casesVm.cases.users}
            createProbe={() => casesVm.createProbe(caseItem.id)}
            probeOpenInNewTab={settings.userSettings.graph.openInNewTab}
          />
        ))}
      </ColDeprecated>
      <div ref={loaderRef} className={cx('Bottom')}>
        {casesVm.casesLoading && <Spinner />}
      </div>
    </React.Fragment>
  )
}

export default observer(Cases)
