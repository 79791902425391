import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Typography } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { ReactComponent as ShareIcon } from '@clain/core/assets/share.svg'

import BaseCard from '../BaseCard'
import { CaseSharedWithDiff, User } from '../../../../types'

import styles from './index.scss'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classnames.bind(styles)

interface SharedWithChangeCardProps {
  date: Date
  user: User
  users: Array<User>
  diff: CaseSharedWithDiff
  className?: string
}

const SharedWithChangeCard: React.FC<SharedWithChangeCardProps> = ({
  date,
  className,
  diff,
  users,
}) => {
  const { t } = useTranslation()

  const noone = diff.new?.length === 0 ? t('platform:noOne') : ''
  const sharedWithData = diff.new.map(
    (id) =>
      users.find(({ id: userId }) => userId === id) || {
        name: '',
        avatar: '',
        id: -1,
      }
  )
  const firstSharedWithData = sharedWithData?.length ? sharedWithData[0] : null
  const sharedWithDataWithoutFirst = sharedWithData.slice(1)

  return (
    <BaseCard
      date={date}
      label={t('platform:sharing')}
      icon={ShareIcon}
      className={className}
      title={
        <>
          {t('platform:caseSharedWith')} {noone}
          {firstSharedWithData && (
            <Typography variant="body3" color="grey2">
              {firstSharedWithData.name}
            </Typography>
          )}
          {sharedWithData?.length > 1 ? (
            <>
              <PopoverBaseContainer
                className={cx('ResponsiblesDropdown')}
                content={
                  <>
                    {sharedWithDataWithoutFirst?.map((user, index) => (
                      <div className={cx('Item')} key={index}>
                        <Avatar
                          size="sm"
                          name={user.name}
                          src={user.avatar}
                          className={cx('Ava')}
                        />
                        <Typography
                          variant="body1"
                          color="black"
                          className={cx('Name')}
                        >
                          {user.name || t('platform:deleted')}
                        </Typography>
                      </div>
                    ))}
                  </>
                }
                anchor={
                  <Typography
                    variant="body1"
                    color="grey2"
                    className={cx('ShowMore')}
                  >
                    {t('platform:andMore', '', {
                      count: sharedWithDataWithoutFirst.length,
                    })}
                  </Typography>
                }
              />
            </>
          ) : null}
        </>
      }
    />
  )
}

export default SharedWithChangeCard
