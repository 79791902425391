import Header from '../../Header'
import useTitle from '@clain/core/useTitle'
import { useNavLinks } from '../../NavIcons'
import { BlockchainIcon, Button, Stack, Typography } from '@clain/core/ui-kit'
import { CoinType } from '../../../types/coin'
import { dataStatsViewModel } from '../../EntityPage/AddressViewModels'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { usePlotEntitiesOnGraph } from '@platform/hooks'
import { useTranslation } from 'react-i18next'

export const useHeaderSlot = ({ id, coin }: { id: string; coin: CoinType }) => {
  const { t } = useTranslation()
  const { data } = dataStatsViewModel
  const { plotSelectedOnGraph, setSelectEntities } =
    usePlotEntitiesOnGraph('addresses')

  useNavLinks(
    {
      clusterAddress: data?.clusterId?.toString(),
    },
    [data?.clusterId]
  )

  useTitle(id)

  HeaderSlot.useContent(
    () => (
      <Header
        withNavIcons={false}
        withNavSearch={false}
        title={
          <Stack gap="md" align="center">
            <Typography variant={'heading1'} color="black">
              {t('platform:explorerAddress')}
            </Typography>
            <BlockchainIcon currency={coin} />
          </Stack>
        }
        actions={
          <Button
            variant="outline"
            color="secondary"
            endIcon="GraphPlot"
            onClick={() => {
              setSelectEntities(
                {
                  id: data.addressId,
                  currency: coin,
                  address: data.address,
                  clusterId: data.clusterId,
                },
                true
              )
              plotSelectedOnGraph()
            }}
          >
            {t('platform:visualizeOnGraph')}
          </Button>
        }
      />
    ),
    [coin, data?.address, data?.addressId, data?.clusterId, t]
  )
}
