import { useSettings } from '@platform/hooks'
import { Timezone as TZComponent } from '@clain/core/Timezone'
import { observer } from 'mobx-react-lite'

export const Timezone = observer(() => {
  const { userSettings, updateUserSettings } = useSettings()

  return (
    <TZComponent
      timezone={userSettings?.timezone}
      updateTimezone={(timezone) => updateUserSettings({ timezone })}
    />
  )
})
