import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as FolderIcon } from '@clain/core/assets/folder_open.svg'

import BaseCard from '../BaseCard'
import { CaseInternalIdDiff, User } from '../../../../types'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface InternalIdChangeCardProps {
  date: Date
  user: User
  diff: CaseInternalIdDiff
  className?: string
}

const InternalIdChangeCard: React.FC<InternalIdChangeCardProps> = ({
  date,
  className,
  diff,
  user,
}) => {
  const { t } = useTranslation()

  return (
    <BaseCard
      date={date}
      label={t('platform:internalCase')}
      icon={FolderIcon}
      className={className}
      title={
        <Typography variant="body3">
          {user.name}{' '}
          <Typography variant="body1">
            {t('platform:changedInternalCase')}
          </Typography>
        </Typography>
      }
    >
      <Typography variant="body1" color="grey2" className={cx('Body')}>
        {t('platform:from')}{' '}
        <Typography variant="body3">#{diff.old}</Typography> {t('platform:to')}{' '}
        <Typography variant="body3">#{diff.new}</Typography>
      </Typography>
    </BaseCard>
  )
}

export default InternalIdChangeCard
