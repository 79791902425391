import { useCallback } from 'react'
import { useAlertOptions } from './useAlertOptions'

export const useAlertInitialValues = () => {
  const { defaultDirection, defaultTrigger, defaultCurrency } =
    useAlertOptions()

  const getInitialValues = useCallback(
    () => ({
      item: undefined,
      direction: defaultDirection,
      tag: '',
      threshold: '',
      recurrent: defaultTrigger,
      native: defaultCurrency,
    }),
    [defaultDirection, defaultTrigger, defaultCurrency]
  )

  return {
    getInitialValues,
  }
}
