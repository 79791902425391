import { FilterDropdownMenuContainerTypes } from '@clain/core/ui-kit'
import { MultisigFilterContainerProps } from './MultisigFilter'
import { ComparatorFilterContainerProps } from './ComparatorFilter'
import {
  ComparatorFilterKeys,
  MultisigFilterKeys,
} from '@platform/components/BlockchainFilter/types'
import i18next from 'i18next'

export const filtersPropsById = () => {
  const { t } = i18next

  const ComparatorFilterPropsById: Record<
    ComparatorFilterKeys,
    FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem &
      ComparatorFilterContainerProps
  > = {
    size: {
      id: 'size',
      label: t('platform:size'),
      title: t('platform:size'),
      units: 'bytes',
      icon: null,
      isDecimalsOnly: true,
    },
    vSize: {
      id: 'vSize',
      label: t('platform:vSize'),
      title: t('platform:vSize'),
      units: 'vBytes',
      icon: null,
      isDecimalsOnly: true,
    },
    fee: {
      id: 'fee',
      label: t('platform:fee'),
      title: t('platform:transactionFee'),
      units: 'sat',
      icon: null,
      isDecimalsOnly: true,
    },
    feeByte: {
      id: 'feeByte',
      label: 'Fee/vByte',
      title: 'Fee/vByte',
      units: 'sat',
      icon: null,
      isDecimalsOnly: false,
    },
    amount: {
      id: 'amount',
      label: t('platform:amount'),
      title: t('platform:amount'),
      units: 'BTC',
      icon: null,
      isDecimalsOnly: false,
    },
    input_amount: {
      id: 'input_amount',
      label: t('platform:inputAmount'),
      title: t('platform:amount'),
      units: 'BTC',
      icon: null,
      isDecimalsOnly: false,
    },
    output_amount: {
      id: 'output_amount',
      label: t('platform:outputAmount'),
      title: t('platform:amount'),
      units: 'BTC',
      icon: null,
      isDecimalsOnly: false,
    },
    numberInputs: {
      id: 'numberInputs',
      label: t('platform:numberOfInputs'),
      title: t('platform:numberOfInputs'),
      icon: null,
      units: '',
      isDecimalsOnly: false,
    },
    numberOutputs: {
      id: 'numberOutputs',
      label: t('platform:numberOfOutputs'),
      title: t('platform:numberOfOutputs'),
      icon: null,
      units: '',
      isDecimalsOnly: false,
    },
  }

  const MultisigFilterPropsById: Record<
    MultisigFilterKeys,
    FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem &
      MultisigFilterContainerProps
  > = {
    input_multisig: {
      id: 'input_multisig',
      label: t('platform:multisig'),
      title: t('platform:input'),
      icon: null,
    },
    output_multisig: {
      id: 'output_multisig',
      label: t('platform:multisig'),
      title: t('platform:output'),
      icon: null,
    },
  }

  return {
    ComparatorFilterPropsById,
    MultisigFilterPropsById,
  }
}
