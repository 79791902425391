import {
  getBoundedDomainBlock,
  getNotGhostedBoundedDomainBlock,
  GetTransactionBlockReturn,
} from '@platform/components/ProbeSandbox/utils/getDomainBlock'
import { probeGraph } from '../../ProbeGraph'
import { probeState } from '../../ProbeState'
import { ProbeViewModel } from '../../ProbeViewModel'

type GhostedEntityOptions = {
  nodeKeys?: Array<string>
  edgeKeys?: Array<string>
  isExpanding?: boolean
}

export type IEntititiesGhosted = {
  toggleVisibleEntities: (options: GhostedEntityOptions) => void
  removeGhostedEntities: () => void
}

export class EntititiesGhosted implements EntititiesGhosted {
  constructor(private probeVM: ProbeViewModel) {}

  public removeGhostedEntities = () => {
    probeState.aboveOverlayNodeIds.forEach((nodeKey) => {
      this.probeVM.app.setNodeBelowOverlay(nodeKey)
    })
    probeState.aboveOverlayEdgeIds.forEach((edgeKey) => {
      this.probeVM.app.setEdgeBelowOverlay(edgeKey)
    })
    probeState.aboveOverlayNodeIds.clear()
    probeState.aboveOverlayEdgeIds.clear()
    this.probeVM.app.toggleOverlay(false)
  }

  private nodeHasSelectedNeighborNodes = (
    currentKey: string,
    selectedKeys: GetTransactionBlockReturn,
    key: string
  ) => {
    const nodeKeys = probeGraph.neighbors(key)
    const edges = probeGraph.edges(key)

    const haveEdgeSelected = edges
      .filter((edgeKey) => {
        if (!selectedKeys) return true

        return !selectedKeys.edgeKeys.includes(edgeKey)
      })
      .some((edgeKey) => {
        return probeState.selectedEdgeIds.has(edgeKey)
      })

    if (haveEdgeSelected) {
      return true
    }

    if (key === currentKey) {
      return nodeKeys.some((nodeKey) => {
        return probeState.selectedNodeIds.has(nodeKey)
      })
    }

    return (
      nodeKeys
        .filter((nodeKey) => nodeKey !== currentKey)
        .some((nodeKey) => {
          return probeState.selectedNodeIds.has(nodeKey)
        }) || probeState.selectedNodeIds.has(key)
    )
  }

  private nodeIsConnectedToSelectedVisibleNode = (
    currentNodeKey: string,
    selectedKeys: GetTransactionBlockReturn,
    edgeKey: string
  ) => {
    const source = probeGraph.source(edgeKey)
    const target = probeGraph.target(edgeKey)

    if (selectedKeys && selectedKeys?.edgeKeys.includes(edgeKey)) {
      if (!selectedKeys.nodeKeys.includes(source)) {
        return probeState.selectedNodeIds.has(source)
      }

      if (!selectedKeys.nodeKeys.includes(target)) {
        return probeState.selectedNodeIds.has(target)
      }

      return false
    }

    if (probeState.selectedEdgeIds.has(edgeKey)) {
      return true
    }

    return (
      (probeState.aboveOverlayNodeIds.has(source) &&
        probeState.selectedNodeIds.has(source) &&
        source !== currentNodeKey) ||
      (probeState.aboveOverlayNodeIds.has(target) &&
        probeState.selectedNodeIds.has(target) &&
        target !== currentNodeKey)
    )
  }

  private edgeHasSelectedNeighborNodes = (
    currentEdgeKey: string,
    selectedKeys: GetTransactionBlockReturn,
    nodeKey: string
  ) => {
    const nodeKeys = probeGraph.neighbors(nodeKey)
    const edges = probeGraph.edges(nodeKey)

    const haveEdgeSelected = edges.some((edgeKey) => {
      if (selectedKeys && selectedKeys.edgeKeys.includes(edgeKey)) {
        const source = probeGraph.source(edgeKey)
        const target = probeGraph.target(edgeKey)

        if (!selectedKeys.nodeKeys.includes(source)) {
          return probeState.selectedNodeIds.has(source)
        }

        if (!selectedKeys.nodeKeys.includes(target)) {
          return probeState.selectedNodeIds.has(target)
        }

        return false
      }

      return (
        probeState.aboveOverlayEdgeIds.has(edgeKey) &&
        edgeKey !== currentEdgeKey
      )
    })

    if (haveEdgeSelected) {
      return true
    }

    return nodeKeys
      .filter(
        (nodeKey) =>
          !selectedKeys ||
          (selectedKeys && !selectedKeys.nodeKeys.includes(nodeKey))
      )
      .some((nodeKey) => {
        return probeState.selectedNodeIds.has(nodeKey)
      })
  }

  private edgeIsConnectedToSelectedVisibleNode = (
    selectedKeys: GetTransactionBlockReturn,
    edgeKey: string
  ) => {
    const source = probeGraph.source(edgeKey)
    const target = probeGraph.target(edgeKey)

    if (selectedKeys && selectedKeys.edgeKeys.includes(edgeKey)) {
      if (!selectedKeys.nodeKeys.includes(source)) {
        return probeState.selectedNodeIds.has(source)
      }

      if (!selectedKeys.nodeKeys.includes(target)) {
        return probeState.selectedNodeIds.has(target)
      }

      return false
    }

    return (
      probeState.selectedNodeIds.has(source) ||
      probeState.selectedNodeIds.has(target)
    )
  }

  public toggleVisibleEntities = ({
    nodeKeys = [],
    edgeKeys = [],
    isExpanding = false,
  }: GhostedEntityOptions) => {
    if (!isExpanding) {
      this.removeGhostedEntities()
    }

    nodeKeys.forEach((key) => {
      const isSelectedNode = probeState.selectedNodeIds.has(key)
      const domainBlock = getNotGhostedBoundedDomainBlock(probeGraph, key)
      const domainSelectedBlock = getBoundedDomainBlock(probeGraph, key)

      if (domainBlock) {
        domainBlock.nodeKeys.forEach((nodeKey) => {
          if (!isSelectedNode || !isExpanding) {
            this.probeVM.app.setNodeAboveOverlay(nodeKey)
            probeState.aboveOverlayNodeIds.add(nodeKey)
          } else if (
            !this.nodeHasSelectedNeighborNodes(
              key,
              domainSelectedBlock,
              nodeKey
            )
          ) {
            this.probeVM.app.setNodeBelowOverlay(nodeKey)
            probeState.aboveOverlayNodeIds.delete(nodeKey)
          }
        })
        domainBlock.edgeKeys.forEach((edgeKey) => {
          if (!isSelectedNode || !isExpanding) {
            this.probeVM.app.setEdgeAboveOverlay(edgeKey)
            probeState.aboveOverlayEdgeIds.add(edgeKey)
          } else if (
            !this.nodeIsConnectedToSelectedVisibleNode(
              key,
              domainSelectedBlock,
              edgeKey
            )
          ) {
            this.probeVM.app.setEdgeBelowOverlay(edgeKey)
            probeState.aboveOverlayEdgeIds.delete(edgeKey)
          }
        })
      }
    })

    edgeKeys.forEach((key) => {
      const isSelectedNode = probeState.selectedEdgeIds.has(key)
      const domainBlock = getNotGhostedBoundedDomainBlock(probeGraph, key)
      const domainSelectedBlock = getBoundedDomainBlock(probeGraph, key)

      if (domainBlock) {
        domainBlock.nodeKeys.forEach((nodeKey) => {
          if (!isSelectedNode || !isExpanding) {
            this.probeVM.app.setNodeAboveOverlay(nodeKey)
            probeState.aboveOverlayNodeIds.add(nodeKey)
          } else if (
            !this.edgeHasSelectedNeighborNodes(
              key,
              domainSelectedBlock,
              nodeKey
            )
          ) {
            this.probeVM.app.setNodeBelowOverlay(nodeKey)
            probeState.aboveOverlayNodeIds.delete(nodeKey)
          }
        })
        domainBlock.edgeKeys.forEach((edgeKey) => {
          if (!isSelectedNode || !isExpanding) {
            this.probeVM.app.setEdgeAboveOverlay(edgeKey)
            probeState.aboveOverlayEdgeIds.add(edgeKey)
          } else if (
            !this.edgeIsConnectedToSelectedVisibleNode(
              domainSelectedBlock,
              edgeKey
            )
          ) {
            this.probeVM.app.setEdgeBelowOverlay(edgeKey)
            probeState.aboveOverlayEdgeIds.delete(edgeKey)
          }
        })
      }
    })

    if (
      probeState.aboveOverlayNodeIds.size > 0 ||
      probeState.aboveOverlayEdgeIds.size > 0
    ) {
      this.probeVM.app.toggleOverlay(true)
    } else {
      this.probeVM.app.toggleOverlay(false)
    }
  }
}
