import { Virtualizer } from '@clain/core/ui-kit'
import * as S from './SimpleTable.styles'

export type TableColumn<T> = {
  name: string
  render: (item: T, index: number) => React.ReactNode
}

type SimpleTableProps<
  T extends {
    key: string | number
  },
> = {
  data: T[]
  columns: TableColumn<T>[]
  rowHeight: number
  loading?: boolean
  notFoundComponent?: React.ReactElement
  virtualizer?: boolean
}

export const SimpleTable = <
  T extends {
    key: string | number
  },
>({
  columns,
  data,
  rowHeight,
  loading,
  notFoundComponent = null,
  virtualizer,
}: SimpleTableProps<T>) => {
  return (
    <S.SimpleTableStub isActive={loading}>
      {virtualizer ? (
        <Virtualizer
          data={data}
          rowHeight={rowHeight}
          overscan={15}
          render={({ virtualItems }) => {
            return virtualItems?.length
              ? virtualItems.map((virtualRow) => {
                  const dataItem = data[virtualRow.index]

                  return (
                    <S.SimpleTableRow
                      key={dataItem.key}
                      $height={rowHeight}
                      spaces={['none', 'xl']}
                    >
                      {columns.map(({ render }) =>
                        render(dataItem, virtualRow.index)
                      )}
                    </S.SimpleTableRow>
                  )
                })
              : notFoundComponent
          }}
        />
      ) : (
        data.map((item, index) => (
          <S.SimpleTableRow
            key={item.key}
            $height={rowHeight}
            spaces={['none', 'xl']}
          >
            {columns.map(({ render }) => render(item, index))}
          </S.SimpleTableRow>
        ))
      )}
    </S.SimpleTableStub>
  )
}
