import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import { KeyValue } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { useFormatDate } from '../../hooks'
import { formatDifficulty } from '@clain/core/utils/format'
import { getBlockSlug } from '@clain/core/utils/slug'
import { formatNumber as formatNumberU } from '@clain/core/utils/format'
import { useTranslation } from 'react-i18next'

import styles from './index.scss'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { CoinTypeEVM } from '../../types/coin'
import { RawBlockDataBlockEVM } from '../../apiServices'
import { Amount, TypographyNew } from '@clainio/web-platform'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

const BlockDetailsEvm = ({
  block,
  loading,
  coinType = 'eth',
}: {
  block: RawBlockDataBlockEVM
  currency: 'src' | 'usd'
  loading: boolean
  coinType: CoinTypeEVM
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })

  const coinTypeData = {
    eth: {
      Nonce: ({ nonce }) => (
        <Amount
          value={formatNumberU(+nonce, 0)}
          fullValue={`${nonce}`}
          copyValue={`${nonce}`}
          enabledCopy
          variant={'body100Normal'}
        />
      ),
      Difficulty: ({ difficulty }) => (
        <Amount
          value={formatDifficulty(difficulty)}
          fullValue={formatNumberU(difficulty, 0)}
          copyValue={formatNumberU(difficulty, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      ),
      'Total difficulty': ({ total_difficulty }) => (
        <Amount
          value={formatDifficulty(total_difficulty)}
          fullValue={formatNumberU(total_difficulty, 0)}
          copyValue={formatNumberU(total_difficulty, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      ),
    },
    bnb: {
      Nonce: ({ nonce }) => (
        <Amount
          value={formatNumberU(+nonce, 0)}
          fullValue={`${nonce}`}
          copyValue={`${nonce}`}
          enabledCopy
          variant={'body100Normal'}
        />
      ),
      Difficulty: ({ difficulty }) => (
        <Amount
          value={formatDifficulty(difficulty)}
          fullValue={formatNumberU(difficulty, 0)}
          copyValue={formatNumberU(difficulty, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      ),
      'Total difficulty': ({ total_difficulty }) => (
        <Amount
          value={formatDifficulty(total_difficulty)}
          fullValue={formatNumberU(total_difficulty, 0)}
          copyValue={formatNumberU(total_difficulty, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      ),
    },
    trx: {},
  }

  const generateDataItem = (key, valueFunction) => ({
    key,
    value: valueFunction,
  })

  const generateDataArray = () => {
    const commonItems = [
      generateDataItem(t('platform:height'), ({ height }) => (
        <Amount
          value={formatNumberU(height, 0)}
          fullValue={formatNumberU(height, 0)}
          copyValue={formatNumberU(height, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      )),
      generateDataItem(t('platform:confirmations'), ({ confirmations }) => (
        <Amount
          value={formatNumberU(confirmations, 0)}
          fullValue={formatNumberU(confirmations, 0)}
          copyValue={formatNumberU(confirmations, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      )),
      generateDataItem(t('platform:size'), ({ size }) => size),
      generateDataItem(t('platform:transactions'), ({ trx_count }) => (
        <Amount
          value={formatNumber(trx_count, 0)}
          fullValue={formatNumberU(trx_count, 0)}
          copyValue={formatNumberU(trx_count, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      )),
      generateDataItem(
        t('platform:unclesCount'),
        ({ uncles_count }) => uncles_count
      ),
      generateDataItem(t('platform:hash'), ({ hash }) => (
        <Amount
          value={getBlockSlug(hash)}
          fullValue={hash}
          copyValue={hash}
          enabledCopy
          variant={'body100NormalCode'}
        />
      )),
      generateDataItem(t('platform:time'), ({ time }) =>
        formatDate(fromUnixTime(time))
      ),
      generateDataItem(t('platform:gasUsed'), ({ gas_used }) => (
        <Amount
          value={`${formatNumber(+gas_used, 0)} gwei`}
          fullValue={formatNumberU(+gas_used, 0)}
          copyValue={formatNumberU(+gas_used, 0)}
          enabledCopy
          variant={'body100Normal'}
        />
      )),
      generateDataItem(t('platform:previousBlock'), ({ height }) => (
        <Link to={`/${coinType}/explorer/block/${height - 1}`}>
          <TypographyNew
            as={'a'}
            variant={'body100Normal'}
            color={'onBackgroundBase'}
          >
            {height - 1}
          </TypographyNew>
        </Link>
      )),
      generateDataItem(t('platform:nextBlock'), ({ height }) => (
        <Link to={`/${coinType}/explorer/block/${height + 1}`}>
          <TypographyNew
            as={'a'}
            variant={'body100Normal'}
            color={'onBackgroundBase'}
          >
            {height + 1}
          </TypographyNew>
        </Link>
      )),
    ]

    const specificItems = Object.entries(coinTypeData[coinType] || {}).map(
      ([key, valueFunction]) => generateDataItem(key, valueFunction)
    )

    return [...commonItems, ...specificItems]
  }

  const data = generateDataArray()

  return (
    <KeyValue
      className={cx('blockTable')}
      data={block}
      loading={loading}
      keyWidth={100}
    >
      {data}
    </KeyValue>
  )
}

export default observer(BlockDetailsEvm)
