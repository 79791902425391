import React from 'react'
import classnames from 'classnames/bind'
import { ReactComponent as ExternalLinkIcon } from '@fortawesome/fontawesome-free/svgs/solid/arrow-up-right-from-square.svg'

import NavLinkIcon from './NavLinkIcon'

import { NavItem as NavItemType } from '../../state'
import { Text } from '@clain/core/ui-kit'
import { Tooltip } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ExternalNavItemProps {
  className?: string
  item: NavItemType
  isOpen: boolean
}

const ExternalNavItem: React.FC<ExternalNavItemProps> = ({
  className,
  item,
  isOpen,
}) => {
  return (
    <li className={cx('ExternalNavItem', className)}>
      <Tooltip content={isOpen ? '' : item.label} placement="right">
        <a
          className={cx('NavItemLink')}
          href={item.to}
          target="_blank"
          rel="noreferrer"
        >
          <NavLinkIcon className={cx('NavItemIcon')} icon={item.icon} />
          <Text className={cx('NavItemLabel')}>{item.label}</Text>
          <ExternalLinkIcon className={cx('ExternalNavItemIcon')} />
        </a>
      </Tooltip>
    </li>
  )
}

export default ExternalNavItem
