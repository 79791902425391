export const POINTER_MOVE_DELTA = 6
export const CHUNK_SIZE = 1_000
export const GRID_SIZE = 25

export const OVERLAY_Z_INDEX = 4
export const EDGE_Z_INDEX = 1
export const NODE_Z_INDEX = 2
export const AREA_Z_INDEX = 0
export const NODE_ABOVE_OVERLAY_Z_INDEX = 10
export const EDGE_ABOVE_OVERLAY_Z_INDEX = 9
