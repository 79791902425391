import React, { FC } from 'react'
import classnames from 'classnames/bind'
import styles from './SelectReportTypeCard.scss'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
const cx = classnames.bind(styles)

export interface SelectReportTypeCardProps {
  title: string
  secondaryTitle?: string
  isDisabled?: boolean
  imageUrl: string
  onSelect?: (name: string) => void
  isSelected: boolean
  name: string
}

export const SelectReportTypeCard: FC<SelectReportTypeCardProps> = ({
  title,
  secondaryTitle,
  imageUrl,
  isDisabled = false,
  isSelected,
  onSelect,
  name,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={cx('SelectReportTypeCard', { isDisabled })}
      onClick={() => {
        if (!isDisabled) {
          return onSelect(name)
        }
        return
      }}
    >
      <RowDeprecated align={'between'}>
        <Typography variant="body1" color="black">
          {title}
        </Typography>
        {secondaryTitle && (
          <Typography variant={'body1'} color={'darkYellow1'}>
            {secondaryTitle}
          </Typography>
        )}
      </RowDeprecated>
      <img
        alt={t('platform:generateReportCardAlt', '', { name })}
        className={cx('ImageCard', { isSelected })}
        src={imageUrl}
      />
    </div>
  )
}
