import React from 'react'
import classnames from 'classnames/bind'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import { ReactComponent as ClusterIcon } from '@clain/core/assets/featured.svg'

import { capitalizeFirstLetter, DropdownMenu, Icon } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Tooltip } from '@clain/core/ui-kit'

import { useSelectedCoin } from '../CoinSelect'

import styles from './index.scss'

const cx = classnames.bind(styles)

export const currencyState = atom<'src' | 'usd'>({
  key: 'currencyState',
  default: 'src',
})

interface NavLinks {
  clusterAddress?: string | null
  probeTransaction?: string | null
}

const navLinks: NavLinks = {
  clusterAddress: null,
  probeTransaction: null,
}

const navLinksState = atom({
  key: 'navLinksState',
  default: navLinks,
})

export const useSelectedCurrency = () => useRecoilValue(currencyState)

export const useNavLinks = (newLinks: NavLinks, deps = []) => {
  const [, setState] = useRecoilState(navLinksState)

  React.useEffect(() => {
    setState(newLinks)
    return () => setState(navLinks)
  }, deps)
}

const Currency = React.forwardRef<
  HTMLDivElement,
  { value: 'src' | 'usd'; className?: string }
>(({ value, className }, ref) => {
  const coin = useSelectedCoin()

  return (
    <div ref={ref} className={cx('Currency', className)}>
      <Icon variant={value === 'src' ? capitalizeFirstLetter(coin) : 'Usd'} />
      <Typography variant="caption1" transform="uppercase">
        {value === 'src' ? coin : value}
      </Typography>
    </div>
  )
})

Currency.displayName = 'Currency'

export function CurrencySelect({ className }: { className?: string }) {
  const coin = useSelectedCoin()
  const [currency, setCurrency] = useRecoilState(currencyState)

  const currencyOptions = React.useMemo(
    () => [
      {
        label: <Currency value="src" />,
        value: 'src',
      },
      {
        label: <Currency value="usd" />,
        value: 'usd',
      },
    ],
    [currency, coin]
  )
  return (
    <DropdownMenu
      options={currencyOptions}
      value={currency}
      placement="bottom-end"
      offset={8}
      onChange={setCurrency}
    >
      <div>
        <Currency className={cx(className)} value={currency} />
      </div>
    </DropdownMenu>
  )
}

const navLinkContent = {
  clusterAddress: {
    tooltip: 'Cluster of this address',
    href: '/:coin/cluster/:id',
    icon: <ClusterIcon />,
  },
  probeTransaction: {
    tooltip: 'This transaction in probe',
    // href: '/probe/new?coin=:coin&trx=:id',
    icon: null,
    // icon: <ProbeIcon />,
  },
}

const Icons = () => {
  const coin = useSelectedCoin()
  const navLinks = useRecoilValue(navLinksState)
  const activeNavLinks = Object.entries(navLinks).filter(([_, id]) => id)

  return (
    <>
      {activeNavLinks.map(([navLink, id]) => {
        const { tooltip, href, icon } = navLinkContent[navLink]

        return (
          <Tooltip key={id} content={tooltip}>
            {href && (
              <Link to={href} params={{ id, coin }} className={cx('NavIcon')}>
                {icon}
              </Link>
            )}
          </Tooltip>
        )
      })}
      {activeNavLinks.length ? <span className={cx('Delimiter')} /> : null}
    </>
  )
}

const NavIcons = () => (
  <>
    <Icons />
    <CurrencySelect className={cx('CurrencyNavItem')} />
  </>
)

export default NavIcons
