import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Container, Icon } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'

import styles from './index.scss'
import { Typography } from '@clain/core/ui-kit'
import { CoinType } from '../../../../../../types/coin'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

const cx = classnames.bind(styles)

interface ClusterSearchCardProps {
  currency: CoinType
  score: number
  name: string
  onClick: () => void
}

export const ClusterSearchCard: React.FC<ClusterSearchCardProps> = ({
  currency,
  name,
  score,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Container gap={[0.75, 1]} className={cx('SearchCard')} onClick={onClick}>
      <RowDeprecated align="between">
        <RowDeprecated className={cx('LeftRow')} align="between">
          <RowDeprecated gap={0.5}>
            <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
            <Score value={score} useNewColors={true} size="sm" />
            <Typography variant="caption1" color="black">
              {name}
            </Typography>
          </RowDeprecated>
          <div className={cx('ItemTag')}>{t('platform:cluster')}</div>
        </RowDeprecated>
        <Typography
          className={cx('AddNode')}
          variant="caption1"
          color="black"
          transform="uppercase"
        >
          {t('platform:addNode')}
        </Typography>
      </RowDeprecated>
    </Container>
  )
}

interface AddressSearchCardProps {
  currency: CoinType
  score: number
  hash: string
  onClick: () => void
}

export const AddressSearchCard: React.FC<AddressSearchCardProps> = ({
  currency,
  hash,
  score,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Container gap={[0.75, 1]} className={cx('SearchCard')} onClick={onClick}>
      <RowDeprecated align="between">
        <RowDeprecated className={cx('LeftRow')} align="between">
          <RowDeprecated gap={0.5}>
            <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
            <Score value={score} useNewColors={true} size="sm" />
            <Typography variant="caption1" color="black">
              {hash}
            </Typography>
          </RowDeprecated>
          <div className={cx('ItemTag')}>{t('platform:address')}</div>
        </RowDeprecated>
        <Typography
          className={cx('AddNode')}
          variant="caption1"
          color="black"
          transform="uppercase"
        >
          {t('platform:addNode')}
        </Typography>
      </RowDeprecated>
    </Container>
  )
}

interface TransactionSearchCardProps {
  currency: CoinType
  hash: string
  onClick: () => void
}

export const TransactionSearchCard: React.FC<TransactionSearchCardProps> = ({
  currency,
  hash,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Container gap={[0.75, 1]} className={cx('SearchCard')} onClick={onClick}>
      <RowDeprecated align="between">
        <RowDeprecated className={cx('LeftRow')} align="between">
          <RowDeprecated gap={0.5}>
            <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
            <Typography variant="caption1" color="black">
              {hash}
            </Typography>
          </RowDeprecated>
          <div className={cx('ItemTag')}>{t('platform:transaction')}</div>
        </RowDeprecated>
        <Typography
          className={cx('AddNode')}
          variant="caption1"
          color="black"
          transform="uppercase"
        >
          {t('platform:addNode')}
        </Typography>
      </RowDeprecated>
    </Container>
  )
}
