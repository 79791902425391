import { useParams } from '@clain/core/Router/router'
import {
  BlockchainFilterPathParams,
  blockchainFilterTxnPath,
} from '@platform/constants'
import { IBlockchainFilterEntityPage } from '../types'

export const useBlockchainFilterEntityPage =
  (): IBlockchainFilterEntityPage => {
    const { entity } = useParams<BlockchainFilterPathParams>()
    const isTransactions = entity === blockchainFilterTxnPath

    return {
      isTransactions,
    }
  }
