import { convertFromUnixTimestamp } from '@clain/core/utils/date'
import { useSettings } from '../useSettings'

/**
 * IMPORTANT! Use inside mobx-react-lite observer!!!
 */
export const useConvertFromUnixTimestamp = () => {
  const { userSettings } = useSettings()
  return (timestamp: string | number) =>
    convertFromUnixTimestamp(timestamp, userSettings?.timezone)
}
