import React from 'react'
import { useTranslation } from 'react-i18next'

import { TypographyNew } from '@clain/core/ui-kit'
import { PortfolioAssetsAccordion } from '../PortfolioAssetsAccordion'
import * as S from './PortfolioAssets.styles'
import { PortfolioAssetsProps } from './PortfolioAssets.types'
import { PortfolioAsset } from './PortfolioAsset'

const PortfolioAssetsComponent: React.FC<PortfolioAssetsProps> = ({
  name,
  assets,
  totalAssetsCount,
  handleTokenFilter,
}) => {
  const { t } = useTranslation()

  return (
    <S.PortfolioAssetsContainer>
      <S.AssetsHeader>
        <TypographyNew variant={'body100Accent'} color={'onBackgroundBase'}>
          {name}
        </TypographyNew>
        <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
          ({totalAssetsCount}{' '}
          {t('platform:asset', '', { count: totalAssetsCount })})
        </TypographyNew>
      </S.AssetsHeader>

      <PortfolioAssetsAccordion>
        {assets.map((asset) => (
          <PortfolioAsset
            key={asset.id}
            asset={asset}
            handleTokenFilter={handleTokenFilter}
          ></PortfolioAsset>
        ))}
      </PortfolioAssetsAccordion>
    </S.PortfolioAssetsContainer>
  )
}

export const PortfolioAssets = React.memo(PortfolioAssetsComponent)
