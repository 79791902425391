import { Sprite, Container, Assets, Texture, Graphics } from 'pixi.js'
import EntityCache, { IEntityCache } from '../../entityCache'
import TextureCache from '../../textureCache'
import { IconOptions } from '../../types'
import formatColor from '../utils/formatColor'
import getTextureKey from '../utils/getTextureKey'
import { colorSvgFile, isSvgFile } from '@clain/core/ui-kit/icon'

type UpdateIconGfxProps = IconOptions

enum ICON_GFX {
  CORE = 'ICON_CORE',
  MASK = 'ICON_MASK',
  ICON = 'ICON',
  OUTER_BORDER = 'ICON_OUTER_BORDER',
}

class IconGfx {
  public gfx: Container
  private entityCache: IEntityCache

  constructor() {
    this.createGfx()
    this.entityCache = new EntityCache()
  }

  public updateGfx = async (
    params: UpdateIconGfxProps,
    textureCache: TextureCache
  ) => {
    await this.uploadIcon(params)
    this.updateIconMaskGfx(params)
    this.updateIconCoreGfx(params, textureCache)
    this.updateOuterBorderGfx(params, textureCache)
  }

  private drawShape = (graphics: Graphics, size: number, shape: string) => {
    switch (shape) {
      case 'circle':
        return graphics.circle(size, size, size)
    }
  }

  private uploadIcon = async (options: UpdateIconGfxProps) => {
    const {
      icon: resourceUrl,
      width,
      height,
      color,
      visibility = true,
    } = options

    const icon = this.gfx.getChildByName(ICON_GFX.ICON) as Sprite
    if (!resourceUrl) {
      icon.visible = false
      return
    }

    icon.visible = visibility
    const data = isSvgFile(resourceUrl)
      ? {
          src: color ? colorSvgFile(resourceUrl, color) : resourceUrl,
          data: {
            resolution: window.devicePixelRatio + 1.5,
          },
        }
      : { src: resourceUrl }

    const iconTexture = await Assets.load<Texture>(data)
    if (iconTexture && icon) {
      if (width) {
        iconTexture.orig.width = width
      }
      if (height) {
        iconTexture.orig.height = height
      }
      icon.texture = iconTexture
    }
  }

  private updateIconMaskGfx = (options: UpdateIconGfxProps) => {
    const size = options.width / 2
    const { shape } = options

    if (!options?.shape) return
    const icon = this.gfx.getChildByName(ICON_GFX.ICON) as Sprite
    const iconMaskKey = getTextureKey(ICON_GFX.MASK, size, shape)

    const iconMask = this.entityCache.get(iconMaskKey, () => {
      const iconMasckGraphics = this.gfx.getChildByName(
        ICON_GFX.MASK
      ) as Graphics

      iconMasckGraphics.circle(0, 0, size)
      const [color, alpha] = formatColor('#fff')
      iconMasckGraphics.fill({ color, alpha })
      iconMasckGraphics.x = icon.x
      iconMasckGraphics.y = icon.y

      return iconMasckGraphics
    })

    if (iconMask) {
      icon.mask = iconMask
    }
  }

  private updateIconCoreGfx = (
    options: UpdateIconGfxProps,
    textureCache: TextureCache
  ) => {
    const size = options.width / 2 + 1
    const { shape, opacity = 1 } = options

    if (!options?.fill || !shape) return

    const nodeCoreTextureKey = getTextureKey(
      ICON_GFX.CORE,
      options.fill,
      size,
      opacity,
      shape
    )
    const iconCoreTexture = textureCache.get(nodeCoreTextureKey, () => {
      const graphics = new Graphics()
      this.drawShape(graphics, size, shape)
      if (options.fill) {
        const [color, alpha] = formatColor(options.fill)
        graphics.fill({ color, alpha: opacity ?? alpha })
      }
      return graphics
    })

    const iconCore = this.gfx.getChildByName(ICON_GFX.CORE) as Sprite
    if (iconCore) {
      iconCore.texture = iconCoreTexture
    }
  }

  private updateOuterBorderGfx = (
    options: UpdateIconGfxProps,
    textureCache: TextureCache
  ) => {
    if (!options?.border) {
      return
    }
    const size = options.width / 2

    const nodeOuterBorderTextureKey = getTextureKey(
      ICON_GFX.OUTER_BORDER,
      size,
      size,
      options.border?.color,
      options.border?.width,
      options.border?.opacity
    )

    const nodeOuterBorderTexture = textureCache.get(
      nodeOuterBorderTextureKey,
      () => {
        const graphics = new Graphics()
        const borderSize =
          Math.max(size, size) + (options.border?.width ?? 0) / 2
        this.drawShape(graphics, borderSize, options.shape)

        if (options.border?.width) {
          const [color, alpha] = formatColor(options.border.color)
          const opacity = options.border.opacity ?? alpha
          graphics.stroke({
            width: options.border.width,
            color,
            alpha: opacity,
          })
        }
        return graphics
      }
    )
    const nodeOuterBorder = this.gfx.getChildByName(
      ICON_GFX.OUTER_BORDER
    ) as Sprite
    if (nodeOuterBorder) {
      nodeOuterBorder.texture = nodeOuterBorderTexture
    }
  }

  private createGfx = (): void => {
    if (this.gfx) {
      return
    }
    this.gfx = new Container()

    const iconCore = new Sprite()
    iconCore.label = ICON_GFX.CORE
    iconCore.anchor.set(0.5)
    this.gfx.addChild(iconCore)

    const icon = new Sprite()
    icon.label = ICON_GFX.ICON
    icon.anchor.set(0.5)
    this.gfx.addChild(icon)

    const iconMask = new Graphics()
    iconMask.label = ICON_GFX.MASK
    this.gfx.addChild(iconMask)

    const nodeOuterBorder = new Sprite()
    nodeOuterBorder.label = ICON_GFX.OUTER_BORDER
    nodeOuterBorder.anchor.set(0.5)
    this.gfx.addChild(nodeOuterBorder)
  }
}

export default IconGfx
