import http from '@clain/core/http'

import {
  convertToCase,
  convertToRawCaseUpdateParams,
  convertToFeed,
  convertToCaseData,
} from '../converters'
import {
  RawCase,
  Case,
  CaseUpdateParams,
  RawCaseUpdateParams,
  CreateProbeParams,
  UpdateProbeParams,
  Feed,
  RawFeed,
  CaseData,
  RawCaseData,
} from '../types'
import { WSState, Channel } from '../../../utils/WebSocketWrapper'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

const CHANNEL = 'cases'
const UPDATE_CASE_EVENT = 'update'
const CREATE_PROBE_EVENT = 'create_probe'
const UPDATE_PROBE_EVENT = 'update_probe'
const DELETE_PROBE_EVENT = 'delete_probe'
const LIST_ACTIVITIES = 'list_activities'
const CREATE_COMMENT = 'create_comment'
const NEW_ACTIVITIES = 'new_activities'
const CASE_UPDATE = 'case_update'

interface CaseServiceInit {
  wsState: WSState
}

export class CaseService {
  private wsState: WSState
  private channel: Channel

  private feedSubscriptionId?: number
  private caseUpdatesSubscriptionId?: number
  private id: string

  public init({ wsState }: CaseServiceInit) {
    this.wsState = wsState
  }

  public getCase = async (id: string): Promise<Case> => {
    this.id = id
    const topic = `${CHANNEL}:${id}`
    this.channel = this.wsState.channel(topic)

    const caseData = await this.channel.join<RawCase>().then(convertToCase)

    return caseData
  }

  public updateCase = async (params: CaseUpdateParams): Promise<CaseData> => {
    return this.channel
      .push<
        RawCase,
        RawCaseUpdateParams
      >(UPDATE_CASE_EVENT, convertToRawCaseUpdateParams(params))
      .then((data) => convertToCaseData(data.case))
  }

  public createProbe = async (
    params: CreateProbeParams = {
      name: 'Untitled Probe',
    }
  ): Promise<{ id: number } & CreateProbeParams> => {
    return this.channel
      .push<{ probe }, CreateProbeParams>(CREATE_PROBE_EVENT, params)
      .then(({ probe }) => probe)
  }

  public updateProbe = async (
    id: number,
    params: UpdateProbeParams
  ): Promise<unknown> => {
    return this.channel.push(UPDATE_PROBE_EVENT, { probe_id: id, ...params })
  }

  public deleteProbe = async (id: number): Promise<unknown> => {
    return this.channel.push<unknown, { probe_id: number }>(
      DELETE_PROBE_EVENT,
      {
        probe_id: id,
      }
    )
  }

  public getFeed = async (): Promise<Feed> => {
    return this.channel
      .push<{ activities: RawFeed }>(LIST_ACTIVITIES)
      .then(({ activities }) => convertToFeed(activities))
  }

  public subscribeToFeed = (cb: (feed: Feed) => void) => {
    this.feedSubscriptionId = this.channel.subscribe(
      NEW_ACTIVITIES,
      ({ activities }: { activities: RawFeed }) => {
        return cb(convertToFeed(activities))
      }
    )
  }

  public unsubscribeFromFeed = () => {
    return this.channel.unsubscribe(NEW_ACTIVITIES, this.feedSubscriptionId)
  }

  public subscribeToCaseUpdates = (cb: (caseData: CaseData) => void) => {
    this.caseUpdatesSubscriptionId = this.channel.subscribe(
      CASE_UPDATE,
      (caseData: RawCaseData) => {
        return cb(convertToCaseData(caseData))
      }
    )
  }

  public unsubscribeFromCaseUpdates = () => {
    return this.channel.unsubscribe(
      NEW_ACTIVITIES,
      this.caseUpdatesSubscriptionId
    )
  }

  public createComment = async (params: {
    comment: string
    files?: Array<File>
  }) => {
    const createComment = (files: Array<number> = []) => {
      return this.channel.push(CREATE_COMMENT, {
        comment: (params.comment || '').replaceAll('<p></p>', '<p><br></p>'),
        files,
      })
    }

    if (params.files?.length) {
      const formData = new FormData()

      params.files.forEach((file) => {
        formData.append('files[]', file)
      })

      const {
        data: { file_ids },
      } = await http.post<{ file_ids: Array<number> }>(
        `${config?.PLATFORM_API}/api/case/${this.id}/upload_files`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )

      return createComment(file_ids)
    }

    return createComment()
  }

  public clear = async () => {
    const topic = `${CHANNEL}:${this.id}`

    return this.wsState.clear(topic)
  }
}
