import { injectable } from 'inversify'
import { GenerateEdge } from './GenerateEdge'

import type { IGenerateEntities } from '../../GraphEvents.types'
import { ServerAddEvents, EventEdgeLink } from '../../types'
import { edgeKey } from '../../utils'

@injectable()
export class GenerateEdgeLink extends GenerateEdge<EventEdgeLink> {
  public produce = async (
    ...params: Parameters<IGenerateEntities<EventEdgeLink>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const edges = this.edges({ meta })

    const srcKey = data.sourceNodeKey
    const targetNodeKey = data.targetNodeKey

    const key = edgeKey(srcKey, targetNodeKey)

    if (!this.isEdgeExists(key)) {
      edges.push({
        type: 'add_edge',
        key: key,
        data: {
          srcKey: srcKey,
          dstKey: targetNodeKey,
          type: 'link',
        },
      })
    }

    return edges.acc
  }
}
