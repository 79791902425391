import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFilterSlice } from '../AddressUtxoFilters.service'
import { useAddressStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '../../../../../Filters/SelectFilter'
import { ADDRESS_TYPE, NOT_LITECOIN_ADDRESS_TYPE } from '../../../../constants'
import { useSelectFilterOptions } from '@platform/components/Filters/SelectFilter/SelectFilter.constants'

export const AddressTypeFilterMenuContainer = () => {
  const [addressType, { setFilter }] = useFilterSlice('addressType.data')
  const staticStore = useAddressStaticStore()
  const { t } = useTranslation()

  let options = NOT_LITECOIN_ADDRESS_TYPE
  switch (staticStore.current.currency) {
    case 'ltc':
      options = ADDRESS_TYPE
      break
    default:
      options = NOT_LITECOIN_ADDRESS_TYPE
  }

  return (
    <SelectFilterMenu
      selected={addressType ? addressType.value : null}
      title={t('platform:addressType')}
      onChange={setFilter}
      size={staticStore.current.filterSize}
      options={options}
    />
  )
}

export const AddressTypeFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('addressType.condition')
  const transactionStaticStore = useAddressStaticStore()
  const addressTypeOptions = useSelectFilterOptions()

  return (
    <SelectFilterCondition
      options={addressTypeOptions}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const AddressTypeFilterContainer = () => {
  const [addressType] = useFilterSlice('addressType.data')
  const [, { resetFilter }] = useFilterSlice('addressType')
  const [condition] = useFilterSlice('addressType.condition')
  const transactionStaticStore = useAddressStaticStore()
  const { t } = useTranslation()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={addressType ? addressType.label : ''}
      icon="FeatureFlag"
      size={transactionStaticStore.current.filterSize}
      label={t('platform:addressType')}
      clearFilter={resetFilter}
      menuComponent={<AddressTypeFilterMenuContainer />}
    />
  )
}
