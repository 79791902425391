import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { RowDeprecated } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import { Select } from '@clain/core/ui-kit'
import Filters2 from '@clain/core/Filters'
import { ButtonSelect } from '@clain/core/ui-kit'

import styles from './index.scss'
import { CasesFilters } from '../../types'
import { SearchTextField } from './Filters.styles'

const cx = classnames.bind(styles)

interface FiltersProps {
  filters: Filters2<CasesFilters>
}

const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const { t } = useTranslation()

  const sortingOptions = [
    {
      value: 'recent_update',
      label: t('platform:recentUpdateFirst'),
    },
    {
      value: 'oldest_update',
      label: t('platform:oldestUpdateFirst'),
    },
    {
      value: 'newest',
      label: t('platform:newestFirst'),
    },
    {
      value: 'oldest',
      label: t('platform:oldestFirst'),
    },
  ]

  const sharedOptions = [
    {
      value: 'my',
      label: t('platform:myCases'),
    },
    {
      value: 'shared',
      label: t('platform:sharedWithMe'),
    },
  ]

  return (
    <div className={cx('Filters')}>
      <RowDeprecated>
        <ButtonSelect
          options={sharedOptions}
          value={filters.value('shared')}
          onChange={filters.update('shared')}
          fullWidth={true}
          className={cx('Shared')}
        />
        <RowDeprecated gap={0.5} className={cx('Inputs')}>
          <CalendarInput
            className={cx('Input', 'date')}
            variant="outline"
            label={`${t('platform:open')}:`}
            placeholder={t('platform:selectDate')}
            value={filters.value('createdOn')}
            onChange={filters.update('createdOn')}
            clearable
          />
          <SearchTextField
            variant="outline"
            startAddon={{ iconVariant: 'Search' }}
            value={filters.value('search')}
            onChange={filters.update('search')}
            placeholder={t('platform:findCaseByNameDescriptionOrTag')}
            clearable
            onClear={filters.reset('search')}
          />
        </RowDeprecated>
      </RowDeprecated>
      <Select
        className={cx('SortingSelect')}
        value={filters.value('sortBy')}
        onChange={filters.update('sortBy')}
        options={sortingOptions}
        sameWidth={false}
      />
    </div>
  )
}

export default observer(Filters)
