import React from 'react'
import classnames from 'classnames/bind'

import { BlockchainIcon, CoinType, RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Token } from '../../types/converted/TokenBalance'

import styles from './index.scss'
import { isDefaultTokenId } from '../../utils/convertTokenBalances'

const cx = classnames.bind(styles)

type TokenItem = Pick<Token, 'id' | 'blockchain' | 'name' | 'icon'>

interface AssetsRowProps {
  tokens: Array<TokenItem>
  className?: string
  showRest?: boolean
  buttonMode?: boolean
}

const AssetsRow = React.forwardRef<HTMLDivElement, AssetsRowProps>(
  ({ tokens, className, showRest = true, buttonMode = false }, ref) => {
    if (!tokens?.length) return null

    const tokensWithPreview = tokens.slice(0, 3)
    const restTokensLength = tokens.slice(3).length

    return (
      <RowDeprecated
        gap={0.125}
        className={cx('Assets', { buttonMode }, className)}
        ref={ref}
      >
        {tokensWithPreview.map(({ id, blockchain, icon }) => {
          return (
            <BlockchainIcon
              key={id}
              size="sm"
              icon={icon}
              currency={blockchain.toLowerCase() as CoinType}
              color={isDefaultTokenId(id) ? 'original' : 'grey'}
            />
          )
        })}
        {showRest && restTokensLength > 0 && (
          <Typography color="grey2" variant="body1-sm">
            +{restTokensLength}
          </Typography>
        )}
      </RowDeprecated>
    )
  }
)

AssetsRow.displayName = 'AssetsRow'

export default AssetsRow
