import { ContainerModule, interfaces } from 'inversify'

import { DI_TYPES } from '@platform/di/DITypes'
import {
  PlotEntitiesOnGraph,
  IPlotEntitiesOnGraph,
  IPlotEntitiesState,
  PlotEntitiesState,
} from '@platform/modules'
import { IPlotEntities } from '@platform/components/ProbeSandbox/models'
import {
  getProbeModule,
  DI_PROBE_TYPES,
} from '@platform/components/ProbeSandbox/di'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IPlotEntitiesOnGraph>(DI_TYPES.PlotEntitiesOnGraph)
    .to(PlotEntitiesOnGraph)
    .inTransientScope()

  bind<IPlotEntitiesState>(DI_TYPES.PlotEntitiesState)
    .to(PlotEntitiesState)
    .inSingletonScope()

  bind<IPlotEntities>(DI_TYPES.PlotProbeEntities)
    .toDynamicValue(() => {
      return getProbeModule(DI_PROBE_TYPES.PlotProbeEntites)
    })
    .inTransientScope()
}

export const probeGraphModules = new ContainerModule(initializeModule)
