import { withErrorBoundary } from '@clain/core/ErrorBoundary'
import { InjectComponentModel } from '@clain/core/utils'
import {
  PresetsFiltersPanel,
  PresetsFiltersPanelProps,
} from '@platform/components/BlockchainFilter/ui'
import { toJS } from 'mobx'
import { dataTransactionsSearchViewModel } from '@platform/components/BlockchainFilter/di/transactions/containers'

export const PresetsFiltersPanelContainer = withErrorBoundary(
  InjectComponentModel<PresetsFiltersPanelProps>(PresetsFiltersPanel, {
    useFactory: () => {
      return {
        presetsCount: toJS(dataTransactionsSearchViewModel.presets?.length),
        isAlertActive: false,
        selectedPresetName: toJS(
          dataTransactionsSearchViewModel.selectedPreset?.name
        ),
      }
    },
  })
)
