import styled, { css } from 'styled-components'
import { RowAddressItemBase } from '../components/RowAddressItem.styles'
import { RowDeprecated, TypographyNew } from '@clainio/web-platform'
import { Stack } from '@clain/core/ui-kit'

export const RowAddressItemContainer = styled(Stack).attrs((props) => ({
  gap: 'md',
  align: 'center',
  ...props,
}))`
  height: 24px;
`

export const RowAddressItemUTXOStyled = styled(RowAddressItemBase)<{
  $direction?: 'left' | 'right'
  $isFullWidth?: boolean
  $withPaddings?: boolean
}>`
  ${({ $direction, $isFullWidth = false, $withPaddings = true }) => css`
    width: ${$isFullWidth ? '100%' : 'auto'};
    overflow-x: hidden;
    align-items: start;

    ${$withPaddings
      ? css`
          padding-left: ${$direction === 'left' ? '4px' : '8px'};
          padding-right: ${$direction === 'left' ? '8px' : '4px'};
        `
      : ''}
  `};
`

export const RowAddressItemUTXOContainer = styled(Stack).attrs((props) => ({
  direction: 'column',
  ...props,
}))`
  overflow: hidden;
`

export const AmountContainerStyled = styled(Stack)`
  width: 120px;
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
`
export const TokenIconStyled = styled.div`
  padding: 4px;
`
export const IconLeftRightWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  width: 24px;
`

export const TagsContainerStyled = styled(RowDeprecated)`
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: wrap;
`

export const TagsTitleStyled = styled(RowDeprecated)`
  width: 24px;
  height: 16px;
  overflow: hidden;
`

export const FullWidthFlexTypography = styled(TypographyNew)`
  flex: 1;
`
export const FullWidthFlexBlock = styled.div`
  flex: 1;
`
