import React, { memo, useCallback } from 'react'
import Chart from '@clain/core/Chart2'
import { FormatDatePreset } from '@clain/core/utils/date'
import { useFormatDate } from '../../hooks'
import { randomDate, randomInt } from '@clain/core/Chart2/mock.utils'

import { CollectionRow } from '../../apiServices/analytics'
import { useChartOptions } from './netflow.hooks'

const generateMockData = (): NetflowData => {
  const transactions = randomDate(randomInt(30, 60)).reduce(
    (result, day, _, arr) => {
      const value = randomInt(1, arr.length)
      return [...result, [day, value, 10, [[value, 10]]]]
    },
    []
  )

  return {
    balances: [],
    incoming: {
      Unknow: transactions,
    },
    outgoing: {
      Unknow: transactions,
    },
  }
}

type Row = number[]

interface NetflowData {
  balances: Row[]
  incoming: { [name: string]: CollectionRow[] }
  outgoing: { [name: string]: CollectionRow[] }
}

interface NetflowOptions {
  data: NetflowData
  groupBy?: 'day' | 'week'
  min?: Date
  max?: Date
  isLoading?: boolean
  formatOptions?: { currency: string; precision: number; decimals?: number }
  formatDate?: (date: Date, preset?: FormatDatePreset) => string
  useNewColors?: boolean
}

interface NetflowChartProps extends NetflowOptions {
  className?: string
  loading?: boolean
  updateTimestampsFilters?: (timestamp: number) => void
  updateDataZoom: (start: Date, end: Date) => void
  useNewColors?: boolean
}

const NetflowChart = ({
  className,
  loading,
  updateDataZoom,
  min,
  max,
  groupBy,
  data,
  formatOptions,
  useNewColors = false,
}: NetflowChartProps) => {
  const formatDate = useFormatDate()

  /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
  const mock = React.useMemo(() => {
    return generateMockData()
  }, [])
  const getOptions = useChartOptions()

  const weakState = React.useRef<any>()
  const [ticker, setTicker] = React.useState(false)

  React.useEffect(() => {
    if (data) {
      if (weakState.current) {
        weakState.current = {
          ...weakState.current,
          series: weakState.current.series.map((item) => ({
            ...item,
            data: [],
          })),
        }
      } else {
        weakState.current = getOptions({
          data: {
            balances: [],
            incoming: {},
            outgoing: {},
          },
          formatDate,
          useNewColors,
        })
      }
      setTicker(true)
    }
  }, [data, min, max, groupBy, formatOptions])

  React.useEffect(() => {
    if (ticker && data) {
      weakState.current = getOptions({
        data,
        min,
        max,
        groupBy,
        formatOptions,
        formatDate,
        useNewColors,
      })
      setTicker(false)
    }
  }, [ticker, data])

  const option =
    weakState.current ||
    getOptions({ data: mock, stub: true, formatDate, useNewColors })

  const handleZoom = useCallback(
    (event, instance) => {
      const { startValue, endValue } = instance.getOption().dataZoom[0]

      const start = new Date(startValue)
      const end = new Date(endValue)
      updateDataZoom(start, end)
    },
    [updateDataZoom]
  )

  const onEvents = React.useMemo(() => {
    return {
      datazoom: handleZoom,
    }
  }, [handleZoom])

  return (
    <Chart
      className={className}
      loading={loading}
      stub={!data}
      style={{ height: 400 }}
      option={option}
      onEvents={onEvents}
    />
  )
}

export default memo(NetflowChart)
