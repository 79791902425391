import React from 'react'
import { useParams } from '@clain/core/Router/router'
import { Container } from '@clain/core/ui-kit'
import { AlertCaution } from '@clain/core/ui-kit'
import { AddressesFiltersContainer } from './Filters/AddressesFiltersContainer'
import { AddressesListToPanel } from './AddressesListToPanel'
import { AddressesList } from './AddressesList'
import { observer } from 'mobx-react-lite'
import { dataAddressesSearchViewModel } from '../../model/addresses'
import { EntitiesNotFound } from '../../ui/EntitiesNotFound'
import {
  BlockchainFilterPagination,
  EntitiesModelProvider,
} from '../../containers'
import { ContentLayout } from '../../ui'
import { BlockchainFilterPathParams } from '@platform/constants'
import { useTranslation } from 'react-i18next'

export const btcToSatoshi = (value?: string) => {
  if (!value) return null
  return Math.round(Number(value) * 1e8)
}

const AddressesSearchComponent: React.FC = () => {
  const { t } = useTranslation()
  const {
    isTransactionsTableDataLoading,
    isTransactionsTableDataError,
    transactionsTableDataError,
    isTransactionsTableDataEmpty,
    isShowTopPanel,
  } = dataAddressesSearchViewModel
  const { coin } = useParams<BlockchainFilterPathParams>()

  return (
    <EntitiesModelProvider>
      <ContentLayout
        filtersComponent={<AddressesFiltersContainer />}
        topComponent={isShowTopPanel && <AddressesListToPanel />}
        loading={isTransactionsTableDataLoading}
        bottomComponent={
          <>
            {isTransactionsTableDataEmpty && (
              <EntitiesNotFound
                title={t('platform:noMatchesFound')}
                subtitle={t('platform:noMatchesFoundDescription', '', {
                  name: t('platform:addresses'),
                })}
                subtitleSecondary={t('platform:noMatchesFoundSuggestion')}
              />
            )}
            {isTransactionsTableDataError && (
              <Container gap={[0.5, 0]}>
                <AlertCaution variant="error">
                  {transactionsTableDataError}
                </AlertCaution>
              </Container>
            )}
            <BlockchainFilterPagination />
          </>
        }
      >
        <AddressesList coin={coin} />
      </ContentLayout>
    </EntitiesModelProvider>
  )
}

export const AddressesSearch = observer(AddressesSearchComponent)
