import { Sprite, Container, Assets, Graphics, Texture } from 'pixi.js'

import getTextureKey from '../utils/getTextureKey'
import TextureCache from '../../textureCache'
import { RhombusSatellite } from '../../types'
import formatColor from '../utils/formatColor'
import { colorSvgFile, isSvgFile } from '@clain/core/ui-kit/icon'

interface RhombusGfxProps {
  options: RhombusSatellite
  textureCache: TextureCache
}

enum SHAPE_GFX {
  CORE = 'SHAPE_CORE',
  OUTER_BORDER = 'SHAPE_OUTER_BORDER',
}

class RhombusGfx {
  public gfx: Container

  constructor() {
    this.createGfx()
  }

  private getAsset = (options: RhombusGfxProps['options']) => {
    let icon = options.icon

    if (isSvgFile(icon) && options?.iconColor) {
      icon = colorSvgFile(icon, options.iconColor)
    }

    return Assets.get<Texture>(icon)
  }

  private loadIconAsset = async (options: RhombusGfxProps['options']) => {
    const promises = []

    if (options.icon) {
      const data = isSvgFile(options.icon)
        ? {
            src: options?.iconColor
              ? colorSvgFile(options.icon, options.iconColor)
              : options.icon,
            data: {
              resolution: window.devicePixelRatio + 1.5,
            },
          }
        : { src: options.icon }

      promises.push(Assets.load(data))
    }

    await Promise.all(promises)
  }

  private drawRhombus = (graphics: Graphics, size: number) => {
    const topX = size / 2
    const topY = 0
    const rightX = size
    const rightY = size / 2
    const bottomX = size / 2
    const bottomY = size
    const leftX = 0
    const leftY = size / 2

    graphics.poly([topX, topY, rightX, rightY, bottomX, bottomY, leftX, leftY])
  }

  private renderIcon = (
    container: Container,
    options: RhombusGfxProps['options'],
    size: number
  ) => {
    if (options?.icon) {
      const icon = new Sprite()
      const iconTexture = this.getAsset(options)

      if (iconTexture) {
        iconTexture.orig.width = options.iconWidth
        iconTexture.orig.height = options.iconHeight

        icon.texture = iconTexture
      }
      icon.pivot.set(
        -(size - options.iconWidth) / 2,
        -(size - options.iconHeight) / 2
      )

      container.addChild(icon)
    }
  }

  public updateGfx = async ({
    options,
    textureCache,
  }: RhombusGfxProps): Promise<void> => {
    const coreTextureKey = getTextureKey(SHAPE_GFX.CORE, options)
    await this.loadIconAsset(options)

    const coreTexture = textureCache.get(coreTextureKey, () => {
      const container = new Container()
      const graphics = new Graphics()
      const size = options.size + (options.border?.width ?? 0) / 2

      this.drawRhombus(graphics, size)

      if (options.fill) {
        const [color, alpha] = formatColor(options.fill)

        graphics.fill({ color, alpha })
      }

      container.addChild(graphics)

      this.renderIcon(container, options, size)

      return container
    })

    const outerBorderTextureKey = getTextureKey(
      SHAPE_GFX.OUTER_BORDER,
      options.size,
      options.border?.color,
      options.border?.width,
      options.border?.opacity
    )
    const outerBorderTexture = textureCache.get(outerBorderTextureKey, () => {
      const graphics = new Graphics()

      const borderSize = options.size + (options.border?.width ?? 0) / 2

      this.drawRhombus(graphics, borderSize)

      if (options.border?.width) {
        const [color, alpha] = formatColor(options.border.color)
        const opacity = options.border.opacity ?? alpha

        graphics.stroke({
          width: options.border.width,
          color,
          alpha: opacity,
        })
      }

      return graphics
    })

    const outerBorder = this.gfx.getChildByName(
      SHAPE_GFX.OUTER_BORDER
    ) as Sprite
    outerBorder.texture = outerBorderTexture

    const core = this.gfx.getChildByName(SHAPE_GFX.CORE) as Sprite
    core.texture = coreTexture
  }

  private createGfx = (): void => {
    this.gfx = new Container()

    const core = new Sprite()
    core.label = SHAPE_GFX.CORE
    core.anchor.set(0.5)
    this.gfx.addChild(core)

    const outerBorder = new Sprite()
    outerBorder.label = SHAPE_GFX.OUTER_BORDER
    outerBorder.anchor.set(0.5)
    this.gfx.addChild(outerBorder)
  }
}

export default RhombusGfx
