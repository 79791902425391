import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  reaction,
} from 'mobx'
import { ActiveEntityType } from '../../../components/ProbeSandbox/vm/active-entity/ActiveEntityViewModel.types'
import { getOneYearZoomValue } from '../AnalyticsViewModel.utils'
import { groupSeriesByWeek, normalizeSeriesDate } from '@clain/core/Chart2'
import mapValues from 'lodash/mapValues'
import { FilterData } from './NetflowDataViewModel.types'
import {
  Token,
  TokenBalance,
} from '../../../components/ProbeSandbox/types/converted/TokenBalance'
import { CoinType, isUTXO } from '@clain/core/types/coin'
import { filterCollectionByScore } from '../../../components/FundsFlowChart/FundsFlowChart.utils'
import { equals } from 'ramda'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { NetflowDataViewModel } from './NetflowDataViewModel'
import { EXCLUDE_FILTERS_CURRENCY } from './NetflowChartViewModel.constants'
import {
  DEFAULT_USD_TOKEN,
  defaultTokenByCurrency,
} from '../../../components/ProbeSandbox/utils/convertTokenBalances'

const commonData = new StateViewModel<{
  clusterOrAddressId: number
  entityType: Omit<ActiveEntityType, 'cluster' | 'address'>
  blockchain: CoinType
  tokens: TokenBalance['token'][]
}>({
  clusterOrAddressId: null,
  entityType: null,
  blockchain: null,
  tokens: [],
})

const formattedParamsData = new StateViewModel<{
  includeTokens: string
  convertTo: string
}>({
  includeTokens: null,
  convertTo: null,
})

const filtersData = new StateViewModel<FilterData & { asset: Token }>({
  groupBy: null,
  score: [1, 10],
  calendar: [null, null],
  period: [null, null],
  asset: {} as Token,
  convertTo: null,
  includeTokens: null,
})

export class NetflowChartViewModel {
  private reactionDisposers: Array<IReactionDisposer> = []
  private netflowDataViewModel = new NetflowDataViewModel()
  private commonData = commonData

  public formattedParamsData = formattedParamsData
  public filtersData = filtersData

  @computed
  private get assetByIncludeTokens() {
    const blockchain = this.commonData.state.blockchain
    const includeTokens = this.formattedParamsData.state.includeTokens

    switch (includeTokens) {
      case null:
      case undefined: {
        return isUTXO(blockchain)
          ? defaultTokenByCurrency[blockchain].token
          : DEFAULT_USD_TOKEN.token
      }

      case 'all': {
        return DEFAULT_USD_TOKEN.token
      }
      case '0': {
        return defaultTokenByCurrency[blockchain].token
      }
      default: {
        const asset = this.assets.find(
          (asset) => asset.id.toString() === includeTokens
        )
        return asset
      }
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.commonData.state.blockchain]
  }

  @action
  private initReactions = () => {
    reaction(
      () => ({
        id: this.assetFilter?.id,
        tokenId: this.assetFilter?.id,
        symbol: this.assetFilter?.symbol,
        blockchain: this.commonData.state?.blockchain,
        convertToFilter: this.convertToFilter,
      }),
      ({ id, tokenId, blockchain, convertToFilter }) => {
        const assetIsBaseCurrency =
          defaultTokenByCurrency?.[blockchain]?.token?.id === tokenId
        const includeTokens = assetIsBaseCurrency
          ? '0'
          : !id
            ? 'all'
            : String(tokenId)
        const convertTo =
          convertToFilter === 'usd' || includeTokens === 'all'
            ? 'usd'
            : includeTokens
        this.formattedParamsData.updateState({
          includeTokens,
          convertTo,
        })
      }
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          includeTokens: this.formattedParamsData.state.includeTokens,
          convertTo: this.formattedParamsData.state.convertTo,
        }),
        ({ includeTokens, convertTo }) => {
          this.netflowDataViewModel.updateApiParams({
            includeTokens,
            convertTo,
          })
        }
      )
    )
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (
    clusterOrAddressId: number,
    entityType: Omit<ActiveEntityType, 'cluster' | 'address'>,
    blockchain: CoinType,
    timezone: string,
    tokens: Token[] = [],
    initialFilters: FilterData,
    defaultFilters?: FilterData
  ) => {
    const defFilters = defaultFilters || initialFilters
    this.commonData.initState({
      clusterOrAddressId,
      blockchain,
      entityType,
      tokens,
    })
    const defaultZoom = getOneYearZoomValue(timezone)

    this.setupFormattedParamsAndFilters(defFilters, defaultZoom, 'default')
    this.setupFormattedParamsAndFilters(initialFilters, defaultZoom, 'initial')

    this.netflowDataViewModel.init(
      clusterOrAddressId,
      blockchain,
      entityType === 'cluster' ? 'cluster_id' : 'aid',
      this.formattedParamsData.state.convertTo,
      this.formattedParamsData.state.includeTokens
    )
  }

  @action
  private setupFormattedParamsAndFilters = (
    filters: FilterData,
    defaultZoom: ReturnType<typeof getOneYearZoomValue>,
    type: 'initial' | 'default'
  ) => {
    let includeTokens = filters.includeTokens
    let convertTo = filters.convertTo

    if (type === 'initial') {
      if (filters.includeTokens == null) {
        includeTokens = isUTXO(this.commonData.state.blockchain) ? '0' : 'all'
      }
      if (filters.convertTo == null || filters.includeTokens == null) {
        convertTo = isUTXO(this.commonData.state.blockchain) ? '0' : 'usd'
      } else {
        convertTo = filters.convertTo === 'usd' ? 'usd' : filters.includeTokens
      }
    }

    if (type === 'default') {
      includeTokens = isUTXO(this.commonData.state.blockchain) ? '0' : 'all'
      convertTo = isUTXO(this.commonData.state.blockchain) ? '0' : 'usd'
    }

    const staticParams = {
      includeTokens,
      convertTo,
      groupBy: filters.groupBy || 'day',
      score: filters.score || [1, 10],
      calendar: filters.calendar || [defaultZoom.start, defaultZoom.end],
      period: filters.period || [defaultZoom.start, defaultZoom.end],
    }

    if (type === 'default') {
      this.formattedParamsData.initState({ includeTokens, convertTo })
      const params = {
        ...staticParams,
        asset: this.assetByIncludeTokens,
      }
      this.filtersData.initState(params)
    } else {
      this.formattedParamsData.updateState({ includeTokens, convertTo })
      const params = {
        ...staticParams,
        asset: this.assetByIncludeTokens,
      }
      this.filtersData.updateState(params)
    }
  }

  @computed.struct
  public get formatOptions() {
    const decimals =
      this.convertToFilter === 'usd' ? 0 : this.assetFilter?.decimals

    return {
      currency:
        this.convertToFilter === 'usd'
          ? this.convertToFilter
          : this.assetFilter?.symbol?.toLowerCase() || '',
      decimals,
      precision: 4,
    }
  }
  @computed
  public get period() {
    return this.filtersData.state.period
  }
  @computed
  public get groupByFilter() {
    return this.filtersData.state.groupBy
  }
  @computed
  public get calendarFilter() {
    return this.filtersData.state.calendar
  }
  @computed
  public get scoreFilter() {
    return this.filtersData.state.score
  }
  @computed
  public get convertToFilter() {
    return this.filtersData.state?.convertTo
  }

  @computed
  public get includeTokensFilter() {
    return this.filtersData.state.includeTokens
  }

  @computed
  public get assetFilter() {
    return this.filtersData.state?.asset || ({} as Token)
  }

  @computed
  public get updateFilters() {
    return this.filtersData.updateState
  }

  @computed
  public get assets() {
    return this.commonData.state.tokens
  }

  @action
  public fetchData = () => {
    this.initReactions()
    this.netflowDataViewModel.fetchData()
  }

  @computed
  public get seriesData() {
    const dataWithNormalizedDate = (() => {
      const data = this.netflowDataViewModel.data
      if (!data) return
      const { balances, incoming, outgoing } = data || {}

      return {
        balances: normalizeSeriesDate(balances),
        incoming: mapValues(incoming, normalizeSeriesDate),
        outgoing: mapValues(outgoing, normalizeSeriesDate),
      }
    })()

    const groupedDataByWeek = (() => {
      if (!dataWithNormalizedDate) return
      const { balances, incoming, outgoing } = dataWithNormalizedDate

      return {
        balances: groupSeriesByWeek(balances, 'cumulative'),
        incoming: mapValues(incoming, groupSeriesByWeek),
        outgoing: mapValues(outgoing, groupSeriesByWeek),
      }
    })()

    const groupedData =
      this.groupByFilter === 'week' ? groupedDataByWeek : dataWithNormalizedDate

    const filteredDataByScore = (() => {
      if (groupedData) {
        const { incoming, outgoing } = groupedData
        const [scoreMin, scoreMax] = this.scoreFilter
        if (scoreMin >= 1 || scoreMax <= 10) {
          return {
            balances: groupedData.balances,
            incoming: filterCollectionByScore(incoming, scoreMin, scoreMax),
            outgoing: filterCollectionByScore(outgoing, scoreMin, scoreMax),
          }
        }

        return groupedData
      }

      return groupedData
    })()

    return filteredDataByScore
  }

  @computed
  public get isFiltersChanged() {
    return !equals(this.filtersData.state, this.filtersData.initialState)
  }

  @computed
  public get isLoading() {
    return this.netflowDataViewModel.status === 'LOADING'
  }

  @computed
  public get isSuccess() {
    return this.netflowDataViewModel.status === 'SUCCESS'
  }

  @computed
  public get notFound() {
    return (
      !this.seriesData?.balances?.length &&
      this.netflowDataViewModel.status === 'SUCCESS'
    )
  }

  @action
  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.commonData.clearState()
    this.filtersData.clearState()
    this.formattedParamsData.clearState()
    this.netflowDataViewModel.clear()
  }

  @action
  public resetFilters = () => {
    this.filtersData.resetState()
    this.formattedParamsData.resetState()
  }
}
