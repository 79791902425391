import { FederatedMouseEvent } from 'pixi.js'

export const isMac = () => {
  return navigator.platform.indexOf('Mac') > -1
}

/**
 * Checks if shift or the "Mod" (command for MacOS, ctrl for others) button was pressed
 *
 * @param event Some interaction event
 * @returns Mod button is clicked
 */
export const shiftOrMod = (
  event:
    | PointerEvent
    | TouchEvent
    | MouseEvent
    | KeyboardEvent
    | FederatedMouseEvent,
  mode: 'shift' | 'mod' | 'both' = 'both'
) => {
  const modKey = isMac() ? 'metaKey' : 'ctrlKey'
  switch (mode) {
    case 'mod':
      return event[modKey]
    case 'shift':
      return event?.shiftKey
    case 'both':
    default: {
      return event?.shiftKey || event[modKey]
    }
  }
}

/**
 * Does nothing
 */
export const noop = () => {}

/**
 * Validates integer and float numbers
 *
 * @param number Some number
 * @returns
 */
export const validateNumber = (number: string) =>
  number.match(/^([0-9]*([.][0-9]{0,8})?|[.][0-9]+)$/)

export function hasDecimal(input: string | number): boolean {
  const numberString = typeof input === 'number' ? String(input) : input

  return numberString.includes('.')
}

export function removeLeadingZeros(input: string): string {
  if (!input) {
    return input
  }

  const value = convertCommaToPoint(input)

  // Split the input into integer and decimal parts
  const parts = value.split('.')
  let integerPart = parts[0].replace(/^0+/, '')
  const decimalPart = parts[1] || ''

  if (integerPart === '') {
    integerPart = '0'
  }

  // Concatenate the integer and decimal parts back together
  const result = hasDecimal(value)
    ? `${integerPart}.${decimalPart}`
    : integerPart

  return result
}

export function convertCommaToPoint(input: string): string {
  return input.replace(/,/g, '.')
}

/**
 * Max value in unsigned int4
 */
export const MAGIC_ADDRESS = 4294967295

export const isDev = process.env.ENV !== 'production'
