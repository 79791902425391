import styled, { css } from 'styled-components'
import {
  createTokenKey,
  RowDeprecated,
  selectToken,
} from '@clainio/web-platform'

export const FooterStyled = styled(RowDeprecated).attrs((props) => ({
  align: 'between',
  ...props,
}))`
  ${({ theme }) =>
    css({
      padding: '8px 20px',
      backgroundColor: selectToken(
        theme,
        createTokenKey(['color', 'secondary', 'container', 'base'])
      ),
      borderBottomLeftRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
      borderBottomRightRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
    })}
`
