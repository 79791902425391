import React from 'react'
import { usePresetsContext } from './PresetsContext'
import { Stack, Stub } from '@clainio/web-platform'
import { PresetsEmpty } from './PresetsEmpty'
import { CreatedPresetCard, CreatePresetCard } from './PresetCard'
import { PresetsBodyProps } from './Presets.types'

import { AnimatePresence } from 'framer-motion'
import { ExpandableAnimatedDiv } from '@clain/core/Animation'
import { observer } from 'mobx-react-lite'
import { useFormatDate } from '@platform/hooks'
import { toDate } from 'date-fns'

export const PresetsBody = observer((props: PresetsBodyProps) => {
  const {
    data,
    createPreset,
    deletePreset,
    selectedPreset,
    selectPreset,
    isLoading,
    isDisabledCreatePreset,
  } = props
  const { isCreatePresetOpen, toggleCreatePreset } = usePresetsContext()
  const formatDate = useFormatDate()
  const showCreatePreset = isCreatePresetOpen
  const showList = data && data.length > 0
  const showEmpty = !showList && !showCreatePreset

  return (
    <AnimatePresence initial={false}>
      <Stack direction="column" gap="xs">
        {showCreatePreset && (
          <ExpandableAnimatedDiv key="create-preset">
            <CreatePresetCard
              onSubmit={createPreset}
              onClose={toggleCreatePreset}
            />
          </ExpandableAnimatedDiv>
        )}
        {showList && (
          <Stack gap="xs" direction="column">
            {data.map(({ ...item }) => (
              <ExpandableAnimatedDiv key={item.id}>
                <Stub isActive={isLoading}>
                  <CreatedPresetCard
                    isLoading={isLoading && selectedPreset === item.id}
                    name={item.name}
                    id={item.id}
                    notificationCount={0}
                    isSelected={selectedPreset === item.id}
                    removeCard={deletePreset}
                    selectCard={() => selectPreset(item)}
                    isMonitored={item.monitoring}
                    timestamp={`${formatDate(
                      toDate(item.timestamp[0] * 1000),
                      'date-time-numeric'
                    )} - ${formatDate(
                      toDate(item.timestamp[1] * 1000),
                      'date-time-numeric'
                    )}`}
                  />
                </Stub>
              </ExpandableAnimatedDiv>
            ))}
          </Stack>
        )}

        {showEmpty && (
          <PresetsEmpty isDisabledCreatePreset={isDisabledCreatePreset} />
        )}
      </Stack>
    </AnimatePresence>
  )
})

PresetsBody.displayName = 'PresetsBody'
