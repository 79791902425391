import { action, computed, makeObservable, observable } from 'mobx'

import { apiServicesStateFacade } from './ApiServicesStateFacade'
import { queryParamsTrxSearchViewModel } from './QueryParamsTrxSearchViewModel'
import { transactionsSearchViewModel } from './TransactionsSearchViewModel'
import { TransactionSearchRequestParams } from '@platform/apiServices'
import { ERROR } from '@platform/constants'
import { inject, injectable } from 'inversify'
import {
  INormalizedTransactionPresetsServerData,
  INormalizedTransactionPresetsServerDataPayload,
  ITransactionFilterPresets,
} from '@platform/modules/transactionFilterPresets/TransactionFilterPresetsViewModel.types'

import { DATA_TRANSACTIONS_SEARCH_VIEW_MODEL } from '@platform/components/BlockchainFilter/di/transactions/constants'
import {
  convertFromUnixTimestamp,
  convertToUnixTimestamp,
} from '@clain/core/utils/date'
import { CoinType } from '@clain/core/ui-kit'

@injectable()
export class DataTransactionsSearchViewModel {
  private presetUpdateTimeout: NodeJS.Timeout | null = null
  private apiServicesStateFacade = apiServicesStateFacade
  private queryParamsTrxSearchViewModel = queryParamsTrxSearchViewModel
  private transactionsSearchViewModel = transactionsSearchViewModel
  @observable private isInitialized = false

  @observable timezone: string

  constructor(
    @inject(
      DATA_TRANSACTIONS_SEARCH_VIEW_MODEL.TransactionFilterPresetsViewModel
    )
    private transactionFilterPresetsViewModel: ITransactionFilterPresets
  ) {
    makeObservable(this)
  }

  @action
  public init = (currency: CoinType, timezone: string) => {
    this.timezone = timezone
    this.transactionsSearchViewModel.init(currency, timezone)
    if (!this.isInitialized) {
      this.transactionFilterPresetsViewModel
        .joinChannelAndGetPresets()
        .then(() => {
          this.isInitialized = true
        })
    }
  }

  public get createPresetAllowed() {
    const excludedFilters = new Set(['page', 'trx_time_from', 'trx_time_to'])
    return Object.entries(this.filters).some(
      ([key, value]) => value !== undefined && !excludedFilters.has(key)
    )
  }

  @computed.struct
  public get presets() {
    return this.transactionFilterPresetsViewModel.presets.filter(
      (preset) => preset.currency === this.blockchain
    )
  }

  public createPreset = async (
    data: Pick<
      INormalizedTransactionPresetsServerDataPayload,
      'name' | 'monitoring'
    >
  ) => {
    await this.transactionFilterPresetsViewModel.createPreset({
      ...data,
      currency: this.blockchain,
      filters: {
        ...this.filters,
        trx_time_from: convertToUnixTimestamp(
          this.filters.trx_time_from,
          this.timezone
        ),
        trx_time_to: convertToUnixTimestamp(
          this.filters.trx_time_to,
          this.timezone
        ),
      },
    })
  }

  public deletePreset = this.transactionFilterPresetsViewModel.deletePreset

  @computed.struct
  public get selectedPreset() {
    return this.transactionFilterPresetsViewModel?.selectedPreset?.currency ===
      this.blockchain
      ? this.transactionFilterPresetsViewModel?.selectedPreset
      : null
  }

  @action
  public setSelectedPreset = (
    preset: INormalizedTransactionPresetsServerData
  ) => {
    this.transactionFilterPresetsViewModel.setSelectedPreset(preset)

    const selectedFilters = preset.filters
    if (selectedFilters) {
      if (this.presetUpdateTimeout) {
        clearTimeout(this.presetUpdateTimeout)
      }

      this.presetUpdateTimeout = setTimeout(() => {
        this.apiServicesStateFacade.setApiParamsStateByService('transactions')({
          ...selectedFilters,
          page: this.filters.page,
          trx_time_from: convertFromUnixTimestamp(
            selectedFilters.trx_time_from,
            this.timezone
          ),
          trx_time_to: convertFromUnixTimestamp(
            selectedFilters.trx_time_to,
            this.timezone
          ),
        })
      }, 10)
    }
  }

  private clearSelectedPreset =
    this.transactionFilterPresetsViewModel.clearSelectedPreset

  @action
  public downloadCSV = () => {
    return this.transactionsSearchViewModel.downloadCSV()
  }

  public get isDownloadingCSV() {
    return this.transactionsSearchViewModel.isDownloadingCSV
  }

  public get blockchain() {
    return this.transactionsSearchViewModel.blockchain
  }

  @computed
  public get filters() {
    return this.apiServicesStateFacade.getApiParamsStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsInitialFilters() {
    return this.apiServicesStateFacade.getApiParamsInitialStateByService(
      'transactions'
    )
  }

  @computed
  public get transactionsUrlParams() {
    return this.queryParamsTrxSearchViewModel.getUrlParams
  }

  @computed.struct
  public get transactionsTableDefaultFilters() {
    return this.apiServicesStateFacade.getApiParamsDefaultStateByService(
      'transactions'
    )
  }

  @action
  public filtersUpdate = (filters: Partial<TransactionSearchRequestParams>) => {
    if (this.selectedPreset) {
      this.clearSelectedPreset()
    }
    return this.apiServicesStateFacade.updateApiParamsStateByService(
      'transactions'
    )(filters)
  }

  @computed
  public get tableData() {
    return this.apiServicesStateFacade.getStateByService('transactions')?.data
      ?.data
  }

  @computed
  public get isTransactionsTableDataLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'LOADING'
    )
  }

  @computed
  public get isTransactionsTableDataIdle() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'IDLE'
    )
  }

  @computed
  public get isTransactionsTableDataSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'SUCCESS'
    )
  }

  @computed
  public get isTransactionsTableDataError() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('transactions') ===
      'ERROR'
    )
  }

  @computed
  public get transactionsTableDataError() {
    const error =
      this.apiServicesStateFacade.getStateErrorByService('transactions')
    if (typeof error === 'string') {
      return error
    }
    return ERROR.networkError
  }

  @computed
  public get isTransactionsTableDataEmpty() {
    if (
      this.isTransactionsTableDataIdle ||
      this.isTransactionsTableDataLoading
    ) {
      return false
    }

    return !this.tableData?.transactions?.length
  }

  @computed
  public get isShowTopPanel() {
    if (
      this.isTransactionsTableDataIdle ||
      (this.isTransactionsTableDataLoading &&
        !this.tableData?.transactions?.length)
    ) {
      return false
    }

    return !!this.tableData?.transactions?.length
  }

  @computed
  public get isDisabledDownloadCsv() {
    return this.filters == null
  }

  private clearPresetUpdateTimeout = () => {
    if (this.presetUpdateTimeout) {
      clearTimeout(this.presetUpdateTimeout)
      this.presetUpdateTimeout = null
    }
  }

  @action
  public clear = () => {
    this.timezone = undefined
    this.clearPresetUpdateTimeout()
    this.transactionsSearchViewModel.clear()
  }
}
