import React from 'react'
import Fuse, { IFuseOptions } from 'fuse.js'

const useFuse = <OptionT>(
  data: Array<OptionT>,
  options?: IFuseOptions<OptionT>
) => {
  const indexer = React.useMemo(() => {
    return new Fuse(data, options)
  }, [options, data])

  const find = React.useCallback(
    (input = '') => {
      const searchText = input.trim()

      if (!searchText) {
        return data
      }

      return indexer.search(searchText).map(({ item }) => item)
    },
    [indexer, data]
  )

  return find
}

export default useFuse
