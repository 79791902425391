import { useTranslation } from 'react-i18next'
import { SelectFilterItem } from '@platform/components/Filters'
import { SelectFilterComparatorValue } from '@platform/components/Filters'

export const useTypeFilter = () => {
  const { t } = useTranslation()

  const TRX_TYPE_MENU_OPTIONS = [
    {
      label: 'P2WUNKN',
      value: 1,
    },
    {
      label: 'P2WKH',
      value: 2,
    },
    {
      label: 'P2PK',
      value: 3,
    },
    {
      label: 'P2PKH',
      value: 4,
    },
    {
      label: 'P2SH',
      value: 5,
    },
    {
      label: 'P2TR',
      value: 6,
    },
    {
      label: 'P2MS',
      value: 7,
    },
    {
      label: 'P2WSH',
      value: 8,
    },
  ]

  const TRX_INPUT_TYPE_FILTER_OPTIONS: SelectFilterItem<
    Extract<SelectFilterComparatorValue, 'is' | 'isAny'>
  >[] = [
    {
      label: t('platform:atLeastOne'),
      value: 'isAny',
    },
    {
      label: t('platform:allInputs'),
      value: 'is',
    },
  ]

  const TRX_OUTPUT_TYPE_FILTER_OPTIONS: SelectFilterItem<
    Extract<SelectFilterComparatorValue, 'is' | 'isAny'>
  >[] = [
    {
      label: t('platform:atLeastOne'),
      value: 'isAny',
    },
    {
      label: t('platform:allOutputs'),
      value: 'is',
    },
  ]

  const TRX_INPUT_TYPE_LABELS = {
    is: {
      text: [t('platform:allInputs')],
    },
    isAny: {
      text: [t('platform:atLeastOne')],
    },
  }

  const TRX_OUTPUT_TYPE_LABELS = {
    is: {
      text: [t('platform:allOutputs')],
    },
    isAny: {
      text: [t('platform:atLeastOne')],
    },
  }

  return {
    TRX_TYPE_MENU_OPTIONS,
    TRX_INPUT_TYPE_FILTER_OPTIONS,
    TRX_OUTPUT_TYPE_FILTER_OPTIONS,
    TRX_INPUT_TYPE_LABELS,
    TRX_OUTPUT_TYPE_LABELS,
  }
}
