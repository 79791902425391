import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { useTransactionStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
} from '../../../../../Filters/SelectFilter'
import { useSelectFilterOptions } from '../../../../../Filters/SelectFilter/SelectFilter.constants'

export const RbfFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('rbf.condition')
  const transactionStaticStore = useTransactionStaticStore()
  const rbfConditions = useSelectFilterOptions()

  return (
    <SelectFilterCondition
      options={rbfConditions}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const RbfFilterContainer = () => {
  const { t } = useTranslation()
  const [filter] = useFilterSlice('rbf.data')
  const [, { resetFilter }] = useFilterSlice('rbf')
  const [condition] = useFilterSlice('rbf.condition')
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      icon="RbfReplaceByFee"
      size={transactionStaticStore.current.filterSize}
      label={t('platform:transaction')}
      clearFilter={resetFilter}
      conditionComponent={<RbfFilterConditionContainer />}
    />
  )
}
