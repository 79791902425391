import {
  DEFAULT_LANG,
  LANG_NAMESPACES,
  LANG_STORAGE_KEY,
  LANGS_KEYS,
} from '@platform/constants/languege'
import { LanguageTypes } from '@platform/types'
import { I18nManager } from '@clain/core/utils/i18next'

const i18next = new I18nManager<LanguageTypes>({
  namespaces: LANG_NAMESPACES,
  fallbackLng: DEFAULT_LANG,
  supportedLanguages: LANGS_KEYS,
})

function isJson(str: string) {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}

const lang = localStorage.getItem(LANG_STORAGE_KEY)

i18next.initialize(isJson(lang) ? JSON.parse(lang) : (lang as LanguageTypes))

export { i18next }
