import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg'

import styles from './index.scss'
import { useTranslation } from '@clain/core/utils/i18next'

const cx = classnames.bind(styles)

interface LogoutButtonProps {
  logout: () => void
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ logout }) => {
  const { t } = useTranslation()

  return (
    <div className={cx('LogoutButton')} onClick={logout}>
      <div className={cx('LogoutButtonInner')}>
        <Typography className={cx('LogoutText')} variant="body2" color="grey5">
          {t('platform:logout')}
        </Typography>
        <LogoutIcon className={cx('LogoutIcon')} />
      </div>
    </div>
  )
}

export default LogoutButton
