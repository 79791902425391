import { RawClusterCounterparty } from '../types/raw/RawClusterCounterparty'
import { ClusterCounterparty } from '../types/converted/ClusterCounterparty'
import { convertToken } from './convertAddressBalance'
import { CoinType } from '@clain/core/ui-kit'

export const convertClusterCounterparty =
  (currency: CoinType) =>
  (rawCounterparty: RawClusterCounterparty): ClusterCounterparty => {
    return {
      type: 'cluster',
      active: rawCounterparty.active,
      category: rawCounterparty.category,
      clusterId: rawCounterparty.cluster_id,
      entity: rawCounterparty.entity,
      net: rawCounterparty.net,
      netUsd: rawCounterparty.net_usd,
      score: rawCounterparty.score,
      size: rawCounterparty.size,
      totalIn: rawCounterparty.total_in,
      totalInUsd: rawCounterparty.total_in_usd,
      totalOut: rawCounterparty.total_out,
      totalOutUsd: rawCounterparty.total_out_usd,
      tokens: rawCounterparty.tokens.map(convertToken(currency)),
    }
  }
