import { injectable, inject } from 'inversify'
import { IFactoryEdge } from '../../models'
import { _EdgeData, EdgeData, NodeData } from '../../types'
import { Factory } from '../Factory'
import { DI_TYPES } from '../../di/DITypes'

@injectable()
export class FactoryEdge
  implements IFactoryEdge<EdgeData, _EdgeData, NodeData>
{
  constructor(
    @inject(DI_TYPES.Factory)
    private factory: Factory
  ) {}

  public produce: IFactoryEdge<EdgeData, _EdgeData, NodeData>['produce'] = ({
    data: { srcKey, dstKey, edgeData },
  }) => {
    const type = edgeData?.edgeType

    if (type === 'address_belongs') {
      return this.factory.produceAddressBelongsProbeEdge(srcKey, dstKey)
    }

    if (type === 'transaction_address_belongs') {
      return this.factory.produceTransactionAddressBelongsProbeEdge(
        srcKey,
        dstKey
      )
    }

    if (type === 'flow') {
      return this.factory.produceFlowProbeEdge(edgeData, srcKey, dstKey)
    }

    if (type === 'utxo_transaction' || type === 'evm_transaction') {
      return this.factory.produceTransactionProbeEdge(srcKey, dstKey, edgeData)
    }

    if (type === 'attribution') {
      return this.factory.produceAttributionEdge(srcKey, dstKey)
    }

    if (type === 'link') {
      return this.factory.produceLinkEdge(srcKey, dstKey)
    }

    if (type === 'comment') {
      return this.factory.produceCommentProbeEdge(srcKey, dstKey)
    }

    if (type === 'demix') {
      return this.factory.produceDemixEdge(srcKey, dstKey, edgeData)
    }

    if (type === 'cross_chain_swap_flow') {
      return this.factory.produceCrossChainSwapFlowEdge(
        srcKey,
        dstKey,
        edgeData
      )
    }

    if (type === 'custom') {
      return this.factory.produceCustomEdge(srcKey, dstKey, edgeData)
    }
  }
}
