import {
  createTokenKey,
  RowDeprecated,
  selectToken,
  Typography,
} from '@clainio/web-platform'
import styled from 'styled-components'
import React from 'react'

export const RowStyled = styled((props) => (
  <RowDeprecated {...props} gap={0.25} />
))<{
  $maxWidth?: number
}>`
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '100%')};
  overflow: hidden;
`

export const HashContainer = styled(RowDeprecated)`
  flex: 0 1 auto;
  overflow: hidden;
`

export const IconContainer = styled.div`
  flex: 1;
`

export const TypographyNewEllipsisWrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

// TODO change to TypographyNew when #4B608A (grey-2) color will be defined on tokens
export const EntityDescriptionStyled = styled(Typography)`
  padding-left: 12px;
  border-left: ${({ theme }) =>
    `2px solid ${selectToken(theme, createTokenKey(['grey500']))}`};
  max-width: 70%;
`
export const RowBalanceWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 24px 0;
  justify-content: space-between;
  background-color: #f8f9fc; //TODO change to token color
`

export const RowBalanceItem = styled(RowDeprecated)`
  padding: 0 12px;
  flex: 1;
  &:not(:first-of-type) {
    border-left: 1px solid #bdc8df;
  }
`
export const RowBalanceItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #8d9ec1;
  background: #f1f3f9;
`
