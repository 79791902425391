import { isEVM, isUTXO, type CoinType } from '@clain/core/types/coin'
import { mergeByKeys } from '@clain/core/utils'
import { checkCoinsByType } from '@clain/core/utils/checkCoinByType'
import { EVM_COINS } from '@clain/core/utils/currency'
import { action, computed, makeObservable } from 'mobx'
import { Cluster } from '../../../types/converted/Cluster'
import { ClusterAddress } from '../../../types/converted/ClusterAddress'
import { ClusterCounterparty } from '../../../types/converted/ClusterCounterparty'
import { ClusterTransactionInputsOutputsAggregate } from '../../../types/converted/ClusterTransactionInputsOutputsAggregate'
import { Osint } from '../../../types/converted/Osint'
import { ActiveEntity } from '../ActiveEntity'
import type { IActiveEntityEvents } from '../ActiveEntityEvents/ActiveEntityEvents.types'
import {
  addressesFetch,
  addressesState,
  counterpartiesFetch,
  counterpartiesState,
  osintsFetch,
  osintsState,
  tokenByAddressFetch,
  tokenByAddressState,
  tokensFetch,
  tokensState,
  transactionsFetch,
  transactionsState,
} from '../ActiveEntityFetch'
import { ActiveEntityFetchState } from '../ActiveEntityFetchState'
import {
  addressesFilters,
  counterpartiesFilters,
  osintsFilters,
  tokenByAddressFilters,
  tokensFilters,
  transactionsFilters,
} from '../ActiveEntityFilters'
import { activeEntityClusterState } from '../ActiveEntityState'
import {
  INITIAL_FILTERS_CURRENCY,
  DEFAULT_OSINTS_FILTERS_CURRENCY,
  DEFAULT_TOKENS_FILTERS,
  DEFAULT_FILTERS_CURRENCY,
  EXCLUDE_FILTERS_CURRENCY,
} from '../constants'
import { applyAllTransferTokens } from '../../../utils/applyAllTransferTokens'
import { normalizeOldTransactionEvm } from '../helpers/normalizeTransaction'
import type {
  ClusterEntitiesFetch,
  ClusterEntitiesFetchState,
  ClusterEntitiesFilters,
} from './ActiveEntityCluster.types'
import { ClusterTransactionsAggregate } from '../../../types/converted/ClusterTransactions'
import { probeState } from '../../ProbeState'
import { DEFAULT_USD_TOKEN } from '../../../utils/convertTokenBalances'
import { ActiveEntityVisitedLink } from '../ActiveEntityVisited'

export class ActiveEntityCluster {
  private activeEntityVisitedLink = new ActiveEntityVisitedLink()
  private activeEntity: ActiveEntity<
    ClusterEntitiesFetchState,
    ClusterEntitiesFetch,
    ClusterEntitiesFilters,
    Cluster
  > = new ActiveEntity(
    {
      entitiesFetchState: {
        counterparties: counterpartiesState,
        transactions: transactionsState,
        addresses: addressesState,
        osints: osintsState,
        tokens: tokensState,
        tokenByAddress: tokenByAddressState,
      },
      entitiesFetch: {
        counterparties: counterpartiesFetch,
        transactions: transactionsFetch,
        addresses: addressesFetch,
        osints: osintsFetch,
        tokens: tokensFetch,
        tokenByAddress: tokenByAddressFetch,
      },
      entitiesFilters: {
        counterparties: counterpartiesFilters,
        transactions: transactionsFilters,
        addresses: addressesFilters,
        osints: osintsFilters,
        tokens: tokensFilters,
        tokenByAddress: tokenByAddressFilters,
      },
      entityState: activeEntityClusterState,
      entityKey: 'cluster',
    },
    probeState
  )

  constructor(private activeEntityEvents: IActiveEntityEvents) {
    makeObservable(this)
  }

  @action
  public init(currency: CoinType) {
    if (currency) {
      this.activeEntity.init(currency)

      counterpartiesFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      counterpartiesFilters.setDefaultFilters(
        DEFAULT_FILTERS_CURRENCY[currency]
      )

      transactionsFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      transactionsFilters.setDefaultFilters(DEFAULT_FILTERS_CURRENCY[currency])

      addressesFilters.initFilters(INITIAL_FILTERS_CURRENCY[currency])
      addressesFilters.setDefaultFilters(DEFAULT_FILTERS_CURRENCY[currency])

      osintsFilters.initFilters(DEFAULT_OSINTS_FILTERS_CURRENCY[currency])
      tokensFilters.initFilters(DEFAULT_TOKENS_FILTERS)
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.activeEntity.currency]
  }

  private get counterpartyFilterTokenId() {
    return counterpartiesFilters.filters?.includeTokens?.[0]?.id
  }

  @computed
  public get filters() {
    return this.activeEntity.entitiesFilters
  }

  public isVisitedLink = (
    ...args: Parameters<typeof this.activeEntityVisitedLink.has>
  ) => {
    return this.activeEntityVisitedLink.has(...args)
  }

  public visitedLinkAdd = (
    ...args: Parameters<typeof this.activeEntityVisitedLink.add>
  ) => {
    return this.activeEntityVisitedLink.add(...args)
  }

  @computed
  public get tokensBalance() {
    return tokensState.state?.tokens || []
  }

  @computed
  public get tokensTotalCount() {
    return tokensState.state?.pagination?.totalEntries || 0
  }

  @computed
  public get tokens() {
    return this.tokensBalance.map((token) => token.token) || []
  }

  @computed
  public get tokensWithoutAggregated() {
    return this.tokens.filter((token) => token.id !== DEFAULT_USD_TOKEN.id)
  }

  @computed
  public get transactionTokens() {
    if (
      isEVM(this.activeEntity.currency) &&
      tokenByAddressFilters.filters?.address &&
      checkCoinsByType(
        tokenByAddressFilters.filters?.address,
        EVM_COINS,
        'address'
      )
    ) {
      return tokenByAddressState.state ? [tokenByAddressState.state] : []
    }

    return this.tokensWithoutAggregated.filter(
      (token) => !token?.spam && !token?.scam
    )
  }

  @computed
  public get tokensLoading() {
    return tokensState.loading
  }

  @computed
  public get tokenByAddressLoading() {
    return tokenByAddressState.loading
  }

  @computed
  public get disabledTransactionAssetStaticSearch() {
    return (
      isEVM(this.activeEntity.currency) &&
      tokenByAddressFilters.filters?.address &&
      checkCoinsByType(
        tokenByAddressFilters.filters.address,
        EVM_COINS,
        'address'
      )
    )
  }

  @action
  public setTokenByAddress = (address: string) => {
    if (isUTXO(this.activeEntity.currency)) return

    if (checkCoinsByType(address, EVM_COINS, 'address')) {
      tokenByAddressFilters.updateFilters({ address })
      return
    }

    tokenByAddressFilters.updateFilters({ address: '' })
  }

  @computed
  public get counterparties() {
    return this.activeEntity.entitiesFetchState.counterparties
  }

  @computed
  public get addresses() {
    return this.activeEntity.entitiesFetchState.addresses
  }

  @computed.struct
  public get transactions(): ActiveEntityFetchState<ClusterTransactionsAggregate> {
    const result = this.activeEntity.entitiesFetchState.transactions

    if (result.state?.data?.transactions?.length) {
      return mergeByKeys(
        'state.data.transactions',
        applyAllTransferTokens(result.state?.data?.transactions),
        result
      ) as ActiveEntityFetchState<ClusterTransactionsAggregate>
    }

    return result as ActiveEntityFetchState<ClusterTransactionsAggregate>
  }

  @computed
  public get osints() {
    return this.activeEntity.entitiesFetchState.osints
  }

  @computed
  public get data() {
    return activeEntityClusterState.state
  }

  @action
  public clear() {
    this.activeEntity.clear()
    this.activeEntityVisitedLink.clear()
  }

  public update(...args: Parameters<typeof this.activeEntity.update>) {
    this.activeEntity.update(...args)
  }

  public toggleCounterparty = (data: ClusterCounterparty, select: boolean) => {
    this.toggleAllCounterparties([data], select)
  }

  public toggleAllCounterparties = (
    data: ClusterCounterparty[],
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterparty',
      data.map((item) => ({
        ...item,
        tokenId: this.counterpartyFilterTokenId,
      })),
      select
    )
  }

  public toggleCounterpartyInflow = async (
    data: ClusterCounterparty,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterpartyInflow',
      {
        entity: [{ clusterId: this.data.clusterId }, data],
        options: { tokenId: this.counterpartyFilterTokenId },
      },
      select
    )
  }

  public toggleCounterpartyOutflow = async (
    data: ClusterCounterparty,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterpartyOutflow',
      {
        entity: [{ clusterId: this.data.clusterId }, data],
        options: { tokenId: this.counterpartyFilterTokenId },
      },
      select
    )
  }

  public toggleCounterpartyNetflow = async (
    data: ClusterCounterparty,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'counterpartyNetflow',
      {
        entity: [{ clusterId: this.data.clusterId }, data],
        options: { tokenId: this.counterpartyFilterTokenId },
      },
      select
    )
  }

  public toggleTransaction = async (
    data: ClusterTransactionInputsOutputsAggregate,
    select: boolean
  ) => {
    this.toggleAllTransactions([data], select)
  }

  public toggleAllTransactions = async (
    data: Array<ClusterTransactionInputsOutputsAggregate>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit(
      'transaction',
      normalizeOldTransactionEvm(data),
      select
    )
  }

  public toggleAddress = async (data: ClusterAddress, select: boolean) => {
    this.toggleAllAddresses([data], select)
  }

  public toggleAllAddresses = async (
    data: Array<ClusterAddress>,
    select: boolean
  ) => {
    this.activeEntityEvents.emit('address', data, select)
  }

  public toggleOsint = async (data: Osint, select: boolean) => {
    this.toggleAllOsints([data], select)
  }

  public toggleAllOsints = async (data: Array<Osint>, select: boolean) => {
    this.activeEntityEvents.emit('osint', data, select)
  }
}
