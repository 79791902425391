import React from 'react'
import { InjectComponentModel } from '@clain/core/utils'
import { IBlockchainFilterView } from '../types/IBlockchainFilterView'
import { FiltersProvider } from '../context'
import { AddressesSearch } from '../entity/AddressesSearch'
import { TransactionsSearch } from '../entity/TransactionsSearch'
import { useBlockchainFilterPage } from '../hooks'

const _BlockchainFilterContent: React.FC<IBlockchainFilterView> = ({
  viewModel,
  isTransactions,
}) => {
  return (
    <FiltersProvider viewModel={viewModel}>
      {isTransactions ? <TransactionsSearch /> : <AddressesSearch />}
    </FiltersProvider>
  )
}

export const BlockchainFilterContent =
  InjectComponentModel<IBlockchainFilterView>(_BlockchainFilterContent, {
    useFactory: useBlockchainFilterPage,
  })
