import React from 'react'
import { FiltersBar, FiltersBarProps } from '../../FiltersBar'
import { TransferTypeFilterProps } from './TransferTypeFilter.types'
import { useComparators } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
import { useTransferTypeFilter } from './TransferTypeFilter.hooks'

export const TransferTypeFilter = ({
  selected,
  menuComponent,
  size = 'md',
  clearFilter,
}: TransferTypeFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  const { t } = useTranslation()
  const { optionsMap } = useTransferTypeFilter()
  const comparators = useComparators()

  if (!selected) {
    return null
  }

  return (
    <FiltersBar
      size={size}
      filterName={{
        label: t('platform:transferType'),
        icon: 'Transaction',
      }}
      operator={{
        label: comparators.is.text[0],
      }}
      filterValue={{
        label: optionsMap[selected],
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
