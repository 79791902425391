import React from 'react'
import { useTranslation } from 'react-i18next'

import { TagDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as AttentionIcon } from '@clain/core/assets/notification_attention.svg'

import BaseCard from '../BaseCard'
import { CaseImportanceDiff, User } from '../../../../types'
import { useSettings } from '../../../../../../hooks'
import { observer } from 'mobx-react-lite'

interface ImportanceChangeCardProps {
  date: Date
  user: User
  diff: CaseImportanceDiff
  className?: string
}

const ImportanceChangeCard: React.FC<ImportanceChangeCardProps> = ({
  date,
  className,
  diff,
}) => {
  const { t } = useTranslation()
  const settings = useSettings()
  const oldSeverity = settings.severities.filter((s) => s.label === diff.old)[0]
  const newSeverity = settings.severities.filter((s) => s.label === diff.new)[0]

  return (
    <BaseCard
      date={date}
      label={t('platform:severity')}
      icon={AttentionIcon}
      className={className}
      title={
        <>
          {t('platform:changedFrom')}{' '}
          <TagDeprecated
            size="md"
            style={{ background: oldSeverity?.color, color: '#fff' }}
          >
            {diff.old}
          </TagDeprecated>{' '}
          {t('platform:to')}{' '}
          <TagDeprecated
            size="md"
            style={{ background: newSeverity?.color, color: '#fff' }}
          >
            {diff.new}
          </TagDeprecated>
        </>
      }
    />
  )
}

export default observer(ImportanceChangeCard)
