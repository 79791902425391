import { Button, Stack, StatusBlock, Tooltip } from '@clainio/web-platform'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePresetsContext } from '@platform/components/BlockchainFilter/ui/PresetsList/PresetsContext'

export const PresetsEmpty = ({
  isDisabledCreatePreset,
}: {
  isDisabledCreatePreset: boolean
}) => {
  const { t } = useTranslation()
  const { toggleCreatePreset } = usePresetsContext()

  return (
    <Stack padding={['xxxxl', 'none', 'none', 'none']}>
      <StatusBlock gap={'xxl'}>
        <StatusBlock.Image maxWidth={74} type={'filterSlider'} />
        <StatusBlock.Title
          variant={'body100Normal'}
          color={'onBackgroundVariant1'}
        >
          {t('platform:presetCreateDescription')}
        </StatusBlock.Title>
        <Tooltip
          content={t('platform:noFiltersSelected')}
          disabled={!isDisabledCreatePreset}
        >
          <div>
            <Button
              disabled={isDisabledCreatePreset}
              onClick={toggleCreatePreset}
              variant={'outline'}
              size={'md'}
              color={'secondary'}
              endIcon={'Plus'}
            >
              {t('platform:addPreset')}
            </Button>
          </div>
        </Tooltip>
      </StatusBlock>
    </Stack>
  )
}
