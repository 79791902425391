import React from 'react'
import { FiltersBar } from '../../FiltersBar'
import {
  SelectFilterComparatorValue,
  SelectFilterProps,
  SelectFilterValue,
} from './SelectFilter.types'
import { comparators, useComparators } from '@clain/core/ui-kit'

export function SelectFilter<
  T extends SelectFilterComparatorValue,
  V extends SelectFilterValue,
>({
  value,
  selectedCondition,
  menuComponent,
  size = 'md',
  label,
  icon,
  iconLabel,
  variantLabel,
  conditionComponent,
  clearFilter,
  maxHeight,
  options: _options,
}: SelectFilterProps<T, V>) {
  const comparator = useComparators()
  const options = _options || comparator

  if (!selectedCondition || !value) {
    return null
  }

  return (
    <FiltersBar
      maxHeight={maxHeight}
      size={size}
      filterName={{ label, icon }}
      operator={{
        label: options[selectedCondition].text[0],
        operatorComponent: conditionComponent,
      }}
      filterValue={{
        label: `${value}`,
        icon: iconLabel,
        variant: variantLabel,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
