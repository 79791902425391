import {
  action,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx'
import { Notification } from '@clainio/web-platform'
import {
  notificationConfig,
  notificationToastOptions,
} from './ProbeGenerateReport.utils'
import { TabOption } from './ProbeGenerateModalContent/Report/Report.constants'

type IModalState =
  | 'SELECT_REPORT_TYPE'
  | 'GENERATING_REPORT'
  | 'GENERATED_REPORT'

type Error =
  | { error: 'Generating' }
  | { error: 'FromServer'; message: string }
  | null

export class GenerateReportStore {
  private reactionDisposers: Array<IReactionDisposer> = []
  @observable modalState: IModalState = 'SELECT_REPORT_TYPE'
  @observable graphNodeIds: Set<string> = new Set<string>()
  @observable selectedNodeIds: Set<string> = new Set<string>()
  @observable selectedEdgeIds: Set<string> = new Set<string>()
  @observable error: Error = null
  @observable report: string[] = []
  @observable selectedTab: TabOption['value'] = 'en'
  @observable feedbackStatus: {
    [key: string]: 'done' | 'initial'
  } = {}
  @observable feedbackState: {
    [key: string]: 'isHiddenProcess' | 'isHiddenFinished'
  } = {}

  constructor() {
    makeObservable(this)
  }

  @action init = () => {
    this.reactionDisposers.push(
      reaction(
        () => this.modalState,
        (modalState) => {
          if (modalState === 'GENERATED_REPORT' && !this.error) {
            Notification.notify(
              notificationConfig().success.text,
              { type: notificationConfig().success.type },
              notificationToastOptions
            )
          }
        }
      )
    )
  }

  @action
  setSelectReportStep = () => {
    this.error = null
    this.report = []
    this.modalState = 'SELECT_REPORT_TYPE'
  }

  @action
  setGeneratingReportStep = (
    graphNodeIds: Set<string>,
    selectedNodeIds: Set<string>,
    selectedEdgeIds: Set<string>
  ) => {
    this.modalState = 'GENERATING_REPORT'
    this.graphNodeIds = graphNodeIds
    this.selectedNodeIds = selectedNodeIds
    this.selectedEdgeIds = selectedEdgeIds
  }

  @action
  setGeneratedReportStep = () => {
    this.modalState = 'GENERATED_REPORT'
  }

  @action
  setError = (error: Error) => {
    this.error = error
  }

  @action
  setReport = (chunk: string) => {
    this.report.push(chunk)
  }

  @action
  setSelectedTab = (tab: TabOption['value']) => {
    this.selectedTab = tab
  }

  @action
  setFeedbackStatus = (tab: string, status: 'done' | 'initial') => {
    this.feedbackStatus[tab] = status
  }

  @action
  setFeedbackState = (
    tab: string,
    state: 'isHiddenProcess' | 'isHiddenFinished'
  ) => {
    this.feedbackState[tab] = state
  }

  @action
  resetFeedbackStatus = () => {
    this.feedbackStatus = {}
  }

  @action
  resetFeedbackState = () => {
    this.feedbackState = {}
  }

  @action
  clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.modalState = 'SELECT_REPORT_TYPE'
    this.graphNodeIds.clear()
    this.selectedNodeIds.clear()
    this.selectedEdgeIds.clear()
    this.error = null
    this.report = []
    this.selectedTab = 'en'
    this.feedbackStatus = {}
    this.feedbackState = {}
  }
}

const generateReportStore = new GenerateReportStore()

export default generateReportStore
