import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  transaction,
} from 'mobx'
import debounce from 'lodash/debounce'

import { AlertService } from './services'
import { AlertState } from './states'

import type {
  Alert,
  AlertCounts,
  AlertEvents,
  AlertEventsParams,
  AlertEventType,
  Alerts,
  AlertsCount,
  NewAlert,
  UpdateAlert,
  WatchAlertEventsRequest,
} from './types'
import type { SnakeToCamelCaseMapping } from '@clain/core/utilsTypes'
import { createHashByType, normalizeAlertsCount } from './AlertsViewModel.utils'
import type { AlertsCountById } from './AlertsViewModel.utils.types'
import { Asset } from '../../states/BlocksHeightState'
import { defaultTokenByCurrency } from '../../components/ProbeSandbox/utils/convertTokenBalances'
import { formatMoney } from '@clain/core/utils/format'

export type AlertEventsFilters = {
  sortBy?: 'amountUsd' | 'time' | 'tag'
  sortOrder?: 'asc' | 'desc'
  search?: string
  page?: number
  addressId?: number
  alertId?: number
}

interface AlertsViewModelProps {
  alertCtx: {
    alertState: AlertState
    alertService: AlertService
  }
}

export class AlertsViewModel {
  @computed public get alertsWithBaseTokens(): Alerts {
    if (!this.alertState.getAlerts) {
      return [] as Alerts
    }
    return this.alertState.getAlerts.map((alert) => {
      if (alert.asset) {
        return {
          ...alert,
          threshold: parseFloat(
            formatMoney({
              value: alert.threshold || 0,
              currency: alert.asset.currency,
              code: '',
              decimals: alert.asset.decimals,
            })
          ),
        }
      }
      const baseToken = defaultTokenByCurrency[alert.currency]?.token
      return {
        ...alert,
        asset: baseToken as unknown as Asset,
        threshold: parseFloat(
          formatMoney({
            value: alert.threshold || 0,
            currency: baseToken.blockchain,
            code: '',
            decimals: baseToken.decimals,
          })
        ),
      }
    })
  }

  @computed public get selectedAlert(): Alert | undefined {
    if (!this.alertEventsParams.alertId) {
      return undefined
    }

    return this.alertState.getAlerts.find(
      ({ id }) => this.alertEventsParams.alertId === id
    )
  }

  @computed public get selectedAlertCount(): AlertsCount | undefined {
    if (!this.alertEventsParams?.alertId) {
      return undefined
    }

    return this.alertState.counts.alertEventsCount[
      this.alertEventsParams.alertId
    ]
  }

  @computed public get alertsEvents() {
    return this.alertState.alerts.loading
  }

  @computed public get loadingAlerts() {
    return this.alertState.alerts.loading
  }

  @computed public get loadingMainAlerts() {
    return this.alertState.alerts.loadingMain
  }

  @computed public get events(): AlertEvents {
    return this.alertState.events.data
  }

  @computed public get loadingEvents() {
    return this.alertState.events.loading
  }

  @computed public get loadingMainEvents() {
    return this.alertState.events.loadingMain
  }

  @computed public get counts(): AlertCounts {
    return this.alertState.counts
  }

  @computed public get alertsCountById(): AlertsCountById {
    return normalizeAlertsCount(this.alertState.counts)
  }

  @computed public get showStub(): boolean {
    return !this.alertsWithBaseTokens?.length && !this.loadingMainAlerts
  }

  @computed public get showAlerts(): boolean {
    return Array.isArray(this.alertsWithBaseTokens)
  }

  @computed public get showEvents(): boolean {
    return Array.isArray(this.events?.events)
  }

  @computed public get alertEventsParams(): AlertEventsParams {
    return {
      sortBy: this.alertEventsFilters.sortBy,
      sortOrder: this.alertEventsFilters.sortOrder,
      search: this.searchDebounced,
      page: this.alertEventsFilters.page,
      addressId: this.alertEventsFilters.addressId,
      alertId: this.alertEventsFilters.alertId,
    }
  }

  @observable public alertEventsFilters: AlertEventsFilters = {
    search: '',
    page: 1,
  }
  @observable public searchDebounced = ''

  private alertState: AlertState
  private alertService: AlertService
  private disposers: Array<IReactionDisposer> = []

  public constructor({
    alertCtx: { alertState, alertService },
  }: AlertsViewModelProps) {
    makeObservable(this)
    this.alertState = alertState
    this.alertService = alertService
  }

  private normalizeTotalEntries(data: typeof this.alertState.events.data) {
    return {
      ...data,
      initTotalEntries: this.alertEventsParams.search
        ? (this.alertState?.events?.data?.initTotalEntries ?? 0)
        : (data?.totalEntries ?? 0),
    }
  }

  @action.bound
  public async init(id: string) {
    this.alertState.setCaseId(id)

    return this.alertService.initChannel(id).then((data) => {
      this.alertState.setCounts(data)

      return data
    })
  }

  @action.bound
  public initAlertEvents({
    addressId,
    type,
    params,
  }: {
    addressId?: number
    type?: AlertEventType
    params?: Pick<AlertEventsParams, 'alertId'>
  }) {
    if (params?.alertId) {
      this.alertEventsFilters.alertId = params.alertId
    }

    this.disposers.push(
      autorun(() => {
        this.alertState.setIsLoadingEvents(true)
        this.alertService
          .getAlertEvents({
            ...this.alertEventsParams,
            alertId: this.alertEventsParams?.alertId || params?.alertId,
            addressId,
            type,
          })
          .then((data) => {
            const alertId = this.alertEventsParams?.alertId || params?.alertId
            const events = this.normalizeTotalEntries(data)

            if (!alertId || !events?.events?.length) {
              this.alertState.setEvents(events)

              return
            }

            if (alertId) {
              const eventAlertId = events?.events?.[0]?.alert?.id

              if (eventAlertId === alertId) {
                this.alertState.setEvents(events)
              }
            }
          })
          .finally(() => {
            this.alertState.setIsLoadingEvents(false)
          })
      })
    )
  }

  @action.bound
  public initDataLoadingReaction(params?: {
    addressId?: number
    type?: 'cluster' | 'address'
  }) {
    const createHashKey = createHashByType(!!params?.addressId)

    const alertHash = createHashKey({ id: this.alertState.id, ...params })

    this.alertState.setActiveAlertHashKey(alertHash)

    this.alertState.setIsLoadingAlerts()
    this.alertService
      .getAlerts({
        addressId: params?.addressId,
      })
      .then(this.alertState.setAlerts)

    this.alertService.subscribeAlertCreated((createdAlert) => {
      this.alertState.putAlertByKey(
        createdAlert,
        createHashKey({
          id: this.alertState.id,
          addressId: createdAlert.addressId,
          type: createdAlert.type,
        })
      )
    })

    this.alertService.subscribeAlertDeleted((alertId) => {
      const index = this.alertsWithBaseTokens?.findIndex(
        ({ id }) => id === alertId
      )

      if (index >= 0) {
        this.alertState.removeAlert(index)
      }
    })

    this.alertService.subscribeAlertDeactivated((alertId) => {
      const index = this.alertsWithBaseTokens?.findIndex(
        ({ id }) => id === alertId
      )

      if (index >= 0) {
        this.alertState.updateAlert(index, (oldValue) => ({
          ...oldValue,
          active: false,
        }))
      }
    })

    this.alertService.subscribeAlertUpdated((updatedAlert) => {
      const index = this.alertsWithBaseTokens.findIndex(
        ({ id }) => id === updatedAlert.id
      )

      this.alertState.updateAlert(index, (oldValue) => ({
        ...oldValue,
        ...updatedAlert,
      }))
    })

    this.alertService.subscribeAlertEventsCreated((data) =>
      this.alertState.setEvents(this.normalizeTotalEntries(data))
    )

    this.alertService.subscribeAlertEventsMarkedSeen(this.alertState.setCounts)
    this.alertService.subscribeAlertEventsCountUpdated(
      this.alertState.setCounts
    )
  }

  public watchAlertEvents(
    params: SnakeToCamelCaseMapping<WatchAlertEventsRequest>
  ) {
    this.alertService.watchAlertEvents(params)
  }

  public unwatchAlertEvents(
    params: SnakeToCamelCaseMapping<WatchAlertEventsRequest>
  ) {
    this.alertService.unwatchAlertEvents(params)
  }

  @action.bound
  public updateAlertEventsFilters(filters: AlertEventsFilters) {
    /* batching updates */
    transaction(() => {
      Object.keys(filters).forEach(
        action((key) => {
          this.alertEventsFilters[key] = filters[key]
        })
      )
    })

    if (filters?.search) {
      this.updateSearchDebounced()
    }
  }

  @action.bound
  private updateSearchDebounced = debounce(
    action(() => {
      this.searchDebounced = this.alertEventsFilters.search
    }),
    300
  )

  @action.bound
  public toggleAlert(index: number) {
    const { id, active } = this.alertsWithBaseTokens[index]

    this.alertService.updateAlert(id, { active: !active })

    this.alertState.updateAlert(index, (oldValue) => ({
      ...oldValue,
      active: !oldValue.active,
    }))
  }

  @action.bound
  public removeAlert(index: number) {
    const { id } = this.alertsWithBaseTokens[index]

    this.alertService.deleteAlert(id)

    this.alertState.removeAlert(index)
    this.alertState.resetEvents()
  }

  @action.bound
  public updateAlert(index: number, updates: UpdateAlert) {
    const { id } = this.alertsWithBaseTokens[index]
    this.alertService.updateAlert(id, updates)
  }

  @action.bound
  public async createAlert(params: NewAlert) {
    const createdAlert = await this.alertService.createAlert(params)

    this.alertState.putAlert(createdAlert)
  }

  @action.bound
  public async markAllAlertEventsSeen() {
    const alertIds = this.alertsWithBaseTokens?.map((alert) => alert.id)

    if (alertIds.length) {
      const counts = await this.alertService.markAlertEventsSeen({ alertIds })
      this.alertState.setCounts(counts)
    }
  }

  @action.bound
  public async markAlertEventsSeen({ alertId }: { alertId: number }) {
    if (alertId) {
      const counts = await this.alertService.markAlertEventsSeen({
        alertIds: [alertId],
      })
      this.alertState.setCounts(counts)
    }
  }

  @action.bound
  public setTag(tag: string) {
    this.updateAlertEventsFilters({ search: tag })
  }

  @action.bound
  public clear() {
    this.disposers.forEach((dispose) => dispose())
    this.alertService.unsubscribeAlertCreated()
    this.alertService.unsubscribeAlertDeleted()
    this.alertService.unsubscribeAlertUpdated()
    this.alertService.unsubscribeAlertDeactivated()
    this.alertService.unsubscribeAlertEventsCreated()
    this.alertService.unsubscribeAlertEventsMarkedSeen()
    this.alertService.unsubscribeAlertEventsCountUpdated()
    this.alertService.clear()
  }
}
