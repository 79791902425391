import { pick } from 'ramda'
import { FlowEdgeData } from '../types/edgeEntitiesData/FlowEdgeData'
import {
  TransactionEdgeEVMData,
  TransactionEdgeUTXOData,
} from '../types/edgeEntitiesData/TransactionEdgeData'
import { AddressNodeDataUtxo } from '../types/nodeEntitiesData/AddressNodeDataBtc'
import { ClusterNodeData } from '../types/nodeEntitiesData/ClusterNodeData'
import { CommentPinNodeData } from '../types/nodeEntitiesData/CommentPinNodeData'
import { CommentPlugNodeData } from '../types/nodeEntitiesData/CommentPlugNodeData'
import { OsintNodeData } from '../types/nodeEntitiesData/OsintNodeData'
import { TransactionAddressNodeData } from '../types/nodeEntitiesData/TransactionAddressNodeData'
import { TransactionNodeDataUtxo } from '../types/nodeEntitiesData/TransactionNodeDataUtxo'
import { TransactionNodeDataEth } from '../types/nodeEntitiesData/TransactionNodeDataEth'
import {
  CreateServerEdgeData,
  CreateServerNodeData,
  ServerAddEdgeEvent,
  ServerAddEdgeEventCrossChain,
  ServerAddEdgeEventCustom,
  ServerAddEdgeEventDemix,
  ServerAddEdgeEventEvmTransaction,
  ServerAddEdgeEventFlow,
  ServerAddEdgeEventUtxoTransaction,
  ServerAddEdgeReceive,
  ServerAddNodeEvent,
  ServerAddNodeReceive,
  ServerEventNodeEdgeReceive,
  ServerNodeCommentPin,
  ServerNodeCommentPlug,
  ServerNodeEntity,
  ServerNodeEvent,
  ServerNodeOsint,
  ServerNodeText,
  ServerNodeUnsupportedAddress,
  ServerNodeUtxoTransactionAddress,
  ServerRemoveEdgeEvent,
  ServerRemoveEdgeReceive,
  ServerRemoveNodeEvent,
  ServerRemoveNodeReceive,
  ServerUpdateEdgeEvent,
  ServerUpdateEdgeReceive,
  ServerUpdateNodeEvent,
  ServerUpdateNodeReceive,
  ServerUpdatePositionNodeEvent,
  ServerUpdatePositionNodeReceive,
} from '../types/serverData'
import { DemixNodeData } from '../types/nodeEntitiesData/DemixNodeData'
import { DemixEdgeData } from '../types/edgeEntitiesData/DemixEdgeData'
import { TextNodeData } from '../types/nodeEntitiesData/TextNodeData'
import { UnsupportedAddressNodeData } from '../types/nodeEntitiesData/UnsupportedAddressNodeData'
import { CrossChainSwapFlowEdgeData } from '../types/edgeEntitiesData/CrossChainSwapFlowEdgeData'
import { CustomEdgeData } from '../types/edgeEntitiesData/CustomEdgeData'

const normalizeNodeAddress = (
  data: CreateServerNodeData<AddressNodeDataUtxo>
): ServerNodeEntity => {
  return {
    type: data.nodeData.nodeType,
    id: data.nodeData.addressId,
    position: data.position,
    currency: data.nodeData.currency,
  }
}

const normalizeNodeUnsupportedAddress = (
  data: CreateServerNodeData<UnsupportedAddressNodeData>
): ServerNodeUnsupportedAddress => {
  return {
    type: data.nodeData.nodeType,
    position: data.position,
    nodeData: {
      unsupportedCurrency: data.nodeData.unsupportedCurrency,
      address: data.nodeData.address,
    },
  }
}

const normalizeNodeCluster = (
  data: CreateServerNodeData<ClusterNodeData>
): ServerNodeEntity => {
  return {
    type: data.nodeData.nodeType,
    id: data.nodeData.clusterId,
    position: data.position,
    currency: data.nodeData.currency,
  }
}

const normalizeNodeDemix = (
  data: CreateServerNodeData<DemixNodeData>
): ServerNodeEntity => {
  return {
    type: data.nodeData.nodeType,
    id: data.nodeData.id,
    position: data.position,
    currency: data.nodeData.currency,
  }
}

const normalizeNodeUtxoTransaction = (
  data: CreateServerNodeData<TransactionNodeDataUtxo>
): ServerNodeEntity => {
  return {
    type: data.nodeData.nodeType,
    id: data.nodeData.id,
    position: data.position,
    currency: data.nodeData.currency,
  }
}

const normalizeNodeEvmTransaction = (
  data: CreateServerNodeData<TransactionNodeDataEth>
): ServerNodeEntity => {
  return {
    type: data.nodeData.nodeType,
    id: data.nodeData.id,
    position: data.position,
    currency: data.nodeData.currency,
  }
}

const normalizeNodeUtxoTransactionAdderess = (
  data: CreateServerNodeData<TransactionAddressNodeData>
): ServerNodeUtxoTransactionAddress => {
  return {
    type: data.nodeData.nodeType,
    id: data.id,
    position: data.position,
    currency: data.nodeData.currency,
    nodeData: pick(
      ['inputId', 'outputId', 'position', 'addressType'],
      data.nodeData
    ),
  }
}

const normalizeNodeComment = (
  data: CreateServerNodeData<CommentPinNodeData | CommentPlugNodeData> & {
    key: string
  }
): ServerNodeCommentPin | ServerNodeCommentPlug => {
  return {
    id: data.key,
    position: data.position,
    type: data.nodeData.nodeType,
    nodeData: data.nodeData as CommentPinNodeData & CommentPlugNodeData,
  }
}

const normalizeText = (
  data: CreateServerNodeData<TextNodeData> & {
    key: string
  }
): ServerNodeText => {
  return {
    id: data.key,
    position: data.position,
    type: data.nodeData.nodeType,
    nodeData: data.nodeData as TextNodeData,
  }
}

const normalizeNodeOsint = (
  data: CreateServerNodeData<OsintNodeData>
): ServerNodeOsint => {
  return {
    type: data.nodeData.nodeType,
    id: data.nodeData.id,
    position: data.position,
    currency: data.nodeData.currency,
    nodeData: pick(['address'], data.nodeData),
  }
}

const normalizeEdgeUtxoTransaction = (
  data: CreateServerEdgeData<TransactionEdgeUTXOData>
): ServerAddEdgeEventUtxoTransaction => {
  return {
    ...pick(['srcKey', 'dstKey'], data),
    type: data.edgeData.edgeType,
    edgeData: pick(['type', 'trxId', 'index'], data.edgeData),
  }
}

const normalizeEdgeEvmTransaction = (
  data: CreateServerEdgeData<TransactionEdgeEVMData>
): ServerAddEdgeEventEvmTransaction => {
  return {
    ...pick(['srcKey', 'dstKey'], data),
    type: data.edgeData.edgeType,
    edgeData: pick(['type', 'trxId', 'index'], data.edgeData),
  }
}

const normalizeEdgeFlow = (
  data: CreateServerEdgeData<FlowEdgeData>
): ServerAddEdgeEventFlow => {
  return {
    ...pick(['srcKey', 'dstKey'], data),
    type: data.edgeData.edgeType,
    edgeData: {
      category: data?.edgeData?.category,
      ...(data.edgeData?.token?.id ? { tokenId: data.edgeData.token.id } : {}),
    },
  }
}

const normalizeEdgeCrossChainSwapFlow = (
  data: CreateServerEdgeData<CrossChainSwapFlowEdgeData>
): ServerAddEdgeEventCrossChain => {
  return {
    ...pick(['srcKey', 'dstKey'], data),
    type: data.edgeData.edgeType,
    edgeData: {
      id: data.edgeData.id,
    },
  }
}

const normalizeEdgeCustom = (
  data: CreateServerEdgeData<CustomEdgeData>
): ServerAddEdgeEventCustom => {
  return {
    ...pick(['srcKey', 'dstKey'], data),
    type: data.edgeData.edgeType,
    edgeData: data.edgeData,
  }
}

const normalizeEdgeDemix = (
  data: CreateServerEdgeData<DemixEdgeData>
): ServerAddEdgeEventDemix => {
  return {
    ...pick(['srcKey', 'dstKey'], data),
    type: data.edgeData.edgeType,
    edgeData: data.edgeData,
  }
}

const normalizeAddNode = (event: ServerAddNodeReceive): ServerAddNodeEvent => {
  let data: ServerAddNodeEvent['data']

  if (event.data.nodeData.nodeType === 'cluster') {
    data = normalizeNodeCluster(
      event.data as CreateServerNodeData<ClusterNodeData>
    )
  } else if (event.data.nodeData.nodeType === 'address') {
    data = normalizeNodeAddress(
      event.data as CreateServerNodeData<AddressNodeDataUtxo>
    )
  } else if (event.data.nodeData.nodeType === 'unsupported_address') {
    data = normalizeNodeUnsupportedAddress(
      event.data as CreateServerNodeData<UnsupportedAddressNodeData>
    )
  } else if (event.data.nodeData.nodeType === 'utxo_transaction') {
    data = normalizeNodeUtxoTransaction(
      event.data as CreateServerNodeData<TransactionNodeDataUtxo>
    )
  } else if (event.data.nodeData.nodeType === 'utxo_transaction_address') {
    data = normalizeNodeUtxoTransactionAdderess(
      event.data as CreateServerNodeData<TransactionAddressNodeData>
    )
  } else if (event.data.nodeData.nodeType === 'evm_transaction') {
    data = normalizeNodeEvmTransaction(
      event.data as CreateServerNodeData<TransactionNodeDataEth>
    )
  } else if (event.data.nodeData.nodeType === 'osint') {
    data = normalizeNodeOsint(event.data as CreateServerNodeData<OsintNodeData>)
  } else if (event.data.nodeData.nodeType === 'demix') {
    data = normalizeNodeDemix(event.data as CreateServerNodeData<DemixNodeData>)
  } else if (
    event.data.nodeData.nodeType === 'comment_pin' ||
    event.data.nodeData.nodeType === 'comment_plug'
  ) {
    data = normalizeNodeComment({
      ...event.data,
      key: event.key,
    } as CreateServerNodeData<CommentPinNodeData | CommentPlugNodeData> & {
      key: string
    })
  } else if (event.data.nodeData.nodeType === 'text') {
    data = normalizeText({
      ...event.data,
      key: event.key,
    } as CreateServerNodeData<TextNodeData> & { key: string })
  }

  return {
    type: 'add_node',
    key: event.key,
    data,
  }
}

const normalizeAddEdge = (event: ServerAddEdgeReceive): ServerAddEdgeEvent => {
  let data: ServerAddEdgeEvent['data']

  if (event.data.edgeData.edgeType === 'utxo_transaction') {
    data = normalizeEdgeUtxoTransaction(
      event.data as CreateServerEdgeData<TransactionEdgeUTXOData>
    )
  } else if (event.data.edgeData.edgeType === 'evm_transaction') {
    data = normalizeEdgeEvmTransaction(
      event.data as CreateServerEdgeData<TransactionEdgeEVMData>
    )
  } else if (event.data.edgeData.edgeType === 'flow') {
    data = normalizeEdgeFlow(event.data as CreateServerEdgeData<FlowEdgeData>)
  } else if (event.data.edgeData.edgeType === 'transaction_address_belongs') {
    data = normalizeEdgeFlow(event.data as CreateServerEdgeData<FlowEdgeData>)
  } else if (event.data.edgeData.edgeType === 'demix') {
    data = normalizeEdgeDemix(event.data as CreateServerEdgeData<DemixEdgeData>)
  } else if (event.data.edgeData.edgeType === 'cross_chain_swap_flow') {
    data = normalizeEdgeCrossChainSwapFlow(
      event.data as CreateServerEdgeData<CrossChainSwapFlowEdgeData>
    )
  } else if (event.data.edgeData.edgeType === 'custom') {
    data = normalizeEdgeCustom(
      event.data as CreateServerEdgeData<CustomEdgeData>
    )
  } else {
    data = {
      ...pick(['srcKey', 'dstKey'], event.data),
      type: event.data.edgeData.edgeType,
    }
  }

  return {
    type: 'add_edge',
    key: event.key,
    data,
  }
}

const normalizeUpdateEdge = (
  event: ServerUpdateEdgeReceive
): ServerUpdateEdgeEvent => {
  return {
    type: 'update_edge',
    key: event.key,
    data:
      event.data.edgeData.edgeType === 'flow'
        ? {
            type: event.data.edgeData.edgeType,
            edgeData: pick(['net', 'assetId', 'color'], event.data.edgeData),
          }
        : event.data.edgeData.edgeType === 'utxo_transaction'
          ? {
              type: event.data.edgeData.edgeType,
              edgeData: pick(['color'], event.data.edgeData),
            }
          : event.data.edgeData.edgeType === 'evm_transaction'
            ? {
                type: event.data.edgeData.edgeType,
                edgeData: pick(['color'], event.data.edgeData),
              }
            : {
                type: event.data.edgeData.edgeType,
                edgeData: pick(['color'], event.data.edgeData),
              },
  }
}

const normalizeUpdateNode = (
  event: ServerUpdateNodeReceive
): ServerUpdateNodeEvent => {
  return {
    type: 'update_node',
    key: event.key,
    data:
      event.data.nodeData.nodeType === 'text'
        ? {
            type: event.data.nodeData.nodeType,
            nodeData: event.data.nodeData,
          }
        : {
            type: event.data.nodeData.nodeType,
          },
  }
}

const normalizeDeleteEntity = (
  event: ServerRemoveNodeReceive | ServerRemoveEdgeReceive
): ServerRemoveNodeEvent | ServerRemoveEdgeEvent => {
  return {
    type: event.type,
    key: event.key,
  }
}

const normalizeUpdatePositionNode = (
  event: ServerUpdatePositionNodeReceive
): ServerUpdatePositionNodeEvent => {
  return {
    type: event.type,
    key: event.key,
    data: {
      position: event.data.position,
    },
  }
}

const normalizeEvent = (event: ServerEventNodeEdgeReceive): ServerNodeEvent => {
  if (event.type === 'add_node') {
    return normalizeAddNode(event)
  }

  if (event.type === 'add_edge') {
    return normalizeAddEdge(event)
  }
  if (event.type === 'update_edge') {
    return normalizeUpdateEdge(event)
  }

  if (event.type === 'delete_node' || event.type === 'delete_edge') {
    return normalizeDeleteEntity(event)
  }

  if (event.type === 'update_position') {
    return normalizeUpdatePositionNode(event)
  }

  if (event.type === 'update_node') {
    return normalizeUpdateNode(event)
  }
}

export const normalizeEventReciveDataToRequest = (
  events: ServerEventNodeEdgeReceive[]
) => {
  return events.map(normalizeEvent)
}
