import { useTranslation } from 'react-i18next'
import { useFilterSlice } from '../TrxFilters.service'
import { FilterDropdownMenuContainerItem } from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'
import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterItem,
} from '@platform/components/Filters'
import { useSelectFilterOptions } from '@platform/components/Filters/SelectFilter/SelectFilter.constants'

const ID = 'rbf'

const FilterContainer = () => {
  const { t } = useTranslation()
  const [, { resetFilter }] = useFilterSlice(ID)
  const [filter] = useFilterSlice(`${ID}.data`)
  const [condition] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={t('platform:transaction')}
      clearFilter={resetFilter}
      conditionComponent={<FilterConditionContainer />}
    />
  )
}

const FilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice(`${ID}.condition`)
  const rbfOptions = useSelectFilterOptions()

  return (
    <SelectFilterCondition
      options={rbfOptions}
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
}

const MenuItem = (
  setFilter: (data: SelectFilterItem<string>) => void
): FilterDropdownMenuContainerItem => {
  const { t } = useTranslation()
  const label = t('platform:rbf')

  return {
    id: ID,
    icon: null,
    label,
    isDisabled: false,
    onItemClick: () => {
      setFilter({ value: ID, label })
    },
  }
}

export const RbfFilter = {
  MenuItem: MenuItem,
  Container: FilterContainer,
}
