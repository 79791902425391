import { comparators, filtersContextFactory } from '@clain/core/ui-kit'
import {
  FILTER_ASSET_KEY,
  FILTER_BALANCE_KEY,
  FILTER_CALENDAR_KEY,
  FILTER_RECEIVED_AMOUNT_KEY,
} from '@platform/components/BlockchainFilter/constants'
import {
  AssetFilteState,
  CalendarFilterState,
  ComparatorFilterState,
} from '@platform/components/BlockchainFilter/types'

export interface AddressesFiltersState {
  calendar: CalendarFilterState
  receivedAmount: ComparatorFilterState
  balance: ComparatorFilterState
  asset: AssetFilteState
}

export type AddressesFiltersStateKeys = keyof AddressesFiltersState

export const ADDRESSES_FILTERS_INIT_STATE: AddressesFiltersState = {
  [FILTER_CALENDAR_KEY.root]: {
    data: null,
    condition: comparators.is.value,
  },
  [FILTER_RECEIVED_AMOUNT_KEY.root]: {
    data: null,
    condition: comparators.isGreater.value,
  },
  [FILTER_BALANCE_KEY.root]: {
    data: null,
    condition: comparators.isGreater.value,
  },
  [FILTER_ASSET_KEY.root]: {
    data: null,
    condition: comparators.is.value,
  },
}

export const ADDRESSES_FILTERS_KEYS: Array<keyof AddressesFiltersState> = [
  FILTER_RECEIVED_AMOUNT_KEY.root,
  FILTER_BALANCE_KEY.root,
]

export const FILTER_BAR_KEYS: Array<keyof AddressesFiltersState> = [
  ...ADDRESSES_FILTERS_KEYS,
]

export const addressesFiltersContext =
  filtersContextFactory<AddressesFiltersState>(ADDRESSES_FILTERS_INIT_STATE)

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  useFilterAction,
  setInitialState,
  useFiltersOptions,
} = addressesFiltersContext
