import { action, computed, makeObservable, observable } from 'mobx'
import { dataStatsViewModel } from './DataStatsViewModel'
import { queryParamsViewModel } from './QueryParamsViewModel'
import { commonPageData } from './ApiServicesStateFacade'
import { dataTokensViewModel } from './DataTokensViewModel'
import {
  transactionsTableViewModel,
  counterpartiesTableViewModel,
  osintTableViewModel,
} from './DataEntitiesTableViewModel'
import { AbstractSectionEntitiesTableViewModel } from '../baseClasses/SectionEntitiesTableViewModel'
import { Token } from '../../ProbeSandbox/types/converted/TokenBalance'
import { TransactionsStrategy } from '../baseClasses/strategies/TransactionsStrategy'
import { CounterpartiesStrategy } from '../baseClasses/strategies/CounterpartiesStrategy'
import { OsintStrategy } from '../baseClasses/strategies/OsintStrategy'
import { EXCLUDE_FILTERS_CURRENCY } from '../constants'
import { isEVM } from '@clain/core/types'
import { dataTokenViewModel } from './DataTokenViewModel'
import { VisitedEntities } from '../baseClasses/VisitedEntities'

export class SectionEntitiesTableViewModel extends AbstractSectionEntitiesTableViewModel {
  private commonPageData = commonPageData
  private dataStatsViewModel = dataStatsViewModel
  private dataTokensViewModel = dataTokensViewModel
  private dataTokenViewModel = dataTokenViewModel

  protected queryParamsViewModel = queryParamsViewModel
  @observable public activeTab: 'transactions' | 'counterparties' | 'osint' =
    'counterparties'

  private openTransactionsTabWithFilters = (
    filters: typeof this.defaultTransactionsTableConfig.filters
  ) => {
    this.queryParamsViewModel.updateQueryParamsState((prev) => ({
      ...prev,
      active_tab: 'transactions',
    }))
    this.defaultTransactionsTableConfig.setFilters(filters)
    this.setActiveTab('transactions')
  }

  constructor() {
    super()
    makeObservable(this)
    this.transactionsTable = new TransactionsStrategy(
      this.commonPageData.state.blockchain,
      transactionsTableViewModel,
      this.queryParamsViewModel,
      this.dataTokenViewModel,
      this.dataTokensViewModel
    )
    this.counterpartiesTable = new CounterpartiesStrategy(
      counterpartiesTableViewModel,
      this.queryParamsViewModel
    )
    this.osintTable = new OsintStrategy(
      osintTableViewModel,
      this.queryParamsViewModel,
      new VisitedEntities()
    )
  }

  @action
  public setActiveTab = (tab: typeof this.activeTab) => {
    this.queryParamsViewModel.updateQueryParamsState((prev) => ({
      ...prev,
      active_tab: tab,
    }))
    this.activeTab = tab
  }

  @computed
  public get defaultTransactionsTableConfig() {
    const { blockchain, aid } = this.commonPageData.state
    return {
      coinType: blockchain,
      cumulative: this.transactionsTable.viewModel?.data?.cumulative,
      filters: this.transactionsTable.viewModel?.filters,
      initFilters: this.transactionsTable.viewModel?.initFilters,
      defaultFilters: this.transactionsTable.viewModel?.defaultFilters,
      setFilters: this.transactionsTable.handleSetFilters,
      excludeFilters: EXCLUDE_FILTERS_CURRENCY[blockchain],
      isLoading: this.transactionsTable.isLoading,
      getSearchResults: this.searchService?.getBlockchainResults,
      getSearchClusters: this.searchService?.getEntitiesResults,
      showInUSD:
        this.queryParamsViewModel.queryParamsState?.trns?.convert_to === 'usd',
      allowedRange: this.dataStatsViewModel.getPeriod(
        this.commonPageData.state.timezone
      ),
      tokensBalance: this.dataTokensViewModel.tokensBalanceData,
      tokens: this.transactionsTable.tokens,
      disabledAssetStaticSearch:
        this.transactionsTable.disabledTransactionAssetStaticSearch,
      onSearchAsset: this.transactionsTable.setTokenByAddress,
      searchAssetLoading: this.transactionsTable.tokenLoading,
      getTokens: (payload) => {
        const getTokens = this.services.getServices(
          'address',
          blockchain
        ).getTokens
        return getTokens(aid, payload)
      },
    }
  }

  @computed
  public get defaultCounterpartiesTableConfig() {
    const { blockchain } = this.commonPageData.state
    return {
      coinType: blockchain,
      counterpartyType: isEVM(blockchain) ? 'address' : 'cluster',
      cumulative: this.counterpartiesTable.viewModel?.data?.cumulative,
      categories: this.counterpartiesTable.viewModel?.data?.categories,
      filters: this.counterpartiesTable.viewModel?.filters,
      initFilters: this.counterpartiesTable.viewModel?.initFilters,
      defaultFilters: this.counterpartiesTable.viewModel?.defaultFilters,
      setFilters: this.counterpartiesTable.handleSetFilters,
      excludeFilters: EXCLUDE_FILTERS_CURRENCY[blockchain],
      isLoading: this.counterpartiesTable.isLoading,
      getEntitiesResults: this.searchService?.getEntitiesResults,
      getBlockchainResults: this.searchService?.getBlockchainResults,
      goToTransactions: this.openTransactionsTabWithFilters,
      tokens: this.dataTokensViewModel.tokensWithoutAggregated,
      showInUSD:
        this.queryParamsViewModel.queryParamsState?.counterparties
          ?.convert_to === 'usd',
      allowedRange: this.dataStatsViewModel.getPeriod(
        this.commonPageData.state.timezone
      ),
    } as const
  }

  @computed
  public get defaultOsintTableConfig() {
    const { blockchain } = this.commonPageData.state
    return {
      data: this.osintTable.data?.osints || [],
      filters: this.osintTable.filters,
      setFilters: this.osintTable.handleSetFilters,
      isLoading: this.osintTable.isLoading,
      isVisitedLink: this.osintTable.isVisitedLink,
      visitedLinkAdd: this.osintTable.visitedLinkAdd,
      currency: blockchain,
    }
  }

  public get page() {
    switch (this.activeTab) {
      case 'counterparties': {
        return this.counterpartiesTable?.filters?.page
      }
      case 'osint': {
        return this.osintTable?.filters?.page
      }
      case 'transactions':
      default: {
        return this.transactionsTable?.filters?.page
      }
    }
  }

  public get totalPages() {
    switch (this.activeTab) {
      case 'counterparties': {
        return this.counterpartiesTable?.data?.totalPages
      }
      case 'osint': {
        return this.osintTable?.data?.totalPages
      }
      case 'transactions':
      default: {
        return this.transactionsTable?.data?.totalPages
      }
    }
  }

  public setPage = (page: number) => {
    switch (this.activeTab) {
      case 'counterparties': {
        this.counterpartiesTable.updateFilters({ page })
        break
      }
      case 'osint': {
        this.osintTable.updateFilters({ page })
        break
      }
      case 'transactions':
      default: {
        this.transactionsTable.updateFilters({ page })
        break
      }
    }
  }

  public get isSwitchButtonDisabled() {
    switch (this.activeTab) {
      case 'counterparties': {
        return this.counterpartiesTable?.isUsdSwitchDisabled
      }
      case 'transactions':
      default: {
        return this.transactionsTable?.isUsdSwitchDisabled
      }
    }
  }

  public get isSwitchButtonShowUSD() {
    switch (this.activeTab) {
      case 'counterparties': {
        return this.defaultCounterpartiesTableConfig?.showInUSD
      }
      case 'transactions':
      default: {
        return this.defaultTransactionsTableConfig?.showInUSD
      }
    }
  }

  @action
  public onSwitchUSDButton = (isShowUSD: boolean) => {
    switch (this.activeTab) {
      case 'counterparties': {
        return this.counterpartiesTable?.switchUSD(isShowUSD)
      }
      case 'transactions':
      default: {
        return this.transactionsTable?.switchUSD(isShowUSD)
      }
    }
  }

  public get isAllTablesLoading() {
    return (
      this.counterpartiesTable.isLoading ||
      this.transactionsTable.isLoading ||
      this.osintTable.isLoading
    )
  }

  @action
  public setAssetByPortfolioSection = (token: Token) => {
    this.transactionsTable.handleSetFilters({ includeTokens: [token] })
    this.counterpartiesTable.handleSetFilters({ includeTokens: [token] })
    this.setActiveTab('transactions')
  }

  @action
  public onCascadeFilterApplied = (filters: {
    scoreFrom: number
    scoreTo: number
    from: number
    to: number
    includeTokens: Token[]
  }) => {
    this.transactionsTable.handleSetFilters(filters, 0)
    this.counterpartiesTable.handleSetFilters(filters, 0)
  }

  @action
  public clear = () => {
    this.transactionsTable?.clear()
    this.counterpartiesTable?.clear()
    this.osintTable?.clear()
  }
}
