import { useFilterSlice } from '../CounterpartyContainerFilters.service'
import { useGetFilterLabels } from '@clain/core/ui-kit'
import { useCounterpartyStaticStore } from '../../context'
import { TextFilterMenuListItem } from '../../../../../Filters'
import { useFetchAddresses } from '../../hooks/useFetchAddresses'
import { useTranslation } from '@clain/core/utils/i18next'

export const useAddressFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('cluster.data')
  const [, { resetFilter }] = useFilterSlice('cluster')
  const { t } = useTranslation()

  const [condition] = useFilterSlice('cluster.condition')
  const { isMultiple, multipleLabel } = useGetFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: t('platform:addresses'),
  })

  return {
    isMultiple,
    multipleLabel,
    selectedItems: data,
    setFilter,
    resetFilter,
  }
}

export const useClusterSearchData = () => {
  const addressStaticStore = useCounterpartyStaticStore()
  const { getAddresses, addresses } = useFetchAddresses<TextFilterMenuListItem>(
    {
      getSearchResults: addressStaticStore.current.getSearchResults,
      currency: addressStaticStore.current.coinType,
      normalize: ({ aid, address }) => ({
        id: aid,
        label: address,
      }),
    }
  )

  return {
    menuListData: addresses,
    onSearch: getAddresses,
  }
}

export const useSelectedClusterFilterData = () => {
  const [selectedItems] = useFilterSlice('cluster.data')

  return selectedItems?.length ? selectedItems[0] : null
}
