import { osintTableViewModel as clusterDataOsint } from '../../ClusterViewModels'
import { osintTableViewModel as addressDataOsint } from '../../AddressViewModels'
import { QueryParamsViewModel } from '../QueryParamsViewModel'
import { action, makeObservable } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'
import { setDebouncedTableFilters } from '../../utils'
import { IVisitedEntities } from '../VisitedEntities'

export class OsintStrategy {
  public viewModel: typeof clusterDataOsint | typeof addressDataOsint
  private queryParamsViewModel: QueryParamsViewModel<any>
  private visitedEntities: IVisitedEntities

  constructor(
    viewModel: typeof clusterDataOsint | typeof addressDataOsint,
    queryParamsViewModel: QueryParamsViewModel<any>,
    visitedEntities: IVisitedEntities
  ) {
    makeObservable(this)
    this.viewModel = viewModel
    this.queryParamsViewModel = queryParamsViewModel
    this.visitedEntities = visitedEntities
  }

  @action
  handleSetFilters = (
    filters: typeof this.viewModel.filters,
    timeout = 150
  ) => {
    const notObservableFilters = cloneDeep(filters)

    setDebouncedTableFilters(timeout, 'osint')(
      notObservableFilters,
      this.queryParamsViewModel.updateQueryParamsState,
      this.viewModel?.updateFilters
    )
  }

  get data() {
    return this.viewModel?.data?.data
  }

  get isLoading(): boolean {
    return this.viewModel?.isLoading
  }

  get filters() {
    return this.viewModel?.filters
  }

  get updateFilters() {
    return this.viewModel?.updateFilters
  }

  public isVisitedLink = (
    ...args: Parameters<typeof this.visitedEntities.has>
  ) => {
    return this.visitedEntities.has(...args)
  }

  public visitedLinkAdd = (
    ...args: Parameters<typeof this.visitedEntities.add>
  ) => {
    return this.visitedEntities.add(...args)
  }

  @action
  clear(): void {
    this.viewModel?.clear()
    this.visitedEntities.clear()
  }
}
