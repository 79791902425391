import 'reflect-metadata'
import { GRAPH_ENTITIES_TYPES } from '../constants/injectTypes'
import { IEntitiesMainState } from '../types'
import { rootContainer } from './rootContainer'
import { sharedContainer } from './sharedContainer'
import { PositioningController as PositioningControllerModule } from '../modules/PositioningController'

class PositioningControllerRoot {
  constructor({ entitiesState }: { entitiesState: IEntitiesMainState }) {
    if (!rootContainer.isBound(GRAPH_ENTITIES_TYPES.EntitiesState)) {
      rootContainer
        .bind<IEntitiesMainState>(GRAPH_ENTITIES_TYPES.EntitiesState)
        .toConstantValue(entitiesState)
    } else {
      rootContainer
        .rebind<IEntitiesMainState>(GRAPH_ENTITIES_TYPES.EntitiesState)
        .toConstantValue(entitiesState)
    }
  }

  getInstance() {
    return sharedContainer.get<PositioningControllerModule>(
      GRAPH_ENTITIES_TYPES.PositioningController
    )
  }
}

const PositioningController = PositioningControllerRoot

type IPositioningController = PositioningControllerRoot

export type { IPositioningController }

export { PositioningController }
