import { ColDeprecated, Typography } from '@clainio/web-platform'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const CascadeFilterTooltipContent = () => {
  const { t } = useTranslation()

  return (
    <ColDeprecated style={{ maxWidth: 245 }}>
      <Typography variant={'body1-sm'} color={'white'}>
        {t('platform:cascadeFiltersDescription')}
      </Typography>
      <Typography variant={'body1-sm'} color={'white'}>
        {t('platform:individualAdjustmentsPossible')}
      </Typography>
    </ColDeprecated>
  )
}
