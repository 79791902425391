import { injectable, inject } from 'inversify'
import { isEVM, isUTXO } from '@clain/core/types/coin'
import { IFactoryNode, NodeEntity } from '../../models'
import { _NodeData, NodeData } from '../../types'
import { Factory } from '../Factory'
import { DI_TYPES } from '../../di/DITypes'

@injectable()
export class FactoryNode<
  TNodeData extends NodeData,
  _TNodeData extends _NodeData
> implements IFactoryNode<TNodeData, _TNodeData>
{
  constructor(
    @inject(DI_TYPES.Factory)
    private factory: Factory
  ) {}

  public produce: IFactoryNode<TNodeData, _TNodeData>['produce'] = ({
    key,
    data: { position, nodeData },
    settings,
  }) => {
    const type = nodeData?.nodeType
    const { x, y } = position

    if (type === 'cluster') {
      return this.factory.produceClusterProbeNode(
        nodeData,
        x,
        y,
        key,
        settings
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'address') {
      if (isUTXO(nodeData)) {
        return this.factory.produceAddressProbeNodeUtxo(
          nodeData,
          x,
          y,
          key,
          settings
        ) as unknown as NodeEntity<_TNodeData>
      }
      if (isEVM(nodeData)) {
        return this.factory.produceAddressProbeNodeEvm(
          nodeData,
          x,
          y,
          key,
          settings
        ) as unknown as NodeEntity<_TNodeData>
      }
    }
    if (type === 'utxo_transaction' || type === 'evm_transaction') {
      if (isUTXO(nodeData)) {
        return this.factory.produceTransactionProbeNodeUtxo(
          nodeData,
          x,
          y,
          key,
          settings
        ) as unknown as NodeEntity<_TNodeData>
      }
      if (isEVM(nodeData)) {
        return this.factory.produceTransactionProbeNodeEvm(
          nodeData,
          x,
          y,
          key,
          settings
        ) as unknown as NodeEntity<_TNodeData>
      }
    }
    if (type === 'utxo_transaction_address') {
      if (
        nodeData.addressType === 'token_input' ||
        nodeData.addressType === 'token_output'
      ) {
        return this.factory.produceTransactionAddressTokenNode(
          nodeData,
          { hash: nodeData.transactionHash },
          x,
          y,
          nodeData?.token,
          nodeData.addressType,
          key,
          settings
        ) as unknown as NodeEntity<_TNodeData>
      }

      if (['btc', 'doge', 'ltc'].includes(nodeData?.currency)) {
        return this.factory.produceTransactionAddressNode(
          nodeData,
          { hash: nodeData.transactionHash },
          x,
          y,
          nodeData?.currency,
          key,
          settings
        ) as unknown as NodeEntity<_TNodeData>
      }
    }
    if (type === 'demix') {
      return this.factory.produceDemixNode(
        nodeData,
        x,
        y,
        settings
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'osint') {
      return this.factory.produceOsintNode(
        nodeData,
        x,
        y,
        settings
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'text') {
      return this.factory.produceTextNode(
        nodeData,
        x,
        y,
        key,
        true
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'comment_pin') {
      return this.factory.produceCommentPinNode(
        nodeData,
        x,
        y,
        key
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'custom') {
      return this.factory.produceCustomNode(
        nodeData,
        x,
        y,
        settings,
        key
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'unsupported_address') {
      return this.factory.produceUnsupportedAddressNode(
        nodeData,
        x,
        y,
        settings,
        key
      ) as unknown as NodeEntity<_TNodeData>
    }
    if (type === 'comment_plug') {
      const result = this.factory.produceCommentPlugNode(x, y, key)
      result.node.setDisabled(true)

      return result as unknown as NodeEntity<_TNodeData>
    }
  }
}
