import { Container } from 'inversify'
import { DataTransactionsSearchViewModel } from '@platform/components/BlockchainFilter/model/transactions/DataTransactionsSearchViewModel'
import { DATA_TRANSACTIONS_SEARCH_VIEW_MODEL } from '@platform/components/BlockchainFilter/di/transactions/constants'
import { transactionFilterPresetsViewModel } from '@platform/components/BlockchainFilter/di/transactions/modules'
import { rootContainer } from '@platform/di/rootContainer'

const container = new Container()

container.parent = rootContainer

container
  .bind<DataTransactionsSearchViewModel>(
    DATA_TRANSACTIONS_SEARCH_VIEW_MODEL.DataTransactionsSearchViewModel
  )
  .to(DataTransactionsSearchViewModel)

container.load(transactionFilterPresetsViewModel)

export const dataTransactionsSearchViewModel =
  container.get<DataTransactionsSearchViewModel>(
    DATA_TRANSACTIONS_SEARCH_VIEW_MODEL.DataTransactionsSearchViewModel
  )
