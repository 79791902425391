import React from 'react'
import { ColDeprecated, StatusBlock } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { EntitiesNotFoundProps } from './EntitiesNotFound.types'

export const EntitiesNotFound: React.FC<EntitiesNotFoundProps> = ({
  title,
  subtitle,
  subtitleSecondary,
}) => (
  <Container gap={[2, 0]}>
    <StatusBlock>
      <StatusBlock.Image type={'search'} />
      <StatusBlock.Title>{title}</StatusBlock.Title>
      <ColDeprecated align={'center'} gap={0}>
        <StatusBlock.Subtitle>{subtitle}</StatusBlock.Subtitle>
        <StatusBlock.Subtitle>{subtitleSecondary}</StatusBlock.Subtitle>
      </ColDeprecated>
    </StatusBlock>
  </Container>
)
