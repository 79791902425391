import { useTranslation } from 'react-i18next'
import { useFilterSlice } from '../TrxFilters.service'

import {
  FilterDropdownMenuComponentProps,
  FilterDropdownMenuContainerItem,
} from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'

import { useTypeFilter } from './TypeFilter.const'
import { TrxTypeFilterState } from './TypeFilter.type'
import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '@platform/components/Filters'
import { FilterStatesByFilter } from '@platform/components/BlockchainFilter/types'

type FilterProps = Partial<FilterDropdownMenuComponentProps> & {
  id: keyof FilterStatesByFilter<TrxTypeFilterState>
  title?: string
}

const MenuContainer = ({ id, title }: FilterProps) => {
  const [filter, { setFilter }] = useFilterSlice(`${id}.data`)
  const { TRX_TYPE_MENU_OPTIONS } = useTypeFilter()
  return (
    <SelectFilterMenu
      selected={filter ? filter.value : null}
      title={title}
      onChange={setFilter}
      size={'md'}
      options={TRX_TYPE_MENU_OPTIONS}
    />
  )
}

const FilterContainer = ({ id, title }: FilterProps) => {
  const [, { resetFilter }] = useFilterSlice(id)
  const [filter] = useFilterSlice(`${id}.data`)
  const [condition] = useFilterSlice(`${id}.condition`)
  const { t } = useTranslation()
  const { TRX_INPUT_TYPE_LABELS, TRX_OUTPUT_TYPE_LABELS } = useTypeFilter()

  return (
    <SelectFilter
      maxHeight={300}
      selectedCondition={condition}
      options={
        id === 'input_type' ? TRX_INPUT_TYPE_LABELS : TRX_OUTPUT_TYPE_LABELS
      }
      value={filter ? filter.label : ''}
      size={'md'}
      label={t('platform:type')}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer id={id} title={title} />}
      conditionComponent={<FilterConditionContainer id={id} />}
    />
  )
}

const FilterConditionContainer = ({ id }: FilterProps) => {
  const { TRX_INPUT_TYPE_FILTER_OPTIONS, TRX_OUTPUT_TYPE_FILTER_OPTIONS } =
    useTypeFilter()
  const [condition, { setFilter }] = useFilterSlice(`${id}.condition`)

  return (
    <SelectFilterCondition
      options={
        id === 'input_type'
          ? TRX_INPUT_TYPE_FILTER_OPTIONS
          : TRX_OUTPUT_TYPE_FILTER_OPTIONS
      }
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
}

const MenuItem = (): FilterDropdownMenuContainerItem => {
  const { t } = useTranslation()

  return {
    id: '',
    label: t('platform:type'),
    icon: null,
    isDisabled: false,
  }
}

export const TypeFilter = {
  MenuItem,
  Container: FilterContainer,
  Menu: MenuContainer,
}
