import { useTranslation } from 'react-i18next'
import { useState, useRef, useEffect } from 'react'
import { IconVariant } from '@clainio/web-platform'

const useInitialButtonState = () => {
  const { t } = useTranslation()
  return {
    variant: 'Cascade' as const,
    label: t('platform:cascade'),
  } as {
    variant: Extract<IconVariant, 'Cascade' | 'Check'>
    label: string
  }
}

const useCascadeButton = (isCascadeLoading: boolean) => {
  const { t } = useTranslation()
  const initialState = useInitialButtonState()

  const [buttonState, setButtonState] = useState(initialState)
  const cascadeButtonTimer = useRef(null)
  const isCascadeActive = useRef(false)

  useEffect(() => {
    if (isCascadeLoading) {
      isCascadeActive.current = true
    }
    if (!isCascadeLoading && isCascadeActive.current) {
      setButtonState({ variant: 'Check', label: t('platform:applied') })
      isCascadeActive.current = false
      cascadeButtonTimer.current = setTimeout(() => {
        setButtonState(initialState)
      }, 1500)
    }
    return () => {
      clearTimeout(cascadeButtonTimer.current)
    }
  }, [isCascadeLoading, t])

  return buttonState
}

export { useCascadeButton }
