import i18n, { TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import * as Sentry from '@sentry/react'

interface I18nManagerOptions<LanguageTypes extends string> {
  fallbackLng?: LanguageTypes
  namespaces: string[]
  path?: string
  supportedLanguages?: LanguageTypes[]
}

interface I18nManagerInterface<LanguageTypes extends string> {
  initialize(language?: LanguageTypes): void
  t: TFunction<['platform', ...never[]], undefined>
  language: LanguageTypes
  changeLanguage: (lang: LanguageTypes) => void
}

export class I18nManager<LanguageTypes extends string>
  implements I18nManagerInterface<LanguageTypes>
{
  private fallbackLng: LanguageTypes
  private defaultLanguage: LanguageTypes
  private namespaces: string[]
  private path: string
  private localesManifest: Record<string, string> | null = null
  private supportedLanguages: LanguageTypes[] = []

  constructor(options: I18nManagerOptions<LanguageTypes>) {
    this.fallbackLng = options?.fallbackLng || ('en' as LanguageTypes)
    this.path = options?.path || 'static/locales'
    const localLanguage = this.getBrowserLanguage() as LanguageTypes
    this.supportedLanguages = options.supportedLanguages

    this.defaultLanguage = this.proxyLanguage(localLanguage, this.fallbackLng)
    this.namespaces = options.namespaces
  }

  private getBrowserLanguage(): string {
    const lang = navigator.language
    return lang.split('-')[0]
  }

  private proxyLanguage(
    language: LanguageTypes,
    defaultLanguage: LanguageTypes
  ) {
    return this.supportedLanguages.includes(language)
      ? language
      : defaultLanguage
  }

  private async getLocalePath(lng: string, ns: string) {
    const originalPath = `${this.path}/${lng}/${ns}.json`

    if (!this.localesManifest) {
      try {
        const response = await fetch(
          `/assets-manifest.json?timestamp=${new Date().getTime()}`,
          {
            cache: 'no-cache',
          }
        )
        this.localesManifest = await response.json()
      } catch (error) {
        Sentry.captureException(error)
        console.error('Failed to load locales manifest:', error)
        return originalPath
      }
    }

    return this.localesManifest.static[originalPath] || originalPath
  }

  public initialize: I18nManagerInterface<LanguageTypes>['initialize'] = (
    language
  ) => {
    const lng = this.proxyLanguage(language, this.defaultLanguage)

    i18n
      .use(HttpBackend)
      .use(initReactI18next)
      .init({
        lng,
        ns: this.namespaces,
        fallbackLng: this.fallbackLng,
        supportedLngs: this.supportedLanguages,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          request: async (options, url, _, callback) => {
            try {
              const matches = url.match(/\/([^/]+)\/([^/]+)\.json$/)
              if (!matches) {
                throw new Error('Invalid locale URL pattern')
              }
              const [, lng, ns] = matches
              const localePath = await this.getLocalePath(lng, ns)
              const finalUrl = localePath

              const response = await fetch(finalUrl, options)
              const data = await response.json()
              callback(null, { status: 200, data })
            } catch (error) {
              Sentry.captureException(error)
              console.error('Failed to load translation:', error)
              callback(error, null)
            }
          },
        },
        preload: [lng, 'en'],
      })
  }

  get t() {
    return i18n.t
  }

  get language() {
    return i18n.language as LanguageTypes
  }

  public changeLanguage: I18nManagerInterface<LanguageTypes>['changeLanguage'] =
    (lang) => {
      if (!this.supportedLanguages.includes(lang)) return

      return i18n.changeLanguage(lang)
    }
}
