import React from 'react'
import classNames from 'classnames/bind'

import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as MoreIcon } from '@clain/core/assets/more_vertical.svg'
import styles from './AlertInfobarOptions.scss'
import { AlertInfobarOptionsProps } from './AlertInfobarOptions.types'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classNames.bind(styles)

export const AlertInfobarOptions: React.FC<AlertInfobarOptionsProps> = ({
  topContent,
  options,
  onChange,
}) => {
  return (
    <PopoverBaseContainer
      placement="bottom-start"
      className={cx('Popup')}
      zIndex={1}
      content={
        <RowDeprecated direction="column" fullWidth gap={0.5}>
          {topContent}
          <RowDeprecated direction="column" fullWidth gap={0}>
            {options.map((item, index) =>
              item.type === 'content' ? (
                <RowDeprecated fullWidth gap={0} key={index}>
                  {item.content}
                </RowDeprecated>
              ) : (
                <div className={cx('OptionSection')} key={index}>
                  <div className={cx('Delimiter')} />
                  <RowDeprecated
                    className={cx('Option')}
                    onClick={() => onChange(item.value)}
                  >
                    <ColDeprecated>{item.icon}</ColDeprecated>
                    <ColDeprecated>
                      <Typography variant="body1-sm" color="black">
                        {item.name}
                      </Typography>
                    </ColDeprecated>
                  </RowDeprecated>
                </div>
              )
            )}
          </RowDeprecated>
        </RowDeprecated>
      }
      anchor={<MoreIcon className={cx('MoreIcon')} />}
    />
  )
}
