import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import {
  AssetIcon,
  EllipsisTypography,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'
import { useCrossChainSwapInfoBarViewModel } from '../context/CrossChainSwap.context'
import * as S from '../CrossChainSwapInfobar.styles'

export const CrossChainSwapHeader = observer(() => {
  const { t } = useTranslation()
  const { bridge } = useCrossChainSwapInfoBarViewModel()

  return (
    <Stack gap="md" padding={['none', 'md']} direction="row">
      <S.Col $fullWith={false}>
        <TypographyNew variant="heading300Accent" color="onBackgroundBase">
          {t('platform:crossChainSwap')}
        </TypographyNew>
      </S.Col>
      <S.Col $width={19}>
        <TypographyNew variant="heading300Normal" color="onBackgroundVariant1">
          {t('platform:on')}
        </TypographyNew>
      </S.Col>
      <S.Col $fullWith={false}>
        <Stack gap="xs" align="center">
          <S.Col $width={16}>
            <AssetIcon id={1} size="xs" icon={bridge?.icon} />
          </S.Col>
          <S.Col>
            <EllipsisTypography
              variant="heading300Accent"
              color="onBackgroundBase"
              type="end"
            >
              {bridge?.name}
            </EllipsisTypography>
          </S.Col>
        </Stack>
      </S.Col>
    </Stack>
  )
})

CrossChainSwapHeader.displayName = 'CrossChainSwapHeader'
