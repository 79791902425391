import { NodeData } from '../../../types/nodeEntitiesData/NodeData'
import ActiveEntityViewModel from '../ActiveEntityViewModel'

export const getAddressId = (
  entity: ActiveEntityViewModel
): {
  id?: number
  type?: 'cluster' | 'address'
} => {
  if (entity.type === 'cluster') {
    return { id: entity.cluster?.data?.clusterId, type: 'cluster' }
  }

  if (entity.type === 'flow') {
    return {
      id: entity.flow?.data?.sourceData?.clusterId,
      type: 'cluster',
    }
  }

  if (entity.type === 'address') {
    return { id: entity.address?.data.addressId, type: 'address' }
  }
}

export const getActiveEntityInfo = (entity: ActiveEntityViewModel) => {
  if (entity.type === 'cluster') {
    return {
      tokensBalance: entity.cluster.tokensBalance,
      tokensTotalCount: entity.cluster.tokensTotalCount,
    }
  }

  if (entity.type === 'address') {
    return {
      tokensBalance: entity.address.tokensBalance,
      tokensTotalCount: entity.address.tokensTotalCount,
    }
  }
}

export const getAddressIdProbe = (entity: NodeData): number => {
  if (entity.nodeType === 'address') {
    return entity.addressId
  }

  if (entity.nodeType === 'cluster') {
    return entity.clusterId
  }
}
