import {
  FiltersButtons,
  RowDeprecated,
  ColDeprecated,
  TypographyNew,
} from '@clain/core/ui-kit'
import React from 'react'
import { injectFiltersModel } from '../context'
import { TABLE_FILTER_MENU_MAX_HEIGHT } from '../constants'

type FilterProps = {
  keys: string[]
  menu: React.ReactNode
  title?: string
  createFilter: (filter: string) => JSX.Element
}

export const FiltersBar = injectFiltersModel<FilterProps, Record<string, any>>(
  ({ keys, menu, title, useFilterNameList, createFilter }) => {
    const filterList = useFilterNameList().filter((f) => keys.includes(f))
    const filterComponents = filterList.map(createFilter)

    const sliders = filterComponents?.length
      ? [
          ...filterComponents,
          ...(filterList.length !== keys.length
            ? [
                <FiltersButtons.IconAdd
                  key="filter-icon-add"
                  maxHeightPopup={TABLE_FILTER_MENU_MAX_HEIGHT}
                  size={'md'}
                  popupContent={menu}
                />,
              ]
            : []),
        ]
      : []

    if (filterList.length === 0) return null

    return (
      <ColDeprecated gap={0.5}>
        {title && (
          <TypographyNew variant={'body100Accent'} color={'onBackgroundBase'}>
            {title}
          </TypographyNew>
        )}
        <RowDeprecated wrap={true}>
          {sliders.map((s, i) => (
            <div key={i}>{s}</div>
          ))}
        </RowDeprecated>
      </ColDeprecated>
    )
  }
)
