import { comparators, filtersContextFactory } from '@clain/core/ui-kit'

import { TrxTypeFilterState } from './TypeFilter/TypeFilter.type'
import {
  CalendarFilterState,
  ScoreFilterState,
  ComparatorFilterState,
  VersionFilterState,
  FeatureFilterState,
  MultisigFilterState,
  CompressionFilterState,
} from '../../../types'

export interface TrxFiltersState {
  calendar: CalendarFilterState
  input_score: ScoreFilterState
  output_score: ScoreFilterState
  amount: ComparatorFilterState
  input_amount: ComparatorFilterState
  output_amount: ComparatorFilterState
  numberInputs: ComparatorFilterState
  numberOutputs: ComparatorFilterState
  fee: ComparatorFilterState
  feeByte: ComparatorFilterState
  size: ComparatorFilterState
  vSize: ComparatorFilterState
  version: VersionFilterState
  rbf: FeatureFilterState
  segwit: FeatureFilterState
  locktime: FeatureFilterState
  output_change: FeatureFilterState
  input_multisig: MultisigFilterState
  output_multisig: MultisigFilterState
  input_compression: CompressionFilterState
  output_compression: CompressionFilterState
  input_type: TrxTypeFilterState
  output_type: TrxTypeFilterState
}

export const TRX_FILTERS_INIT_STATE: TrxFiltersState = {
  calendar: {
    data: null,
    condition: comparators.is.value,
  },
  input_score: { data: null, condition: comparators.is.value },
  output_score: { data: null, condition: comparators.is.value },
  amount: {
    data: null,
    condition: comparators.isGreater.value,
  },
  input_amount: {
    data: null,
    condition: comparators.isGreater.value,
  },
  output_amount: {
    data: null,
    condition: comparators.isGreater.value,
  },
  numberInputs: {
    data: null,
    condition: comparators.isGreater.value,
  },
  numberOutputs: {
    data: null,
    condition: comparators.isGreater.value,
  },
  fee: {
    data: null,
    condition: comparators.isGreater.value,
  },
  feeByte: {
    data: null,
    condition: comparators.isGreater.value,
  },
  vSize: {
    data: null,
    condition: comparators.isGreater.value,
  },
  size: {
    data: null,
    condition: comparators.isGreater.value,
  },
  version: {
    data: null,
    condition: comparators.is.value,
  },
  rbf: {
    data: null,
    condition: comparators.is.value,
  },
  segwit: {
    data: null,
    condition: comparators.is.value,
  },
  locktime: {
    data: null,
    condition: comparators.is.value,
  },
  output_change: {
    data: null,
    condition: comparators.isNot.value,
  },
  input_compression: {
    data: null,
    condition: comparators.is.value,
  },
  output_compression: {
    data: null,
    condition: comparators.is.value,
  },
  input_type: {
    data: null,
    condition: comparators.isAny.value,
  },
  output_type: {
    data: null,
    condition: comparators.isAny.value,
  },
  input_multisig: {
    data: null,
    condition: null,
  },
  output_multisig: {
    data: null,
    condition: null,
  },
}

export const INPUT_FILTERS_KEYS: Array<keyof TrxFiltersState> = [
  'input_score',
  'input_multisig',
  'input_compression',
  'input_type',
  'input_amount',
]
export const OUTPUT_FILTERS_KEYS: Array<keyof TrxFiltersState> = [
  'output_multisig',
  'output_score',
  'output_compression',
  'output_type',
  'output_amount',
  'output_change',
]
export const TRANSACTION_FILTERS_KEYS: Array<keyof TrxFiltersState> = [
  'fee',
  'feeByte',
  'vSize',
  'size',
  'amount',
  'numberInputs',
  'numberOutputs',
  'rbf',
  'segwit',
  'locktime',
  'version',
]

export const FILTER_BAR_KEYS: Array<keyof TrxFiltersState> = [
  ...TRANSACTION_FILTERS_KEYS,
  ...INPUT_FILTERS_KEYS,
  ...OUTPUT_FILTERS_KEYS,
]

export const filtersContext = filtersContextFactory<TrxFiltersState>(
  TRX_FILTERS_INIT_STATE
)

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
  useFilterAction,
  setInitialState,
  useFiltersOptions,
} = filtersContext
