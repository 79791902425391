import React from 'react'
import {
  List,
  ListItem,
  ListItemContent,
  usePopoverState,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import { useGroupByFilter } from './useGroupByFilter'
import { GroupByMenuProps } from './GroupByFilter.types'
import { listItemSize } from './GroupByFilter.constants'

export const GroupByFilterMenu = ({
  selectedGroupBy,
  onSelect,
}: GroupByMenuProps) => {
  const { groupByOptions } = useGroupByFilter()
  const { setOpen } = usePopoverState()

  const handleSelect = (groupBy: typeof selectedGroupBy) => {
    setOpen(false)
    onSelect(groupBy)
  }

  return (
    <List>
      {groupByOptions?.map(({ value, label }) => (
        <ListItem
          key={value}
          onClick={() => handleSelect(value)}
          size={listItemSize}
          isSelected={selectedGroupBy === value}
          content={({ ...params }) => (
            <ListItemContent
              {...params}
              slots={[
                {
                  type: 'text',
                  component: ({ variant: [body] }) => (
                    <EllipsisTypography
                      variant={body}
                      color={'onBackgroundBase'}
                    >
                      {label}
                    </EllipsisTypography>
                  ),
                },
              ]}
            />
          )}
        />
      ))}
    </List>
  )
}
