import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { CopyIcon } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../../hooks'

import { TransactionUtxo } from '../../../types/converted/TransactionUtxo'
import { TransactionFeatureTags } from '../../FeautureTags'

import InfobarTable from '../InfobarTable'

import styles from './index.scss'
import { CoinTypeUTXO } from '../../../../../types/coin'
import {
  createFormatMoney,
  GENERAL_NUMBER_NOTATION,
} from '@clain/core/utils/format'
import { useFormatMoneySettings } from '../../../../../hooks/useFormatMoneySettings'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

interface TransactionBtcInfobarProps {
  transaction: TransactionUtxo
  isUsd: boolean
  currency: CoinTypeUTXO
}

const formatMoney = createFormatMoney({ formats: GENERAL_NUMBER_NOTATION })

const TransactionUtxoInfobar: React.FC<TransactionBtcInfobarProps> = ({
  transaction,
  isUsd,
  currency,
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const formatMoneySettings = useFormatMoneySettings({ type: 'probe-tabels' })

  if (!transaction) return null

  return (
    <Container className={cx('TransactionBtcInfobar')} gap={[1.5, 1]}>
      <ColDeprecated gap={2}>
        <ColDeprecated>
          <RowDeprecated gap={0.5}>
            <Typography variant="subheading2">
              {t('platform:transaction')}
            </Typography>
            <Typography className={cx('Transaction')} variant="code1">
              <Link
                className={cx('TransactionLink')}
                to="/:coin/explorer/transaction/:hash"
                params={{ hash: transaction.hash, coin: currency }}
                target="_blank"
              >
                {transaction.hash}
              </Link>
            </Typography>
            <CopyIcon value={transaction.hash} />
          </RowDeprecated>
        </ColDeprecated>
        <InfobarTable
          data={[
            [
              {
                name: t('platform:features') + ':',
                value: <TransactionFeatureTags transaction={transaction} />,
              },
              {
                name: t('platform:inputs') + ':',
                value: transaction.inputs?.length ?? 0,
              },
              {
                name: t('platform:outputs') + ':',
                value: transaction.outputs?.length ?? 0,
              },
            ],
            [
              {
                name: t('platform:in') + ':',
                value: isUsd
                  ? formatMoneySettings({
                      value: transaction.totalInUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.totalIn,
                      currency: currency,
                    }),
              },
              {
                name: t('platform:out') + ':',
                value: isUsd
                  ? formatMoneySettings({
                      value: transaction.totalOutUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.totalOut,
                      currency: currency,
                    }),
              },
              {
                name: t('platform:fee') + ':',
                value: isUsd
                  ? formatMoneySettings({
                      value: transaction.feeUsd,
                      currency: 'usd',
                    })
                  : formatMoney({
                      value: transaction.fee,
                      currency: currency,
                    }),
              },
              {
                name: t('platform:feeVByte') + ':',
                value: formatMoney({
                  value: transaction.feeByte,
                  currency: 'sat',
                }),
              },
            ],
            [
              {
                name: t('platform:time') + ':',
                value: transaction.memPool ? (
                  <Typography
                    variant="tag2"
                    color="grey2"
                    transform="uppercase"
                    className={cx('Mempool')}
                  >
                    {t('platform:mempool')}
                  </Typography>
                ) : (
                  transaction.time &&
                  formatDate(fromUnixTime(transaction.time), 'date-time')
                ),
              },
              {
                name: t('platform:size') + ':',
                value: transaction.size,
              },
              {
                name: t('platform:block') + ':',
                value: transaction.memPool ? (
                  <Typography
                    variant="tag2"
                    color="grey2"
                    transform="uppercase"
                    className={cx('Mempool')}
                  >
                    {t('platform:mempool')}
                  </Typography>
                ) : (
                  <Link
                    className={cx('BlockLink')}
                    to="/:coin/explorer/block/:block"
                    params={{ block: transaction.block, coin: currency }}
                    target="_blank"
                  >
                    {transaction.block}
                  </Link>
                ),
              },
              {
                name: t('platform:confirmations') + ':',
                value: transaction.confirmations,
              },
            ],
          ]}
        />
      </ColDeprecated>
    </Container>
  )
}

export default observer(TransactionUtxoInfobar)
