import React, { PropsWithChildren } from 'react'
import { motion } from 'framer-motion'

export const ExpandableAnimatedDiv: React.FC<
  PropsWithChildren<{ duration?: number }>
> = ({ children, duration = 0.25 }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration }}
    >
      {children}
    </motion.div>
  )
}
