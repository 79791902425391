import {
  filtersContextFactory,
  comparators,
  ComparatorsValue,
} from '@clain/core/ui-kit'
import { useEffect } from 'react'
import { useGroupByFilterDispatchers } from './GroupByFilter/useGroupByFilterData'
import { useCalendarFilterDispatchers } from './CalendarFilter/useCalendarFilterData'
import { useTransactionsFlagsViewModel } from '../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'
import { useGroupByFilter } from '../Filters/GroupByFilter/useGroupByFilter'

interface ClusterFingerprintFiltersState {
  groupBy: {
    data: ReturnType<typeof useGroupByFilter>['groupByOptions'][number]['value']
  }
  calendar: { data: [Date, Date]; condition: Extract<ComparatorsValue, 'is'> }
}

export const {
  FiltersProvider,
  useFilterSlice,
  useFilters,
  useFilterNameList,
  useFiltersActions,
} = filtersContextFactory<ClusterFingerprintFiltersState>({
  groupBy: { data: null },
  calendar: { data: null, condition: comparators.is.value },
})

export const useInitFilters = () => {
  const { groupByFilter, calendarFilter } = useTransactionsFlagsViewModel()
  const { setFilters } = useFiltersActions()
  useGroupByFilterDispatchers()
  useCalendarFilterDispatchers()
  useEffect(() => {
    if (groupByFilter && calendarFilter) {
      setFilters({
        groupBy: {
          data: groupByFilter,
        },
        calendar: {
          data: [...calendarFilter],
          condition: comparators.is.value,
        },
      })
    }
  }, [calendarFilter, groupByFilter])
}
