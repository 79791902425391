import { CoinType } from '../types/coin'

export const probePath = 'probes/'

export const probePathSchema = `${probePath}:probeId`

export const createProbePath = (probeId: string | number) =>
  `/${probePath}${probeId}`

export const blockchainFilterTxnPath = 'txn'
export const blockchainFilterAddressPath = 'address'

export const blockchainFilterPath = 'blockchainFilter'

export const blockchainFilterPathSchema = `:coin/${blockchainFilterPath}/:entity`

export const createBlockchainFilterTnxPath = (coin: CoinType) =>
  `/${coin}/${blockchainFilterPath}/${blockchainFilterTxnPath}`

export type BlockchainFilterPathParams = {
  coin: CoinType
  entity: 'txn' | 'address'
}

export const createBlockchainFilterAddressPath = (coin: CoinType) =>
  `/${coin}/${blockchainFilterPath}/${blockchainFilterAddressPath}`

export const createBlockchainFilterPath = (
  coin: CoinType,
  entity: BlockchainFilterPathParams['entity']
) => `/${coin}/${blockchainFilterPath}/${entity}`
