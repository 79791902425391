import { InjectComponentModel } from '@clain/core/utils'
import { FormatProvider } from '@clain/core/ui-kit'
import { useInjectBlockchainModel } from '../hooks'
import {
  AddressesSearchViewModelProvider,
  TransactionsSearchViewModelProvider,
} from '../context'
import { DataTransactionsSearchViewModel } from '../model/transactions'
import { DataAddressesSearchViewModel } from '../model/addresses'
import { ICreateViewModelContext } from '@clain/core/utils/mobxUtils'
import { IBlockchainFilterEntityPage } from '../types'
import { useBlockchainFilterEntityPage } from '../hooks/useBlockchainFilterEntityPage'
import { PropsWithChildren, useEffect } from 'react'
import { useParams, generatePath, redirect } from '@clain/core/Router/router'
import { BlockchainFilterPathParams } from '@platform/constants'
import { UTXO_COINS } from '@clain/core/utils/currency'
import { useTimezone } from '@platform/hooks'

interface IEntitiesModelProvider extends IBlockchainFilterEntityPage {
  viewModel: DataTransactionsSearchViewModel | DataAddressesSearchViewModel
}

export const EntitiesModelProvider = InjectComponentModel<
  PropsWithChildren<IEntitiesModelProvider>,
  Omit<PropsWithChildren<IEntitiesModelProvider>, 'children'>
>(
  ({ isTransactions, viewModel, children }) => {
    const ViewModelProvider = (
      isTransactions
        ? TransactionsSearchViewModelProvider
        : AddressesSearchViewModelProvider
    ) as ICreateViewModelContext<
      DataTransactionsSearchViewModel | DataAddressesSearchViewModel
    >['ViewModelProvider']

    return (
      <ViewModelProvider viewModel={viewModel}>
        <FormatProvider
          precision={{
            btc: 8,
            eth: 8,
            bnb: 8,
            trx: 6,
            doge: 8,
            ltc: 8,
            usd: 2,
          }}
        >
          {children}
        </FormatProvider>
      </ViewModelProvider>
    )
  },
  {
    useFactory: (props) => {
      const { viewModel } = useInjectBlockchainModel()
      const { isTransactions } = useBlockchainFilterEntityPage()
      const timezone = useTimezone()
      const params = useParams<BlockchainFilterPathParams>()
      const { coin } = params

      useEffect(() => {
        if (timezone) {
          viewModel.init(coin, timezone)
          return () => {
            viewModel.clear()
          }
        }
      }, [coin, timezone])

      useEffect(() => {
        // @ts-ignore
        if (!UTXO_COINS.includes(coin)) {
          const redirectUrl = coin
            ? generatePath('/:coin/dashboard', { coin })
            : '/'

          redirect(redirectUrl)
        }
      }, [coin])

      return { ...props, viewModel, isTransactions }
    },
  }
)
