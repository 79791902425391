import { useTranslation } from 'react-i18next'

export const useGroupByFilter = () => {
  const { t } = useTranslation()

  const groupByOptions = [
    {
      label: t('platform:day'),
      value: 'day',
    },
    {
      label: t('platform:week'),
      value: 'week',
    },
  ] as const

  const groupByOptionsMap = {
    day: t('platform:day'),
    week: t('platform:week'),
  }

  return {
    groupByOptions,
    groupByOptionsMap,
  }
}
