import { createFilterKeys } from '@clain/core/utils'

export const FILTER_CALENDAR_KEY = createFilterKeys('calendar')
export const FILTER_INPUT_SCORE_KEY = createFilterKeys('input_score')
export const FILTER_OUTPUT_SCORE_KEY = createFilterKeys('output_score')

export const FILTER_AMOUNT_KEY = createFilterKeys('amount')
export const FILTER_INPUT_AMOUNT_KEY = createFilterKeys('input_amount')
export const FILTER_OUTPUT_AMOUNT_KEY = createFilterKeys('output_amount')
export const FILTER_NUMBER_INPUTS_KEY = createFilterKeys('numberInputs')
export const FILTER_NUMBER_OUTPUTS_KEY = createFilterKeys('numberOutputs')
export const FILTER_FEE_KEY = createFilterKeys('fee')
export const FILTER_FEE_BYTE_KEY = createFilterKeys('feeByte')
export const FILTER_SIZE_KEY = createFilterKeys('size')
export const FILTER_VSIZE_KEY = createFilterKeys('vSize')

export const FILTER_VERSION_KEY = createFilterKeys('version')
export const FILTER_RBF_KEY = createFilterKeys('rbf')
export const FILTER_SEGWIT_KEY = createFilterKeys('segwit')
export const FILTER_LOCKTIME_KEY = createFilterKeys('locktime')
export const FILTER_INPUT_COMPRESSION_KEY =
  createFilterKeys('input_compression')
export const FILTER_OUTPUT_COMPRESSION_KEY =
  createFilterKeys('output_compression')
export const FILTER_INPUT_TYPE_KEY = createFilterKeys('input_type')
export const FILTER_OUTPUT_TYPE_KEY = createFilterKeys('output_type')
export const FILTER_MULTI_SIG_KEY = createFilterKeys('locktime')
export const FILTER_OUTPUT_CHANGE_KEY = createFilterKeys('output_change')
export const FILTER_OUTPUT_MULTISIG_KEY = createFilterKeys('output_multisig')
export const FILTER_INPUT_MULTISIG_KEY = createFilterKeys('input_multisig')

export const FILTER_RECEIVED_AMOUNT_KEY = createFilterKeys('receivedAmount')
export const FILTER_BALANCE_KEY = createFilterKeys('balance')

export const FILTER_ASSET_KEY = createFilterKeys('asset')
