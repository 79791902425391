import { useTranslation } from 'react-i18next'
import { useFilterSlice } from '../TransactionUtxoFilters.service'
import { useTransactionStaticStore } from '../../context'
import {
  SelectFilter,
  SelectFilterCondition,
} from '../../../../../Filters/SelectFilter'
import { useSelectFilterOptions } from '../../../../../Filters/SelectFilter/SelectFilter.constants'

export const LocktimeFilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice('locktime.condition')
  const transactionStaticStore = useTransactionStaticStore()
  const locktimeConditions = useSelectFilterOptions()

  return (
    <SelectFilterCondition
      options={locktimeConditions}
      selected={condition}
      onChange={setFilter}
      size={transactionStaticStore.current.filterSize}
    />
  )
}

export const LocktimeFilterContainer = () => {
  const [filter] = useFilterSlice('locktime.data')
  const [, { resetFilter }] = useFilterSlice('locktime')
  const [condition] = useFilterSlice('locktime.condition')
  const transactionStaticStore = useTransactionStaticStore()
  const { t } = useTranslation()

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      icon="ClockLocktime"
      size={transactionStaticStore.current.filterSize}
      label={t('platform:transaction')}
      clearFilter={resetFilter}
      conditionComponent={<LocktimeFilterConditionContainer />}
    />
  )
}
