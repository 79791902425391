import { ComparatorFilter } from '@platform/components/BlockchainFilter/containers'
import { useAddressesFilterPropsById } from './AddressesFilter.const'
import { AddressesFiltersStateKeys } from './AddressesFilters.service'
import {
  FILTER_ASSET_KEY,
  FILTER_BALANCE_KEY,
  FILTER_RECEIVED_AMOUNT_KEY,
} from '@platform/components/BlockchainFilter/constants'
import { AssetFilter } from './AddressesAssetFilter'

export const CreateFilter = (filter: AddressesFiltersStateKeys) => {
  const { AddressesComparatorFilterPropsById } = useAddressesFilterPropsById()

  switch (filter) {
    case FILTER_RECEIVED_AMOUNT_KEY.root:
    case FILTER_BALANCE_KEY.root:
      return (
        <ComparatorFilter.Container
          key={filter}
          {...AddressesComparatorFilterPropsById[filter]}
        />
      )
    case FILTER_ASSET_KEY.root:
      return (
        <AssetFilter.Container
          key={filter}
          {...AddressesComparatorFilterPropsById[filter]}
        />
      )
  }
}
