import { useComparators } from '@clain/core/ui-kit'
import { FiltersBar, FiltersBarProps } from '../../FiltersBar'
import { MultisigFilterProps } from './MultisigFilter.types'
import { useTranslation } from 'react-i18next'

export const MultisigFilter = ({
  selectedCondition,
  size = 'md',
  label,
  icon,
  clearFilter,
  menuComponent,
  operatorComponent,
  values,
}: MultisigFilterProps &
  Partial<Pick<FiltersBarProps, 'size' | 'clearFilter'>>) => {
  const { t } = useTranslation()
  const comparators = useComparators()

  if (!selectedCondition) {
    return null
  }

  const [fromValue, toValue] = values && Array.isArray(values) ? values : []

  const filterValueLabel =
    fromValue && toValue
      ? t('platform:multisigWithValues', '', { from: fromValue, to: toValue })
      : t('platform:multisig')

  return (
    <FiltersBar
      size={size}
      filterName={{ label, icon }}
      operator={{
        label:
          selectedCondition === comparators.isAny.value
            ? 'is'
            : comparators[selectedCondition].text[0],
        operatorComponent,
      }}
      filterValue={{
        label: filterValueLabel,
        menuComponent,
      }}
      clearFilter={clearFilter}
    />
  )
}
