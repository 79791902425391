import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@clain/core/ui-kit'
import { IconButton } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

import { ToolPanelAddAlert } from '../ToolPanelAddAlert'

import styles from '../index.scss'

const cx = classnames.bind(styles)

interface AddressEvmToolpanelProps {
  plotParent: () => void
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

const AddressEvmToolpanel: React.FC<AddressEvmToolpanelProps> = ({
  plotParent,
  deleteNode,
  isDeleteNodeDisabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <Tooltip placement="right" content={t('platform:plotParent')}>
          <IconButton size="md" iconVariant="Parent" onClick={plotParent} />
        </Tooltip>
        <IconButton size="md" iconVariant="Connections" disabled />
        <IconButton size="md" iconVariant="AttributeBelongs" disabled />
        <IconButton size="md" iconVariant="Pallete" disabled />
        <ToolPanelAddAlert />
      </RowDeprecated>
      <div className={cx('Delimeter')} />
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <Tooltip
          placement="right"
          content={t(
            isDeleteNodeDisabled
              ? 'platform:deletingDisabled'
              : 'platform:removeNode'
          )}
        >
          <IconButton
            size="md"
            disabled={isDeleteNodeDisabled}
            onClick={isDeleteNodeDisabled ? null : deleteNode}
            iconVariant="Delete"
          />
        </Tooltip>
      </RowDeprecated>
    </React.Fragment>
  )
}

export default AddressEvmToolpanel
