import { action, computed, makeObservable, observable } from 'mobx'
import { isToday, isThisWeek, isThisMonth } from 'date-fns'
import { CaseState } from '../../states'

import { ACTIVITY_TYPE, Feed, File } from '../../types'

interface FilesViewModelProps {
  caseCtx: {
    caseState: CaseState
  }
}

export class FilesViewModel {
  private caseState: CaseState

  @observable public isLightboxActive = false
  @observable public lightboxIndex = 0

  public constructor({ caseCtx: { caseState } }: FilesViewModelProps) {
    makeObservable(this)
    this.caseState = caseState
  }

  @action.bound
  public setIsLightboxActive(isLightboxActive: boolean) {
    this.isLightboxActive = isLightboxActive
  }

  @action.bound
  public setLightboxIndex(lightboxIndex: number) {
    this.lightboxIndex = lightboxIndex
  }

  @action.bound
  public handleClickOnFileItem(index: number) {
    this.lightboxIndex = index
    this.isLightboxActive = true
  }

  @computed public get showStub(): boolean {
    return this.files.length <= 0
  }

  @computed public get showEarlierFilesGroupOnly(): boolean {
    return this.groupedFiles.earlier.length === this.files.length
  }

  @computed public get files(): Array<File> {
    return this.feed.reduce((files, { activity }) => {
      if (
        activity.type === ACTIVITY_TYPE.CASE_NOTE_CREATED &&
        activity.files?.length
      ) {
        return [...files, ...activity.files]
      }

      return files
    }, [])
  }

  @computed public get groupedFiles(): {
    day: Array<File & { index: number }>
    week: Array<File & { index: number }>
    month: Array<File & { index: number }>
    earlier: Array<File & { index: number }>
  } {
    return this.files.reduce(
      (groups, file, index) => {
        if (isToday(file.uploadedAt)) {
          return {
            ...groups,
            day: [...groups.day, { ...file, index }],
          }
        }

        if (isThisWeek(file.uploadedAt)) {
          return {
            ...groups,
            week: [...groups.week, { ...file, index }],
          }
        }

        if (isThisMonth(file.uploadedAt)) {
          return {
            ...groups,
            month: [...groups.month, { ...file, index }],
          }
        }

        return {
          ...groups,
          earlier: [...groups.earlier, { ...file, index }],
        }
      },
      {
        day: [],
        week: [],
        month: [],
        earlier: [],
      }
    )
  }

  @computed private get feed(): Feed {
    return this.caseState.feed || []
  }
}
