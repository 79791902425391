import React, { memo } from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import {
  capitalizeFirstLetter,
  CoinType,
  Icon,
  notify,
  Typography,
} from '@clain/core/ui-kit'
import { Modal } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Form, { Field, validators } from '@clain/core/Form'
import { TextField } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { ButtonSelect } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import styles from './CreateAlertModal.scss'
import {
  EntityAutocompleteValue,
  CreateAlertModalProps,
  CreateAlertFormKeys,
  CreateAlertForm,
} from './CreateAlertModal.types'

import { validateNumber } from '@clain/core/utils/tools'
import { omit } from 'ramda'
import { NewAlert } from '../../../../../modules'
import { selectByKeys } from '@clain/core/utils'
import { useAlertCurrencyText } from './hooks/useAlertCurrencyText'
import { useAlertInitialValues } from './hooks/useAlertInitialValues'
import { useAlertOptions } from './hooks/useAlertOptions'
import { CreateAlertTokenFilter } from './CreateAlertTokenFilter'
import { EntityAutocomplete } from './EntityAutocomplete'

const cx = classnames.bind(styles)

const FieldThreshold: React.FC<{ currecy: string; disabled: boolean }> = memo(
  ({ currecy, disabled }) => {
    return (
      <Field
        fullWidth
        name="threshold"
        as={({
          value,
          onChange,
        }: {
          value: string
          onChange: (value: string) => void
        }) => (
          <TextField
            variant="outline"
            value={value}
            name="threshold"
            placeholder={`${currecy ? currecy.toUpperCase() : ''} >= 0`}
            disabled={disabled}
            onChange={(value) => {
              if (validateNumber(value)) {
                onChange(value)
              }
            }}
          />
        )}
      />
    )
  }
)

FieldThreshold.displayName = 'FieldThreshold'

export const CreateAlertModal: React.FC<CreateAlertModalProps> = observer(
  (props) => {
    const { t } = useTranslation()
    const [formError, setFormError] = React.useState()
    const { getInitialValues } = useAlertInitialValues()
    const { getCurrencyText } = useAlertCurrencyText()
    const { triggerFrequencyOptions, currencyOptions, directionOptions } =
      useAlertOptions()

    return (
      <Modal
        target={props.target}
        className={cx('CreateAlertModal')}
        preventScroll={true}
        visible={props?.visible}
        onRequestClose={props?.onRequestClose}
      >
        {({ hide }) => {
          const onFormSubmit = async (
            data: NewAlert &
              CreateAlertForm & {
                item: EntityAutocompleteValue & {
                  currency: CoinType
                }
              }
          ) => {
            setFormError(undefined)
            try {
              let finalData: NewAlert
              const tokenId = data.asset.isBaseToken ? 0 : data.asset.id
              if (props.type === 'probe') {
                finalData =
                  props.nodeType === 'address'
                    ? {
                        ...omit(['item'], data),
                        currency: props.currency,
                        tokenId,
                        address: `${props.id}`,
                      }
                    : {
                        ...omit(['item', 'asset'], data),
                        currency: props.currency,
                        tokenId,
                        cluster: props.id,
                      }
              } else {
                finalData = data.item.aid
                  ? {
                      ...data,
                      currency: data.item.currency,
                      tokenId,
                      address: data.item.address,
                    }
                  : {
                      ...data,
                      currency: data.item.currency,
                      tokenId,
                      cluster: data.item.cluster_id,
                    }
              }

              await props.onSubmit(finalData)
              notify(
                t('platform:alertCreatedSuccess', '', {
                  tag: selectByKeys(CreateAlertFormKeys.TAG, finalData),
                }),
                {
                  type: 'success',
                }
              )
              hide()
              props?.onRequestClose?.({} as any)
            } catch (data) {
              setFormError(data.error)
            }
          }

          return (
            <React.Fragment>
              <RowDeprecated align="between" className={cx('Header')}>
                <RowDeprecated
                  align="between"
                  gap={props.type === 'probe' ? 1 : 0}
                >
                  <Typography variant="heading5" color="black">
                    {t('platform:addAlert')}
                  </Typography>
                </RowDeprecated>
                <CloseIcon
                  className={cx('CloseIcon')}
                  onClick={(e) => {
                    hide()
                    props?.onRequestClose?.(e)
                  }}
                />
              </RowDeprecated>
              <Form
                className={cx('NewAlertForm')}
                initialValues={getInitialValues()}
                onSubmit={onFormSubmit}
              >
                {({ form }) => {
                  const { valid, values } = form.getState()

                  return (
                    <ColDeprecated gap={2}>
                      <RowDeprecated gap={1.5}>
                        <Label
                          content={
                            props.type === 'probe'
                              ? props.nodeType === 'cluster'
                                ? t('platform:cluster')
                                : t('platform:address')
                              : t('platform:clusterNameOrAddressRequired')
                          }
                          className={cx('Search')}
                          color="grey1"
                        >
                          {props.type === 'probe' ? (
                            <RowDeprecated
                              align="left"
                              gap={1}
                              className={cx('ProbeInfoRow')}
                            >
                              <Icon
                                variant={capitalizeFirstLetter(props.currency)}
                                size="sm"
                              />
                              <RowDeprecated
                                gap={0.5}
                                className={cx('ProbeInfo')}
                              >
                                <Score
                                  value={props.score}
                                  useNewColors={true}
                                  size="sm"
                                />
                                <Typography
                                  variant="body1"
                                  color="black"
                                  ellipsis
                                >
                                  {props.entities}
                                </Typography>
                              </RowDeprecated>
                            </RowDeprecated>
                          ) : (
                            <Field
                              fullWidth
                              name="item"
                              variant="outline"
                              as={EntityAutocomplete}
                              validate={validators.required()}
                            />
                          )}
                        </Label>
                        <Label
                          content={t('platform:asset')}
                          className={cx('Asset')}
                          color="grey1"
                          as="div"
                        >
                          <CreateAlertTokenFilter
                            currency={
                              props.type === 'probe'
                                ? props.currency
                                : selectByKeys(
                                    CreateAlertFormKeys.ITEM_CURRENCY,
                                    values
                                  )
                            }
                            value={
                              selectByKeys(CreateAlertFormKeys.ASSET, values)
                                ?.id
                            }
                            onChange={(value) => {
                              form?.change(CreateAlertFormKeys.ASSET, value)
                            }}
                            disabled={
                              props.type === 'case' &&
                              !selectByKeys(CreateAlertFormKeys.ITEM, values)
                            }
                            placeholder={t('platform:selectAsset')}
                          />
                        </Label>
                      </RowDeprecated>
                      <RowDeprecated gap={1.5}>
                        <Label
                          content={t('platform:alertLabel')}
                          className={cx('Description')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="tag"
                            variant="outline"
                            as={TextField}
                            placeholder={t(
                              'platform:alertDescriptionPlaceholder',
                              '',
                              { maxLength: 70 }
                            )}
                            validate={validators.required()}
                          />
                        </Label>
                        <Label
                          content={t('platform:trigger')}
                          className={cx('TriggerFrequency')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="recurrent"
                            variant="outline"
                            as={({ value, onChange }) => (
                              <ButtonSelect
                                fullWidth
                                value={value}
                                onChange={onChange}
                                options={triggerFrequencyOptions}
                              />
                            )}
                            validate={validators.required()}
                          />
                        </Label>
                      </RowDeprecated>

                      <RowDeprecated gap={1.5}>
                        <Label
                          content={t('platform:direction')}
                          className={cx('Direction')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="direction"
                            variant="outline"
                            as={({ value, onChange }) => (
                              <ButtonSelect
                                fullWidth
                                value={value}
                                onChange={onChange}
                                options={directionOptions}
                              />
                            )}
                            placeholder={t('platform:suspiciousActivity')}
                            validate={validators.required()}
                          />
                        </Label>
                        <Label
                          content={t('platform:threshold')}
                          className={cx('Threshold')}
                          color="grey1"
                        >
                          <FieldThreshold
                            currecy={getCurrencyText(props, values)}
                            disabled={
                              props.type === 'case' &&
                              !selectByKeys(CreateAlertFormKeys.ITEM, values)
                            }
                          />
                        </Label>
                        <Label
                          content={t('platform:currency')}
                          className={cx('Currency')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="native"
                            variant="outline"
                            as={({ value, onChange }) => (
                              <ButtonSelect
                                fullWidth
                                value={value}
                                onChange={onChange}
                                options={currencyOptions}
                              />
                            )}
                            validate={validators.required()}
                          />
                        </Label>
                      </RowDeprecated>
                      <RowDeprecated className={cx('FormButtons')}>
                        <Button
                          size="lg"
                          variant="outline"
                          color="secondary"
                          onClick={(e) => {
                            hide()
                            props?.onRequestClose?.(
                              e as unknown as React.MouseEvent<
                                Element,
                                MouseEvent
                              >
                            )
                          }}
                        >
                          {t('platform:cancel')}
                        </Button>
                        <Button
                          disabled={!valid}
                          size="lg"
                          variant="solid"
                          color="primary"
                          type="submit"
                        >
                          {t('platform:addAlert')}
                        </Button>
                      </RowDeprecated>
                      {formError && (
                        <Typography className={cx('Error')} color="magenta">
                          {t('platform:alertError', '', {
                            error: JSON.stringify(formError),
                          })}
                        </Typography>
                      )}
                    </ColDeprecated>
                  )
                }}
              </Form>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
)
