import { injectable, inject } from 'inversify'

import { AddVirtualNodes } from '../AddVirtualNodes'
import { GenerateNode } from './GenerateNode'

import type { IPositioningEntities } from '../PositioningEntities'
import type { IAddedEntities } from '../AddedEntities'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IGenerateEntities } from '../../GraphEvents.types'
import { EventOsint, IEntitiesMainState, ServerAddEvents } from '../../types'
import { osintKey } from '../../utils'

@injectable()
export class GenerateNodeOsint extends GenerateNode<EventOsint> {
  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    probeState: IEntitiesMainState,
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.PositioningEntities)
    private positioningEntities: IPositioningEntities
  ) {
    super(probeState, addedEntities, addVirtualNodes)
  }

  public produce = async (
    ...params: Parameters<IGenerateEntities<EventOsint>['produce']>
  ): Promise<ServerAddEvents> => {
    const [{ data, meta }] = params
    const key = osintKey(data)
    const position = this.positioningEntities.run('osint')

    const nodes = this.nodes({ meta })

    if (!this.isNodeExists(key)) {
      nodes.push({
        type: 'add_node',
        key,
        data: {
          id: data.id,
          position,
          currency: data.currency,
          type: 'osint',
          nodeData: {
            address: data.address,
          },
        },
      })
    }

    return nodes.acc
  }
}
