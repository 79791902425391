import { reaction, IReactionDisposer } from 'mobx'
import { ProbeViewModel } from './ProbeViewModel'
import {
  PROBE_BOTTOMBAR_COLLAPSED_HEIGHT,
  PROBE_BOTTOMBAR_HEIGHT,
  PROBE_INFOBAR_WIDTH,
} from './constants'
import { IProbeState } from './ProbeState'

export interface IResizeGraphViewport {
  observer: () => void
  clear: () => void
}

export class ReziseGraphViewport implements IResizeGraphViewport {
  private probeVM: ProbeViewModel
  private reactionDisposers: Array<IReactionDisposer> = []
  private prevOpenState = false

  constructor(probeVM: ProbeViewModel) {
    this.probeVM = probeVM
  }

  private resize = ({
    isBottombarActive,
    bottombarStatus,
    isInfobarActive,
  }: Pick<
    IProbeState,
    'isBottombarActive' | 'bottombarStatus' | 'isInfobarActive'
  >) => {
    if (!this.probeVM.container) return
    const { width, height } = this.probeVM.container.getBoundingClientRect()
    let bottombarHeight = 0
    let infobarWidth = 0

    if (isBottombarActive) {
      bottombarHeight =
        bottombarStatus === 'default'
          ? PROBE_BOTTOMBAR_HEIGHT
          : PROBE_BOTTOMBAR_COLLAPSED_HEIGHT
    }

    if (isInfobarActive) {
      infobarWidth = PROBE_INFOBAR_WIDTH
    }

    const offsetX =
      this.prevOpenState === isInfobarActive
        ? 0
        : !this.prevOpenState
          ? infobarWidth
          : -PROBE_INFOBAR_WIDTH

    this.probeVM.app.resize(
      { width, height },
      {
        width: width - infobarWidth,
        height: height - bottombarHeight,
        offsetX,
        offsetViewportX: infobarWidth,
      }
    )
    this.prevOpenState = isInfobarActive
  }

  private resizeWithContainers = () => {
    this.resize({
      isBottombarActive: this.probeVM.probeState.isBottombarActive,
      bottombarStatus: this.probeVM.probeState.bottombarStatus,
      isInfobarActive: this.probeVM.probeState.isInfobarActive,
    })
  }

  public observer = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          isBottombarActive: this.probeVM.probeState.isBottombarActive,
          bottombarStatus: this.probeVM.probeState.bottombarStatus,
          isInfobarActive: this.probeVM.probeState.isInfobarActive,
        }),
        this.resize
      )
    )
    window.addEventListener('resize', this.resizeWithContainers)
  }

  clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    window.removeEventListener('resize', this.resizeWithContainers)
  }
}
