import { IBlockchainFilterView } from '../types/IBlockchainFilterView'
import { useFiltersContext } from './useFiltersContext'
import { useBlockchainFilterEntityPage } from './useBlockchainFilterEntityPage'

export const useBlockchainFilterPage = (): IBlockchainFilterView => {
  const viewModel = useFiltersContext()
  const { isTransactions } = useBlockchainFilterEntityPage()

  return {
    viewModel,
    isTransactions,
  }
}
