import React from 'react'
import { Tag, TypographyNew, RowDeprecated } from '@clain/core/ui-kit'
import { CaseListItemProps } from './types'
import {
  STATUS_TO_TAG_LABEL,
  STATUS_TO_TAG_TYPE_VARIANT,
} from '../../../../constants'

import classnames from 'classnames/bind'
import styles from './index.scss'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export const CaseListItem = (props: CaseListItemProps) => {
  const { t } = useTranslation()

  return (
    <RowDeprecated
      align={'between'}
      className={cx('listItem', { selected: props.isSelected })}
      onClick={() => props.selectCase(props.id)}
    >
      <RowDeprecated>
        <div
          className={cx('severity')}
          style={{ backgroundColor: props.severity.color }}
          title={props.severity.label}
        />

        <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
          {props.title}
        </TypographyNew>
      </RowDeprecated>

      <RowDeprecated gap={0.5}>
        {props.isShared && (
          <Tag size={'sm'} type={'outline'} variant={'secondary'}>
            {t('platform:sharedWithMe')}
          </Tag>
        )}

        <Tag size={'sm'} {...STATUS_TO_TAG_TYPE_VARIANT[props.status]}>
          {t(`platform:${STATUS_TO_TAG_LABEL[props.status]}`)}
        </Tag>
      </RowDeprecated>
    </RowDeprecated>
  )
}
