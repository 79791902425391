import React from 'react'
import { observer } from 'mobx-react-lite'
import { ScoreSquare, RowDeprecated, ActivityPeriod } from '@clain/core/ui-kit'
import {
  EntityDescriptionWrapper,
  EntityDetailsLayout,
  RowAddresses,
  RowBalance,
  RowCategory,
  RowCountry,
  RowEntityName,
  RowWebsite,
} from '../../../EntityDetails'
import { useClusterDetailsData } from './useClusterDetailsData'
import { useTranslation } from 'react-i18next'

const ClusterDetailsComponent = () => {
  const { t } = useTranslation()
  const {
    detailsData: {
      isLoading,
      score,
      entityName,
      category,
      country,
      size,
      description,
      statsPeriodLocal,
      balance,
      totalReceived,
      totalSent,
      transactionsCount,
      firstLink,
      restLinks,
      hrefs,
      timezone,
    },
    formatDate,
    formatNumber,
  } = useClusterDetailsData()

  return (
    <EntityDetailsLayout
      isLoading={isLoading}
      scoreSlot={<ScoreSquare value={score} />}
      titleSlot={<RowEntityName name={entityName} />}
      informationSlot={
        <RowDeprecated gap={1.5}>
          <RowCategory category={category} />
          <RowWebsite
            firstLink={firstLink}
            hrefs={hrefs}
            restLinks={restLinks}
          />
          <RowCountry country={country} />
          <RowAddresses size={size} formatNumber={formatNumber} />
        </RowDeprecated>
      }
      descriptionSlot={
        <EntityDescriptionWrapper>
          {description || t('platform:clusterDetailsNoDescription')}
        </EntityDescriptionWrapper>
      }
      activityPeriodSlot={
        <ActivityPeriod
          timeZone={timezone}
          today={t('platform:clusterDetailsToday')}
          activityPeriod={statsPeriodLocal}
          //label={t('platform:clusterDetailsActivityPeriod')}
        />
      }
      balanceSlot={
        <RowBalance
          balance={balance}
          received={totalReceived}
          sent={totalSent}
          transactions={transactionsCount}
          labels={{
            balance: t('platform:clusterDetailsTotalBalance'),
            received: t('platform:clusterDetailsTotalReceived'),
            sent: t('platform:clusterDetailsTotalSent'),
            transactions: t('platform:clusterDetailsTotalTransactions'),
          }}
        />
      }
    />
  )
}

export const ClusterDetails = observer(ClusterDetailsComponent)
