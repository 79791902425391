import { createViewModelContext } from '@clain/core/utils/mobxUtils'
import { filtersContextFactory } from '@clain/core/ui-kit'
import {
  createInjectFiltersHook,
  createInjectFiltersModel,
} from '@clain/core/utils'

export const {
  ViewModelProvider: FiltersProvider,
  useViewModel: useFiltersModel,
} = createViewModelContext<ReturnType<typeof filtersContextFactory<any>>>()

export const injectFiltersModel = createInjectFiltersModel(useFiltersModel)

export const injectFiltersHook = createInjectFiltersHook(useFiltersModel)
