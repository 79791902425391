import { observer } from 'mobx-react-lite'
import { useAddressesSearchViewModel } from '../../context'
import { CoinType } from '@platform/types/coin'

type TransactionListProps = {
  coin: CoinType
}

export const AddressesList: React.FC<TransactionListProps> = observer(
  ({ coin }) => {
    const _ = useAddressesSearchViewModel()

    return <>table addresses</>
  }
)
