import { ContainerModule, interfaces } from 'inversify'
import { DI_PROBE_TYPES } from '../DITypes'
import { IProbeState, probeState } from '../../vm/ProbeState'
import { IProbeEvents, probeEvents } from '../../vm/ProbeEvents'

const initializeModule = (bind: interfaces.Bind) => {
  bind<IProbeState>(DI_PROBE_TYPES.ProbeState).toConstantValue(probeState)
  bind<IProbeEvents>(DI_PROBE_TYPES.ProbeEvents).toConstantValue(probeEvents)
}

export const probeBaseModules = new ContainerModule(initializeModule)
