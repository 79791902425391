import {
  Foggy,
  Stack,
  StackProps,
  StyledProps,
  TableCellColumn,
  TableRow,
} from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const VisibilityContainer = styled.div<
  StyledProps<{ visible: boolean }>
>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden ')};
`

export const FoggyStyled = styled(Foggy)`
  width: 100%;
`

export const TableCellContainer = styled(Stack).attrs<
  StackProps & StyledProps<{ muted?: boolean }>
>((props) => ({
  ...props,
  align: 'center',
}))`
  width: 100%;
  height: 31px;

  ${({ $muted }) => {
    return css({
      opacity: $muted ? 0.3 : 1,
    })
  }}
`

export const TableRowStyled = styled(TableRow)`
  &:hover ${TableCellContainer} {
    opacity: 1;
  }
`

export const TableCellStyled = styled(TableCellColumn)<
  StyledProps<{ removeBorder: boolean }>
>`
  ${({ $removeBorder }) => {
    if ($removeBorder) {
      return css`
        border-bottom: none;
      `
    }
  }}
`

export const StackCell = styled(Stack)`
  height: 100%;
`

export const TrxEvmTableCell = styled.div`
  width: 100%;
  height: 100%;
`
