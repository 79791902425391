import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface EditInternalIdModalProps {
  visible: boolean
  open: () => void
  close: () => void
  internalId: string
  onChange: (internalId: string) => void
}

const EditInternalIdModal: React.FC<
  PropsWithChildren<EditInternalIdModalProps>
> = ({ children, visible, open, close, internalId = '', onChange }) => {
  const { t } = useTranslation()
  const [newInternalId, setNewInternalId] = React.useState(internalId)

  const apply = () => {
    onChange(newInternalId)
    close()
  }

  const discard = () => {
    close()
    setNewInternalId(internalId)
  }

  const target: ModalSlot = ({ show }) => {
    return <div onClick={open}>{children}</div>
  }

  const body: ModalSlot = ({ hide }) => (
    <>
      <div className={cx('TopPanel')}>
        <Typography variant="heading5" color="black">
          {t('platform:editInternalCaseNumber')}
        </Typography>
        <CloseIcon className={cx('CloseIcon')} onClick={discard} />
      </div>
      <TextField
        variant="outline"
        value={newInternalId}
        defaultValue={newInternalId}
        onChange={setNewInternalId}
        fullWidth={true}
        placeholder={t('platform:insertInternalCaseId')}
        autoFocus={true}
      />
      <div className={cx('BottomPanel')}>
        <Button
          variant="outline"
          color="secondary"
          className={cx('CancelButton')}
          onClick={discard}
        >
          {t('platform:cancel')}
        </Button>
        <Button
          variant="solid"
          color="primary"
          className={cx('ApplyButton')}
          onClick={apply}
        >
          {t('platform:confirm')}
        </Button>
      </div>
    </>
  )

  return (
    <Modal
      target={target}
      visible={visible}
      preventScroll={true}
      onRequestClose={discard}
      className={cx('EditInternalIdModal')}
    >
      {body}
    </Modal>
  )
}

export default EditInternalIdModal
