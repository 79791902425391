import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import {
  Checkbox,
  CheckboxSwitchDouble,
  Icon,
  RowDeprecated,
  Stack,
  SurfaceContainer,
  TypographyNew,
  Virtualizer,
} from '@clain/core/ui-kit'

import BottombarTopPanel from '../BottombarTopPanel'
import ProbeVM from '../../../vm/ProbeViewModel'
import { TransactionCommonEvm } from '../../../types/converted/TransactionEvm'
import { addressKey, edgeEvmTrxByTypeKey } from '../../../utils/key'

import { createFlowId, evmTransactionFlowId } from '../../../utils/createFlowId'
import {
  TransactionAddressTable,
  useInteraction,
  useProbeTransactionAddressEvmTableConfig,
  TransactionAddressSelectedEvmTableItem,
} from '../../../../EntitiesTable'
import { CoinTypeEVM } from '../../../../../types/coin'
import * as S from './TransactionEvmBottombar.styles'

interface TransactionEthBottombarProps {
  itemsPerPage: number
  transaction: TransactionCommonEvm
}

const TransactionEvmBottombar: React.FC<TransactionEthBottombarProps> = ({
  transaction,
}) => {
  const { t } = useTranslation()
  const [raw, setRaw] = React.useState(false)
  const activeEntity = ProbeVM.activeEntity.transactionEvm
  const currency = ProbeVM.activeEntity.currency as CoinTypeEVM

  const transfersData: Array<TransactionAddressSelectedEvmTableItem> = (() => {
    if (!transaction?.transfers) return []

    return transaction?.transfers.map(
      ({ from, to, value, usd, ...rest }, index) => {
        const fromSelected = ProbeVM.probeState.edges.has(
          edgeEvmTrxByTypeKey(
            addressKey({ address: from.hash, currency }),
            transaction.hash,
            {
              index,
              type: 'transfer',
            }
          )
        )

        const toSelected = ProbeVM.probeState.edges.has(
          edgeEvmTrxByTypeKey(
            transaction.hash,
            addressKey({ address: to.hash, currency }),
            {
              index,
              type: 'transfer',
            }
          )
        )

        const isProcessing =
          ProbeVM.probeEvents.meta.nodesInProcessingFlow[
            evmTransactionFlowId({
              hash: transaction.hash,
              type: 'transfer',
              index,
            })
          ]?.includes(transaction.hash) ||
          !!ProbeVM.probeEvents.meta.nodesInProcessingFlow[
            createFlowId(['transfer', 'all'])
          ]

        return {
          ...rest,
          from,
          to,
          amount: value,
          amountUsd: usd,
          index,
          selected: fromSelected && toSelected,
          isProcessing,
        }
      }
    )
  })()

  const internalsData: Array<TransactionAddressSelectedEvmTableItem> = (() => {
    if (!transaction?.internals) return []

    return transaction.internals.map((internal) => {
      const fromSelected = ProbeVM.probeState.edges.has(
        edgeEvmTrxByTypeKey(
          addressKey({ address: internal.from.hash, currency }),
          transaction.hash,
          {
            index: internal.index,
            type: 'internal',
          }
        )
      )

      const toSelected = ProbeVM.probeState.edges.has(
        edgeEvmTrxByTypeKey(
          transaction.hash,
          addressKey({ address: internal.to.hash, currency }),
          {
            index: internal.index,
            type: 'internal',
          }
        )
      )

      const isProcessing =
        ProbeVM.probeEvents.meta.nodesInProcessingFlow[
          evmTransactionFlowId({
            hash: transaction.hash,
            type: 'internal',
            index: internal.index,
          })
        ]?.includes(transaction.hash) ||
        !!ProbeVM.probeEvents.meta.nodesInProcessingFlow[
          createFlowId(['internal', 'all'])
        ]

      return {
        ...internal,
        selected: fromSelected && toSelected,
        isProcessing,
      }
    })
  })()

  const tokensData: Array<TransactionAddressSelectedEvmTableItem> = (() => {
    if (!transaction?.tokens) return []

    return transaction.tokens.map((token) => {
      const fromSelected = ProbeVM.probeState.edges.has(
        edgeEvmTrxByTypeKey(
          addressKey({ address: token.from.hash, currency }),
          transaction.hash,
          {
            index: token.logIndex,
            type: 'token_transfer',
          }
        )
      )

      const toSelected = ProbeVM.probeState.edges.has(
        edgeEvmTrxByTypeKey(
          transaction.hash,
          addressKey({ address: token.to.hash, currency }),
          {
            index: token.logIndex,
            type: 'token_transfer',
          }
        )
      )

      const isProcessing =
        ProbeVM.probeEvents.meta.nodesInProcessingFlow[
          evmTransactionFlowId({
            hash: transaction.hash,
            type: 'token_transfer',
            index: token.logIndex,
          })
        ]?.includes(transaction.hash) ||
        !!ProbeVM.probeEvents.meta.nodesInProcessingFlow[
          createFlowId(['token_transfer', 'all'])
        ]

      return {
        ...token,
        currency,
        selected: fromSelected && toSelected,
        index: token.logIndex,
        isProcessing,
      }
    })
  })()

  const {
    isAllChecked: isAllInternalsChecked,
    isAllLoading: isAllInternalsLoading,
    isIndeterminate: isInternalsIndeterminate,
    handleAllSelected: handleChangeInternalsCheckbox,
  } = useInteraction({
    data: internalsData,
    currency: currency as CoinTypeEVM,
    setAllSelected: (data, select) => {
      activeEntity.toggleAllAddresses(data, select, 'internal')
    },
  })

  const {
    isAllChecked: isAllTokensChecked,
    isAllLoading: isAllTokensLoading,
    isIndeterminate: isTokensIndeterminate,
    handleAllSelected: handleChangeTokensCheckbox,
  } = useInteraction({
    data: tokensData,
    currency: currency as CoinTypeEVM,
    setAllSelected: (data, select) => {
      activeEntity.toggleAllAddresses(data, select, 'token_transfer')
    },
  })

  const configTransfer = useProbeTransactionAddressEvmTableConfig({
    setSelected: (...args) => {
      activeEntity.toggleAddresses(...args, 'transfer')
    },
    setAllSelected: (...args) => {
      activeEntity.toggleAllAddresses(...args, 'transfer')
    },
    showInUSD: ProbeVM.isUsdCurrency,
    currency,
  })

  const configInternal = useProbeTransactionAddressEvmTableConfig({
    setSelected: (...args) => {
      activeEntity.toggleAddresses(...args, 'internal')
    },
    setAllSelected: (...args) => {
      activeEntity.toggleAllAddresses(...args, 'internal')
    },
    showInUSD: ProbeVM.isUsdCurrency,
    currency,
  })

  const configTokenTransfer = useProbeTransactionAddressEvmTableConfig({
    setSelected: (...args) => {
      activeEntity.toggleAddresses(...args, 'token_transfer')
    },
    setAllSelected: (...args) => {
      activeEntity.toggleAllAddresses(...args, 'token_transfer')
    },
    showInUSD: ProbeVM.isUsdCurrency,
    currency,
  })

  return (
    <S.TransactionBottombar variant="high">
      <BottombarTopPanel>
        <S.TopPannelContainer justify="space-between" align="center">
          <TypographyNew variant="body100Accent" color="onBackgroundVariant1">
            {t('platform:transactionDetails')}
          </TypographyNew>
          <RowDeprecated align="between">
            <CheckboxSwitchDouble<boolean, boolean>
              value={raw}
              onChange={setRaw}
              leftLabel={t('platform:decoded')}
              rightLabel={t('platform:raw')}
              size="sm"
            />
          </RowDeprecated>
        </S.TopPannelContainer>
      </BottombarTopPanel>
      <S.TransactionBottombarContent>
        {!raw ? (
          <>
            <SurfaceContainer spaces="xl" variant="base">
              <Stack gap="lg" align="center">
                <Icon
                  variant="AlertCircle"
                  color="onBackgroundVariant1"
                  size="xs"
                />
                <TypographyNew
                  variant="body200Normal"
                  color="onBackgroundVariant1"
                >
                  {t('platform:decodedModeDescription')}
                </TypographyNew>
              </Stack>
            </SurfaceContainer>
            {transfersData.length > 0 && (
              <TransactionAddressTable
                data={transfersData}
                currency={currency}
                config={configTransfer}
                isFixedHeight
              />
            )}
          </>
        ) : (
          <>
            <Virtualizer
              data={[...internalsData, ...tokensData]}
              rowHeight={32}
              overscan={15}
              render={({ virtualItems }) => {
                const virtualItemsReduced = virtualItems.reduce<{
                  internals: TransactionAddressSelectedEvmTableItem[]
                  tokens: TransactionAddressSelectedEvmTableItem[]
                }>(
                  (acc, virtualRow) => {
                    if (virtualRow.index < internalsData.length) {
                      return {
                        ...acc,
                        internals: [
                          ...acc.internals,
                          internalsData[virtualRow.index],
                        ],
                      }
                    } else {
                      return {
                        ...acc,
                        tokens: [
                          ...acc.tokens,
                          tokensData[virtualRow.index - internalsData.length],
                        ],
                      }
                    }
                  },
                  { internals: [], tokens: [] }
                )

                return (
                  <S.TableStack>
                    {virtualItemsReduced.internals.length > 0 && (
                      <div>
                        <S.AddressGroupCell>
                          <Checkbox
                            value={isAllInternalsChecked}
                            onChange={handleChangeInternalsCheckbox}
                            indeterminate={isInternalsIndeterminate}
                            isLoading={isAllInternalsLoading}
                          />
                          <TypographyNew
                            variant="body200Accent"
                            color="onBackgroundBase"
                          >
                            {t('platform:internals')}
                          </TypographyNew>
                        </S.AddressGroupCell>
                        <TransactionAddressTable
                          data={virtualItemsReduced.internals}
                          currency={currency}
                          config={configInternal}
                          virtualizer={false}
                          isFixedHeight
                        />
                      </div>
                    )}
                    {virtualItemsReduced.tokens.length > 0 && (
                      <div>
                        <S.AddressGroupCell>
                          <Checkbox
                            value={isAllTokensChecked}
                            onChange={handleChangeTokensCheckbox}
                            indeterminate={isTokensIndeterminate}
                            isLoading={isAllTokensLoading}
                          />
                          <TypographyNew
                            variant="body200Accent"
                            color="onBackgroundBase"
                          >
                            Tokens
                          </TypographyNew>
                        </S.AddressGroupCell>
                        <TransactionAddressTable
                          data={virtualItemsReduced.tokens}
                          config={configTokenTransfer}
                          currency={currency}
                          isFixedHeight
                          virtualizer={false}
                        />
                      </div>
                    )}
                  </S.TableStack>
                )
              }}
            />
          </>
        )}
      </S.TransactionBottombarContent>
    </S.TransactionBottombar>
  )
}

export default observer(TransactionEvmBottombar)
