import { i18next } from '@platform/i18Manager'

// https://github.com/OmniLayer/spec/blob/master/OmniSpecification.adoc#field-transaction-type
export const OMNI_TRANSACTION_TYPE = () => ({
  0: i18next.t('platform:omniTypes.simpleSend'),
  3: i18next.t('platform:omniTypes.sendToOwners'),
  20: i18next.t('platform:omniTypes.sellOmni'),
  21: i18next.t('platform:omniTypes.offerAcceptOmni'),
  22: i18next.t('platform:omniTypes.purchaseOmni'),
  50: i18next.t('platform:omniTypes.createFixedProperty'),
  51: i18next.t('platform:omniTypes.createCrowdsaleProperty'),
  52: i18next.t('platform:omniTypes.promoteProperty'),
  53: i18next.t('platform:omniTypes.closeCrowdsale'),
  54: i18next.t('platform:omniTypes.createManagedProperty'),
  55: i18next.t('platform:omniTypes.grantTokens'),
  56: i18next.t('platform:omniTypes.revokeTokens'),
  70: i18next.t('platform:omniTypes.changeIssuer'),
  185: i18next.t('platform:omniTypes.freezeTokens'),
  186: i18next.t('platform:omniTypes.unfreezeTokens'),
})

export const PREFIX_ADDRESS_NULLDATA = '7'
export const SHOW_ITEM_LIST_LIMIT = 5
export const SHOW_MORE_ITEM_LIST_LIMIT = 50
export const ERROR_TRANSACTION = () => ({
  label: i18next.t('platform:errorTransaction'),
  tooltipContent: i18next.t('platform:errorTransactionTooltip'),
})
