import React, { useMemo } from 'react'
import classNames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { CounterDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as ChevronDownIcon } from '@clain/core/assets/chevron_down.svg'
import { ReactComponent as ChevronUpIcon } from '@clain/core/assets/chevron_up.svg'
import styles from './AlertInfobarHeader.scss'
import { AlertInfobarHeaderProps } from './AlertInfobarHeader.types'
import { AlertsViewModel } from '../../../../../../modules'
import { useCtx } from '../../../../../../ctx'
import useVm from '@clain/core/useVm'
import { getAddressId } from '../../../../vm/active-entity/helpers/getAddressId'
import ProbeVM from '@clain/platform/src/components/ProbeSandbox/vm/ProbeViewModel'
import { alertEventsCountState } from '../../../../../../modules/alerts/AlertsViewModel.utils'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const CountAlert = observer(() => {
  const alertsVm = useVm(AlertsViewModel, useCtx())
  const address = getAddressId(ProbeVM.activeEntity)

  const count = useMemo(
    () => alertEventsCountState(alertsVm.counts, address?.id),
    [address?.id, alertsVm.counts]
  )

  return (
    <CounterDeprecated
      value={count?.seen + count?.markedSeen}
      iconLeft
      variant="alert"
      state={
        count?.seen > 0 ? 'triggered' : count?.active ? 'normal' : 'inactive'
      }
    />
  )
})

export const AlertInfobarHeader: React.FC<AlertInfobarHeaderProps> = ({
  isOpen,
  onIsOpen,
}) => {
  const { t } = useTranslation()

  return (
    <RowDeprecated
      align="between"
      fullWidth
      className={cx('AlertInfobarRow')}
      onClick={onIsOpen}
    >
      <ColDeprecated>
        <RowDeprecated gap={0.5}>
          <CountAlert />
          <Typography variant="body3-sm" color="grey4">
            {t('platform:alerts')}
          </Typography>
        </RowDeprecated>
      </ColDeprecated>
      <ColDeprecated>
        {isOpen ? (
          <ChevronUpIcon className={cx('ChevronIcon')} />
        ) : (
          <ChevronDownIcon className={cx('ChevronIcon')} />
        )}
      </ColDeprecated>
    </RowDeprecated>
  )
}
