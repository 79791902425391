import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'

import {
  TableFilterButton,
  getSortOrder,
  TableSortButton,
  useTableStubs,
  Amount,
  PopoverBaseContainer,
  DataGrid,
} from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { AssetsListShort } from '@clain/core/ui-kit'

import { TransactionFeatureTags } from '../../../ProbeSandbox/ui/FeautureTags'
import TransactionFeaturesFilter from '../../../ProbeSandbox/ui/ProbeBottombar/tables/filters/TransactionFeaturesFilter'
import AmountFilter from '../../../ProbeSandbox/ui/ProbeBottombar/tables/filters/AmountFilter'
import PeriodFilter from '../../../ProbeSandbox/ui/ProbeBottombar/tables/filters/PeriodFilter'
import AssetsRow from '../../../ProbeSandbox/ui/AssetsRow'

import styles from './index.scss'
import { useFormatMoneySettings } from '../../../../hooks/useFormatMoneySettings'
import { ProbeTokenFilter } from '../../../ProbeSandbox/ui/ProbeBottombar/tables/filters/ProbeTokenFilter'
import { ClusterTransactionUtxo } from '../../../ProbeSandbox/types/converted/ClusterTransaction'
import { TransactionFlowUtxoTableProps } from './TransactionFlowUtxoTable.types'
import { AssetFilterPopover, EntityTableNotFound } from '../../ui'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  isDefaultTokenId,
} from '../../../ProbeSandbox/utils/convertTokenBalances'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'

const cx = classnames.bind(styles)

export const TransactionFlowUtxoTable: React.FC<
  TransactionFlowUtxoTableProps
> = ({
  data,
  cumulative,
  setSelected,
  setAllSelected,
  filters,
  setFilters,
  isLoading,
  itemsPerPage = 10,
  allowedRange,
  showInUSD,
  getTokens,
  currency,
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const formatMoneySettings = useFormatMoneySettings({ type: 'probe-tabels' })

  const [isAllTransactionsChecked, setIsAllTransactionsChecked] =
    React.useState(false)
  const [isAllTransactionsLoading, setIsAllTransactionsLoading] =
    React.useState(false)

  React.useEffect(() => {
    if (data?.every((item) => item.selected)) {
      setIsAllTransactionsChecked(true)
    }

    if (data?.every((item) => !item.selected)) {
      setIsAllTransactionsChecked(false)
    }
    if (data?.every((item) => item.isProcessing)) {
      setIsAllTransactionsLoading(true)
    }

    if (data?.every((item) => !item.isProcessing)) {
      setIsAllTransactionsLoading(false)
    }
  }, [data])

  const isIndeterminate = !(
    data?.every((item) => item.selected) ||
    data?.every((item) => !item.selected)
  )

  const handleChangeTransactionCheckbox = () => {
    const selected = isIndeterminate || !isAllTransactionsChecked
    setAllSelected(
      data.filter((item) => (selected ? !item.selected : item.selected)),
      selected
    )
  }

  const onChangeSort = (field: string) =>
    setFilters({
      sortBy: field,
      sortOrder: getSortOrder(filters.sortOrder, filters.sortBy, field),
    })

  const renderFooter = cumulative?.volume

  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : filters?.includeTokens?.[0]?.id !== null ||
          DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
        ? {
            value: amount,
            currency,
          }
        : {
            value: amount,
            decimals: filters?.includeTokens[0].decimals,
            code: filters?.includeTokens[0].symbol,
          }
  }

  const { stubbedColumns, stubbedData } = useTableStubs(
    [
      {
        name: 'transaction',
        width: 2,
        gap: 'xl',
        renderTitle: () => (
          <>
            <Checkbox
              isLoading={isAllTransactionsLoading}
              value={isAllTransactionsChecked}
              onChange={handleChangeTransactionCheckbox}
              indeterminate={isIndeterminate}
              disabled={!data?.length}
            />
            <Typography variant="body2-sm" color="grey2">
              {t('platform:transaction')}
            </Typography>
          </>
        ),
        render: (item) => (
          <>
            <Checkbox
              value={item.selected}
              onChange={() => setSelected(item, !item.selected)}
              isLoading={item.isProcessing}
            />
            <Typography variant="code2" className={cx('Transaction')}>
              <Link
                className={cx('TransactionLink')}
                to="/:coin/explorer/transaction/:hash"
                params={{ hash: item.hash, coin: 'btc' }}
                target="_blank"
              >
                {item.hash}
              </Link>
            </Typography>
          </>
        ),
        renderFooter: renderFooter
          ? () => (
              <Typography variant="body3-sm" color="grey3">
                Totals
              </Typography>
            )
          : null,
      },
      {
        name: 'features',
        width: 0.75,
        minWidth: '140px',
        gap: 'md',
        renderTitle: () => (
          <>
            <Typography variant="body2-sm" color="grey2">
              {t('platform:features')}
            </Typography>
            <TransactionFeaturesFilter value={filters} onChange={setFilters}>
              <TableFilterButton
                active={Boolean(
                  filters.rbf || filters.segwit || filters.lt || filters.version
                )}
              />
            </TransactionFeaturesFilter>
          </>
        ),
        render: (transaction) => (
          <TransactionFeatureTags transaction={transaction} />
        ),
      },
      {
        name: 'addressFrom',
        width: 1.5,
        renderTitle: () => (
          <Typography variant="body2-sm" color="grey2">
            {t('platform:fromAddress')}
          </Typography>
        ),
        render: (props) => {
          const transaction = props as ClusterTransactionUtxo
          let inputs: Array<{ hash: string }> = []

          inputs =
            transaction.inputs?.map((input) => ({
              hash: input.address,
            })) || []

          if (!inputs?.length)
            return (
              <Typography variant="code2" className={cx('Address')}>
                {t('platform:unidentified')}
              </Typography>
            )

          return (
            <Typography variant="code2" className={cx('Address')}>
              {inputs.length > 1 ? (
                t('platform:multipleInputs')
              ) : (
                <Link
                  className={cx('AddressLink')}
                  to="/:coin/address/:hash"
                  params={{ hash: inputs[0].hash, coin: 'btc' }}
                  target="_blank"
                >
                  {inputs[0].hash}
                </Link>
              )}
            </Typography>
          )
        },
      },
      {
        name: 'addressTo',
        width: 1.5,
        renderTitle: () => (
          <Typography variant="body2-sm" color="grey2">
            {t('platform:toAddress')}
          </Typography>
        ),
        render: (props) => {
          const transaction = props as ClusterTransactionUtxo
          const outputs: Array<{ hash: string }> =
            transaction.outputs?.map((input) => ({
              hash: input.address,
            })) || []

          if (!outputs?.length) {
            return (
              <Typography variant="code2" className={cx('Address')}>
                {t('platform:unidentified')}
              </Typography>
            )
          }

          return (
            <Typography variant="code2" className={cx('Address')}>
              {outputs.length > 1 ? (
                t('platform:multipleOutputs')
              ) : (
                <Link
                  className={cx('AddressLink')}
                  to="/:coin/address/:hash"
                  params={{
                    hash: outputs[0].hash,
                    coin: 'btc',
                  }}
                  target="_blank"
                >
                  {outputs[0].hash}
                </Link>
              )}
            </Typography>
          )
        },
      },
      {
        name: 'amount',
        width: 1,
        justify: 'end',
        gap: 'md',
        renderTitle: () => (
          <>
            <Typography variant="body2-sm" color="grey2">
              {t('platform:amount')}
            </Typography>
            <TableSortButton
              name="amount"
              onChangeSort={onChangeSort}
              order={filters.sortOrder}
              sortBy={filters.sortBy}
              disabled={isLoading}
            />
            <AmountFilter
              value={{ from: filters.amountFrom, to: filters.amountTo }}
              onChange={({ from: amountFrom, to: amountTo }) =>
                setFilters({ amountFrom, amountTo })
              }
              code={filters?.includeTokens?.[0]?.symbol || 'BTC'}
            >
              <TableFilterButton
                active={Boolean(filters.amountFrom || filters.amountTo)}
              />
            </AmountFilter>
          </>
        ),
        render: ({ amount, amountUsd }) => {
          if (!amount) return

          const formatAmountConfig = getAmountConfig(amount, amountUsd)

          return (
            <Amount
              variant="body200NormalCode"
              color="onBackgroundBase"
              value={formatMoneySettings({
                ...formatAmountConfig,
                formats: GENERAL_NUMBER_NOTATION,
              })}
              fullValue={formatMoney({
                ...formatAmountConfig,
                precision: 64,
                minimumSignificantDigits: 1,
              })}
              copyValue={formatMoney({
                ...formatAmountConfig,
                precision: 64,
                code: '',
                minimumSignificantDigits: 1,
              })}
              enabledCopy
            />
          )
        },
        renderFooter: renderFooter
          ? () => {
              if (!cumulative?.volume) return

              const formatVolumeConfig = getAmountConfig(
                cumulative.volume,
                cumulative.volumeUsd
              )

              return (
                <Amount
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                  value={formatMoneySettings({
                    ...formatVolumeConfig,
                    formats: GENERAL_NUMBER_NOTATION,
                  })}
                  fullValue={formatMoney({
                    ...formatVolumeConfig,
                    precision: 64,
                    minimumSignificantDigits: 1,
                  })}
                  copyValue={formatMoney({
                    ...formatVolumeConfig,
                    precision: 64,
                    code: '',
                    minimumSignificantDigits: 1,
                  })}
                  enabledCopy
                />
              )
            }
          : null,
      },
      {
        name: 'tokens',
        width: 0.5,
        justify: 'center',
        gap: 'md',
        renderTitle: () => (
          <>
            <Typography variant="body2-sm" color="grey2">
              {t('platform:assets')}
            </Typography>
            <ProbeTokenFilter
              currency={currency}
              value={filters?.includeTokens[0]?.id}
              onChange={({ token }) => setFilters({ includeTokens: [token] })}
              getTokens={getTokens}
            />
          </>
        ),
        render: ({ tokens }) => (
          <AssetFilterPopover
            maxHeight={300}
            content={
              <AssetsListShort
                isDefaultTokenId={isDefaultTokenId}
                assets={tokens}
              />
            }
            anchor={
              <span>
                <AssetsRow
                  className={cx('AssetsRow')}
                  buttonMode
                  tokens={tokens}
                />
              </span>
            }
          />
        ),
      },
      {
        name: 'time',
        width: 1,
        gap: 'md',
        renderTitle: () => (
          <>
            <Typography variant="body2-sm" color="grey2">
              {t('platform:time')}
            </Typography>
            <TableSortButton
              name="time"
              onChangeSort={onChangeSort}
              order={filters.sortOrder}
              sortBy={filters.sortBy}
              disabled={isLoading}
            />
            <PeriodFilter
              value={[filters.from, filters.to]}
              onChange={([from, to]) => setFilters({ from, to })}
              allowedRange={allowedRange}
            >
              <TableFilterButton active={Boolean(filters.from || filters.to)} />
            </PeriodFilter>
          </>
        ),
        render: ({ time }) => (
          <Typography color="black" variant="body1-sm">
            {formatDate(fromUnixTime(time), 'date-time-numeric')}
          </Typography>
        ),
      },
    ],
    data?.map((item) => ({ ...item, key: item.hash })),
    itemsPerPage
  )

  const loading = Boolean(data && isLoading)

  return (
    <div className={cx('TransactionFlowBtcTableWrapper')}>
      <DataGrid
        type="simple"
        columns={stubbedColumns}
        data={stubbedData}
        loading={loading}
        size={'md'}
        variant={['base', 'high', 'base']}
        borders={[
          { bottom: { color: 'secondaryContainerDeep' } },
          { bottom: { color: 'secondaryContainerDeep' } },
        ]}
        spaces={['none', 'xl']}
        notFoundComponent={<EntityTableNotFound />}
      />
    </div>
  )
}
