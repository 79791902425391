import { MultisigFilterValues } from './MultisigFilter.types'
import { useTranslation } from 'react-i18next'

export const useValidateValue = () => {
  const { t } = useTranslation('platform')

  return (values: MultisigFilterValues) => {
    let error = ''

    if (values.length === 2) {
      const [first, second] = values
      if (!first || !second) {
        error = t('platform:enterNumbers')
      }

      if (+first > +second) {
        error = t('platform:firstNumberLessOrEqual')
      }

      if (+second > 15) {
        error = t('platform:valuesLimited')
      }
    } else {
      const [first] = values
      if (!first) {
        error = t('platform:enterNumber')
      }
    }

    return error
  }
}
