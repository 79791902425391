import type { EdgeAttributes } from '@clain/graph'
import { roundToPrecision } from '@clain/core/utils/math'
import type { ScoreRounded } from 'packages/core/ui-kit'

import ProbeEdge from './ProbeEdge'
import { AddressBelongsEdgeData, ClusterNodeData } from '../../types'
import { GHOSTED_OPACITY } from '../../constants'

const WIDTH = 4
const STYLE = 'solid'
const SOURCE_TYPE = 'arc'
const TARGET_TYPE = 'arc'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const LABEL_COLOR_HIGHLIGHTED = 'rgba(255, 255, 255, 1)'
const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'

export class AddressBelongsProbeEdge<
  T extends AddressBelongsEdgeData = AddressBelongsEdgeData,
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const score = (this.targetAttributes.data as ClusterNodeData)?.score

    const scoreBackgroundColor =
      score != null
        ? this.theme.getToken([
            'score',
            'tag',
            `score${roundToPrecision(score) as ScoreRounded}`,
            'background',
            'color',
          ])
        : this.theme.getToken([
            'node',
            'transaction',
            'address',
            'border',
            'color',
          ])

    const attributes = {
      width: WIDTH,
      increaseHitArea: 10,
      color: scoreBackgroundColor,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      style: STYLE,
      sourceType: SOURCE_TYPE,
      targetType: TARGET_TYPE,
    } as EdgeAttributes<T>

    if (this.highlighted) {
      attributes.color = COLOR_HIGHLIGHTED

      if (attributes.label) {
        attributes.label.color = LABEL_COLOR_HIGHLIGHTED
        attributes.label.fill = LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      }
    }

    return attributes
  }
}
