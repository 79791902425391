import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  reaction,
} from 'mobx'
import { ActiveEntityType } from '../../../components/ProbeSandbox/vm/active-entity/ActiveEntityViewModel.types'
import { getOneYearZoomValue } from '../AnalyticsViewModel.utils'
import { FilterData } from './SenkeyDataViewModel.types'
import {
  Token,
  TokenBalance,
} from '../../../components/ProbeSandbox/types/converted/TokenBalance'
import { CoinType, isUTXO } from '@clain/core/types/coin'
import { equals } from 'ramda'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { SenkeyDataViewModel } from './SenkeyDataViewModel'
import {
  AddressFormatted,
  Cluster,
} from '../../../components/Chart/Chart.types'
import { EXCLUDE_FILTERS_CURRENCY } from './SenkeyChartViewModel.constants'
import {
  DEFAULT_USD_TOKEN,
  defaultTokenByCurrency,
} from '../../../components/ProbeSandbox/utils/convertTokenBalances'
import { convertToUnixTimestamp } from '@clain/core/utils/date'

const commonData = new StateViewModel<{
  clusterOrAddressId: number
  entityType: Omit<ActiveEntityType, 'cluster' | 'address'>
  blockchain: CoinType
  tokens: TokenBalance['token'][]
  statsData: Cluster | AddressFormatted
  timezone: string
}>({
  clusterOrAddressId: null,
  entityType: null,
  blockchain: null,
  tokens: [],
  statsData: {},
  timezone: undefined,
})

const formattedParamsData = new StateViewModel<{
  includeTokens: string
  convertTo: string
}>({
  includeTokens: null,
  convertTo: null,
})

const filtersData = new StateViewModel<FilterData & { asset: Token }>({
  score: [1, 10],
  calendar: [null, null],
  period: [null, null],
  asset: {} as Token,
  convertTo: null,
  includeTokens: null,
})

export class SenkeyChartViewModel {
  private reactionDisposers: Array<IReactionDisposer> = []
  private commonData = commonData
  private senkeyDataViewModel = new SenkeyDataViewModel()

  public formattedParamsData = formattedParamsData
  public filtersData = filtersData

  @computed
  private get assetByIncludeTokens() {
    const blockchain = this.commonData.state.blockchain
    const includeTokens = this.formattedParamsData.state.includeTokens

    switch (includeTokens) {
      case null:
      case undefined: {
        return isUTXO(blockchain)
          ? defaultTokenByCurrency[blockchain].token
          : DEFAULT_USD_TOKEN.token
      }

      case 'all': {
        return DEFAULT_USD_TOKEN.token
      }
      case '0': {
        return defaultTokenByCurrency[blockchain].token
      }
      default: {
        const asset = this.assets.find(
          (asset) => asset.id.toString() === includeTokens
        )
        return asset
      }
    }
  }

  @action
  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          id: this.assetFilter.id,
          tokenId: this.assetFilter.id,
          symbol: this.assetFilter.symbol,
          blockchain: this.commonData.state.blockchain,
          convertToFilter: this.convertToFilter,
        }),
        ({ id, tokenId, blockchain, convertToFilter }) => {
          const assetIsBaseCurrency =
            defaultTokenByCurrency?.[blockchain]?.token?.id === tokenId
          const includeTokens = assetIsBaseCurrency
            ? '0'
            : !id
              ? 'all'
              : String(tokenId)
          const convertTo =
            convertToFilter === 'usd' || includeTokens === 'all'
              ? 'usd'
              : includeTokens

          this.formattedParamsData.updateState({
            includeTokens,
            convertTo,
          })
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          calendarFilter: this.calendarFilter,
          scoreFilter: this.scoreFilter,
          currency: this.commonData.state.blockchain,
          includeTokens: this.formattedParamsData.state.includeTokens,
          convertTo: this.formattedParamsData.state.convertTo,
          timezone: this.commonData.state.timezone,
        }),
        ({
          calendarFilter,
          scoreFilter,
          currency,
          includeTokens,
          convertTo,
          timezone,
        }) => {
          const queryParams = {
            timestamp_from: convertToUnixTimestamp(
              calendarFilter?.[0],
              timezone
            ),
            timestamp_to: convertToUnixTimestamp(calendarFilter?.[1], timezone),
            score_min: scoreFilter?.[0],
            score_max: scoreFilter?.[1],
            currency,
            include_tokens: includeTokens,
            convert_to: convertTo,
          }

          if (
            !equals(
              this.senkeyDataViewModel.apiParams?.queryParams,
              queryParams
            )
          ) {
            this.senkeyDataViewModel.updateApiParams({ queryParams })
          }
        }
      )
    )
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (
    clusterOrAddressId: number,
    entityType: Omit<ActiveEntityType, 'cluster' | 'address'>,
    blockchain: CoinType,
    tokens: Token[] = [],
    statsData: Cluster | AddressFormatted,
    initialFilters: FilterData,
    defaultFilters: FilterData,
    timezone: string
  ) => {
    this.commonData.initState({
      clusterOrAddressId,
      blockchain,
      entityType,
      tokens,
      statsData,
      timezone,
    })

    const defaultZoom = getOneYearZoomValue(timezone)

    this.setupFormattedParamsAndFilters(defaultFilters, defaultZoom, 'default')
    this.setupFormattedParamsAndFilters(initialFilters, defaultZoom, 'initial')

    this.senkeyDataViewModel.init({
      entityId: clusterOrAddressId,
      entityType: entityType === 'cluster' ? 'entities' : 'address',
      currency: blockchain,
      queryParams: this.createQueryParams(initialFilters, blockchain),
    })
  }

  @action
  private setupFormattedParamsAndFilters = (
    filters: FilterData,
    defaultZoom: ReturnType<typeof getOneYearZoomValue>,
    type: 'initial' | 'default'
  ) => {
    let includeTokens = filters.includeTokens
    let convertTo = filters.convertTo

    if (type === 'initial') {
      if (filters.includeTokens == null) {
        includeTokens = isUTXO(this.commonData.state.blockchain) ? '0' : 'all'
      }
      if (filters.convertTo == null || filters.includeTokens == null) {
        convertTo = isUTXO(this.commonData.state.blockchain) ? '0' : 'usd'
      } else {
        convertTo = filters.convertTo === 'usd' ? 'usd' : filters.includeTokens
      }
    }

    if (type === 'default') {
      includeTokens = isUTXO(this.commonData.state.blockchain) ? '0' : 'all'
      convertTo = isUTXO(this.commonData.state.blockchain) ? '0' : 'usd'
    }

    const staticParams = {
      ...filters,
      includeTokens,
      convertTo,
      calendar: filters.calendar || [defaultZoom.start, defaultZoom.end],
      period: filters.period || [defaultZoom.start, defaultZoom.end],
    }

    if (type === 'default') {
      this.formattedParamsData.initState({ includeTokens, convertTo })
      const params = {
        ...staticParams,
        asset: this.assetByIncludeTokens,
      }
      this.filtersData.initState(params)
    } else {
      this.formattedParamsData.updateState({ includeTokens, convertTo })
      const params = {
        ...staticParams,
        asset: this.assetByIncludeTokens,
      }
      this.filtersData.updateState(params)
    }
  }

  private createQueryParams = (filters: FilterData, blockchain: CoinType) => {
    const { calendar, score } = filters
    return {
      timestamp_from: convertToUnixTimestamp(
        calendar?.[0],
        this.commonData.state.timezone
      ),
      timestamp_to: convertToUnixTimestamp(
        calendar?.[1],
        this.commonData.state.timezone
      ),
      convert_to: this.formattedParamsData.state.convertTo,
      include_tokens: this.formattedParamsData.state.includeTokens,
      score_min: score?.[0],
      score_max: score?.[1],
      currency: blockchain,
    }
  }

  @computed
  public get excludeFilters() {
    return EXCLUDE_FILTERS_CURRENCY[this.commonData.state.blockchain]
  }

  @computed
  public get statsData() {
    return this.commonData.state.statsData
  }

  @computed
  public get entityType() {
    return this.commonData.state.entityType
  }

  @computed
  public get blockchain() {
    return this.commonData.state.blockchain
  }

  @computed.struct
  public get formatOptions() {
    const decimals =
      this.convertToFilter === 'usd' ? 0 : this.assetFilter.decimals

    return {
      currency:
        this.convertToFilter === 'usd'
          ? this.convertToFilter
          : this.assetFilter?.symbol?.toLowerCase() || '',
      decimals,
      precision: 4,
    }
  }
  @computed
  public get period() {
    return this.filtersData.state.period
  }
  @computed
  public get calendarFilter() {
    return this.filtersData.state.calendar
  }
  @computed
  public get scoreFilter() {
    return this.filtersData.state.score
  }
  @computed
  public get convertToFilter() {
    return this.filtersData.state.convertTo
  }

  @computed
  public get includeTokensFilter() {
    return this.filtersData.state.includeTokens
  }

  @computed
  public get assetFilter() {
    return this.filtersData.state?.asset || ({} as Token)
  }

  @computed
  public get updateFilters() {
    return this.filtersData.updateState
  }

  @computed
  public get assets() {
    return this.commonData.state.tokens
  }

  @action
  public fetchData = () => {
    this.senkeyDataViewModel.fetchData()
    this.initReactions()
  }

  @computed
  public get seriesData() {
    return this.senkeyDataViewModel.data
  }

  @computed
  public get isFiltersChanged() {
    return !equals(this.filtersData.state, this.filtersData.initialState)
  }

  @computed
  public get isLoading() {
    return this.senkeyDataViewModel.status === 'LOADING'
  }

  @computed
  public get isSuccess() {
    return this.senkeyDataViewModel.status === 'SUCCESS'
  }

  @computed
  public get notFound() {
    return (
      !this.seriesData?.edges?.length &&
      this.senkeyDataViewModel.status === 'SUCCESS'
    )
  }

  @action
  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.commonData.clearState()
    this.filtersData.clearState()
    this.formattedParamsData.clearState()
    this.senkeyDataViewModel.clear()
  }

  @action
  public resetFilters = () => {
    this.filtersData.resetState()
    this.formattedParamsData.resetState()
  }
}
