import React from 'react'
import {
  AssetCard,
  PortfolioItemInfo,
  PortfolioItemInfoProps,
} from '@clain/core/ui-kit'
import {
  formatAmount,
  formatAmountShort,
  formatUsd,
} from './PortfolioAssets.utils'
import * as S from './PortfolioAssets.styles'
import { PortfolioAssetProps } from './PortfolioAssets.types'
import { PopoverBaseContainer } from '@clainio/web-platform'
import { BlockchainType } from '../../../types/coin'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const PortfolioAssetComponent: React.FC<PortfolioAssetProps> = ({
  asset,
  handleTokenFilter,
}) => {
  const decimals = Math.pow(10, asset.token.decimals)
  const assetData: PortfolioItemInfoProps = {
    color: isDefaultTokenId(asset?.id) ? 'original' : 'grey',
    assetName: asset.token.name,
    ticker: asset.token.symbol,
    blockchain: asset.token.blockchain.toUpperCase() as BlockchainType,
    balance: formatAmount(asset.balance / decimals),
    balanceShort: formatAmountShort(asset.balance / decimals),
    balanceUsd: formatAmount(asset.balanceUsd),
    balanceUsdShort: formatUsd(asset.balanceUsd),
    sent: formatAmount(asset.sent / decimals),
    sentShort: formatAmountShort(asset.sent / decimals),
    sentUsd: formatAmount(asset.sentUsd),
    sentUsdShort: formatUsd(asset.sentUsd),
    received: formatAmount(asset.received / decimals),
    receivedShort: formatAmountShort(asset.received / decimals),
    receivedUsd: formatAmount(asset.receivedUsd),
    receivedUsdShort: formatUsd(asset.receivedUsd),
    tokenIconUrl: asset.token.icon,
  }

  const variant = asset.token?.scam ? 'scam' : 'spam'
  const isScamSpam = asset.token?.scam || asset.token?.spam

  return (
    <S.AssetWrapper>
      <PopoverBaseContainer
        content={
          <PortfolioItemInfo
            applyFilterHandler={() => handleTokenFilter(asset.token)}
            {...assetData}
          />
        }
        anchor={
          <S.AssetCardWrapper>
            <AssetCard
              type={'asset'}
              variant={isScamSpam ? variant : 'licit'}
              color={isDefaultTokenId(asset?.id) ? 'original' : 'grey'}
              icon={assetData.tokenIconUrl}
              blockchain={assetData.blockchain}
              mainText={`${assetData.balanceShort} ${assetData.ticker || ''}`}
              secondaryText={`${assetData.balanceUsdShort}`}
            />
          </S.AssetCardWrapper>
        }
      />
    </S.AssetWrapper>
  )
}

export const PortfolioAsset = React.memo<PortfolioAssetProps>(
  PortfolioAssetComponent,
  (prevProps, nextProps) => prevProps.asset.id === nextProps.asset.id
)
