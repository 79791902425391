import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDirection } from '../../../../../../modules'

export const useAlertOptions = () => {
  const { t } = useTranslation()

  const triggerFrequencyOptions = useMemo(
    () => [
      {
        value: 'once',
        label: t('platform:once'),
      },
      {
        value: 'everyTxn',
        label: t('platform:everyTransaction'),
      },
    ],
    [t]
  )

  const currencyOptions = useMemo(
    () => [
      {
        value: true,
        label: t('platform:native'),
      },
      {
        value: false,
        label: t('platform:usd'),
      },
    ],
    [t]
  )

  const directionOptions = useMemo(
    (): Array<{
      label: string
      value: AlertDirection
    }> => [
      {
        value: 'in',
        label: t('platform:incoming'),
      },
      {
        value: 'out',
        label: t('platform:outgoing'),
      },
      {
        value: 'both',
        label: t('platform:incomingOutgoing'),
      },
    ],
    [t]
  )

  return {
    triggerFrequencyOptions,
    currencyOptions,
    directionOptions,
    defaultDirection: 'both' as AlertDirection,
    defaultTrigger: 'once',
    defaultCurrency: true,
  }
}
