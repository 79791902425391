import React from 'react'
import { observer } from 'mobx-react-lite'
import { Stack, Button } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
import { IPlotOnGraph } from '../../types'

export const PlotOnGraphPanel: React.FC<IPlotOnGraph> = observer(
  ({
    disabledPlotOnGraph,
    selectUnSelectAll,
    isAllSelected,
    plotSelectedOnGraph,
  }) => {
    const { t } = useTranslation()

    return (
      <Stack align="center" gap="md">
        <Button
          variant="outline"
          color="secondary"
          endIcon="Checks"
          onClick={selectUnSelectAll}
        >
          {isAllSelected
            ? t('platform:deselectPage')
            : t('platform:selectPage')}
        </Button>
        <Button
          variant="outline"
          color="secondary"
          endIcon="GraphPlot"
          disabled={disabledPlotOnGraph}
          onClick={plotSelectedOnGraph}
        >
          {t('platform:plotSelected')}
        </Button>
      </Stack>
    )
  }
)
