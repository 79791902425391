import { useTranslation } from 'react-i18next'
import { TransferTypeFilterItem } from './TransferTypeFilter.types'

export const useTransferTypeFilter = () => {
  const { t } = useTranslation()

  const options: TransferTypeFilterItem[] = [
    {
      label: t('platform:received'),
      value: 'in',
      icon: 'FlowIn',
    },
    {
      label: t('platform:sent'),
      value: 'out',
      icon: 'FlowOut',
    },
    {
      label: t('platform:receivedAndSent'),
      value: 'both',
      icon: 'FlowInOut',
    },
  ]

  const optionsMap: Record<TransferTypeFilterItem['value'], string> = {
    in: t('platform:received'),
    out: t('platform:sent'),
    both: t('platform:receivedAndSent'),
  }

  return {
    options,
    optionsMap,
  }
}
