import { ComparatorsValue, CreateFilter } from '@clain/core/ui-kit'
import { useFilters } from '../entity/TransactionsSearch'

export interface CalendarFilterState {
  data: [Date, Date]
  condition: Extract<ComparatorsValue, 'is'>
}

import {
  ComparatorsValueFilterValues,
  MultisigFilterValues,
  SelectFilterItem,
} from '@platform/components/Filters'
import { CoinType } from '@clain/core/types/coin'
import { Token } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

export type FeatureFilterDataState = SelectFilterItem<string> | null

export type AssetFilteState = CreateFilter<
  Token[],
  Extract<ComparatorsValue, 'is'>
>

export interface ComparatorFilterState {
  data: ComparatorsValueFilterValues
  condition: ComparatorFilterConditionState
}

export interface MultisigFilterState {
  data: MultisigFilterValues
  condition: MultisigFilterConditionState
}

export type MultisigFilterConditionState = Extract<
  ComparatorsValue,
  'is' | 'isNot'
>

export interface FeatureFilterState {
  data: FeatureFilterDataState
  condition: Extract<ComparatorsValue, 'is' | 'isNot'>
}

export type ComparatorFilterConditionState = Extract<
  ComparatorsValue,
  'isGreater' | 'isLess' | 'is' | 'isBetween'
>

export type VersionFilterDataState = SelectFilterItem<1 | 2> | null

export interface VersionFilterState {
  data: VersionFilterDataState
  condition: Extract<ComparatorsValue, 'is' | 'isNot'>
}

export interface CompressionFilterState {
  data: SelectFilterItem<1 | 2> | null
  condition: Extract<ComparatorsValue, 'is' | 'isNot'>
}

export interface ScoreFilterState {
  data: [number, number]
  condition: Extract<ComparatorsValue, 'is'>
}

export interface CalendarFilterState {
  data: [Date, Date]
  condition: Extract<ComparatorsValue, 'is'>
}

export interface CurrencyFilterState {
  data: CoinType
  condition: Extract<ComparatorsValue, 'is'>
}

export enum InputOutputTypeId {
  P2PK = 0,
  P2PKH = 1,
  P2MS = 2,
  P2SH = 3,
  P2WSH = 6,
  P2WKH = 7,
  P2WUNKN = 8,
  P2TR = 9,
}

export type FilterStates = ReturnType<typeof useFilters>

export type FilterStatesComparators = {
  [K in keyof FilterStates as FilterStates[K] extends ComparatorFilterState
    ? K
    : never]: FilterStates[K]
}
export type ComparatorFilterKeys = keyof FilterStatesComparators

export type FilterStatesMultisig = {
  [K in keyof FilterStates as FilterStates[K] extends MultisigFilterState
    ? K
    : never]: FilterStates[K]
}
export type MultisigFilterKeys = keyof FilterStatesMultisig

export type FilterStatesByFilter<T> = {
  [K in keyof FilterStates as FilterStates[K] extends T
    ? K
    : never]: FilterStates[K]
}
