import { action, computed, makeObservable, observable } from 'mobx'
import { NodesPosition } from '../../types/NodesPosition'
import { Position } from '../../types/Position'
import { IProbeState, ProbeNodes, probeState } from '../ProbeState'
import { INodesPositionController } from './NodesPositionController.types'

class NodesPositionController implements INodesPositionController {
  @observable private _nodesPositionStartDrag: Record<string, Position> = {}
  @observable private _nodesPositionEndDrag: Record<string, Position> = {}

  constructor(private probeState: IProbeState) {
    makeObservable(this)
  }

  @action.bound
  private _setNodesPositionsStartDrag = (nodesPosition: NodesPosition) => {
    this._nodesPositionStartDrag = nodesPosition
  }

  @action.bound
  private _setNodesPositionsEndDrag = (nodesPosition: NodesPosition) => {
    this._nodesPositionEndDrag = nodesPosition
  }

  private normalizeNodesPosition = (nodes: ProbeNodes[]): NodesPosition => {
    return nodes.reduce(
      (acc, node) => ({
        ...acc,
        [node.key]: node.position,
      }),
      {}
    )
  }

  @action.bound
  private selectedNodePosition = (key: string): NodesPosition => {
    const node = this.probeState.nodes.get(key)

    const nodes =
      this.probeState.selectedNodeIds.has(key) &&
      this.probeState.selectedNodeIds.size
        ? Array.from(this.probeState.selectedNodeIds.values())
            .map((key) => this.probeState.nodes.get(key))
            .filter((node) => !!node)
        : this.probeState.nodes.has(key)
          ? [node]
          : []

    return this.normalizeNodesPosition(nodes)
  }

  @action.bound
  public setNodePositionStartDrag = (key: string): NodesPosition => {
    const nodesPosition = this.selectedNodePosition(key)
    this._setNodesPositionsStartDrag(nodesPosition)

    return nodesPosition
  }

  public setNodePositionEndDrag = (key: string): NodesPosition => {
    const nodesPosition = this.selectedNodePosition(key)
    this._setNodesPositionsEndDrag(nodesPosition)

    return nodesPosition
  }

  public getNodePositionStartDrag = (key: string): Position | undefined => {
    return this._nodesPositionStartDrag[key]
  }

  @computed
  get nodesPositionStartDrag() {
    return this._nodesPositionStartDrag
  }

  @computed
  get nodesPositionEndDrag() {
    return this._nodesPositionEndDrag
  }
}

export const nodesPositionController = new NodesPositionController(probeState)
