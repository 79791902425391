import styled, { css } from 'styled-components'
import {
  createTokenKey,
  RowDeprecated,
  selectToken,
} from '@clainio/web-platform'
import { Foggy } from '@clain/core/ui-kit'

export const RowAddressItemBase = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 8px;
  margin: 0 -8px;
`

export const FoggyStyled = styled(Foggy)`
  min-width: 60px;
`

export const RowAddressItemStyled = styled(RowAddressItemBase)`
  ${({ theme }) => css`
    height: 32px;

    &:hover {
      background-color: ${selectToken(
        theme,
        createTokenKey(['color', 'secondary', 'container', 'base'])
      )};
    }
  `};
`

export const FlexGrowContainer = styled.div<{
  $flexGrow?: number
  $isMinWidth?: boolean
}>`
  align-self: flex-start;
  flex-basis: 0;
  flex-grow: ${({ $flexGrow = 1 }) => $flexGrow};
  min-width: ${({ $isMinWidth = true }) => ($isMinWidth ? 0 : 'auto')};
`

export const FlexGrowArrowContainer = styled(FlexGrowContainer)`
  display: flex;
  justify-content: center;
  align-self: center;
`

export const AddressNameContainerStyled = styled(RowDeprecated)`
  position: relative;
  display: flex;
  gap: 4px;
  flex: 1;
  min-width: 0;
  display: inline-flex;
`

export const AddressLabelWrapperStyled = styled.div<{
  highlighted?: boolean
  widthPercent?: number
  maxWidth?: number
}>`
  width: ${({ widthPercent = 67 }) => `${widthPercent}%`};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  position: relative;
  z-index: 1;
  display: inline-flex;
  background-color: ${({ theme, highlighted = false }) =>
    highlighted
      ? selectToken(theme, createTokenKey(['yellow200']))
      : 'transparent'};
`
export const ClusterLabelWrapperStyled = styled.div<{
  widthPercent?: number
}>`
  width: ${({ widthPercent = 33 }) => `${widthPercent}%`};
  position: relative;
  z-index: 1;
  display: inline-flex;
`
export const ScoreWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  width: 34px;
`
