import React, { PropsWithChildren } from 'react'
import { MagicGrid, LoaderOverlay } from '@clain/core/ui-kit'
import { ContentLayoutProps } from './ContentLayout.types'

export const ContentLayout: React.FC<PropsWithChildren<ContentLayoutProps>> = ({
  loading,
  topComponent,
  bottomComponent,
  filtersComponent,
  children,
}) => {
  return (
    <MagicGrid gap={2}>
      {filtersComponent}
      <MagicGrid gap={0.5}>
        {topComponent}
        <LoaderOverlay
          as={(props) => <MagicGrid {...props} gap={0.5} />}
          loading={loading}
        >
          {children}
        </LoaderOverlay>
        {bottomComponent}
      </MagicGrid>
    </MagicGrid>
  )
}
