import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { selectByKeys } from '@clain/core/utils'
import {
  CreateAlertModalProps,
  CreateAlertForm,
  CreateAlertFormKeys,
} from '../CreateAlertModal.types'

export const useAlertCurrencyText = () => {
  const { t } = useTranslation()

  const getCurrencyText = useCallback(
    (
      props: CreateAlertModalProps,
      values: CreateAlertForm
    ): string | undefined => {
      if (selectByKeys(CreateAlertFormKeys.NATIVE, values)) {
        return props.type === 'probe'
          ? values?.asset?.token?.symbol || props.currency
          : values?.asset?.token?.symbol || values?.item?.currency
      }

      return t('platform:usd')
    },
    [t]
  )

  return {
    getCurrencyText,
  }
}
