import React from 'react'
import classnames from 'classnames/bind'
import { KeyValue } from '@clain/core/ui-kit'
import { Badge } from '@clain/core/ui-kit'
import { useFormatDate } from '../../hooks'
import { formatNumber, useFormatMoney } from '@clain/core/utils/format'
import { useTranslation } from 'react-i18next'

import styles from './index.scss'
import { CoinTypeEVM } from '../../types/coin'
import { RawBlockTransactionEVM } from '../../apiServices'
import { observer } from 'mobx-react-lite'
import { fromUnixTime } from 'date-fns'

interface TransactionDetailsEthProps {
  transaction: RawBlockTransactionEVM
  currency: 'src' | 'usd'
  loading: boolean
  coinType: CoinTypeEVM
}

const cx = classnames.bind(styles)

const TransactionDetailsEvm = observer(
  ({
    transaction,
    currency,
    loading,
    coinType = 'eth',
  }: TransactionDetailsEthProps) => {
    const { t } = useTranslation()
  const formatMoney = useFormatMoney()
    const formatDate = useFormatDate()

    return (
      <KeyValue
        className={cx('transactionTable')}
        data={transaction}
        loading={loading}
      >
        {[
          {
            key: t('platform:status'),
            value: ({ status }) =>
              status && <Badge variant="success">{t('platform:success')}</Badge>,
          },
          {
            key: t('platform:block'),
            value: ({ height }) => formatNumber(height, 0),
          },
          {
            key: t('platform:confirmations'),
            value: ({ confirmations }) => formatNumber(confirmations, 0),
          },
          {
            key: t('platform:time'),
            value: ({ time }) => formatDate(fromUnixTime(time)),
          },
          {
            key: t('platform:value'),
            value: ({ amount_usd, amount }) =>
              formatMoney({
                value: currency === 'usd' ? amount_usd : amount,
                currency: currency === 'src' ? coinType : currency,
              }),
          },
          {
            key: t('platform:fee'),
            value: ({ fee_usd, fee }) =>
              formatMoney({
                value: currency === 'usd' ? fee_usd : fee,
                currency: currency === 'src' ? coinType : currency,
              }),
          },
        ]}
      </KeyValue>
    )
  }
)

export default TransactionDetailsEvm
