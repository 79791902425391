import { Menu } from '@clain/core/ui-kit'
import styled from 'styled-components'

export const MenuContainer = styled.div<{ isCentered?: boolean }>`
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  align-items: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
`

export const MenuContent = styled(Menu)`
  width: 100%;
`
