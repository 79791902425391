import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { formatMoney } from '@clain/core/utils/format'
import { getRealCoinValue } from '@clain/core/utils/currency'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { stubColor } from '@clain/core/Chart2/mock.utils'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { NetflowTooltip } from './NetflowTooltip'
import { NetflowOptions } from './netflow.types'

export const useChartOptions = () => {
  const { t } = useTranslation()

  return useCallback(
    ({
      min,
      max,
      groupBy,
      data,
      formatOptions,
      stub = false,
      formatDate,
      useNewColors = false,
    }: NetflowOptions & { stub?: boolean }) => {
      const currencyName = formatOptions?.currency?.toUpperCase()

      return {
        xAxis: [
          {
            type: 'time',
            show: !stub,
          },
        ],
        yAxis: [
          {
            name: t('platform:balance') + `, ${currencyName}`,
            type: 'value',
            axisLabel: {
              formatter: (value) =>
                formatMoney({
                  ...formatOptions,
                  value,
                  precision: 0,
                  ...(Math.abs(getRealCoinValue(currencyName, value)) < 10
                    ? { minimumSignificantDigits: 1 }
                    : {}),
                  code: '',
                }),
            },
            show: !stub,
            nameTextStyle: {
              align: 'left',
            },
          },
          {
            name: t('platform:netflow') + `, ${currencyName}`,
            type: 'value',
            axisLabel: {
              formatter: (value) =>
                formatMoney({
                  ...formatOptions,
                  value: value,
                  precision: 0,
                  ...(Math.abs(getRealCoinValue(currencyName, value)) < 10
                    ? { minimumSignificantDigits: 1 }
                    : {}),
                  code: '',
                }),
            },
            show: !stub,
            nameTextStyle: {
              align: 'right',
            },
          },
        ],
        grid: {
          top: 50,
          left: 20,
          right: 25,
          bottom: 50,
          containLabel: true,
        },
        tooltip: {
          appendToBody: false,
          transitionDuration: 0,
          trigger: 'axis',
          confine: true,
          padding: 0,
          formatter: createTooltipFormatter(
            (points) => ({ points }),
            NetflowTooltip,
            { groupBy, formatOptions, formatDate }
          ),
          axisPointer: {
            type: 'cross',
            label: {
              formatter: (point) =>
                point.axisDimension === 'x'
                  ? formatDate(new Date(point?.value))
                  : formatMoney({ ...formatOptions, value: point.value }),
            },
          },
          backgroundColor: 'rgba(255, 255, 255, 1)',
          extraCssText:
            'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25); width: 300px; z-index: 0;',
          position: function (pos, params, el, elRect, size) {
            const obj = { top: 10 }
            obj[['left', 'right'][+(pos[0] < size?.viewSize[0] / 2)]] = 120
            return obj
          },
        },
        axisPointer: {
          link: { xAxisIndex: 'all' },
          label: {
            backgroundColor: '#777',
          },
        },
        dataZoom: !stub
          ? [
              {
                type: 'slider',
                show: true,
                brushSelect: false,
                zoomLock: false,
                xAxisIndex: [0],
                startValue: min,
                endValue: max,
                showDetail: false,
                realtime: false,
              },
            ]
          : null,
        series: [
          {
            name: t('platform:balance'),
            data:
              data?.balances.map(([time, value]) => ({
                value: [time, value],
              })) || [],
            type: 'line',
            yAxisIndex: 0,
            showSymbol: false,
            itemStyle: {
              color: '#2173FF',
            },
            lineStyle: {
              color: '#2173FF',
              width: 1,
            },
          },
          ...Object.entries(data?.incoming || []).map(([name, group]) => ({
            name,
            data: group.map(([x, y, score]) => ({
              value: [x, y, score],
              itemStyle: {
                color: !stub ? getScoreColor(score, useNewColors) : stubColor,
              },
            })),
            type: 'bar',
            barMaxWidth: 10,
            stack: '1',
            yAxisIndex: 1,
          })),
          ...Object.entries(data?.outgoing || []).map(([name, group]) => ({
            name,
            data: group.map(([x, y, score]) => ({
              value: [x, -y, score],
              itemStyle: {
                color: !stub ? getScoreColor(score, useNewColors) : stubColor,
              },
            })),
            type: 'bar',
            barMaxWidth: 10,
            stack: '1',
            yAxisIndex: 1,
          })),
        ],
      }
    },
    [t]
  )
}
