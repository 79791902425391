import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssetFilterData, useSelectedAssetData } from './useAssetFilterData'
import { AssetFilter, AssetFilterMenu } from '../../../../../Filters'
import { useTransactionStaticStore } from '../../context'

export const AssetFilterMenuContainer = () => {
  const { t } = useTranslation()
  const {
    selectedTokens,
    menuListData,
    setFilter,
    searchLoading,
    disabledInnerSearch,
  } = useAssetFilterData()
  const transactionStaticStore = useTransactionStaticStore()

  const onSelect = useCallback((tokenIds: typeof selectedTokens) => {
    setFilter(tokenIds)
  }, [])

  return (
    <AssetFilterMenu
      onSelect={onSelect}
      selectedTokens={selectedTokens}
      tokenList={menuListData}
      size={transactionStaticStore.current.filterSize}
      onSearch={transactionStaticStore.current.onSearchAsset}
      loading={searchLoading}
      disabledStaticSearch={disabledInnerSearch}
      searchInfo={t('platform:findAssetInfo')}
    />
  )
}

export const AssetFilterContainer = () => {
  const { isMultiple, multipleLabel, resetFilter } = useAssetFilterData()
  const firstSelectedToken = useSelectedAssetData()
  const transactionStaticStore = useTransactionStaticStore()

  return (
    <AssetFilter
      isMultiple={isMultiple}
      multipleLabel={multipleLabel}
      firstSelectedToken={firstSelectedToken}
      size={transactionStaticStore.current.filterSize}
      clearFilter={resetFilter}
      menuComponent={<AssetFilterMenuContainer />}
    />
  )
}
