import React from 'react'
import { MagicGrid } from '@clain/core/ui-kit'
import NotFound from '@clain/core/NotFound'
import { AddressDetails } from './components/AddressDetails'
import { AddressCounterparties } from './components/AddressCounterparties'
import { AddressEntitiesTable } from './components/AddressEntitiesTable'
import { CoinType } from '../../types/coin'
import { LoaderOverlay } from '@clain/core/ui-kit'
import { useHeaderSlot } from './hooks/useHeaderSlot'
import { observer } from 'mobx-react-lite'
import { addressPageViewModel } from '../EntityPage/AddressViewModels'
import { AddressExposure } from './components/AddressExposure'
import { dataStatsViewModel } from '../EntityPage/AddressViewModels'
import { AddressFundsFlow } from './components/AddressFundsFlow'
import useScrollTo from '../../hooks/useScrollTo'
import { AddressPortfolio } from './components/AddressPortfolio'
import { useParams } from '@clain/core/Router/router'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import { useTimezone } from '@platform/hooks'

export const Address = observer(() => {
  const { hash, coin } = useParams<{ hash: string; coin: CoinType }>()
  const timezone = useTimezone()
  useHeaderSlot({ id: hash, coin })

  const { init, clear, pageNotFound, isInitialized } = addressPageViewModel

  const [transactionTableRef, scrollToTransactionTable] =
    useScrollTo<HTMLDivElement>()

  const { isLoading } = dataStatsViewModel

  React.useEffect(() => {
    if (timezone) {
      init(coin, hash, timezone)
      return () => {
        clear()
      }
    }
  }, [hash, coin, timezone])

  if (!isInitialized) {
    return null
  }

  if (pageNotFound) {
    return <NotFound />
  }

  return (
    <LoaderOverlay loading={isLoading}>
      <ErrorBoundaryContainer>
        <MagicGrid>
          <AddressDetails />
          <AddressPortfolio
            scrollToTransactionTable={scrollToTransactionTable}
          />
          <AddressExposure />
          <AddressFundsFlow />
          <AddressCounterparties />
          <AddressEntitiesTable ref={transactionTableRef} />
        </MagicGrid>
      </ErrorBoundaryContainer>
    </LoaderOverlay>
  )
})
