import React from 'react'
import { AlertCaution, Button } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Report } from '../Report'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames/bind'
import styles from './GeneratedReportStep.scss'
import {
  FeedBackProvider,
  Typography,
  FeedBackActions,
  FeedBackButton,
  FeedBackButtonGroup,
  FeedBackTextArea,
} from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import reportFacade from '../../ProbeGenerateReport.facade'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

export const GeneratedReportStep = observer(() => {
  const { t } = useTranslation()

  const renderFeedbackComponent = () => {
    return !reportFacade.isFeedbackCollapsed ? (
      <div
        className={cx('FeedbackWrapper', {
          isCollapsing: reportFacade.isFeedbackCollapsing,
        })}
      >
        <FeedBackProvider
          status={reportFacade.feedbackStatus}
          onSubmit={reportFacade.submitFeedback}
          validationRule={(formState) => {
            return (
              formState.quality != null ||
              (formState.text != '' && formState.text != null)
            )
          }}
        >
          <ColDeprecated gap={1}>
            <Typography variant={'body1'} color={'black'}>
              {t('platform:aiReportFeedbackRequest')}
            </Typography>
            <RowDeprecated gap={1} className={cx('FeedbackContent')}>
              <FeedBackButtonGroup name="quality">
                <FeedBackButton
                  isFullWidth
                  title={t('platform:feedbackBad')}
                  value="bad"
                  variant="bad"
                />
                <FeedBackButton
                  isFullWidth
                  title={t('platform:feedbackFair')}
                  value="fair"
                  variant="fair"
                />
                <FeedBackButton
                  isFullWidth
                  title={t('platform:feedbackGood')}
                  value="good"
                  variant="good"
                />
              </FeedBackButtonGroup>
              <FeedBackTextArea
                name="text"
                fullWidth
                variant="outline"
                rows={1}
                placeholder={t('platform:feedbackPlaceholder')}
              />
              <FeedBackActions>
                {({ isValid }) => (
                  <RowDeprecated align={'right'}>
                    <Button
                      type="submit"
                      variant="outline"
                      color="secondary"
                      size={'lg'}
                      disabled={!isValid}
                    >
                      {t('platform:submit')}
                    </Button>
                  </RowDeprecated>
                )}
              </FeedBackActions>
            </RowDeprecated>
          </ColDeprecated>
        </FeedBackProvider>
      </div>
    ) : null
  }

  const renderErrorContent = () => {
    switch (reportFacade.generatingReportError.error) {
      case 'FromServer': {
        return (
          <AlertCaution variant="error" className={cx('AlertWrapper')}>
            {reportFacade.generatingReportError.message}
          </AlertCaution>
        )
      }
      default:
      case 'Generating': {
        return (
          <AlertCaution variant="error" className={cx('AlertWrapper')}>
            {t('platform:tokenLimitError')}
          </AlertCaution>
        )
      }
    }
  }

  return (
    <ColDeprecated
      gap={reportFacade.generatingReportError ? 1 : 2}
      className={cx('ReportWrapper', {
        isCollapsed: reportFacade.isFeedbackCollapsed,
      })}
    >
      <Report isReportGenerating={false} />
      {reportFacade.generatingReportError
        ? renderErrorContent()
        : renderFeedbackComponent()}
    </ColDeprecated>
  )
})
