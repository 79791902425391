import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { COINNAMES, getCoinCode } from '../currency'

interface RedirectProps {
  from: string
  to: string
  preserveQuery?: boolean
}

const Redirect: React.FC<RedirectProps> = ({ to, preserveQuery }) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(to + (preserveQuery ? location.search : ''), {
      replace: true,
      state: location.state,
    })
  }, [to, navigate, location, preserveQuery])

  return null
}

export const generateRedirectRoutes = (redirects: RedirectProps[]) => {
  return redirects.flatMap(({ from, to, preserveQuery }) => {
    return COINNAMES.map((coin) => {
      const fromPath = from.replace(':currencyKey', coin.toLowerCase())
      const toPath = to.replace(':coin', getCoinCode(coin).toLowerCase())

      return {
        path: fromPath,
        element: (
          <Redirect from={fromPath} to={toPath} preserveQuery={preserveQuery} />
        ),
      }
    })
  })
}
