import { injectable } from 'inversify'
import { NodeAttributes } from '@clain/graph'

import ProbeNode from './ProbeNode'
import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'

import { CustomNodeData } from '../../types'

const CORE_RADIUS = 48
const CORE_BACKGROUND_COLOR = 'rgba(255, 255, 255, 1)'
const LABEL_ORBIT_WIDTH = 36
const LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const LABEL_COLOR = 'rgba(15, 19, 27, 1)'
const LABEL_SIZE = 12
const LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const LABEL_BORDER_RADIUS = 4
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'
const GHOSTED_OPACITY = 0.3

@injectable()
export class CustomNode<
  T extends CustomNodeData = CustomNodeData
> extends ProbeNode<T> {
  protected generateAttributes() {
    const attributes = {
      size: CORE_RADIUS,
      fill: CORE_BACKGROUND_COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      locked: this.locked === true ? true : false,
      linkType: 'master',
      shape: 'square',
      icon: icon({
        variant: this.data.type === 'client' ? 'customActor' : 'customCompany',
      }),
      iconColor: this.theme.getToken([
        'icon',
        'on',
        'background',
        'variant1',
        'color',
      ]),
      iconWidth: 42,
      iconHeight: 42,
      border: {
        color: OUTER_BORDER_COLOR,
        width: 0,
      },
      orbits: [
        {
          size: CORE_RADIUS + 2,
          border: {
            width: 0,
            color: OUTER_BORDER_COLOR,
          },
        },
        {
          size: LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
                text:
                  this.data.text?.length < 25
                    ? this.data.text
                    : this.data.text?.slice(0, 22) + '...',
                color: LABEL_COLOR,
                fontSize: LABEL_SIZE,
                fill: LABEL_BACKGROUND_COLOR,
                borderRadius: LABEL_BORDER_RADIUS,
                padding: [2, 1],
              },
            },
          ],
        },
      ],
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.orbits[0].border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    return attributes
  }
}
