import {
  BlockchainFilterContent,
  BlockchainFilterHeader,
  //BlockchainFilterTabs,
} from './containers'

export const BlockchainFilter: React.FC = () => {
  return (
    <>
      <BlockchainFilterHeader />
      {/* <BlockchainFilterTabs />*/}
      <BlockchainFilterContent />
    </>
  )
}
