export const ADDRESS_SERVICE_CONFIG = {
  COUNTERPARTIES_EVENT: 'counterparties',
  TRANSACTIONS_EVENT: 'transactions',
  OSINTS_EVENT: 'osint',
  STATS_EVENT: 'simple_stats',
  STATS_BY_ADDRESS_EVENT: 'simple_stats_by_address',
  TOKENS_EVENT: 'tokens',
}

export const UTXO_ADDRESS_SERVICE_CONFIG = ADDRESS_SERVICE_CONFIG

export const EVM_ADDRESS_SERVICE_CONFIG = {
  ...ADDRESS_SERVICE_CONFIG,
  COUNTERPARTIES_EVENT: 'address_counterparties',
}
