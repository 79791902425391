import { Token } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'
import { CoinType } from '../../../../../../types/coin'
import wsState, { Channel } from '../../../../../../utils/WebSocketWrapper'
import { GetTokenByAddressPayload } from './ExplorerService.types'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

const TIMEOUT_IN_MS_DEFAULT = 60_000

interface ExplorerServiceProps {
  TIMEOUT_IN_MS?: number
  CHANNEL_KEY: string
  TRANSACTION_EVENT: string
  TOKEN_BY_ADDRESS_EVENT: string
}

export const getChannelKeyExplorer = (key: CoinType) => `explorer:${key}`

export const EXPLORER_SERVICE_CONFIG = {
  TRANSACTION_EVENT: 'transaction',
  ADDRESS_TRANSACTIONS_EVENT: 'address_transactions',
  TOKEN_BY_ADDRESS_EVENT: 'token',
}

export class ExplorerServiceSchema {
  protected channel: Channel
  private TIMEOUT_IN_MS: ExplorerServiceProps['TIMEOUT_IN_MS']
  private CHANNEL_KEY: ExplorerServiceProps['CHANNEL_KEY']
  private TRANSACTION_EVENT: ExplorerServiceProps['TRANSACTION_EVENT']
  private TOKEN_BY_ADDRESS_EVENT: ExplorerServiceProps['TOKEN_BY_ADDRESS_EVENT']

  constructor({
    TIMEOUT_IN_MS = TIMEOUT_IN_MS_DEFAULT,
    CHANNEL_KEY,
    TRANSACTION_EVENT,
    TOKEN_BY_ADDRESS_EVENT,
  }: ExplorerServiceProps) {
    this.TIMEOUT_IN_MS = TIMEOUT_IN_MS
    this.CHANNEL_KEY = CHANNEL_KEY
    this.TRANSACTION_EVENT = TRANSACTION_EVENT
    this.TOKEN_BY_ADDRESS_EVENT = TOKEN_BY_ADDRESS_EVENT

    this.channel = wsState.channel(this.CHANNEL_KEY)
    this.channel.join()
  }

  protected _getTransaction = <T>(hash: string): Promise<T> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(this.TRANSACTION_EVENT, { hash }, this.TIMEOUT_IN_MS)
        .then((rawTransaction: T) => {
          resolve(rawTransaction)
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }

  protected _getTokenByAddress = (
    payload: GetTokenByAddressPayload
  ): Promise<Token> => {
    return new Promise((resolve, reject) => {
      this.channel
        .push(
          this.TOKEN_BY_ADDRESS_EVENT,
          normalizeCamelToSnakeCase<GetTokenByAddressPayload>(payload),
          this.TIMEOUT_IN_MS
        )
        .then((response: Token) => {
          resolve(normalizeSnakeToCamelCase(response))
        })
        .catch((error: string) => {
          reject(error)
        })
    })
  }
}
