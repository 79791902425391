import { useEffect } from 'react'

export const useDebounceEffect = (
  callback: () => void,
  delay: number,
  deps: any[] = []
) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [...deps, delay])
}
