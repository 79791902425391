import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { IconButton } from '@clain/core/ui-kit'

import { IPaletteController } from '../../../../vm/PaletteController'
import { PaletteColors } from '../../../PaletteColors'

import styles from '../index.scss'

const cx = classnames.bind(styles)

interface TransactionToolpanelProps
  extends Pick<
    IPaletteController,
    'paintActiveEntities' | 'restoreColorActiveEntities' | 'selectedColor'
  > {
  deleteNode: () => void
  isDeleteNodeDisabled?: boolean
}

const TransactionToolpanel: React.FC<TransactionToolpanelProps> = ({
  deleteNode,
  isDeleteNodeDisabled = false,
  paintActiveEntities,
  restoreColorActiveEntities,
  selectedColor,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <IconButton size="md" iconVariant="Play" disabled />
        <PaletteColors
          value={selectedColor}
          onChange={paintActiveEntities}
          onReset={restoreColorActiveEntities}
        />
      </RowDeprecated>
      <div className={cx('Delimeter')} />
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <Tooltip
          placement="right"
          content={t(
            isDeleteNodeDisabled
              ? 'platform:deletingDisabled'
              : 'platform:removeNode'
          )}
        >
          <IconButton
            size="md"
            disabled={isDeleteNodeDisabled}
            iconVariant="Delete"
            onClick={isDeleteNodeDisabled ? null : deleteNode}
          />
        </Tooltip>
      </RowDeprecated>
    </React.Fragment>
  )
}

export default TransactionToolpanel
