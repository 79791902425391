import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'
import { useFormatDate } from '@platform/hooks'

import styles from './index.scss'
import { observer } from 'mobx-react-lite'
import { Stack, TypographyNew } from '@clainio/web-platform'

const cx = classnames.bind(styles)

interface BaseCardProps {
  date: Date
  icon: React.ElementType
  label: string
  title?: React.ReactNode
  className?: string
}

const BaseCard: React.FC<PropsWithChildren<BaseCardProps>> = ({
  date,
  icon: Icon,
  label,
  title,
  className,
  children,
}) => {
  const formatDate = useFormatDate()

  return (
    <div className={cx('BaseCard', className)}>
      <div className={cx('Heading', { withBody: children })}>
        <Stack gap={'md'} align={'center'} fullWidth={false}>
          <Icon className={cx('Icon')} />
          <TypographyNew
            variant="body100Normal"
            color="accent1Base"
            transform="capitalize"
          >
            {label}
          </TypographyNew>
        </Stack>
        {title && (
          <TypographyNew
            variant="body100Normal"
            color="onBackgroundVariant1"
            className={cx('Title')}
          >
            {title}
          </TypographyNew>
        )}
        <TypographyNew
          variant="body100Normal"
          color="onBackgroundVariant2"
          className={cx('Date')}
        >
          {formatDate(date, 'time')}
        </TypographyNew>
      </div>
      {children}
    </div>
  )
}

export default observer(BaseCard)
