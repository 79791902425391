import { injectable } from 'inversify'

import { NodeAttributes } from '@clain/graph'
import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'
import { roundToPrecision } from '@clain/core/utils/math'
import type { ScoreRounded } from 'packages/core/ui-kit'

import ProbeNode from './ProbeNode'

import { LabelSatellite } from '@clain/graph/src/types'
import {
  FORMATS_WITHOUT_DECIMALS,
  formatMoneyByType,
  formatNumberByType,
} from '@clain/core/utils/format'
import { createAlertAddon } from './ProbeNode.utils'
import { ClusterNodeData } from '../../types'

const CORE_RADIUS = 28
const CORE_BACKGROUND_COLOR = '#fff'
const INNER_ORBIT_WIDTH = 10
const INNER_ORBIT_COLOR = 'rgba(255, 255, 255, 1)'
const SCORE_ORBIT_WIDTH = 6
const LABEL_ORBIT_WIDTH = 56
const LABEL_ORBIT_LOCATION_ANGLE = Math.PI / 2
const LABEL_COLOR = 'rgba(15, 19, 27, 1)'
const LABEL_SIZE = 12
const LABEL_LINE_HEIGHT = 16
const LABEL_BACKGROUND_COLOR = 'rgba(231, 237, 249, 0.8)'
const LABEL_BORDER_RADIUS = 4
const OUTER_BORDER_WIDTH_HIGHLIGHTED = 8
const OUTER_BORDER_COLOR = 'rgba(33, 115, 255, 1)'
const SCORE_LABEL_ORBIT_SIZE = CORE_RADIUS + INNER_ORBIT_WIDTH + 2
const GHOSTED_OPACITY = 0.3
const BALANCE_SIZE_LABEL_ORBIT = 56
const BALANCE_SIZE_LABEL_ORBIT_LOCATION_ANGLE = -Math.PI / 2
const BALANCE_SIZE_LABEL_COLOR = 'rgba(75, 96, 138, 1)'

@injectable()
export class ClusterProbeNode<
  T extends ClusterNodeData = ClusterNodeData,
> extends ProbeNode<T> {
  protected generateAttributes() {
    const countryFlag = this.data.entity?.country
      ? // eslint-disable-next-line @typescript-eslint/no-require-imports
        require(
          `svg-country-flags/png100px/${this.data.entity?.country.toLowerCase()}.png`
        )
      : undefined

    const formatMoney = formatMoneyByType({
      isShort: this.letterNotation,
      formats: FORMATS_WITHOUT_DECIMALS,
    })

    const formatNumber = formatNumberByType(this.letterNotation)
    const scoreBackgroundColor =
      this.data.score != null
        ? this.theme.getToken([
            'score',
            'tag',
            `score${roundToPrecision(this.data.score) as ScoreRounded}`,
            'background',
            'color',
          ])
        : this.theme.getToken([
            'node',
            'transaction',
            'address',
            'border',
            'color',
          ])

    const scoreTextColor = this.theme.getToken([
      'score',
      'tag',
      `score${roundToPrecision(this.data.score) as ScoreRounded}`,
      'text',
      'color',
    ])

    const attributes = {
      size: CORE_RADIUS,
      fill: CORE_BACKGROUND_COLOR,
      ghosted: this.ghosted === true ? true : false,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      locked: this.locked === true ? true : false,
      shape: 'circle',
      linkType: 'master',
      icon: icon({ variant: 'categoryDefaultDetailed' }),
      iconColor: this.theme.getToken([
        'icon',
        'on',
        'background',
        'variant1',
        'color',
      ]),
      iconWidth: 52,
      iconHeight: 52,
      border: {
        color: OUTER_BORDER_COLOR,
        width: 0,
      },
      orbits: [
        {
          size: CORE_RADIUS,
          border: {
            color: INNER_ORBIT_COLOR,
            width: INNER_ORBIT_WIDTH,
            opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
          },
        },
        {
          size: CORE_RADIUS + INNER_ORBIT_WIDTH,
          border: {
            color: scoreBackgroundColor,
            width: SCORE_ORBIT_WIDTH,
            opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
          },
        },
        {
          size: SCORE_LABEL_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 0.5,
              satellite: {
                type: 'label',
              },
            },
          ],
          virtual: true,
        },
        {
          size: CORE_RADIUS + INNER_ORBIT_WIDTH + 6,
          locations: [
            {
              angle: Math.PI * 1.75,
              satellite: {
                type: 'icon',
              },
            },
          ],
          virtual: true,
        },
        {
          size: CORE_RADIUS + INNER_ORBIT_WIDTH + 3,
          locations: [
            {
              angle: Math.PI * 0.75,
              satellite: {
                type: 'icon',
                icon: icon({ variant: this.data.currency }),
                width: 20,
                height: 20,
                fill: this.layoutSettingsState.state.graphBackgroundColor,
                shape: 'circle',
                border: {
                  width: 2,
                  color: this.layoutSettingsState.state.graphBackgroundColor,
                },
              },
            },
          ],
          virtual: true,
        },
        {
          size: LABEL_ORBIT_WIDTH,
          virtual: true,
          locations: [
            {
              angle: LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
              },
            },
          ],
        },
        {
          size: BALANCE_SIZE_LABEL_ORBIT,
          virtual: true,
          locations: [
            {
              angle: BALANCE_SIZE_LABEL_ORBIT_LOCATION_ANGLE,
              satellite: {
                type: 'label',
                text: '',
                color: BALANCE_SIZE_LABEL_COLOR,
                fontSize: LABEL_SIZE,
                lineHeight: LABEL_LINE_HEIGHT,
                fill: LABEL_BACKGROUND_COLOR,
                borderRadius: LABEL_BORDER_RADIUS,
                padding: [2, 1],
              },
            },
          ],
        },
      ],
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.orbits[0].border.color = scoreBackgroundColor
      attributes.orbits[0].border.width = INNER_ORBIT_WIDTH + SCORE_ORBIT_WIDTH
      attributes.orbits[1].border.width = 0
      attributes.border.width = OUTER_BORDER_WIDTH_HIGHLIGHTED
    }

    if (this.layers.score && this.data.score) {
      attributes.orbits[2].locations[0].satellite = {
        type: 'label',
        fontSize: 10,
        color: scoreTextColor,
        text: `${this.data.score.toFixed(2)}`,
        fill: scoreBackgroundColor,
        borderRadius: 4,
        padding: [3, 1],
      }
    }

    if (this.layers.geography) {
      attributes.orbits[3].locations[0].satellite = {
        type: 'icon',
        icon: countryFlag,
        width: 28,
        height: 18.375,
      }
    }

    if (this.layers.clusterLabel) {
      attributes.orbits[5].locations[0].satellite = {
        type: 'label',
        text: String(this.data.entity?.name || this.data.clusterId),
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [2, 1],
        ...createAlertAddon({
          theme: this.theme,
          count:
            this.data?.alertCount?.seen + this.data?.alertCount?.markedSeen,
          state:
            this.data?.alertCount?.seen > 0
              ? 'triggered'
              : this.data?.alertCount?.active
                ? 'normal'
                : 'inactive',
        }),
      }
    }

    if (this.layers.balance || this.layers.size) {
      const orbitRef = attributes.orbits[6]
      const labelRef = orbitRef.locations[0].satellite as LabelSatellite

      const text = (() => {
        if (this.layers.balance) {
          let result =
            'Bal. ' +
            (this.layoutSettingsState.state.isUsdCurrency
              ? formatMoney({
                  value: this.data.balanceUsd.balance,
                  currency: 'usd',
                })
              : formatMoney({
                  value: this.data.balance.balance,
                  currency: this.data.currency,
                }))

          if (this.layers.size) {
            result += `\n Size ${formatNumber(this.data.size, 0)}`
          }

          return result
        }
        if (this.layers.size) {
          return `Size ${formatNumber(this.data.size, 0)}`
        }
      })()
      const size =
        this.layers.balance && this.layers.size
          ? BALANCE_SIZE_LABEL_ORBIT + LABEL_LINE_HEIGHT / 2
          : BALANCE_SIZE_LABEL_ORBIT

      labelRef.text = text
      orbitRef.size = size
    }

    return attributes
  }
}
