import { useFormatDate as useFormatDateCommon } from '@clain/core/utils/date'
import { useSettings } from '../useSettings'

/**
 * IMPORTANT! Use inside mobx-react-lite observer!!!
 */
export const useFormatDate = () => {
  const { userSettings } = useSettings()
  return useFormatDateCommon(userSettings?.timezone)
}
