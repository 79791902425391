import {
  convertToCase,
  convertToCases,
  convertToRawNewCase,
  convertToRawCasesFilters,
} from '../converters'
import { Cases, NewCase, Case, CasesFilters, RawCases } from '../types'
import { WSState, Channel } from '../../../utils/WebSocketWrapper'

const CHANNEL_KEY = 'cases:global'
const CREATE_CASE_EVENT = 'create'
const CASES_LIST_EVENT = 'list'
const CREATE_PROBE = 'create_probe'
const CASES_UPDATED = 'cases_updated'

interface CasesServiceInit {
  wsState: WSState
}

export class CasesService {
  private wsState: WSState
  private channel: Channel
  private updatedCasesSubscriptionId?: number

  public init({ wsState }: CasesServiceInit) {
    this.wsState = wsState
    this.channel = wsState.channel(CHANNEL_KEY)
    this.channel.join()
  }

  public create = (newCase: NewCase): Promise<Case> => {
    return this.channel
      .push(CREATE_CASE_EVENT, convertToRawNewCase(newCase))
      .then(({ case: rawCase }) => convertToCase(rawCase))
  }

  public list = (filters: CasesFilters): Promise<Cases> => {
    return this.channel
      .push(CASES_LIST_EVENT, convertToRawCasesFilters(filters))
      .then(convertToCases)
  }

  public createProbe = async (caseId: number): Promise<{ id: number }> => {
    return this.channel
      .push<
        { probe: { id: number } },
        { name: string; case_id: number }
      >(CREATE_PROBE, { name: 'Untitled Probe', case_id: caseId })
      .then(({ probe }) => probe)
  }

  public subscribeUpdatedCases = (cb: (response: Cases) => void) => {
    this.updatedCasesSubscriptionId = this.channel.subscribe(
      CASES_UPDATED,
      (response: RawCases) => {
        return cb(convertToCases(response))
      }
    )
  }

  public unsubscribeUpdatedCases = () => {
    return this.channel.unsubscribe(
      CASES_UPDATED,
      this.updatedCasesSubscriptionId
    )
  }

  public clear = async () => {
    this.unsubscribeUpdatedCases()
  }
}
