import { StateViewModel } from '../StateViewModel'
import { action, computed, makeObservable, observable } from 'mobx'
import { IApiServiceStateViewModel } from '../mobxUtils.types'

export class APIServiceStateViewModel<T, E = unknown>
  implements IApiServiceStateViewModel<T, E>
{
  private stateViewModel: StateViewModel<T>
  @observable private _isFirstLoading = false
  @observable private _status: IApiServiceStateViewModel<T, E>['status'] =
    'IDLE'
  @observable private _error: E

  constructor(state?: T) {
    makeObservable(this)
    this.stateViewModel = new StateViewModel<T>(state)
  }

  @computed
  public get state() {
    return this.stateViewModel.state
  }

  /**
   * Updates the state with the provided partial state or updater function.
   *
   * If a partial state object is provided, only the specified fields are updated,
   * and the other fields in the state remain unchanged.
   * If an updater function is provided, it receives the current state and returns
   * a new state object. Only the fields that differ from the current state will be updated.
   *
   * @param stateOrUpdater - A partial state object or a function that returns a new state.
   * @param isForceInitUpdate - If true, updates the initialState with the new values as well.
   *
   * @example
   * // Update specific fields without affecting others
   * updateState({ name: "Alice" });
   * // this.state will be { name: "Alice", age: 17 } if the previous state was { name: "John", age: 17 }
   *
   * @example
   * // Use an updater function to modify the state
   * updateState(prevState => ({ ...prevState, name: "Alice" }));
   * // this.state will be { name: "Alice", age: 17 } if the previous state was { name: "John", age: 17 }
   */
  @action
  public updateState = (
    stateOrUpdater: Partial<T> | ((prevState: T) => T),
    isForceInitUpdate = false
  ) => {
    this.stateViewModel.updateState(stateOrUpdater, isForceInitUpdate)
  }

  @computed
  public get status() {
    return this._status
  }
  @action
  public setStatus = (status: typeof this._status) => {
    this.setIsFirstLoading(this.status === 'IDLE')
    this._status = status
  }

  @computed
  public get isFirstLoading() {
    return this._isFirstLoading
  }

  @action
  public setIsFirstLoading = (isFirstLoading: typeof this._isFirstLoading) => {
    this._isFirstLoading = isFirstLoading
  }

  @computed
  public get error() {
    return this._error
  }
  @action
  public setError = (error: typeof this._error) => {
    this._error = error
  }

  @action
  public clearData = () => {
    this.setStatus('IDLE')
    this.setError(undefined)
    this.stateViewModel.clearState()
  }
}
