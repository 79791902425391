import React, { memo, ReactNode } from 'react'
import {
  getDefaultEvmTableConfig,
  TransactionEvmTableItem,
  TransactionEvmTableProps,
  TransactionsEvmExpandedItem,
} from './EvmTable'
import {
  getDefaultUtxoTableConfig,
  TransactionsUtxoTableItem,
  TransactionsUtxoTableProps,
} from './UtxoTable'
import {
  DataGrid,
  DataItemType,
  Table,
  TableBody,
  TableCellColumn,
  TableHead,
  TableRow,
  TableVirtualizer,
  useTableExpanded,
  useTableStubs,
} from '@clain/core/ui-kit'
import { useDefaultTransactionsTableConfig } from './useDefaultTransactionsTableConfig'
import classnames from 'classnames/bind'
import styles from './TransactionTable.scss'
import * as S from '../../ui'
import { EntityTableNotFound } from '../../ui'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'
import { isEVM } from '@clain/core/types'
import { ClusterTransactionEvmAggregate } from '@platform/components/ProbeSandbox/types/converted/ClusterTransactionAggregate'
import { ClusterTransactionUtxo } from '@platform/components/ProbeSandbox/types/converted/ClusterTransaction'
import { TableCellStyled, TableRowStyled } from './TransactionTable.styles'
import { TRANSACTION_ITEM_KEYS_REMOVE_BORDER } from './constants/keysRemoveBorder'

const cx = classnames.bind(styles)

export type Props = Pick<
  | TransactionsUtxoTableProps<TransactionsUtxoTableItem>
  | TransactionEvmTableProps<TransactionEvmTableItem>,
  | 'data'
  | 'itemsPerPage'
  | 'isLoading'
  | 'coinType'
  | 'virtualizerType'
  | 'currentPage'
> & {
  config: ReturnType<typeof useDefaultTransactionsTableConfig>
  filtersContent?: ReactNode
}

type EVMProps = Pick<
  TransactionEvmTableProps<TransactionEvmTableItem>,
  'data' | 'itemsPerPage' | 'isLoading' | 'virtualizerType' | 'currentPage'
> & {
  config: ReturnType<typeof getDefaultEvmTableConfig>
}

type UTXOProps = Pick<
  TransactionsUtxoTableProps<TransactionsUtxoTableItem>,
  'data' | 'itemsPerPage' | 'isLoading' | 'currentPage'
> & {
  config: ReturnType<typeof getDefaultUtxoTableConfig>
}

const TransactionsTableEvm: React.FC<EVMProps> = ({
  data,
  itemsPerPage,
  isLoading,
  config,
  virtualizerType = 'inner',
  currentPage,
}) => {
  const { stubbedColumns, stubbedData } = useTableStubs(
    Object.values(config.defaultConfig),
    data?.length
      ? data?.map((item, index) => ({
          ...item,
          key: index,
        }))
      : null,
    itemsPerPage
  )

  const { expandedData, expandedRows, onExpandedRows } = useTableExpanded<
    TransactionEvmTableItem & DataItemType,
    TransactionsEvmExpandedItem & DataItemType,
    (item: TransactionEvmTableItem) => TransactionEvmTableItem['transfers']
  >({
    data: stubbedData,
    selectChildList: (item) => item.transfers?.slice(1, item.transfers.length),
    normalizeChildData: (parentData, item, index) => ({
      currency: parentData.currency,
      allTransfersTokens: parentData.allTransfersTokens,
      transfer: item,
      key: `${parentData.key}_${index}`,
    }),
  })

  const expandedColumns = Object.values(config.expandendConfig)
  const loading = Boolean(data && isLoading)

  return (
    <Table
      columns={stubbedColumns}
      size={'md'}
      variant={['base', 'high', 'high']}
      loading={loading}
      emitScrollToTop={currentPage}
      spaces={{
        0: ['none', 'xl'],
        1: ['none', 'lg'],
        2: ['none', 'lg'],
        3: ['none', 'lg'],
        4: ['none', 'lg'],
        5: ['none', 'lg'],
        6: ['none', 'lg', 'none', 'none'],
      }}
    >
      <TableHead>
        <TableRow>
          {stubbedColumns.map(({ name, renderTitle, ...rest }) => (
            <TableCellColumn key={name} {...rest}>
              {renderTitle?.()}
            </TableCellColumn>
          ))}
        </TableRow>
      </TableHead>
      {expandedData.length ? (
        <TableBody
          as={TableVirtualizer}
          data={expandedData}
          overscan={10}
          type={virtualizerType}
          render={({ virtualItems }) => {
            return (
              virtualItems?.length &&
              virtualItems.map((virtualRow) => {
                const row = expandedData[virtualRow.index]

                if (row.type === 'parent') {
                  const itemData = row.itemData

                  return (
                    <TableRowStyled key={virtualRow.key}>
                      {stubbedColumns.map(({ render, name, ...rest }) => (
                        <TableCellStyled
                          {...rest}
                          key={name}
                          $removeBorder={
                            !!expandedRows[row.index] &&
                            TRANSACTION_ITEM_KEYS_REMOVE_BORDER.includes(
                              name as (typeof TRANSACTION_ITEM_KEYS_REMOVE_BORDER)[number]
                            )
                          }
                        >
                          {render(itemData, virtualRow.index, {
                            collapse: !!expandedRows[row.index],
                            onCollapse: () => onExpandedRows(row.index),
                          })}
                        </TableCellStyled>
                      ))}
                    </TableRowStyled>
                  )
                } else if (row.type === 'child') {
                  const childItemData = row.itemData

                  return (
                    <TableRowStyled key={virtualRow.key}>
                      {expandedColumns.map(({ render, name, ...rest }) => (
                        <TableCellColumn {...rest} key={name}>
                          {render(childItemData, virtualRow.index)}
                        </TableCellColumn>
                      ))}
                    </TableRowStyled>
                  )
                }
              })
            )
          }}
        />
      ) : !loading ? (
        <TableBody>
          <EntityTableNotFound />
        </TableBody>
      ) : null}
    </Table>
  )
}

const TransactionsTableUtxo: React.FC<UTXOProps> = ({
  data,
  isLoading,
  itemsPerPage = 10,
  config,
  currentPage,
}) => {
  const { stubbedColumns, stubbedData } = useTableStubs(
    Object.values(config.defaultConfig),
    data?.map((item, index) => ({
      ...item,
      key: index,
    })),
    itemsPerPage
  )

  const loading = Boolean(data && isLoading)

  return (
    <DataGrid
      type="simple"
      columns={stubbedColumns}
      data={stubbedData}
      size={'md'}
      variant={['base', 'high', 'high']}
      borders={[
        { bottom: { color: 'secondaryContainerDeep' } },
        { bottom: { color: 'secondaryContainerDeep' } },
      ]}
      spaces={{
        0: ['none', 'xl'],
        1: ['none', 'lg'],
        2: ['none', 'lg'],
        3: ['none', 'lg'],
        4: ['none', 'lg'],
        5: ['none', 'lg'],
        6: ['none', 'lg', 'none', 'none'],
      }}
      countMock={10}
      loading={loading}
      notFoundComponent={<EntityTableNotFound />}
      emitScrollToTop={currentPage}
    />
  )
}

const TransactionTableComponent = memo(
  ({
    data,
    isLoading,
    itemsPerPage = 10,
    config,
    filtersContent,
    coinType,
    virtualizerType,
    currentPage,
  }: Props) => {
    return (
      <div className={cx('TransactionTableWrapper')}>
        <S.Container>
          {filtersContent ? (
            <S.FilterContainer>{filtersContent}</S.FilterContainer>
          ) : null}
          <S.TableContainer>
            {isEVM(coinType) ? (
              <TransactionsTableEvm
                data={data as ClusterTransactionEvmAggregate[]}
                config={config as ReturnType<typeof getDefaultEvmTableConfig>}
                itemsPerPage={itemsPerPage}
                isLoading={isLoading}
                virtualizerType={virtualizerType}
                currentPage={currentPage}
              />
            ) : (
              <TransactionsTableUtxo
                data={data as ClusterTransactionUtxo[]}
                config={config as ReturnType<typeof getDefaultUtxoTableConfig>}
                itemsPerPage={itemsPerPage}
                isLoading={isLoading}
                currentPage={currentPage}
              />
            )}
          </S.TableContainer>
        </S.Container>
      </div>
    )
  }
)

export const TransactionTable = withErrorBoundary(TransactionTableComponent)

TransactionTableComponent.displayName = 'TransactionTable'
