import type { EdgeAttributes } from '@clain/graph'

import ProbeEdge from './ProbeEdge'
import { DemixEdgeData } from '../../types'
import { GHOSTED_OPACITY } from '../../constants'

const WIDTH = 1
const STYLE = 'dashed'
const SOURCE_TYPE = 'arrow'
const TARGET_TYPE = 'arrow'
const COLOR_HOVERED = 'rgba(99, 123, 171, 1)'
const LABEL_BORDER_WIDTH_HOVERED = 2
const LABEL_BORDER_COLOR_HOVERED = 'rgba(99, 123, 171, 1)'
const LABEL_COLOR = 'rgba(141, 158, 193, 1)'
const LABEL_BACKGROUND_COLOR = 'rgba(237, 241, 247, 1)'
const LABEL_BORDER_RADIUS = 4
const LABEL_SIZE = 12
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const LABEL_COLOR_HIGHLIGHTED = 'rgba(237, 241, 247, 1)'
const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'

export class DemixEdge<
  T extends DemixEdgeData = DemixEdgeData,
> extends ProbeEdge<T> {
  protected generateAttributes() {
    const attributes = {
      width: WIDTH,
      increaseHitArea: 10,
      color: LABEL_COLOR,
      style: STYLE,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      sourceType: this.data.isDeposit ? SOURCE_TYPE : undefined,
      targetType: this.data.isDeposit ? undefined : TARGET_TYPE,
    } as EdgeAttributes<T>

    if (this.layers.demixDepositLabel) {
      attributes['label'] = {
        text: this.data.isDeposit ? 'Deposit' : 'Withdrawal',
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [4, 3],
      }
    }

    if (this.hovered && !this.highlighted) {
      attributes.color = COLOR_HOVERED
      if (attributes.label) {
        attributes.label.border = {
          width: LABEL_BORDER_WIDTH_HOVERED,
          color: LABEL_BORDER_COLOR_HOVERED,
        }
      }
    }

    if (this.highlighted) {
      attributes.color = COLOR_HIGHLIGHTED

      if (attributes.label) {
        attributes.label.color = LABEL_COLOR_HIGHLIGHTED
        attributes.label.fill = LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      }
    }

    return attributes
  }
}
