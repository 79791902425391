import { action, computed, makeObservable } from 'mobx'
import { apiServicesStateFacade } from './ApiServicesStateFacade'

export class DataTokenViewModel {
  private apiServicesStateFacade = apiServicesStateFacade

  constructor() {
    makeObservable(this)
  }

  @computed.struct
  public get data() {
    return this.apiServicesStateFacade.getStateByService('token')
  }

  @computed.struct
  public get params() {
    return this.apiServicesStateFacade.getApiParamsStateByService('token')
  }

  @computed.struct
  public get updateParams() {
    return this.apiServicesStateFacade.updateApiParamsStateByService('token')
  }

  @computed.struct
  public get token() {
    return this.data
  }

  @computed
  public get isLoading() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('token') === 'LOADING'
    )
  }

  @computed
  public get isSuccess() {
    return (
      this.apiServicesStateFacade.getStateStatusByService('token') === 'SUCCESS'
    )
  }

  @action
  public clear = () => {
    this.apiServicesStateFacade.clear()
  }
}

export const dataTokenViewModel = new DataTokenViewModel()
