import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import Link from '@clain/core/Link'
import { useFormatDate } from '../../hooks'
import { useFormatMoney } from '@clain/core/utils/format'
import { useTranslation } from 'react-i18next'
import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
} from '@clain/core/ui-kit'
import { EthBlock } from './types'
import styles from './index.scss'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { CoinTypeEVM } from '../../types/coin'
import { observer } from 'mobx-react-lite'
import { TypographyNew } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const BlocksTableEvm = ({
  blocks,
  currency,
  loading,
  coinType = 'eth',
}: {
  blocks: EthBlock[]
  currency: 'src' | 'usd'
  loading: boolean
  coinType: CoinTypeEVM
}) => {
  const { t } = useTranslation()
  const formatMoney = useFormatMoney()
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const formatDate = useFormatDate()

  const { columns, columnsData } = createColumns<EthBlock>(blocks, [
    {
      title: t('platform:height'),
      field: 'Height',
      render: ({ height }) => (
        <Link to={`/${coinType}/explorer/block/${height}`}>{height}</Link>
      ),
    },
    {
      title: t('platform:age'),
      field: 'Age',
      render: ({ time }) => formatDate(fromUnixTime(time)),
    },
    {
      title: t('platform:transactions'),
      field: 'Transactions',
      render: ({ trx_count }) => formatNumber(trx_count, 0),
      type: 'number',
    },
    {
      title: `${t('platform:totalFee')}, ${currency === 'usd' ? 'USD' : coinType.toUpperCase()}`,
      field: 'TotalFee',
      render: ({ total_fee, total_fee_usd }) =>
        formatMoney({
          value: currency === 'usd' ? total_fee_usd : total_fee,
          currency: currency === 'src' ? coinType : currency,
          code: '',
        }),
      type: 'number',
    },
    {
      title: t('platform:size'),
      field: 'Size',
      render: ({ size }) => size,
      type: 'number',
    },
  ])

  const noValuesStub = blocks && !blocks.length

  return (
    <Table interactive={Boolean(blocks?.length)} loading={loading}>
      <Thead>
        <Tr>
          {columns.map(({ title, field, type }) => (
            <Th key={field} className={cx('Title', field)} type={type}>
              {title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {columnsData.map((block, index) => (
          <Tr key={index}>
            {columns.map(({ render, field, type }) => (
              <Td key={field} className={cx('Cell', field)} type={type}>
                {render(block)}
              </Td>
            ))}
          </Tr>
        ))}
        {noValuesStub && (
          <Tr>
            <Td colSpan={columns.length}>
              <TypographyNew
                variant="body100Normal"
                color="onBackgroundVariant2"
              >
                {t('platform:noBlocksFound')}
              </TypographyNew>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  )
}

export default observer(BlocksTableEvm)
