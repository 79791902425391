import type { CoinType } from '@clain/core/ui-kit'
import {
  action,
  autorun,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  toJS,
} from 'mobx'
import type { IProbeState } from '../../ProbeState'
import { activeEntityCacheModel } from '../ActiveEntityCacheModel'
import type { IActiveEntityFetchFacade } from '../ActiveEntityFetchFacade'
import type { IActiveEntityFetchState } from '../ActiveEntityFetchState'
import type { IActiveEntityFiltersState } from '../ActiveEntityFiltersState/ActiveEntityFiltersState.types'
import type { IActiveEntityState } from '../ActiveEntityState/ActiveEntityState.types'
import type { ActiveEntityDI } from './ActiveEntity.types'

export class ActiveEntity<
  IFetchState,
  IFetch,
  IFilters extends Record<string, IActiveEntityFiltersState<unknown>>,
  IState,
> {
  public entitiesFetchState: IFetchState
  private entitiesFetch: IFetch
  @observable public entitiesFilters: IFilters
  public entityState: IActiveEntityState<IState>
  private reactionDisposers: Array<IReactionDisposer> = []
  @observable public currency: CoinType
  private activeEntityCacheModel = activeEntityCacheModel
  private entityKey: string
  private probeState: IProbeState

  constructor(
    {
      entitiesFetchState,
      entitiesFetch,
      entitiesFilters,
      entityState,
      entityKey,
    }: ActiveEntityDI<
      IFetchState,
      IFetch,
      IFilters,
      IActiveEntityState<IState>
    >,
    probeState: IProbeState
  ) {
    makeObservable(this)

    this.entitiesFetch = entitiesFetch
    this.entitiesFilters = entitiesFilters
    this.entityState = entityState
    this.entitiesFetchState = entitiesFetchState
    this.entityKey = entityKey
    this.probeState = probeState
  }

  public init = (currency: CoinType) => {
    this.currency = currency
  }

  private createCacheKey = (id: number, key: string) => {
    return `${this.entityKey}_${id}_${this.currency}_${key}`
  }

  private initDataLoadingReaction = () => {
    Object.keys(this.entitiesFetch).forEach((key) => {
      const fetch = this.entitiesFetch[key] as IActiveEntityFetchFacade<
        unknown,
        unknown
      >

      const { initFilters, forceInitFilters } = this.entitiesFilters[
        key
      ] as IActiveEntityFiltersState<unknown>

      const { setState } = this.entitiesFetchState[
        key
      ] as IActiveEntityFetchState<unknown>

      const state = this.entityState as IActiveEntityState<unknown, number>

      this.reactionDisposers.push(
        reaction(
          () => state?.id,
          (stateId) => {
            if (stateId) {
              const cacheFilterKey = this.createCacheKey(stateId, key)

              if (
                this.activeEntityCacheModel.cachedFilters.has(cacheFilterKey)
              ) {
                initFilters(
                  this.activeEntityCacheModel.cachedFilters.get(cacheFilterKey)
                )
              }
            }
          },
          { fireImmediately: true }
        )
      )

      // Sync initFilters with filters when changed tab
      this.reactionDisposers.push(
        reaction(
          () => this.probeState.bottombarTabActive,
          () => {
            if (state?.id) {
              const { filters } = this.entitiesFilters[
                key
              ] as IActiveEntityFiltersState<unknown>

              forceInitFilters(toJS(filters))
            }
          }
        )
      )

      this.reactionDisposers.push(
        autorun(() => {
          const { filters } = this.entitiesFilters[
            key
          ] as IActiveEntityFiltersState<unknown>

          if (state?.id) {
            const cacheFilterKey = this.createCacheKey(state.id, key)
            const cacheRequestKey = `${cacheFilterKey}${JSON.stringify(
              filters
            )}`

            const cachedNode =
              this.activeEntityCacheModel.cachedNodes.get(cacheRequestKey)

            if (cachedNode && cachedNode.node) {
              setState(cachedNode.node)
              this.activeEntityCacheModel.cacheFilters(
                cacheFilterKey,
                toJS(filters)
              )
              return
            } else {
              fetch.request(...[state.id, filters]).then((result) => {
                this.activeEntityCacheModel.cacheNode(cacheRequestKey, {
                  type: key,
                  node: result,
                })
                this.activeEntityCacheModel.cacheFilters(
                  cacheFilterKey,
                  toJS(filters)
                )
              })
            }
          }
        })
      )
    })
  }

  public invalidateCache = <
    K extends keyof IFilters,
    T = IFilters[K]['filters'],
  >(
    id: number,
    key: K,
    cb: (cachedFilters: T | undefined) => boolean
  ) => {
    const cacheKey = this.createCacheKey(id, key as string)

    const isInvalidateCache = cb(
      this.activeEntityCacheModel.cachedFilters.get(cacheKey) as T
    )

    if (isInvalidateCache) {
      this.activeEntityCacheModel.cachedFilters.delete(cacheKey)
    }
  }

  @action.bound
  public update(id: number, info: IState) {
    if (!this.reactionDisposers.length) {
      this.initDataLoadingReaction()
    }

    this.entityState.setId(id)
    this.entityState.setState(info)
  }

  @action.bound
  public updateEntityKey(entityKey: string) {
    this.entityKey = entityKey
  }

  @action
  private clearData = () => {
    Object.keys(this.entitiesFetchState).forEach((key) => {
      const { clearData } = this.entitiesFetchState[
        key
      ] as IActiveEntityFetchState<unknown>

      const { clearFilters } = this.entitiesFilters[
        key
      ] as IActiveEntityFiltersState<unknown>

      clearData()
      clearFilters()
    })
  }

  @action
  public clear() {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []

    this.clearData()
  }
}
