import wsState, { Channel } from '../../utils/WebSocketWrapper'
import {
  CreatePresetDataPayload,
  ITransactionPresetsAPIService,
  RawPresetData,
  TransactionPresetsServerData,
} from './TransactionPresetsService.types'
import { injectable } from 'inversify'

const TIMEOUT_IN_MS = 60_000
const CHANNEL_KEY = 'trx_filter:global'

@injectable()
export class TransactionPresetsService
  implements ITransactionPresetsAPIService
{
  private channel: Channel

  public joinChannelAndGetPresets = () => {
    this.channel = wsState.channel(CHANNEL_KEY)
    return new Promise<TransactionPresetsServerData>((resolve, reject) => {
      this.channel
        .join<TransactionPresetsServerData>()
        .then((rawBreakdownData) => {
          resolve(rawBreakdownData)
        })
        .catch((error: string) => {
          console.warn('getPresets error', error) // TODO: remove
          reject(error)
        })
    })
  }

  public createPreset = (data: CreatePresetDataPayload) => {
    return new Promise<RawPresetData>((resolve, reject) => {
      this.channel
        ?.push<RawPresetData>('add_preset', data, TIMEOUT_IN_MS)
        .then((rawPresetData) => {
          resolve(rawPresetData)
        })
        .catch((error: string) => {
          console.warn('add_preset error', error) // TODO: remove
          reject(error)
        })
    })
  }

  public deletePreset = (id: number | string) => {
    return new Promise<void>((resolve, reject) => {
      this.channel
        ?.push<void>('remove_preset', { id }, TIMEOUT_IN_MS)
        .then(() => {
          resolve()
        })
        .catch((error: string) => {
          console.warn('remove_preset error', error) // TODO: remove
          reject(error)
        })
    })
  }
}
