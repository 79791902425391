import {
  transactionsTableViewModel as addresDataETVM,
  dataTokensViewModel as addressTokensViewModel,
} from '../../AddressViewModels'
import {
  transactionsTableViewModel as clusterDataETVM,
  dataTokensViewModel as clusterTokensViewModel,
} from '../../ClusterViewModels'
import { QueryParamsViewModel } from '../QueryParamsViewModel'
import { action, computed, makeObservable } from 'mobx'
import cloneDeep from 'lodash/cloneDeep'
import { setDebouncedTableFilters } from '../../utils'
import { ClusterTransactionsAggregatePaginated } from '../../../ProbeSandbox/types/converted/ClusterTransactions'
import { mergeByKeys } from '@clain/core/utils'
import { applyAllTransferTokens } from '../../../ProbeSandbox/utils/applyAllTransferTokens'
import { ENTITY_FILTER_PREFIXES } from '../../constants'
import { dataTokenViewModel as clusterTokenViewModel } from '../../ClusterViewModels/DataTokenViewModel'
import { dataTokenViewModel as addressTokenViewModel } from '../../AddressViewModels/DataTokenViewModel'
import { isEVM, isUTXO } from '@clain/core/types'
import { EVM_COINS } from '@clain/core/ui-kit'
import { checkCoinsByType } from '@clain/core/utils/checkCoinByType'
import { CoinType } from '@platform/types/coin'

export class TransactionsStrategy {
  public viewModel: typeof addresDataETVM | typeof clusterDataETVM
  private queryParamsViewModel: QueryParamsViewModel<any>
  private blockchain: CoinType
  private tokenViewModel:
    | typeof clusterTokenViewModel
    | typeof addressTokenViewModel

  private tokensViewModel:
    | typeof addressTokensViewModel
    | typeof clusterTokensViewModel

  constructor(
    blockchain: CoinType,
    viewModel: typeof addresDataETVM | typeof clusterDataETVM,
    queryParamsViewModel: QueryParamsViewModel<any>,
    tokenViewModel: typeof clusterTokenViewModel | typeof addressTokenViewModel,
    tokensViewModel:
      | typeof addressTokensViewModel
      | typeof clusterTokensViewModel
  ) {
    makeObservable(this)
    this.blockchain = blockchain
    this.viewModel = viewModel
    this.queryParamsViewModel = queryParamsViewModel
    this.tokenViewModel = tokenViewModel
    this.tokensViewModel = tokensViewModel
  }

  @action
  handleSetFilters = (
    filters: typeof this.viewModel.filters,
    timeout = 150
  ) => {
    const notObservableFilters = cloneDeep(filters)
    setDebouncedTableFilters(timeout, 'trns')(
      notObservableFilters,
      this.queryParamsViewModel.updateQueryParamsState,
      this.viewModel?.updateFilters
    )
  }

  get data(): ClusterTransactionsAggregatePaginated {
    const result = this.viewModel?.data?.data

    if (result?.transactions?.length) {
      return mergeByKeys(
        'transactions',
        applyAllTransferTokens(result.transactions),
        result
      ) as unknown as ClusterTransactionsAggregatePaginated
    }

    return result as unknown as ClusterTransactionsAggregatePaginated
  }

  get isLoading(): boolean {
    return this.viewModel?.isLoading
  }

  get filters() {
    return this.viewModel?.filters
  }

  get updateFilters() {
    return this.viewModel?.updateFilters
  }

  get isUsdSwitchDisabled() {
    return this.viewModel?.filters.includeTokens?.[0]?.id == null
  }

  @action
  switchUSD = (isUsd: boolean) => {
    this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
      ...prevState,
      [ENTITY_FILTER_PREFIXES.transactions]: {
        ...prevState[ENTITY_FILTER_PREFIXES.transactions],
        convert_to: isUsd ? 'usd' : 'native',
      },
    }))
  }

  @computed
  public get tokens() {
    if (
      isEVM(this.blockchain) &&
      this.tokenViewModel.params?.address &&
      checkCoinsByType(
        this.tokenViewModel.params?.address,
        EVM_COINS,
        'address'
      )
    ) {
      return this.tokenViewModel.data ? [this.tokenViewModel.data] : []
    }

    return this.tokensViewModel.tokensWithoutAggregated.filter(
      (token) => !token?.spam && !token?.scam
    )
  }

  @computed
  public get disabledTransactionAssetStaticSearch() {
    return (
      isEVM(this.blockchain) &&
      this.tokenViewModel.params?.address &&
      checkCoinsByType(
        this.tokenViewModel.params?.address,
        EVM_COINS,
        'address'
      )
    )
  }

  @action
  public setTokenByAddress = (address: string) => {
    if (isUTXO(this.blockchain)) return

    if (checkCoinsByType(address, EVM_COINS, 'address')) {
      this.tokenViewModel.updateParams({ address })
      return
    }

    this.tokenViewModel.updateParams({ address: '' })
  }

  @computed
  public get tokenLoading() {
    return this.tokenViewModel.isLoading
  }

  @action
  clear(): void {
    this.viewModel?.clear()
  }
}
