import React, { useCallback, useEffect, useRef } from 'react'
import {
  ColDeprecated,
  RowDeprecated,
  Stub,
  Separator,
  FiltersButtons,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import {
  useFilterNameList,
  useInitFilters,
} from './ClusterFingerprintFilters.service'
import { GroupByFilterContainer } from './GroupByFilter'
import { CalendarFilterContainer } from './CalendarFilter'
import { useTransactionsFlagsViewModel } from '../../modules/analytics/TransactionsFlags/TransactionFlagsData.context'
import { useTranslation } from 'react-i18next'

export const ClusterFingerprintFilters = observer(() => {
  const { t } = useTranslation()
  const { isFiltersChanged, isLoading, resetFilters } =
    useTransactionsFlagsViewModel()
  const filterList = useFilterNameList()
  useInitFilters()
  const leftSlots = useRef([])
  const rightSlots = useRef([])
  const createFilter = useCallback((filter: (typeof filterList)[number]) => {
    switch (filter) {
      case 'groupBy':
        return leftSlots.current.push(<GroupByFilterContainer key={filter} />)
      case 'calendar':
        return rightSlots.current.push(<CalendarFilterContainer key={filter} />)
    }
  }, [])

  useEffect(() => {
    leftSlots.current = []
    rightSlots.current = []
    filterList.map(createFilter)
  }, [filterList.length])

  return (
    <Stub isActive={isLoading}>
      <ColDeprecated>
        <RowDeprecated align={'between'} fullWidth>
          <RowDeprecated>
            {leftSlots.current.map((el) => el)}
            {rightSlots.current?.length ? (
              <>
                <Separator variant={'vertical'} />
                {rightSlots.current.map((el) => el)}
              </>
            ) : null}
          </RowDeprecated>
          <FiltersButtons.Clear
            disabled={!isFiltersChanged}
            size="md"
            onClear={resetFilters}
            iconVariant="Restore"
            label={t('platform:reset')}
          />
        </RowDeprecated>
      </ColDeprecated>
    </Stub>
  )
})
