import { CoinType, isUTXO } from '@clain/core/types/coin'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
} from 'mobx'
import {
  DEFAULT_OSINTS_FILTERS,
  DEFAULT_TOKENS_FILTERS,
  ENTITY_FILTER_PREFIXES,
} from '../constants'
import { EntityServices } from '../../ProbeSandbox/vm/services/EntitiesServices'
import { IEntityServices } from '../../ProbeSandbox/vm/services/EntitiesServices/types'
import { CURRENCY } from '../../ProbeSandbox/constants/currency'
import { isEmpty } from 'ramda'
import { queryParamsViewModel } from './QueryParamsViewModel'
import {
  apiServicesStateFacade,
  commonPageData,
} from './ApiServicesStateFacade'
import { dataStatsViewModel } from './DataStatsViewModel'
import { dataTokensViewModel } from './DataTokensViewModel'
import { SectionDetailsViewModel } from './SectionDetailsViewModel'
import { SectionEntitiesTableViewModel } from './SectionEntitiesTableViewModel'
import { PageAnalyticsViewModel } from './PageAnalyticsViewModel'
import { SectionPortfolioViewModel } from '../baseClasses/SectionPortfolioViewModel'
import {
  transactionsTableViewModel,
  counterpartiesTableViewModel,
  addressesTableViewModel,
  osintTableViewModel,
} from './DataEntitiesTableViewModel'

export class ClusterPageViewModel {
  @observable public isInitialized = false

  private static instance: ClusterPageViewModel
  private reactionDisposers: Array<IReactionDisposer> = []
  private services: IEntityServices
  private entityType = 'cluster' as const
  private queryParamsViewModel = queryParamsViewModel
  private apiServicesStateFacade = apiServicesStateFacade
  private statsViewModel = dataStatsViewModel
  private tokensViewModel = dataTokensViewModel
  private transactionsTableViewModel = transactionsTableViewModel
  private counterpartiesTableViewModel = counterpartiesTableViewModel
  private addressesTableViewModel = addressesTableViewModel
  private osintTableViewModel = osintTableViewModel

  public pageAnalyticsViewModel: PageAnalyticsViewModel
  public commonPageData = commonPageData
  public sectionDetailsViewModel: SectionDetailsViewModel
  public sectionEntitiesTableViewModel: SectionEntitiesTableViewModel
  public sectionPortfolioViewModel: SectionPortfolioViewModel<
    typeof this.statsViewModel,
    typeof this.tokensViewModel,
    typeof this.commonPageData
  >

  public static getInstance(): ClusterPageViewModel {
    if (!ClusterPageViewModel.instance) {
      ClusterPageViewModel.instance = new ClusterPageViewModel()
    }
    return ClusterPageViewModel.instance
  }

  constructor() {
    makeObservable(this)
  }

  @action
  public init = (currency: CoinType, id: number, timezone: string) => {
    this.commonPageData.initState({
      entityId: id,
      blockchain: currency,
      timezone,
    })
    this.services = EntityServices.getInstance(CURRENCY)
    this.pageAnalyticsViewModel = new PageAnalyticsViewModel()
    this.sectionPortfolioViewModel = new SectionPortfolioViewModel(
      this.statsViewModel,
      this.tokensViewModel,
      this.commonPageData
    )
    this.sectionDetailsViewModel = new SectionDetailsViewModel(
      this.sectionPortfolioViewModel
    )
    this.sectionEntitiesTableViewModel = new SectionEntitiesTableViewModel()
    this.initApiServicesAndFetchData()
    this.initReactions()
    this.isInitialized = true
  }

  @action
  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => ({
          isStatsSuccess: this.statsViewModel.isSuccess,
          isTokensSuccess: this.tokensViewModel.isSuccess,
          entityId: this.entityId,
        }),
        ({ isStatsSuccess, isTokensSuccess, entityId }) => {
          if (isStatsSuccess && isTokensSuccess) {
            this.initQueryParams()
            this.initEntitiesTable()
            this.initAnalytics()
            this.apiServicesStateFacade.initDataLoadingReaction(
              'transactions',
              [entityId]
            )
            this.apiServicesStateFacade.initDataLoadingReaction('token')
            this.apiServicesStateFacade.initDataLoadingReaction(
              'counterparties',
              [entityId]
            )
            this.apiServicesStateFacade.initDataLoadingReaction('addresses', [
              entityId,
            ])
            this.apiServicesStateFacade.initDataLoadingReaction('osint', [
              entityId,
            ])
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isTransactionsDataSuccess:
            this.pageAnalyticsViewModel.transactionsFlagsChartViewModel
              .isSuccess,
          groupByFilter:
            this.pageAnalyticsViewModel.transactionsFlagsChartViewModel
              .groupByFilter,
          calendarFilter:
            this.pageAnalyticsViewModel.transactionsFlagsChartViewModel
              .calendarFilter || [],
        }),
        ({ isTransactionsDataSuccess, groupByFilter, calendarFilter }) => {
          if (isTransactionsDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              tbf: {
                ...prevState.tbf,
                group_by: groupByFilter,
                timestamp_from: calendarFilter[0],
                timestamp_to: calendarFilter[1],
              },
            }))
          }
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          isNetflowChartSuccess:
            this.pageAnalyticsViewModel.netflowChartViewModel.isSuccess,
          calendarFilter:
            this.pageAnalyticsViewModel.netflowChartViewModel.calendarFilter,
          scoreFilter:
            this.pageAnalyticsViewModel.netflowChartViewModel.scoreFilter,
          groupByFilter:
            this.pageAnalyticsViewModel.netflowChartViewModel.groupByFilter,
          convertTo:
            this.pageAnalyticsViewModel.netflowChartViewModel
              .formattedParamsData.state.convertTo,
          includeTokens:
            this.pageAnalyticsViewModel.netflowChartViewModel
              .formattedParamsData.state.includeTokens,
        }),
        ({
          isNetflowChartSuccess,
          calendarFilter,
          scoreFilter,
          groupByFilter,
          convertTo,
          includeTokens,
        }) => {
          if (isNetflowChartSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              group_by: groupByFilter,
              score_min: scoreFilter?.[0],
              score_max: scoreFilter?.[1],
              timestamp_from: calendarFilter?.[0],
              timestamp_to: calendarFilter?.[1],
              convert_to: convertTo === 'usd' ? 'usd' : 'native',
              include_tokens: includeTokens,
            }))
          }
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          isSenkeyChartSuccess:
            this.pageAnalyticsViewModel.senkeyChartViewModel.isSuccess,
          calendarFilter:
            this.pageAnalyticsViewModel.senkeyChartViewModel.calendarFilter,
          scoreFilter:
            this.pageAnalyticsViewModel.senkeyChartViewModel.scoreFilter,
          includeTokens:
            this.pageAnalyticsViewModel.senkeyChartViewModel.formattedParamsData
              .state.includeTokens,
          convertTo:
            this.pageAnalyticsViewModel.senkeyChartViewModel.formattedParamsData
              .state.convertTo,
        }),
        ({
          isSenkeyChartSuccess,
          calendarFilter,
          scoreFilter,
          includeTokens,
          convertTo,
        }) => {
          if (isSenkeyChartSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              cp: {
                ...prevState.cp,
                score_min: scoreFilter?.[0],
                score_max: scoreFilter?.[1],
                timestamp_from: calendarFilter?.[0],
                timestamp_to: calendarFilter?.[1],
                convert_to: convertTo === 'usd' ? 'usd' : 'native',
                include_tokens: includeTokens,
              },
            }))
          }
        }
      )
    )

    this.reactionDisposers.push(
      reaction(
        () => ({
          isTransactionsDataSuccess: this.transactionsTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.transactionsTable.filters
            .page,
        }),
        ({ isTransactionsDataSuccess, page }) => {
          if (isTransactionsDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.transactions]: {
                ...prevState[ENTITY_FILTER_PREFIXES.transactions],
                page,
              },
            }))
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isDataSuccess: this.counterpartiesTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.counterpartiesTable.filters
            .page,
        }),
        ({ isDataSuccess, page }) => {
          if (isDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.counterparties]: {
                ...prevState[ENTITY_FILTER_PREFIXES.counterparties],
                page,
              },
            }))
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isDataSuccess: this.addressesTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.addressesTable.filters.page,
        }),
        ({ isDataSuccess, page }) => {
          if (isDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.addresses]: {
                ...prevState[ENTITY_FILTER_PREFIXES.addresses],
                page,
              },
            }))
          }
        }
      )
    )
    this.reactionDisposers.push(
      reaction(
        () => ({
          isDataSuccess: this.osintTableViewModel.isSuccess,
          page: this.sectionEntitiesTableViewModel.osintTable.filters.page,
        }),
        ({ isDataSuccess, page }) => {
          if (isDataSuccess) {
            this.queryParamsViewModel.updateQueryParamsState((prevState) => ({
              ...prevState,
              [ENTITY_FILTER_PREFIXES.osint]: {
                ...prevState[ENTITY_FILTER_PREFIXES.osint],
                page,
              },
            }))
          }
        }
      )
    )
  }

  @action
  private initApiServicesAndFetchData = () => {
    this.initTokens()
    this.initStats()
    this.apiServicesStateFacade.initDataLoadingReaction('tokens', [
      this.entityId,
    ])
    this.apiServicesStateFacade.initDataLoadingReaction('stats', [
      this.entityId,
    ])
  }

  @action
  private initTokens = () => {
    this.apiServicesStateFacade.initApiParamsStateByService('tokens')(
      DEFAULT_TOKENS_FILTERS
    )
    this.apiServicesStateFacade.injectRequestMethodByService('tokens')(
      this.services.getServices(this.entityType, this.blockchain).getTokens
    )
  }

  @action
  private initStats = () => {
    this.apiServicesStateFacade.injectRequestMethodByService('stats')(
      this.services.getServices(this.entityType, this.blockchain).getStats
    )
  }

  @action
  private initEntitiesTable = () => {
    this.sectionEntitiesTableViewModel.setActiveTab(
      this.queryParamsViewModel.queryParamsState.active_tab
    )
    this.apiServicesStateFacade.initApiParamsStateByService('osint')({
      ...DEFAULT_OSINTS_FILTERS,
      ...this.queryParamsViewModel.queryParamsState.osint,
    })
    this.apiServicesStateFacade.injectRequestMethodByService('osint')(
      this.services.getServices(this.entityType, this.blockchain).getOsints
    )

    const { includeTokens = [], ...restParams } =
      this.queryParamsViewModel.queryParamsState.trns
    const tokenId = includeTokens?.[0] ? Number(includeTokens?.[0]) : null
    const token = this.tokensViewModel.tokensBalanceData.find(
      (el) => tokenId === el?.token?.id
    )

    this.apiServicesStateFacade.initApiParamsStateByService('transactions')({
      ...restParams,
      includeTokens: token?.token != null ? [token?.token] : [],
    })
    this.apiServicesStateFacade.initDefaultApiParamsStateByService(
      'transactions'
    )({
      ...this.queryParamsViewModel.queryParamsDefaultState.trns,
      includeTokens: [],
    })
    this.apiServicesStateFacade.injectRequestMethodByService('transactions')(
      this.services.getServices(this.entityType, this.blockchain)
        .getTransactions
    )

    this.apiServicesStateFacade.injectRequestMethodByService('token')(
      async (payload) => {
        if (!payload?.address) return

        return await this.services
          .getServices('explorer', this.blockchain)
          .getTokenByAddress(payload)
      }
    )

    const {
      includeTokens: includeTokensCounterparties = [],
      ...restCounterpartiesParams
    } = this.queryParamsViewModel.queryParamsState.counterparties
    const tokenIdCounterparties = includeTokensCounterparties?.[0]
      ? Number(includeTokensCounterparties?.[0])
      : null
    const tokenCounterparties = this.tokensViewModel.tokensBalanceData.find(
      (el) => tokenIdCounterparties === el?.token?.id
    )

    this.apiServicesStateFacade.initDefaultApiParamsStateByService(
      'counterparties'
    )({
      ...this.queryParamsViewModel.queryParamsDefaultState.counterparties,
      includeTokens: [],
    })
    this.apiServicesStateFacade.initApiParamsStateByService('counterparties')({
      ...restCounterpartiesParams,
      includeTokens:
        tokenCounterparties?.token != null ? [tokenCounterparties?.token] : [],
    })

    this.apiServicesStateFacade.injectRequestMethodByService('counterparties')(
      this.services.getServices(this.entityType, this.blockchain)
        .getCounterparties
    )

    const {
      includeTokens: includeTokensAddresses = [],
      ...restAddressesParams
    } = this.queryParamsViewModel.queryParamsState.addresses
    const tokenIdAddresses = includeTokensAddresses?.[0]
      ? Number(includeTokensAddresses?.[0])
      : null
    const tokenAddresses = this.tokensViewModel.tokensBalanceData.find(
      (el) => tokenIdAddresses === el?.token?.id
    )

    this.apiServicesStateFacade.initApiParamsStateByService('addresses')({
      ...restAddressesParams,
      includeTokens:
        tokenAddresses?.token != null ? [tokenAddresses?.token] : [],
    })
    this.apiServicesStateFacade.initDefaultApiParamsStateByService('addresses')(
      {
        ...this.queryParamsViewModel.queryParamsDefaultState.addresses,
        includeTokens: [],
      }
    )
    this.apiServicesStateFacade.injectRequestMethodByService('addresses')(
      this.services.getServices(this.entityType, this.blockchain).getAddresses
    )
  }

  @action
  private initAnalytics = () => {
    const isInitTransactionsByFlags = isUTXO(this.blockchain)
    this.pageAnalyticsViewModel.initAnalytics({ isInitTransactionsByFlags })
    this.pageAnalyticsViewModel.loadAnalytics({
      isInitTransactionsByFlags,
    })
  }

  @action
  private initQueryParams = () => {
    this.queryParamsViewModel.initQueryParams()
  }

  @computed
  public get entityId() {
    return this.commonPageData.state.entityId
  }

  @computed
  public get blockchain() {
    return this.commonPageData.state.blockchain
  }

  @computed.struct
  public get pageNotFound() {
    return (
      isEmpty(this.statsViewModel.data || {}) && this.statsViewModel.isSuccess
    )
  }

  @computed
  public get periodWithTimezone() {
    return this.statsViewModel.getPeriod?.(this.commonPageData.state?.timezone)
  }

  @action
  public clear = () => {
    this.isInitialized = false
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.commonPageData.clearState()
    this.statsViewModel.clear()
    this.tokensViewModel.clear()
    this.sectionEntitiesTableViewModel.clear()
    this.pageAnalyticsViewModel.clear()
    this.queryParamsViewModel.queryParamsController.clearQueryParamsState()
    this.apiServicesStateFacade.clear()
  }
}

export const clusterPageViewModel = ClusterPageViewModel.getInstance()
