import React, { FC, ReactNode } from 'react'
import { PresetsProvider } from './PresetsContext'
import { PresetsLayout } from './PresetsLayout'
import { PresetsHeader } from './PresetsHeader'
import { PresetsBody } from './PresetsBody'

export const Presets: FC<{ children: ReactNode }> & {
  Layout: typeof PresetsLayout
  Header: typeof PresetsHeader
  Body: typeof PresetsBody
} = ({ children }) => {
  return <PresetsProvider>{children}</PresetsProvider>
}

Presets.Layout = PresetsLayout
Presets.Header = PresetsHeader
Presets.Body = PresetsBody
