import { SelectFilterItem } from '@platform/components/Filters'

export const VERSION_OPTIONS = [
  {
    label: 'V1',
    value: 1,
  },
  {
    label: 'V2',
    value: 2,
  },
]

export const COMPRESSION_OPTIONS = [
  {
    label: 'Compressed',
    value: 1,
  },
  {
    label: 'Uncompressed',
    value: 2,
  },
]

export const FILTER_RBF = {
  value: 'rbf',
  label: 'RBF',
} as SelectFilterItem<string> | null

export const FILTER_SEGWIT = {
  value: 'segwit',
  label: 'SegWit',
} as SelectFilterItem<string> | null

export const FILTER_LOCKTIME = {
  value: 'locktime',
  label: 'Locktime',
} as SelectFilterItem<string> | null

export const FILTER_OUT_CHANGE = {
  value: 'output_change',
  label: 'Change',
} as SelectFilterItem<string> | null
