import { useFilterSlice } from '../AddressUtxoFilters.service'
import { useGetFilterLabels } from '@clain/core/ui-kit'
import { useAddressStaticStore } from '../../context'
import { TextFilterMenuListItem } from '../../../../../Filters'
import { useFetchAddresses } from '../../hooks/useFetchAddresses'

export const useAddressFilterData = () => {
  const [data, { setFilter }] = useFilterSlice('address.data')
  const [, { resetFilter }] = useFilterSlice('address')

  const [condition] = useFilterSlice('address.condition')
  const { isMultiple, multipleLabel } = useGetFilterLabels({
    filterValueCount: data?.length || 0,
    condition,
    pluralLabel: 'Cluster',
  })

  return {
    isMultiple,
    multipleLabel,
    selectedItems: data,
    setFilter,
    resetFilter,
  }
}

export const useClusterSearchData = () => {
  const addressStaticStore = useAddressStaticStore()
  const { getAddresses, addresses } = useFetchAddresses<TextFilterMenuListItem>(
    {
      ...addressStaticStore.current,
      normalize: ({ aid, address }) => ({
        id: aid,
        label: address,
      }),
    }
  )

  return {
    menuListData: addresses,
    onSearch: getAddresses,
  }
}

export const useSelectedClusterFilterData = () => {
  const [selectedItems] = useFilterSlice('address.data')

  return selectedItems?.length ? selectedItems[0] : null
}
