import { ContainerModule, interfaces } from 'inversify'

import {
  ITransactionPresetsAPIService,
  TransactionPresetsService,
} from '@platform/apiServices/transactionPresets'
import { DI_TYPES } from '@platform/di/DITypes'

const initializeModule = (bind: interfaces.Bind) => {
  bind<ITransactionPresetsAPIService>(DI_TYPES.TransactionFilterPresetsAPI).to(
    TransactionPresetsService
  )
}

export const apiModules = new ContainerModule(initializeModule)
