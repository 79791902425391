import React, { useState } from 'react'
import { Button, Typography } from '@clainio/web-platform'
import { ColDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { SelectReportTypeCard } from './SelectReportTypeCard'
import genAllImage from '@clain/core/assets/generate_report_all.png'
import genSelectedSelectedImage from '@clain/core/assets/generate_report_selected.png'
import genSelectedDisabledImage from '@clain/core/assets/generate_report_selected_disabled.png'

import classnames from 'classnames/bind'
import styles from './SelectReportTypeStep.scss'
import reportFacade from '../../ProbeGenerateReport.facade'
import { AlertCaution } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
const cx = classnames.bind(styles)

export const SelectReportTypeStep = () => {
  const { t } = useTranslation()
  const isDisabledCard = reportFacade.isCardDisabled
  const isGenerateDisabled = reportFacade.isGenerateButtonDisabled
  const [selectedCard, setSelectedCard] = useState<'all' | 'selected' | null>(
    isDisabledCard && !isGenerateDisabled ? 'all' : null
  )

  const handleCardSelect = (name: typeof selectedCard) => {
    setSelectedCard(name)
  }

  return (
    <ColDeprecated gap={2} className={cx('SelectReportWrapper')}>
      <ColDeprecated gap={1}>
        <Typography variant={'heading4'} color={'black'}>
          {t('platform:generateReportTitle')}
        </Typography>
        <Typography variant={'body1'} color={'black'}>
          {t('platform:generateReportDescription')}
        </Typography>
      </ColDeprecated>
      <RowDeprecated align={'between'}>
        <SelectReportTypeCard
          title={t('platform:entireGraph')}
          onSelect={isGenerateDisabled ? null : handleCardSelect}
          name={'all'}
          imageUrl={genAllImage}
          isDisabled={isGenerateDisabled}
          isSelected={selectedCard === 'all'}
        />
        <SelectReportTypeCard
          title={t('platform:selectedObjects')}
          onSelect={isDisabledCard ? null : handleCardSelect}
          secondaryTitle={
            isDisabledCard && !isGenerateDisabled
              ? t('platform:noSelectedObjects')
              : null
          }
          name={'selected'}
          imageUrl={
            isDisabledCard && !isGenerateDisabled
              ? genSelectedDisabledImage
              : genSelectedSelectedImage
          }
          isDisabled={isDisabledCard}
          isSelected={selectedCard === 'selected'}
        />
      </RowDeprecated>
      <RowDeprecated align={'right'}>
        <Button
          disabled={!selectedCard || isGenerateDisabled}
          variant="solid"
          color="primary"
          onClick={() =>
            reportFacade.generateReport(selectedCard === 'selected')
          }
        >
          {t('platform:generateReport')}
        </Button>
      </RowDeprecated>
      {isGenerateDisabled && (
        <div className={cx('ReportWrapperFooter')}>
          <AlertCaution variant="warning">
            {t('platform:noObjectsOnGraph')}
          </AlertCaution>
        </div>
      )}
    </ColDeprecated>
  )
}
