import { Button, Stack, TypographyNew } from '@clainio/web-platform'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './PresetDeleteModal.styles'

type PresetDeleteModalProps = {
  isOpen: boolean
  onDelete: () => void
  onClose: () => void
}

export const PresetDeleteModal: FC<PresetDeleteModalProps> = ({
  isOpen,
  onDelete,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <S.ModalWrapper
      visible={isOpen}
      onRequestClose={onClose}
      title={
        <TypographyNew
          as={'span'}
          variant="heading300Accent"
          color="onBackgroundBase"
        >
          {t('platform:deletePreset')}
        </TypographyNew>
      }
      target={null}
      //TODO need to fix jump scrollbar when preventScroll={true}
      preventScroll={false}
      closable
    >
      {() => (
        <Stack
          gap={'xl'}
          direction={'column'}
          padding={['xxs', 'none', 'none', 'none']}
        >
          <Stack padding={['md', 'none', 'xxl', 'none']}>
            <TypographyNew variant={'body100Normal'} color={'onBackgroundBase'}>
              {t('platform:presetDeleteConfirmationText')}
            </TypographyNew>
          </Stack>

          <Stack align="center" justify="space-between" gap="xs">
            <Button
              variant="solid"
              color={'critical'}
              size="lg"
              onClick={() => {
                onDelete()
                onClose()
              }}
            >
              {t('platform:delete')}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              size="lg"
              onClick={onClose}
            >
              {t('platform:cancel')}
            </Button>
          </Stack>
        </Stack>
      )}
    </S.ModalWrapper>
  )
}
