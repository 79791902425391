import styled from 'styled-components'
import { IconButton, Stack, SurfaceContainer } from '@clainio/web-platform'

export const DeleteIconButton = styled(IconButton)`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
`
export const CreatedCardContainer = styled(SurfaceContainer)`
  &:hover {
    ${DeleteIconButton} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

export const CreatedCardTitleSection = styled.div`
  display: inline-grid;
  align-items: center;
  height: 40px;
`

export const CreatedCardMetaSection = styled(Stack)`
  height: 16px;
`
