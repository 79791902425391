import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@clain/core/ui-kit'
import { IconButton } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

import { ToolPanelAddAlert } from '../ToolPanelAddAlert'

import styles from '../index.scss'

const cx = classnames.bind(styles)

interface AddressBtcToolpanelProps {
  plotParent: () => void
  deleteNode: () => void
  showAddAlert?: boolean
  isDeleteNodeDisabled?: boolean
}

const AddressBtcToolpanel: React.FC<AddressBtcToolpanelProps> = ({
  plotParent,
  deleteNode,
  showAddAlert = false,
  isDeleteNodeDisabled = false,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <Tooltip placement="right" content={t('platform:plotParent')}>
          <IconButton size="md" onClick={plotParent} iconVariant="Parent" />
        </Tooltip>
        <IconButton size="md" disabled iconVariant="Connections" />
        <IconButton size="md" disabled iconVariant="AttributeBelongs" />
        <IconButton size="md" disabled iconVariant="Play" />
        <IconButton size="md" disabled iconVariant="Pallete" />
        {showAddAlert && <ToolPanelAddAlert />}
      </RowDeprecated>
      <div className={cx('Delimeter')} />
      <RowDeprecated className={cx('Container')} direction="column" gap={0.25}>
        <Tooltip
          placement="right"
          content={t(
            isDeleteNodeDisabled
              ? 'platform:deletingDisabled'
              : 'platform:removeNode'
          )}
        >
          <IconButton
            size="md"
            disabled={isDeleteNodeDisabled}
            onClick={isDeleteNodeDisabled ? null : deleteNode}
            iconVariant="Delete"
          />
        </Tooltip>
      </RowDeprecated>
    </React.Fragment>
  )
}

export default AddressBtcToolpanel
