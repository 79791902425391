import React from 'react'
import classnames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface StatusTagProps {
  status?: 'in_progress' | 'on_hold' | 'archived'
  size?: 'sm' | 'md'
  className?: string
}

const StatusTag: React.FC<StatusTagProps> = ({
  status,
  size = 'sm',
  className,
}) => {
  const { t } = useTranslation()

  if (!status) return null

  const variant = size === 'sm' ? 'tag3' : 'tag2'

  const statusLabel = {
    in_progress: t('platform:inProgress'),
    on_hold: t('platform:onHold'),
    archived: t('platform:archived'),
  }

  return (
    <div className={cx('StatusTag', status, size, className)}>
      <Typography
        className={cx('Text')}
        transform="uppercase"
        variant={variant}
        color="black"
      >
        {statusLabel[status]}
      </Typography>
    </div>
  )
}

export default StatusTag
