import { TrxFiltersState } from './TrxFilters.service'

import { btcToSatoshi } from '../TransactionsSearch'
import { TransactionSearchRequestParams } from '../../../../../apiServices'
import { comparators } from '@clain/core/ui-kit'
import { InputOutputTypeId } from '@platform/components/BlockchainFilter/types'

export const normalizeTrxFilters = (
  trxFilters: TrxFiltersState
): Partial<TransactionSearchRequestParams> => {
  const normalizedFilters: Partial<TransactionSearchRequestParams> = {
    page: 1,
    trx_total_amount_from: undefined,
    trx_total_amount_to: undefined,
    input_amount_from: undefined,
    input_amount_to: undefined,
    input_amount_eq: undefined,
    output_amount_from: undefined,
    output_amount_to: undefined,
    output_amount_eq: undefined,

    trx_fee_from: undefined,
    trx_fee_to: undefined,
    trx_fee_eq: undefined,
    trx_fee_per_byte_from: undefined,
    trx_fee_per_byte_to: undefined,
    trx_fee_per_byte_eq: undefined,
    trx_vsize_from: undefined,
    trx_vsize_to: undefined,
    trx_vsize_eq: undefined,
    trx_size_from: undefined,
    trx_size_to: undefined,
    trx_size_eq: undefined,

    trx_num_inputs_from: undefined,
    trx_num_inputs_to: undefined,
    trx_num_inputs_eq: undefined,
    trx_num_outputs_from: undefined,
    trx_num_outputs_to: undefined,
    trx_num_outputs_eq: undefined,

    input_score_from: undefined,
    input_score_to: undefined,
    output_score_from: undefined,
    output_score_to: undefined,

    trx_version: undefined,
    trx_rbf: undefined,
    trx_segwit: undefined,
    trx_locktime: undefined,

    input_compressed: undefined,
    output_compressed: undefined,
    output_not_a_change: undefined,

    input_type: undefined,
    output_type: undefined,
    trx_inputs_all: undefined,
    trx_outputs_all: undefined,

    input_not_a_multisig: undefined,
    output_not_a_multisig: undefined,
    output_multisig: undefined,
    input_multisig: undefined,
  }

  if (trxFilters.calendar.data) {
    const [fromDate, toDate] = trxFilters.calendar.data

    if (fromDate) {
      normalizedFilters['trx_time_from'] = fromDate
    }

    if (toDate) {
      normalizedFilters['trx_time_to'] = toDate
    }
  }

  if (trxFilters.amount.data) {
    const [fromValue, toValue] = trxFilters.amount.data
    const condition = trxFilters.amount.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_total_amount_from = btcToSatoshi(fromValue)
        normalizedFilters.trx_total_amount_to = btcToSatoshi(toValue)
        break

      case 'is':
        normalizedFilters.trx_total_amount_from = btcToSatoshi(fromValue)
        normalizedFilters.trx_total_amount_to = btcToSatoshi(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_total_amount_to = btcToSatoshi(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_total_amount_from = btcToSatoshi(fromValue)
        break
    }
  }

  if (trxFilters.input_score.data) {
    const [amountFrom, amountTo] = trxFilters.input_score.data

    normalizedFilters.input_score_from = amountFrom
    normalizedFilters.input_score_to = amountTo
  }

  if (trxFilters.input_multisig.condition) {
    if (trxFilters.input_multisig.condition === comparators.isNot.value) {
      normalizedFilters.input_not_a_multisig = true
    }
  }

  if (trxFilters.input_multisig.data?.length) {
    normalizedFilters.input_multisig = [
      Number(trxFilters.input_multisig.data[0]),
      Number(trxFilters.input_multisig.data[1]),
    ]
  }

  if (trxFilters.output_multisig.condition) {
    if (trxFilters.output_multisig.condition === comparators.isNot.value) {
      normalizedFilters.output_not_a_multisig = true
    }
  }

  if (trxFilters.output_multisig.data?.length) {
    normalizedFilters.output_multisig = [
      Number(trxFilters.output_multisig.data[0]),
      Number(trxFilters.output_multisig.data[1]),
    ]
  }

  if (trxFilters.output_score.data) {
    const [amountFrom, amountTo] = trxFilters.output_score.data

    normalizedFilters.output_score_from = amountFrom
    normalizedFilters.output_score_to = amountTo
  }

  if (trxFilters.fee.data) {
    const [fromValue, toValue] = trxFilters.fee.data
    const condition = trxFilters.fee.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_fee_from = Number(fromValue)
        normalizedFilters.trx_fee_to = Number(toValue)
        break

      case 'is':
        normalizedFilters.trx_fee_eq = Number(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_fee_to = Number(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_fee_from = Number(fromValue)
        break
    }
  }

  if (trxFilters.feeByte.data) {
    const [fromValue, toValue] = trxFilters.feeByte.data
    const condition = trxFilters.feeByte.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_fee_per_byte_from = Number(fromValue)
        normalizedFilters.trx_fee_per_byte_to = Number(toValue)
        break

      case 'is':
        normalizedFilters.trx_fee_per_byte_eq = Number(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_fee_per_byte_to = Number(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_fee_per_byte_from = Number(fromValue)
        break
    }
  }

  if (trxFilters.size.data) {
    const [fromValue, toValue] = trxFilters.size.data
    const condition = trxFilters.size.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_size_from = Number(fromValue)
        normalizedFilters.trx_size_to = Number(toValue)
        break

      case 'is':
        normalizedFilters.trx_size_eq = Number(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_size_to = Number(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_size_from = Number(fromValue)
        break
    }
  }

  if (trxFilters.vSize.data) {
    const [fromValue, toValue] = trxFilters.vSize.data
    const condition = trxFilters.vSize.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_vsize_from = Number(fromValue)
        normalizedFilters.trx_vsize_to = Number(toValue)
        break

      case 'is':
        normalizedFilters.trx_vsize_eq = Number(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_vsize_to = Number(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_vsize_from = Number(fromValue)
        break
    }
  }

  if (trxFilters.numberInputs.data) {
    const [fromValue, toValue] = trxFilters.numberInputs.data
    const condition = trxFilters.numberInputs.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_num_inputs_from = Number(fromValue)
        normalizedFilters.trx_num_inputs_to = Number(toValue)
        break

      case 'is':
        normalizedFilters.trx_num_inputs_eq = Number(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_num_inputs_to = Number(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_num_inputs_from = Number(fromValue)
        break
    }
  }

  if (trxFilters.numberOutputs.data) {
    const [fromValue, toValue] = trxFilters.numberOutputs.data
    const condition = trxFilters.numberOutputs.condition

    switch (condition) {
      case 'isBetween':
        normalizedFilters.trx_num_outputs_from = Number(fromValue)
        normalizedFilters.trx_num_outputs_to = Number(toValue)
        break

      case 'is':
        normalizedFilters.trx_num_outputs_eq = Number(fromValue)
        break

      case 'isLess':
        normalizedFilters.trx_num_outputs_to = Number(fromValue)
        break

      case 'isGreater':
        normalizedFilters.trx_num_outputs_from = Number(fromValue)
        break
    }
  }

  if (trxFilters.input_amount.data) {
    const [fromValue, toValue] = trxFilters.input_amount.data
    const condition = trxFilters.input_amount.condition

    switch (condition) {
      case 'is':
        normalizedFilters.input_amount_eq = btcToSatoshi(fromValue)
        break

      case 'isLess':
        normalizedFilters.input_amount_to = btcToSatoshi(fromValue)
        break

      case 'isGreater':
        normalizedFilters.input_amount_from = btcToSatoshi(fromValue)
        break

      case 'isBetween':
        normalizedFilters.input_amount_from = btcToSatoshi(fromValue)
        normalizedFilters.input_amount_to = btcToSatoshi(toValue)
        break
    }
  }

  if (trxFilters.output_amount.data) {
    const [fromValue, toValue] = trxFilters.output_amount.data
    const condition = trxFilters.output_amount.condition

    switch (condition) {
      case 'is':
        normalizedFilters.output_amount_eq = btcToSatoshi(fromValue)
        break

      case 'isLess':
        normalizedFilters.output_amount_to = btcToSatoshi(fromValue)
        break

      case 'isGreater':
        normalizedFilters.output_amount_from = btcToSatoshi(fromValue)
        break

      case 'isBetween':
        normalizedFilters.output_amount_from = btcToSatoshi(fromValue)
        normalizedFilters.output_amount_to = btcToSatoshi(toValue)
        break
    }
  }

  if (trxFilters.version.data) {
    const { data, condition } = trxFilters.version
    switch (data.value) {
      case 1:
        normalizedFilters.trx_version = condition === 'is' ? 1 : 2
        break
      case 2:
        normalizedFilters.trx_version = condition === 'is' ? 2 : 1
        break
    }
  }

  if (trxFilters.rbf.data) {
    normalizedFilters.trx_rbf = trxFilters.rbf.condition === 'is'
  }

  if (trxFilters.segwit.data) {
    normalizedFilters.trx_segwit = trxFilters.segwit.condition === 'is'
  }

  if (trxFilters.locktime.data) {
    normalizedFilters.trx_locktime = trxFilters.locktime.condition === 'is'
  }

  if (trxFilters.input_compression.data) {
    const { data, condition } = trxFilters.input_compression
    switch (data.value) {
      case 1:
        normalizedFilters.input_compressed = condition === 'is'
        break
      case 2:
        normalizedFilters.input_compressed = condition !== 'is'
        break
    }
  }

  if (trxFilters.output_compression.data) {
    const { data, condition } = trxFilters.output_compression
    switch (data.value) {
      case 1:
        normalizedFilters.output_compressed = condition === 'is'
        break
      case 2:
        normalizedFilters.output_compressed = condition !== 'is'
        break
    }
  }

  if (trxFilters.output_change.data) {
    normalizedFilters.output_not_a_change =
      trxFilters.output_change.condition === 'isNot'
  }

  if (trxFilters.input_type.data) {
    const { data, condition } = trxFilters.input_type

    switch (condition) {
      case 'is': // all inputs
        normalizedFilters.trx_inputs_all = Number(InputOutputTypeId[data.label])
        break

      case 'isAny': // at least one
        normalizedFilters.input_type = Number(InputOutputTypeId[data.label])
        break
    }
  }

  if (trxFilters.output_type.data) {
    const { data, condition } = trxFilters.output_type

    switch (condition) {
      case 'is': // all outputs
        normalizedFilters.trx_outputs_all = Number(
          InputOutputTypeId[data.label]
        )
        break

      case 'isAny': // at least one
        normalizedFilters.output_type = Number(InputOutputTypeId[data.label])
        break
    }
  }

  return normalizedFilters
}
