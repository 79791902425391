import { useAssetFilterData, useSelectedAssetData } from './useAssetFilterData'
import {
  AssetFilter as AssetFilterComponent,
  AssetFilterMenu,
} from '@platform/components/Filters'
import { InjectComponentModel } from '@clain/core/utils'
import {
  AssetFilterContainerProps,
  AssetFilterData,
  AssetFilterMenuContainerProps,
  AssetFilterSelected,
} from './AssetFilter.types'

const FilterContainer = InjectComponentModel<
  AssetFilterContainerProps,
  AssetFilterData & AssetFilterSelected
>(
  (props) => (
    <AssetFilterComponent
      {...props}
      menuComponent={<AssetFilterMenu {...props} />}
    />
  ),
  {
    useFactory: (props) => {
      const assetData = useAssetFilterData(props)
      const selectedAsset = useSelectedAssetData(props)

      return { ...props, ...assetData, ...selectedAsset }
    },
  }
)

const FilterMenuContainer = InjectComponentModel<
  AssetFilterMenuContainerProps,
  AssetFilterData
>(AssetFilterMenu, {
  useFactory: (props) => {
    const assetData = useAssetFilterData(props)

    return { ...props, ...assetData }
  },
})

export const AssetFilter = {
  Container: FilterContainer,
  Menu: FilterMenuContainer,
}
