import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@clain/core/ui-kit'
import { ConfirmButton, ConfirmButtonProps } from '@clain/core/ui-kit'
import { ImportantCategoryProps } from './ImportantCategory.types'
import { MagicGrid } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

export const ImportantCategoryRemoveConfirm: React.FC<
  React.PropsWithChildren<
    Pick<ConfirmButtonProps, 'onClick'> &
      Pick<ImportantCategoryProps, 'label' | 'color'>
  >
> = ({ color, label, onClick, children }) => {
  const { t } = useTranslation()

  return (
    <ConfirmButton
      as="span"
      onClick={onClick}
      confirmVariant="delete"
      title={
        <Typography variant="heading5" color="black">
          {t('platform:deleteImportanceCategory')}
        </Typography>
      }
      content={
        <MagicGrid>
          <Typography variant="body1" color="black">
            <RowDeprecated gap={0.5}>
              <TagDeprecated style={{ background: color, color: '#fff' }}>
                {label}
              </TagDeprecated>
              {t('platform:importanceCategoryWillBeDeleted')}
            </RowDeprecated>
          </Typography>
          <Typography variant="body1" color="black">
            {t('platform:casesMovedToDefault')}
          </Typography>
        </MagicGrid>
      }
    >
      {children}
    </ConfirmButton>
  )
}
