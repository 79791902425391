import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react'
import { type PresetsContextProps } from './Presets.types'

const PresetsContext = createContext<PresetsContextProps | undefined>(undefined)

export function usePresetsContext() {
  const context = useContext(PresetsContext)
  if (!context) {
    throw new Error('usePresetsContext must be used within a PresetsContext')
  }
  return context
}

export const PresetsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isCreatePresetOpen, setIsCreatePresetOpen] = useState(false)

  const toggleCreatePreset = () => {
    setIsCreatePresetOpen((prev) => !prev)
  }

  return (
    <PresetsContext.Provider value={{ isCreatePresetOpen, toggleCreatePreset }}>
      {children}
    </PresetsContext.Provider>
  )
}
