import { inject, injectable } from 'inversify'
import {
  SpecificPositioningOptions,
  StraightPositioningOptions,
  FreeSquarePositioningOptions,
  HasNeighborsOptions,
} from './PositioningController.types'
import { Position } from '@clain/graph-layout/types'
import { GRAPH_ENTITIES_TYPES } from '../../constants/injectTypes'
import { IEntitiesMainState } from '../../types'

const UNIT_SIZE = 25

@injectable()
export class PositioningController {
  private occupiedPositions: Map<number, Set<number>>

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.EntitiesState)
    private probeState: IEntitiesMainState
  ) {
    this.occupiedPositions = new Map()
  }

  private markOccupied({ x, y }: Position): void {
    if (!this.occupiedPositions.has(x)) {
      this.occupiedPositions.set(x, new Set())
    }
    this.occupiedPositions.get(x)!.add(y)
  }

  private isOccupied({ x, y }: Position): boolean {
    return (
      this.occupiedPositions.has(x) && this.occupiedPositions.get(x)!.has(y)
    )
  }

  public worldCoordinatesToSpaceMatrix({ x, y }: Position): Position {
    return {
      x: Math.round(x / UNIT_SIZE),
      y: Math.round(y / UNIT_SIZE),
    }
  }

  public spaceMatrixToWorldCoordinates({ x, y }: Position): Position {
    return {
      x: x * UNIT_SIZE,
      y: y * UNIT_SIZE,
    }
  }

  public specificPositioning(
    pivot: Position,
    options: SpecificPositioningOptions
  ): Position {
    const { x = 0, y = 0 } = options
    const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)
    const spaceMatrixResult = { x: x + pivotX, y: y + pivotY }
    const result = this.spaceMatrixToWorldCoordinates(spaceMatrixResult)

    this.markOccupied(spaceMatrixResult)

    return result
  }

  public straightPositioning(
    pivot: Position,
    options: StraightPositioningOptions
  ): Position {
    const {
      direction,
      minIndent,
      allowShift,
      autoAdjustment = true,
      step = 1,
    } = options

    const dirCorrection = minIndent > 0 ? 1 : -1
    const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)

    if (
      !this.isOccupied({ x: pivotX, y: pivotY }) &&
      !this.hasNeighbors({ x: pivotX, y: pivotY }, { depth: minIndent })
    ) {
      this.markOccupied({ x: pivotX, y: pivotY })
      return this.spaceMatrixToWorldCoordinates({ x: pivotX, y: pivotY })
    }

    let iteration = 0

    while (true) {
      const indent = minIndent + iteration * step * dirCorrection

      const x = direction === 'vertical' ? pivotX : pivotX + indent
      const y = direction === 'vertical' ? pivotY + indent : pivotY

      if (!this.isOccupied({ x, y }) && !this.hasNeighbors({ x, y })) {
        if (autoAdjustment) {
          this.markOccupied({ x, y })
        }

        return this.spaceMatrixToWorldCoordinates({ x, y })
      }

      if (allowShift) {
        const shiftingPivot = this.spaceMatrixToWorldCoordinates({ x, y })

        const step1 = this.straightPositioning(shiftingPivot, {
          direction: direction === 'vertical' ? 'horizontal' : 'vertical',
          minIndent: 1,
          allowShift: false,
          autoAdjustment: false,
        })

        const step2 = this.straightPositioning(shiftingPivot, {
          direction: direction === 'vertical' ? 'horizontal' : 'vertical',
          minIndent: -1,
          allowShift: false,
          autoAdjustment: false,
        })

        return this.pickBetterStep(shiftingPivot, step1, step2, autoAdjustment)
      }

      iteration++
    }
  }

  private pickBetterStep(
    pivot: Position,
    step1: Position,
    step2: Position,
    autoAdjustment: boolean
  ): Position {
    const distance1 = Math.hypot(pivot.x - step1.x, pivot.y - step1.y)
    const distance2 = Math.hypot(pivot.x - step2.x, pivot.y - step2.y)

    const betterStep = distance1 < distance2 ? step1 : step2

    if (autoAdjustment) {
      const { x, y } = this.worldCoordinatesToSpaceMatrix(betterStep)
      this.markOccupied({ x, y })
    }

    return betterStep
  }

  public freeSquarePositioning(
    pivot: Position,
    options: FreeSquarePositioningOptions
  ): Position {
    const { indent = 1 } = options
    const { x: pivotX, y: pivotY } = this.worldCoordinatesToSpaceMatrix(pivot)
    const step = indent + 1

    let direction: 'top' | 'right' | 'bottom' | 'left' = 'right'
    let centerX = pivotX
    let centerY = pivotY
    let iteration = 0

    while (true) {
      if (
        this.isFreeSquare(
          { x: centerX - indent, y: centerY - indent },
          { x: centerX + indent, y: centerY + indent }
        )
      ) {
        this.markOccupied({ x: centerX, y: centerY })
        return this.spaceMatrixToWorldCoordinates({ x: centerX, y: centerY })
      }

      ;({ direction, centerX, centerY, iteration } = this.updateDirection(
        direction,
        centerX,
        centerY,
        pivotX,
        pivotY,
        step,
        iteration
      ))
    }
  }

  private updateDirection(
    direction: 'right' | 'bottom' | 'left' | 'top',
    centerX: number,
    centerY: number,
    pivotX: number,
    pivotY: number,
    step: number,
    iteration: number
  ): {
    direction: 'right' | 'bottom' | 'left' | 'top'
    centerX: number
    centerY: number
    iteration: number
  } {
    switch (direction) {
      case 'right':
        if (Math.abs(centerX - pivotX) / step >= iteration) {
          return {
            direction: 'bottom',
            centerX,
            centerY: centerY + step,
            iteration,
          }
        }
        return { direction, centerX: centerX + step, centerY, iteration }

      case 'bottom':
        if (Math.abs(centerY - pivotY) / step >= iteration) {
          return {
            direction: 'left',
            centerX: centerX - step,
            centerY,
            iteration,
          }
        }
        return { direction, centerX, centerY: centerY + step, iteration }

      case 'left':
        if (Math.abs(centerX - pivotX) / step >= iteration) {
          return {
            direction: 'top',
            centerX,
            centerY: centerY - step,
            iteration,
          }
        }
        return { direction, centerX: centerX - step, centerY, iteration }

      case 'top':
        if (Math.abs(centerY - pivotY) / step >= iteration) {
          return {
            direction: 'right',
            centerX: centerX + step,
            centerY,
            iteration: iteration + 1,
          }
        }
        return { direction, centerX, centerY: centerY - step, iteration }

      default:
        throw new Error(`Invalid direction: ${direction}`)
    }
  }

  private isFreeSquare(from: Position, to: Position): boolean {
    for (let x = from.x; x <= to.x; x++) {
      if (!this.occupiedPositions.has(x)) continue
      for (let y = from.y; y <= to.y; y++) {
        if (this.occupiedPositions.get(x)!.has(y)) return false
      }
    }
    return true
  }

  public clearSpaceMatrix(): void {
    this.occupiedPositions.clear()
  }

  private hasNeighbors(
    { x: pivotX, y: pivotY }: Position,
    options: HasNeighborsOptions = {}
  ): boolean {
    const { depth = 1 } = options

    for (let d = 1; d <= depth; d++) {
      for (let x = pivotX - d; x <= pivotX + d; x++) {
        if (!this.occupiedPositions.has(x)) continue
        for (let y = pivotY - d; y <= pivotY + d; y++) {
          if (this.occupiedPositions.get(x)!.has(y)) return true
        }
      }
    }

    return false
  }

  public calculateSpaceMatrix = ({
    except,
  }: { except?: Array<string> } = {}) => {
    this.clearSpaceMatrix()

    const exceptNodesSet = new Set(except)

    this.probeState.nodes.forEach(({ position, key }) => {
      if (exceptNodesSet.has(key)) return

      const { x, y } = this.worldCoordinatesToSpaceMatrix(position)

      // TODO: FIXME https://platform.clain.io/cases/9/probes/221
      // do this in drag handler
      if (x < 0 || y < 0) {
        return
      }
      this.markOccupied({ x, y })
    })
  }
}
