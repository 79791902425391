import { BlockchainFilterPathParams } from '@platform/constants'
import { TabsProps, Tabs } from '@clain/core/ui-kit'
import { InjectComponentModel } from '@clain/core/utils'
import { useEntitySelectedTabs } from '../hooks'

export const BlockchainFilterTabs = InjectComponentModel<
  TabsProps<
    BlockchainFilterPathParams['entity'],
    BlockchainFilterPathParams['entity']
  >
>(Tabs, {
  useFactory: useEntitySelectedTabs,
})
