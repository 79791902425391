import { useTranslation } from 'react-i18next'
import { useFilterSlice } from '../TrxFilters.service'
import {
  SelectFilter,
  SelectFilterCondition,
  SelectFilterMenu,
} from '../../../../../Filters'
import { FilterDropdownMenuContainerItem } from '@clainio/web-platform/dist/components/Filters/FilterDropdownMenuContainer/FilterDropdownMenuContainer.types'
import { useSelectFilterOptions } from '../../../../../Filters/SelectFilter/SelectFilter.constants'
import { VERSION_OPTIONS } from '@platform/components/BlockchainFilter/constants'

const ID = 'version'

const MenuContainer = () => {
  const { t } = useTranslation()
  const [filter, { setFilter }] = useFilterSlice(`${ID}.data`)

  return (
    <SelectFilterMenu
      selected={filter ? filter.value : null}
      title={t('platform:version')}
      onChange={setFilter}
      size={'md'}
      options={VERSION_OPTIONS}
    />
  )
}

const FilterContainer = () => {
  const { t } = useTranslation()
  const [, { resetFilter }] = useFilterSlice(ID)
  const [filter] = useFilterSlice(`${ID}.data`)
  const [condition] = useFilterSlice(`${ID}.condition`)

  return (
    <SelectFilter
      selectedCondition={condition}
      value={filter ? filter.label : ''}
      size={'md'}
      label={t('platform:version')}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer />}
      conditionComponent={<FilterConditionContainer />}
    />
  )
}

const FilterConditionContainer = () => {
  const [condition, { setFilter }] = useFilterSlice(`${ID}.condition`)
  const versionOptions = useSelectFilterOptions()

  return (
    <SelectFilterCondition
      options={versionOptions}
      selected={condition}
      onChange={setFilter}
      size={'md'}
    />
  )
}

const MenuItem = (): FilterDropdownMenuContainerItem => {
  const { t } = useTranslation()

  return {
    id: ID,
    label: t('platform:version'),
    icon: null,
    isDisabled: false,
    component: MenuContainer,
  }
}

export const VersionFilter = {
  MenuItem,
  Container: FilterContainer,
}
