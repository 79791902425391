import React, { useState } from 'react'
import { useTranslation } from '@clain/core/utils/i18next'

import { NewEntityButton } from '@clain/core/ui-kit'
import { NEW_SEVERITY } from '../../../../../modules'

import {
  ImportanceCategories,
  ImportantCategoryField,
} from '../ui/ImportanceCategories'
import { observer } from 'mobx-react-lite'
import { useSettings } from '../../../../../hooks'
import { Container } from '@clain/core/ui-kit'
import { AlertCaution } from '@clain/core/ui-kit'

export const SeveritiesModel: React.FC = observer(() => {
  const { t } = useTranslation()
  const [showAddCategory, setShowAddCategory] = useState(false)
  const [error, setError] = useState(false)
  const settings = useSettings()

  return (
    <>
      <ImportanceCategories
        list={settings.severities}
        onDelete={settings.removeSeverity}
        onDefault={settings.setDefaultSeverity}
        onSave={({ id, ...rest }) => {
          if (id) {
            settings.updateSeverty({ ...rest, id })
          }
        }}
        onError={setError}
      />
      {!showAddCategory && settings.isEnabledAddSeverity && (
        <NewEntityButton
          size="md"
          onClick={() => {
            setShowAddCategory(true)
          }}
        >
          {t('platform:addCategory')}
        </NewEntityButton>
      )}
      {showAddCategory && settings.isEnabledAddSeverity && (
        <ImportantCategoryField
          list={settings.severities}
          {...NEW_SEVERITY}
          onSave={(data) => {
            settings.createSeverity(data)
            setShowAddCategory(false)
          }}
          onCancel={() => {
            setShowAddCategory(false)
          }}
          isEdit
          onError={setError}
        />
      )}
      {error && (
        <Container gap={[0.5, 0]}>
          <AlertCaution variant="error">
            {t('platform:labelAlreadyExists')}
          </AlertCaution>
        </Container>
      )}
    </>
  )
})
