import React from 'react'
import warning from 'warning'
import classnames from 'classnames/bind'

import Item from './Item'
import ItemComponent from './ItemComponent'
import ItemExternal from './ItemExternal'
import ItemSection from './ItemSection'
import { NavItem, RouterParams } from '../../state'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NavMenuListProps {
  className?: string
  items: Array<NavItem>
  params: RouterParams
  isOpen: boolean
}

const navTypes = {
  default: Item,
  external: ItemExternal,
  component: ItemComponent,
  section: ItemSection,
}

type NavMenuType = keyof typeof navTypes

function detectType(item: NavItem): NavMenuType {
  let type

  if (typeof item.to !== 'undefined') {
    type = 'default'
  }

  if (typeof item.external !== 'undefined') {
    type = 'external'
  }

  if (typeof item.section !== 'undefined') {
    type = 'section'
  }

  if (typeof item.component !== 'undefined') {
    type = 'component'
  }

  return type
}

function getComponent(item: NavItem) {
  return navTypes[detectType(item)]
}

function getKey(item: NavItem, i: number) {
  const k = detectType(item) === 'section' ? item.section : item.label

  return k ? k + i : i
}

const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return // Skip circular reference
      }
      seen.add(value)
    }
    return value
  }
}

const NavMenuList: React.FC<NavMenuListProps> = ({
  className,
  items,
  isOpen,
  params,
}) => {
  return (
    <ul className={className}>
      {items.map((item, i) => {
        const Component = getComponent(item)
        const key = getKey(item, i)

        warning(
          Component,
          `navItem ${JSON.stringify(item, getCircularReplacer)} doesn't match any type`
        )

        return Component ? (
          <Component
            key={key}
            className={cx('NavMenuListItem')}
            item={item}
            isOpen={isOpen}
            params={params}
          />
        ) : null
      })}
    </ul>
  )
}

export default NavMenuList
