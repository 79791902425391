import React from 'react'
import { useFilterSlice } from '../TrxFilters.service'
import {
  ComparatorsValueFilter,
  ComparatorsValueFilterMenu,
} from '@platform/components/Filters'
import { ComparatorFilterKeys } from '@platform/components/BlockchainFilter/types'

export type ComparatorFilterContainerProps = {
  id: ComparatorFilterKeys
  label: string
  title: string
  units?: string
  isDecimalsOnly?: boolean
}

export type ComparatorMenuContainerProps = {
  id: ComparatorFilterKeys
  title?: string
  units?: string
  isDecimalsOnly?: boolean
}
// -> "amount" | "input_amount" | "output_amount" | "numberInputs" | "numberOutputs" | "fee" | "feeByte" | "size" | "vSize"

const MenuContainer = ({
  id,
  title,
  units,
  isDecimalsOnly,
}: ComparatorMenuContainerProps) => {
  const [filter, { setFilter }] = useFilterSlice(`${id}`)

  return (
    <ComparatorsValueFilterMenu
      values={filter.data}
      title={title}
      label={units}
      onChange={setFilter}
      selectedCondition={filter.condition}
      size={'md'}
      isDecimalsOnly={isDecimalsOnly}
    />
  )
}

const FilterContainer = ({
  id,
  label,
  units,
  title,
}: ComparatorFilterContainerProps) => {
  const [, { resetFilter }] = useFilterSlice(id)
  const [values] = useFilterSlice(`${id}.data`)
  const [condition] = useFilterSlice(`${id}.condition`)

  return (
    <ComparatorsValueFilter
      values={values}
      selectedCondition={condition}
      size={'md'}
      label={label}
      units={units}
      clearFilter={resetFilter}
      menuComponent={<MenuContainer id={id} title={title} units={units} />}
    />
  )
}

export const ComparatorFilter = {
  Container: FilterContainer,
  Menu: MenuContainer,
}
