import { useTranslation } from 'react-i18next'
import { FilterDropdownMenuContainerTypes } from '@clain/core/ui-kit'
import { AddressComparatorFilterKeys } from './AddressesFilter.types'
import { ComponentFilterKey } from '@clain/core/utils'
import {
  FILTER_BALANCE_KEY,
  FILTER_RECEIVED_AMOUNT_KEY,
} from '@platform/components/BlockchainFilter/constants'
import { ComparatorFilterContainerProps } from '@platform/components/BlockchainFilter/containers'
import { FILTER_ASSET_KEY } from '@platform/components/EntitiesTable/constants'

export const useAddressesFilterPropsById = () => {
  const { t } = useTranslation()

  const AddressesComparatorFilterPropsById: Record<
    AddressComparatorFilterKeys,
    FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem &
      ComparatorFilterContainerProps &
      ComponentFilterKey<AddressComparatorFilterKeys>
  > = {
    [FILTER_RECEIVED_AMOUNT_KEY.root]: {
      id: FILTER_RECEIVED_AMOUNT_KEY.root,
      filterKey: FILTER_RECEIVED_AMOUNT_KEY,
      label: t('platform:receivedAmount'),
      title: t('platform:receivedAmount'),
      units: '',
      icon: null,
      isDecimalsOnly: false,
    },
    [FILTER_BALANCE_KEY.root]: {
      id: FILTER_RECEIVED_AMOUNT_KEY.root,
      filterKey: FILTER_BALANCE_KEY,
      label: t('platform:balance'),
      title: t('platform:balance'),
      units: '',
      icon: null,
      isDecimalsOnly: false,
      info: t('platform:balanceInfo'),
    },
  }

  const AddressesAssetFilterPropsById: Record<
    typeof FILTER_ASSET_KEY.root,
    FilterDropdownMenuContainerTypes.FilterDropdownMenuContainerItem
  > = {
    [FILTER_ASSET_KEY.root]: {
      id: FILTER_ASSET_KEY.root,
      label: t('platform:asset'),
      icon: null,
    },
  }

  return {
    AddressesComparatorFilterPropsById,
    AddressesAssetFilterPropsById,
  }
}
