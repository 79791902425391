import React from 'react'
import { PortfolioContainer } from '../PortfolioContainer'
import { CheckboxSwitchDouble, Stub } from '@clain/core/ui-kit'
import { PortfolioAssets } from '../PortfolioAssets'

import { Token } from '../../ProbeSandbox/types/converted/TokenBalance'

import { PortfolioProps } from './Portfolio.types'
import { useTranslation } from 'react-i18next'

export const Portfolio: React.FC<PortfolioProps> = ({
  tokensBalance,
  totalAssetsCount,
  updateTokenFilter,
  isLoading,
  scrollToTransactionTable,
}) => {
  const [isShowAll, setIsShowAll] = React.useState(false)
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  const { t } = useTranslation()

  const tokens = React.useMemo(
    () =>
      isShowAll
        ? tokensBalance
        : tokensBalance.filter((tb) => {
            return !tb?.token?.scam && !tb?.token?.spam
          }),
    [isShowAll, tokensBalance]
  )

  const handleTokenFilter = React.useCallback(
    (token: Token) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        scrollToTransactionTable?.()
      }, 100)

      updateTokenFilter(token)
    },
    [scrollToTransactionTable, updateTokenFilter]
  )

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Stub isActive={isLoading}>
      <PortfolioContainer
        label={t('platform:portfolio')}
        rightSlot={
          <CheckboxSwitchDouble<boolean, boolean>
            value={isShowAll}
            onChange={setIsShowAll}
            leftLabel={t('platform:holdings')}
            rightLabel={t('platform:all')}
          />
        }
      >
        <PortfolioAssets
          totalAssetsCount={isShowAll ? totalAssetsCount : tokens.length}
          name={t('platform:assets')}
          assets={tokens}
          handleTokenFilter={handleTokenFilter}
        />
      </PortfolioContainer>
    </Stub>
  )
}
