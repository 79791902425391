import React from 'react'
import { Stack, TypographyNew } from '@clain/core/ui-kit'
import { useTranslation } from 'react-i18next'
import { SelectedEntitiesCountProps } from './SelectedEntitiesCount.types'

export const SelectedEntitiesCount: React.FC<SelectedEntitiesCountProps> = ({
  count,
  entityName,
}) => {
  const { t } = useTranslation()

  if (!count) return null

  return (
    <TypographyNew variant={'body100Normal'} color={'onBackgroundVariant1'}>
      <Stack align="center" gap="xs">
        <div>{'('}</div>
        <TypographyNew variant={'body100Accent'} color={'onBackgroundVariant1'}>
          {count}
        </TypographyNew>
        <div>
          {t('platform:selectedEntitiesCount', '', {
            count,
            entity: entityName,
          })}
        </div>
        <div>{')'}</div>
      </Stack>
    </TypographyNew>
  )
}
