import { HashContainer, IconContainer, RowStyled } from './EntityDetails.styles'
import { CopyIcon } from '@clain/core/ui-kit'
import React, { FC } from 'react'
import {
  DATA_TEXT_CONTAINER_ATTRIBUTE,
  EllipsisTypography,
  HiddenText,
  TypographyNew,
} from '@clainio/web-platform'
import { splitHash } from './EntityDetails.utils'
import { RowEntityName } from './RowEntityName'

export const RowHashEntityName: FC<{ name: string }> = ({ name }) => {
  const formattedHash = splitHash(name)
  return typeof formattedHash === 'string' ? (
    <RowEntityName isHash name={name} />
  ) : (
    <RowStyled>
      <HiddenText text={name} variant={'heading200NormalCode'}>
        <HashContainer gap={0} {...{ [DATA_TEXT_CONTAINER_ATTRIBUTE]: true }}>
          <TypographyNew
            variant={'heading200NormalCode'}
            color={'onBackgroundBase'}
          >
            {formattedHash.firstPart}
          </TypographyNew>
          <EllipsisTypography
            variant={'heading200NormalCode'}
            color={'onBackgroundVariant2'}
          >
            {formattedHash.middlePart}
          </EllipsisTypography>
          <TypographyNew
            variant={'heading200NormalCode'}
            color={'onBackgroundBase'}
          >
            {formattedHash.lastPart}
          </TypographyNew>
        </HashContainer>
      </HiddenText>
      <IconContainer>
        <CopyIcon value={name} />
      </IconContainer>
    </RowStyled>
  )
}
