import React from 'react'
import classnames from 'classnames/bind'
import { useParams, useSearchParams } from '@clain/core/Router/router'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ExplorerIcon } from '@clain/core/assets/view.svg'

import Portlet from '@clain/core/Portlet'
import useTitle from '@clain/core/useTitle'
import { Button, MagicGrid, Stack } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import { FormatProvider } from '@clain/core/utils/format'
import NotFound from '@clain/core/NotFound'
import Header from '../Header'

import TransactionDetailsUtxo from './TransactionDetailsBtc'
import TransactionDetailsEvm from './TransactionDetailsEth'
import { useSelectedCoin } from '../CoinSelect'
import { useNavLinks, useSelectedCurrency } from '../NavIcons'
import { getConfig } from '@clain/core/useConfig'
const config = getConfig()

import styles from './index.scss'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { Stub } from '@clainio/web-platform'
import { TransactionBlock } from '../TransactionBlock'
import { HeaderSlot } from '@clain/core/Layout/slots'
import {
  BlockDataTransaction,
  RawBlockTransaction,
  RawBlockTransactionEVM,
  RawBlockTransactionUTXO,
} from '../../apiServices'
import { isEVM } from '@clain/core/types'
import { CoinType } from '@platform/types/coin'
import { usePlotEntitiesOnGraph } from '@platform/hooks'

const cx = classnames.bind(styles)

interface TransactionTableProps {
  transaction?: RawBlockTransaction
  type: CoinType
  currency: 'src' | 'usd'
  loading?: boolean
}

const TransactionDetails = ({
  transaction,
  type,
  currency,
  loading,
}: TransactionTableProps) => {
  if (isEVM(type)) {
    return (
      <TransactionDetailsEvm
        transaction={transaction as RawBlockTransactionEVM}
        currency={currency}
        loading={loading}
        coinType={type}
      />
    )
  }

  return (
    <TransactionDetailsUtxo
      transaction={transaction as RawBlockTransactionUTXO}
      currency={currency}
      loading={loading}
      type={type}
    />
  )
}

const ExplorerTransaction = () => {
  const { t } = useTranslation()
  const { id: hash } = useParams<{ id: string }>()
  const coin = useSelectedCoin()
  const currency = useSelectedCurrency()

  const [searchParams] = useSearchParams()
  const highlightedInputIndex = searchParams.get('highlightedInputIndex')
  const highlightedOutputIndex = searchParams.get('highlightedOutputIndex')

  useTitle(hash)

  const { data, isLoading } = useHttp<RawBlockTransaction>(
    `${config?.PLATFORM_API}/api/private/explorer/${coin}/trx/${hash}`
  )
  useNavLinks({ probeTransaction: hash })
  const formattedData = normalizeSnakeToCamelCase(data)
  const transactions = formattedData ? [formattedData] : undefined
  const { plotSelectedOnGraph, setSelectEntities } = usePlotEntitiesOnGraph(
    'transactions',
    transactions as unknown as BlockDataTransaction
  )

  HeaderSlot.useContent(
    () => (
      <Header
        icon={<ExplorerIcon />}
        title={
          <>
            {t('platform:transaction')}{' '}
            <span className={cx('subtitle')}>{data?.hash}</span>
          </>
        }
      />
    ),
    []
  )

  if (!data && !isLoading) {
    return <NotFound />
  }

  // TODO: перенести ниже
  if (isLoading) {
    return null
  }

  return (
    <FormatProvider
      precision={{ btc: 8, eth: 8, bnb: 8, trx: 6, doge: 8, ltc: 8, usd: 2 }}
    >
      <MagicGrid>
        <Portlet variant="card">
          {{
            body: (
              <TransactionDetails
                transaction={data}
                type={coin}
                currency={currency}
                loading={isLoading}
              />
            ),
          }}
        </Portlet>
        <Stack direction="column" gap="md">
          <Stack align="center" justify="end" gap="md">
            <Button
              variant="outline"
              color="secondary"
              endIcon="GraphPlot"
              onClick={() => {
                setSelectEntities(formattedData, true)
                plotSelectedOnGraph()
              }}
            >
              {t('platform:plotOnGraph')}
            </Button>
          </Stack>
          <Stub isActive={isLoading}>
            <TransactionBlock
              isShowFullTransaction
              blockchain={coin}
              transaction={formattedData}
              isUSD={currency == 'usd'}
              isLinkToTransactionDisabled
              highlightedInputIndex={
                highlightedInputIndex != null
                  ? Number(highlightedInputIndex)
                  : null
              }
              highlightedOutputIndex={
                highlightedOutputIndex != null
                  ? Number(highlightedOutputIndex)
                  : null
              }
            />
          </Stub>
        </Stack>
      </MagicGrid>
    </FormatProvider>
  )
}

export default ExplorerTransaction
