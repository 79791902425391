import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { EyeCheckbox } from '@clain/core/ui-kit'

import ProbeVM from '../../vm/ProbeViewModel'

import styles from './index.scss'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

const ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT = 34 // px
const ACTIVE_BOTTOMBAR_HEIGHT = 300 // px
const ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT = 100 // %

const ProbeRightSidebar = () => {
  const { t } = useTranslation()

  const infobarStyle = React.useMemo(() => {
    if (!ProbeVM.probeState.isBottombarActive) {
      return { height: '100%' }
    }
    if (ProbeVM.probeState.bottombarStatus === 'hidden') {
      return { height: `calc(100% - ${ACTIVE_HIDDEN_BOTTOMBAR_HEIGHT}px)` }
    }
    if (ProbeVM.probeState.bottombarStatus === 'default') {
      return { height: `calc(100% - ${ACTIVE_BOTTOMBAR_HEIGHT}px)` }
    }
    if (ProbeVM.probeState.bottombarStatus === 'fullScreen') {
      return { height: `calc(100% - ${ACTIVE_FULL_SCREEN_BOTTOMBAR_HEIGHT}%)` }
    }
  }, [ProbeVM.probeState.bottombarStatus, ProbeVM.probeState.isBottombarActive])

  return (
    <div
      className={cx('ProbeRightSidebar', {
        active: ProbeVM.isRightSidebarActive,
      })}
      style={infobarStyle}
      onFocus={() => ProbeVM.setIsRightSidebarActive(true)}
    >
      <Container>
        <ColDeprecated gap={1.5}>
          <Typography variant="subheading2" color="grey2">
            {t('platform:layers')}
          </Typography>
          <ColDeprecated gap={0.5}>
            <RowDeprecated gap={0.5}>
              <EyeCheckbox
                value={ProbeVM.layers.score}
                onChange={ProbeVM.layers.setScore}
              />
              <Typography
                className={cx('LayerName')}
                variant="caption1"
                color="black"
              >
                {t('platform:score')}
              </Typography>
            </RowDeprecated>
            <RowDeprecated gap={0.5}>
              <EyeCheckbox
                value={ProbeVM.layers.osint}
                onChange={ProbeVM.layers.setOsint}
              />
              <Typography
                className={cx('LayerName')}
                variant="caption1"
                color="black"
              >
                {t('platform:osint')}
              </Typography>
            </RowDeprecated>
            <RowDeprecated gap={0.5}>
              <EyeCheckbox
                value={ProbeVM.layers.comments}
                onChange={ProbeVM.layers.setComments}
              />
              <Typography
                className={cx('LayerName')}
                variant="caption1"
                color="black"
              >
                {t('platform:comments')}
              </Typography>
            </RowDeprecated>
            {/* <RowDeprecated gap={0.5}>
              <EyeCheckbox
                value={ProbeVM.layers.text}
                onChange={ProbeVM.layers.setText}
              />
              <Typography
                className={cx('LayerName')}
                variant='caption1'
                color='black'
              >
                Text
              </Typography>
            </RowDeprecated> */}
          </ColDeprecated>
          <ColDeprecated>
            <Typography color="grey2" variant="caption2">
              {t('platform:cluster')}
            </Typography>
            <ColDeprecated gap={0.5}>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.geography}
                  onChange={ProbeVM.layers.setClusterGeography}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:geography')}
                </Typography>
              </RowDeprecated>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.balance}
                  onChange={ProbeVM.layers.setClusterBalance}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:balance')}
                </Typography>
              </RowDeprecated>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.size}
                  onChange={ProbeVM.layers.setClusterSize}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:size')}
                </Typography>
              </RowDeprecated>
            </ColDeprecated>
          </ColDeprecated>
          <ColDeprecated>
            <Typography color="grey2" variant="caption2">
              {t('platform:address')}
            </Typography>
            <ColDeprecated gap={0.5}>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.addressFeatures}
                  onChange={ProbeVM.layers.setAddressFeatures}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:featuresBitcoin')}
                </Typography>
              </RowDeprecated>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.addressClusterLabel}
                  onChange={ProbeVM.layers.setAddressClusterLabel}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:clusterLabel')}
                </Typography>
              </RowDeprecated>
            </ColDeprecated>
          </ColDeprecated>
          <ColDeprecated>
            <Typography color="grey2" variant="caption2">
              {t('platform:transaction')}
            </Typography>
            <ColDeprecated gap={0.5}>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.trxFeatures}
                  onChange={ProbeVM.layers.setTransactionFeatures}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:featuresBitcoin')}
                </Typography>
              </RowDeprecated>
              <RowDeprecated gap={0.5}>
                <EyeCheckbox
                  value={ProbeVM.layers.trxTimestamp}
                  onChange={ProbeVM.layers.setTimestamp}
                />
                <Typography
                  className={cx('LayerName')}
                  variant="caption1"
                  color="black"
                >
                  {t('platform:timestamp')}
                </Typography>
              </RowDeprecated>
            </ColDeprecated>
          </ColDeprecated>
        </ColDeprecated>
      </Container>
    </div>
  )
}

export default withErrorBoundary(observer(ProbeRightSidebar))
